import { supabase } from './supabase'
import { auth } from './auth'; // Import the auth service
import { QueryClient } from 'react-query';
import { createLogger } from '../utils/Logger';

const logger = createLogger({ namespace: 'api.ts' });

// PWA Mobile Interfaces

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export interface PWAInstallState {
  canInstall: boolean;
  isInstalled: boolean;
  deferredPrompt: BeforeInstallPromptEvent | null;
}

// Mobile Interfaces

// src/types/mobile.ts

export interface TouchPosition {
  x: number;
  y: number;
}

export interface SwipeDirection {
  horizontal: 'left' | 'right' | null;
  vertical: 'up' | 'down' | null;
}

export interface TouchHandlers {
  onTouchStart: (e: React.TouchEvent) => void;
  onTouchMove: (e: React.TouchEvent) => void;
  onTouchEnd: (e: React.TouchEvent) => void;
}

export interface SwipeConfig {
  minDistance: number;  // Minimum distance for a swipe
  maxTime: number;     // Maximum time for a swipe in ms
  preventScroll?: boolean;
}

// Device detection types
export interface DeviceInfo {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  isSafari: boolean;
  isChrome: boolean;
  isFirefox: boolean;
  orientation: 'portrait' | 'landscape';
}

// Mobile-specific component props
export interface MobileProps {
  enableSwipe?: boolean;
  swipeThreshold?: number;
  preventScroll?: boolean;
  onSwipe?: (direction: SwipeDirection) => void;
  onTap?: (position: TouchPosition) => void;
  onDoubleTap?: (position: TouchPosition) => void;
}

// Mobile gesture handlers
export interface GestureHandlers extends TouchHandlers {
  onSwipe?: (direction: SwipeDirection) => void;
  onPinch?: (scale: number) => void;
  onRotate?: (angle: number) => void;
  onLongPress?: (position: TouchPosition) => void;
}

// Mobile viewport types
export interface SafeAreaInsets {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

// Mobile orientation type
export type OrientationType = 'portrait' | 'landscape' | 'portrait-primary' | 'portrait-secondary' | 'landscape-primary' | 'landscape-secondary';

// Mobile input types
export interface MobileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  preventZoom?: boolean;
  autoComplete?: 'on' | 'off' | 'name' | 'email' | 'username' | 'new-password' | 'current-password';
  enterKeyHint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
}

// Existing user types
export type UserRole = 'user' | 'admin' | 'moderator';

export interface User {
  id: string;
  email: string;
  full_name: string | null;
  created_at: string | null;
  last_sign_in_at: string | null;
  role: UserRole;
  payment_id: string | null;
  updated_at: string | null;
  switches_available: number;
  last_switch_date: string | null;
  last_switch_replenish_date: string | null;
  sections_passed: number; // Add this line
}

// New support user types
export interface SupportUser {
  id: string;
  email: string;
  full_name: string;
  created_at: string;
  last_activity: string;
  auth_user_id: string | null;
  metadata: Record<string, unknown>;
  email_domain: string;
}

export interface UserInstance {
  id: string;
  user_id: string;
  browser_instance_id: string;
  device_info: string;
  created_at: string;
  last_active: string;
}

export interface Product {
  id: string;
  title: string;  // Changed from title to name
  description: string;
  created_at: string;
  type: string;
  is_addon: boolean;
  is_global: boolean;
  updated_at: string;
}

export interface UserCourseAccess {
  id: string;
  user_id: string;
  course_id: string;
  start_date: string | null;
  end_date: string | null;
  tier_id: string;
  updated_at: string;
}

export type ProductType = 'digital' | 'physical';
export type ProductStatus = 'test' | 'live';
export type SubscriptionStatus = 
  | 'active'
  | 'active_partial_refund'
  | 'cancelled'
  | 'cancelled_partial_refund'
  | 'failed'
  | 'refunded'
  | 'completed'
  | 'paused'
  | 'pending'
  | 'trial'
  | 'expired';
  
  export interface UserCoursePurchase {
    // Primary identifiers
    id: string;
    user_id: string;
    tier_id: string;
    
    // Product information
    thrivecart_product_id: string;
    product_name: string | null;
    product_label: string | null;
    product_type: ProductType | null;
    product_type_string: string | null;
    product_status: ProductStatus | null;
    product_status_string: string | null;
    product_embed_type: string | null;
    product_url: string | null;
    
    // Order information
    thrivecart_order_id: string | null;
    invoice_id: string | null;
    purchase_date: string;
    purchase_ip: string | null;
    purchase_country: string | null;
    event_type: string | null;
    
    // Customer information
    customer_identifier: string | null;
    customer_name: string | null;
    customer_email: string | null;
    
    // Payment information
    payment_processor: string | null;
    payment_amount: number | null;
    payment_currency: string | null;
    payment_frequency: string | null;
    payment_plan_id: string | null;
    payment_plan_name: string | null;
    
    // Subscription information
    thrivecart_subscription_id: string | null;
    subscription_status: SubscriptionStatus;
    subscription_reference: string | null;
    subscription_frequency: string | null;
    subscription_amount: number | null;
    subscription_currency: string | null;
    subscription_total_paid: number | null;
    subscription_date_started: string | null;
    is_active: boolean;
    
    // Payment tracking
    total_payments_expected: number | null;
    payments_made: number | null;
    payments_remaining: number | null;
    last_payment_date: string | null;
    next_payment_date: string | null;
    failed_payment_count: number;
    last_failed_payment_date: string | null;
    
    // Refund and cancellation
    refund_date: string | null;
    cancellation_date: string | null;
    cancellation_reason: string | null;
    
    // Pause/Resume
    pause_date: string | null;
    resume_date: string | null;
    
    // Bump offer information
    has_bump: boolean;
    bump_ids: string[] | null;
    bump_names: string[] | null;
    bump_labels: string[] | null;
    bump_types: string[] | null;
    bump_statuses: string[] | null;
    bump_urls: string[] | null;
    bump_total: number | null;
    
    // Upsell information
    has_upsell: boolean;
    upsell_ids: string[] | null;
    upsell_names: string[] | null;
    upsell_labels: string[] | null;
    upsell_types: string[] | null;
    upsell_total: number | null;
    
    // Downsell information
    downsell_names: string[] | null;
    downsell_labels: string[] | null;
    downsell_types: string[] | null;
    
    // Affiliate information
    affiliate_id: string | null;
    affiliate_email: string | null;
    affiliate_name: string | null;
    affiliate_commission_amount: number | null;
    affiliate_commission_currency: string | null;
    
    // Purchase mapping and raw data
    purchase_map: string[] | null;
    raw_data: Record<string, any> | null;
    
    // Metadata
    created_at: string;
    updated_at: string;
    
    // Generated columns
    subscription_health: string | null;
  }

export interface Tier {
  id: string;
  name: string;
  description: string | null;
  is_global: boolean;
  created_at: string;
  updated_at: string;
}

export interface UserAccessState {
  product: Product;
  currentTier: string;
  isActivatable: boolean;
  hasFullAccess: boolean;
  hasMentorAccess: boolean;
}

export const COURSE_IDS = {
  ISC_2024: '2ced0142-2bd9-423e-a541-9ac6bfed88ef',
  REG_2024: '2d55838e-52b1-46d3-8bdf-8d691e28e596',
  FAR_2024: '2e7dd01a-dffa-481d-9359-17594c903eb0',
  BAR_2024: '7b008bd2-4b5d-4876-82d5-c9f1e05895e0',
  AUD_2024: 'e780a7b3-dcd1-4740-ba54-014e594326a4',
  TCP_2024: 'ffbf1eef-d731-4100-9bc7-8dda62a586d3',
  MENTORSHIP: 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395',
  COPILOT: 'e0bb5344-b61a-419d-b794-4ee4994edfb9',
  STUDY_TIPS: '7e165c0d-c598-4854-aef0-33d6c76d0c75',
} as const;

export const TIER_IDS = {
  STUDY_MATERIALS_ONLY: '0b992474-3a65-41b5-ad90-cc88662cc594',
  MENTOR_ONLY: '25f83b83-0a8a-4599-b387-c8870d79b214',
  FREE_TRIAL: '337eeb06-1040-43d5-ad6d-5316e058aec9',
  FULL_ACCESS: 'c99793af-5529-446b-be12-cc56709509e6',
  COPILOT: '73dc2b94-a518-42a7-91a8-c969326a7515',
  STUDY_TIPS: '32a101a3-1d0f-419d-906c-99b928280bd8',
} as const;

// CACHE FUNCTIONS

// Add this new QueryKeys type definition
export type QueryKeys = {
  [key: string]: unknown;
};
// Cache key constants remain the same
export const CACHE_KEYS = {
  USER_DATA: (userId: string) => `userData_${userId}`,
  USER_ACCESS: (userId: string) => `userAccess_${userId}`,
  PRODUCTS: 'products',
  USER_COURSE_ACCESS: (userId: string, courseId: string) => `userCourseAccess_${userId}_${courseId}`,
  USER_COURSE_PURCHASES: (userId: string) => `userCoursePurchases_${userId}`,
  SIMULATION_DATA: (courseId: string, userId: string) => ['simulationData', courseId, userId],
  SIMULATION_COUNTS: (courseId: string, categoryIds: string[], keslerQFilter: string[], userId: string) => 
    ['simulationCounts', courseId, ...categoryIds.sort(), ...keslerQFilter.sort(), userId],
  CATEGORY_STATS: (courseId: string, categoryIds: string[], filters: string[]) => 
    ['categoryStats', courseId, categoryIds.join(','), filters.join(',')],
  FLASHCARD_CATEGORIES: (courseId: string) => `flashcardCategories_${courseId}`,
  FLASHCARDS: (courseId: string, userId: string) => `flashcards_${courseId}_${userId}`,
  USER_FLASHCARD_PROGRESS: (userId: string, courseId: string) => `userFlashcardProgress_${userId}_${courseId}`,
  CUSTOM_FLASHCARDS: (userId: string, courseId: string) => `customFlashcards_${userId}_${courseId}`,
  FEEDBACK_LIST: 'feedbackList',
  FEEDBACK_ITEM: (ticketId: string) => `feedback_${ticketId}`,
} as const;

// Cache durations remain the same
export const CACHE_DURATION = {
  USER_DATA: 2 * 60 * 1000, // 2 minutes
  PRODUCTS: 30 * 60 * 1000, // 30 minutes
  USER_ACCESS: 2 * 60 * 1000, // 2 minutes
  CONFIDENCE_LEVELS: 5 * 60 * 1000, // 5 minutes
  SIMULATION_DATA: 5 * 60 * 1000, // 5 minutes
  FLASHCARDS: 10 * 60 * 1000, // 10 minutes
  FEEDBACK_LIST: 5 * 60 * 1000, // 5 minutes
  FEEDBACK_ITEM: 5 * 60 * 1000, // 5 minutes
  DEFAULT_STALE_TIME: 60 * 60 * 1000, // 1 hour
  DEFAULT_CACHE_TIME: 24 * 60 * 60 * 1000 // 24 hours
} as const;

// Interfaces remain the same
interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

interface FlashcardCacheData {
  categories?: FlashcardCategory[];
  flashcards?: Flashcard[];
  userProgress?: UserFlashcardProgress[];
  customFlashcards?: CustomFlashcard[];
}

// Enhanced CacheManager implementation
class CacheManager {
  private cache: Map<string, CacheEntry<any>> = new Map();
  private flashcardCache: Map<string, CacheEntry<FlashcardCacheData>> = new Map();
  private persistentKeys: Set<string> = new Set();

  get<T>(key: string): T | null {
    // Check in-memory cache first
    const entry = this.cache.get(key);
    if (!entry) {
      // Check localStorage as fallback
      const storedData = this.getFromStorage<T>(key);
      if (storedData) {
        this.cache.set(key, storedData);
        return storedData.data;
      }
      return null;
    }
    
    const isExpired = Date.now() - entry.timestamp > CACHE_DURATION.CONFIDENCE_LEVELS;
    if (isExpired) {
      this.cache.delete(key);
      this.removeFromStorage(key);
      return null;
    }
    
    return entry.data;
  }
  
  set<T>(key: string, data: T): void {
    const entry = {
      data,
      timestamp: Date.now()
    };
    
    this.cache.set(key, entry);
    this.setInStorage(key, entry);
  }

  getFlashcardData(courseId: string, userId: string): FlashcardCacheData | null {
    const key = `flashcard_${courseId}_${userId}`;
    const entry = this.flashcardCache.get(key) || this.getFromStorage<FlashcardCacheData>(key);
    
    if (!entry) return null;
    
    const isExpired = Date.now() - entry.timestamp > CACHE_DURATION.FLASHCARDS;
    if (isExpired) {
      this.flashcardCache.delete(key);
      this.removeFromStorage(key);
      return null;
    }
    
    return entry.data;
  }

  setFlashcardData(courseId: string, userId: string, data: Partial<FlashcardCacheData>): void {
    const key = `flashcard_${courseId}_${userId}`;
    const existing = this.flashcardCache.get(key)?.data || 
                    this.getFromStorage<FlashcardCacheData>(key)?.data || {};
    
    const entry = {
      data: { ...existing, ...data },
      timestamp: Date.now()
    };
    
    this.flashcardCache.set(key, entry);
    this.setInStorage(key, entry);
  }

  invalidateFlashcardCache(courseId: string, userId: string): void {
    const key = `flashcard_${courseId}_${userId}`;
    this.flashcardCache.delete(key);
    this.removeFromStorage(key);
  }

  private getFromStorage<T>(key: string): CacheEntry<T> | null {
    try {
      const storedData = localStorage.getItem(key);
      if (!storedData) return null;
      
      const entry = JSON.parse(storedData) as CacheEntry<T>;
      if (Date.now() - entry.timestamp > CACHE_DURATION.CONFIDENCE_LEVELS) {
        this.removeFromStorage(key);
        return null;
      }
      
      return entry;
    } catch (error) {
      console.error('Error reading from storage:', error);
      return null;
    }
  }

  private setInStorage<T>(key: string, entry: CacheEntry<T>): void {
    try {
      localStorage.setItem(key, JSON.stringify(entry));
      this.persistentKeys.add(key);
    } catch (error) {
      console.error('Error writing to storage:', error);
    }
  }

  private removeFromStorage(key: string): void {
    try {
      localStorage.removeItem(key);
      localStorage.removeItem(`${key}_expiry`);
      this.persistentKeys.delete(key);
    } catch (error) {
      console.error('Error removing from storage:', error);
    }
  }

  clear(pattern?: string): void {
    if (pattern) {
      // Clear in-memory caches with pattern
      Array.from(this.cache.keys()).forEach(key => {
        if (key.startsWith(pattern)) {
          this.cache.delete(key);
          this.removeFromStorage(key);
        }
      });
      
      Array.from(this.flashcardCache.keys()).forEach(key => {
        if (key.startsWith(pattern)) {
          this.flashcardCache.delete(key);
          this.removeFromStorage(`flashcard_${key}`);
        }
      });

      // Clear localStorage entries with pattern
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith(pattern)) {
          this.removeFromStorage(key);
        }
      });
    } else {
      // Clear all caches
      this.cache.clear();
      this.flashcardCache.clear();
      
      // Clear all cache-related localStorage entries
      Object.keys(localStorage).forEach(key => {
        if (
          key.includes('userData_') || 
          key.includes('userAccess_') || 
          key.includes('products') ||
          key.includes('flashcard_') ||
          key.includes('userCourseAccess_') ||
          key.includes('simulationData_') ||
          key.includes('customFlashcards_') ||
          key.includes('flashcardProgress_')
        ) {
          this.removeFromStorage(key);
        }
      });
    }
  }
}

// Export singleton instance
export const cacheManager = new CacheManager();

// Backward compatibility
export const cacheUtils = {
  get: <T>(key: string): T | null => cacheManager.get<T>(key),
  set: <T>(key: string, data: T): void => cacheManager.set(key, data),
  clear: (pattern?: string): void => cacheManager.clear(pattern)
};

// Add the queryKeys constant for consistent key management
export const queryKeys = {
  user: {
    all: ['users'] as const,
    current: () => ['users', 'current'] as const,
    access: (userId: string) => ['users', userId, 'access'] as const,
  },
  products: {
    all: ['products'] as const,
    byId: (id: string) => ['products', id] as const,
  },
  feedback: {
    all: ['feedback'] as const,
    list: ['feedback', 'list'] as const,
    detail: (ticketId: string) => ['feedback', 'detail', ticketId] as const,
    byId: (id: string) => ['feedback', id] as const,
  },
  courseAccess: {
    all: (userId: string) => ['courseAccess', userId] as const,
    byId: (userId: string, courseId: string) => ['courseAccess', userId, courseId] as const,
  },
  flashcards: {
    all: (courseId: string) => ['flashcards', courseId] as const,
    categories: (courseId: string) => ['flashcards', courseId, 'categories'] as const,
    progress: (userId: string, courseId: string) => ['flashcards', courseId, userId, 'progress'] as const,
    custom: (userId: string, courseId: string) => ['flashcards', courseId, userId, 'custom'] as const,
  },
  simulations: {
    all: (courseId: string) => ['simulations', courseId] as const,
    data: (courseId: string, userId: string) => ['simulations', courseId, userId, 'data'] as const,
    counts: (courseId: string, categoryIds: string[], keslerQFilter: string[], userId: string) => 
      ['simulations', courseId, 'counts', ...categoryIds.sort(), ...keslerQFilter.sort(), userId] as const,
  },
  categories: {
    stats: (courseId: string, categoryIds: string[], filters: string[]) => 
      ['categories', courseId, 'stats', categoryIds.join(','), filters.join(',')] as const,
  }
} as const;

// Initialize QueryClient - remains the same
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: CACHE_DURATION.USER_DATA,
      cacheTime: CACHE_DURATION.USER_DATA * 2,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

//QUIZ

export interface QuestionCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

export interface Question {
  id: string;
  course_id: string;
  question_content: string;
  answer_content: string;
  question_content_file: string | null;
  answer_content_file: string | null;
  exam_id: string | null;
  exam_name: string | null;
  exam_section_name: string | null;
  exam_section_time_allowed: number | null;
  question_category_id: string;
  question_category_name: string;
  sub_question_category_name: string;
  question_type: string;
  choices_count: number;
  correct_answer: string;
  related_lessons: string[];
  question_number: number;
  answers_count: number;
  elo_rank: number;
  correct_answers_count: number;
  incorrect_answers_count: number;
  related_section_ids: string[];
  related_section_names: string[];
  external_id: string | null;
  tags: string[];
  answer_container_type: string;
  avg_confidence: number;
  not_confident_count: number;
  guessing_count: number;
  confident_count: number;
  note: Note | null;
  confidenceLevel: string | null;
  confidenceHistory: ConfidenceHistoryEntry[];
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}

// Database Response Types
export interface DBQuestionResponse {
  question_data: {
    id: string;
    course_id: string;
    question_content: string;
    answer_content: string;
    question_category_id: string;
    question_category_name: string;
    sub_question_category_name: string;
    correct_answer: string;
    free_trial_content: boolean;
    note: Note | null;
    confidence_level: string | null;  // Added this field
  };
  confidence_history: ConfidenceHistoryEntry[];
}

interface ConfidenceLevelData {
  question_id: string;
  confidence_level: string | null;
  confidence_history: ConfidenceHistoryEntry[];
}

interface ConfidenceLevel {
  question_id: string;
  current_confidence_level: string | null;
  confidence_history: ConfidenceHistoryEntry[];
}

// Update the QuizSummary interface
export interface QuizSummary {
  parentCategory: string;
  subCategory: string;
  questionId: string;
  question: string;
  romanList: string | null;
  options: string[];
  tableHTML: string;
  isTableQuestion: boolean;
  userAnswer: string;
  correctAnswer: string;
  answerContent: string;
  isCorrect: boolean;
  note: Note | null;
  confidenceLevel: string;
  timeSpent: number;
  free_trial_content: boolean;  // Added this line
}

// Helper Types
export interface ConfidenceHistoryEntry {
  level: string | null;
  timestamp: string;
}

export interface FilterCounts {
  all: number;
  correct: number;
  incorrect: number;
  confident: number;
  maybe: number;
  guessing: number;
}

export interface UserAnswer {
  [questionId: string]: string;
}

export interface QuizStats {
  totalQuestions: number;
  unansweredQuestions: number;
  incorrectQuestions: number;
  confidenceLevels: {
    guessing: number;
    maybe: number;
    confident: number;
  };
}


// Function Parameter Types
export interface QuizParams {
  p_user_id: string;
  p_course_id: string;
  p_category_ids: string[];
  p_kesler_q_filter: string[];
  p_question_count: number;
  p_is_limited_tier: boolean;
}

export interface FilteredQuestionResult {
  question_id?: string;
  simulation_id?: string;
  category_id: string;
  sequence_order: number;
  last_confidence_level: string | null;
  confidence_history: Array<{
    level: string;
    timestamp: string;
  }>;
}

interface QuestionWithSequence extends Question {
  sequence_order?: number;
}

//END QUIZ

//Quiz History section

// Confidence level types
export type ConfidenceLevelValue = 'confident' | 'maybe' | 'guessing' | null;

// Question result interface
export interface QuestionResult {
  questionId: string;
  score: number;
  maxScore: number;
  isCorrect: boolean;
}

// Base quiz data for all question types
export interface BaseQuizData {
  answered: boolean;
  isCorrect: boolean;
  timeSpent: number;
  confidenceLevel: ConfidenceLevelValue;
  free_trial_content: boolean;
  questionId: string;
  userAnswer: string;
}

// Extended base for mock exam questions
export interface BaseMockExamQuizData extends BaseQuizData {
  mockExamId: string;
  mockExamTestletId: string;
  testletNumber: number;
  mockExamNumber: number;
}

// Regular MCQ specific data
export interface MCQQuestionData extends BaseQuizData {
  type: 'mcq';
}

// Mock Exam MCQ data
export interface MockExamMCQQuestionData extends BaseMockExamQuizData {
  type: 'mock_exam_mcq';
}

// Regular Simulation specific data
export interface SimulationQuestionData extends BaseQuizData {
  type: 'simulation';
  simulationId: string;
  score: number;
  feedback: FeedbackItem[];
  question_results: QuestionResult[];
}

// Mock Exam Simulation data
export interface MockExamSimulationQuestionData extends BaseMockExamQuizData {
  type: 'mock_exam_sim';
  simulationId: string;
  score: number;
  feedback: FeedbackItem[];
  question_results: QuestionResult[];
}

// Study Task Testlet specific data
export interface StudyTaskTestletQuestionData extends BaseQuizData {
  type: 'sp_studytasktestlet';
  testletId: string;
  studyTaskId: string; // Add this field
  score: number;
  feedback: FeedbackItem[];
  question_results: QuestionResult[];
}

// Update the QuizQuestionData union type to include the new type
export type QuizQuestionData = 
  | MCQQuestionData 
  | SimulationQuestionData 
  | MockExamMCQQuestionData 
  | MockExamSimulationQuestionData
  | StudyTaskTestletQuestionData;

// Type guards
export function isSimulationQuestion(question: QuizQuestionData): question is SimulationQuestionData {
  return question.type === 'simulation';
}

export function isMockExamQuestion(
  question: QuizQuestionData
): question is MockExamMCQQuestionData | MockExamSimulationQuestionData {
  return question.type === 'mock_exam_mcq' || question.type === 'mock_exam_sim';
}

export function isMockExamMCQ(
  question: QuizQuestionData
): question is MockExamMCQQuestionData {
  return question.type === 'mock_exam_mcq';
}

export function isMockExamSimulation(
  question: QuizQuestionData
): question is MockExamSimulationQuestionData {
  return question.type === 'mock_exam_sim';
}

export function isStudyTaskTestlet(
  question: QuizQuestionData
): question is StudyTaskTestletQuestionData {
  return question.type === 'sp_studytasktestlet';
}

// Type guard to check if object is Timeout
export function isTimeout(timer: unknown): timer is NodeJS.Timeout {
  return timer !== null && typeof timer === 'object' && 'hasRef' in timer;
}

// Quiz History interface
export type QuizHistoryFilter = 
  | 'all' 
  | 'correct' 
  | 'incorrect' 
  | 'confident' 
  | 'maybe' 
  | 'guessing' 
  | 'simulation' 
  | 'mcq';

export interface QuizHistory {
  id: string;
  course_id: string;
  start_time: string;
  end_time: string | null;
  total_questions: number;
  correct_answers: number;
  incorrect_answers: number;
  percentage_correct: number;
  completed: boolean;
  questions_data: QuizQuestionData[];
  total_time: number;
  avg_time_per_question: number;
  quiz_name: string;
  mock_exam_testlet_id?: string; // Add new optional field
  study_task_testlet_id?: string; // Add new optional field
  study_task_testlet_type?: StudyTaskTestletType; // Add this field
}

// Helper functions

// Add helper function to ensure consistent time tracking
export function formatTimeSpent(seconds: number): {
  minutes: number;
  seconds: number;
  formatted: string;
} {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return {
    minutes,
    seconds: remainingSeconds,
    formatted: `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  };
}

export function formatMockExamName(examNumber: number, testletNumber: number): string {
  return `Mock Exam #${examNumber} - Testlet ${testletNumber}`;
}

export function getMockExamInfo(question: QuizQuestionData): { 
  examNumber: number; 
  testletNumber: number; 
} | null {
  if (isMockExamQuestion(question)) {
    return {
      examNumber: question.mockExamNumber,
      testletNumber: question.testletNumber
    };
  }
  return null;
}

// TBS API TYPES

export interface SimulationCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

export interface BatchUserTBSResponse {
  [simulationId: string]: UserTBSResponse;
}

export interface Exhibit {
  id: string;
  type: 'pdf' | 'markdown';
  content: string;
}

export interface ExhibitsData {
  exhibits: Exhibit[];
}

export interface SimulationQuestionContent {
  questions: (TBSq | HTMLContent)[];
  tableHeaders: string[];
  introduction: string;
}

export interface Simulation {
  id: string;
  course_id: string;
  question_type: 'paragraph' | 'table' | 'standalone_dropdown' | 'mixed_table' | null;
  question_content: SimulationQuestionContent;
  html: string | null;
  exhibits: ExhibitsData | null;
  answer_content: string;
  correct_answer: {
    [questionId: string]: {
      [cellRef: string]: string;
    };
  };
  question_content_file: string | null;
  answer_content_file: string | null;
  exam_id: string | null;
  exam_name: string | null;
  exam_section_name: string | null;
  exam_section_time_allowed: number | null;
  question_category_id: string | null;
  question_category_name: string | null;
  sub_question_category_name: string | null;
  choices_count: number | null;
  related_lessons: string[] | null;
  question_number: number | null;
  answers_count: number;
  elo_rank: number | null;
  correct_answers_count: number;
  incorrect_answers_count: number;
  related_section_ids: string[] | null;
  related_section_names: string[] | null;
  external_id: string | null;
  tags: string[] | null;
  answer_container_type: string | null;
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
  confidenceHistory?: ConfidenceHistoryEntry[];
  currentConfidenceLevel?: string | null;
}

// Update the merged simulation type
export interface MergedSimulation extends Simulation {
  confidenceHistory: ConfidenceHistoryEntry[];
  currentConfidenceLevel: string | null;
  confidenceLevel: string | null;
  sequence_order: number; // Add this new property
}

// Optional: Update FilteredSimulationResult interface to be more explicit
export interface FilteredSimulationResult {
  simulation_id: string;
  question_category_id: string;
  sequence_order: number;
  confidence_level: string | null;
  confidence_history: ConfidenceHistoryEntry[];
  score: number | null;
  response_id: string | null;
}

// Update SimulationWithResponses to not include sequence_order since it's not in the DB
export interface SimulationWithResponses {
  simulations: string; // This is the id after aliasing
  question_category_id: string;
  course_id: string;
  question_content: SimulationQuestionContent;
  html: string | null;
  exhibits: ExhibitsData | null;
  answer_content: string;
  free_trial_content: boolean;
  user_tbs_responses: Array<{
    confidence_level: string | null;
    score: number;
  }>;
  user_confidence_levels: Array<{
    confidence_level: string | null;
    confidence_history: ConfidenceHistoryEntry[];
  }>;
}

export interface SimulationQuestionContent {
  type: 'mixed_table';
  questions: (TBSq | HTMLContent)[];
  tableHeaders: string[];
  introduction: string;
}

// TBSq (Task-Based Simulation Question) interface
// This interface defines the structure for various types of simulation questions
export interface TBSq {
  id: string;
  type: 'paragraph' | 'table' | 'standalone_dropdown' | 'mixed_table';
  cells: Array<{
    ref: string;
    type: 'text' | 'dropdown' | 'input';
    value: string;
    options?: string[];
  }>;
  content?: {
    text?: string;
    dropdowns?: Array<{
      id: string;
      options: string[];
      correctAnswer: string;
    }>;
    table?: {
      headers: string[];
      rows: Array<{
        id: string;
        cells: Array<{
          ref: string;
          type: 'text' | 'dropdown' | 'input';
          value: string;
          options?: string[];
        }>;
      }>;
    };
  };
  additionalInfo?: {
    title?: string;
    description?: string;
  };
}


export interface HTMLContent {
  type: 'html';
  content: string;
}

export interface AnswerFeedback {
  isCorrect: boolean;
  userAnswer: any;
  correctAnswer: any;
}

export interface FeedbackItem {
  questionId: string;
  userAnswer: Record<string, any>;
  correctAnswer: Record<string, any>;
  score: number;
  maxScore: number;
  scorePercentage: number;
  explanation: string;
  answerFeedback: Record<string, AnswerFeedback>;
}

// Response from simulation submission
export interface UserTBSResponse {
  id: string;
  user_id: string;
  course_id: string;
  simulation_id: string;
  answers: Record<string, any>;
  score: number;
  completed: boolean;
  created_at: string;
  updated_at: string;
  feedback: FeedbackItem[];
  confidence_level: ConfidenceLevelValue | null;
  question_results: Array<{
    questionId: string;
    score: number;
    maxScore: number;
    isCorrect: boolean;
  }>;
  free_trial_content: boolean;
  time_spent: number;
}

export interface SimulationResult {
  simulation: Simulation;
  userAnswers: Record<string, any>;
  score: number;
  feedback: FeedbackItem[];
  confidenceLevel: ConfidenceLevelValue;
  timeSpent: number;
}

export interface SimulationCounts {
  total: number;
  unanswered: number;
  correct: number;
  incorrect: number;
  confident: number;
  maybe: number;
  guessing: number;
  confident_correct: number;
  confident_incorrect: number;
  maybe_correct: number;
  maybe_incorrect: number;
  guessing_correct: number;
  guessing_incorrect: number;
  [key: string]: number;
}

export interface ExtendedSimulationStats {
  maybe: number;
  total: number;
  correct: number;
  guessing: number;
  confident: number;
  incorrect: number;
  unanswered: number;
  maybe_correct: number;
  maybe_incorrect: number;
  guessing_correct: number;
  confident_correct: number;
  guessing_incorrect: number;
  confident_incorrect: number;
}

export const DEFAULT_STATS: ExtendedSimulationStats = {
  maybe: 0,
  total: 0,
  correct: 0,
  guessing: 0,
  confident: 0,
  incorrect: 0,
  unanswered: 0,
  maybe_correct: 0,
  maybe_incorrect: 0,
  guessing_correct: 0,
  confident_correct: 0,
  guessing_incorrect: 0,
  confident_incorrect: 0,
};

// Input parameters for the get_simulation_counts RPC
export interface SimulationCountsRPCParams {
  p_course_id: string;
  p_category_ids: string[];
  p_kesler_q_filter: string[];
  p_user_id: string;
  p_is_limited_tier: boolean;
}

// Raw response from the SQL query
export interface SimulationCategoryRawStats {
  parent_category: string;
  category_stats: {
    total: number;
    unanswered: number;
    correct: number;
    incorrect: number;
    confident: number;
    maybe: number;
    guessing: number;
    confident_correct: number;
    confident_incorrect: number;
    maybe_correct: number;
    maybe_incorrect: number;
    guessing_correct: number;
    guessing_incorrect: number;
  };
}

// Processed stats for a category
export interface SimulationCategoryStats {
  parent_category: string;
  category_stats: ExtendedSimulationStats;
}

// Update the CategoryStats[] type to SimulationCategoryStats[]
export type SimulationStatsResponse = SimulationCategoryStats[];

// END TBS TYPES


// Confidence Level Checks

// Update DBQuestionResponse to handle both types
export interface DBConfidenceResponse {
  question_data?: {
    id: string;
    course_id: string;
    question_content: string;
    answer_content: string;
    question_category_id: string;
    question_category_name: string;
    sub_question_category_name: string;
    correct_answer: string;
    free_trial_content: boolean;
    note: Note | null;
    confidence_level: string | null;
  };
  simulation_data?: {
    id: string;
    course_id: string;
    question_content: string;
    answer_content: string;
    question_category_id: string;
    question_category_name: string;
    sub_question_category_name: string;
    free_trial_content: boolean;
    confidence_level: string | null;
  };
  confidence_history: ConfidenceHistoryEntry[];
}

// Add a type guard to check what kind of response we have
export function isQuestionResponse(response: DBConfidenceResponse): response is DBConfidenceResponse & { question_data: NonNullable<DBConfidenceResponse['question_data']> } {
  return response.question_data !== undefined;
}

export function isSimulationResponse(response: DBConfidenceResponse): response is DBConfidenceResponse & { simulation_data: NonNullable<DBConfidenceResponse['simulation_data']> } {
  return response.simulation_data !== undefined;
}

//end confidence level checks

// FLASHCARD INTERFACE

export interface FlashcardCategory {
  id: string;
  course_id: string;
  parent_flashcard_category_name: string;
  sub_flashcard_category_name: string;
  sequence_number: number; // Add this line
}

export interface Flashcard {
  id: string;
  course_id: string;
  side_1_content: string;
  side_2_content: string;
  side_1_content_file: string | null;
  side_2_content_file: string | null;
  flash_card_category_id: string;
  flash_card_category_name: string;
  sub_flash_card_category_name: string;
  related_sections: string[];
  avg_confidence: number;
  not_confident_count?: number; // (some older fields if relevant)
  guessing_count: number;
  maybe_count: number;
  confident_count: number;
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}

export interface CustomFlashcard {
  id: string;
  user_id: string;
  course_id: string;
  front_content: string;
  back_content: string;
  confidence_level: 'guessing' | 'maybe' | 'confident' | null;
  created_at: string;
  updated_at: string;
  tags: string[];
  color: string;
  last_reviewed: string;
  browser_instance_id: string;
  version: number;
  synced: number;
  local_id: string;
}

export interface UserFlashcardProgress {
  id: string;
  user_id: string;
  course_id: string;
  flashcard_id: string;
  confidence_level: 'guessing' | 'maybe' | 'confident' | null;
  last_reviewed: string;
  browser_instance_id: string; // Add this for device tracking
  version: number; // Add this for optimistic locking
  synced: number; // Add this line
}

export type FilterType = 'All' | 'Unanswered' | 'Guessing' | 'Maybe' | 'Confident' | 'CreateCards';

// Extend the Flashcard type to include an optional confidence level
export interface FlashcardWithConfidence extends Flashcard {
  confidence_level?: ConfidenceLevelValue;
}

// Define the union type for filterable cards
export type FilterableCard = FlashcardWithConfidence | CustomFlashcard;

//LESSONS

export interface LessonCategory {
  id: string;
  course_id: string;
  name: string;
  parent_lesson_category_id: string | null;
  category_number: number; // Add this line
}

export interface Lesson {
  id: string;
  course_id: string;
  name: string;
  parent_section_id: string | null;
  lesson_category_id: string | null;
  lesson_category_name: string | null;
  sub_lesson_category_name: string | null;
  content: string | null;
  sequence_number: number | null; // Add this line
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}

export interface UserLessonResponse {
  id: string;
  user_id: string;
  lesson_id: string;
  course_id: string;
  is_completed: boolean;
  confidence_level: string | null;
  last_interaction_at: string;
}

// MOCK EXAM INTERFACE

export interface MockExam {
  id: string;
  user_id: string;
  course_id: string;
  created_at: string;
  updated_at: string;
  exam_number: number;
  total_time: number | null;
  score: number | null;
  exam_day_score: number | null;
  completed: boolean;
  testlets: any | null;
}

export interface MockExamTestlet {
  id: string;
  mock_exam_id: string;
  testlet_number: number;
  type: 'MCQ' | 'SIM';
  questions_data: string[];
  completed: boolean;
  score: number | null;
  time_spent: number | null;
  created_at: string;
  updated_at: string;
}

export interface MockExamResetResponse {
  success: boolean;
  error?: string;
}

//END MOCK EXAM INTERFACE

interface StudyTask {
  id: string;
  course_id: string;
  name: string;
  parent_study_task_name: string | null;
  task_item_type: string;
  task_item_category_id: string | null;
  task_item_ids: string[];
}

interface StudyTaskGroup {
  id: string;
  course_id: string;
  name: string;
  study_task_count: number;
}


// UPDATE: Add lesson_name, lesson_category, and lesson_subcategory to the Note interface
export interface Note {
  id: string;
  course_id: string;
  user_id: string;
  lesson_id: string | null;
  question_id: string | null;
  simulation_id: string | null;
  module_id: string | null;
  content: string;
  created_at: string;
  updated_at: string;
  question_category?: string;
  sub_question_category?: string;
  lesson_name?: string;
  lesson_category?: string;
  lesson_subcategory?: string;
  module_title?: string;
  is_free_trial_content?: boolean;
}

// Filter out PREMIUM non-free trial content
export interface ExtendedNote extends Note {
  lessons?: {
    name: string;
    free_trial_content: boolean;
    lesson_categories: {
      name: string;
      parent_lesson_category_id: string | null;
    };
  };
  questions?: {
    question_category_name: string;
    sub_question_category_name: string;
    free_trial_content: boolean;
  };
  simulations?: {
    free_trial_content: boolean;
  };
  mentor_modules?: {
    title: string;
    free_trial_content: boolean;
  };
  is_free_trial_content: boolean;
}

interface UserActivity {
  id: string;
  user_id: string;
  course_id: string;
  activity_type: string;
  activity_id: string;
  result: string | null;
  created_at: string;
}

interface UserQuestionResponse {
  id: string;
  user_id: string;
  question_id: string;
  course_id: string;
  selected_answer: string;
  is_correct: boolean;
  confidence_level: 'guessing' | 'maybe' | 'confident';
  response_time: number;
  created_at: string;
}

// Additional interfaces for the new methods
interface UserProgress {
  user_id: string;
  course_id: string;
  progress: any; // This should be more specifically defined based on your app's needs
}

interface UserAnalytics {
  categoryPerformance: Array<{ name: string; score: number }>;
  recentQuizzes: Array<{ date: string; name: string; score: number }>;
  totalStudyTime: number;
  averageDailyStudyTime: number;
}

export interface Category {
  id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
  total_questions: number;
  unanswered_questions: number;
  correct_percentage: number;
}

export interface CategoryStats {
  category_id: string;
  total_questions: number;
  unanswered_questions: number;
  correct_percentage: number;
}

export interface ExtendedCategoryStats extends CategoryStats {
  incorrect: number;
  correct: number;
  confident: number;
  maybe: number;
  guessing: number;
  confident_correct: number;
  confident_incorrect: number;
  maybe_correct: number;
  maybe_incorrect: number;
  guessing_correct: number;
  guessing_incorrect: number;
}

export interface LocationState {
  resumeQuizId?: string;
  quizData?: QuizHistory;
  viewQuizId?: string;
  from?: string;
}

//STUDY PLANNER INTERFACES

// Updated and new interfaces
export interface SPCourse {
  cpa_course_id: string;
  course_id: string;
  name: string;
  display_name: string;
  is_blueprint_based: boolean;
  outline_name: string;
}

export interface SPTopic {
  topic_id: string;
  cpa_course_id: string;
  course_provider: string;
  title: string;
  sequence_number: number;
  is_blueprint: boolean;
  estimated_minutes: number | null;
  retake_minutes: number | null;
  topic_type: 'learn' | 'loop' | 'funnel' | 'retake_funnel' | 'mock_exam' | 'mock_exam_retake' | null;
}

export interface SPTopicMapping {
  mapping_id: string;
  course_id: string;
  blueprint_topic_id: string;
  kesler_topic_id: string | null;
  becker_topic_id: string | null;
  gleim_topic_id: string | null;
  question_category_id: string | null;
  flashcard_category_id: string | null;
  task_name: string;
  lesson_id: string | null;
  blueprint_topic: SPTopic;
  primary_topic: SPTopic;
  secondary_topic?: SPTopic;
}

// Mental state types for Brand New candidates
export type BrandNewMentalState = 'Positive' | 'Struggling' | 'Lost';

// Mental state types for In Process candidates
export type InProcessMentalState = 'Positive' | 'Struggling' | 'Lost';

// Mental state types for Failed candidates
export type FailedMentalState = 'Good' | 'Frustrated' | 'Hopeless';

// Work hours type
export type WorkHours = '40 Or Less Hours' | '40-50 Hours' | 'More Than 50' | 'Unemployed' | 'Student';

// Accountability type
export type Accountability = 'Significant Other' | 'Co-Worker' | 'Family Member' | 'Friend' | 'I don\'t have anyone';

// Struggle type
export type Struggle = 'studying' | 'juggling' | 'motivation' | 'recovering from failure' | 'time' | 'getting started';

// Shirt size type
export type ShirtSize = 'small' | 'medium' | 'large' | 'x-large' | '2x-large';

export interface SPStudyType {
  id: string;
  user_id: string;
  course_id: string;
  study_type: 'brand_new' | 'retake';
  bn_mental_state?: BrandNewMentalState | null;
  ip_mental_state?: InProcessMentalState | null;
  f_mental_state?: FailedMentalState | null;
  work_hours?: WorkHours | null;
  accountability?: Accountability | null;
  kids?: boolean | null;
  struggle?: Struggle | null;
  survivor_shirt?: ShirtSize | null;
  created_at: string;
}

export interface CreateStudyTypeRequest {
  userId: string;
  courseId: string;
  studyType: 'brand_new' | 'retake';
  bnMentalState?: BrandNewMentalState | null;
  ipMentalState?: InProcessMentalState | null;
  fMentalState?: FailedMentalState | null;
  workHours?: WorkHours | null;
  accountability?: Accountability | null;
  kids?: boolean | null;
  struggle?: Struggle | null;
  survivorShirt?: ShirtSize | null;
}

export interface SPCourseMix {
  mix_id: string;
  user_id: string;
  course_id: string;
  primary_cpa_course_id: string;
  secondary_cpa_course_id: string | null;
  alias: string | null;  // Add this line
}

export interface SPExamDate {
  exam_date_id: string;
  user_id: string;
  course_id: string;
  start_date: string;
  exam_date: string;
  created_at: string;
  updated_at: string;
}

export interface SPStudyDays {
  study_days_id: string;
  user_id: string;
  course_id: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface SPUserStudyPlan {
  plan_id: string;
  user_id: string;
  course_id: string;
  course_mix_id: string;
  study_type_id: string;
  current_schedule_id: string | null;
  created_at: string;
  updated_at: string;
  recommended_weekly_hours: '15' | '20' | '25'| '30'; // Add this line
}

export interface SPStudySchedule {
  schedule_id: string;
  plan_id: string;
  exam_date_id: string;
  start_date: string;
  end_date: string;
  is_current: boolean;
  created_at: string;
  updated_at: string;
}

export interface SPStudyPlanItem {
  item_id: string;
  plan_id: string;
  mapping_id: string;
  is_completed: boolean;
  sequence_number: number;
  week_number: number; // Added for week-based scheduling
  task_name: string;
  blueprint_topic_title: string;
  blueprint_topic_sequence: number;
  primary_topic_title: string;
  primary_topic_sequence: number;
  secondary_topic_title: string | null;
  secondary_topic_sequence: number | null;
  primary_provider: string;
  secondary_provider: string | null;
  primary_cpa_course_id: string;
  secondary_cpa_course_id: string | null;
  planned_date: string | null;
  estimated_minutes: number | null;
  primary_sequence: number;
  is_primary_blueprint: boolean;  // Add this line
  is_secondary_blueprint: boolean;  // Add this line
  primary_alias: string | null;
  secondary_alias: string | null;
  topic_type: string | null;
}

export interface SPStudyPlanItemSchedule {
  item_schedule_id: string;
  item_id: string;
  schedule_id: string;
  planned_date: string;
  estimated_minutes: number;
  is_completed: boolean;
  completion_date: string | null;
}

export interface TopicInfo {
  sequence_number: number;
  course_provider: string;
}

export interface TopicMappingResult {
  mapping_id: string;
  task_name: string;
  blueprint_topics: TopicInfo[];
  primary_topics: TopicInfo[];
  secondary_topics: TopicInfo[];
}

export interface ConfidenceData {
  total: number;
  confident: number;
}

export interface ConfidenceTrackerData {
  mentorship: {
    total: number;
    completed: number;
  };
  questions: {
    total: number;
    confident: number;
  };
  flashcards: {
    total: number;
    confident: number;
  };
  lessons: {
    total: number;
    confident: number;
  };
  simulations: {
    total: number;
    confident: number;
  };
}

export interface ScheduledDateItem {
  item_id: string;
  planned_date: string;
  week_number: number;
  estimated_minutes: number;
  primary_sequence: number;
  is_primary_blueprint: boolean;
  is_secondary_blueprint: boolean;
  accumulated_minutes?: number; // Added to track running total of study minutes
}

// New interface for weekly study plan summary
export interface WeeklyStudyPlan {
  week_number: number;
  total_minutes: number;
  start_date: string;
  end_date: string;
  tasks: SPStudyPlanItem[];
}

export interface StudyTaskDetails {
  task_name: string;
  plan_id: string;
  study_task_id: string;
  week_number: number; // Added for week-based scheduling
  description: string;
  lesson_id: string | null;
  lesson_name: string | null;
  lesson_category_name: string | null;
  sub_lesson_category_name: string | null;
  question_category_id: string | null;
  parent_question_category_name: string | null;
  sub_question_category_name: string | null;
  flashcard_category_id: string | null;
  parent_flashcard_category_name: string | null;
  sub_flashcard_category_name: string | null;
  primary_course_name: string;
  primary_course_display_name: string;
  primary_course_alias: string | null;
  secondary_course_name: string | null;
  secondary_course_display_name: string | null;
  secondary_course_alias: string | null;
  primary_topic_title: string;
  secondary_topic_title: string | null;
  blueprint_topic_title: string;
  is_completed: boolean;
  topic_type: string | null;
  estimated_minutes: number | null;
}

// New interface for study plan calculation results
export interface StudyPlanCalculation {
  calculatedExamDate: string;
  totalStudyHours: number;
  totalWeeks: number;
  planId: string;
}

// Add to your existing types
export type StudyTaskTestletType = 'loop' | 'learn' | 'funnel' | 'retake_funnel';

export interface StudyTaskTestlet {
  id: string;
  study_task_id: string;
  user_id: string;  // Added user_id field
  type: StudyTaskTestletType;
  questions_data: string[];
  completed: boolean;
  score: number | null;
  time_spent: number | null;
  created_at: string;
  updated_at: string;
}

export interface StudyTaskQuiz {
  testlet: StudyTaskTestlet;
  questionIds: string[];
  quizHistory: QuizHistory;
}

// MENTOR INTERFACES

export interface MentorModule {
  id: string;
  course_id: string;
  title: string;
  content: {
    description: string;
    blocks: ContentBlock[];
    sales_pitch?: string; // Added for premium content marketing
  };
  sales_pitch?: string; // From database column
  sequence_number: number;
  created_at: string;
  updated_at: string;
  free_trial_content: boolean;
  is_completed?: boolean;
  notes?: string;
}

export interface ContentBlock {
  type: 'text' | 'video' | 'download' | 'html';
  content: string;
  videoId?: string;
  downloadUrl?: string;
  buttonText?: string;
  buttonClass?: string;
}

export interface UserMentorProgress {
  id: string;
  user_id: string;
  module_id: string;
  is_completed: boolean;
  notes: string | null;
}

export interface MentorModuleMetadata {
  id: string;
  title: string;
  sequence_number: number;
  free_trial_content: boolean;
  is_completed?: boolean;
  sales_pitch?: string;
}

export interface MentorModuleContent {
  content: any;
  notes?: string;
}


//Search bar interface

export interface SearchResult {
  id: string;
  type: 'lesson' | 'question' | 'simulation' | 'flashcard' | 'custom_flashcard' | 'note';
  title: string;
  preview: string;
  category_id?: string;
  fullContent?: {
    question?: string;
    answer?: string;
    front?: string;
    back?: string;
    content?: string;
  };
}


// CUSTOMER SUPPORT INTERFACES

// INTERNAL FEEDBACK INTERFACE FOR CUSTOMERS TO LEAVE FEEDBACK ON STUDY MATERIALS
export type ContentType = 'questions' | 'lessons' | 'simulations' | 'flashcards';
export type FeedbackValidity = 'pending' | 'yes' | 'no';

export interface ContentFeedback {
  id?: string;
  userId: string;
  contentId: string;
  feedbackText: string;
  createdAt: string;
  email?: string;
  ticketId?: string;
}

interface FeedbackResult {
  feedbackId?: string;
  ticketId?: string;
}

export interface ContentTypeMap {
  questions: { tableName: 'questions', idField: 'id' };
  lessons: { tableName: 'lessons', idField: 'id' };
  simulations: { tableName: 'simulations', idField: 'id' };
  flashcards: { tableName: 'flashcards', idField: 'id' };
}

const contentTypeConfig: ContentTypeMap = {
  questions: { tableName: 'questions', idField: 'id' },
  lessons: { tableName: 'lessons', idField: 'id' },
  simulations: { tableName: 'simulations', idField: 'id' },
  flashcards: { tableName: 'flashcards', idField: 'id' }
};

// Support service interface
export interface SupportService {
  getOrCreateSupportUser(email: string, name?: string): Promise<SupportUser>;
  createTicket(
    email: string,
    subject: string,
    body: string,
    priority?: Ticket['priority']
  ): Promise<TicketCreationResult>;
}

export interface Ticket {
  id: string;
  short_id: number;
  support_user_id: string;
  admin_id: string | null;
  status: 'new' | 'open' | 'pending' | 'closed';
  subject: string;
  body: string;
  priority: 'low' | 'medium' | 'high' | 'urgent';
  created_at: string;
  updated_at: string;
  last_activity_at: string;
  closed_at: string | null;
  tags: string[];
  content_type: ContentType | null;
  content_id: string | null;
  feedback_validity?: FeedbackValidity;
  feedback_reason?: string;  
  feedback_resolved: boolean;
  has_unread?: boolean;
  attachments: Array<{
    name: string;
    url: string;
    type: string;
  }>;
  user: SupportUser;
  
  // Email-related fields
  source: 'email' | 'web' | 'api';
  original_email_id: string | null;
  original_from_email: string | null;
  original_subject: string | null;
  email_thread_id: string | null;
  support_email_alias: string | null;
  is_spam: boolean;
  spam_score: number | null;
  thread_topic: string | null;
  list_id: string | null;

  // Joined fields
  admin?: {
    email: string;
    full_name: string;
  };
  _count?: {
    messages: number;
  };
}

export interface Message {
  id: string;
  ticket_id: string;
  support_user_id: string;
  content: string;
  is_internal: boolean;
  created_at: string;
  is_read: boolean;
  attachments: Array<{
    name: string;
    url: string;
    type: string;
  }>;
  
  // Email sending status
  email_sent: boolean;
  email_sent_at: string | null;
  sendgrid_message_id: string | null;
  
  // Email delivery tracking
  email_delivered: boolean;
  email_delivered_at: string | null;
  
  // Email open tracking
  email_opened: boolean;
  email_opened_at: string | null;
  email_open_count: number;
  
  // Email click tracking
  email_clicked: boolean;
  email_clicked_at: string | null;
  email_click_count: number;
  
  // Email error tracking
  email_error: boolean;
  email_error_reason: string | null;
  email_bounce_category: string | null;
  
  // Email metadata
  email_source: Record<string, unknown> | null;
  from_email: string | null;
  to_emails: string[] | null;
  email_subject: string | null;
  email_timestamp: string | null;
  email_spam_score: number | null;
  message_type: 'outbound' | 'inbound' | 'internal' | 'system';
  email_message_id: string | null;
  email_in_reply_to: string | null;
  email_references: string[] | null;
  user: SupportUser;  // Changed from {email: string, full_name: string} to SupportUser
  admin?: {
    email: string;
    full_name: string | null;
  };
}

// Helper types for email-related data
export interface EmailSource {
  headers: Record<string, string>;
  dkim: string;
  spf: string;
  spam_report: string;
}

export interface EmailAttachment {
  name: string;
  url: string;
  type: string;
}

export interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  content: string;
  variables: string[];
  created_by: string | null;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  category: string | null;
  description: string | null;
}

// Additional interface for creating new tickets
export interface NewTicketData {
  user_id: string;
  subject: string;
  body: string;
  priority: Ticket['priority'];
  status: Ticket['status'];
}

// Additional interface for ticket updates
export interface TicketUpdate extends Partial<Pick<Ticket, 
  'status' | 
  'priority' | 
  'admin_id' | 
  'tags' | 
  'body' |
  'original_email_id' |
  'email_thread_id' |
  'thread_topic' |
  'list_id' |
  'attachments' |
  'feedback_validity' |  // Add feedback validation fields
  'feedback_reason' |
  'feedback_resolved'
>> {
  updated_at?: string;
  closed_at?: string | null;
  last_activity_at?: string;
}

interface TicketCreationResult {
  ticket: Ticket;
  message: Message | null;
}


// Version Control 
/**
 * VersionControlRecord interface for storing old versions
 */

export interface VersionControlRecord {
  id: string;
  content_type: ContentType;
  content_id: string;
  old_value: string;   // JSON stringified old content
  new_value: string;   // JSON stringified new content
  updated_at: string;
  updated_by: string;
  reason: string | null;
  
  // Optional fields for extended functionality
  version_number?: number;
  course_id?: string | null;
  category_id?: string | null;
  category_name?: string | null;
  sub_category_name?: string | null;
  raw_content?: any;
  
  // Content type specific fields - all optional
  side_1_content?: string;
  side_2_content?: string;
  content?: string;
  question_content?: string;
  answer_content?: string;
  question_content_jsonb?: any;
  simulation_html?: string;
  correct_answer?: any;
  exhibits?: any;
  external_id?: string | null;
}

//END INTERFACES

// Helper function for error handling
const handleApiError = (error: any): never => {
  console.error('API call failed:', error);
  if (error instanceof Error) {
    if (error.message.includes('network')) {
      throw new Error('Network error: Please check your internet connection');
    }
    if (error.message.includes('timeout')) {
      throw new Error('Request timeout: The server is taking too long to respond');
    }
  }
  throw error;
};

// Helper functions at the top level
const sanitizeContent = (content: string): string => {
  // Already has HTML formatting? Return as-is
  if (/<[a-z][\s\S]*>/i.test(content)) {
    return content;
  }
  
  // Convert newlines and add styling
  return `
    <div style="font-family: Arial, sans-serif; color: #333333; line-height: 1.6; padding: 20px;">
      ${content.replace(/\n/g, '<br>')}
      <br><br>
      <div style="color: #666666; font-size: 12px; border-top: 1px solid #eeeeee; margin-top: 20px; padding-top: 20px;">
        <p>Kesler CPA Review Support Team</p>
        <p>Need help? Reply to this email or visit our <a href="https://app.keslercpareview.com/user/support" style="color: #0066cc; text-decoration: underline;">Support Center</a></p>
      </div>
    </div>
  `;
};

const formatSubject = (subject: string, shortId: string): string => {
  // Remove any existing ticket reference patterns
  const cleanSubject = subject
    .replace(/^Re:\s*/, '')                // Remove leading "Re: "
    .replace(/\[#[^\]]+\]\s*/, '')        // Remove [#123456]
    .replace(/\[Ticket #[^\]]+\]\s*/, '')  // Remove [Ticket #...]
    .trim();                               // Clean up whitespace
  
  return `Re: [#${shortId}] ${cleanSubject}`;
};

// Helper function to check if user has full access from any course
const hasFullAccessFromAnyCourse = (userAccesses: { tier_id: string; course_id: string; }[]): boolean => {
  return userAccesses.some(access => access.tier_id === TIER_IDS.FULL_ACCESS);
};










// API methods
export const api = {












  // STALE DATA CHECK BETWEEN SUPABASE AND IDB
  async getLastUpdatedTimestamp(table: 'flashcards' | 'flashcard_categories', courseId: string): Promise<string> {
    const { data, error } = await supabase
      .from(table)
      .select('last_updated')
      .eq('course_id', courseId)
      .order('last_updated', { ascending: false })
      .limit(1)
      .single();

    if (error) throw error;
    return data?.last_updated || '1970-01-01T00:00:00Z';
  },




  // User methods
  async getUser(userId: string): Promise<User | null> {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', userId)
      .single();
    
    if (error) {
      if (error.code === 'PGRST116') {
        console.warn(`No user found with ID: ${userId}`);
        return null;
      }
      console.error('Error fetching user:', error);
      throw error;
    }
    
    return data as User;
  },

  async getUserCoursePurchases(userId: string): Promise<UserCoursePurchase[]> {
    const { data, error } = await supabase
      .from('user_course_purchases')
      .select('*')
      .eq('user_id', userId);

    if (error) {
      console.error('Error fetching user course purchases:', error);
      throw error;
    }

    return data || [];
  },

  async getAllUserData(userId: string): Promise<{
    user: User | null;
    userCoursePurchases: UserCoursePurchase[];
    courseAccesses: UserCourseAccess[];
    mentorshipAccess: UserCourseAccess | null;
  }> {
    const cacheKey = `userData_${userId}`;
    const cachedData = localStorage.getItem(cacheKey);
  
    if (cachedData) {
      const parsed = JSON.parse(cachedData);
      if (parsed.expiry > Date.now()) {
        return parsed.data;
      }
    }
  
    const [
      { data: userData, error: userError },
      { data: purchasesData, error: purchasesError },
      { data: accessData, error: accessError }
    ] = await Promise.all([
      supabase.from('users').select('*').eq('id', userId).single(),
      supabase.from('user_course_purchases').select('*').eq('user_id', userId),
      supabase.from('user_course_access').select('*').eq('user_id', userId)
    ]);
  
    if (userError) throw userError;
    if (purchasesError) throw purchasesError;
    if (accessError) throw accessError;
  
    const result = {
      user: userData,
      userCoursePurchases: purchasesData || [],
      courseAccesses: accessData || [],
      mentorshipAccess: accessData?.find(access => 
        access.course_id === 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395'
      ) || null
    };
  
    localStorage.setItem(cacheKey, JSON.stringify({
      data: result,
      expiry: Date.now() + (5 * 60 * 1000) // 5 minutes
    }));
  
    return result;
  },
  
  async createUserInstance(browserInstanceId: string): Promise<void> {
    const user = await auth.getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const deviceInfo = navigator.userAgent;

    const { error } = await supabase
      .from('user_instances')
      .insert({
        user_id: user.id,
        browser_instance_id: browserInstanceId,
        device_info: deviceInfo
      });

    if (error) throw error;
  },

  async updateUserInstance(browserInstanceId: string): Promise<void> {
    const user = await auth.getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const { error } = await supabase
      .from('user_instances')
      .upsert({
        user_id: user.id,
        browser_instance_id: browserInstanceId,
        last_active: new Date().toISOString()
      }, {
        onConflict: 'user_id,browser_instance_id'
      });

    if (error) throw error;
  },

  async updateUser(userId: string, updates: Partial<User>): Promise<User> {
    const { data, error } = await supabase
      .from('users')
      .update(updates)
      .eq('id', userId)
      .single();
    if (error) throw error;
    return data;
  },

  // Product methods
  async getProducts(): Promise<Product[]> {
    logger.debug('API: getProducts called');
    
    const cacheKey = 'cachedProducts';
    const cachedData = localStorage.getItem(cacheKey);
  
    if (cachedData) {
      const { data, expiry } = JSON.parse(cachedData);
      if (expiry > Date.now()) {
        logger.debug(`API: getProducts returned ${data.length} product(s) from cache`);
        return data as Product[];
      }
    }
  
    const { data, error } = await supabase
      .from('products')
      .select('*');
    
    if (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
    
    // Cache the data for 1 hour
    localStorage.setItem(cacheKey, JSON.stringify({
      data,
      expiry: Date.now() + 3600000 // 1 hour in milliseconds
    }));
  
    logger.debug(`API: getProducts returned ${data.length} product(s) from API`);
    return data as Product[];
  },

  async getCourse(courseId: string): Promise<Product | null> {
    logger.debug(`API: getCourse called for courseId: ${courseId}`);
    
    const { data, error } = await supabase
      .from('products')
      .select('*')
      .eq('id', courseId)
      .single();
    
    if (error) {
      if (error.code === 'PGRST116') {
        console.warn(`No course found with ID: ${courseId}`);
        return null;
      }
      console.error('Error fetching course:', error);
      throw error;
    }
    
    logger.debug(`API: getCourse returned data for courseId: ${courseId}`);
    return data as Product;
  },


  // User Course Access methods
  // Updated User Course Access method with caching
  async getUserCourseAccess(userId: string, courseId?: string): Promise<UserCourseAccess | UserCourseAccess[] | null> {
    if (courseId) {
      // Fetch single course access
      const cacheKey = `userCourseAccess_${userId}_${courseId}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        return JSON.parse(cachedData);
      }

      const { data, error } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();

      if (error) {
        console.error('Error fetching user course access:', error);
        return null;
      }

      if (data) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      }

      return data;
    } else {
      // Fetch all course accesses for the user
      const cacheKey = `userCourseAccesses_${userId}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        return JSON.parse(cachedData);
      }

      const { data, error } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId);

      if (error) {
        console.error('Error fetching user course accesses:', error);
        return null;
      }

      if (data) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      }

      return data;
    }
  },

  //PRODUCT ACTIVATION

  async handleCourseSwitch(
    userId: string,
    currentCourseId: string | null,
    newCourseId: string,
    useSwitch: boolean
  ): Promise<{ success: boolean; message: string; switchUsed: boolean }> {
    try {
      const { data, error } = await supabase.rpc('access_switch_cpa_course', {
        input_user_id: userId,
        input_current_course_id: currentCourseId,
        input_new_course_id: newCourseId,
        input_use_switch: useSwitch
      });
  
      if (error) throw error;
  
      // Clear all caches for the user
      await this.clearUserCache(userId);
  
      // Clear flashcard cache for both courses
      if (currentCourseId) {
        localStorage.removeItem(`flashcards_${currentCourseId}`);
        localStorage.removeItem(`flashcardProgress_${currentCourseId}`);
        localStorage.removeItem(`customFlashcards_${currentCourseId}`);
      }
      localStorage.removeItem(`flashcards_${newCourseId}`);
      localStorage.removeItem(`flashcardProgress_${newCourseId}`);
      localStorage.removeItem(`customFlashcards_${newCourseId}`);
  
      // Clear React Query cache
      queryClient.clear();
  
      return {
        success: true,
        message: 'Course switched successfully',
        switchUsed: data
      };
    } catch (error) {
      console.error('Error switching course:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to switch course',
        switchUsed: false
      };
    }
  },
  
  async getDefaultTier(userId: string): Promise<string> {
    const { data, error } = await supabase.rpc('access_get_default_tier', {
      user_id: userId
    });
  
    if (error) throw error;
    return data;
  },
  
  async verifyActivePurchase(userId: string): Promise<boolean> {
    const { data, error } = await supabase.rpc('access_verify_active_purchase', {
      user_id: userId
    });
  
    if (error) throw error;
    return data;
  },



  // CACHING OF PRODUCT TO LOCAL STORAGE AND CLEARING IT
  // New method to clear user-specific cache
  async clearAllCache() {
    // Clear product cache
    localStorage.removeItem('cachedProducts');
    
    // Clear all user-specific caches
    Object.keys(localStorage).forEach(key => {
      if (
        key.startsWith('userCourseAccess_') ||
        key.startsWith('flashcards_') ||
        key.startsWith('flashcardProgress_') ||
        key.startsWith('customFlashcards_')
      ) {
        localStorage.removeItem(key);
      }
    });
  
    // Clear React Query cache
    queryClient.clear();
  },
  
  // New method to clear user-specific cache
  async clearUserCache(userId: string) {
    // Clear localStorage items
    localStorage.removeItem(`userCourseAccess_${userId}`);
    localStorage.removeItem(`userCourseAccess_${userId}_expiry`);
    
    const cachedProducts = localStorage.getItem('cachedProducts');
    if (cachedProducts) {
      localStorage.removeItem('cachedProducts');
    }
  
    // Get all courses the user has access to
    try {
      const { data: userCourses } = await supabase
        .from('user_course_access')
        .select('course_id')
        .eq('user_id', userId);
  
      if (userCourses) {
        // Clear flashcard cache for each course
        userCourses.forEach(({ course_id }) => {
          localStorage.removeItem(`flashcards_${course_id}`);
          localStorage.removeItem(`flashcardProgress_${course_id}`);
          localStorage.removeItem(`customFlashcards_${course_id}`);
        });
      }
  
      // Clear React Query cache for this user
      queryClient.clear();
    } catch (error) {
      console.error('Error clearing user cache:', error);
    }
  },





// QUIZ
//QUIZ START

  // Question Category methods
  async getQuestionCategories(courseId: string): Promise<QuestionCategory[]> {
    const { data, error } = await supabase
      .from('question_categories')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

  // Question methods
  async getQuestionsForCategories(courseId: string, categoryIds: string[], userId: string): Promise<Question[]> {
    try {
      logger.debug('Fetching questions for categories:', categoryIds);

      // Get user's access level
      const { data: userCourseAccess } = await supabase
        .from('user_course_access')
        .select('tier_id')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();

      const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
      const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
      const isLimitedTier = userCourseAccess?.tier_id === freeTrialTierId || userCourseAccess?.tier_id === mentorOnlyTierId;

      const { data: answeredQuestions, error: answeredError } = await supabase
        .from('user_question_responses')
        .select('question_id')
        .eq('user_id', userId);

      if (answeredError) throw answeredError;

      const answeredQuestionIds = answeredQuestions.map(q => q.question_id);

      // Fetch all question categories
      const { data: allCategories, error: categoriesError } = await supabase
        .from('question_categories')
        .select('*')
        .eq('course_id', courseId);

      if (categoriesError) throw categoriesError;

      const subCategoryIds = categoryIds.reduce((acc: string[], categoryId) => {
        const category = allCategories.find(c => c.id === categoryId || c.parent_question_category_name === categoryId);
        if (category) {
          if (category.sub_question_category_name === 'All') {
            acc.push(...allCategories
              .filter(c => c.parent_question_category_name === category.parent_question_category_name && c.id !== category.id)
              .map(c => c.id)
            );
          } else {
            acc.push(category.id);
          }
        }
        return acc;
      }, []);

      let query = supabase
        .from('questions')
        .select(`
          *,
          notes:notes(*),
          user_confidence_levels!inner(confidence_history)
        `)
        .eq('course_id', courseId)
        .in('question_category_id', subCategoryIds)
        .eq('user_confidence_levels.user_id', userId)
        .eq('user_confidence_levels.course_id', courseId);

      // Add free trial filter if needed
      if (isLimitedTier) {
        query = query.eq('free_trial_content', true);
      }

      if (answeredQuestionIds.length > 0) {
        query = query.not('id', 'in', `(${answeredQuestionIds.join(',')})`);
      }

      const { data, error } = await query;

      if (error) throw error;

      return data.map(question => ({
        ...question,
        note: question.notes.length > 0 ? question.notes[0] : null,
        confidenceHistory: question.user_confidence_levels[0]?.confidence_history || [],
        // Null out content if not accessible
        question_content: isLimitedTier && !question.free_trial_content ? null : question.question_content,
        answer_content: isLimitedTier && !question.free_trial_content ? null : question.answer_content
      }));

    } catch (error) {
      console.error('Error fetching questions:', error);
      throw error;
    }
  },

  // Save Q responses
  async saveQuestionResponse(data: {
    user_id: string;
    question_id: string;
    course_id: string;
    selected_answer: string;
    is_correct: boolean;
    confidence_level: string;
    response_time: number;
  }): Promise<void> {
    try {
      const { data: existingData, error: selectError } = await supabase
        .from('user_question_responses')
        .select()
        .eq('user_id', data.user_id)
        .eq('question_id', data.question_id)
        .maybeSingle();
  
      if (selectError) {
        console.error('Error checking for existing response:', selectError);
        throw selectError;
      }

      if (existingData) {
        const { error: updateError } = await supabase
          .from('user_question_responses')
          .update(data)
          .eq('user_id', data.user_id)
          .eq('question_id', data.question_id);
  
        if (updateError) {
          console.error('Error updating question response:', updateError);
          throw updateError;
        }
      } else {
        const { error: insertError } = await supabase
          .from('user_question_responses')
          .insert(data);
  
        if (insertError) {
          console.error('Error inserting question response:', insertError);
          throw insertError;
        }
      }
    } catch (error) {
      console.error('Error in saveQuestionResponse:', error);
      throw error;
    }
  },

  async getCategoryStats(
    userId: string, 
    courseId: string, 
    categoryIds: string[]
  ): Promise<ExtendedCategoryStats[]> {
    logger.debug('Fetching category stats...', { userId, courseId, categoryIds });
  
    try {
      // Call the Supabase RPC
      const { data, error } = await supabase.rpc('get_bulk_category_stats', {
        p_user_id: userId,
        p_course_id: courseId,
        p_category_ids: categoryIds
      });
  
      if (error) {
        console.error('Error fetching category stats:', error);
        throw error;
      }
  
      // The RPC can return either an array or a single object.
      // Force it to an array safely:
      const rawData = Array.isArray(data) ? data : [data];
  
      // Transform to ExtendedCategoryStats
      const transformedData: ExtendedCategoryStats[] = rawData.map((stat: any) => ({
        category_id: stat.category_id,
        total_questions: stat.total_questions,
        unanswered_questions: stat.unanswered_questions,
        correct_percentage: parseFloat(stat.correct_percentage), // sometimes numeric
        incorrect: stat.incorrect,
        correct: stat.correct,
        confident: stat.confident,
        maybe: stat.maybe,
        guessing: stat.guessing,
        confident_correct: stat.confident_correct,
        confident_incorrect: stat.confident_incorrect,
        maybe_correct: stat.maybe_correct,
        maybe_incorrect: stat.maybe_incorrect,
        guessing_correct: stat.guessing_correct,
        guessing_incorrect: stat.guessing_incorrect
      }));
  
      logger.debug('Transformed category stats:', transformedData);
      return transformedData;
    } catch (err) {
      console.error('Exception in getCategoryStats:', err);
      throw err;
    }
  },

  async getNoteForQuestion(userId: string, courseId: string, questionId: string): Promise<Note | null> {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('question_id', questionId)
        .maybeSingle();
  
      if (error) {
        console.error('Error fetching note:', error);
        return null;
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching note:', error);
      return null;
    }
  },

  // QUIZ HISTORY
  
  async createQuizHistory(
    userId: string, 
    courseId: string, 
    questionsData: QuizQuestionData[], 
    quizName?: string,
    mockExamTestletId?: string
  ): Promise<string> {
    try {
      let finalQuizName = quizName;
  
      // If no quiz name provided and not a mock exam, generate one
      if (!finalQuizName && !mockExamTestletId) {
        const quizType = questionsData[0]?.type === 'simulation' ? 'Sim Quiz' : 'MCQ Quiz';
        const { data: nextNumber, error: numberError } = await supabase
          .rpc('get_next_quiz_number', {
            p_user_id: userId,
            p_course_id: courseId,
            p_quiz_type: quizType
          });
        
        if (numberError) throw numberError;
        finalQuizName = `${quizType} #${nextNumber}`;
      }
  
      const { data, error } = await supabase
        .from('quiz_history')
        .insert({
          user_id: userId,
          course_id: courseId,
          total_questions: questionsData.length,
          correct_answers: 0,
          incorrect_answers: 0,
          percentage_correct: 0,
          questions_data: questionsData,
          completed: false,
          total_time: 0,
          avg_time_per_question: 0,
          start_time: new Date().toISOString(),
          end_time: null,
          quiz_name: finalQuizName,
          mock_exam_testlet_id: mockExamTestletId
        })
        .select('id')
        .single();
  
      if (error) throw error;
      return data.id;
    } catch (error) {
      console.error('Error creating quiz history:', error);
      throw error;
    }
  },

  //TBS SPECIFIC HISTORY FUNCTIONS
  async createSimulationQuizHistory(
    userId: string,
    courseId: string,
    simulation: Simulation,
    results: UserTBSResponse,
    timeSpent: number
  ): Promise<string> {
    const questionData: SimulationQuestionData = {
      type: 'simulation',
      questionId: simulation.id,
      simulationId: simulation.id,
      answered: true,
      isCorrect: results.score >= 70,
      timeSpent: timeSpent,
      userAnswer: JSON.stringify(results.answers), // Store actual answers
      confidenceLevel: results.confidence_level,
      score: results.score,
      feedback: results.feedback,
      free_trial_content: results.free_trial_content,
      question_results: results.question_results || []
    };
  
    // Let createQuizHistory handle the quiz naming
    return this.createQuizHistory(userId, courseId, [questionData]);
  },

  async getSimulationQuizHistoryById(quizHistoryId: string): Promise<QuizHistory> {
    const { data, error } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('id', quizHistoryId)
      .single();
  
    if (error) throw error;
    
    // Filter both types of simulation questions
    const simulationQuestionsData = (data.questions_data as (SimulationQuestionData | MockExamSimulationQuestionData)[])
      .filter(q => q.type === 'simulation' || q.type === 'mock_exam_sim');
    
    return {
      ...data,
      questions_data: simulationQuestionsData
    };
  },

  async updateSimulationQuizHistory(
    quizHistoryId: string, 
    questionData: SimulationQuestionData | MockExamSimulationQuestionData
  ): Promise<void> {
    const { data: quizHistory, error: fetchError } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('id', quizHistoryId)
      .single();
  
    if (fetchError) throw fetchError;
  
    // Update typing to include mock exam simulations
    const originalQuestionsData = quizHistory.questions_data as (SimulationQuestionData | MCQQuestionData | MockExamSimulationQuestionData)[];
    
    // Update with proper type checking
    const updatedQuestionsData = originalQuestionsData.map(q => {
      if ((q.type === 'simulation' || q.type === 'mock_exam_sim') && q.simulationId === questionData.simulationId) {
        return { ...q, ...questionData };
      }
      return q;
    });
  
    // Filter simulations with proper type guard
    const simulationQuestions = updatedQuestionsData.filter(
      (q): q is SimulationQuestionData | MockExamSimulationQuestionData => 
        q.type === 'simulation' || q.type === 'mock_exam_sim'
    );
    
    const totalAnswered = simulationQuestions.filter(q => q.answered).length;
    const totalSimulations = simulationQuestions.length;
    const completed = totalAnswered === totalSimulations;
  
    // Calculate percentage for all simulation types
    const percentageCorrect = completed 
      ? simulationQuestions.reduce((sum, q) => sum + (q.score || 0), 0) / totalAnswered 
      : 0;
  
    const totalTime = simulationQuestions.reduce((sum, q) => sum + (q.timeSpent || 0), 0);
    const avgTimePerQuestion = totalAnswered > 0 ? totalTime / totalAnswered : 0;
  
    const { error: updateError } = await supabase
      .from('quiz_history')
      .update({
        questions_data: updatedQuestionsData,
        percentage_correct: percentageCorrect,
        completed,
        end_time: completed ? new Date().toISOString() : null,
        total_time: totalTime,
        avg_time_per_question: avgTimePerQuestion,
      })
      .eq('id', quizHistoryId);
  
    if (updateError) throw updateError;
  },

  async getNextSimulationFromQuizHistory(
    quizHistoryId: string,
    currentSimulationId: string
  ): Promise<string | null> {
    const { data: quizHistory, error } = await supabase
      .from('quiz_history')
      .select('questions_data')
      .eq('id', quizHistoryId)
      .single();
  
    if (error) throw error;
  
    // Filter both types of simulation questions
    const simulationQuestions = (quizHistory.questions_data as (SimulationQuestionData | MockExamSimulationQuestionData)[])
      .filter(q => q.type === 'simulation' || q.type === 'mock_exam_sim');
  
    const currentIndex = simulationQuestions.findIndex(
      q => q.simulationId === currentSimulationId
    );
  
    if (currentIndex === -1 || currentIndex === simulationQuestions.length - 1) {
      return null;
    }
  
    return simulationQuestions[currentIndex + 1].simulationId;
  },
  
  //END TBS SPECIFIC HISTORY FUNCTIONS
  
  async updateQuizHistory(
    quizHistoryId: string, 
    questionId: string, 
    userAnswer: string, 
    isCorrect: boolean, 
    confidenceLevel: ConfidenceLevelValue, 
    timeSpent: number
  ): Promise<void> {
    const { data: quizHistory, error: fetchError } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('id', quizHistoryId)
      .single();
  
    if (fetchError) throw fetchError;
  
    // Update question data while preserving type-specific fields
    const updatedQuestionsData = quizHistory.questions_data.map((q: QuizQuestionData) => {
      if (q.questionId === questionId) {
        // Start with base properties that all types share
        const baseUpdate = {
          ...q,
          answered: true,
          userAnswer,
          isCorrect,
          confidenceLevel,
          timeSpent,
          type: q.type,
          free_trial_content: q.free_trial_content
        };
  
        // Add type-specific properties using type guards
        if (isMockExamMCQ(q)) {
          return {
            ...baseUpdate,
            mockExamId: q.mockExamId,
            mockExamTestletId: q.mockExamTestletId,
            testletNumber: q.testletNumber,
            mockExamNumber: q.mockExamNumber
          };
        } else if (isStudyTaskTestlet(q)) {
          return {
            ...baseUpdate,
            testletId: q.testletId,
            studyTaskId: q.studyTaskId,
            score: q.score,
            feedback: q.feedback,
            question_results: q.question_results
          };
        }
  
        return baseUpdate;
      }
      return q;
    });
  
    // Calculate statistics (no changes needed here - logic is correct)
    const totalAnswered = updatedQuestionsData.filter((q: QuizQuestionData) => q.answered).length;
    const correctAnswers = updatedQuestionsData.filter((q: QuizQuestionData) => q.isCorrect).length;
    const completed = totalAnswered === updatedQuestionsData.length;
    const percentageCorrect = totalAnswered > 0 ? (correctAnswers / totalAnswered) * 100 : 0;
    const totalTime = updatedQuestionsData.reduce((sum: number, q: QuizQuestionData) => sum + (q.timeSpent || 0), 0);
    const avgTimePerQuestion = totalAnswered > 0 ? totalTime / totalAnswered : 0;
  
    // Update quiz history
    const { error: updateError } = await supabase
      .from('quiz_history')
      .update({
        questions_data: updatedQuestionsData,
        correct_answers: correctAnswers,
        incorrect_answers: totalAnswered - correctAnswers,
        percentage_correct: percentageCorrect,
        completed: completed,
        end_time: completed ? new Date().toISOString() : null,
        total_time: totalTime,
        avg_time_per_question: avgTimePerQuestion,
      })
      .eq('id', quizHistoryId);
  
    if (updateError) throw updateError;
  
    // Study task testlet handling
    if (completed && quizHistory.study_task_testlet_id) {
      await this.updateStudyTaskTestletFromQuizHistory(
        quizHistory.study_task_testlet_id,
        quizHistoryId
      );
    }
  },
  
  async getQuizHistory(userId: string, courseId: string): Promise<QuizHistory[]> {
    if (!userId) {
      console.warn('getQuizHistory called without a valid userId');
      return [];
    }
    return this.getFilteredQuizHistory(userId, courseId, 'all');
  },

  async getQuizHistoryForQuestion(userId: string, courseId: string, questionId: string): Promise<any | null> {
    const { data, error } = await supabase
      .rpc('get_quiz_history_for_question', {
        p_user_id: userId,
        p_course_id: courseId,
        p_question_id: questionId
      });

    if (error) {
      console.error('Error fetching quiz history for question:', error);
      throw error;
    }

    return data && data.length > 0 ? data[0] : null;
  },
  
  async getIncompleteQuiz(userId: string, courseId: string): Promise<any | null> {
    logger.debug('API: Fetching incomplete quiz...', { userId, courseId });
    try {
      const { data, error } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('completed', false)
        .order('start_time', { ascending: false })
        .limit(1);
  
      if (error) {
        console.error('API: Error fetching incomplete quiz:', error);
        throw error;
      }
      logger.debug('API: Incomplete quiz fetched:', data);
      return data.length > 0 ? data[0] : null;
    } catch (err) {
      console.error('API: Error in getIncompleteQuiz:', err);
      throw err;
    }
  },

  async getQuestionById(questionId: string): Promise<Question> {
    const { data, error } = await supabase
      .from('questions')
      .select('*')
      .eq('id', questionId)
      .single();

    if (error) throw error;
    return data;
  },
  
  async getQuestionCategory(categoryId: string): Promise<QuestionCategory | null> {
    const { data, error } = await supabase
      .from('question_categories')
      .select('*')
      .eq('id', categoryId)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        return null; // Category not found
      }
      throw error;
    }
    return data;
  },

  async getQuestionsById(questionIds: string[], userId: string, courseId: string): Promise<(Question & { note: Note | null })[]> {
    logger.debug('Fetching questions by ID:', { questionIds, userId, courseId });
    try {
      if (!questionIds || questionIds.length === 0) {
        throw new Error('No question IDs provided');
      }

      // Get user's access level
      const { data: userCourseAccess } = await supabase
        .from('user_course_access')
        .select('tier_id')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();

      const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
      const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
      const isLimitedTier = userCourseAccess?.tier_id === freeTrialTierId || userCourseAccess?.tier_id === mentorOnlyTierId;

      let query = supabase
        .from('questions')
        .select(`
          *,
          question_categories (
            parent_question_category_name,
            sub_question_category_name
          ),
          notes (
            id,
            content,
            created_at,
            updated_at
          )
        `)
        .in('id', questionIds);

      // Add free trial filter if needed
      if (isLimitedTier) {
        query = query.eq('free_trial_content', true);
      }

      // Add user and course filters for notes
      if (userId?.trim()) {
        query = query.eq('notes.user_id', userId);
      }
      if (courseId?.trim()) {
        query = query.eq('notes.course_id', courseId);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Supabase query error:', error);
        throw error;
      }

      return data.map(q => ({
        ...q,
        question_category_name: q.question_categories?.parent_question_category_name || '',
        sub_question_category_name: q.question_categories?.sub_question_category_name || '',
        note: q.notes && q.notes.length > 0 ? q.notes[0] : null,
        question_content: isLimitedTier && !q.free_trial_content ? null : q.question_content,
        answer_content: isLimitedTier && !q.free_trial_content ? null : q.answer_content
      }));
    } catch (error) {
      console.error('Error in getQuestionsById:', error);
      throw error;
    }
  },

  // IMPORTANT: Get mockexamMCQquestion testlets / normal mcq quizzes from quiz history
  async getQuizHistoryById(quizId: string): Promise<QuizHistory> {
    const { data, error } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('id', quizId)
      .single();
  
    if (error) throw error;
    return data;
  },

  // Helper method to check quiz history type
  async getMockExamTestletType(quizId: string): Promise<'simulation' | 'mcq' | null> {
    const quizHistory = await this.getQuizHistoryById(quizId);

    if (!quizHistory?.questions_data?.length) return null;

    const firstQuestion = quizHistory.questions_data[0] as QuizQuestionData;
    
    if (isMockExamSimulation(firstQuestion)) return 'simulation';
    if (isMockExamMCQ(firstQuestion)) return 'mcq';
    
    return null;
  },

  // Add new method to find quiz history by testlet ID
  async getQuizHistoryByTestletId(testletId: string): Promise<QuizHistory | null> {
    const { data, error } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('mock_exam_testlet_id', testletId)
      .single();
  
    if (error) {
      if (error.code === 'PGRST116') return null;
      throw error;
    }
  
    return data;
  },

  // Add new method to get all quiz histories for a mock exam
  async getMockExamQuizHistories(mockExamId: string): Promise<QuizHistory[]> {
    const { data: testlets, error: testletsError } = await supabase
      .from('mock_exam_testlets')
      .select('id')
      .eq('mock_exam_id', mockExamId);

    if (testletsError) throw testletsError;

    const testletIds = testlets.map(t => t.id);

    const { data, error } = await supabase
      .from('quiz_history')
      .select('*')
      .in('mock_exam_testlet_id', testletIds)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data;
  },

  async updateConfidenceLevel(userId: string, courseId: string, questionId: string, confidenceLevel: string): Promise<void> {
    try {
      const { error } = await supabase.rpc('update_question_confidence_level', {
        p_user_id: userId, // Supabase will automatically handle the UUID conversion
        p_course_id: courseId,
        p_question_id: questionId,
        p_confidence_level: confidenceLevel
      });
  
      if (error) {
        console.error('Error updating confidence level:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error in updateConfidenceLevel:', error);
      throw error;
    }
  },

  async getConfidenceLevels(userId: string, courseId: string, questionIds: string[]): Promise<ConfidenceLevel[]> {
    try {
      if (!questionIds?.length) {
        return [];
      }
  
      // Create cache key
      const cacheKey = `confidence_${userId}_${courseId}_${questionIds.sort().join(',')}`;
      
      // Check cache
      const cachedData = cacheUtils.get<ConfidenceLevel[]>(cacheKey);
      if (cachedData) {
        return cachedData;
      }
  
      const { data, error } = await supabase
        .from('user_confidence_levels')
        .select('question_id, confidence_level, confidence_history')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .in('question_id', questionIds)
        .not('confidence_history', 'is', null);
  
      if (error) throw error;
  
      // Process the data to remove null entries
      const processedData = (data as ConfidenceLevelData[])
        .filter(item => {
          const history = item.confidence_history || [];
          // Only keep items that have at least one non-null confidence level
          return history.some((entry: ConfidenceHistoryEntry) => entry.level !== null);
        })
        .map(item => ({
          question_id: item.question_id,
          current_confidence_level: item.confidence_level || null,
          confidence_history: (item.confidence_history || [])
            .filter((entry: ConfidenceHistoryEntry) => entry.level !== null)
            .sort((a: ConfidenceHistoryEntry, b: ConfidenceHistoryEntry) => 
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            )
        }));
  
      // Cache the results
      cacheUtils.set(cacheKey, processedData);
  
      return processedData;
    } catch (error) {
      console.error('Error fetching confidence levels:', error);
      return [];
    }
  },
  
  async getActiveQuiz(userId: string, courseId: string): Promise<QuizHistory | null> {
    if (!userId) {
      console.warn('getActiveQuiz called without a valid userId');
      return null;
    }
  
    try {
      // Check session storage first
      const stored = sessionStorage.getItem('activeQuiz');
      if (stored) {
        const { id, timestamp } = JSON.parse(stored);
        const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;
        
        // If we have a recent stored quiz, fetch it directly
        if (timestamp > fiveMinutesAgo) {
          const { data: storedQuiz, error: storedError } = await supabase
            .from('quiz_history')
            .select('*')
            .eq('id', id)
            .single();
            
          if (storedQuiz && !storedError && !storedQuiz.completed && !storedQuiz.end_time) {
            return storedQuiz as QuizHistory;
          }
        }
      }
  
      const { data, error } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('completed', false)
        .is('end_time', null)
        .order('start_time', { ascending: false })
        .limit(1);
  
      if (error) {
        console.error('Error fetching active quiz:', error);
        throw error;
      }
  
      if (!data || data.length === 0) {
        sessionStorage.removeItem('activeQuiz');
        return null;
      }
  
      const quiz = data[0] as QuizHistory;
  
      if (!quiz.questions_data || !Array.isArray(quiz.questions_data)) {
        sessionStorage.removeItem('activeQuiz');
        return null;  
      }
  
      const firstQuestion = quiz.questions_data[0];
      const isSimulation = firstQuestion.type === 'simulation' || firstQuestion.type === 'mock_exam_sim';
      
      if (isSimulation) {
        return quiz;
      }
  
      const allAnswered = quiz.questions_data.every((q: QuizQuestionData) => q.answered);
      if (allAnswered) {
        await supabase
          .from('quiz_history')
          .update({ completed: true })
          .eq('id', quiz.id);
        sessionStorage.removeItem('activeQuiz');
        return null;
      }
  
      return quiz;
    } catch (error) {
      console.error('Caught error in getActiveQuiz:', error);
      sessionStorage.removeItem('activeQuiz');
      return null;
    }
  },
  
  async endQuizSession(quizId: string, totalTime: number, manuallyEnded: boolean): Promise<void> {
    try {
      // Fetch quiz data
      const { data: quizData, error: fetchError } = await supabase
        .from('quiz_history')
        .select('*, study_task_testlet_id')
        .eq('id', quizId)
        .single();
  
      if (fetchError) throw fetchError;
  
      // Filter and preserve answered questions with type-specific fields
      const answeredQuestions = quizData.questions_data
        .filter((q: QuizQuestionData) => q.answered)
        .map((q: QuizQuestionData) => {
          // Start with base properties that all types share
          const baseQuestion = {
            type: q.type,
            questionId: q.questionId,
            answered: true,
            isCorrect: q.isCorrect,
            timeSpent: q.timeSpent || 0,
            userAnswer: q.userAnswer,
            confidenceLevel: q.confidenceLevel,
            free_trial_content: q.free_trial_content
          };
  
          // Add type-specific properties using type guards
          if (isMockExamMCQ(q)) {
            return {
              ...baseQuestion,
              type: 'mock_exam_mcq' as const,
              mockExamId: q.mockExamId,
              mockExamTestletId: q.mockExamTestletId,
              testletNumber: q.testletNumber,
              mockExamNumber: q.mockExamNumber
            };
          } else if (isStudyTaskTestlet(q)) {
            return {
              ...baseQuestion,
              type: 'sp_studytasktestlet' as const,
              testletId: q.testletId,
              studyTaskId: q.studyTaskId,
              score: q.score,
              feedback: q.feedback,
              question_results: q.question_results
            };
          }
  
          return baseQuestion;
        });
  
      const totalAnswered = answeredQuestions.length;
      const correctAnswers = answeredQuestions.filter((q: QuizQuestionData) => q.isCorrect).length;
      const incorrectAnswers = totalAnswered - correctAnswers;
      const percentageCorrect = totalAnswered > 0 ? (correctAnswers / totalAnswered) * 100 : 0;
      const avgTimePerQuestion = totalAnswered > 0 ? totalTime / totalAnswered : 0;
  
      // Update quiz history
      const { error: updateError } = await supabase
        .from('quiz_history')
        .update({
          completed: true,
          end_time: new Date().toISOString(),
          total_questions: totalAnswered,
          correct_answers: correctAnswers,
          incorrect_answers: incorrectAnswers,
          percentage_correct: percentageCorrect,
          questions_data: answeredQuestions,
          total_time: totalTime,
          avg_time_per_question: avgTimePerQuestion
        })
        .eq('id', quizId);
  
      if (updateError) throw updateError;
  
      // Handle study task testlet if present
      if (quizData.study_task_testlet_id) {
        const { error: testletUpdateError } = await supabase
          .from('sp_studytasktestlets')
          .update({
            completed: true,
            score: percentageCorrect,
            time_spent: totalTime,
            questions_data: answeredQuestions,
            updated_at: new Date().toISOString()
          })
          .eq('id', quizData.study_task_testlet_id);
  
        if (testletUpdateError) {
          console.error('Error updating study task testlet:', testletUpdateError);
          throw testletUpdateError;
        }
      }
    } catch (error) {
      console.error('Error ending quiz session:', error);
      throw error;
    }
  },

  async getAvailableQuestionCounts(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    userId: string
  ): Promise<ExtendedCategoryStats> {
    try {
      // Check tier access
      const { data: userCourseAccess, error: accessError } = await supabase
        .from('user_course_access')
        .select('tier_id')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();

      if (accessError) throw accessError;

      const isLimitedTier = userCourseAccess?.tier_id === TIER_IDS.FREE_TRIAL || 
                           userCourseAccess?.tier_id === TIER_IDS.MENTOR_ONLY;

      // Call RPC to get raw counts first
      const { data: rawData, error: rawError } = await supabase.rpc('get_available_question_counts', {
        p_course_id: courseId,
        p_category_ids: categoryIds,
        p_kesler_q_filter: [], // Empty filter to get raw counts
        p_user_id: userId,
        p_is_limited_tier: isLimitedTier
      });

      if (rawError) throw rawError;

      // Transform into ExtendedCategoryStats with raw counts
      const transformedData: ExtendedCategoryStats = {
        category_id: 'aggregated',
        total_questions: rawData.total || 0,
        unanswered_questions: rawData.unanswered || 0,
        correct_percentage: rawData.total ? ((rawData.correct || 0) / rawData.total) * 100 : 0,
        incorrect: rawData.incorrect || 0,
        correct: rawData.correct || 0,
        confident: rawData.confident || 0,
        maybe: rawData.maybe || 0,
        guessing: rawData.guessing || 0,
        confident_correct: rawData.confident_correct || 0,
        confident_incorrect: rawData.confident_incorrect || 0,
        maybe_correct: rawData.maybe_correct || 0,
        maybe_incorrect: rawData.maybe_incorrect || 0,
        guessing_correct: rawData.guessing_correct || 0,
        guessing_incorrect: rawData.guessing_incorrect || 0
      };

      logger.debug('Transformed available counts:', transformedData);
      return transformedData;
    } catch (error) {
      console.error('API: Error fetching available question counts:', error);
      throw error;
    }
  },
  
  async getQuestionsForCustomQuiz(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    questionCount: number,
    userId: string
  ): Promise<Question[]> {
    try {
      logger.debug('Getting filtered questions with params:', {
        courseId,
        categoryIds,
        keslerQFilter,
        requestedCount: questionCount,
        userId
      });

      if (!categoryIds || categoryIds.length === 0) {
        console.error('No category IDs provided');
        return [];
      }

      // Ensure all elements in categoryIds are defined strings
      const validCategoryIds = categoryIds.filter((id): id is string => id !== undefined);

      // Single call with the new function that handles distribution
      const { data: filteredQuestions, error } = await supabase
        .rpc('get_filtered_question_ids', {
          p_user_id: userId,
          p_course_id: courseId,
          p_category_ids: validCategoryIds,
          p_kesler_q_filter: keslerQFilter,
          p_question_count: questionCount
        }) as unknown as {
          data: FilteredQuestionResult[] | null;
          error: any;
        };

      if (error) {
        console.error('Error getting filtered questions:', error);
        throw error;
      }

      if (!filteredQuestions || filteredQuestions.length === 0) {
        logger.debug('No questions found matching filters');
        return [];
      }

      logger.debug('Filtered questions received:', {
        requested: questionCount,
        received: filteredQuestions.length
      });

      // Get the question IDs, ensuring they are defined
      const selectedIds = filteredQuestions
        .filter(q => q.question_id !== undefined)
        .map(q => q.question_id as string);

      // Fetch full question data
      const questions = await this.getQuestionsById(selectedIds, userId, courseId);

      // Create confidence map with proper typing
      const confidenceMap = filteredQuestions.reduce<Record<string, {
        lastLevel: string | null;
        history: ConfidenceHistoryEntry[];
        sequence_order: number;
      }>>((acc, q) => {
        if (q.question_id) {
          acc[q.question_id] = {
            lastLevel: q.last_confidence_level,
            history: Array.isArray(q.confidence_history) ? q.confidence_history : [],
            sequence_order: q.sequence_order
          };
        }
        return acc;
      }, {});

      // Map questions with confidence data and sort by sequence order
      const questionsWithConfidence = questions
        .map((q): QuestionWithSequence => ({
          ...q,
          confidenceHistory: confidenceMap[q.id]?.history || [],
          confidenceLevel: confidenceMap[q.id]?.lastLevel,
          sequence_order: confidenceMap[q.id]?.sequence_order || 0
        }))
        .sort((a, b) => 
          ((a.sequence_order || 0) - (b.sequence_order || 0))
        );

      // Remove sequence_order before returning since it's not in the Question interface
      const finalQuestions = questionsWithConfidence.map(({ sequence_order, ...rest }) => rest);

      logger.debug('Returning questions:', {
        requested: questionCount,
        returned: finalQuestions.length,
        firstQuestionId: finalQuestions[0]?.id,
        lastQuestionId: finalQuestions[finalQuestions.length - 1]?.id
      });

      return finalQuestions;

    } catch (error) {
      console.error('Error in getQuestionsForCustomQuiz:', error);
      throw error;
    }
  },
  
  async resetSelectedQuestions(
    userId: string,
    courseId: string,
    questionIds: string[]
  ): Promise<void> {
    try {
      logger.debug('Resetting selected questions:', { userId, courseId, questionIds });
      const { error } = await supabase.rpc('reset_selected_questions', {
        p_user_id: userId,
        p_course_id: courseId,
        p_question_ids: questionIds
      });
      if (error) throw error;
      logger.debug('Successfully reset selected questions');
    } catch (error) {
      console.error('Error resetting selected questions:', error);
      throw error;
    }
  },

  async updateQuestionConfidenceLevel(userId: string, courseId: string, questionId: string, confidenceLevel: string): Promise<void> {
    const { error } = await supabase.rpc('update_question_confidence_level', {
      p_user_id: userId,
      p_course_id: courseId,
      p_question_id: questionId,
      p_confidence_level: confidenceLevel
    });
    if (error) throw error;
  },

  async resetQuestionConfidenceLevel(userId: string, questionId: string): Promise<void> {
    const { error } = await supabase.rpc('reset_question_confidence_level', {
      p_user_id: userId,
      p_question_id: questionId
    });
    if (error) throw error;
  },

  
  //Filtering quiz history
  async getFilteredQuizHistory(userId: string, courseId: string, filter: QuizHistoryFilter): Promise<QuizHistory[]> {
    if (!userId) {
      console.warn('getFilteredQuizHistory called without a valid userId');
      return [];
    }
  
    try {
      const { data, error } = await supabase.rpc('filter_quiz_history', {
        p_user_id: userId,
        p_course_id: courseId,
        p_filter: filter.toLowerCase()
      });
  
      if (error) throw error;
      
      // Ensure quiz_name is included in the response
      return data.map((quiz: any) => ({
        ...quiz,
        quiz_name: quiz.quiz_name || '',
      }));
    } catch (err) {
      console.error('API: Error fetching filtered quiz history:', err);
      throw err;
    }
  },


  async deleteEmptyQuizzes(userId: string, courseId: string): Promise<void> {
    const { error } = await supabase.rpc('delete_empty_quizzes', {
      p_user_id: userId,
      p_course_id: courseId
    });
    if (error) throw error;
  },

  //END QUIZ











// FLASHCARDS API


  
/**
 * Fetch all flashcard categories for a course from Supabase.
 */
async getFlashcardCategories(courseId: string): Promise<FlashcardCategory[]> {
  const cached = cacheManager.getFlashcardData(courseId, 'global');
  if (cached?.categories) {
    return cached.categories;
  }

  try {
    const { data, error } = await supabase
      .from('flashcard_categories')
      .select('*')
      .eq('course_id', courseId);

    if (error) throw error;
    if (!data) throw new Error('No data returned from API for categories');

    cacheManager.setFlashcardData(courseId, 'global', { categories: data });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Fetch all flashcards for a course. 
 * Applies free-trial checks by the user's tier if found.
 */
async getFlashcards(courseId: string, userId: string): Promise<Flashcard[]> {
  const cached = cacheManager.getFlashcardData(courseId, userId);
  if (cached?.flashcards) {
    return cached.flashcards;
  }

  try {
    const { data: userCourseAccess, error: accessError } = await supabase
      .from('user_course_access')
      .select('tier_id')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();

    if (accessError) {
      logger.debug('No user course access or error fetching userCourseAccess:', accessError);
    }

    const tierId = userCourseAccess?.tier_id;
    const { data, error } = await supabase
      .from('flashcards')
      .select('*')
      .eq('course_id', courseId);

    if (error) throw error;
    if (!data) throw new Error('No data returned from API for flashcards');

    // For restricted tiers, only keep free_trial_content
    const filteredData = (tierId === TIER_IDS.FREE_TRIAL || tierId === TIER_IDS.MENTOR_ONLY)
      ? data.filter(fc => fc.free_trial_content)
      : data;

    cacheManager.setFlashcardData(courseId, userId, { flashcards: filteredData });
    return filteredData;
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Upsert a user's flashcard progress in Supabase, returning the updated record.
 */
async updateUserFlashcardProgress(
  progress: Omit<UserFlashcardProgress, 'id'>
): Promise<UserFlashcardProgress> {
  try {
    const { error: upsertError } = await supabase
      .from('user_flashcard_progress')
      .upsert(progress, {
        onConflict: 'user_id,course_id,flashcard_id,browser_instance_id'
      });
    if (upsertError) throw upsertError;

    const { data, error: fetchError } = await supabase
      .from('user_flashcard_progress')
      .select('*')
      .match({
        user_id: progress.user_id,
        course_id: progress.course_id,
        flashcard_id: progress.flashcard_id,
        browser_instance_id: progress.browser_instance_id
      })
      .single();

    if (fetchError) throw fetchError;
    if (!data) throw new Error('Failed to fetch updated user flashcard progress');

    // Invalidate the cache
    cacheManager.invalidateFlashcardCache(progress.course_id, progress.user_id);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Fetch all user flashcard progress for a given user + course.
 */
async getUserFlashcardProgress(userId: string, courseId: string): Promise<UserFlashcardProgress[]> {
  const cached = cacheManager.getFlashcardData(courseId, userId);
  if (cached?.userProgress) {
    return cached.userProgress;
  }

  try {
    const { data, error } = await supabase
      .from('user_flashcard_progress')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId);

    if (error) throw error;
    if (!data) throw new Error('No data returned from API for user progress');

    cacheManager.setFlashcardData(courseId, userId, { userProgress: data });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Fetch custom flashcards from Supabase for a user + course, optionally filtered by tags/level/etc.
 */
async getCustomFlashcards(
  userId: string,
  courseId: string,
  filters?: {
    tags?: string[];
    confidenceLevel?: string;
    lastReviewedBefore?: Date;
  }
): Promise<CustomFlashcard[]> {
  const cached = cacheManager.getFlashcardData(courseId, userId);
  if (cached?.customFlashcards) {
    let filtered = cached.customFlashcards;

    if (filters?.tags?.length) {
      filtered = filtered.filter(card => filters.tags!.some(tag => card.tags.includes(tag)));
    }
    if (filters?.confidenceLevel) {
      filtered = filtered.filter(card => card.confidence_level === filters.confidenceLevel);
    }
    if (filters?.lastReviewedBefore) {
      filtered = filtered.filter(card => new Date(card.last_reviewed) < filters.lastReviewedBefore!);
    }
    return filtered;
  }

  try {
    let query = supabase
      .from('custom_flashcards')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .order('last_reviewed', { ascending: true });

    if (filters?.tags?.length) {
      query = query.contains('tags', filters.tags);
    }
    if (filters?.confidenceLevel) {
      query = query.eq('confidence_level', filters.confidenceLevel);
    }
    if (filters?.lastReviewedBefore) {
      query = query.lt('last_reviewed', filters.lastReviewedBefore.toISOString());
    }

    const { data, error } = await query;
    if (error) throw error;
    if (!data) throw new Error('No data returned from API for custom flashcards');

    cacheManager.setFlashcardData(courseId, userId, { customFlashcards: data });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Create a new custom flashcard in Supabase.
 */
async createCustomFlashcard(
  flashcard: Omit<CustomFlashcard, 
    'id' | 'created_at' | 'updated_at' | 'last_reviewed' | 
    'version' | 'synced' | 'local_id'>
): Promise<CustomFlashcard> {
  try {
    const now = new Date().toISOString();
    const newFlashcard = {
      ...flashcard,
      last_reviewed: now
    };
    const { data, error } = await supabase
      .from('custom_flashcards')
      .insert(newFlashcard)
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('No data returned from API after inserting custom flashcard');

    // Invalidate cache
    cacheManager.invalidateFlashcardCache(flashcard.course_id, flashcard.user_id);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Update an existing custom flashcard in Supabase.
 */
async updateCustomFlashcard(
  id: string,
  updates: Partial<CustomFlashcard>
): Promise<CustomFlashcard> {
  try {
    const { data: existingCard, error: fetchError } = await supabase
      .from('custom_flashcards')
      .select('*')
      .eq('id', id)
      .single();

    if (fetchError) throw fetchError;
    if (!existingCard) throw new Error('Custom flashcard not found in Supabase');

    const merged = {
      ...existingCard,
      ...updates,
      updated_at: new Date().toISOString()
    };

    const { data, error } = await supabase
      .from('custom_flashcards')
      .update(merged)
      .eq('id', id)
      .select();
    if (error) throw error;
    if (!data || !data.length) throw new Error('Failed to update custom flashcard');

    // Invalidate cache
    cacheManager.invalidateFlashcardCache(existingCard.course_id, existingCard.user_id);
    return data[0];
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Delete a custom flashcard from Supabase by its DB id.
 */
async deleteCustomFlashcard(flashcardId: string): Promise<void> {
  try {
    // First get the card to know which cache to invalidate
    const { data: card, error: fetchError } = await supabase
      .from('custom_flashcards')
      .select('user_id, course_id')
      .eq('id', flashcardId)
      .single();
    
    if (fetchError) throw fetchError;

    const { error } = await supabase
      .from('custom_flashcards')
      .delete()
      .eq('id', flashcardId);
    if (error) throw error;

    // Invalidate cache if we found the card
    if (card) {
      cacheManager.invalidateFlashcardCache(card.course_id, card.user_id);
    }
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Compute user's overall "cardsCompleted".
 */
async getUserStudyStats(userId: string, courseId: string): Promise<{ cardsCompleted: number }> {
  try {
    const { count: premadeCount, error: premadeError } = await supabase
      .from('user_flashcard_progress')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('course_id', courseId);
    if (premadeError) throw premadeError;

    const { count: customCount, error: customError } = await supabase
      .from('custom_flashcards')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('course_id', courseId);
    if (customError) throw customError;

    const totalCount = (premadeCount ?? 0) + (customCount ?? 0);
    return { cardsCompleted: totalCount };
  } catch (error) {
    return handleApiError(error);
  }
},

/**
 * Retrieve all user tags by scanning the user's custom flashcards.
 */
async getAllUserTags(userId: string, courseId: string): Promise<string[]> {
  try {
    const cCards = await this.getCustomFlashcards(userId, courseId);
    const tagsSet = new Set<string>();
    cCards.forEach((card: CustomFlashcard) => 
      card.tags.forEach((tag: string) => tagsSet.add(tag))
    );
    return Array.from(tagsSet);
  } catch (error) {
    return handleApiError(error);
  }
},

// END FLASHCARD FUNCTIONS

















//START lesson functions

  // Lesson Category methods
  async getLessonCategories(courseId: string): Promise<LessonCategory[]> {
    const { data, error } = await supabase
      .from('lesson_categories')
      .select('*')
      .eq('course_id', courseId)
      .order('category_number', { ascending: true }); // Add this line
    if (error) throw error;
    return data;
  },

  async getLessons(courseId: string, userId: string): Promise<(Lesson & { is_completed: boolean; confidence_level: string | null })[]> {
    const { data: userCourseAccess } = await supabase
      .from('user_course_access')
      .select('tier_id')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
    const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
    const isLimitedTier = userCourseAccess?.tier_id === freeTrialTierId || userCourseAccess?.tier_id === mentorOnlyTierId;
  
    const { data, error } = await supabase
      .from('lessons')
      .select(`
        *,
        notes:notes(*),
        user_lesson_responses!left(is_completed, confidence_level),
        lesson_categories!inner(category_number)
      `)
      .eq('course_id', courseId)
      .eq('user_lesson_responses.user_id', userId)
      .order('lesson_categories(category_number)', { ascending: true })
      .order('sequence_number', { ascending: true });
  
    if (error) throw error;
  
    return data.map(lesson => ({
      ...lesson,
      note: lesson.notes[0] || null,
      is_completed: lesson.user_lesson_responses[0]?.is_completed || false,
      confidence_level: lesson.user_lesson_responses[0]?.confidence_level || null,
      content: isLimitedTier && !lesson.free_trial_content ? null : lesson.content
    }));
  },

  // Update lesson status
// Update the function name to plural to indicate it returns multiple responses
async getUserLessonResponse(userId: string, courseId: string): Promise<UserLessonResponse[]> {
  // Use courseId instead of lessonId to fetch all responses for the course
  const { data, error } = await supabase
    .from('user_lesson_responses')
    .select('*')
    .eq('user_id', userId)
    .eq('course_id', courseId);

  if (error) {
    console.error('Error fetching user lesson responses:', error);
    throw error;
  }

  // Return an empty array if no data is found
  return data || [];
},

  async updateLessonStatus(userId: string, courseId: string, lessonId: string, isCompleted: boolean, confidenceLevel: string | null): Promise<void> {
    const { error } = await supabase
      .from('user_lesson_responses')
      .upsert({
        user_id: userId,
        course_id: courseId,
        lesson_id: lessonId,
        is_completed: isCompleted,
        confidence_level: confidenceLevel,
        last_interaction_at: new Date().toISOString(),
      }, {
        onConflict: 'user_id,lesson_id'
      });

    if (error) {
      console.error('Error updating lesson status:', error);
      throw error;
    }
  },

  async getNoteForLesson(userId: string, courseId: string, lessonId: string): Promise<Note | null> {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('lesson_id', lessonId)
        .order('created_at', { ascending: false })
        .limit(1);
  
      if (error) throw error;
  
      // Return the first note if it exists, otherwise return null
      return data.length > 0 ? data[0] : null;
    } catch (error) {
      console.error('Error fetching note for lesson:', error);
      throw error;
    }
  },

  //end lesson functions












// Simulations
//TBS SIMULATIONS START

 // Simulation Category methods (unchanged)
 async getSimulationCategories(courseId: string): Promise<SimulationCategory[]> {
  const { data, error } = await supabase
    .from('simulation_categories')
    .select('*')
    .eq('course_id', courseId);
  if (error) throw error;
  return data;
},

// Updated Simulation methods
async getSimulations(courseId: string): Promise<Simulation[]> {
  const { data, error } = await supabase
    .from('simulations')
    .select('*')  // Select all fields to match the Simulation interface
    .eq('course_id', courseId);
  if (error) throw error;
  return data;
},

async getSimulation(simulationId: string): Promise<Simulation> {
  const { data, error } = await supabase
    .from('simulations')
    .select('*')
    .eq('id', simulationId)
    .single();
  if (error) throw error;
  return data;
},

async getActiveSimulation(userId: string, courseId: string): Promise<UserTBSResponse | null> {
  try {
    const { data, error } = await supabase
      .from('user_tbs_responses')
      .select(`
        *,
        simulations:simulations!left ( 
          id,
          course_id,
          question_category_id,
          free_trial_content
        )
      `)
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .eq('completed', false)
      .order('created_at', { ascending: false })
      .limit(1)
      .maybeSingle();

    if (error) {
      console.error('Error checking for active simulation:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error in getActiveSimulation:', error);
    return null;
  }
},

  // Reset specific simulations
  async resetSelectedSimulations(
    userId: string,
    courseId: string,
    simulationIds: string[]
  ): Promise<void> {
    try {
      logger.debug('Resetting selected simulations:', { userId, courseId, simulationIds });
      
      // First delete TBS responses
      const { error: tbsError } = await supabase
        .from('user_tbs_responses')
        .delete()
        .eq('user_id', userId)
        .in('simulation_id', simulationIds);

      if (tbsError) throw tbsError;

      // Then update existing confidence levels
      const { data: existingConfidenceLevels, error: fetchError } = await supabase
        .from('user_confidence_levels')
        .select('*')
        .eq('user_id', userId)
        .in('simulation_id', simulationIds);

      if (fetchError) throw fetchError;

      // For existing records, append null to confidence history
      if (existingConfidenceLevels && existingConfidenceLevels.length > 0) {
        const updatePromises = existingConfidenceLevels.map(async (cl) => {
          const newHistoryEntry = { level: null, timestamp: new Date().toISOString() };
          const updatedHistory = [...(cl.confidence_history || []), newHistoryEntry];
          
          const { error: updateError } = await supabase
            .from('user_confidence_levels')
            .update({
              confidence_history: updatedHistory,
              updated_at: new Date().toISOString()
            })
            .eq('user_id', userId)
            .eq('simulation_id', cl.simulation_id);

          if (updateError) throw updateError;
        });

        await Promise.all(updatePromises);
      }

      // For simulations without confidence levels, insert new records
      const existingSimIds = new Set(existingConfidenceLevels?.map(cl => cl.simulation_id) || []);
      const newSimIds = simulationIds.filter(id => !existingSimIds.has(id));

      if (newSimIds.length > 0) {
        const { error: insertError } = await supabase
          .from('user_confidence_levels')
          .insert(
            newSimIds.map(simId => ({
              user_id: userId,
              course_id: courseId,
              simulation_id: simId,
              confidence_history: [{ level: null, timestamp: new Date().toISOString() }],
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString()
            }))
          );

        if (insertError) throw insertError;
      }

      // Invalidate all caches after reset
      await this.invalidateSimulationCaches();
      
      logger.debug('Successfully reset selected simulations');
    } catch (error) {
      console.error('Error resetting selected simulations:', error);
      throw error;
    }
  },

  // Main reset function
  async resetSimulations(
    userId: string,
    courseId: string,
    categoryIds?: string[]
  ): Promise<void> {
    try {
      if (categoryIds && categoryIds.length > 0) {
        // Get simulation IDs for the categories
        const { data: simulationIds, error: fetchError } = await supabase
          .from('simulations')
          .select('id')
          .eq('course_id', courseId)
          .in('question_category_id', categoryIds);

        if (fetchError) throw fetchError;

        if (simulationIds && simulationIds.length > 0) {
          // Reset specific simulations and their caches
          await this.resetSelectedSimulations(
            userId,
            courseId,
            simulationIds.map(s => s.id)
          );
        }
      } else {
        // Reset all simulations for the course
        const { error } = await supabase.rpc('reset_simulations', {
          p_user_id: userId,
          p_course_id: courseId
        });

        if (error) throw error;
        
        // Invalidate all caches after full reset
        await this.invalidateSimulationCaches();
      }
    } catch (error) {
      console.error('Error resetting simulations:', error);
      throw error;
    }
  },

  async getAllUserTBSResponses(userId: string, courseId: string): Promise<BatchUserTBSResponse> {
    try {
      const { data, error } = await supabase
        .from('user_tbs_responses')
        .select(`
          *,
          simulations!inner (
            id,
            free_trial_content
          )
        `)
        .eq('user_id', userId)
        .eq('course_id', courseId);

      if (error) {
        console.error('Error fetching user TBS responses:', error);
        return {};
      }

      // Transform array of responses into a map keyed by simulation_id
      return (data || []).reduce((acc: BatchUserTBSResponse, response) => {
        acc[response.simulation_id] = {
          ...response,
          answers: response.answers || {},
          feedback: response.feedback || [],
          question_results: response.question_results || [],
          free_trial_content: response.simulations.free_trial_content,
          time_spent: response.time_spent || 0
        };
        return acc;
      }, {});
    } catch (error) {
      console.error('Error in getAllUserTBSResponses:', error);
      return {};
    }
  },

  // Update existing getUserTBSResponse to use a cached responses object
  async getUserTBSResponse(
    userId: string, 
    simulationId: string, 
    courseId: string, 
    cachedResponses?: BatchUserTBSResponse
  ): Promise<UserTBSResponse | null> {
    // If we have cached responses, use those instead of making a new query
    if (cachedResponses) {
      const response = cachedResponses[simulationId];
      if (!response) {
        logger.debug('No existing TBS response found for this user and simulation.');
        return null;
      }
      return response;
    }

    // Fall back to original single-response query if no cache provided
    try {
      const { data, error } = await supabase
        .from('user_tbs_responses')
        .select(`
          *,
          simulations!inner (
            id,
            free_trial_content
          )
        `)
        .eq('user_id', userId)
        .eq('simulation_id', simulationId)
        .eq('course_id', courseId)
        .maybeSingle();

      if (error || !data) {
        console.error('Error fetching user TBS response:', error);
        return null;
      }

      return {
        ...data,
        answers: data.answers || {},
        feedback: data.feedback || [],
        question_results: data.question_results || [],
        free_trial_content: data.simulations.free_trial_content,
        time_spent: data.time_spent || 0
      };
    } catch (error) {
      console.error('Error in getUserTBSResponse:', error);
      return null;
    }
  },

  async getSimulationConfidenceCounts(
    userId: string,
    courseId: string,
    categoryIds: string[]
  ): Promise<FilterCounts> {
    try {
      const { data: responses, error } = await supabase
        .from('user_tbs_responses')
        .select(`
          *,
          simulations!inner (
            id,
            question_category_id
          )
        `)
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .in('simulations.question_category_id', categoryIds);
  
      if (error) throw error;
  
      const counts: FilterCounts = {
        all: responses.length,
        correct: 0,
        incorrect: 0,
        confident: 0,
        maybe: 0,
        guessing: 0
      };
  
      responses.forEach(response => {
        // Count by correctness
        if (response.score >= 70) counts.correct++;
        else counts.incorrect++;
  
        // Count by confidence level
        switch (response.confidence_level) {
          case 'confident':
            counts.confident++;
            break;
          case 'maybe':
            counts.maybe++;
            break;
          case 'guessing':
            counts.guessing++;
            break;
        }
      });
  
      return counts;
    } catch (error) {
      console.error('Error getting simulation confidence counts:', error);
      throw error;
    }
  },
  
  async getSimulationStats(
    userId: string,
    courseId: string,
    categoryIds: string[]
  ): Promise<QuizStats> {
    try {
      const { data: responses, error } = await supabase
        .from('user_tbs_responses')
        .select(`
          *,
          simulations!inner (
            id,
            question_category_id
          )
        `)
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .in('simulations.question_category_id', categoryIds);
  
      if (error) throw error;
  
      const stats: QuizStats = {
        totalQuestions: responses.length,
        unansweredQuestions: 0,
        incorrectQuestions: 0,
        confidenceLevels: {
          guessing: 0,
          maybe: 0,
          confident: 0
        }
      };
  
      responses.forEach(response => {
        if (!response.completed) {
          stats.unansweredQuestions++;
        } else if (response.score < 70) {
          stats.incorrectQuestions++;
        }
  
        if (response.confidence_level) {
          stats.confidenceLevels[response.confidence_level as keyof typeof stats.confidenceLevels]++;
        }
      });
  
      return stats;
    } catch (error) {
      console.error('Error getting simulation stats:', error);
      throw error;
    }
  },

  // Helper function to format numbers with commas
  formatNumberWithCommas(value: string | number): string {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(num)) return value.toString();
    if (Math.abs(num) >= 1000) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
    return num.toString();
  },

  // Helper function to recursively format numbers in an object
  formatObjectNumbers(obj: Record<string, any>): Record<string, any> {
    const formatted: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        formatted[key] = this.formatObjectNumbers(value);
      } else if (typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value)))) {
        formatted[key] = this.formatNumberWithCommas(value);
      } else {
        formatted[key] = value;
      }
    }
    return formatted;
  },

  async submitUserTBSResponse(
    userId: string,
    simulationId: string,
    answers: Record<string, any>,
    confidenceLevel: 'guessing' | 'maybe' | 'confident',
    courseId?: string,
    timeSpent?: number
  ): Promise<UserTBSResponse | null> {
    try {
      const formattedAnswers = this.formatObjectNumbers(answers);
      logger.debug('Submitting user TBS response:', { 
        userId, 
        simulationId, 
        courseId,
        timeSpent: Math.round(timeSpent || 0),
        formattedAnswers 
      });
  
      const { data: simulation, error: simulationError } = await supabase
        .from('simulations')
        .select('correct_answer, free_trial_content, course_id')
        .eq('id', simulationId)
        .single();
  
      if (simulationError) {
        console.error('Error fetching simulation:', simulationError);
        return null;
      }
  
      const effectiveCourseId = courseId || simulation.course_id;
  
      // Update confidence level
      try {
        const { error: confidenceError } = await supabase
          .rpc('update_simulation_confidence_level', {
            p_user_id: userId,
            p_course_id: effectiveCourseId,
            p_simulation_id: simulationId,
            p_confidence_level: confidenceLevel
          });
  
        if (confidenceError) {
          console.error('Error updating confidence levels:', confidenceError);
        }
      } catch (confidenceError) {
        console.error('Error handling confidence level update:', confidenceError);
      }
  
      // Call grade_tbs with correct parameter order
      const { data: gradingResult, error: gradingError } = await supabase.rpc('grade_tbs', {
        p_user_id: userId,
        p_course_id: effectiveCourseId,
        p_simulation_id: simulationId,
        p_time_spent: Math.round(timeSpent || 0),
        p_user_answers: formattedAnswers
      });
  
      if (gradingError) {
        console.error('Error grading TBS simulation:', gradingError);
        return null;
      }
  
      logger.debug('Graded TBS response:', gradingResult);
  
      if (!gradingResult) {
        throw new Error('Invalid response from grading function');
      }
  
      // Handle both array and object response formats
      const result = Array.isArray(gradingResult) ? gradingResult[0] : gradingResult;
      
      if (!result.feedback && !result.feedback_array) {
        throw new Error('Feedback missing from grading result');
      }
  
      const feedbackArray = result.feedback_array || result.feedback;
      logger.debug('Feedback array:', feedbackArray);
  
      if (!Array.isArray(feedbackArray)) {
        throw new Error('Invalid feedback format from grading function');
      }
  
      const processedFeedback = feedbackArray
        .filter((item: FeedbackItem) => {
          return item.questionId !== null && 
                item.questionId !== 'totals' && 
                item.userAnswer !== null;
        })
        .map((item: FeedbackItem) => {
          // Keep the original answerFeedback
          const originalAnswerFeedback = item.answerFeedback || {};
          
          // Calculate scores without modifying the original feedback
          let itemScore = 0;
          const maxScore = Object.keys(item.userAnswer || {}).length;

          Object.entries(item.userAnswer || {}).forEach(([key, userValue]) => {
            const correctValue = item.correctAnswer[key];
            const isCorrect = String(userValue).toLowerCase() === String(correctValue).toLowerCase();
            if (isCorrect) itemScore += 1;
          });

          const scorePercentage = maxScore > 0 ? (itemScore / maxScore) * 100 : 0;
          const explanation = itemScore === maxScore 
            ? 'Correct! All parts are correct.'
            : itemScore === 0 
            ? 'Incorrect. Please review all parts.'
            : 'Partially correct. Please review the incorrect parts.';

          // Return combined object with original feedback preserved
          return {
            ...item,
            score: itemScore,
            maxScore,
            scorePercentage,
            explanation,
            answerFeedback: originalAnswerFeedback  // Preserve the original feedback
          };
        });
  
      const overallScore = result.score || (
        processedFeedback.reduce((sum: number, item: FeedbackItem) => 
          sum + item.scorePercentage, 0) / processedFeedback.length
      );
  
      const userTBSResponse = {
        user_id: userId,
        simulation_id: simulationId,
        course_id: effectiveCourseId,
        answers: formattedAnswers,
        score: overallScore,
        completed: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        feedback: processedFeedback,
        confidence_level: confidenceLevel,
        time_spent: Math.round(timeSpent || 0),
        question_results: result.question_results || processedFeedback.map((item: FeedbackItem) => ({
          questionId: item.questionId,
          score: item.score,
          maxScore: item.maxScore,
          isCorrect: item.score === item.maxScore
        }))
      };
  
      const { data: savedResponse, error: saveError } = await supabase
        .from('user_tbs_responses')
        .upsert(userTBSResponse, {
          onConflict: 'user_id,simulation_id,course_id'
        })
        .select(`
          *,
          simulations!inner (
            id,
            free_trial_content
          )
        `)
        .single();
  
      if (saveError) {
        console.error('Error saving graded TBS response:', saveError);
        return null;
      }
  
      return {
        ...savedResponse,
        completed: true,
        free_trial_content: savedResponse.simulations.free_trial_content
      };
    } catch (error) {
      console.error('Error in submitUserTBSResponse:', error);
      throw error; // Changed to throw error instead of return null for better error handling
    }
  },

  // Grade TBS simulation
  async gradeSimulation(userId: string, simulationId: string, userAnswers: Record<string, any>): Promise<{
    score: number;
    feedback: any[];
    question_results: any[];
  } | null> {
    try {
      logger.debug('Grading simulation with params:', { userId, simulationId, userAnswers });
      
      const { data, error } = await supabase.rpc('grade_tbs', {
        p_user_id: userId,
        p_simulation_id: simulationId,
        p_user_answers: userAnswers
      });
  
      if (error) {
        console.error('Error grading simulation:', error);
        if (error.code === 'PGRST203') {
          console.error('Multiple grade_tbs functions detected. Please remove one from the database.');
        }
        return null;
      }
  
      logger.debug('Raw data from grade_tbs:', data);
  
      // Handle the single row return type from PostgreSQL function
      if (data && typeof data === 'object' && 'score' in data) {
        return {
          score: data.score,
          feedback: data.feedback || [],
          question_results: data.question_results || []
        };
      }
  
      console.error('Invalid data structure returned from grade_tbs:', data);
      return null;
    } catch (error) {
      console.error('Error in gradeSimulation:', error);
      return null;
    }
  },

  async saveSimulationGradingResults(userId: string, simulationId: string, results: any): Promise<void> {
    const answersToSave = results.userAnswers || {};
    
    const { error } = await supabase
      .from('user_tbs_responses')
      .upsert({
        user_id: userId,
        simulation_id: simulationId,
        answers: answersToSave,
        score: results.score,
        feedback: results.feedback,
        question_results: results.questionResults,
        completed: true,
        updated_at: new Date().toISOString()
      }, {
        onConflict: 'user_id,simulation_id'
      });

    if (error) {
      console.error('Error saving simulation results:', error);
      throw error;
    }
  },

  async getSimulationGradingResults(userId: string, simulationId: string): Promise<any | null> {
    const { data, error } = await supabase
      .from('user_tbs_responses')
      .select('*')
      .eq('user_id', userId)
      .eq('simulation_id', simulationId)
      .single();

    if (error) {
      console.error('Error fetching simulation grading results:', error);
      return null;
    }

    if (data) {
      return {
        score: data.score,
        feedback: data.feedback,
        questionResults: data.question_results,
        userAnswers: data.answers
      };
    }

    return null;
  },

  // Add these functions to extend the existing TBS API functionality

  async getUserTBSResponses(userId: string, courseId: string): Promise<UserTBSResponse[]> {
    try {
      const { data, error } = await supabase
        .from('user_tbs_responses')
        .select(`
          *,
          simulations!inner (
            id,
            course_id,
            free_trial_content
          )
        `)
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .order('created_at', { ascending: false });
  
      if (error) throw error;
  
      return data.map(response => ({
        ...response,
        answers: response.answers || {},
        feedback: response.feedback || [],
        question_results: response.question_results || [],
        free_trial_content: response.simulations?.free_trial_content || false,
        time_spent: response.time_spent || 0,
        completed: response.completed || false, // Explicitly include completed
        id: response.id
      }));
    } catch (error) {
      console.error('Error fetching user TBS responses:', error);
      return [];
    }
  },
  
  async endTBSSession(quizId: string, totalTime: number): Promise<void> {
    if (!quizId) {
      throw new Error('Quiz ID is required');
    }
  
    try {
      // Fetch quiz data
      const { data: quizData, error: fetchError } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('id', quizId)
        .single();
  
      if (fetchError) throw fetchError;

      const answeredQuestions = quizData.questions_data
        .filter((q: SimulationQuestionData | MockExamSimulationQuestionData) => 
          (q.type === 'simulation' || q.type === 'mock_exam_sim') && 
          q.answered && 
          q.score !== undefined && 
          q.score !== null &&
          q.feedback && 
          Array.isArray(q.feedback) && 
          q.feedback.length > 0
        );
      
      const totalAnswered = answeredQuestions.length;
      const correctAnswers = answeredQuestions
        .filter((q: SimulationQuestionData | MockExamSimulationQuestionData) => q.score >= 70).length;
      const percentageCorrect = totalAnswered > 0 
        ? (correctAnswers / totalAnswered) * 100 
        : 0;
  
      const totalTimeAnswered = answeredQuestions.reduce(
        (sum: number, q: SimulationQuestionData | MockExamSimulationQuestionData) => 
          sum + (q.timeSpent || 0), 
        0
      );
  
      // Update quiz history
      const { error: updateError } = await supabase
        .from('quiz_history')
        .update({
          completed: true,
          end_time: new Date().toISOString(),
          total_questions: totalAnswered,
          correct_answers: correctAnswers,
          incorrect_answers: totalAnswered - correctAnswers,
          percentage_correct: percentageCorrect,
          questions_data: answeredQuestions,
          total_time: totalTimeAnswered
        })
        .eq('id', quizId);
  
      if (updateError) throw updateError;
  
      // Update average time if there are answered questions
      if (totalAnswered > 0) {
        const avgTimePerQuestion = totalTimeAnswered / totalAnswered;
        const { error: avgTimeUpdateError } = await supabase
          .from('quiz_history')
          .update({ avg_time_per_question: avgTimePerQuestion })
          .eq('id', quizId);
  
        if (avgTimeUpdateError) {
          console.error('Error updating average time:', avgTimeUpdateError);
        }
      }
      
      // Update TBS responses
      const simulationIds = answeredQuestions.map(
        (q: SimulationQuestionData | MockExamSimulationQuestionData) => q.simulationId
      );
  
      if (simulationIds.length > 0) {
        const { error: tbsUpdateError } = await supabase
          .from('user_tbs_responses')
          .update({
            completed: true,
            updated_at: new Date().toISOString()
          })
          .in('simulation_id', simulationIds);
  
        if (tbsUpdateError) {
          console.error('Error updating TBS responses:', tbsUpdateError);
        }
      }

      // Invalidate relevant caches
      await this.invalidateSimulationCaches();

  } catch (error) {
    console.error('Error ending TBS session:', error);
    throw error;
  }
},

  async getSimulationReviewData(courseId: string, userId: string): Promise<SimulationResult[]> {
    try {
      const { data, error } = await supabase
        .from('user_tbs_responses')
        .select(`
          *,
          simulations!inner (
            id,
            course_id,
            question_content,
            html,
            exhibits,
            answer_content,
            free_trial_content
          )
        `)
        .eq('user_id', userId)
        .eq('simulations.course_id', courseId)
        .eq('completed', true)
        .order('created_at', { ascending: false });
  
      if (error) throw error;
  
      return data.map(response => ({
        simulation: response.simulations,
        userAnswers: response.answers || {},
        score: response.score,
        feedback: response.feedback || [],
        confidenceLevel: response.confidence_level || 'guessing',
        timeSpent: 0 // You might want to store this in the database
      }));
    } catch (error) {
      console.error('Error fetching simulation review data:', error);
      return [];
    }
  },

  async getSimulationsForCustomPractice(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    simulationCount: number,
    userId: string
  ): Promise<MergedSimulation[]> {
    try {
      logger.debug('Getting simulations for custom practice:', {
        courseId, 
        categoryIds, 
        keslerQFilter, 
        requestedCount: simulationCount, 
        userId
      });
  
      if (!categoryIds || categoryIds.length === 0) {
        console.error('No category IDs provided');
        return [];
      }
  
      // Call the stored procedure with correct parameter order
      const { data: filteredSimulations, error: filterError } = await supabase
        .rpc('get_filtered_simulation_ids', {
          p_category_ids: categoryIds,
          p_course_id: courseId,
          p_kesler_q_filter: keslerQFilter,
          p_simulation_count: simulationCount,
          p_user_id: userId
        }) as { 
          data: FilteredSimulationResult[] | null; 
          error: any; 
        };
  
      if (filterError) {
        console.error('Error getting filtered simulations:', filterError);
        throw filterError;
      }
  
      if (!filteredSimulations || filteredSimulations.length === 0) {
        return [];
      }
  
      // Process filtered simulations
      const selectedSimulationIds = filteredSimulations.map(s => s.simulation_id);
  
      // Create a map for sequence info and confidence data
      const simulationSequenceMap = filteredSimulations.reduce<Record<string, {
        sequence_order: number;
        confidence_level: string | null;
        confidence_history: ConfidenceHistoryEntry[];
      }>>((acc, sim) => ({
        ...acc,
        [sim.simulation_id]: {
          sequence_order: sim.sequence_order,
          confidence_level: sim.confidence_level,
          confidence_history: sim.confidence_history
        }
      }), {});
  
      // Reset if needed - only reset when not filtering for unanswered
      if (!keslerQFilter.includes('unanswered')) {
        await this.resetSelectedSimulations(userId, courseId, selectedSimulationIds);
      }
  
      // Get full simulation data with related information
      const { data: simulationsData, error: simError } = await supabase
        .from('simulations')
        .select(`
          *,
          user_tbs_responses (
            confidence_level,
            score
          ),
          user_confidence_levels (
            confidence_level,
            confidence_history
          )
        `)
        .in('id', selectedSimulationIds);
  
      if (simError) {
        console.error('Error fetching simulation data:', simError);
        throw simError;
      }
  
      if (!simulationsData) {
        console.warn('No simulation data found');
        return [];
      }
  
      // Map and merge the data
      const mergedSimulations = simulationsData.map((sim: any): MergedSimulation => {
        const sequenceInfo = simulationSequenceMap[sim.id];
        const tbsResponse = sim.user_tbs_responses?.[0];
        const confidenceLevel = sim.user_confidence_levels?.[0];
  
        return {
          ...sim,
          confidenceHistory: sequenceInfo?.confidence_history || confidenceLevel?.confidence_history || [],
          currentConfidenceLevel: tbsResponse?.confidence_level || confidenceLevel?.confidence_level || null,
          confidenceLevel: tbsResponse?.confidence_level || confidenceLevel?.confidence_level || null,
          sequence_order: sequenceInfo?.sequence_order || 0
        };
      });
  
      // Sort by sequence order and return
      return mergedSimulations.sort((a, b) => a.sequence_order - b.sequence_order);
  
    } catch (error) {
      console.error('Error in getSimulationsForCustomPractice:', error);
      throw error;
    }
  },

  async getSimulationsById(simulationIds: string[], userId: string, courseId: string): Promise<Simulation[]> {
    const { data, error } = await supabase
      .from('simulations')
      .select('*')
      .in('id', simulationIds);
  
    if (error) {
      console.error('Error fetching simulations by ID:', error);
      throw error;
    }
  
    return data as Simulation[];
  }, 

  async getFilteredSimulationIds(
    userId: string,
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[]
  ): Promise<FilteredSimulationResult[]> {
    try {
      const { data, error } = await supabase
        .rpc('get_filtered_simulation_ids', {
          p_user_id: userId,
          p_course_id: courseId,
          p_category_ids: categoryIds,
          p_kesler_q_filter: keslerQFilter
        });
  
      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error('Error getting filtered simulation IDs:', error);
      throw error;
    }
  },

  // In your api service file
  async getAvailableSimulationCounts(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    userId: string
  ): Promise<ExtendedSimulationStats> {
    const cacheKey = CACHE_KEYS.SIMULATION_COUNTS(courseId, categoryIds, keslerQFilter, userId);
    
    try {
      const { data: userCourseAccess, error: accessError } = await supabase
        .from('user_course_access')
        .select('tier_id')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();
  
      if (accessError) throw accessError;
  
      const isLimitedTier = 
        userCourseAccess?.tier_id === TIER_IDS.FREE_TRIAL ||
        userCourseAccess?.tier_id === TIER_IDS.MENTOR_ONLY;
  
      const { data: rawData, error: countError } = await supabase.rpc(
        'get_available_simulation_counts',
        {
          p_course_id: courseId,
          p_category_ids: categoryIds,
          p_kesler_q_filter: keslerQFilter,
          p_user_id: userId,
          p_is_limited_tier: isLimitedTier
        }
      );
  
      if (countError) throw countError;
  
      const stats = rawData || DEFAULT_STATS;
      const formattedStats: ExtendedSimulationStats = {
        total: stats.total || 0,
        unanswered: stats.unanswered || 0,
        correct: stats.correct || 0,
        incorrect: stats.incorrect || 0,
        confident: stats.confident || 0,
        maybe: stats.maybe || 0,
        guessing: stats.guessing || 0,
        confident_correct: stats.confident_correct || 0,
        confident_incorrect: stats.confident_incorrect || 0,
        maybe_correct: stats.maybe_correct || 0,
        maybe_incorrect: stats.maybe_incorrect || 0,
        guessing_correct: stats.guessing_correct || 0,
        guessing_incorrect: stats.guessing_incorrect || 0
      };
  
      // Update both React Query and local cache
      queryClient.setQueryData(cacheKey, formattedStats);
      cacheUtils.set(JSON.stringify(cacheKey), formattedStats);
      
      return formattedStats;
    } catch (error) {
      console.error('API: Error fetching available simulation counts:', error);
      throw error;
    }
  },

  // New method to invalidate simulation-related caches
  async invalidateSimulationCaches(): Promise<void> {
    try {
      // Invalidate React Query caches
      await queryClient.invalidateQueries(['simulationData']);
      await queryClient.invalidateQueries(['simulationCounts']);
      await queryClient.invalidateQueries(['categoryStats']);
      
      // Clear local cache for simulation-related entries
      cacheUtils.clear('simulation_');
      
      // Clear session storage for simulation-related data
      Object.keys(sessionStorage).forEach(key => {
        if (key.startsWith('simulation_')) {
          sessionStorage.removeItem(key);
        }
      });
    } catch (error) {
      console.error('Error invalidating simulation caches:', error);
    }
  },

  async getSimulationCategoryStats(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    userId: string
  ): Promise<SimulationCategoryStats[]> {
    try {
      // Check tier access
      const { data: userCourseAccess, error: accessError } = await supabase
        .from('user_course_access')
        .select('tier_id')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();
  
      if (accessError) throw accessError;
  
      const isLimitedTier = 
        userCourseAccess?.tier_id === TIER_IDS.FREE_TRIAL ||
        userCourseAccess?.tier_id === TIER_IDS.MENTOR_ONLY;
  
      // PostgreSQL function returns JSON type, so we need to handle the response accordingly
      const { data: rawData, error: countError } = await supabase
        .rpc('get_simulation_counts', {
          p_course_id: courseId,
          p_category_ids: categoryIds,
          p_kesler_q_filter: keslerQFilter,
          p_user_id: userId,
          p_is_limited_tier: isLimitedTier
        });
  
      if (countError) throw countError;
  
      // Ensure rawData is an array and type it correctly
      const statsArray = Array.isArray(rawData) ? rawData : [];
      
      // Transform the raw data into properly typed response
      return statsArray.map((item) => ({
        parent_category: item.parent_category,
        category_stats: {
          total: item.category_stats.total || 0,
          unanswered: item.category_stats.unanswered || 0,
          correct: item.category_stats.correct || 0,
          incorrect: item.category_stats.incorrect || 0,
          confident: item.category_stats.confident || 0,
          maybe: item.category_stats.maybe || 0,
          guessing: item.category_stats.guessing || 0,
          confident_correct: item.category_stats.confident_correct || 0,
          confident_incorrect: item.category_stats.confident_incorrect || 0,
          maybe_correct: item.category_stats.maybe_correct || 0,
          maybe_incorrect: item.category_stats.maybe_incorrect || 0,
          guessing_correct: item.category_stats.guessing_correct || 0,
          guessing_incorrect: item.category_stats.guessing_incorrect || 0
        }
      }));
    } catch (error) {
      console.error('API: Error fetching simulation category stats:', error);
      throw error;
    }
  },

  // TBS NOTE SECTION

  // Get note for a specific simulation
  async getSimulationNote(userId: string, courseId: string, simulationId: string): Promise<Note | null> {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('simulation_id', simulationId)
        .maybeSingle();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching simulation note:', error);
      return null;
    }
  },

  // Create or update a simulation note
  async upsertSimulationNote(
    userId: string,
    courseId: string,
    simulationId: string,
    content: string
  ): Promise<Note | null> {
    try {
      const { data, error } = await supabase
        .from('notes')
        .upsert({
          user_id: userId,
          course_id: courseId,
          simulation_id: simulationId,
          content,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,course_id,simulation_id'
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error upserting simulation note:', error);
      return null;
    }
  },

  // Delete a simulation note
  async deleteSimulationNote(noteId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('notes')
        .delete()
        .eq('id', noteId);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting simulation note:', error);
      throw error;
    }
  },
  


// END TBS




















// FOCUS NOTES

  // Note methods
  
  async getNotes(
    userId: string,
    courseId: string,
    noteType: string | null = null,
    page: number = 1,
    limit: number = 25
  ): Promise<{ notes: ExtendedNote[], total: number }> {
    try {
      let query = supabase
        .from('notes')
        .select(`
          *,
          lessons:lessons (
            name,
            free_trial_content,
            lesson_categories:lesson_categories (
              name,
              parent_lesson_category_id
            )
          ),
          questions:questions (
            question_category_name,
            sub_question_category_name,
            free_trial_content
          ),
          simulations:simulations (
            free_trial_content
          ),
          mentor_modules:mentor_modules (
            title,
            free_trial_content
          )
        `, { count: 'exact' })
        .eq('user_id', userId);

      // Apply content type filters
      if (noteType === 'Mentor') {
        query = query.not('module_id', 'is', null);
      } else if (noteType === 'General') {
        query = query
          .eq('course_id', courseId)
          .is('question_id', null)
          .is('lesson_id', null)
          .is('simulation_id', null)
          .is('module_id', null);
      } else if (noteType) {
        query = query.eq('course_id', courseId);
        switch (noteType) {
          case 'Quiz':
            query = query.not('question_id', 'is', null);
            break;
          case 'Lesson':
            query = query.not('lesson_id', 'is', null);
            break;
          case 'Simulation':
            query = query.not('simulation_id', 'is', null);
            break;
        }
      } else {
        query = query.or(`course_id.eq.${courseId},module_id.not.is.null`);
      }

      query = query
        .order('created_at', { ascending: false })
        .range((page - 1) * limit, page * limit - 1);

      const { data, error, count } = await query;

      if (error) throw error;

      const processedNotes = data.map(note => ({
        ...note,
        lesson_name: note.lessons?.name,
        lesson_category: note.lessons?.lesson_categories?.name,
        lesson_subcategory: note.lessons?.lesson_categories?.parent_lesson_category_id 
          ? note.lessons?.lesson_categories?.name 
          : null,
        question_category: note.questions?.question_category_name,
        sub_question_category: note.questions?.sub_question_category_name,
        module_title: note.mentor_modules?.title,
        // Add helper properties for free trial content checking
        is_free_trial_content: 
          (note.question_id && note.questions?.free_trial_content) ||
          (note.lesson_id && note.lessons?.free_trial_content) ||
          (note.simulation_id && note.simulations?.free_trial_content) ||
          (note.module_id && note.mentor_modules?.free_trial_content) ||
          false
      }));

      return {
        notes: processedNotes,
        total: count || 0
      };
    } catch (error) {
      console.error('Error fetching notes:', error);
      throw error;
    }
  },

  async createNote(note: Omit<Note, 'id' | 'created_at' | 'updated_at'>): Promise<Note> {
    logger.debug('createNote function called with:', note);
    try {
      const { data, error } = await supabase
        .from('notes')
        .insert(note)
        .select()
        .single();
      
      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }
      
      if (!data) {
        console.error('No data returned from Supabase');
        throw new Error('Note creation failed: No data returned');
      }
      
      logger.debug('Note created successfully:', data);
      return data;
    } catch (error) {
      console.error('Error in createNote:', error);
      throw error;
    }
  },

  // Note methods
  async updateNote(
    noteId: string | null, 
    updates: Partial<Note>, 
    userId: string, 
    courseId: string | null, // Made courseId optional
    questionId: string | null, 
    lessonId: string | null,
    moduleId: string | null,
    simulationId: string | null
  ): Promise<Note> {
    logger.debug('updateNote called with:', { 
      noteId, 
      updates, 
      userId, 
      courseId, 
      questionId, 
      lessonId, 
      moduleId, 
      simulationId 
    });
    
    try {
      // Start with required userId
      const noteData: Partial<Note> = {
        user_id: userId,
        ...updates
      };
  
      // Add optional fields only if they're provided
      if (courseId) noteData.course_id = courseId;
      if (questionId) noteData.question_id = questionId;
      if (lessonId) noteData.lesson_id = lessonId;
      if (moduleId) noteData.module_id = moduleId;
      if (simulationId) noteData.simulation_id = simulationId;
  
      // Remove undefined or null fields
      Object.keys(noteData).forEach(key => {
        if (noteData[key as keyof Note] === undefined || noteData[key as keyof Note] === null) {
          delete noteData[key as keyof Note];
        }
      });
  
      // If we have a noteId, do an update
      if (noteId) {
        const { data, error } = await supabase
          .from('notes')
          .update(noteData)
          .eq('id', noteId)
          .select()
          .single();
        
        if (error) {
          console.error('Error updating note:', error);
          throw error;
        }
        
        if (!data) {
          console.error('No data returned when updating note');
          throw new Error('No data returned when updating note');
        }
        
        logger.debug('Note updated successfully:', data);
        return data;
      } else {
        // If no noteId, do an insert
        const { data, error } = await supabase
          .from('notes')
          .insert(noteData)
          .select()
          .single();
        
        if (error) {
          console.error('Error inserting note:', error);
          throw error;
        }
        
        if (!data) {
          console.error('No data returned when inserting note');
          throw new Error('No data returned when inserting note');
        }
        
        logger.debug('Note inserted successfully:', data);
        return data;
      }
    } catch (error) {
      console.error('Error in updateNote:', error);
      throw error;
    }
  },

  async deleteNote(noteId: string): Promise<void> {
    const { error } = await supabase
      .from('notes')
      .delete()
      .eq('id', noteId);
    if (error) throw error;
  },

  // User Activity methods
  async logUserActivity(activity: Omit<UserActivity, 'id' | 'created_at'>): Promise<void> {
    const { error } = await supabase
      .from('user_activity')
      .insert(activity);
    if (error) throw error;
  },

  // User Progress methods
  async getUserProgress(userId: string, courseId: string): Promise<UserProgress> {
    const { data, error } = await supabase
      .from('user_progress')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
    if (error) throw error;
    return data;
  },

  async updateUserProgress(userId: string, courseId: string, progress: Partial<UserProgress>): Promise<void> {
    const { error } = await supabase
      .from('user_progress')
      .upsert({ user_id: userId, course_id: courseId, ...progress });
    if (error) throw error;
  },

  // Analytics methods
  async getUserAnalytics(userId: string, courseId: string): Promise<UserAnalytics> {
    // This is a placeholder. In a real application, you might need to aggregate data from multiple tables.
    const { data, error } = await supabase.rpc('get_user_analytics', { user_id: userId, course_id: courseId });
    if (error) throw error;
    return data;
  },

  async resetCategory(userId: string, courseId: string, categoryId: string): Promise<void> {
    logger.debug('Resetting category:', categoryId);
  
    const { data: questionIds, error: fetchError } = await supabase
      .from('questions')
      .select('id')
      .eq('question_category_id', categoryId);
  
    if (fetchError) {
      console.error('Error fetching questions:', fetchError);
      throw fetchError;
    }
  
    if (!questionIds || questionIds.length === 0) {
      logger.debug('No questions found for category:', categoryId);
      return; // No questions to reset
    }
  
    logger.debug(`Found ${questionIds.length} questions to reset`);
  
    // Delete all user responses for the category
    const { error: deleteError } = await supabase
      .from('user_question_responses')
      .delete()
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .in('question_id', questionIds.map(q => q.id));
  
    if (deleteError) {
      console.error('Error deleting user responses:', deleteError);
      throw deleteError;
    }
  
    logger.debug('Successfully reset category:', categoryId);
  },

  // Search method
  async searchContent(courseId: string, query: string): Promise<Array<Question | Flashcard | Lesson | Simulation>> {
    const { data, error } = await supabase.rpc('search_course_content', { course_id: courseId, search_query: query });
    if (error) throw error;
    return data;
  },

























// STUDY PLANNER FUNCTIONS



  // Study Task methods
  async getStudyTasks(courseId: string): Promise<StudyTask[]> {
    const { data, error } = await supabase
      .from('study_tasks')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

  // Study Task Group methods
  async getStudyTaskGroups(courseId: string): Promise<StudyTaskGroup[]> {
    const { data, error } = await supabase
      .from('study_task_groups')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

async getCPACourses(courseId: string): Promise<SPCourse[]> {
  const { data, error } = await supabase
    .from('sp_courses')
    .select('*')
    .eq('course_id', courseId);
  if (error) throw error;
  return data;
},

  async getTopics(CPACourseId: string): Promise<SPTopic[]> {
    const { data, error } = await supabase
      .from('sp_topics')
      .select('*')
      .eq('cpa_course_id', CPACourseId)
      .order('sequence_number');
    if (error) throw error;
    return data;
  },

  async getTopicMappings(mappingIds: string[]): Promise<SPTopicMapping[]> {
    const { data, error } = await supabase
      .from('sp_topicmappings')
      .select('*')
      .in('mapping_id', mappingIds);
    if (error) throw error;
    return data;
  },

  async createStudyType(request: CreateStudyTypeRequest): Promise<SPStudyType> {
    const { data, error } = await supabase
      .from('sp_studytype')
      .upsert({
        user_id: request.userId,
        course_id: request.courseId,
        study_type: request.studyType,
        bn_mental_state: request.bnMentalState || null,
        ip_mental_state: request.ipMentalState || null,
        f_mental_state: request.fMentalState || null,
        work_hours: request.workHours || null,
        accountability: request.accountability || null,
        kids: request.kids || null,
        struggle: request.struggle || null,
        survivor_shirt: request.survivorShirt || null
      }, {
        onConflict: 'user_id,course_id'
      })
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Failed to create or update study type');
    
    return data;
  },

  async createCourseMix(
    userId: string, 
    courseId: string, 
    primaryCPACourseId: string | null,
    secondaryCPACourseId: string | null,
    alias: string | null
  ): Promise<SPCourseMix> {
    const { data, error } = await supabase
      .from('sp_course_mix')
      .upsert(
        {
          user_id: userId,
          course_id: courseId,
          primary_cpa_course_id: primaryCPACourseId,
          secondary_cpa_course_id: secondaryCPACourseId,
          alias: alias
        },
        {
          onConflict: 'user_id,course_id',
          ignoreDuplicates: false
        }
      )
      .select()
      .single();
  
    if (error) {
      console.error('Error in createCourseMix:', error);
      throw error;
    }
  
    if (!data) {
      throw new Error('Failed to create or update course mix');
    }
  
    return data as SPCourseMix;
  },

  async getCourseMix(userId: string, courseId: string): Promise<SPCourseMix | null> {
    const { data, error } = await supabase
      .from('sp_course_mix')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    if (error) {
      if (error.code === 'PGRST116') {
        // No matching row found
        return null;
      }
      console.error('Error in getCourseMix:', error);
      throw error;
    }
  
    return data as SPCourseMix;
  },

  // CREATE STUDY PLAN
  async calculateStudyPlanAndExamDate(
    userId: string,
    courseId: string,
    startDate: string,
    studyType: 'brand_new' | 'retake',
    weeklyHours: '15' | '20' | '25' | '30',
    courseMixId: string,
    studyTypeId: string
  ): Promise<StudyPlanCalculation> {
    try {
      logger.debug('Calling calculate_study_plan_and_schedule with:', {
        userId,
        courseId,
        startDate,
        studyType,
        weeklyHours,
        courseMixId,
        studyTypeId
      });

      const { data, error } = await supabase.rpc('calculate_study_plan_and_schedule', {
        p_user_id: userId,
        p_course_id: courseId,
        p_start_date: startDate,
        p_study_type: studyType,
        p_weekly_hours: weeklyHours,
        p_course_mix_id: courseMixId,
        p_study_type_id: studyTypeId
      });

      if (error) {
        console.error('Supabase function error:', error);
        throw error;
      }

      logger.debug('Study plan calculation result:', data);

      return {
        calculatedExamDate: data.calculated_exam_date,
        totalStudyHours: data.total_study_hours,
        totalWeeks: data.total_weeks,
        planId: data.plan_id
      };
    } catch (error) {
      console.error('Error in calculateStudyPlanAndExamDate:', error);
      throw error;
    }
  },

  async updateSectionsPassed(userId: string, sectionsPassed: number): Promise<User> {
    // Validate the input
    if (sectionsPassed < 0 || sectionsPassed > 4) {
      throw new Error('Sections passed must be between 0 and 4');
    }
  
    const { data, error } = await supabase
      .from('users')
      .update({ sections_passed: sectionsPassed })
      .eq('id', userId)
      .single();
  
    if (error) {
      console.error('Error updating sections passed:', error);
      throw error;
    }
  
    return data;
  },

  async setStudyDays(userId: string, courseId: string, studyDays: Omit<SPStudyDays, 'study_days_id' | 'user_id' | 'course_id'>): Promise<SPStudyDays> {
    const { data, error } = await supabase
      .from('sp_studydays')
      .upsert({ user_id: userId, course_id: courseId, ...studyDays })
      .single();
    if (error) throw error;
    return data;
  },

  async getUserStudyDays(userId: string, courseId: string): Promise<SPStudyDays | null> {
    const { data, error } = await supabase
      .from('sp_studydays')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    if (error) {
      if (error.code === 'PGRST116') {
        // No record found
        return null;
      }
      throw error;
    }
  
    return data;
  },

  // Update the createUserStudyPlan function
  async createUserStudyPlan(
    userId: string, 
    courseId: string, 
    courseMixId: string, 
    studyTypeId: string, 
    recommendedWeeklyHours: '15' | '20' | '25' | '30'
  ): Promise<SPUserStudyPlan> {
    const { data, error } = await supabase
      .from('sp_userstudyplans')
      .upsert({
        user_id: userId,
        course_id: courseId,
        course_mix_id: courseMixId,
        study_type_id: studyTypeId,
        recommended_weekly_hours: recommendedWeeklyHours,
      }, {
        onConflict: 'user_id,course_id'
      })
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Failed to create user study plan');
    return data;
  },

  async getUserStudyPlan(userId: string, courseId: string): Promise<SPUserStudyPlan | null> {
    try {
      logger.debug('Fetching user study plan:', { userId, courseId });
  
      const { data, error } = await supabase
        .from('sp_userstudyplans')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();
  
      if (error) {
        if (error.code === 'PGRST116') {
          // No matching row found
          logger.debug('No study plan found for user and course:', { userId, courseId });
          return null;
        }
        console.error('Error in getUserStudyPlan:', error);
        throw error;
      }
  
      if (!data) {
        logger.debug('No study plan data returned for user and course:', { userId, courseId });
        return null;
      }
  
      logger.debug('Study plan fetched successfully:', data);
      return data as SPUserStudyPlan;
    } catch (error) {
      console.error('Unexpected error in getUserStudyPlan:', error);
      return null;
    }
  },

  async createStudySchedule(planId: string, examDateId: string, startDate: string, endDate: string): Promise<void> {
    const { error } = await supabase
      .from('sp_studyschedule')
      .insert({
        plan_id: planId,
        exam_date_id: examDateId,
        start_date: startDate,
        end_date: endDate,
        is_current: true
      });

    if (error) throw error;
  },

  async getStudySchedule(planId: string): Promise<SPStudySchedule | null> {
    const { data, error } = await supabase
      .from('sp_studyschedule')
      .select('*')
      .eq('plan_id', planId)
      .eq('is_current', true)
      .single();

    if (error) {
      console.error('Error fetching study schedule:', error);
      return null;
    }

    return data;
  },

  async createStudyPlanItems(planId: string, courseId: string, userId: string): Promise<void> {
    try { 
      logger.debug('Creating study plan items for plan:', planId, 'course:', courseId, 'and user:', userId);
      
      const { data, error } = await supabase.rpc('insert_study_plan_items', {
        p_plan_id: planId,
        p_course_id: courseId,
        p_user_id: userId
      });
  
      if (error) {
        console.error('Error inserting study plan items:', error);
        throw error;
      }
  
      // Check if any items were inserted
      const { count, error: countError } = await supabase
        .from('sp_studyplanitems')
        .select('*', { count: 'exact', head: true })
        .eq('plan_id', planId);
  
      if (countError) {
        console.error('Error counting study plan items:', countError);
      } else {
        logger.debug(`Number of study plan items: ${count}`);
      }
  
      logger.debug('Study plan items process completed');
    } catch (error) {
      console.error('Error in createStudyPlanItems:', error);
      throw error;
    }
  },

  
  async getOrderedTopicMappings(
    courseId: string, 
    primaryCPACourseId: string, 
    secondaryCPACourseId: string | null,
    isRetake: boolean,
    isPrimaryBlueprintBased: boolean
  ): Promise<SPTopicMapping[]> {
    logger.debug('Starting getOrderedTopicMappings with params:', { courseId, primaryCPACourseId, secondaryCPACourseId, isRetake, isPrimaryBlueprintBased });
  
    const getPrimaryTopicColumn = (id: string) => {
      if (id.includes('kesler')) return 'kesler_topic_id';
      if (id.includes('becker')) return 'becker_topic_id';
      if (id.includes('gleim')) return 'gleim_topic_id';
      return 'blueprint_topic_id';
    };
  
    const primaryTopicColumn = getPrimaryTopicColumn(primaryCPACourseId);
    const secondaryTopicColumn = secondaryCPACourseId ? getPrimaryTopicColumn(secondaryCPACourseId) : null;
  
    logger.debug('Constructed topic columns:', { primaryTopicColumn, secondaryTopicColumn });
  
    let query = supabase
      .from('sp_topicmappings')
      .select(`
        *,
        blueprint_topic:sp_topics!fk_blueprint_topic_id (
          topic_id, cpa_course_id, course_provider, title, sequence_number, is_blueprint, estimated_minutes
        ),
        primary_topic:sp_topics!fk_${primaryTopicColumn} (
          topic_id, cpa_course_id, course_provider, title, sequence_number, is_blueprint, estimated_minutes
        )
        ${secondaryTopicColumn ? `, secondary_topic:sp_topics!fk_${secondaryTopicColumn} (
          topic_id, cpa_course_id, course_provider, title, sequence_number, is_blueprint, estimated_minutes
        )` : ''}
      `)
      .eq('course_id', courseId);
  
    logger.debug('Constructed query:', query.toString());
  
    const { data, error } = await query;
  
    if (error) {
      console.error('Error in getOrderedTopicMappings query:', error);
      throw error;
    }
  
    logger.debug('Raw query result:', data);

    // Type guard function
    function isValidTopicMapping(item: any): item is SPTopicMapping {
      return (
        item &&
        typeof item.mapping_id === 'string' &&
        typeof item.course_id === 'string' &&
        typeof item.blueprint_topic_id === 'string' &&
        (item.kesler_topic_id === null || typeof item.kesler_topic_id === 'string') &&
        (item.becker_topic_id === null || typeof item.becker_topic_id === 'string') &&
        (item.gleim_topic_id === null || typeof item.gleim_topic_id === 'string') &&
        (item.question_category_id === null || typeof item.question_category_id === 'string') &&
        (item.flashcard_category_id === null || typeof item.flashcard_category_id === 'string') &&
        typeof item.task_name === 'string' &&
        (item.lesson_id === null || typeof item.lesson_id === 'string') &&
        isValidTopic(item.blueprint_topic) &&
        isValidTopic(item.primary_topic) &&
        (item.secondary_topic === undefined || isValidTopic(item.secondary_topic))
      );
    }

    // Type guard for SPTopic
    function isValidTopic(topic: any): topic is SPTopic {
      return (
        topic &&
        typeof topic.topic_id === 'string' &&
        typeof topic.cpa_course_id === 'string' &&
        typeof topic.course_provider === 'string' &&
        typeof topic.title === 'string' &&
        typeof topic.sequence_number === 'number' &&
        typeof topic.is_blueprint === 'boolean' &&
        typeof topic.estimated_minutes === 'number'
      );
    }

    // Filter out invalid mappings and sort
    const validMappings = (data as any[]).filter(isValidTopicMapping);
    logger.debug('Valid mappings count:', validMappings.length);
  
    const sortedMappings = validMappings.sort((a, b) => {
      const aSeq = isRetake || isPrimaryBlueprintBased
        ? a.blueprint_topic.sequence_number
        : a.primary_topic.sequence_number;
      const bSeq = isRetake || isPrimaryBlueprintBased
        ? b.blueprint_topic.sequence_number
        : b.primary_topic.sequence_number;
      return aSeq - bSeq;
    });
  
    logger.debug('Sorted mappings (first 3):', sortedMappings.slice(0, 3));
  
    return sortedMappings;
  },
  
  mergeTopics(blueprintTopics: any[], primaryTopics: any[], secondaryTopics: any[]): TopicMappingResult[] {
    const mergedMap = new Map<string, TopicMappingResult>();
  
    const processTopics = (topics: any[], topicType: 'blueprint_topics' | 'primary_topics' | 'secondary_topics') => {
      topics.forEach(topic => {
        const existingTopic = mergedMap.get(topic.mapping_id);
        if (existingTopic) {
          existingTopic[topicType] = Array.isArray(topic.sp_topics) ? topic.sp_topics : [topic.sp_topics];
        } else {
          mergedMap.set(topic.mapping_id, {
            mapping_id: topic.mapping_id,
            task_name: topic.task_name,
            blueprint_topics: [],
            primary_topics: [],
            secondary_topics: [],
            [topicType]: Array.isArray(topic.sp_topics) ? topic.sp_topics : [topic.sp_topics]
          });
        }
      });
    };
  
    processTopics(blueprintTopics, 'blueprint_topics');
    processTopics(primaryTopics, 'primary_topics');
    processTopics(secondaryTopics, 'secondary_topics');
  
    return Array.from(mergedMap.values());
  },
  
  orderTopics(topics: TopicMappingResult[]): TopicMappingResult[] {
    return topics.sort((a, b) => {
      const aSeq = a.blueprint_topics[0]?.sequence_number ?? 
                   a.primary_topics[0]?.sequence_number ?? 
                   a.secondary_topics[0]?.sequence_number ?? 0;
      const bSeq = b.blueprint_topics[0]?.sequence_number ?? 
                   b.primary_topics[0]?.sequence_number ?? 
                   b.secondary_topics[0]?.sequence_number ?? 0;
      return aSeq - bSeq;
    });
  },

  async getStudyPlanItems(planId: string): Promise<SPStudyPlanItem[]> {
    try {
      const { data: items, error } = await supabase
        .from('sp_studyplanitems')
        .select(`
          *,
          topics:sp_topicmappings (
            task_name,
            blueprint_topic:sp_topics!fk_blueprint_topic_id (
              topic_id, 
              title, 
              sequence_number, 
              topic_type,
              estimated_minutes
            ),
            kesler_topic:sp_topics!fk_kesler_topic_id (
              topic_id, 
              title, 
              sequence_number, 
              topic_type,
              estimated_minutes
            ),
            becker_topic:sp_topics!fk_becker_topic_id (
              topic_id, 
              title, 
              sequence_number, 
              topic_type,
              estimated_minutes
            ),
            gleim_topic:sp_topics!fk_gleim_topic_id (
              topic_id, 
              title, 
              sequence_number, 
              topic_type,
              estimated_minutes
            )
          ),
          study_plan:sp_userstudyplans (
            user_id,
            course_id,
            mix:sp_course_mix (
              primary_cpa_course_id,
              secondary_cpa_course_id,
              alias,
              primary_course:sp_courses!sp_course_mix_primary_cpa_course_id_fkey (
                is_blueprint_based
              ),
              secondary_course:sp_courses!sp_course_mix_secondary_cpa_course_id_fkey (
                is_blueprint_based
              )
            )
          )
        `)
        .eq('plan_id', planId)
        .order('week_number', { ascending: true })
        .order('sequence_number', { ascending: true });
  
      if (error) throw error;
  
      logger.debug('Fetched study plan items:', items);
  
      if (!items || items.length === 0) {
        throw new Error('No study plan items found');
      }
  
      // Safely access nested properties
      const studyPlan = items[0]?.study_plan;
      if (!studyPlan) {
        throw new Error('Study plan not found');
      }
  
      const userId = studyPlan.user_id;
      const courseId = studyPlan.course_id;
  
      // Fetch start date
      const { data: examDate, error: examDateError } = await supabase
        .from('sp_examdates')
        .select('start_date')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();
  
      if (examDateError) throw examDateError;
  
      if (!examDate?.start_date) {
        throw new Error('No start date found for the study plan');
      }
  
      const startDate = new Date(examDate.start_date);
      startDate.setHours(0, 0, 0, 0);
  
      // Fetch scheduled dates
      const { data: scheduledDates, error: scheduleError } = await supabase
        .rpc('fetch_study_plan_schedule', { p_plan_id: planId });
  
      if (scheduleError) throw scheduleError;
  
      // Create a map of item_id to scheduled date and week number
      const dateMap = new Map(
        (scheduledDates as ScheduledDateItem[]).map((item) => [
          item.item_id,
          { 
            planned_date: item.planned_date, 
            week_number: item.week_number, 
            estimated_minutes: item.estimated_minutes,
            primary_sequence: item.primary_sequence,
            is_primary_blueprint: item.is_primary_blueprint,
            is_secondary_blueprint: item.is_secondary_blueprint
          }
        ])
      );
  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
  
      // Safely get course mix
      const courseMix = studyPlan.mix;
      if (!courseMix) {
        throw new Error('Course mix not found');
      }
  
      const courseIds = [courseMix.primary_cpa_course_id];
      if (courseMix.secondary_cpa_course_id) {
        courseIds.push(courseMix.secondary_cpa_course_id);
      }
  
      const validCourseIds = courseIds.filter(Boolean);
  
      const { data: courses, error: coursesError } = await supabase
        .from('sp_courses')
        .select('cpa_course_id, name, is_blueprint_based')
        .in('cpa_course_id', validCourseIds);
  
      if (coursesError) throw coursesError;
  
      const courseProviders: { [key: string]: { name: string; isBlueprint: boolean } } = {};
      (courses as SPCourse[]).forEach(course => {
        if (course?.cpa_course_id) {
          courseProviders[course.cpa_course_id] = {
            name: this.getCourseProvider(course.name),
            isBlueprint: course.is_blueprint_based
          };
        }
      });
  
      return items.map((item: any) => {
        const courseMix = item.study_plan?.mix;
        const topicMappings = item.topics;
  
        if (!courseMix || !topicMappings) {
          console.warn('Missing course mix or topic mappings for item:', item.item_id);
          return null;
        }
  
        const isPrimaryBlueprint = courseMix.primary_course?.is_blueprint_based ?? false;
        const isSecondaryBlueprint = courseMix.secondary_course?.is_blueprint_based ?? false;
  
        const primaryProvider = courseProviders[courseMix.primary_cpa_course_id];
        const secondaryProvider = courseMix.secondary_cpa_course_id ? courseProviders[courseMix.secondary_cpa_course_id] : null;
  
        if (!primaryProvider) {
          console.warn('Primary provider not found for item:', item.item_id);
          return null;
        }
  
        const primaryTopic = topicMappings[`${primaryProvider.name}_topic`] || topicMappings.blueprint_topic;
        const secondaryTopic = secondaryProvider ? topicMappings[`${secondaryProvider.name}_topic`] : null;
  
        const scheduledItem = dateMap.get(item.item_id);
        let formattedDate: string | null = null;
  
        if (scheduledItem?.planned_date) {
          const dateObj = new Date(scheduledItem.planned_date);
          if (!isNaN(dateObj.getTime())) {
            dateObj.setHours(0, 0, 0, 0);
            formattedDate = dateObj.getTime() === today.getTime() 
              ? 'Today' 
              : `Week ${scheduledItem.week_number}`;
          }
        }
  
        return {
          item_id: item.item_id,
          plan_id: item.plan_id,
          mapping_id: item.mapping_id,
          is_completed: item.is_completed,
          sequence_number: item.sequence_number,
          week_number: scheduledItem?.week_number ?? 1,
          task_name: item.task_name || topicMappings.task_name,
          blueprint_topic_title: topicMappings.blueprint_topic?.title ?? 'N/A',
          blueprint_topic_sequence: topicMappings.blueprint_topic?.sequence_number ?? 0,
          primary_topic_title: primaryTopic?.title ?? 'N/A',
          primary_topic_sequence: primaryTopic?.sequence_number ?? 0,
          secondary_topic_title: secondaryTopic?.title ?? null,
          secondary_topic_sequence: secondaryTopic?.sequence_number ?? null,
          primary_provider: primaryProvider.name,
          secondary_provider: secondaryProvider?.name ?? null,
          primary_cpa_course_id: courseMix.primary_cpa_course_id,
          secondary_cpa_course_id: courseMix.secondary_cpa_course_id ?? null,
          planned_date: formattedDate,
          estimated_minutes: scheduledItem?.estimated_minutes ?? null,
          primary_sequence: scheduledItem?.primary_sequence ?? primaryTopic?.sequence_number ?? 0,
          is_primary_blueprint: isPrimaryBlueprint,
          is_secondary_blueprint: isSecondaryBlueprint,
          primary_alias: isPrimaryBlueprint ? courseMix.alias : null,
          secondary_alias: isSecondaryBlueprint ? courseMix.alias : null,
          topic_type: primaryTopic?.topic_type ?? topicMappings.blueprint_topic?.topic_type ?? null,
        };
      }).filter(Boolean) as SPStudyPlanItem[];
    } catch (error) {
      console.error('Error in getStudyPlanItems:', error);
      throw error;
    }
  },

  async getWeeklyStudyTasks(planId: string, weekNumber: number): Promise<WeeklyStudyPlan> {
    try {
      const { data, error } = await supabase
        .from('sp_studyplanitems')
        .select(`
          *,
          sp_topicmappings!inner (
            task_name,
            blueprint_topic:sp_topics!fk_blueprint_topic_id (
              topic_id, title, sequence_number, topic_type, estimated_minutes
            )
          ),
          sp_userstudyplans!inner (
            course_mix:sp_course_mix!inner (
              primary_cpa_course_id,
              secondary_cpa_course_id,
              alias,
              primary_course:sp_courses!sp_course_mix_primary_cpa_course_id_fkey (
                is_blueprint_based
              ),
              secondary_course:sp_courses!sp_course_mix_secondary_cpa_course_id_fkey (
                is_blueprint_based
              )
            )
          )
        `)
        .eq('plan_id', planId)
        .eq('week_number', weekNumber)
        .order('sequence_number', { ascending: true });

      if (error) throw error;

      const tasks = data.map(item => ({
        ...item,
        estimated_minutes: item.sp_topicmappings.blueprint_topic.estimated_minutes || 180,
        primary_provider: item.sp_userstudyplans.course_mix.primary_course.is_blueprint_based ? 'blueprint' : 'kesler',
        primary_cpa_course_id: item.sp_userstudyplans.course_mix.primary_cpa_course_id,
        secondary_cpa_course_id: item.sp_userstudyplans.course_mix.secondary_cpa_course_id,
        primary_alias: item.sp_userstudyplans.course_mix.alias,
        secondary_alias: null,
        is_primary_blueprint: item.sp_userstudyplans.course_mix.primary_course.is_blueprint_based,
        is_secondary_blueprint: item.sp_userstudyplans.course_mix.secondary_course?.is_blueprint_based || false,
      }));

      const totalMinutes = tasks.reduce((sum, task) => sum + (task.estimated_minutes || 0), 0);
      const startDate = new Date(); // You'll need to fetch this from your study schedule
      startDate.setDate(startDate.getDate() + (weekNumber - 1) * 7);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);

      return {
        week_number: weekNumber,
        total_minutes: totalMinutes,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        tasks
      };
    } catch (error) {
      console.error('Error in getWeeklyStudyTasks:', error);
      throw error;
    }
  },

  async recalculateStudyWeeks(planId: string, weeklyHours: '15' | '20' | '25' | '30'): Promise<void> {
    try {
      const { error } = await supabase.rpc('calculate_study_weeks', {
        p_plan_id: planId,
        p_weekly_hours: weeklyHours
      });

      if (error) {
        console.error('Error recalculating study weeks:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error in recalculateStudyWeeks:', error);
      throw error;
    }
  },
  
  // Helper function to determine course provider
  getCourseProvider(courseName: string): string {
    const lowerCaseName = courseName.toLowerCase();
    if (lowerCaseName.includes('kesler')) return 'kesler';
    if (lowerCaseName.includes('becker')) return 'becker';
    if (lowerCaseName.includes('gleim')) return 'gleim';
    return 'blueprint';
  },

  async updateRecommendedWeeklyHours(userId: string, courseId: string, hours: '15' | '20' | '25'): Promise<void> {
    const { error } = await supabase
      .from('sp_userstudyplans')
      .update({ recommended_weekly_hours: hours })
      .eq('user_id', userId)
      .eq('course_id', courseId);
  
    if (error) throw error;
  },

  async getExamDate(userId: string, courseId: string): Promise<{ exam_date: string, start_date: string } | null> {
    const { data, error } = await supabase
      .from('sp_examdates')
      .select('exam_date, start_date')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    if (error) {
      console.error('Error fetching exam date:', error);
      return null;
    }
  
    return data;
  },
  
  async getStudyPlanItemSchedule(scheduleId: string): Promise<SPStudyPlanItemSchedule[]> {
    const { data, error } = await supabase
      .from('sp_studyplanitemschedule')
      .select(`
        *,
        sp_studyplanitems!inner (
          sp_topicmappings!inner (
            task_name,
            sp_topics!inner (title)
          )
        )
      `)
      .eq('schedule_id', scheduleId)
      .order('planned_date');
    
    if (error) throw error;
    return data.map(item => ({
      ...item,
      task_name: item.sp_studyplanitems.sp_topicmappings.task_name,
      topic_title: item.sp_studyplanitems.sp_topicmappings.sp_topics.title
    }));
  },

  // Update the getConfidenceTrackerData method
  async getConfidenceTrackerData(userId: string, courseId: string): Promise<ConfidenceTrackerData> {
    const { data, error } = await supabase.rpc('get_confidence_tracker_data', {
      p_user_id: userId,
      p_course_id: courseId
    });

    if (error) throw error;
    return data;
  },

  // Add a new method to get study tasks data
  async getStudyTasksProgress(planId: string): Promise<{ completed: number; total: number }> {
    const { data, error } = await supabase
      .from('sp_studyplanitems')
      .select('is_completed')
      .eq('plan_id', planId);
  
    if (error) {
      console.error('Error fetching study tasks progress:', error);
      throw error;
    }
  
    const total = data.length;
    const completed = data.filter(item => item.is_completed).length;
  
    return { completed, total };
  },

  async updateStudyPlanItemCompletion(itemId: string, isCompleted: boolean, completionDate: string | null): Promise<void> {
    const { error } = await supabase
      .from('sp_studyplanitemschedule')
      .update({ is_completed: isCompleted, completion_date: completionDate })
      .eq('item_id', itemId);
    if (error) throw error;
  },

  async getStudyTaskDetails(userId: string, courseId: string, itemId: string): Promise<StudyTaskDetails> {
    logger.debug('API: getStudyTaskDetails called with:', { userId, courseId, itemId });
    try {
      logger.debug('API: Making RPC call to get_study_task_details');
      const { data, error } = await supabase.rpc('get_study_task_details', {
        p_user_id: userId,
        p_course_id: courseId,
        p_item_id: itemId
      });
  
      logger.debug('API: RPC call completed. Result:', { data, error });
  
      if (error) {
        console.error('API: Supabase RPC error:', error);
        throw error;
      }
  
      if (!data || data.length === 0) {
        console.warn('API: No task details returned from RPC call');
        throw new Error('No task details found');
      }
  
      const rawTaskDetails = data[0];

      // Transform response to ensure proper typing and handle null values
      const taskDetails: StudyTaskDetails = {
        ...rawTaskDetails,
        // Parse integer values
        estimated_minutes: rawTaskDetails.estimated_minutes 
          ? parseInt(rawTaskDetails.estimated_minutes.toString(), 10)
          : null,
        // Ensure topic_type is present
        topic_type: rawTaskDetails.topic_type || 'learn',
        // Ensure other required fields
        is_completed: Boolean(rawTaskDetails.is_completed),
        task_name: rawTaskDetails.task_name || '',
        primary_course_name: rawTaskDetails.primary_course_name || '',
        primary_course_display_name: rawTaskDetails.primary_course_display_name || '',
        primary_topic_title: rawTaskDetails.primary_topic_title || '',
        blueprint_topic_title: rawTaskDetails.blueprint_topic_title || '',
        // Optional fields can remain as is
        secondary_course_name: rawTaskDetails.secondary_course_name,
        secondary_course_display_name: rawTaskDetails.secondary_course_display_name,
        secondary_topic_title: rawTaskDetails.secondary_topic_title,
        lesson_id: rawTaskDetails.lesson_id,
        question_category_id: rawTaskDetails.question_category_id,
      };

      logger.debug('API: Returning task details:', taskDetails);
      return taskDetails;
    } catch (error) {
      console.error('API: Error in getStudyTaskDetails:', error);
      throw error;
    }
  },

  async getStudyType(userId: string, courseId: string): Promise<SPStudyType | null> {
    const { data, error } = await supabase
      .from('sp_studytype')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();

    if (error) {
      if (error.code === 'PGRST116') return null;
      throw error;
    }

    return data;
  },

async getNextIncompleteTask(planId: string): Promise<SPStudyPlanItem | null> {
  try {
    logger.debug('Fetching next incomplete task for plan:', planId);
    
    const { data, error } = await supabase
      .from('sp_studyplanitems')
      .select(`
        *,
        sp_topicmappings!inner (
          task_name
        )
      `)
      .eq('plan_id', planId)
      .eq('is_completed', false)
      .order('sequence_number', { ascending: true })
      .limit(1)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        logger.debug('No incomplete tasks found for plan:', planId);
        return null;
      }
      console.error('Error fetching next incomplete task:', error);
      throw error;
    }

    if (!data) {
      logger.debug('No data returned for next incomplete task');
      return null;
    }

    const nextTask: SPStudyPlanItem = {
      item_id: data.item_id,
      plan_id: data.plan_id,
      mapping_id: data.mapping_id,
      is_completed: data.is_completed,
      sequence_number: data.sequence_number,
      week_number: data.week_number || 1, // Add this line
      task_name: data.sp_topicmappings.task_name,
      primary_topic_title: data.primary_topic_title || '',
      secondary_topic_title: data.secondary_topic_title || null,
      blueprint_topic_title: data.blueprint_topic_title || '',
      primary_sequence: data.sequence_number,
      blueprint_topic_sequence: 0,
      primary_topic_sequence: data.sequence_number,
      secondary_topic_sequence: null,
      primary_provider: '',
      secondary_provider: null,
      primary_cpa_course_id: '',
      secondary_cpa_course_id: null,
      planned_date: null,
      estimated_minutes: null,
      is_primary_blueprint: false,
      is_secondary_blueprint: false,
      primary_alias: null,
      secondary_alias: null,
      topic_type: null,
    };

    logger.debug('Next incomplete task found:', nextTask);
    return nextTask;
  } catch (error) {
    console.error('Unexpected error in getNextIncompleteTask:', error);
    throw error;
  }
},

async markStudyTaskComplete(itemId: string): Promise<SPStudyPlanItem> {
  logger.debug('Calling markStudyTaskComplete with itemId:', itemId);
  try {
    logger.debug('Making RPC call to mark_study_task_complete');
    const { error } = await supabase.rpc('mark_study_task_complete', {
      p_item_id: itemId,
      p_is_complete: true
    });

    if (error) {
      console.error('Supabase RPC error:', error);
      throw new Error(`Failed to mark task as complete: ${error.message}`);
    }

    logger.debug('Task marked as complete successfully');
    
    // Fetch the updated study task item
    return await this.getStudyTaskItem(itemId);
  } catch (error) {
    console.error('Error in markStudyTaskComplete:', error);
    throw error;
  }
},

async unmarkStudyTaskComplete(itemId: string): Promise<SPStudyPlanItem> {
  logger.debug('Calling unmarkStudyTaskComplete with itemId:', itemId);
  try {
    logger.debug('Making RPC call to mark_study_task_complete with is_complete set to false');
    const { error } = await supabase.rpc('mark_study_task_complete', {
      p_item_id: itemId,
      p_is_complete: false
    });

    if (error) {
      console.error('Supabase RPC error:', error);
      throw new Error(`Failed to unmark task as complete: ${error.message}`);
    }

    logger.debug('Task unmarked as complete successfully');
    
    // Fetch the updated study task item
    return await this.getStudyTaskItem(itemId);
  } catch (error) {
    console.error('Error in unmarkStudyTaskComplete:', error);
    throw error;
  }
},

async getStudyTaskItem(itemId: string): Promise<SPStudyPlanItem> {
  logger.debug('Fetching study task item:', itemId);
  const { data, error } = await supabase
    .from('sp_studyplanitems')
    .select(`
      *,
      sp_studyplanitemschedule (
        planned_date,
        estimated_minutes,
        is_completed,
        completion_date
      )
    `)
    .eq('item_id', itemId)
    .single();

  if (error) {
    console.error('Error fetching study task item:', error);
    throw error;
  }
  
  if (!data) {
    throw new Error('Study task item not found');
  }
  
  logger.debug('Study task item fetched:', data);
  return {
    ...data,
    planned_date: data.sp_studyplanitemschedule?.planned_date,
    estimated_minutes: data.sp_studyplanitemschedule?.estimated_minutes,
    is_completed: data.sp_studyplanitemschedule?.is_completed,
    completion_date: data.sp_studyplanitemschedule?.completion_date
  } as SPStudyPlanItem;
},

  async resetStudyPlan(userId: string, courseId: string): Promise<void> {
    try {
      const { error } = await supabase.rpc('reset_user_study_plan', {
        p_user_id: userId,
        p_course_id: courseId
      });

      if (error) {
        console.error('Error resetting study plan:', error);
        throw error;
      }

      logger.debug('Study plan reset successfully');
    } catch (error) {
      console.error('Error in resetStudyPlan:', error);
      throw error;
    }
  },


  // Study Task Testlet generators
  async getStudyTaskTestletById(testletId: string): Promise<StudyTaskTestlet> {
    try {
      const { data: testlet, error } = await supabase
        .from('sp_studytasktestlets')
        .select('*')
        .eq('id', testletId)
        .single();

      if (error) {
        console.error('Error fetching study task testlet:', error);
        throw new Error(`Failed to fetch study task testlet: ${error.message}`);
      }

      if (!testlet) {
        throw new Error('Study task testlet not found');
      }

      return {
        id: testlet.id,
        study_task_id: testlet.study_task_id,
        user_id: testlet.user_id,  // Added user_id
        type: testlet.type,
        completed: testlet.completed,
        score: testlet.score,
        time_spent: testlet.time_spent,
        created_at: testlet.created_at,
        updated_at: testlet.updated_at,
        questions_data: testlet.questions_data || [] // Add default empty array
      };
    } catch (error) {
      console.error('Error in getStudyTaskTestletById:', error);
      throw error instanceof Error ? error : new Error('Unknown error occurred');
    }
  },

  async checkActiveStudyTaskQuiz(
    studyTaskId: string,
    type: 'learn' | 'loop' | 'funnel' | 'retake_funnel'
  ): Promise<{ hasActive: boolean; testlet?: StudyTaskTestlet; quizHistory?: QuizHistory }> {
    try {
      // First get the testlet
      const { data: testlet, error: testletError } = await supabase
        .from('sp_studytasktestlets')
        .select('*')
        .eq('study_task_id', studyTaskId)
        .eq('type', type)
        .eq('completed', false)
        .single();

      if (testletError && testletError.code !== 'PGRST116') {
        throw testletError;
      }

      if (!testlet) {
        return { hasActive: false };
      }

      // Then get the associated quiz history
      const { data: quizHistory, error: quizError } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('study_task_testlet_id', testlet.id)
        .single();

      if (quizError && quizError.code !== 'PGRST116') {
        throw quizError;
      }

      return {
        hasActive: true,
        testlet: {
          ...testlet,
          user_id: testlet.user_id  // Ensure user_id is included
        },
        quizHistory: quizHistory || undefined
      };
    } catch (error) {
      console.error('Error checking active quiz:', error);
      return { hasActive: false };
    }
  },

  async getStudyTaskQuizQuestions(
    userId: string,
    courseId: string,
    studyTaskId: string,
    questionCategoryId: string,
    type: 'learn' | 'loop' | 'funnel',
    taskName: string
  ): Promise<{ questionIds: string[]; testlet: StudyTaskTestlet; quizHistory: QuizHistory }> {
    const { data, error } = await supabase
      .rpc('get_study_task_learn_questions', {
        p_user_id: userId,
        p_course_id: courseId,
        p_question_category_id: questionCategoryId,
        p_study_task_id: studyTaskId,
        p_task_name: taskName
      });

    if (error) {
      console.error('RPC Error:', error);
      throw error;
    }

    // Ensure testlet has user_id
    const testlet: StudyTaskTestlet = {
      ...data.testlet,
      user_id: userId
    };

    return {
      questionIds: data.question_ids,
      testlet,
      quizHistory: data.quiz_history
    };
  },

  async getStudyTaskLoopQuizQuestions(
    userId: string,
    courseId: string,
    studyTaskId: string
  ): Promise<StudyTaskQuiz> {
    const { data, error } = await supabase
      .rpc('get_study_task_loop_questions', {
        p_user_id: userId,
        p_course_id: courseId,
        p_study_task_id: studyTaskId
      });

    if (error) {
      console.error('RPC Error:', error);
      throw error;
    }

    // Ensure testlet has user_id
    const testlet: StudyTaskTestlet = {
      ...data.testlet,
      user_id: userId
    };

    return {
      questionIds: data.question_ids,
      testlet,
      quizHistory: data.quiz_history
    };
  },

  async getStudyTaskFunnelQuizQuestions(
    userId: string,
    courseId: string,
    studyTaskId: string,
    questionCategoryId: string | null | undefined,
    taskName: string
  ): Promise<StudyTaskQuiz> {
    const { data, error } = await supabase.rpc('get_study_task_funnel_questions', {
      p_user_id: userId,
      p_course_id: courseId,
      p_study_task_id: studyTaskId,
      p_question_category_id: questionCategoryId ?? null,
      p_task_name: taskName
    });

    if (error) {
      console.error('RPC Error:', error);
      throw error;
    }

    // Ensure testlet has user_id
    const testlet: StudyTaskTestlet = {
      ...data.testlet,
      user_id: userId
    };

    return {
      questionIds: data.question_ids,
      testlet,
      quizHistory: data.quiz_history
    };
  },

  async updateStudyTaskTestletFromQuizHistory(
    testletId: string,
    quizHistoryId: string
  ): Promise<void> {
    try {
      // Get the quiz history results
      const { data: quiz, error: quizError } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('id', quizHistoryId)
        .single();

      if (quizError) throw quizError;

      // Validate the time spent
      const timeSpent = quiz.total_time && quiz.total_time > 0 ? quiz.total_time : 0;

      // Only update the testlet completion status and score
      const { error: updateError } = await supabase
        .from('sp_studytasktestlets')
        .update({
          completed: true,
          score: quiz.percentage_correct,
          time_spent: timeSpent,
          updated_at: new Date().toISOString()
        })
        .eq('id', testletId);

      if (updateError) throw updateError;
    } catch (error) {
      console.error('Error updating study task testlet:', error);
      throw error;
    }
  },

  async deleteStudyTaskTestlet(testletId: string): Promise<void> {
    try {
      // Delete associated quiz history first
      const { error: quizHistoryError } = await supabase
        .from('quiz_history')
        .delete()
        .eq('study_task_testlet_id', testletId);

      if (quizHistoryError) throw quizHistoryError;

      // Then delete the testlet
      const { error: testletError } = await supabase
        .from('sp_studytasktestlets')
        .delete()
        .eq('id', testletId);

      if (testletError) throw testletError;
    } catch (error) {
      console.error('Error deleting study task testlet:', error);
      throw error;
    }
  },

// STUDY PLAN END























  // mentor
  //MENTORSHIP MODULES FUNCTIONS
  
  // Mentor API Functions
  async getMentorModulesMetadata(): Promise<MentorModuleMetadata[]> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');
  
      const { data: allUserAccess, error: accessError } = await supabase
        .from('user_course_access')
        .select('tier_id, course_id')
        .eq('user_id', user.id);
  
      if (accessError) throw accessError;
  
      // Check if user has mentor access either through MENTOR_ONLY tier or any FULL_ACCESS tier
      const hasMentorAccess = allUserAccess?.some(access => 
        (access.course_id === COURSE_IDS.MENTORSHIP && 
         [TIER_IDS.MENTOR_ONLY, TIER_IDS.FULL_ACCESS].includes(access.tier_id)) ||
        access.tier_id === TIER_IDS.FULL_ACCESS
      );
  
      const mentorshipAccess = allUserAccess?.find(access => 
        access.course_id === COURSE_IDS.MENTORSHIP
      );
  
      // Only consider limited tier if user doesn't have full access from any course
      const isLimitedTier = !hasMentorAccess && 
        mentorshipAccess?.tier_id === TIER_IDS.FREE_TRIAL && 
        !hasFullAccessFromAnyCourse(allUserAccess || []);
  
      const { data: modules, error: modulesError } = await supabase
        .from('mentor_modules')
        .select(`
          id,
          title,
          sequence_number,
          free_trial_content,
          sales_pitch,
          user_mentor_progress!left(
            is_completed
          )
        `)
        .eq('user_mentor_progress.user_id', user.id)
        .order('sequence_number');
  
      if (modulesError) throw modulesError;
      if (!modules) return [];
  
      return modules.map((module): MentorModuleMetadata => ({
        id: module.id,
        title: module.title,
        sequence_number: module.sequence_number,
        free_trial_content: module.free_trial_content,
        sales_pitch: module.sales_pitch,
        is_completed: isLimitedTier && !module.free_trial_content 
          ? false 
          : module.user_mentor_progress?.[0]?.is_completed || false
      }));
  
    } catch (error) {
      console.error('Error in getMentorModulesMetadata:', error);
      throw error;
    }
  },
  
  async getModuleContent(moduleId: string): Promise<MentorModuleContent | null> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');
  
      // Get all user access levels
      const { data: userAccess } = await supabase
        .from('user_course_access')
        .select('tier_id, course_id')
        .eq('user_id', user.id);
  
      // Check for mentor access through MENTOR_ONLY tier or any FULL_ACCESS tier
      const hasMentorAccess = userAccess?.some(access => 
        (access.course_id === COURSE_IDS.MENTORSHIP && 
         [TIER_IDS.MENTOR_ONLY, TIER_IDS.FULL_ACCESS].includes(access.tier_id)) ||
        access.tier_id === TIER_IDS.FULL_ACCESS
      );
  
      // Fetch module content and notes
      const { data: module, error: moduleError } = await supabase
        .from('mentor_modules')
        .select(`
          content,
          free_trial_content,
          notes!left(
            content,
            updated_at
          )
        `)
        .eq('id', moduleId)
        .eq('notes.user_id', user.id)
        .single();
  
      if (moduleError) throw moduleError;
      if (!module) return null;
  
      // Parse content
      let parsedContent;
      try {
        parsedContent = typeof module.content === 'string' 
          ? JSON.parse(module.content) 
          : module.content;
  
        // If user doesn't have mentor access and content isn't free trial, return limited content
        if (!hasMentorAccess && !module.free_trial_content) {
          parsedContent = {
            description: parsedContent.description,
            blocks: [],
            sales_pitch: parsedContent.sales_pitch
          };
        }
      } catch (e) {
        console.error('Error parsing module content:', e);
        parsedContent = { description: 'Error loading content', blocks: [] };
      }
  
      return {
        content: parsedContent,
        notes: module.notes?.[0]?.content || ''
      };
    } catch (error) {
      console.error('Error in getModuleContent:', error);
      throw error;
    }
  },

  async updateMentorModuleProgress(moduleId: string, isCompleted: boolean): Promise<void> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const { error } = await supabase
      .from('user_mentor_progress')
      .upsert({ 
        user_id: user.id,
        module_id: moduleId,
        is_completed: isCompleted
      }, {
        onConflict: 'user_id, module_id'
      });

    if (error) throw error;
  },

  async getNoteForMentor(userId: string, _courseId: string, moduleId: string): Promise<Note | null> {
    logger.debug('Getting note for mentor module:', { userId, moduleId });
    
    if (!userId || !moduleId) {
      logger.debug('Missing required parameters for getNoteForMentor');
      return null;
    }
  
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('module_id', moduleId)
        .maybeSingle();
  
      if (error) {
        console.error('Error fetching note for mentor module:', error);
        throw error;
      }
  
      if (data) {
        logger.debug('Note fetched successfully:', data);
        return data;
      } else {
        logger.debug('No note found for this mentor module');
        return null;
      }
    } catch (error) {
      console.error('Error in getNoteForMentor:', error);
      throw error;
    }
  },

  // Add this method to the api object
  async getMentorshipProgress(userId: string, courseId: string): Promise<ConfidenceTrackerData> {
    const { data, error } = await supabase.rpc('get_confidence_tracker_data', {
      p_user_id: userId,
      p_course_id: courseId
    });

    if (error) throw error;
    return data;
  },

//SEARCH FUNCTIONALITY

// Add this function to the api object
async performContentSearch(courseId: string, query: string, filters: string[] = []): Promise<SearchResult[]> {
  try {
    const { data, error } = await supabase.rpc('search_course_content', {
      p_course_id: courseId,
      p_query: query,
      p_filters: filters
    });

    logger.debug('Raw search results:', data);

    if (error) {
      console.error('Supabase RPC error:', error);
      throw error;
    }

    if (!data || !Array.isArray(data)) {
      console.error('Unexpected data structure:', data);
      return [];
    }

    return data.map(item => ({
      id: item.id,
      type: item.type,
      title: item.title,
      preview: item.preview,
      category_id: item.category_id,
      fullContent: item.full_content
    }));
  } catch (error) {
    console.error('Error searching content:', error);
    throw error;
  }
},



// MOCK EXAM FUNCTIONS

async canCreateMockExam(userId: string, courseId: string): Promise<boolean> {
  const { data: access, error } = await supabase
    .from('user_course_access')
    .select('tier_id')
    .eq('user_id', userId)
    .eq('course_id', courseId)
    .single();

  if (error || !access) return false;
  const allowedTiers = ['0b992474-3a65-41b5-ad90-cc88662cc594', 'c99793af-5529-446b-be12-cc56709509e6'];
  return allowedTiers.includes(access.tier_id);
},

// Update your existing createMockExam function to use the reset
async createMockExam(userId: string, courseId: string): Promise<string> {
  const canCreate = await this.canCreateMockExam(userId, courseId);
  if (!canCreate) throw new Error('Not allowed to create mock exam');

  const { data: examId, error } = await supabase
    .rpc('create_mock_exam', {
      p_user_id: userId,
      p_course_id: courseId
    });

  if (error) throw error;
  return examId;
},

async getMockExamHistory(userId: string, courseId: string): Promise<MockExam[]> {
  const { data, error } = await supabase
    .from('mock_exams')
    .select('*')
    .eq('user_id', userId)
    .eq('course_id', courseId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
},

async getMockExamTestlets(mockExamId: string): Promise<MockExamTestlet[]> {
  const { data, error } = await supabase
    .from('mock_exam_testlets')
    .select('*')
    .eq('mock_exam_id', mockExamId)
    .order('testlet_number', { ascending: true });

  if (error) throw error;
  return data;
},

async getMockExamMCQs(
  userId: string,
  courseId: string,
  count: number,
  mockExamId: string,
  testletNumber: number
): Promise<string[]> {
  const { data, error } = await supabase
    .rpc('get_mock_exam_mcqs', {
      p_user_id: userId,
      p_course_id: courseId,
      p_count: count,
      p_mock_exam_id: mockExamId,
      p_testlet_number: testletNumber
    });

  if (error) throw error;
  return data;
},

async getMockExamSims(
  userId: string,
  courseId: string,
  count: number,
  mockExamId: string,
  testletNumber: number
): Promise<string[]> {
  const { data, error } = await supabase
    .rpc('get_mock_exam_sims', {
      p_user_id: userId,
      p_course_id: courseId,
      p_count: count,
      p_mock_exam_id: mockExamId,
      p_testlet_number: testletNumber
    });

  if (error) throw error;
  return data;
},


// Update completeTestlet to include proper error handling and typing
async completeTestlet(
  testletId: string,
  score: number,
  timeSpent: number,
  quizHistoryId?: string
): Promise<void> {
  try {
    // If we have a quiz history ID, update testlet using that data first
    if (quizHistoryId) {
      await this.updateMockExamTestletFromQuizHistory(testletId, quizHistoryId);
      
      // Get the testlet to check what mock exam it belongs to
      const { data: testlet, error: testletError } = await supabase
        .from('mock_exam_testlets')
        .select('mock_exam_id')
        .eq('id', testletId)
        .single();

      if (testletError) throw testletError;

      // Update the mock exam's total time
      if (testlet?.mock_exam_id) {
        await this.updateMockExamTotalTime(testlet.mock_exam_id);
      }
    } else {
      // Fallback to direct update if no quiz history
      const { error: updateError } = await supabase
        .from('mock_exam_testlets')
        .update({
          completed: true,
          score,
          time_spent: timeSpent,
          updated_at: new Date().toISOString()
        })
        .eq('id', testletId);

      if (updateError) {
        console.error('Error in direct testlet update:', updateError);
        throw updateError;
      }
    }

  } catch (err: any) {
    console.error('Error completing testlet:', err);
    throw err;
  }
},

async getMockExamTestletById(testletId: string): Promise<MockExamTestlet> {
  const { data, error } = await supabase
    .rpc('get_mock_exam_testlet_by_id', {
      p_testlet_id: testletId
    });

  if (error) {
    console.error('Error fetching mock exam testlet:', error);
    throw error;
  }

  if (!data || data.length === 0) {
    throw new Error('Mock exam testlet not found');
  }

  // Since we're querying by primary key, we'll only get one result
  return data[0] as MockExamTestlet;
},

async createMockExamMCQHistory(
  userId: string,
  courseId: string,
  mockExamId: string,
  mockExamTestletId: string,
  mockExamNumber: number,
  testletNumber: number,
  questionsData: string[]
): Promise<string> {
  const formattedQuestionsData: MockExamMCQQuestionData[] = questionsData.map(qId => ({
    type: 'mock_exam_mcq',
    questionId: qId,
    mockExamId,
    mockExamTestletId,
    mockExamNumber,
    testletNumber,
    answered: false,
    isCorrect: false,
    timeSpent: 0,
    userAnswer: '',
    confidenceLevel: null,
    free_trial_content: false
  }));

  const { data: mockExamName } = await supabase
    .rpc('format_mock_exam_name', {
      p_exam_number: mockExamNumber,
      p_testlet_number: testletNumber
    });

  const { data, error } = await supabase
    .from('quiz_history')
    .insert({
      user_id: userId,
      course_id: courseId,
      total_questions: questionsData.length,
      correct_answers: 0,
      incorrect_answers: 0,
      percentage_correct: 0,
      questions_data: formattedQuestionsData,
      completed: false,
      total_time: 0,
      avg_time_per_question: 0,
      start_time: new Date().toISOString(),
      end_time: null,
      quiz_name: mockExamName,
      mock_exam_testlet_id: mockExamTestletId
    })
    .select('id')
    .single();

  if (error) throw error;
  return data.id;
},


async createMockExamSimHistory(
  userId: string,
  courseId: string,
  mockExamId: string,
  mockExamTestletId: string,
  mockExamNumber: number,
  testletNumber: number,
  simulationId: string,
  results: UserTBSResponse,
  timeSpent: number
): Promise<string> {
  // Validate timeSpent
  const validatedTime = Math.max(0, Math.floor(timeSpent));
  
  // Use the same format_mock_exam_name function as MCQs
  const { data: mockExamName } = await supabase
    .rpc('format_mock_exam_name', {
      p_exam_number: mockExamNumber,
      p_testlet_number: testletNumber
    });

  const questionData: MockExamSimulationQuestionData = {
    type: 'mock_exam_sim',
    questionId: simulationId,
    simulationId,
    mockExamId,
    mockExamTestletId,
    mockExamNumber,
    testletNumber,
    answered: true,
    isCorrect: results.score >= 70,
    userAnswer: JSON.stringify(results.answers),
    confidenceLevel: results.confidence_level as ConfidenceLevelValue,
    score: results.score,
    feedback: results.feedback as FeedbackItem[],
    free_trial_content: false,
    question_results: results.question_results || [],
    timeSpent: validatedTime,
  };

  const { data, error } = await supabase
    .from('quiz_history')
    .insert({
      user_id: userId,
      course_id: courseId,
      total_questions: 1,
      correct_answers: results.score >= 70 ? 1 : 0,
      incorrect_answers: results.score >= 70 ? 0 : 1,
      percentage_correct: results.score,
      questions_data: [questionData],
      completed: true,
      total_time: validatedTime,
      avg_time_per_question: validatedTime,
      start_time: new Date(Date.now() - validatedTime * 1000).toISOString(),
      end_time: new Date().toISOString(),
      quiz_name: mockExamName, // This will now be "Mock #N | Testlet M"
      mock_exam_testlet_id: mockExamTestletId
    })
    .select('id')
    .single();

  if (error) throw error;
  return data.id;
},

// Add these two functions to your Api.ts class:

// Add new function to update mock exam total time
async updateMockExamTotalTime(mockExamId: string): Promise<void> {
  // Get all completed testlets
  const { data: testlets, error: testletsError } = await supabase
    .from('mock_exam_testlets')
    .select('time_spent')
    .eq('mock_exam_id', mockExamId)
    .eq('completed', true);

  if (testletsError) {
    console.error('Error fetching testlets:', testletsError);
    throw testletsError;
  }

  // Sum up total time from testlets
  const totalTime = testlets.reduce((sum, testlet) => sum + (testlet.time_spent || 0), 0);

  // Update mock exam
  const { error: updateError } = await supabase
    .from('mock_exams')
    .update({ 
      total_time: totalTime,
      updated_at: new Date().toISOString()
    })
    .eq('id', mockExamId);

  if (updateError) {
    console.error('Error updating mock exam time:', updateError);
    throw updateError;
  }
},

// Add new function to periodically save timer state
async saveMockExamTimerState(
  mockExamId: string,
  elapsedSeconds: number
): Promise<void> {
  try {
    await supabase
      .from('mock_exams')
      .update({ 
        total_time: elapsedSeconds,
        updated_at: new Date().toISOString()
      })
      .eq('id', mockExamId);
  } catch (error) {
    console.error('Error saving timer state:', error);
  }
},

async updateMockExamTestletFromQuizHistory(
  testletId: string,
  quizHistoryId: string
): Promise<void> {
  try {
    // Get the quiz history with full data including mock exam ID
    const { data: quiz, error: quizError } = await supabase
      .from('quiz_history')
      .select(`
        *,
        questions_data,
        mock_exam_testlets!quiz_history_mock_exam_testlet_id_fkey (
          type,
          mock_exam_id
        )
      `)
      .eq('id', quizHistoryId)
      .single();

    if (quizError) {
      console.error('Error fetching quiz history:', quizError);
      throw quizError;
    }

    if (!quiz) {
      throw new Error(`Quiz history not found for id: ${quizHistoryId}`);
    }

    // Prioritize timestamp-based time calculation
    let timeSpent = 0;
    
    if (quiz.start_time && quiz.end_time) {
      // Primary method: Use actual start/end timestamps
      timeSpent = Math.floor(
        (new Date(quiz.end_time).getTime() - new Date(quiz.start_time).getTime()) / 1000
      );
    } else if (quiz.total_time) {
      // Fallback: Use stored total time if timestamps aren't available
      timeSpent = quiz.total_time;
    } else {
      // Last resort: Sum question times
      timeSpent = quiz.questions_data.reduce((total: number, question: QuizQuestionData) => {
        return total + (question.timeSpent || 0);
      }, 0);
    }

    // Validate and ensure positive time value
    timeSpent = Math.max(0, Math.floor(timeSpent));

    // Log significant time discrepancies for monitoring
    if (quiz.total_time && Math.abs(timeSpent - quiz.total_time) > 5) {
      console.info('Time calculation difference detected:', {
        testletId,
        quizHistoryId,
        calculatedTime: timeSpent,
        storedTime: quiz.total_time,
        difference: timeSpent - quiz.total_time
      });
    }

    // Update testlet with results
    const { error: updateError } = await supabase
      .from('mock_exam_testlets')
      .update({
        completed: true,
        score: quiz.percentage_correct,
        time_spent: timeSpent,
        updated_at: new Date().toISOString()
      })
      .eq('id', testletId);

    if (updateError) {
      console.error('Error updating mock exam testlet:', updateError);
      throw updateError;
    }

    // Update parent mock exam's total time if available
    if (quiz.mock_exam_testlets?.mock_exam_id) {
      await this.updateMockExamTotalTime(quiz.mock_exam_testlets.mock_exam_id);
    }
  } catch (error) {
    console.error('Error in updateMockExamTestletFromQuizHistory:', error);
    throw error;
  }
},

async endMockExam(mockExamId: string): Promise<void> {
  // Mark the mock exam as completed with a score of 0
  const { error: mockExamError } = await supabase
    .from('mock_exams')
    .update({
      completed: true,
      score: 0,
      exam_day_score: 0,
      total_time: 14400 // 4 hours in seconds
    })
    .eq('id', mockExamId);

  if (mockExamError) throw mockExamError;
},

async deleteMockExam(mockExamId: string): Promise<void> {
  // First get all testlet IDs to find associated quiz histories
  const { data: testlets, error: testletsError } = await supabase
    .from('mock_exam_testlets')
    .select('id')
    .eq('mock_exam_id', mockExamId);

  if (testletsError) throw testletsError;

  // Delete associated quiz histories
  const { error: quizHistoryError } = await supabase
    .from('quiz_history')
    .delete()
    .in('mock_exam_testlet_id', testlets.map(t => t.id));

  if (quizHistoryError) throw quizHistoryError;

  // Delete testlets
  const { error: testletsDeleteError } = await supabase
    .from('mock_exam_testlets')
    .delete()
    .eq('mock_exam_id', mockExamId);

  if (testletsDeleteError) throw testletsDeleteError;

  // Finally delete the mock exam
  const { error: mockExamError } = await supabase
    .from('mock_exams')
    .delete()
    .eq('id', mockExamId);

  if (mockExamError) throw mockExamError;
},

async hasActiveMockExam(userId: string, courseId: string): Promise<boolean> {
  const { data, error } = await supabase
    .from('mock_exams')
    .select('id')
    .eq('user_id', userId)
    .eq('course_id', courseId)
    .eq('completed', false)
    .limit(1);

  if (error) throw error;
  return data && data.length > 0;
},


//END MOCK EXAMS












// CUSTOMER SUPPORT TICKETING SYSTEM

  // INTERNAL FEEDBACK FUNCTION FOR STUDY MATERIALS
  async saveFeedbackWithTicket(
    contentType: ContentType,
    contentId: string,
    feedbackText: string,
    userId: string,
    subject: string,
    userEmail?: string
  ): Promise<FeedbackResult> {
    try {
      // 1. First create the ticket
      const { ticket } = await this.createTicket(
        userId,
        subject,
        feedbackText,
        feedbackText,
        'medium',
        contentId,
        'web',
        [],
        contentType
      );
  
      if (!ticket) {
        throw new Error('Failed to create ticket');
      }
  
      // 2. Then save the feedback with the ticket ID
      const config = contentTypeConfig[contentType];
      
      // Verify content exists and get current feedback
      const { data: currentData, error: fetchError } = await supabase
        .from(config.tableName)
        .select('id, feedback, course_id')
        .eq('id', contentId)
        .single();
  
      if (fetchError) {
        // Mark ticket as closed with error
        await this.updateTicket(ticket.id, {
          status: 'closed',
          feedback_validity: 'no',
          feedback_reason: `Failed to fetch content: ${fetchError.message}`
        });
        throw new Error(`Failed to fetch current content: ${fetchError.message}`);
      }
  
      if (!currentData) {
        // Mark ticket as closed with error
        await this.updateTicket(ticket.id, {
          status: 'closed',
          feedback_validity: 'no',
          feedback_reason: `Content with id ${contentId} not found`
        });
        throw new Error(`Content with id ${contentId} not found`);
      }
  
      // Create new feedback item with ticket ID
      const newFeedback: ContentFeedback = {
        contentId,
        userId,
        feedbackText,
        createdAt: new Date().toISOString(),
        email: userEmail,
        ticketId: ticket.id
      };
  
      // Prepare feedback array
      const currentFeedback = Array.isArray(currentData.feedback) ? currentData.feedback : [];
      const updatedFeedback = [...currentFeedback, newFeedback];
  
      // Update record with new feedback array
      const { error: updateError } = await supabase
        .from(config.tableName)
        .update({ feedback: updatedFeedback })
        .match({ id: contentId });
  
      if (updateError) {
        // Mark ticket as closed with error
        await this.updateTicket(ticket.id, {
          status: 'closed',
          feedback_validity: 'no',
          feedback_reason: `Failed to save feedback: ${updateError.message}`
        });
        throw new Error(`Failed to save feedback: ${updateError.message}`);
      }
  
      return {
        ticketId: ticket.id,
        feedbackId: newFeedback.id
      };
    } catch (error) {
      console.error('Error in saveFeedbackWithTicket:', error);
      throw error;
    }
  },

  async getOrCreateSupportUser(email: string, name?: string): Promise<SupportUser> {
    try {
      const { data: existing, error: fetchError } = await supabase
        .from('support_users')
        .select('*')
        .eq('email', email)
        .single();

      if (!fetchError && existing) {
        // Check if we really need to update
        const needsUpdate = (
          // Only update if name is provided AND current name is missing or different
          (name && (!existing.full_name || existing.full_name === 'Future CPA')) ||
          // Or if somehow the full_name got set to the auth_user_id
          (existing.auth_user_id && existing.full_name === existing.auth_user_id)
        );

        if (needsUpdate) {
          const { data: updated, error: updateError } = await supabase
            .from('support_users')
            .update({ 
              full_name: name || existing.full_name || 'Future CPA',
              last_activity: new Date().toISOString()
            })
            .eq('id', existing.id)
            .select()
            .single();

          if (updateError) throw updateError;
          return updated;
        }

        // No update needed
        return existing;
      }

      // For new records
      const { data: created, error: createError } = await supabase
        .from('support_users')
        .insert({
          email,
          full_name: name || 'Future CPA',
          last_activity: new Date().toISOString(),
          metadata: {}
        })
        .select()
        .single();

      if (createError) throw createError;
      return created;
    } catch (error) {
      console.error('Error in getOrCreateSupportUser:', error);
      throw error;
    }
  },

  async getOrCreateStaffSupportUser(authUserId: string): Promise<SupportUser> {
    try {
      // First check if user exists in public.users and has proper role
      const { data: publicUser, error: userError } = await supabase
        .from('users')
        .select('id, email, full_name, role')
        .eq('id', authUserId)
        .single();

      if (userError || !publicUser) {
        throw new Error('User not found in public.users');
      }

      if (!['admin', 'moderator'].includes(publicUser.role)) {
        throw new Error('User does not have support staff permissions');
      }

      // Try to get existing support user by email
      const { data: existingSupportUser, error: supportError } = await supabase
        .from('support_users')
        .select('*')
        .eq('email', publicUser.email)
        .single();

      if (!supportError && existingSupportUser) {
        // Update auth_user_id if it's not set
        if (!existingSupportUser.auth_user_id) {
          const { data: updated, error: updateError } = await supabase
            .from('support_users')
            .update({ 
              auth_user_id: authUserId,
              full_name: publicUser.full_name || publicUser.email,
              last_activity: new Date().toISOString(),
              metadata: {
                ...existingSupportUser.metadata,
                role: publicUser.role,
                is_staff: true
              }
            })
            .eq('id', existingSupportUser.id)
            .select()
            .single();

          if (updateError) throw updateError;
          return updated;
        }
        return existingSupportUser;
      }

      // Create new support user for staff member
      const { data: created, error: createError } = await supabase
        .from('support_users')
        .insert({
          email: publicUser.email,
          full_name: publicUser.full_name || publicUser.email,
          auth_user_id: authUserId,
          last_activity: new Date().toISOString(),
          metadata: {
            role: publicUser.role,
            is_staff: true,
            created_from: 'admin_panel'
          }
        })
        .select()
        .single();

      if (createError) throw createError;
      return created;
    } catch (error) {
      console.error('Error in getOrCreateStaffSupportUser:', error);
      throw error instanceof Error 
        ? error 
        : new Error('Unknown error occurred while getting/creating staff support user');
    }
  },
  
   // Updated ticket functions
   async getTickets(
    filter: 'all' | 'mine' | 'urgent' = 'all',
    userId?: string,
    searchQuery?: string,
    contentId?: string
  ): Promise<Ticket[]> {
    try {
      let query = supabase
        .from('support_tickets')
        .select(`
          *,
          user:support_users!support_tickets_support_user_id_fkey(
            id,
            email,
            full_name,
            auth_user_id,
            metadata,
            email_domain,
            created_at,
            last_activity
          ),
          admin:users!support_tickets_admin_id_fkey(
            email,
            full_name
          ),
          messages:support_messages!support_messages_ticket_id_fkey(count)
        `)
        .order('last_activity_at', { ascending: false });

      if (filter === 'mine' && userId) {
        query = query.eq('admin_id', userId);
      } else if (filter === 'urgent') {
        query = query.eq('priority', 'urgent');
      }

      if (contentId) {
        query = query.eq('content_id', contentId);
      }

      if (searchQuery) {
        query = query.or(`
          subject.ilike.%${searchQuery}%,
          body.ilike.%${searchQuery}%,
          support_users.email.ilike.%${searchQuery}%,
          support_users.full_name.ilike.%${searchQuery}%
        `);
      }

      const { data, error } = await query;
      if (error) throw error;

      return data || [];
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw error;
    }
  },

  async createTicket(
    userId: string,
    subject: string,
    body: string,
    initialMessage: string,
    priority: Ticket['priority'] = 'medium',
    contentId?: string,
    source: 'web' | 'email' | 'api' = 'web',
    attachments: Array<{ name: string; url: string; type: string }> = [],
    // Add this parameter
    contentType?: ContentType
  ): Promise<TicketCreationResult> {
    try {
      // 1. First get or create support user from auth user
      const { data: userData, error: userError } = await supabase
        .from('support_users')
        .select('id, email, full_name, auth_user_id')
        .eq('auth_user_id', userId)
        .single();
  
      if (userError) throw userError;
  
      // 2. Simple check for any purchase record
      let ticketPriority = priority;
      if (userData.auth_user_id) {
        const { count } = await supabase
          .from('user_course_purchases')
          .select('id', { count: 'exact', head: true })
          .eq('user_id', userData.auth_user_id);
  
        if (count && count > 0) {
          ticketPriority = 'urgent';
        }
      }
  
      // 2. Generate message IDs for email threading
      const messageId = crypto.randomUUID();
      const emailMessageId = `<${messageId}@keslercpareview.com>`;
      
      // 3. Create the ticket with clean subject and auto-assigned admin
      const cleanSubject = subject
        .replace(/^Re:\s*/, '')
        .replace(/\[#[^\]]+\]\s*/, '')
        .replace(/\[Ticket #[^\]]+\]\s*/, '')
        .trim();
  
      const { data: ticketData, error: ticketError } = await supabase
        .from('support_tickets')
        .insert({
          support_user_id: userData.id,
          subject: cleanSubject,
          body,
          status: 'new',
          priority: ticketPriority,
          content_id: contentId,
          content_type: contentType, // Add this field
          source,
          has_unread: true,
          attachments,
          original_email_id: emailMessageId,
          email_thread_id: emailMessageId,
          thread_topic: cleanSubject,
          list_id: `ticket-${messageId}.support.keslercpareview.com`,
          original_from_email: userData.email,
          original_subject: cleanSubject,
          // Auto-assign to specific support agent
          admin_id: 'fa0639d8-74d7-4024-8576-2ca6aa12694a' // Default support agent ID
        })
        .select()
        .single();
  
      if (ticketError) throw ticketError;
  
      // Get admin user data for the response
      const { data: adminData } = await supabase
        .from('users')
        .select('email, full_name')
        .eq('id', 'fa0639d8-74d7-4024-8576-2ca6aa12694a')
        .single();
  
      const ticket = {
        ...ticketData,
        user: userData,
        admin: adminData || null
      };
  
      // 5. Add system message for web tickets
      if (source === 'web') {
        const { error: systemMessageError } = await supabase
          .from('support_messages')
          .insert({
            ticket_id: ticket.id,
            support_user_id: userData.id,
            content: `Ticket #${ticketData.short_id} created via web interface and automatically assigned to support team`,
            is_internal: true,
            message_type: 'system',
            email_message_id: emailMessageId,
            email_in_reply_to: null,
            email_references: [],
            from_email: 'system@keslercpareview.com',
            to_emails: null,
            email_subject: `[#${ticketData.short_id}] ${cleanSubject}`,
            email_timestamp: new Date().toISOString()
          });
  
        if (systemMessageError) throw systemMessageError;
      }
  
      return {
        ticket,
        message: null
      };
  
    } catch (error) {
      console.error('Error creating ticket:', error);
      throw error;
    }
  },

  // Add method to generate email message ID (utility function)
  generateEmailMessageId(prefix: string = ''): {
    messageId: string;
    emailMessageId: string;
  } {
    const uuid = crypto.randomUUID();
    const messageId = prefix ? `${prefix}-${uuid}` : uuid;
    const emailMessageId = `<${messageId}@keslercpareview.com>`;
    return { messageId, emailMessageId };
  },

  // Helper method to get proper email thread references
  getEmailThreadReferences(ticket: Ticket): string[] {
    const references: string[] = [];
    
    if (ticket.email_thread_id) {
      references.push(ticket.email_thread_id);
    }
    
    if (ticket.original_email_id && ticket.original_email_id !== ticket.email_thread_id) {
      references.push(ticket.original_email_id);
    }
    
    return references;
  },
  
  // Update existing ticket
  async updateTicket(
    ticketId: string,
    updates: TicketUpdate
  ): Promise<void> {
    const { error } = await supabase
      .from('support_tickets')
      .update({
        ...updates,
        updated_at: new Date().toISOString(),
        last_activity_at: new Date().toISOString(),
        ...(updates.status === 'closed' ? { closed_at: new Date().toISOString() } : {})
      })
      .eq('id', ticketId);

    if (error) throw error;
  },
  
  async getTicketById(ticketId: string): Promise<Ticket | null> {
    try {
      // First get the ticket
      const { data: ticketData, error: ticketError } = await supabase
        .from('support_tickets')
        .select('*')
        .eq('id', ticketId)
        .single();
  
      if (ticketError) throw ticketError;
      if (!ticketData) return null;
  
      // Then get the support user and admin data separately
      const { data: supportUser, error: supportUserError } = await supabase
        .from('support_users')
        .select('id, email, full_name, auth_user_id, metadata')
        .eq('id', ticketData.support_user_id)
        .single();
  
      if (supportUserError) {
        console.error('Error fetching support user:', supportUserError);
      }
  
      // Get admin data if exists
      let adminData;
      if (ticketData.admin_id) {
        const { data: adminUser, error: adminError } = await supabase
          .from('users')
          .select('id, email, full_name')
          .eq('id', ticketData.admin_id)
          .single();
  
        if (adminError) {
          console.error('Error fetching admin user:', adminError);
        } else {
          adminData = adminUser;
        }
      }
  
      // Build the complete ticket object
      const ticket = {
        ...ticketData,
        user: supportUser || { 
          id: ticketData.support_user_id,
          email: 'unknown',
          full_name: 'Unknown User',
          auth_user_id: null,
          metadata: {}
        },
        admin: adminData,
        _count: {
          messages: 0 // We'll update this if needed
        }
      };
  
      // Optionally get message count
      const { count: messageCount } = await supabase
        .from('support_messages')
        .select('*', { count: 'exact', head: true })
        .eq('ticket_id', ticketId);
  
      if (messageCount !== null) {
        ticket._count.messages = messageCount;
      }
  
      return ticket;
    } catch (error) {
      console.error('Error fetching ticket:', error);
      return null;
    }
  },

  async markMessageAsRead(messageId: string): Promise<void> {
    const { error } = await supabase
      .from('support_messages')
      .update({ is_read: true })
      .eq('id', messageId);

    if (error) throw error;
  },
  
  async markTicketMessagesAsRead(ticketId: string, supportUserId: string): Promise<void> {
    const { error } = await supabase
      .from('support_messages')
      .update({ is_read: true })
      .eq('ticket_id', ticketId)
      .neq('support_user_id', supportUserId);

    if (error) throw error;
  },

  // Get ticket messages
  async getTicketMessages(ticketId: string): Promise<Message[]> {
    const { data, error } = await supabase
      .from('support_messages')
      .select(`
        *,
        user:support_users!support_messages_support_user_id_fkey(
          id,
          email,
          full_name,
          auth_user_id,
          metadata,
          email_domain,
          created_at,
          last_activity
        )
      `)
      .eq('ticket_id', ticketId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  },

  // Add ticket message
  async addTicketMessage(
    ticketId: string,
    userId: string,
    content: string,
    isInternal: boolean = false,
    attachments: Array<{ name: string; url: string; type: string }> = []
  ): Promise<Message> {
    // First get or ensure support user exists
    const { data: supportUser } = await supabase
      .from('support_users')
      .select('id')
      .eq('auth_user_id', userId)
      .single();

    if (!supportUser) {
      throw new Error('Support user not found');
    }

    const { data, error } = await supabase
      .from('support_messages')
      .insert({
        ticket_id: ticketId,
        support_user_id: supportUser.id,
        content,
        is_internal: isInternal,
        attachments
      })
      .select(`
        *,
        user:support_users!support_messages_support_user_id_fkey(
          id,
          email,
          full_name,
          auth_user_id,
          metadata
        )
      `)
      .single();

    if (error) throw error;

    // Update ticket's last activity timestamp
    await this.updateTicket(ticketId, {
      last_activity_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    });

    return data;
  },

  async uploadTicketAttachment(
    ticketId: string,
    file: File
  ): Promise<{ url: string; path: string }> {
    const path = `support-attachments/${ticketId}/${Date.now()}-${file.name}`;
    
    const { error: uploadError } = await supabase.storage
      .from('support-attachments')
      .upload(path, file);

    if (uploadError) throw uploadError;

    const { data: { publicUrl } } = supabase.storage
      .from('support-attachments')
      .getPublicUrl(path);

    return { url: publicUrl, path };
  },

  async getEmailTemplates(): Promise<EmailTemplate[]> {
    const { data, error } = await supabase
      .from('email_templates')
      .select('*')
      .eq('is_active', true)
      .order('name');

    if (error) throw error;
    return data || [];
  },

  async createEmailTemplate(template: Omit<EmailTemplate, 'id' | 'created_at' | 'updated_at'>): Promise<EmailTemplate> {
    const { data, error } = await supabase
      .from('email_templates')
      .insert(template)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async deleteTicketAttachment(path: string): Promise<void> {
    const { error } = await supabase.storage
      .from('support-attachments')
      .remove([path]);

    if (error) throw error;
  },
  
  async sendTicketResponse(
    ticketId: string,
    userId: string,
    content: string,
    isInternal: boolean,
    attachments: File[] = [],
    subject?: string
  ): Promise<Message> {
    try {
      logger.debug('Starting sendTicketResponse:', { ticketId, userId, isInternal });
  
      if (!userId) {
        throw new Error('user_id is required for creating messages');
      }
  
      // 1. First get the support user
      logger.debug('Looking up support user:', userId);
      const { data: supportUser, error: supportError } = await supabase
        .from('support_users')
        .select('*')
        .eq('id', userId) // First try by direct ID
        .single() as { data: SupportUser | null, error: any };
  
      if (supportError || !supportUser) {
        console.error('Support user not found:', supportError);
        throw new Error('User not found in support system');
      }
  
      // 2. If this support user has an auth_user_id, verify admin status
      if (!isInternal && supportUser.auth_user_id) {
        logger.debug('Verifying admin status for auth_user_id:', supportUser.auth_user_id);
        const { data: publicUser, error: userError } = await supabase
          .from('users')
          .select('id, role')
          .eq('id', supportUser.auth_user_id)
          .single();
  
        logger.debug('Public user query result:', { publicUser, error: userError });
  
        if (userError || !publicUser) {
          console.error('Public user not found:', userError);
          throw new Error('Admin user not found');
        }
  
        if (!['admin', 'moderator'].includes(publicUser.role)) {
          throw new Error('Only admin users can send external messages');
        }
      } else if (!isInternal && !supportUser.auth_user_id) {
        // If no auth_user_id (customer) trying to send non-internal message
        throw new Error('Customers can only send internal messages');
      }
  
      // 3. Get Session Token
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError || !sessionData.session?.access_token) {
        throw new Error('Authentication required: No valid session found');
      }
      const accessToken = sessionData.session.access_token;
  
      // 4. Get Ticket Info
      interface TicketWithSupportUser {
        subject: string;
        support_user_id: string;
        original_email_id: string | null;
        email_thread_id: string | null;
        short_id: number;
        support_users: SupportUser;
      }
  
      const { data: ticketData, error: ticketError } = await supabase
        .from('support_tickets')
        .select(`
          subject,
          support_user_id,
          original_email_id,
          email_thread_id,
          short_id,
          support_users!inner (
            id,
            email,
            full_name,
            created_at,
            last_activity,
            auth_user_id,
            metadata,
            email_domain
          )
        `)
        .eq('id', ticketId)
        .single() as { data: TicketWithSupportUser | null; error: any };
  
      if (ticketError || !ticketData) {
        throw new Error(ticketError?.message || 'Ticket not found');
      }
  
      // 5. Generate message ID
      const messageId = crypto.randomUUID();
  
      // 6. Process Attachments
      const uploadedAttachments = await Promise.all(
        attachments.map(async (file) => {
          try {
            const result = await this.uploadTicketAttachment(ticketId, file);
            return {
              name: file.name,
              url: result.url,
              type: file.type || 'application/octet-stream'
            };
          } catch (error) {
            console.error(`Failed to upload attachment ${file.name}:`, error);
            return null;
          }
        })
      ).then(results => results.filter((result): result is NonNullable<typeof result> => result !== null));
  
      // 7. Create Message Record
      const { data: message, error: messageError } = await supabase
        .from('support_messages')
        .insert({
          ticket_id: ticketId,
          support_user_id: supportUser.id,
          content,
          is_internal: isInternal,
          attachments: uploadedAttachments,
          message_type: isInternal ? 'internal' : 'outbound',
          email_sent: !isInternal,
          email_sent_at: !isInternal ? new Date().toISOString() : null,
          sendgrid_message_id: !isInternal ? messageId : null,
          email_message_id: !isInternal ? `<${messageId}@keslercpareview.com>` : null,
          email_in_reply_to: !isInternal ? ticketData.original_email_id : null,
          email_references: !isInternal && ticketData.email_thread_id ? [ticketData.email_thread_id] : [],
          from_email: !isInternal ? 'support@keslercpareview.com' : null,
          to_emails: !isInternal ? [ticketData.support_users.email] : null,
          email_subject: !isInternal ? formatSubject(subject || ticketData.subject, ticketData.short_id.toString()) : null
        })
        .select()
        .single() as { data: Message | null; error: any };
  
      if (messageError || !message) {
        throw messageError || new Error('Failed to create message');
      }
  
      // 8. Send Email via SendGrid (if not internal)
      if (!isInternal) {
        const sendGridPayload = {
          ticketId,
          messageId,
          to: ticketData.support_users.email,
          subject: formatSubject(subject || ticketData.subject, ticketData.short_id.toString()),
          content: sanitizeContent(content),
          attachments: uploadedAttachments,
          recipientName: ticketData.support_users.full_name,
          fromName: `${supportUser.full_name}`,
          fromEmail: 'support@keslercpareview.com'
        };
  
        const response = await fetch(
          'https://jvidoqtewkukebhpabuv.supabase.co/functions/v1/sendgrid',
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendGridPayload)
          }
        );
  
        const responseData = await response.json();
  
        if (!responseData.success) {
          throw new Error(responseData.details || 'Failed to send email');
        }
  
        if (responseData.warning) {
          console.warn('SendGrid warning:', responseData.warning);
        }
      }
  
      // 9. Update support user activity
      await supabase
        .from('support_users')
        .update({ last_activity: new Date().toISOString() })
        .eq('id', supportUser.id);
  
      return {
        ...message,
        user: supportUser
      };
  
    } catch (error) {
      console.error('Error in sendTicketResponse:', error);
      throw error;
    }
  },  

  // Function to get tickets dashboard statistics
  async getTicketStats(): Promise<{
    open: number;
    pending: number;
    closed: number;
    urgent: number;
    unassigned: number;
  }> {
    const { data, error } = await supabase.rpc('get_ticket_stats');
    
    if (error) throw error;
    return data || {
      open: 0,
      pending: 0,
      closed: 0,
      urgent: 0,
      unassigned: 0
    };
  },

  // FEEDBACK 

  /**
 * Fetch tickets that have a content_type (i.e. user feedback on study content).
 * Return an array of { ticket, productTitle } so the front-end can list them.
 */
  async getFeedbackTickets(): Promise<{ticket: Ticket; productTitle: string | null}[]> {
    try {
      // Try to get from cache first
      const cachedData = cacheManager.get<{ticket: Ticket; productTitle: string | null}[]>(CACHE_KEYS.FEEDBACK_LIST);
      if (cachedData) {
        return cachedData;
      }

      const { data, error } = await supabase
        .from('support_tickets')
        .select('*')
        .not('content_type', 'is', null)
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      if (!data || data.length === 0) return [];

      const results: { ticket: Ticket; productTitle: string | null }[] = [];
      for (const t of data) {
        let productTitle: string | null = null;
        if (t.content_type && t.content_id) {
          productTitle = await api.getProductTitleForContent(t.content_type, t.content_id);
        }
        results.push({ ticket: t as Ticket, productTitle });
      }

      // Store in cache
      cacheManager.set(CACHE_KEYS.FEEDBACK_LIST, results);
      return results;
    } catch (error) {
      console.error('getFeedbackTickets error:', error);
      throw error;
    }
  },

/**
 * Helper to get the product title from the content table (questions, lessons, flashcards, etc.)
 */
async getProductTitleForContent(
  contentType: ContentType,
  contentId: string
): Promise<string | null> {
  try {
    const cacheKey = `productTitle_${contentType}_${contentId}`;
    const cached = cacheManager.get<string>(cacheKey);
    if (cached !== null) {
      return cached;
    }

    const configMap: Record<ContentType, { table: string }> = {
      questions: { table: 'questions' },
      lessons: { table: 'lessons' },
      simulations: { table: 'simulations' },
      flashcards: { table: 'flashcards' },
    };
    const tableName = configMap[contentType].table;

    const { data, error } = await supabase
      .from(tableName)
      .select('course_id')
      .eq('id', contentId)
      .single();
    
    if (error) throw error;
    if (!data?.course_id) return null;

    const { data: productData, error: productError } = await supabase
      .from('products')
      .select('title')
      .eq('id', data.course_id)
      .single();
    
    if (productError) throw productError;
    if (!productData) return null;

    const title = productData.title || null;
    cacheManager.set(cacheKey, title);
    return title;
  } catch (error) {
    console.error('getProductTitleForContent error:', error);
    return null;
  }
},

/**
 * Update the feedback validity of a ticket
 */
async updateTicketFeedbackValidity(
  ticketId: string,
  validity: 'pending' | 'yes' | 'no',
  reason: string
): Promise<void> {
  try {
    const { error } = await supabase
      .from('support_tickets')
      .update({
        feedback_validity: validity,
        feedback_reason: reason,
        feedback_resolved: false
      })
      .eq('id', ticketId);

    if (error) throw error;

    // Invalidate relevant caches
    cacheManager.clear(CACHE_KEYS.FEEDBACK_LIST);
    cacheManager.clear(CACHE_KEYS.FEEDBACK_ITEM(ticketId));

    // If using React Query, also invalidate queries
    queryClient.invalidateQueries(CACHE_KEYS.FEEDBACK_LIST);
    queryClient.invalidateQueries(['feedback', ticketId]);
  } catch (error) {
    console.error('Error updating feedback validity:', error);
    throw new Error('Failed to update feedback validity');
  }
},

/**
 * Returns the relevant columns as HTML from the specified table
 */
async getEditableContentAsHTML(contentType: ContentType, contentId: string): Promise<string> {
  const { data } = await this.fetchContentRow(contentType, contentId);

  if (contentType === 'lessons') {
    return data.content || '';
  } else if (contentType === 'flashcards') {
    return `<p>${(data.side_1_content || '')}</p><hr/><p>${(data.side_2_content || '')}</p>`;
  } else if (contentType === 'questions') {
    const qContent = data.question_content || '';
    const aContent = data.answer_content || '';
    return `<h3>Question</h3><div>${qContent}</div><hr/><h3>Answer</h3><div>${aContent}</div>`;
  } else if (contentType === 'simulations') {
    const qHtml = data.html || '';
    const aHtml = data.answer_content || '';
    return `<div>${qHtml}</div><hr/><div>${aHtml}</div>`;
  }
  return '';
},

/**
 * Saves an HTML string back to the table
 * 1. We fetch old data for version control.
 * 2. We parse the new HTML to store it in the correct columns.
 * 3. We store an entry in content_version_control for revert capability.
 */
async saveEditableContentAsHTML(
  contentType: ContentType,
  contentId: string,
  newHTML: string
): Promise<void> {
  const { data: oldData } = await this.fetchContentRow(contentType, contentId);
  const { updatedFields } = await this.parseHTMLToFields(contentType, newHTML, oldData);
  await this.saveVersionControlRecord(contentType, contentId, oldData, updatedFields);

  const table = this.contentTypeToTableName(contentType);
  const { error } = await supabase
    .from(table)
    .update(updatedFields)
    .eq('id', contentId);
  if (error) throw error;
},

/**
 * Returns the raw text or JSON from the specified table row
 */
async getEditableContentRaw(contentType: ContentType, contentId: string): Promise<string> {
  const { data } = await this.fetchContentRow(contentType, contentId);
  return JSON.stringify(data, null, 2);
},

/**
 * Saves raw string (JSON or HTML) to the specified table row
 */
async saveEditableContentRaw(
  contentType: ContentType,
  contentId: string,
  rawString: string
): Promise<void> {
  const { data: oldData } = await this.fetchContentRow(contentType, contentId);

  let newObj: Record<string, any>;
  try {
    newObj = JSON.parse(rawString);
  } catch (err) {
    throw new Error('Invalid JSON format. Could not parse raw content.');
  }

  await this.saveVersionControlRecord(contentType, contentId, oldData, newObj);

  const table = this.contentTypeToTableName(contentType);
  const { error } = await supabase
    .from(table)
    .update(newObj)
    .eq('id', contentId);
  if (error) throw error;
},


/* --------------------------------------------------------------------------
   CATEGORY MAPPING
   -------------------------------------------------------------------------- */

/**
 * Return an array of categories for the given content type.
 * (You can unify them into one table or do multiple queries.)
 */
async getCategoriesForContentType(
  contentType: ContentType
): Promise<Array<{id: string; name: string}>> {
  // Example only
  // In real usage, you might do a different query depending on contentType
  let categoryTable;
  switch (contentType) {
    case 'questions':
      categoryTable = 'question_categories';
      break;
    case 'lessons':
      categoryTable = 'lesson_categories';
      break;
    case 'simulations':
      categoryTable = 'simulation_categories';
      break;
    case 'flashcards':
      categoryTable = 'flashcard_categories';
      break;
    default:
      return [];
  }
  const { data, error } = await supabase
    .from(categoryTable)
    .select('id, name')
    .order('name');
  if (error) throw error;
  return data || [];
},

/**
 * Return the current category ID of the specified content
 */
async getCurrentCategory(
  contentType: ContentType,
  contentId: string
): Promise<string | null> {
  const { data } = await this.fetchContentRow(contentType, contentId);
  
  switch (contentType) {
    case 'questions':
      return data.question_category_id || null;
    case 'lessons':
      return data.lesson_category_id || null;
    case 'flashcards':
      return data.flash_card_category_id || null;
    case 'simulations':
      return data.question_category_id || null;
    default:
      return null;
  }
},

/**
 * Update the content record with the given category ID
 */
async updateContentCategory(
  contentType: ContentType,
  contentId: string,
  newCategoryId: string
): Promise<void> {
  const table = this.contentTypeToTableName(contentType);

  let columnName: string;
  switch (contentType) {
    case 'questions':
      columnName = 'question_category_id';
      break;
    case 'lessons':
      columnName = 'lesson_category_id';
      break;
    case 'flashcards':
      columnName = 'flash_card_category_id';
      break;
    case 'simulations':
      columnName = 'question_category_id';
      break;
    default:
      throw new Error('Invalid content type');
  }

  const { data: oldData } = await this.fetchContentRow(contentType, contentId);

  const { error } = await supabase
    .from(table)
    .update({ [columnName]: newCategoryId })
    .eq('id', contentId);
  if (error) throw error;

  const newData = { ...oldData, [columnName]: newCategoryId };
  await this.saveVersionControlRecord(contentType, contentId, oldData, newData);
},

// Add to your API service
async resolveFeedback(contentType: string, contentId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('support_tickets')
      .update({
        feedback_resolved: true,
        status: 'closed',
        closed_at: new Date().toISOString()
      })
      .eq('content_type', contentType)
      .eq('content_id', contentId);

    if (error) throw error;

    // Invalidate caches
    cacheManager.clear(CACHE_KEYS.FEEDBACK_LIST);
    queryClient.invalidateQueries(CACHE_KEYS.FEEDBACK_LIST);
  } catch (error) {
    console.error('Error resolving feedback:', error);
    throw new Error('Failed to resolve feedback');
  }
},

// Add new method to api service for getting ticket by feedback ID
async getTicketByFeedbackId(feedbackId: string): Promise<Ticket | null> {
  try {
    const cacheKey = CACHE_KEYS.FEEDBACK_ITEM(feedbackId);
    const cached = cacheManager.get<Ticket>(cacheKey);
    if (cached) {
      return cached;
    }

    const { data: feedbackData, error: feedbackError } = await supabase
      .from('content_feedback')
      .select('ticket_id')
      .eq('id', feedbackId)
      .single();

    if (feedbackError || !feedbackData?.ticket_id) {
      return null;
    }

    const { data: ticket, error: ticketError } = await supabase
      .from('support_tickets')
      .select(`
        *,
        user:support_users!support_tickets_support_user_id_fkey (
          id,
          email,
          full_name,
          auth_user_id,
          metadata
        ),
        admin:users!support_tickets_admin_id_fkey (
          email,
          full_name
        )
      `)
      .eq('id', feedbackData.ticket_id)
      .single();

    if (ticketError) {
      console.error('Error fetching ticket:', ticketError);
      return null;
    }

    if (ticket) {
      cacheManager.set(cacheKey, ticket);
    }

    return ticket;
  } catch (error) {
    console.error('Error in getTicketByFeedbackId:', error);
    return null;
  }
},

// Add migration function to ensure all feedback entries have IDs
async ensureFeedbackIds(contentType: ContentType, contentId: string): Promise<void> {
  try {
    const table = contentTypeConfig[contentType].tableName;
    
    // Get current feedback array
    const { data: content, error: fetchError } = await supabase
      .from(table)
      .select('feedback')
      .eq('id', contentId)
      .single();

    if (fetchError || !content) {
      throw fetchError;
    }

    // Update feedback items to ensure they have IDs and contentId
    const updatedFeedback = (content.feedback || []).map((item: ContentFeedback) => ({
      ...item,
      id: item.id || crypto.randomUUID(),
      contentId: contentId
    }));

    // Save back to database
    const { error: updateError } = await supabase
      .from(table)
      .update({ feedback: updatedFeedback })
      .eq('id', contentId);

    if (updateError) {
      throw updateError;
    }
  } catch (error) {
    console.error('Error ensuring feedback IDs:', error);
    throw error;
  }
},

/* --------------------------------------------------------------------------
   INTERNAL HELPERS
   -------------------------------------------------------------------------- */

/**
 * Helper to fetch a single row from the relevant content table
 */
async updateContentFields(
  contentType: ContentType,
  contentId: string,
  updates: Record<string, any>
): Promise<{ error: Error | null }> {
  try {
    const table = this.contentTypeToTableName(contentType);
    
    // Get current data for version control
    const { data: oldData } = await this.fetchContentRow(contentType, contentId);
    
    // Update the content
    const { error } = await supabase
      .from(table)
      .update(updates)
      .eq('id', contentId);

    if (error) throw error;

    // Save version control record
    await this.saveVersionControlRecord(
      contentType,
      contentId,
      oldData,
      { ...oldData, ...updates }
    );

    return { error: null };
  } catch (error) {
    console.error('Error updating content fields:', error);
    return { error: error as Error };
  }
},

// Update the fetchContentRow function to include HTML parsing if needed
async fetchContentRow(
  contentType: ContentType,
  contentId: string
): Promise<{ data: Record<string, any>; error: Error | null }> {
  try {
    const tableName = this.contentTypeToTableName(contentType);
    const { data, error } = await supabase
      .from(tableName)
      .select('*')
      .eq('id', contentId)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Content not found');

    return { data, error: null };
  } catch (error) {
    console.error('Error fetching content row:', error);
    return { data: {}, error: error as Error };
  }
},

contentTypeToTableName(contentType: ContentType): string {
  return contentTypeConfig[contentType].tableName;
},

/**
 * Parse HTML to fields for storing in supabase
 */
async parseHTMLToFields(
  contentType: ContentType,
  newHTML: string,
  oldData: Record<string, any>
): Promise<{ updatedFields: Record<string, any> }> {
  if (contentType === 'lessons') {
    return { updatedFields: { content: newHTML } };
  } else if (contentType === 'flashcards') {
    const [side1, side2] = newHTML.split('<hr/>');
    return {
      updatedFields: {
        side_1_content: this.stripHtml(side1 || ''),
        side_2_content: this.stripHtml(side2 || ''),
      },
    };
  } else if (contentType === 'questions') {
    return { updatedFields: { question_content: newHTML } };
  } else if (contentType === 'simulations') {
    return { updatedFields: { html: newHTML } };
  }
  return { updatedFields: {} };
}
,

stripHtml(html: string): string {
  return html.replace(/<[^>]*>/g, '').trim();
},


  // VERSION CONTROL

/**
 * Save a record of the old data vs. new data for version control.
 * The new_value can be partial or entire row, depending on your preference.
 * This can store them as JSON strings.
 */
/**
   * Creates a version control record when content is updated
   */
async saveVersionControlRecord(
  contentType: ContentType,
  contentId: string,
  oldData: Record<string, any>,
  newData: Record<string, any>,
  reason?: string
): Promise<void> {
  const user = await auth.getCurrentUser();
  if (!user) throw new Error('User must be authenticated');

  // Get the course ID if it exists in the data
  const courseId = oldData.course_id || newData.course_id || null;

  // Get category information
  const categoryId = oldData.category_id || newData.category_id || null;
  const categoryName = oldData.category_name || newData.category_name || null;
  const subCategoryName = oldData.sub_category_name || newData.sub_category_name || null;

  // Create the base version record
  const baseRecord = {
    content_type: contentType,
    content_id: contentId,
    course_id: courseId,
    category_id: categoryId,
    category_name: categoryName,
    sub_category_name: subCategoryName,
    updated_at: new Date().toISOString(),
    updated_by: user.id,
    reason: reason || null,
    raw_content: newData, // Store complete new state
  };

  // Add content-specific fields based on content type
  let specificFields: Record<string, any> = {};
  
  switch (contentType) {
    case 'flashcards':
      specificFields = {
        side_1_content: newData.side_1_content,
        side_2_content: newData.side_2_content,
      };
      break;
    
    case 'lessons':
      specificFields = {
        content: newData.content,
      };
      break;
    
    case 'questions':
      specificFields = {
        question_content: newData.question_content,
        answer_content: newData.answer_content,
      };
      break;
    
    case 'simulations':
      specificFields = {
        question_content_jsonb: newData.question_content,
        simulation_html: newData.html,
        correct_answer: newData.correct_answer,
        exhibits: newData.exhibits,
        external_id: newData.external_id,
      };
      break;
  }

  const record = {
    ...baseRecord,
    ...specificFields,
  };

  const { error } = await supabase
    .from('content_version_control')
    .insert(record);

  if (error) {
    console.error('Failed to save version control record:', error);
    throw error;
  }
},

/**
 * Retrieves version history for a specific content item
 */
async getVersionHistory(
  contentType: ContentType,
  contentId: string
): Promise<VersionControlRecord[]> {
  const { data, error } = await supabase
    .from('content_version_control')
    .select(`
      id,
      version_number,
      updated_at,
      updated_by,
      reason,
      content_type,
      content_id,
      course_id,
      category_id,
      category_name,
      sub_category_name,
      side_1_content,
      side_2_content,
      content,
      question_content,
      answer_content,
      question_content_jsonb,
      simulation_html,
      correct_answer,
      exhibits,
      external_id,
      raw_content
    `)
    .eq('content_type', contentType)
    .eq('content_id', contentId)
    .order('version_number', { ascending: false });

  if (error) throw error;
  return data as VersionControlRecord[];
},

/**
 * Restores content to a specific version
 */
async restoreVersion(
  versionId: string
): Promise<void> {
  // Fetch the version record
  const { data: versionRecord, error: fetchError } = await supabase
    .from('content_version_control')
    .select('*')
    .eq('id', versionId)
    .single();

  if (fetchError || !versionRecord) {
    throw new Error('Failed to fetch version record');
  }

  const tableName = this.contentTypeToTableName(versionRecord.content_type);
  const contentToRestore = versionRecord.raw_content;

  // Update the content in its respective table
  const { error: updateError } = await supabase
    .from(tableName)
    .update(contentToRestore)
    .eq('id', versionRecord.content_id);

  if (updateError) {
    throw new Error('Failed to restore version');
  }

  // Create a new version record for this restoration
  await this.saveVersionControlRecord(
    versionRecord.content_type,
    versionRecord.content_id,
    {}, // We don't need the old data since we're creating a restore point
    contentToRestore,
    `Restored to version ${versionRecord.version_number}`
  );
},

/**
 * Gets a specific version's details
 */
async getVersionDetails(
  versionId: string
): Promise<VersionControlRecord> {
  const { data, error } = await supabase
    .from('content_version_control')
    .select('*')
    .eq('id', versionId)
    .single();

  if (error || !data) {
    throw new Error('Failed to fetch version details');
  }

  return data as VersionControlRecord;
},

/**
 * Compare two versions of content
 */
async compareVersions(
  versionId1: string,
  versionId2: string
): Promise<{
  version1: VersionControlRecord;
  version2: VersionControlRecord;
}> {
  const [version1, version2] = await Promise.all([
    this.getVersionDetails(versionId1),
    this.getVersionDetails(versionId2),
  ]);

  return { version1, version2 };
},




};