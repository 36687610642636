import React, { createContext, useContext, useEffect } from 'react';

// Simple utility to join classNames
const joinClassNames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(' ');
};

interface DrawerContextType {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  side?: 'left' | 'right';
  width?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
}

const DrawerContext = createContext<DrawerContextType>({
  open: false,
  onOpenChange: () => {},
  side: 'right',
  width: 'md'
});

interface DrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  side?: 'left' | 'right';
  width?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
}

const widthClasses = {
  sm: 'w-full sm:w-[480px]',
  md: 'w-full sm:w-[66%]',
  lg: 'w-full sm:w-[75%]',
  xl: 'w-full sm:w-[90%]',
  '2xl': 'w-full sm:w-[95%]',
  'full': 'w-full'
} as const;

export const Drawer: React.FC<DrawerProps> = ({
  open = false,
  onOpenChange,
  children,
  side = 'right',
  width = 'md'
}) => {
  return (
    <DrawerContext.Provider value={{ open, onOpenChange: onOpenChange || (() => {}), side, width }}>
      {children}
    </DrawerContext.Provider>
  );
};

interface DrawerTriggerProps {
  asChild?: boolean;
  children: React.ReactNode;
}

export const DrawerTrigger: React.FC<DrawerTriggerProps> = ({ 
  asChild = false,
  children 
}) => {
  const { onOpenChange } = useContext(DrawerContext);
  
  if (asChild) {
    return React.cloneElement(children as React.ReactElement, {
      onClick: () => onOpenChange(true)
    });
  }

  return (
    <button type="button" onClick={() => onOpenChange(true)}>
      {children}
    </button>
  );
};

interface DrawerContentProps {
  className?: string;
  children: React.ReactNode;
}

export const DrawerContent: React.FC<DrawerContentProps> = ({ 
  className = '',
  children 
}) => {
  const { open, onOpenChange, side, width } = useContext(DrawerContext);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onOpenChange(false);
      }
    };

    if (open) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [open, onOpenChange]);

  if (!open) return null;

  const sideClasses = side === 'left' ? 'left-0' : 'right-0';
  const widthClass = widthClasses[width as keyof typeof widthClasses] || widthClasses.md;
  
  return (
    <div className="fixed inset-0 z-50">
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/50 transition-opacity"
        onClick={() => onOpenChange(false)}
      />
      
      {/* Drawer panel */}
      <div 
        className={joinClassNames(
          'fixed inset-y-0 bg-white dark:bg-gray-900 shadow-xl',
          'transition-transform duration-300 ease-in-out',
          widthClass,
          sideClasses,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

interface DrawerSectionProps {
  className?: string;
  children: React.ReactNode;
}

export const DrawerHeader: React.FC<DrawerSectionProps> = ({ 
  className = '',
  children 
}) => (
  <div className={joinClassNames('p-4 border-b dark:border-gray-700', className)}>
    {children}
  </div>
);

export const DrawerFooter: React.FC<DrawerSectionProps> = ({ 
  className = '',
  children 
}) => (
  <div className={joinClassNames('p-4 border-t dark:border-gray-700', className)}>
    {children}
  </div>
);

export const DrawerTitle: React.FC<DrawerSectionProps> = ({ 
  className = '',
  children 
}) => (
  <h2 className={joinClassNames('text-lg font-semibold', className)}>
    {children}
  </h2>
);

export const DrawerDescription: React.FC<DrawerSectionProps> = ({ 
  className = '',
  children 
}) => (
  <p className={joinClassNames('text-sm text-gray-500 dark:text-gray-400', className)}>
    {children}
  </p>
);