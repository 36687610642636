import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import { useUserCourseAccess } from '../components/UserCourseAccessProvider';
import DigitalProductView from '../components/digitalProducts/DigitalProductView';
import { TIER_IDS, COURSE_IDS } from '../services/api';
import { createLogger } from '../utils/Logger';

const logger = createLogger({ namespace: 'DigitalProducts.tsx' });

interface ProductConfig {
  title: string;
  description: string;
  downloadUrl?: string;
  audioUrl?: string;
  requiredTier: string;
  type: 'download' | 'copilot' | 'study-tips';
  allowedTiers?: string[];
}

const DIGITAL_PRODUCTS: Record<string, ProductConfig> = {
  [COURSE_IDS.COPILOT]: {
    title: "CPA Exam Copilot",
    description: "Your AI-powered study companion for the CPA exam. Get personalized study strategies and instant answers to your exam-related questions.",
    requiredTier: TIER_IDS.COPILOT,
    type: 'copilot',
    allowedTiers: [TIER_IDS.COPILOT, TIER_IDS.FREE_TRIAL]
  },
  [COURSE_IDS.STUDY_TIPS]: {
    title: "221 Advanced Study Tips & Strategies",
    description: "Exclusive study tips and strategies from successful CPA candidates. Learn how to maximize your study efficiency and boost your exam performance.",
    downloadUrl: "/downloads/study-tips.pdf",
    audioUrl: "https://drive.google.com/file/d/17NGEHELRXrYtsKTPDSQh_nE1O8DAkU_2/view?usp=drive_link",
    requiredTier: TIER_IDS.STUDY_TIPS,
    type: 'study-tips'
  }
};

const DigitalProducts: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const { user } = useAuth();
  const { userCourseAccesses } = useUserCourseAccess();

  if (!productId || !DIGITAL_PRODUCTS[productId]) {
    logger.debug('Product not found');
    return <div>Product not found</div>;
  }

  const product = DIGITAL_PRODUCTS[productId];
  const courseAccess = userCourseAccesses.find(
    access => access.course_id === productId
  );
  
  const hasAccess = product.allowedTiers 
    ? product.allowedTiers.includes(courseAccess?.tier_id || '')
    : courseAccess?.tier_id === product.requiredTier;

  if (!user) {
    logger.debug('No user found');
    return null;
  }

  return (
    <DigitalProductView
      productId={productId}
      title={product.title}
      description={product.description}
      downloadUrl={product.downloadUrl}
      audioUrl={product.audioUrl}
      hasAccess={hasAccess}
      type={product.type}
      tierId={courseAccess?.tier_id}
    />
  );
};

export default DigitalProducts;