import React, { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../ui/Select';
import { Button } from '../../ui/Button';
import { api, ContentType } from '../../../services/api';
import { Card } from '../../ui/Card';

interface QuestionCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

interface SimulationCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

interface LessonCategory {
  id: string;
  course_id: string;
  name: string;
  parent_lesson_category_id: string | null;
  category_number: number;
}

interface FlashcardCategory {
  id: string;
  course_id: string;
  parent_flashcard_category_name: string;
  sub_flashcard_category_name: string;
  sequence_number: number;
}

type Category = QuestionCategory | SimulationCategory | LessonCategory | FlashcardCategory;

interface CategoryMapperProps {
  contentType: ContentType;
  contentId: string;
  courseId: string;
}

const CategoryMapper: React.FC<CategoryMapperProps> = ({ contentType, contentId, courseId }) => {
  const [availableCategories, setAvailableCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (courseId) {
      loadCategories();
      loadCurrentCategory();
    }
  }, [contentType, contentId, courseId]);

  async function loadCategories() {
    try {
      setLoading(true);
      let categories: Category[] = [];
      
      switch (contentType) {
        case 'questions':
          categories = await api.getQuestionCategories(courseId);
          break;
        case 'simulations':
          categories = await api.getSimulationCategories(courseId);
          break;
        case 'lessons':
          categories = await api.getLessonCategories(courseId);
          break;
        case 'flashcards':
          categories = await api.getFlashcardCategories(courseId);
          break;
      }
      
      // Sort categories based on their display names
      categories.sort((a, b) => getCategoryLabel(a).localeCompare(getCategoryLabel(b)));
      setAvailableCategories(categories);
    } catch (error) {
      console.error('Error loading categories:', error);
    } finally {
      setLoading(false);
    }
  }

  async function loadCurrentCategory() {
    try {
      const currentCatId = await api.getCurrentCategory(contentType, contentId);
      if (currentCatId) setSelectedCategory(currentCatId);
    } catch (error) {
      console.error('Error loading current category:', error);
    }
  }

  async function handleSave() {
    if (!selectedCategory) return;
    try {
      setSaving(true);
      await api.updateContentCategory(contentType, contentId, selectedCategory);
    } catch (error) {
      console.error('Error updating category:', error);
    } finally {
      setSaving(false);
    }
  }

  const getCategoryLabel = (category: Category): string => {
    switch (contentType) {
      case 'questions':
      case 'simulations': {
        const cat = category as QuestionCategory | SimulationCategory;
        return `${cat.parent_question_category_name} › ${cat.sub_question_category_name}`;
      }
      case 'lessons': {
        const cat = category as LessonCategory;
        return cat.name;
      }
      case 'flashcards': {
        const cat = category as FlashcardCategory;
        return `${cat.parent_flashcard_category_name} › ${cat.sub_flashcard_category_name}`;
      }
      default:
        return '';
    }
  };

  const getSelectedCategoryLabel = (): string => {
    const category = availableCategories.find(cat => cat.id === selectedCategory);
    return category ? getCategoryLabel(category) : 'Select category';
  };

  if (loading) {
    return (
      <Card>
        <div className="p-3 flex items-center gap-2 text-sm text-gray-500 dark:text-gray-300">
          <Loader2 className="w-4 h-4 animate-spin" />
          Loading categories...
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="p-3">
        <div className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
          Category Assignment
        </div>
        <div className="flex flex-col gap-2">
          <Select
            value={selectedCategory}
            onValueChange={(val) => setSelectedCategory(val)}
          >
            <SelectTrigger className="w-full text-sm h-9">
              <SelectValue>
                {getSelectedCategoryLabel()}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {availableCategories.map((cat) => (
                <SelectItem 
                  key={cat.id} 
                  value={cat.id}
                  className="text-sm"
                >
                  {getCategoryLabel(cat)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button
            onClick={handleSave}
            disabled={!selectedCategory || saving}
            className="w-full h-9 text-sm"
          >
            {saving ? (
              <span className="flex items-center gap-1.5">
                <Loader2 className="w-4 h-4 animate-spin" />
                Saving...
              </span>
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default CategoryMapper;