import React, { useContext, useEffect } from 'react';
import { Modal } from '../../../ui/Modal';
import { Button } from '../../../ui/Button';
import { ModalContext } from '../../../../App';
import { useAuth } from '../../../AuthProvider';
import { BookOpen, Check } from 'lucide-react';

interface UpgradeSMOnlyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UpgradeSMOnlyModal: React.FC<UpgradeSMOnlyModalProps> = ({
  isOpen,
  onClose
}) => {
  const { setIsModalOpen } = useContext(ModalContext);
  const { user } = useAuth();

  useEffect(() => {
    setIsModalOpen(isOpen);
    return () => setIsModalOpen(false);
  }, [isOpen, setIsModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const getThrivecartUrl = () => {
    const baseUrl = 'https://sales.cpaexamguide.com/study-materials-only/';
    const params = new URLSearchParams();

    if (user) {
      const { email, full_name } = user;
      if (email) params.append('passthrough[customer_email]', email);
      if (full_name) params.append('passthrough[customer_name]', full_name);
    }

    return `${baseUrl}?${params.toString()}`;
  };

  const handleUpgradeClick = () => {
    if (!user) {
      console.warn('User not authenticated');
      return;
    }
    
    const url = getThrivecartUrl();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          {user?.full_name ? `Upgrade Study Materials for ${user.full_name}` : 'Upgrade to Study Materials'}
        </h2>
        
        <p className="mb-6 text-base text-gray-600 dark:text-gray-300">
          Unlock full access to all study materials while keeping your mentor access.
        </p>

        <div className="mb-6 space-y-3">
          <div className="flex items-center space-x-3">
            <Check className="w-5 h-5 text-green-500" />
            <span className="text-gray-700 dark:text-gray-300">3,800+ Multiple Choice Questions</span>
          </div>
          <div className="flex items-center space-x-3">
            <Check className="w-5 h-5 text-green-500" />
            <span className="text-gray-700 dark:text-gray-300">3,900+ Digital Flashcards</span>
          </div>
          <div className="flex items-center space-x-3">
            <Check className="w-5 h-5 text-green-500" />
            <span className="text-gray-700 dark:text-gray-300">60+ Task-Based Simulations</span>
          </div>
          <div className="flex items-center space-x-3">
            <Check className="w-5 h-5 text-green-500" />
            <span className="text-gray-700 dark:text-gray-300">Unlimited Custom Notes & Flashcards</span>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <Button
            onClick={handleUpgradeClick}
            disabled={!user}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors duration-200 flex items-center justify-center"
          >
            <BookOpen className="w-5 h-5 mr-2" />
            Upgrade Study Materials
          </Button>
          <Button
            onClick={handleClose}
            variant="outline"
            className="w-full bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeSMOnlyModal;