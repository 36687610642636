import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  api, 
  QuizHistory, 
  QuizQuestionData, 
  SimulationQuestionData,
  MockExamSimulationQuestionData,
  MockExamMCQQuestionData,
  isMockExamSimulation,
  isSimulationQuestion,
  isStudyTaskTestlet,
  StudyTaskTestletType,
  isMockExamMCQ,
  TIER_IDS
} from '../../services/api';
import { auth } from '../../services/auth';
import LoadingScreen from './common/LoadingScreen';
import { useQuizHistory } from '../../hooks/useQuizHistory';
import QuizHistoryTable from './AnalyticsQuizCards';
import Pagination from './common/Pagination';
import UpgradeModal from './common/UpgradeModal';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { Crown, Lock } from 'lucide-react';
import { Button } from '../ui/Button';
import AnalyticsOverviewCard from './AnalyticsOverviewCard';
import { useQuizData } from '../../hooks/useQuizData';
import { useSimulationData } from '../../hooks/useSimulationData';
import { createLogger } from '../../utils/Logger';

const logger = createLogger({ namespace: 'analytics' });

interface AnalyticsProps {
  courseId: string;
  userId: string;
}

function Analytics({ courseId, userId }: AnalyticsProps) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [selectedType, setSelectedType] = useState(() => {
    // Get filter type from localStorage or default to 'all'
    const savedType = localStorage.getItem('quizFilterType');
    return savedType || 'all';
  });
  const [currentPage, setCurrentPage] = useState(() => {
    // Get page from localStorage or default to 1
    const savedPage = localStorage.getItem('quizCurrentPage');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [specialOfferProductId, setSpecialOfferProductId] = useState<string | undefined>(undefined);
  const { userCourseAccesses, isLoading: accessLoading } = useUserCourseAccess();
  const quizzesPerPage = 21;
  const initialFetchDone = useRef(false);
  // Fetch all data at once
  const { categoriesData, categoriesLoading } = useQuizData(courseId, userId);
  const { simulationData, isLoading: simulationsLoading } = useSimulationData(courseId, userId);

  // Clear localStorage on component mount
  useEffect(() => {
    const clearStoredFilters = () => {
      localStorage.removeItem('quizFilterType');
      localStorage.removeItem('quizCurrentPage');
      setSelectedType('all');
      setCurrentPage(1);
    };

    clearStoredFilters();
    
    // Cleanup on unmount
    return () => {
      clearStoredFilters();
    };
  }, []);

  // Update localStorage when filter type changes
  useEffect(() => {
    localStorage.setItem('quizFilterType', selectedType);
    // Reset page to 1 when filter changes
    setCurrentPage(1);
    localStorage.setItem('quizCurrentPage', '1');
  }, [selectedType]);

  // Update localStorage when page changes
  useEffect(() => {
    localStorage.setItem('quizCurrentPage', currentPage.toString());
  }, [currentPage]);

  // Check if user has restricted access (free trial or mentor only)
  const isRestrictedAccess = useMemo(() => {
    logger.debug('Checking access restrictions:', {
      userCourseAccesses,
      courseId,
      TIER_IDS
    });

    if (accessLoading) {
      logger.debug('Access data still loading');
      return true;
    }

    if (!userCourseAccesses) {
      logger.debug('No course access data available');
      return true;
    }
    
    const currentAccess = Array.isArray(userCourseAccesses) 
      ? userCourseAccesses.find(access => access.course_id === courseId)
      : userCourseAccesses;

    logger.debug('Current access:', currentAccess);

    if (!currentAccess) {
      logger.debug('No access found for current course');
      return true;
    }
    
    const hasUnrestrictedAccess = currentAccess.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY || 
                                 currentAccess.tier_id === TIER_IDS.FULL_ACCESS;
    
    logger.debug('Access check result:', {
      tierId: currentAccess.tier_id,
      hasUnrestrictedAccess,
      STUDY_MATERIALS_ONLY: TIER_IDS.STUDY_MATERIALS_ONLY,
      FULL_ACCESS: TIER_IDS.FULL_ACCESS
    });

    return !hasUnrestrictedAccess;
  }, [userCourseAccesses, courseId, accessLoading]);

  // Handle upgrade modal
  const handleUpgradeClick = useCallback(() => {
    setIsUpgradeModalOpen(true);
  }, []);

  const handleCloseUpgradeModal = useCallback(() => {
    setIsUpgradeModalOpen(false);
  }, []);

  // Special offer check
  useEffect(() => {
    const checkSpecialOffer = async () => {
      if (currentUser?.last_sign_in_at) {
        const lastSignIn = new Date(currentUser.last_sign_in_at);
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        if (lastSignIn < thirtyDaysAgo) {
          setSpecialOfferProductId("special_offer_product_id");
          setIsUpgradeModalOpen(true);
        }
      }
    };

    checkSpecialOffer();
  }, [currentUser]);

  const {
    data: quizHistory,
    isLoading: quizHistoryLoading,
    error: quizHistoryError,
    refetchQuizHistory
  } = useQuizHistory(currentUser?.id, courseId, 'all', false, false, isRestrictedAccess);

  // Combined loading state
  const isLoading = useMemo(() => {
    return categoriesLoading || 
          simulationsLoading || 
          quizHistoryLoading || 
          accessLoading || 
          isRefetching;
  }, [categoriesLoading, simulationsLoading, quizHistoryLoading, accessLoading, isRefetching]);

  useEffect(() => {
    async function fetchUser() {
      const user = await auth.getCurrentUser();
      setCurrentUser(user);
    }
    fetchUser();
  }, []);

  const manualRefetch = useCallback(async () => {
    if (!isRefetching && currentUser) {
      setIsRefetching(true);
      try {
        await api.deleteEmptyQuizzes(currentUser.id, courseId);
        await refetchQuizHistory();
      } catch (error) {
        console.error('Error during refetch:', error);
      } finally {
        setIsRefetching(false);
      }
    }
  }, [refetchQuizHistory, isRefetching, currentUser, courseId]);

  useEffect(() => {
    if (currentUser && !initialFetchDone.current) {
      initialFetchDone.current = true;
      manualRefetch();
    }
  }, [currentUser, manualRefetch]);

  const handleViewQuizResults = async (quizId: string) => {
    if (!quizId || !courseId) {
      console.error('Missing required parameters:', { quizId, courseId });
      alert('Unable to view quiz results. Missing required information.');
      return;
    }
  
    try {
      const quizHistoryData = await api.getQuizHistoryById(quizId);
      if (!quizHistoryData || !quizHistoryData.questions_data?.length) {
        throw new Error('Quiz history not found or has no questions');
      }
  
      const firstQuestion = quizHistoryData.questions_data[0];
      const isSimulationTest = isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion);
  
      if (isSimulationTest) {
        logger.debug('Navigating to simulation review with state:', {
          quizHistoryId: quizId,
          courseId,
          completed: true,
          mockExamId: isMockExamSimulation(firstQuestion) ? firstQuestion.mockExamId : undefined
        });
  
        navigate(`/course/${courseId}/simulation-review`, { 
          state: { 
            quizHistoryId: quizId,
            courseId,
            completed: true,
            ...(isMockExamSimulation(firstQuestion) && {
              mockExamId: firstQuestion.mockExamId
            })
          }
        });
      } else {
        // Regular quiz review navigation remains the same
        navigate(`/course/${courseId}/quiz`, { 
          state: { 
            viewQuizId: quizId,
            quizData: quizHistoryData,
            from: 'analytics',
            showSummary: true,
            quizCompleted: true
          }
        });
      }
    } catch (err) {
      console.error('Failed to fetch quiz history:', err);
      alert('Failed to load quiz results. Please try again.');
    }
  };

  const handleFilterChange = (newType: string) => {
    setSelectedType(newType);
    setCurrentPage(1);
  };

  // Moved filtering logic to a common useMemo hook
  const filteredQuizzes = useMemo(() => {
    if (!quizHistory) return [];
    
    return quizHistory.filter(quiz => {
      const firstQuestion = quiz.questions_data?.[0];
      if (!firstQuestion) return false;

      switch (selectedType) {
        case 'simulation':
          return isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion);
        case 'mockExam':
          return isMockExamMCQ(firstQuestion) || isMockExamSimulation(firstQuestion);
        case 'studyTask':
          return isStudyTaskTestlet(firstQuestion);
        case 'mcq':
          return !isSimulationQuestion(firstQuestion) && 
                 !isMockExamMCQ(firstQuestion) &&
                 !isMockExamSimulation(firstQuestion) && 
                 !isStudyTaskTestlet(firstQuestion);
        case 'all':
        default:
          return true;
      }
    });
  }, [quizHistory, selectedType]);

  // Calculate pagination values in a common place
  const totalItems = filteredQuizzes.length;
  const indexOfLastQuiz = currentPage * quizzesPerPage;
  const indexOfFirstQuiz = indexOfLastQuiz - quizzesPerPage;
  const currentQuizzes = useMemo(() => {
    return filteredQuizzes.slice(indexOfFirstQuiz, indexOfLastQuiz);
  }, [filteredQuizzes, indexOfFirstQuiz, indexOfLastQuiz]);

  const renderQuizHistoryTable = (isBlurred = false) => (
    <QuizHistoryTable 
      quizzes={currentQuizzes}
      onViewResults={handleViewQuizResults}
      onResumeQuiz={handleResumeQuizFromHistory}
      onEndQuiz={handleEndQuizSession}
      courseId={courseId}
      selectedType={selectedType}
      onFilterChange={handleFilterChange}
      currentPage={currentPage}
      totalItems={totalItems}
      onPageChange={setCurrentPage}
    />
  );

  // Update the handleResumeQuizFromHistory function
  const handleResumeQuizFromHistory = useCallback(async (quizToResume: QuizHistory) => {
  try {
    if (!quizToResume.questions_data || !Array.isArray(quizToResume.questions_data) || quizToResume.questions_data.length === 0) {
      console.error('Invalid question data in quiz:', quizToResume);
      return;
    }

    const firstQuestion = quizToResume.questions_data[0] as QuizQuestionData;
    
    // Handle mock exam MCQ
    if (isMockExamMCQ(firstQuestion)) {
      logger.debug('Handling mock exam MCQ quiz');
      const mockExamQuestion = firstQuestion as MockExamMCQQuestionData;
      const searchParams = new URLSearchParams({
        mock_exam_testlet_id: mockExamQuestion.mockExamTestletId,
        quiz_history_id: quizToResume.id
      }).toString();

      navigate(`/course/${courseId}/quiz?${searchParams}`, { 
        state: { 
          resumeQuizId: quizToResume.id,
          quizData: quizToResume,
          mockExamId: mockExamQuestion.mockExamId,
          mockExamTestletId: mockExamQuestion.mockExamTestletId,
          from: 'quiz-history',
          preserveParams: true
        },
        replace: false // Keep URL parameters
      });
      return;
    }

    // Handle simulations (both regular and mock exam)
    if (isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion)) {
      const unansweredSimulation = quizToResume.questions_data.find(
        (q): q is SimulationQuestionData | MockExamSimulationQuestionData => 
          (isSimulationQuestion(q) || isMockExamSimulation(q)) && !q.answered
      );

      if (!unansweredSimulation) {
        console.error('No unanswered simulations found');
        return;
      }

      if (isMockExamSimulation(firstQuestion)) {
        // Handle mock exam simulation
        navigate(`/course/${courseId}/simulations`, {
          state: {
            resumeQuizId: quizToResume.id,
            quizData: quizToResume,
            mockExamId: firstQuestion.mockExamId,
            mockExamTestletId: firstQuestion.mockExamTestletId,
            from: 'quiz-history',
            currentSimulationIndex: quizToResume.questions_data.findIndex(
              q => isMockExamSimulation(q) && !q.answered
            )
          }
        });
      } else {
        // Handle regular simulation
        navigate(`/course/${courseId}/simulations`, { 
          state: { 
            resumeQuizId: quizToResume.id,
            quizData: quizToResume,
            currentSimulationIndex: quizToResume.questions_data.findIndex(
              q => isSimulationQuestion(q) && q.simulationId === unansweredSimulation.simulationId
            )
          }
        });
      }
      return;
    }

    // Handle study task testlets
    if (isStudyTaskTestlet(firstQuestion)) {
      try {
        // Try to get studyTaskId from multiple sources
        let studyTaskId = firstQuestion.studyTaskId;
        let testletType: StudyTaskTestletType = 'learn';
        let testletId = firstQuestion.testletId;

        logger.debug('Initial study task data:', { 
          studyTaskId, 
          testletId: quizToResume.study_task_testlet_id || testletId 
        });

        // If we have a study_task_testlet_id in the quiz history, use that first
        if (!studyTaskId && quizToResume.study_task_testlet_id) {
          logger.debug('Fetching testlet data using quiz history testlet ID:', quizToResume.study_task_testlet_id);
          
          const testletData = await api.getStudyTaskTestletById(quizToResume.study_task_testlet_id);
          logger.debug('Retrieved testlet data:', testletData);

          if (testletData) {
            studyTaskId = testletData.study_task_id;
            testletType = testletData.type;
            testletId = testletData.id;
          }
        }

        // If we still don't have a studyTaskId, try using the testletId
        if (!studyTaskId && testletId) {
          logger.debug('Fetching testlet data using question testlet ID:', testletId);
          
          const testletData = await api.getStudyTaskTestletById(testletId);
          logger.debug('Retrieved testlet data from testletId:', testletData);

          if (testletData) {
            studyTaskId = testletData.study_task_id;
            testletType = testletData.type;
          }
        }

        if (!studyTaskId) {
          console.error('Unable to find study task ID from any source');
          return;
        }

        const studyTaskData = {
          testletId: testletId,
          studyTaskId: studyTaskId,
          type: testletType
        };

        logger.debug('Final study task data for navigation:', studyTaskData);

        const searchParams = new URLSearchParams({
          study_task_testlet_id: studyTaskData.testletId,
          quiz_history_id: quizToResume.id,
          study_task_id: studyTaskId,
          type: studyTaskData.type
        }).toString();

        navigate(`/course/${courseId}/quiz?${searchParams}`, { 
          state: { 
            resumeQuizId: quizToResume.id,
            quizData: quizToResume,
            studyTaskTestletId: studyTaskData.testletId,
            studyTaskId: studyTaskId,
            from: 'quiz-history',
            showSummary: false,
            quizCompleted: false,
            testletType: studyTaskData.type,
            preserveParams: true
          },
          replace: false
        });
        return;
      } catch (error) {
        console.error('Error resuming study task testlet:', error);
        throw error;
      }
    }

    // Handle regular MCQ quiz (default case)
    navigate(`/course/${courseId}/quiz`, { 
      state: { 
        resumeQuizId: quizToResume.id,
        quizData: quizToResume,
        from: 'quiz-history'
      }
    });
    } catch (error) {
      console.error('Error in handleResumeQuizFromHistory:', error);
      throw error;
    }
  }, [courseId, navigate, api]);

  const handleEndQuizSession = useCallback(async (quizId: string, quizTime: number) => {
    try {
      await refetchQuizHistory();
    } catch (err) {
      console.error('Failed to end quiz session:', err);
      alert('Failed to end quiz session. Please try again.');
    }
  }, [refetchQuizHistory]);

  const renderRestrictedOverlay = () => (
    <div className="relative min-h-[600px] w-full">
      {/* Blurred background table or placeholder */}
      <div className="blur-sm pointer-events-none">
        {quizHistory && quizHistory.length > 0 ? (
          renderQuizHistoryTable(true)
        ) : (
          <div className="h-[600px] w-full bg-gray-100 dark:bg-gray-800 rounded-lg"></div>
        )}
      </div>

      {/* Centered overlay */}
      <div className="absolute inset-0 flex items-center justify-center bg-gray-900/75">
        <div className="bg-gray-800 text-white rounded-xl shadow-2xl max-w-2xl w-full mx-4 overflow-y-auto">
          <div className="p-6 sm:p-8">
            <Lock size={48} className="mx-auto mb-6 text-primary-orange" />
            <h3 className="text-2xl sm:text-3xl font-bold mb-4">Enhanced Analytics Locked</h3>
            <p className="text-base sm:text-lg mb-6 text-gray-200">
              Upgrade your account to unlock full access to your quiz history and detailed analytics. 
              Track your progress, identify weak areas, and improve your study strategy with comprehensive performance data.
            </p>
            <ul className="text-left mb-6 space-y-4">
              <li className="flex items-start sm:items-center gap-3">
                <Crown size={20} className="mt-1 sm:mt-0 shrink-0 text-primary-orange" />
                <span className="text-sm sm:text-base">Access to study task, mock exam, multiple choice and simulation performance results</span>
              </li>
              <li className="flex items-start sm:items-center gap-3">
                <Crown size={20} className="mt-1 sm:mt-0 shrink-0 text-primary-orange" />
                <span className="text-sm sm:text-base">Leave notes and make flashcards directly from your quiz results to build deeper understanding of the AICPA Blueprints</span>
              </li>
              <li className="flex items-start sm:items-center gap-3">
                <Crown size={20} className="mt-1 sm:mt-0 shrink-0 text-primary-orange" />
                <span className="text-sm sm:text-base">Progress tracking across all question types and easy ability to dig into your weakest areas</span>
              </li>
            </ul>
            <Button
              onClick={handleUpgradeClick}
              className="w-full sm:w-auto bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center mx-auto px-8 py-3 text-base sm:text-lg font-medium"
            >
              <Crown size={20} className="mr-2" />
              Upgrade Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderQuizHistory = () => {
    if (isRestrictedAccess) {
      return renderRestrictedOverlay();
    }

    if (quizHistoryLoading || isRefetching) {
      return <LoadingScreen message="Loading quiz history..." subMessage="Please wait while we fetch your quiz data." />;
    }

    if (quizHistoryError) {
      return <div>Error loading quiz history: {(quizHistoryError as Error).message || 'Unknown error'}</div>;
    }

    if (!quizHistory || quizHistory.length === 0) {
      return <div>No quiz history available.</div>;
    }

    return (
      <div className="mt-8">
        <div className="overflow-x-auto px-4">
          {renderQuizHistoryTable()}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoadingScreen 
          message="Loading analytics data..." 
          subMessage="Please wait while we fetch your performance data." 
        />
      );
    }

    return (
      <div className="space-y-6">
        <AnalyticsOverviewCard 
          userId={userId} 
          courseId={courseId} 
          categoriesData={categoriesData}
          simulationData={simulationData}
          isLoading={false}
        />
        
        {isRestrictedAccess ? (
          renderRestrictedOverlay()
        ) : (
          renderQuizHistory()
        )}
      </div>
    );
  };

  return (
    <div className="space-y-6 md:p-4 max-w-[1200px] mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-800 dark:shadow-none border border-gray-400 dark:border-gray-700">
      {renderContent()}
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleCloseUpgradeModal}
      />
    </div>
  );
}

export default Analytics;