import React from 'react';
import { useConfidenceTracker } from '../../hooks/useConfidenceTracker';
import { ConfidenceTrackerData } from '../../services/api';
import { useAuth } from '../AuthProvider';
import { FileText } from 'lucide-react';

interface StudyPlanExamDayTrackerProps {
  userId: string;
  courseId: string;
}

const ConfidenceGauge: React.FC<{ confident: number; total: number; label: string; isOverall?: boolean }> = ({ confident, total, label, isOverall = false }) => {
  const percentage = total > 0 ? (confident / total) * 100 : 0;
  const isReady = percentage >= 75;

  const getColor = (value: number) => {
    if (value <= 25) return 'bg-red-500';
    if (value <= 50) return 'bg-yellow-500';
    if (value <= 75) return 'bg-orange-500';
    return 'bg-green-500';
  };

  return (
    <div className={`flex-1 flex flex-col justify-center px-4 ${
      isOverall ? 'py-3 bg-gray-50 dark:bg-gray-700/50 border border-gray-200 dark:border-gray-600 rounded-lg mb-2' : 'py-1'
    }`}>
      <div className="flex justify-between items-center mb-1">
        <span className="font-medium text-blue-700 dark:text-white truncate mr-2 text-sm">
          {label}{isReady ? ' (Ready!)' : ''}
        </span>
        <span className="font-medium text-blue-700 dark:text-white whitespace-nowrap text-sm">
          {confident}/{total}
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700 relative overflow-hidden">
        {[25, 50, 75].map((mark) => (
          <div
            key={mark}
            className="absolute top-0 bottom-0 w-px bg-gray-400"
            style={{ left: `${mark}%` }}
          ></div>
        ))}
        {[25, 50, 75, 100].map((chunk, index) => {
          const chunkPercentage = Math.min(Math.max(percentage - (index * 25), 0), 25);
          const isFirstChunk = index === 0;
          const isLastChunk = index === 3;
          const isFull = chunkPercentage === 25;
          const isPreviousFull = percentage > index * 25;

          let roundedClass = '';
          if (isFirstChunk) roundedClass = 'rounded-l-full';
          if (isLastChunk && !isFull) roundedClass = 'rounded-r-full';
          if (!isFirstChunk && !isPreviousFull) roundedClass = 'rounded-l-full';

          return (
            <div
              key={chunk}
              className={`absolute top-0 left-0 h-2 ${getColor(chunk)} ${roundedClass}`}
              style={{
                width: `${chunkPercentage}%`,
                left: `${index * 25}%`
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

const StudyPlanExamDayTracker: React.FC<StudyPlanExamDayTrackerProps> = ({ 
  userId, 
  courseId,
}) => {
  const { data, loading, error } = useConfidenceTracker(userId, courseId);
  const { user } = useAuth();

  if (loading) return (
    <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 sm:p-6 h-full border border-gray-400 dark:border-gray-700 flex items-center justify-center">
      <span className="text-sm">Loading exam day confidence tracker...</span>
    </div>
  );
  
  if (error) return (
    <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 sm:p-6 h-full border border-gray-400 dark:border-gray-700 flex items-center justify-center">
      <span className="text-sm text-red-500">Error loading confidence tracker: {error.message}</span>
    </div>
  );
  
  if (!data) return null;
  
  const orderedData: [string, keyof ConfidenceTrackerData][] = [
    ['Mentorship', 'mentorship'],
    ['Lessons', 'lessons'],
    ['Flashcards', 'flashcards'],
    ['Questions', 'questions'],
    ['Simulations', 'simulations'],
  ];

  return (
    <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 sm:p-6 h-full border border-gray-400 dark:border-gray-700 flex flex-col">
      <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-300 text-center">
        Exam Day Confidence Tracker
      </h3>
      <div className="flex-1 flex flex-col">
        <div className="flex-1">
          <ConfidenceGauge 
            confident={Object.values(data).reduce((sum, category) => 
              sum + (category.confident || category.completed || 0), 0)}
            total={Object.values(data).reduce((sum, category) => 
              sum + category.total, 0)}
            label="Overall"
            isOverall={true}
          />
          <div className="mt-4 space-y-1">
            {orderedData.map(([label, key]) => (
              <ConfidenceGauge 
                key={key} 
                confident={key === 'mentorship' ? data[key].completed : data[key].confident} 
                total={data[key].total} 
                label={label} 
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyPlanExamDayTracker;