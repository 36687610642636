// src/utils/serviceWorkerRegistration.ts
export const registerServiceWorker = async (): Promise<void> => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/serviceWorker.js');
      } catch (error) {
        console.error('ServiceWorker registration failed:', error);
      }
    }
  };
  