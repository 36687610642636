// src/components/admin/CustomerDashboard.tsx

import React, { useState, useMemo, useEffect } from 'react';
import { supabase, moderatorOperations, isStaffUser } from '../../services/supabase';
import { auth } from '../../services/auth';
import { format } from 'date-fns';
import { 
  AlertCircle,
  BadgeDollarSign,
  CreditCard,
  Loader2,
  PauseCircle,
  Download,
  Mail,
  ExternalLink,
  MoreHorizontal,
  ChevronRight
} from 'lucide-react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Badge } from '../ui/Badge';
import { Alert, AlertDescription } from '../ui/Alert';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/Accordion';
import { Dialog, DialogContent, DialogTitle } from '../ui/Dialog';
import { Card } from '../ui/Card';
import { ScrollArea } from '../ui/ScrollArea';
import { useThriveCart } from '../../contexts/ThriveCartContext';
import { useNotification } from '../../contexts/NotificationContext';
import { 
  SubscriptionStatus, 
  UserCoursePurchase,
} from '../../services/api';

export interface PaymentControlsProps {
  purchases: UserCoursePurchase[];
  onPurchaseUpdate: () => Promise<void>;
  defaultOpen?: boolean;
}

type SubscriptionStateGroups = {
  ACTIVE: SubscriptionStatus[];
  PAUSED: SubscriptionStatus[];
  PENDING: SubscriptionStatus[];
  TERMINATED: SubscriptionStatus[];
};

const SUBSCRIPTION_STATES: SubscriptionStateGroups = {
  ACTIVE: ['active', 'active_partial_refund', 'trial'],
  PAUSED: ['paused'],
  PENDING: ['pending'],
  TERMINATED: ['cancelled', 'cancelled_partial_refund', 'failed', 'refunded', 'completed', 'expired']
};

interface EditDropdownProps {
  onRefund: () => void;
  onPause: () => void;
  onCancel: () => void;
  canRefund: boolean;
  isActiveSubscription: boolean;
}

interface TransactionGroup {
  mainPurchase: UserCoursePurchase;
  relatedTransactions: UserCoursePurchase[];
}

const PaymentControls: React.FC<PaymentControlsProps> = ({
  purchases,
  onPurchaseUpdate,
  defaultOpen = false
}) => {
  const [accordionValue, setAccordionValue] = useState<string | undefined>(
    defaultOpen ? "payment-controls" : undefined
  );
  const [selectedPurchase, setSelectedPurchase] = useState<UserCoursePurchase | null>(null);
  const [refundAmount, setRefundAmount] = useState('');
  const [refundReason, setRefundReason] = useState('');
  const [pauseDate, setPauseDate] = useState('');
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [showPauseDialog, setShowPauseDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [hasStaffAccess, setHasStaffAccess] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());

  // Custom button classes for responsive design
  const buttonClasses = {
    refund: `
      inline-flex items-center justify-center px-2 py-1 h-7
      text-xs font-medium rounded
      bg-amber-100 text-amber-700 hover:bg-amber-200
      dark:bg-amber-900/30 dark:text-amber-300 dark:hover:bg-amber-900/50
      focus:outline-none focus:ring-1 focus:ring-amber-500
      disabled:opacity-50 disabled:cursor-not-allowed
    `,
    pause: `
      inline-flex items-center justify-center px-2 py-1 h-7
      text-xs font-medium rounded
      bg-blue-100 text-blue-700 hover:bg-blue-200
      dark:bg-blue-900/30 dark:text-blue-300 dark:hover:bg-blue-900/50
      focus:outline-none focus:ring-1 focus:ring-blue-500
      disabled:opacity-50 disabled:cursor-not-allowed
    `,
    cancel: `
      inline-flex items-center justify-center px-2 py-1 h-7
      text-xs font-medium rounded
      bg-red-100 text-red-700 hover:bg-red-200
      dark:bg-red-900/30 dark:text-red-300 dark:hover:bg-red-900/50
      focus:outline-none focus:ring-1 focus:ring-red-500
      disabled:opacity-50 disabled:cursor-not-allowed
    `,
    action: `
      inline-flex items-center justify-center px-2 py-0.5 h-6
      text-xs font-medium rounded
      bg-gray-100 text-gray-700 hover:bg-gray-200
      dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700
      focus:outline-none focus:ring-1 focus:ring-gray-500
      disabled:opacity-50 disabled:cursor-not-allowed
    `
  };

  const smallButtonClasses = `
    inline-flex items-center justify-center px-2 py-1 h-7
    text-xs font-medium rounded
    bg-gray-50 text-gray-700 hover:bg-gray-100
    dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700
    focus:outline-none focus:ring-1 focus:ring-gray-500
    disabled:opacity-50 disabled:cursor-not-allowed
  `;

  const transactionCardClasses = `
    p-3 text-sm border-0
    bg-white dark:bg-gray-800
    hover:bg-gray-50 dark:hover:bg-gray-700
    transition-colors duration-150
  `;

  const { showNotification } = useNotification();
  const {
    fetchOrder,
    fetchSubscription,
    cancelSubscription,
    pauseSubscription,
    resumeSubscription,
    refundOrder,
  } = useThriveCart();

  const getSubscriptionHealthColor = (health: string | null) => {
    switch (health) {
      case 'excellent': return 'text-green-600 bg-green-100 dark:bg-green-900/20';
      case 'good': return 'text-blue-600 bg-blue-100 dark:bg-blue-900/20';
      case 'fair': return 'text-yellow-600 bg-yellow-100 dark:bg-yellow-900/20';
      case 'poor': return 'text-red-600 bg-red-100 dark:bg-red-900/20';
      default: return 'text-gray-600 bg-gray-100 dark:bg-gray-900/20';
    }
  };

  useEffect(() => {
    checkStaffAccess();
  }, []);

  const checkStaffAccess = async () => {
    const isStaff = await isStaffUser();
    setHasStaffAccess(isStaff);
    if (!isStaff) {
      setError('Unauthorized: Only staff members can modify payments');
    }
  };

  const EditDropdown: React.FC<EditDropdownProps> = ({
    onRefund,
    onPause,
    onCancel,
    canRefund,
    isActiveSubscription
  }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleAction = (action: () => void) => {
      action();
      setIsOpen(false);
    };
  
    return (
      <div className="relative">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsOpen(!isOpen)}
          className="h-7 w-7 p-0 flex items-center justify-center" // Updated styling
        >
          <MoreHorizontal className="w-4 h-4" />
        </Button>
        
        {isOpen && (
          <div className="absolute right-0 mt-1 w-36 bg-white dark:bg-gray-800 rounded-md shadow-lg border dark:border-gray-700 py-1 z-50">
            {canRefund && (
              <button
                onClick={() => handleAction(onRefund)}
                className="w-full text-left px-3 py-1.5 text-xs text-amber-700 dark:text-amber-300 hover:bg-amber-50 dark:hover:bg-amber-900/20"
              >
                Refund
              </button>
            )}
            {isActiveSubscription && (
              <>
                <button
                  onClick={() => handleAction(onPause)}
                  className="w-full text-left px-3 py-1.5 text-xs text-blue-700 dark:text-blue-300 hover:bg-blue-50 dark:hover:bg-blue-900/20"
                >
                  Pause
                </button>
                <button
                  onClick={() => handleAction(onCancel)}
                  className="w-full text-left px-3 py-1.5 text-xs text-red-700 dark:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20"
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const getPaymentProcessor = (processor: string | null) => {
    if (!processor) return 'Unknown';
    
    const processors: Record<string, string> = {
      'stripe': 'Stripe',
      'paypal': 'PayPal',
      'authorize': 'Authorize.net',
      'apple_pay': 'Apple Pay',
      'google_pay': 'Google Pay'
    };
    return processors[processor.toLowerCase()] || processor;
  };

  const getActiveSubscriptions = () => {
    return purchases.filter(p => 
      p.subscription_status === 'active' || 
      p.subscription_status === 'active_partial_refund'
    );
  };

  const handlePurchaseAction = async (purchase: UserCoursePurchase) => {
    setSelectedPurchase(purchase);
    
    try {
      const [orderData, subscriptionData] = await Promise.all([
        purchase.thrivecart_order_id ? fetchOrder(purchase.thrivecart_order_id) : null,
        purchase.thrivecart_subscription_id ? fetchSubscription(purchase.thrivecart_subscription_id) : null
      ]);
  
      // Update selected purchase with any new data
      setSelectedPurchase(current => {
        if (!current) return null;
        return {
          ...current,
          ...(orderData || {}),
          ...(subscriptionData || {})
        };
      });
    } catch (error) {
      console.error('Error fetching purchase details:', error);
      showNotification?.('Failed to load purchase details', 'error');
    }
  };
  
  const handleRefund = async () => {
    if (!selectedPurchase?.thrivecart_order_id) return;
    
    setProcessing(true);
    try {
      const amount = refundAmount ? parseFloat(refundAmount) : undefined;
      const isSuccess = await refundOrder(selectedPurchase.thrivecart_order_id, amount, refundReason);
      
      if (isSuccess) {
        showNotification?.('Refund processed successfully', 'success');
        setRefundAmount('');
        setRefundReason('');
        setShowRefundDialog(false);
        await onPurchaseUpdate();
      } else {
        throw new Error('Refund failed');
      }
    } catch (error) {
      console.error('Error processing refund:', error);
      setError('Failed to process refund');
      showNotification?.('Failed to process refund', 'error');
    } finally {
      setProcessing(false);
    }
  };
  
  const handlePause = async () => {
    if (!selectedPurchase?.thrivecart_subscription_id || !pauseDate) return;
    
    setProcessing(true);
    try {
      const resumeDate = new Date(pauseDate);
      const isSuccess = await pauseSubscription(selectedPurchase.thrivecart_subscription_id, resumeDate);
      
      if (isSuccess) {
        showNotification?.('Subscription paused successfully', 'success');
        setPauseDate('');
        setShowPauseDialog(false);
        await onPurchaseUpdate();
      } else {
        throw new Error('Failed to pause subscription');
      }
    } catch (error) {
      console.error('Error pausing subscription:', error);
      setError('Failed to pause subscription');
      showNotification?.('Failed to pause subscription', 'error');
    } finally {
      setProcessing(false);
    }
  };
  
  const handleResume = async () => {
    if (!selectedPurchase?.thrivecart_subscription_id) return;
    
    setProcessing(true);
    try {
      const isSuccess = await resumeSubscription(selectedPurchase.thrivecart_subscription_id);
      if (isSuccess) {
        showNotification?.('Subscription resumed successfully', 'success');
        await onPurchaseUpdate();
      } else {
        throw new Error('Failed to resume subscription');
      }
    } catch (error) {
      console.error('Error resuming subscription:', error);
      setError('Failed to resume subscription');
      showNotification?.('Failed to resume subscription', 'error');
    } finally {
      setProcessing(false);
    }
  };
  
  const handleCancel = async () => {
    if (!selectedPurchase?.thrivecart_subscription_id) return;
    
    setProcessing(true);
    try {
      const isSuccess = await cancelSubscription(
        selectedPurchase.thrivecart_subscription_id,
        'Cancelled by admin'
      );
      if (isSuccess) {
        showNotification?.('Subscription cancelled successfully', 'success');
        await onPurchaseUpdate();
      } else {
        throw new Error('Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('Failed to cancel subscription');
      showNotification?.('Failed to cancel subscription', 'error');
    } finally {
      setProcessing(false);
    }
  };
  
  const organizedTransactions = useMemo(() => {
    const groups: TransactionGroup[] = [];
    const processedIds = new Set<string>();
  
    purchases.forEach(purchase => {
      if (processedIds.has(purchase.id)) return;
  
      // Find the main purchase (subscription or one-time)
      if (!purchase.thrivecart_order_id) return;
  
      const related = purchases.filter(p => 
        p.id !== purchase.id && (
          // Related by order ID (bumps, upsells)
          p.thrivecart_order_id === purchase.thrivecart_order_id ||
          // Related by subscription (rebills, cancellations)
          (purchase.thrivecart_subscription_id && 
           p.thrivecart_subscription_id === purchase.thrivecart_subscription_id) ||
          // Related by subscription reference
          (purchase.subscription_reference && 
           p.subscription_reference === purchase.subscription_reference)
        )
      );
  
      groups.push({
        mainPurchase: purchase,
        relatedTransactions: related.sort((a, b) => 
          new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime()
        )
      });
  
      processedIds.add(purchase.id);
      related.forEach(r => processedIds.add(r.id));
    });
  
    return groups.sort((a, b) => 
      new Date(b.mainPurchase.purchase_date).getTime() - 
      new Date(a.mainPurchase.purchase_date).getTime()
    );
  }, [purchases]);

  const getTransactionType = (purchase: UserCoursePurchase): string => {
    if (purchase.event_type) {
      switch (purchase.event_type) {
        case 'bump': return 'Bump Offer';
        case 'upsell': return 'Upsell';
        case 'rebill': return 'Rebill';
        case 'refund': return 'Refund';
        case 'cancel': return 'Cancellation';
        case 'failed': return 'Failed Payment';
        default: return 'Transaction';
      }
    }
    return purchase.subscription_status ? 'Subscription' : 'One-time Purchase';
  };

  const toggleGroupExpansion = (groupId: string) => {
    setExpandedGroups(current => {
      const newSet = new Set(current);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  };

  const organizedPurchases = useMemo(() => {
    const active = purchases.filter(p => 
      SUBSCRIPTION_STATES.ACTIVE.includes(p.subscription_status as SubscriptionStatus)
    );
    const paused = purchases.filter(p => 
      SUBSCRIPTION_STATES.PAUSED.includes(p.subscription_status as SubscriptionStatus)
    );
    const others = purchases.filter(p => 
      !SUBSCRIPTION_STATES.ACTIVE.includes(p.subscription_status as SubscriptionStatus) &&
      !SUBSCRIPTION_STATES.PAUSED.includes(p.subscription_status as SubscriptionStatus)
    );

    return {
      active,
      paused,
      others
    };
  }, [purchases]);

  const getStatusBadgeVariant = (status: SubscriptionStatus | null): 'default' | 'secondary' | 'destructive' | 'outline' | 'warning' => {
    if (!status) return 'secondary';
    
    if (SUBSCRIPTION_STATES.TERMINATED.includes(status)) return 'destructive';
    if (SUBSCRIPTION_STATES.PAUSED.includes(status)) return 'outline';
    if (SUBSCRIPTION_STATES.PENDING.includes(status)) return 'warning';
    if (SUBSCRIPTION_STATES.ACTIVE.includes(status)) return 'default'; // Changed from 'success' to 'default'
    
    return 'secondary';
  };

  const canRefund = (purchase: UserCoursePurchase): boolean => {
    if (!purchase.subscription_status) return true; // Allow refund for one-time purchases
    
    // Can't refund if status is in TERMINATED
    if (SUBSCRIPTION_STATES.TERMINATED.includes(purchase.subscription_status)) {
      return false;
    }
    // Can't refund if amount is 0 or undefined
    if (!purchase.payment_amount || purchase.payment_amount <= 0) {
      return false;
    }
    return true;
  };

  const isActiveSubscription = (status: SubscriptionStatus | null): boolean => {
    return !!status && SUBSCRIPTION_STATES.ACTIVE.includes(status);
  };

  const isPausedSubscription = (status: SubscriptionStatus | null): boolean => {
    return !!status && SUBSCRIPTION_STATES.PAUSED.includes(status);
  };

  const isTerminatedSubscription = (status: SubscriptionStatus | null): boolean => {
    return !!status && SUBSCRIPTION_STATES.TERMINATED.includes(status);
  };

  const renderTransactionGroup = (group: TransactionGroup) => {
    const { mainPurchase, relatedTransactions } = group;
    const hasRelated = relatedTransactions.length > 0;
    const isExpanded = expandedGroups.has(mainPurchase.id);
    
    return (
      <Card key={mainPurchase.id} className="mb-2">
        <div 
          className={`p-3 ${hasRelated ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800' : ''}`}
          onClick={() => hasRelated && toggleGroupExpansion(mainPurchase.id)}
        >
          <div className="flex items-start justify-between">
            <div className="flex-grow">
              <div className="flex items-center gap-2">
                <span className="font-semibold">
                  ${mainPurchase.payment_amount?.toFixed(2)}
                </span>
                <Badge variant={getStatusBadgeVariant(mainPurchase.subscription_status as SubscriptionStatus)}>
                  {getTransactionType(mainPurchase)}
                </Badge>
                {hasRelated && (
                  <ChevronRight 
                    className={`w-4 h-4 transition-transform ${isExpanded ? 'rotate-90' : ''}`}
                  />
                )}
              </div>
              <div className="text-xs text-gray-500 mt-1">
                {format(new Date(mainPurchase.purchase_date), 'MMM d, yyyy h:mm a')}
              </div>
              <div className="text-sm mt-1">{mainPurchase.payment_plan_name}</div>
            </div>
            {isActiveSubscription(mainPurchase.subscription_status as SubscriptionStatus) && (
              <EditDropdown
                onRefund={() => {/* existing handlers */}}
                onPause={() => {/* existing handlers */}}
                onCancel={() => {/* existing handlers */}}
                canRefund={canRefund(mainPurchase)}
                isActiveSubscription={true}
              />
            )}
          </div>

          {hasRelated && isExpanded && (
            <div className="mt-3 space-y-2 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
              {relatedTransactions.map(transaction => (
                <div 
                  key={transaction.id}
                  className="text-sm border-b border-gray-100 dark:border-gray-800 last:border-0 pb-2"
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="flex items-center gap-2">
                        <span>${transaction.payment_amount?.toFixed(2)}</span>
                        <Badge variant="secondary" className="text-xs">
                          {getTransactionType(transaction)}
                        </Badge>
                      </div>
                      <div className="text-xs text-gray-500 mt-0.5">
                        {format(new Date(transaction.purchase_date), 'MMM d, yyyy h:mm a')}
                      </div>
                    </div>
                    {canRefund(transaction) && (
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-7 text-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePurchaseAction(transaction);
                          setShowRefundDialog(true);
                        }}
                      >
                        Refund
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>
    );
  };

  // Updated renderPurchaseCard function with conditional rendering of EditDropdown
  const renderPurchaseCard = (purchase: UserCoursePurchase) => {
    const status = purchase.subscription_status as SubscriptionStatus | null;
    const badgeVariant = getStatusBadgeVariant(status);
    
    // Check if the subscription is active or active_partial_refund
    const isEditable = status === 'active' || status === 'active_partial_refund';
    
    return (
      <Card key={purchase.id} className={transactionCardClasses}>
        <div className="flex justify-between items-start mb-2">
          <div className="min-w-0">
            <div className="flex items-center gap-2">
              <span className="font-semibold text-base">
                ${purchase.payment_amount?.toFixed(2)}
              </span>
              <Badge 
                variant={getStatusBadgeVariant(status)}
                className={`text-xs px-1.5 py-0.5 ${
                  SUBSCRIPTION_STATES.ACTIVE.includes(status as SubscriptionStatus)
                    ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300'
                    : ''
                }`}
              >
                {status || 'one-time'}
              </Badge>
            </div>
            <div className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
              {format(new Date(purchase.purchase_date), 'MMM do, yyyy, h:mm a')}
            </div>
          </div>
          {isEditable && (
            <EditDropdown
              onRefund={() => {
                handlePurchaseAction(purchase);
                setShowRefundDialog(true);
              }}
              onPause={() => {
                handlePurchaseAction(purchase);
                setShowPauseDialog(true);
              }}
              onCancel={() => {
                handlePurchaseAction(purchase);
                handleCancel();
              }}
              canRefund={canRefund(purchase)}
              isActiveSubscription={isActiveSubscription(status)}
            />
          )}
        </div>

        <div className="space-y-1.5 text-xs">
          <div className="font-medium">{purchase.payment_plan_name}</div>
          <div className="text-gray-600 dark:text-gray-400">
            ID: {purchase.thrivecart_order_id}
          </div>
          <div className="grid grid-cols-2 gap-2 mt-2">
            <div>
              <div className="text-gray-500">Method</div>
              <div>{getPaymentProcessor(purchase.payment_processor)}</div>
            </div>
            <div>
              <div className="text-gray-500">Status</div>
              <div>{purchase.is_active ? 'Active' : 'Inactive'}</div>
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <Button
              variant="outline"
              size="sm"
              className={buttonClasses.action}
              onClick={() => window.open(`/api/invoice/${purchase.id}`, '_blank')}
            >
              <Download className="w-3 h-3 mr-1" />
              Invoice
            </Button>
            <Button
              variant="outline"
              size="sm"
              className={buttonClasses.action}
              onClick={() => window.open(`/api/receipt/${purchase.id}`, '_blank')}
            >
              <Mail className="w-3 h-3 mr-1" />
              Receipt
            </Button>
            {purchase.payment_processor?.toLowerCase() === 'paypal' && (
              <Button
                variant="outline"
                size="sm"
                className={buttonClasses.action}
                onClick={() => window.open(`https://www.paypal.com/activity/payment/${purchase.thrivecart_order_id}`, '_blank')}
              >
                <ExternalLink className="w-3 h-3 mr-1" />
                PayPal
              </Button>
            )}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      value={accordionValue}
      onValueChange={setAccordionValue}
    >
      <AccordionItem value="payment-controls" className="border-none">
        <AccordionTrigger className="flex items-center gap-2 px-3 py-2 hover:no-underline">
          <CreditCard className="w-4 h-4" />
          <span>Payment History</span>
          {purchases.length > 0 && (
            <Badge variant="secondary" className="ml-auto">
              {purchases.length}
            </Badge>
          )}
        </AccordionTrigger>

        <AccordionContent>
          {error && (
            <Alert variant="destructive" className="mb-3 mx-3">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <ScrollArea className="h-[600px]">
            <div className="space-y-2 px-3">
              {organizedTransactions.map(renderTransactionGroup)}
            </div>
          </ScrollArea>

          {/* Dialogs with updated styling */}
          <Dialog open={showRefundDialog} onOpenChange={setShowRefundDialog}>
            <DialogContent className="sm:max-w-[400px]">
              <DialogTitle className="text-base">Process Refund</DialogTitle>
              <div className="space-y-3">
                <div className="text-xs space-y-1 bg-gray-50 dark:bg-gray-800 p-3 rounded">
                  <p>Order ID: {selectedPurchase?.thrivecart_order_id}</p>
                  <p>Amount: ${selectedPurchase?.payment_amount?.toFixed(2)}</p>
                  <p>Product: {selectedPurchase?.payment_plan_name}</p>
                </div>
                <Input
                  type="number"
                  placeholder="Refund amount"
                  value={refundAmount}
                  onChange={(e) => setRefundAmount(e.target.value)}
                  min={0}
                  max={selectedPurchase?.payment_amount ?? undefined}
                  step="0.01"
                  className="h-8 text-sm"
                />
                <Input
                  placeholder="Reason for refund"
                  value={refundReason}
                  onChange={(e) => setRefundReason(e.target.value)}
                  className="h-8 text-sm"
                />
                <div className="flex justify-end gap-2 pt-2">
                  <Button
                    variant="outline"
                    onClick={() => setShowRefundDialog(false)}
                    disabled={processing}
                    className="h-8 text-xs"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={handleRefund}
                    disabled={processing || !refundAmount || !refundReason}
                    className="h-8 text-xs"
                  >
                    {processing ? (
                      <Loader2 className="w-3 h-3 mr-1 animate-spin" />
                    ) : (
                      <BadgeDollarSign className="w-3 h-3 mr-1" />
                    )}
                    Process Refund
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/* Pause Dialog with updated styling */}
          <Dialog open={showPauseDialog} onOpenChange={setShowPauseDialog}>
            <DialogContent className="sm:max-w-[400px]">
              <DialogTitle className="text-base">Pause Subscription</DialogTitle>
              <div className="space-y-3">
                <div className="text-xs space-y-1 bg-gray-50 dark:bg-gray-800 p-3 rounded">
                  <p>ID: {selectedPurchase?.thrivecart_subscription_id}</p>
                  <p>Plan: {selectedPurchase?.payment_plan_name}</p>
                </div>
                <div>
                  <label className="text-xs font-medium block mb-1">
                    Resume Date
                  </label>
                  <Input
                    type="date"
                    value={pauseDate}
                    onChange={(e) => setPauseDate(e.target.value)}
                    min={format(new Date(), 'yyyy-MM-dd')}
                    className="h-8 text-sm"
                  />
                </div>
                <div className="flex justify-end gap-2 pt-2">
                  <Button
                    variant="outline"
                    onClick={() => setShowPauseDialog(false)}
                    disabled={processing}
                    className="h-8 text-xs"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handlePause}
                    disabled={processing || !pauseDate}
                    className="h-8 text-xs"
                  >
                    {processing ? (
                      <Loader2 className="w-3 h-3 mr-1 animate-spin" />
                    ) : (
                      <PauseCircle className="w-3 h-3 mr-1" />
                    )}
                    Pause Subscription
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default PaymentControls;