import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../../services/supabase';
import { SupportUser } from '../../../services/api';
import { 
  Mail,
  Calendar,
  Loader2,
  UserCircle,
  Building,
  Search,
  X
} from 'lucide-react';
import { format } from 'date-fns';
import { Modal } from '../../ui/Modal';
import { Button } from '../../ui/Button';

interface UserSearchProps {
  onUserSelect: (user: SupportUser) => void;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const UserSearch: React.FC<UserSearchProps> = ({ 
  onUserSelect,
  isOpen,
  onOpenChange
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState<SupportUser[]>([]);
  const [loading, setLoading] = useState(false);
  const searchTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (searchQuery.length < 2) {
      setResults([]);
      return;
    }

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(async () => {
      setLoading(true);
      try {
        const query = searchQuery.toLowerCase();
        const { data, error } = await supabase
          .from('support_users')
          .select('*')
          .or(`email.ilike.%${query}%,full_name.ilike.%${query}%,email_domain.ilike.%${query}%`)
          .order('last_activity', { ascending: false })
          .limit(10);

        if (error) throw error;
        setResults(data || []);
      } catch (error) {
        console.error('Error searching users:', error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    }, 300);

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, [searchQuery]);

  const handleSelect = (user: SupportUser) => {
    onUserSelect(user);
    onOpenChange(false);
    setSearchQuery('');
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onOpenChange(false)}>
      <div className="w-full max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        <div className="p-4 border-b dark:border-gray-700">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold dark:text-gray-100">Search Users</h2>
            <Button 
              variant="ghost" 
              size="default"
              onClick={() => onOpenChange(false)}
              className="h-8 w-8 p-0"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <div className="p-4">
          <div className="flex items-center border rounded-md px-3 bg-white dark:bg-gray-700">
            <Search className="w-4 h-4 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search users by email, name, or domain..."
              className="flex h-10 w-full bg-transparent py-3 text-sm outline-none placeholder:text-gray-500 disabled:cursor-not-allowed disabled:opacity-50 dark:text-gray-100 dark:placeholder:text-gray-400"
            />
          </div>

          <div className="mt-4 max-h-[300px] overflow-y-auto">
            {loading ? (
              <div className="flex items-center justify-center py-6">
                <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
              </div>
            ) : !searchQuery ? (
              <div className="py-6 text-center text-sm text-gray-500 dark:text-gray-400">
                Start typing to search...
              </div>
            ) : searchQuery.length < 2 ? (
              <div className="py-6 text-center text-sm text-gray-500 dark:text-gray-400">
                Enter at least 2 characters to search...
              </div>
            ) : results.length === 0 ? (
              <div className="py-6 text-center text-sm text-gray-500 dark:text-gray-400">
                No users found
              </div>
            ) : (
              <div className="space-y-2">
                {results.map((user) => (
                  <div
                    key={user.id}
                    onClick={() => handleSelect(user)}
                    className="flex items-start gap-3 p-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
                  >
                    <UserCircle className="w-8 h-8 text-gray-500 dark:text-gray-400 flex-shrink-0" />
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between gap-2">
                        <span className="font-medium truncate dark:text-gray-100">
                          {user.full_name || user.email.split('@')[0]}
                        </span>
                        {user.auth_user_id && (
                          <span className="text-xs bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300 px-1.5 py-0.5 rounded">
                            Registered
                          </span>
                        )}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 space-y-0.5">
                        <div className="flex items-center gap-1">
                          <Mail className="w-3 h-3" />
                          <span className="truncate">{user.email}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Building className="w-3 h-3" />
                          <span className="truncate">{user.email_domain}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Calendar className="w-3 h-3" />
                          <span>
                            Joined {format(new Date(user.created_at), 'MMM d, yyyy')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserSearch;