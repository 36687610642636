import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/queryClient';
import { registerServiceWorker } from './utils/serviceWorkerRegistration';

// Register service worker in production
if (process.env.NODE_ENV === 'production') {
  registerServiceWorker()
    .catch(error => {
      console.error('Service worker registration failed:', error);
    });
}

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element');
}