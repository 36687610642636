// File: src/components/admin/support/UserSearchDrawer.tsx

import React, { useEffect, useState, useCallback } from 'react';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '../../ui/Drawer';
import { Button } from '../../ui/Button';
import { Card } from '../../ui/Card';
import {
  AlertCircle,
  Calendar,
  Clock,
  Edit,
  ExternalLink,
  FileQuestion,
  FileText,
  HelpCircle,
  ListOrdered,
  Loader2,
  Mail,
  MessageSquare,
  Search,
  Send,
  SmilePlus,
  UserCircle,
  XCircle,
  Activity,
  BookOpen
} from 'lucide-react';
import { Separator } from '../../ui/Separator';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../ui/Tabs';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../../ui/Accordion';
import { Alert, AlertDescription } from '../../ui/Alert';
import { Badge } from '../../ui/Badge';
import { format } from 'date-fns';

import { supabase, supabaseAdmin } from '../../../services/supabase';
import { auth } from '../../../services/auth';
import CourseAccessControls from '../CourseAccessControls';
import PaymentControls from '../PaymentControls';

import {
  COURSE_IDS,
  TIER_IDS,
  SupportUser,
  User,
  Ticket,
  ContentType,
  FeedbackValidity,
  UserCoursePurchase,
  UserCourseAccess,
  Product,
  Question,
  Lesson,
  Simulation,
  Flashcard
} from '../../../services/api';

interface UserSearchDrawerProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  selectedUser: SupportUser | null;
  /** Optional: if your code needs to navigate to a piece of content, you can pass a callback. */
  onNavigateToContent?: (contentType: ContentType, contentId: string) => void;
  /** If you need to refresh data from outside. */
  onRefreshData?: (msg?: string) => void;
}

/** For the short "status" about a user's course access. */
interface CourseAccessStatus {
  productTitle: string;
  accessType: 'Full Access' | 'Study Materials Only' | 'Mentor Only' | 'Free Trial';
  tierId: string;
}

/** For feedback on a ticket. */
interface FeedbackStatus {
  validity: FeedbackValidity;
  reason?: string;
  resolved: boolean;
}

/** For deeper user details: tickets, course data, etc. */
interface UserDetailsData {
  supportUser: SupportUser;
  authUser: User | null;
  tickets: Ticket[];
  courses: { [courseId: string]: ExtendedCourseProgress };
}

/** We add a `courseMix` property to course progress. */
interface ExtendedCourseProgress extends CourseProgress {
  courseMix?: {
    primaryCourseName: string;
    secondaryCourseName?: string;
    alias?: string;
  };
}

  /** A narrower type matching the fields we select from Supabase. */
  interface ProductTitleOnly {
    id: string;
    title: string;
  }

interface CourseProgress {
  examDate: string | null;
  customFlashcardsCount: number;
  flashcardConfidence: {
    guessing: number;
    maybe: number;
    confident: number;
    unanswered: number;
    total: number;
  };
  quizCount: number;
  studyPlanItems: {
    total: number;
    completed: number;
  };
  lessonProgress: {
    total: number;
    completed: number;
  };
  mentorProgress: {
    total: number;
    completed: number;
  };
  mcqProgress: {
    total: number;
    correct: number;
    incorrect: number;
    confident: number;
    maybe: number;
    guessing: number;
  };
  tbsProgress: {
    total: number;
    completed: number;
    averageScore: number;
    confident: number;
    maybe: number;
    guessing: number;
  };
}

/** The raw ticket shape returned by Supabase queries. */
interface RawTicketData {
  id: string;
  short_id: number;
  support_user_id: string;
  admin_id: string | null;
  status: 'new' | 'open' | 'pending' | 'closed';
  subject: string;
  body: string;
  priority: 'low' | 'medium' | 'high' | 'urgent';
  created_at: string;
  updated_at: string;
  last_activity_at: string;
  closed_at: string | null;
  tags: string[];
  content_type: ContentType | null;
  content_id: string | null;
  feedback_validity?: FeedbackValidity;
  feedback_reason?: string;
  feedback_resolved: boolean;
  has_unread?: boolean;
  attachments: Array<{
    name: string;
    url: string;
    type: string;
  }>;
  source: 'email' | 'web' | 'api';
  original_email_id: string | null;
  original_from_email: string | null;
  original_subject: string | null;
  email_thread_id: string | null;
  support_email_alias: string | null;
  is_spam: boolean;
  spam_score: number | null;
  thread_topic: string | null;
  list_id: string | null;
  support_user: SupportUser;
  messages_count: number;
}

/** Main component merging everything into a single drawer. */
const UserSearchDrawer: React.FC<UserSearchDrawerProps> = ({
  isOpen,
  onOpenChange,
  selectedUser,
  onNavigateToContent,
  onRefreshData
}) => {
  // Basic states
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Full user details (tickets, course progress, etc.)
  const [details, setDetails] = useState<UserDetailsData | null>(null);

  // For additional info on the authenticated user
  const [userRole, setUserRole] = useState<string | null>(null);
  const [purchases, setPurchases] = useState<UserCoursePurchase[]>([]);
  const [userCourseAccess, setUserCourseAccess] = useState<UserCourseAccess[]>([]);
  const [courseAccess, setCourseAccess] = useState<CourseAccessStatus[]>([]);
  const [products, setProducts] = useState<Record<string, string>>({});

  // For optional feedback investigation
  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatus | null>(null);

  // For sending or resending an invite
  const [sendingInvite, setSendingInvite] = useState(false);
  const [invitationSent, setInvitationSent] = useState(false);
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [lastInviteTime, setLastInviteTime] = useState<Date | null>(null);

  // For content references (like if a ticket references a question)
  const [contentId, setContentId] = useState<string | null>(null);
  const [contentType, setContentType] = useState<ContentType | null>(null);
  const [contentLoading, setContentLoading] = useState(false);
  const [question, setQuestion] = useState<Question | null>(null);
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [flashcard, setFlashcard] = useState<Flashcard | null>(null);

  // For user detail "All Courses" tab
  const [activeCourse, setActiveCourse] = useState<string>('ALL');
  const [courseNames, setCourseNames] = useState<Record<string, string>>({
    ALL: 'All Courses'
  });

  // Once the drawer opens with a selected user, do everything
  useEffect(() => {
    if (isOpen && selectedUser) {
      // Full load logic
      initLoad();
    } else if (!isOpen) {
      // Clear states so we don't see stale data
      resetAllStates();
    }
  }, [isOpen, selectedUser]);

  const resetAllStates = () => {
    setLoading(false);
    setError(null);
    setDetails(null);
    setFeedbackStatus(null);
    setUserRole(null);
    setPurchases([]);
    setUserCourseAccess([]);
    setCourseAccess([]);
    setProducts({});
    setInvitationSent(false);
    setInviteError(null);
    setLastInviteTime(null);
    setActiveCourse('ALL');
    // content references
    setContentId(null);
    setContentType(null);
    setContentLoading(false);
    setQuestion(null);
    setLesson(null);
    setSimulation(null);
    setFlashcard(null);
  };

  /** 
   * Main function that loads:
   * 1) Current user role,
   * 2) The support user + tickets + course progress (like "UserDetails" logic),
   * 3) The auth user (like "CustomerSidebar" logic) + purchases/course access,
   * 4) Possibly feedback status if needed
   * 5) Product titles
   */
  const initLoad = async () => {
    try {
      setLoading(true);
      setError(null);

      // 1) Current user's role (admin/moderator check)
      const { data: currentUser } = await supabase.auth.getUser();
      if (currentUser?.user) {
        const { data: userData } = await supabase
          .from('users')
          .select('role')
          .eq('id', currentUser.user.id)
          .maybeSingle();
        if (userData?.role) {
          setUserRole(userData.role);
        }
      }

      // 2) If we have a selectedUser, gather support user data + tickets
      if (!selectedUser) {
        setError('No user selected.');
        return;
      }

      // We'll do a function that fetches tickets/course progress:
      const userDetails = await fetchFullUserDetails(selectedUser.id);
      setDetails(userDetails);

      // 3) If we do have an auth_user_id, gather purchases, userCourseAccess, etc.
      if (selectedUser.auth_user_id) {
        const { role: _role } = userDetails.authUser || {};
        // role is already in userDetails, but not necessarily needed
        await loadAuthUserData(selectedUser.auth_user_id);
      }

      // 4) Load feedback status if relevant. 
      // (If you have a "ticketId" around, or if your code expects it.)
      // For now, we won't do that unless you have some param for it. 
      // If you do have a ticket reference, call loadFeedbackStatus(ticketId).

      // 5) Load product names
      await loadProductNames();

    } catch (err: any) {
      console.error('Error in initLoad:', err);
      setError(err.message || 'Error loading user data');
    } finally {
      setLoading(false);
    }
  };

  /** 
   * Grabs the "support user" row, plus the tickets and detailed course progress 
   * from your old "UserDetails" code. 
   */
  const fetchFullUserDetails = useCallback(async (supportUserId: string): Promise<UserDetailsData> => {
    // 1) support_users row
    const { data: supportUserData, error: supportUserError } = await supabase
      .from('support_users')
      .select('*')
      .eq('id', supportUserId)
      .single();
    if (supportUserError) throw supportUserError;
    if (!supportUserData) {
      throw new Error('Support user not found.');
    }

    const supportUser: SupportUser = supportUserData;

    // 2) tickets
    const { data: ticketsData, error: ticketsError } = await supabase
      .from('support_tickets')
      .select(
        `
        *,
        support_user:support_users (
          id,
          email,
          full_name,
          created_at,
          last_activity,
          auth_user_id,
          metadata,
          email_domain
        ),
        messages_count:support_messages (count)
      `
      )
      .eq('support_user_id', supportUserId)
      .order('created_at', { ascending: false });

    if (ticketsError) throw ticketsError;

    // gather adminIds
    const adminIds = (ticketsData || []).map((t: RawTicketData) => t.admin_id).filter(Boolean);
    const adminMap: Record<string, { email: string; full_name: string }> = {};
    if (adminIds.length > 0) {
      const { data: adminData } = await supabase
        .from('users')
        .select('id, email, full_name')
        .in('id', adminIds);
      if (adminData) {
        adminData.forEach((adm) => {
          adminMap[adm.id] = {
            email: adm.email,
            full_name: adm.full_name || 'Unknown Admin'
          };
        });
      }
    }

    const tickets: Ticket[] = (ticketsData || []).map((raw: RawTicketData) => ({
      id: raw.id,
      short_id: raw.short_id,
      support_user_id: raw.support_user_id,
      admin_id: raw.admin_id,
      status: raw.status,
      subject: raw.subject,
      body: raw.body,
      priority: raw.priority,
      created_at: raw.created_at,
      updated_at: raw.updated_at,
      last_activity_at: raw.last_activity_at,
      closed_at: raw.closed_at,
      tags: raw.tags || [],
      content_type: raw.content_type,
      content_id: raw.content_id,
      feedback_validity: raw.feedback_validity,
      feedback_reason: raw.feedback_reason,
      feedback_resolved: raw.feedback_resolved,
      has_unread: raw.has_unread,
      attachments: raw.attachments || [],
      source: raw.source,
      original_email_id: raw.original_email_id,
      original_from_email: raw.original_from_email,
      original_subject: raw.original_subject,
      email_thread_id: raw.email_thread_id,
      support_email_alias: raw.support_email_alias,
      is_spam: raw.is_spam,
      spam_score: raw.spam_score,
      thread_topic: raw.thread_topic,
      list_id: raw.list_id,
      user: raw.support_user,
      admin: raw.admin_id ? adminMap[raw.admin_id] : undefined,
      _count: { messages: raw.messages_count }
    }));

    // 3) If user not authenticated, we have no further data
    if (!supportUser.auth_user_id) {
      return {
        supportUser,
        authUser: null,
        tickets,
        courses: {}
      };
    }

    // 4) load the main user from "users"
    const { data: authUserData } = await supabase
      .from('users')
      .select('*')
      .eq('id', supportUser.auth_user_id)
      .maybeSingle();

    const authUser = (authUserData || null) as User | null;

    // 5) gather course progress for each course
    // also gather "mentor_modules" for total vs completed
    const { data: allModulesData } = await supabase
      .from('mentor_modules')
      .select('id');

    const mentorProgressData = {
      total: allModulesData?.length || 0,
      completed: 0
    };
    if (allModulesData?.length) {
      const moduleIds = allModulesData.map((m) => m.id);
      const { data: progressData } = await supabase
        .from('user_mentor_progress')
        .select('id, module_id, is_completed')
        .eq('user_id', supportUser.auth_user_id)
        .in('module_id', moduleIds);
      if (progressData) {
        mentorProgressData.completed = progressData.filter((p) => p.is_completed).length;
      }
    }

    // We'll skip MENTORSHIP course
    const courseIds = Object.values(COURSE_IDS).filter((cid) => cid !== COURSE_IDS.MENTORSHIP);

    const courseProgressMap: { [courseId: string]: ExtendedCourseProgress } = {};
    for (const courseId of courseIds) {
      const defaultProg: ExtendedCourseProgress = {
        examDate: null,
        customFlashcardsCount: 0,
        flashcardConfidence: {
          guessing: 0,
          maybe: 0,
          confident: 0,
          unanswered: 0,
          total: 0
        },
        quizCount: 0,
        studyPlanItems: { total: 0, completed: 0 },
        lessonProgress: { total: 0, completed: 0 },
        mentorProgress: { ...mentorProgressData },
        mcqProgress: {
          total: 0,
          correct: 0,
          incorrect: 0,
          confident: 0,
          maybe: 0,
          guessing: 0
        },
        tbsProgress: {
          total: 0,
          completed: 0,
          averageScore: 0,
          confident: 0,
          maybe: 0,
          guessing: 0
        }
      };

      // sp_course_mix
      const { data: mixData } = await supabase
        .from('sp_course_mix')
        .select(
          `
          *,
          primary_course:cpa_courses!sp_course_mix_primary_cpa_course_id_fkey(name),
          secondary_course:cpa_courses!sp_course_mix_secondary_cpa_course_id_fkey(name)
        `
        )
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId)
        .maybeSingle();
      if (mixData) {
        defaultProg.courseMix = {
          primaryCourseName: mixData.primary_course?.name || 'Unknown',
          secondaryCourseName: mixData.secondary_course?.name,
          alias: mixData.alias
        };
      }

      // exam date
      const { data: examDateData } = await supabase
        .from('sp_examdates')
        .select('*')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId)
        .maybeSingle();
      if (examDateData?.exam_date) {
        defaultProg.examDate = examDateData.exam_date;
      }

      // custom flashcards
      const { data: customCards } = await supabase
        .from('custom_flashcards')
        .select('confidence_level')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId);
      if (customCards) {
        defaultProg.customFlashcardsCount = customCards.length;
        defaultProg.flashcardConfidence.total = customCards.length;
        let guess = 0,
          mayb = 0,
          conf = 0,
          unans = 0;
        customCards.forEach((c) => {
          if (c.confidence_level === 'guessing') guess++;
          else if (c.confidence_level === 'maybe') mayb++;
          else if (c.confidence_level === 'confident') conf++;
          else unans++;
        });
        defaultProg.flashcardConfidence.guessing = guess;
        defaultProg.flashcardConfidence.maybe = mayb;
        defaultProg.flashcardConfidence.confident = conf;
        defaultProg.flashcardConfidence.unanswered = unans;
      }

      // quiz_history => custom quizzes
      const { data: quizData } = await supabase
        .from('quiz_history')
        .select('id')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId)
        .is('mock_exam_testlet_id', null)
        .is('study_task_testlet_id', null);
      if (quizData) {
        defaultProg.quizCount = quizData.length;
      }

      // sp_userstudyplans => sp_studyplanitems
      const { data: planData } = await supabase
        .from('sp_userstudyplans')
        .select('plan_id')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId)
        .maybeSingle();
      if (planData?.plan_id) {
        const { data: itemsData } = await supabase
          .from('sp_studyplanitems')
          .select('item_id,is_completed')
          .eq('plan_id', planData.plan_id);
        if (itemsData) {
          defaultProg.studyPlanItems.total = itemsData.length;
          defaultProg.studyPlanItems.completed = itemsData.filter((i) => i.is_completed).length;
        }
      }

      // user_lesson_responses
      const { data: lessonData } = await supabase
        .from('user_lesson_responses')
        .select('id,is_completed')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId);
      if (lessonData) {
        defaultProg.lessonProgress.total = lessonData.length;
        defaultProg.lessonProgress.completed = lessonData.filter((l) => l.is_completed).length;
      }

      // user_question_responses => MCQs
      const { data: qResp } = await supabase
        .from('user_question_responses')
        .select('id,is_correct,confidence_level')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId);
      if (qResp) {
        defaultProg.mcqProgress.total = qResp.length;
        let correct = 0,
          incorrect = 0,
          conf = 0,
          mayb = 0,
          guess = 0;
        qResp.forEach((r) => {
          if (r.is_correct) correct++;
          else incorrect++;
          if (r.confidence_level === 'confident') conf++;
          else if (r.confidence_level === 'maybe') mayb++;
          else if (r.confidence_level === 'guessing') guess++;
        });
        defaultProg.mcqProgress.correct = correct;
        defaultProg.mcqProgress.incorrect = incorrect;
        defaultProg.mcqProgress.confident = conf;
        defaultProg.mcqProgress.maybe = mayb;
        defaultProg.mcqProgress.guessing = guess;
      }

      // user_tbs_responses => TBS
      const { data: tbsData } = await supabase
        .from('user_tbs_responses')
        .select('id,completed,score,confidence_level')
        .eq('user_id', supportUser.auth_user_id)
        .eq('course_id', courseId);
      if (tbsData) {
        defaultProg.tbsProgress.total = tbsData.length;
        defaultProg.tbsProgress.completed = tbsData.filter((tb) => tb.completed).length;
        let totalScore = 0,
          conf = 0,
          mayb = 0,
          guess = 0;
        tbsData.forEach((t) => {
          totalScore += t.score ?? 0;
          if (t.confidence_level === 'confident') conf++;
          else if (t.confidence_level === 'maybe') mayb++;
          else if (t.confidence_level === 'guessing') guess++;
        });
        if (tbsData.length > 0) {
          defaultProg.tbsProgress.averageScore = Math.round(
            (totalScore / tbsData.length) * 100
          ) / 100;
        }
        defaultProg.tbsProgress.confident = conf;
        defaultProg.tbsProgress.maybe = mayb;
        defaultProg.tbsProgress.guessing = guess;
      }

      courseProgressMap[courseId] = defaultProg;
    }

    return {
      supportUser,
      authUser,
      tickets,
      courses: courseProgressMap
    };
  }, []);

  /** 
   * Loads the user record from `users`, plus purchases, course access, 
   * building your "courseAccess" summary. 
   */
  const loadAuthUserData = async (authUserId: string) => {
    // This uses supabaseAdmin to bypass RLS if needed.
    try {
      if (!authUserId) return;

      const { data: userData, error: userError } = await supabaseAdmin
        .from('users')
        .select('*')
        .eq('id', authUserId)
        .maybeSingle();
      if (userError) throw userError;
      if (!userData) return;

      const { data: purchasesData, error: purchasesError } = await supabaseAdmin
        .from('user_course_purchases')
        .select('*')
        .eq('user_id', authUserId)
        .order('purchase_date', { ascending: false });
      if (purchasesError) throw purchasesError;

      const { data: accessData, error: accessError } = await supabaseAdmin
        .from('user_course_access')
        .select('*')
        .eq('user_id', authUserId);
      if (accessError) throw accessError;

      setPurchases(purchasesData || []);
      setUserCourseAccess(accessData || []);

      const newAccessStatuses: CourseAccessStatus[] = (accessData || []).map((access) => ({
        productTitle: access.course_id,
        accessType: getAccessType(access.tier_id),
        tierId: access.tier_id
      }));
      setCourseAccess(newAccessStatuses);
    } catch (err) {
      console.error('Error loading auth user data:', err);
    }
  };

  /** Loads product titles for course IDs, if you want them. */
  
  /** Loads product titles for the relevant course IDs. */
  const loadProductNames = async () => {
    try {
      // Tell TS we expect an array of exactly { id, title } objects:
      const { data: prodData, error } = await supabase
        .from('products')
        .select('id, title')
        .in('type', ['course', 'mentorship'])
        .in('id', Object.values(COURSE_IDS)) as { data: ProductTitleOnly[]; error: any };
  
      if (error) throw error;
      if (!prodData) return;
  
      const map: Record<string, string> = {};
      prodData.forEach((p) => {
        map[p.id] = p.title;
      });
      setProducts(map);
    } catch (err) {
      console.error('Error loading product names:', err);
    }
  };
  

  /** If you have a particular ticket ID that references feedback, you can load it. */
  const loadFeedbackStatus = async (ticketId: string) => {
    try {
      const { data: ticket, error } = await supabase
        .from('support_tickets')
        .select('feedback_validity, feedback_reason, feedback_resolved')
        .eq('id', ticketId)
        .single();
      if (error) throw error;

      if (ticket) {
        setFeedbackStatus({
          validity: ticket.feedback_validity || 'pending',
          reason: ticket.feedback_reason,
          resolved: ticket.feedback_resolved || false
        });
      }
    } catch (err) {
      console.error('Error loading feedback status:', err);
    }
  };

  /** If a ticket references a question/lesson/etc., load that item. */
  const loadContentData = async (cType: ContentType, cId: string) => {
    try {
      setContentLoading(true);
      setError(null);

      const tableName = {
        questions: 'questions',
        lessons: 'lessons',
        simulations: 'simulations',
        flashcards: 'flashcards'
      }[cType];

      const { data, error } = await supabase
        .from(tableName)
        .select('*')
        .eq('id', cId)
        .maybeSingle();

      if (error) throw error;
      if (!data) {
        setError(`Could not find that ${cType}`);
        return;
      }
      // set the appropriate content object
      setQuestion(null);
      setLesson(null);
      setSimulation(null);
      setFlashcard(null);

      if (cType === 'questions') setQuestion(data as Question);
      if (cType === 'lessons') setLesson(data as Lesson);
      if (cType === 'simulations') setSimulation(data as Simulation);
      if (cType === 'flashcards') setFlashcard(data as Flashcard);
    } catch (err: any) {
      console.error(`Error loading content: ${cType}`, err);
      setError(err.message || `Error loading ${cType}`);
    } finally {
      setContentLoading(false);
    }
  };

  /** Helper for the tier -> label. */
  const getAccessType = (tierId: string): CourseAccessStatus['accessType'] => {
    switch (tierId) {
      case TIER_IDS.FULL_ACCESS:
        return 'Full Access';
      case TIER_IDS.STUDY_MATERIALS_ONLY:
        return 'Study Materials Only';
      case TIER_IDS.MENTOR_ONLY:
        return 'Mentor Only';
      default:
        return 'Free Trial';
    }
  };

  /** If a user doesn't have an auth_user_id, show "Send Invite" / "Resend invite" button. */
  const renderAuthInviteButton = () => {
    if (!selectedUser?.email) return null;
    if (selectedUser?.auth_user_id) return null; // user is already authenticated

    return (
      <>
        <Separator />
        <div className="flex items-center gap-2">
          <Button
            variant="destructive"
            size="sm"
            onClick={invitationSent ? handleResendInvite : handleSendInvite}
            disabled={sendingInvite}
            className="w-full bg-orange-500 hover:bg-orange-600 flex items-center justify-center gap-2"
          >
            {sendingInvite ? <Loader2 className="h-4 w-4 animate-spin" /> : <Send className="h-4 w-4" />}
            <span>
              {invitationSent ? 'Resend Authentication Email' : 'Send Authentication Email'}
            </span>
          </Button>
        </div>
        {lastInviteTime && (
          <div className="text-sm text-gray-500 mt-2">
            Last sent: {format(lastInviteTime, 'MMM d, yyyy h:mm a')}
          </div>
        )}
        {inviteError && (
          <Alert variant="destructive" className="mt-2">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{inviteError}</AlertDescription>
          </Alert>
        )}
      </>
    );
  };

  const handleSendInvite = async () => {
    if (!selectedUser?.email) return;
    try {
      setSendingInvite(true);
      setInviteError(null);

      const { error } = await auth.sendAuthenticationEmail(selectedUser.email);
      if (error) throw error;

      setInvitationSent(true);
      setLastInviteTime(new Date());
      onRefreshData?.('Authentication email sent successfully');
    } catch (err: any) {
      const msg = err.message || 'Error sending authentication email';
      setInviteError(msg);
      console.error(msg);
    } finally {
      setSendingInvite(false);
    }
  };

  const handleResendInvite = async () => {
    if (!selectedUser?.email) return;
    try {
      setSendingInvite(true);
      setInviteError(null);

      const { error } = await auth.resendAuthenticationEmail(selectedUser.email);
      if (error) throw error;

      setLastInviteTime(new Date());
      onRefreshData?.('Authentication email resent successfully');
    } catch (err: any) {
      const msg = err.message || 'Error resending authentication email';
      setInviteError(msg);
      console.error(msg);
    } finally {
      setSendingInvite(false);
    }
  };

  /** Returns a short label for the user’s status. */
  const getUserStatus = () => {
    if (!selectedUser) {
      return { label: 'No User', variant: 'destructive' };
    }
    if (selectedUser?.auth_user_id) {
      if (courseAccess.length > 0) {
        const hasFull = courseAccess.some((c) => c.tierId === TIER_IDS.FULL_ACCESS);
        return {
          label: hasFull ? 'Customer' : 'Free Trial',
          variant: hasFull ? 'default' : 'secondary'
        };
      }
      return { label: 'Registered', variant: 'outline' };
    }
    return { label: 'Unregistered', variant: 'destructive' };
  };

  /** Helper for "Feedback Investigation" if the user is an admin/moderator. */
  const canInvestigateFeedback = () => {
    return userRole === 'admin' || userRole === 'moderator';
  };

  /** If we had a specific ticket ID, we could open /admin/feedback/xxx to investigate. */
  const handleInvestigateFeedback = () => {
    // Example only; adjust as needed
  };

  const renderValidityBadge = (validity: FeedbackValidity) => {
    const styles = {
      pending: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100',
      yes: 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100',
      no: 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
    };
    const labels = {
      pending: 'Pending Review',
      yes: 'Valid Feedback',
      no: 'Invalid Feedback'
    };
    return (
      <Badge variant="secondary" className={`${styles[validity]} border-0`}>
        {labels[validity]}
      </Badge>
    );
  };

  const renderResolutionBadge = (resolved: boolean) => {
    return (
      <Badge
        variant="secondary"
        className={
          resolved
            ? 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100 border-0'
            : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100 border-0'
        }
      >
        {resolved ? 'Resolved' : 'Unresolved'}
      </Badge>
    );
  };

  /** If content is assigned (contentType/contentId), show a summary & link. */
  const renderContentReference = () => {
    if (!contentType || !contentId) return null;
    const labelMap: Record<ContentType, string> = {
      questions: 'Question',
      lessons: 'Lesson',
      simulations: 'Simulation',
      flashcards: 'Flashcard'
    };
    const label = labelMap[contentType] || 'Content';
    return (
      <Card className="mb-4">
        <div className="p-4 flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <HelpCircle className="w-4 h-4" />
            <span className="font-medium">{label} Reference</span>
          </div>
          <Button
            size="sm"
            onClick={() => onNavigateToContent?.(contentType, contentId)}
            className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white"
          >
            <span>See {label}</span>
            <ExternalLink className="w-4 h-4" />
          </Button>
        </div>
      </Card>
    );
  };

  /** 
   * The big "render" function for the entire drawer below:
   * (1) If loading or error,
   * (2) If we have partial or full data,
   * (3) Show user info, tickets, course data, plus Payment/Course Access controls, etc.
   */
  const renderDrawerBody = () => {
    if (!selectedUser) {
      return <div className="p-4 text-sm text-gray-500">No user selected.</div>;
    }
    if (loading) {
      return (
        <div className="p-4 flex items-center justify-center">
          <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
        </div>
      );
    }
    if (error) {
      return (
        <div className="p-4 flex flex-col items-center justify-center text-red-500">
          <AlertCircle className="w-6 h-6 mb-2" />
          <p>{error}</p>
        </div>
      );
    }
    if (!details) {
      return <div className="p-4 text-sm text-gray-500">No details available.</div>;
    }

    // Unpack details
    const { supportUser, authUser, tickets, courses } = details;

    // If user not authenticated, partial info only
    if (!authUser) {
      return (
        <div className="p-4 space-y-4">
          <Card className="p-3 md:p-4">
            <div className="flex items-center gap-2">
              <UserCircle className="w-4 h-4 text-gray-500" />
              <h3 className="text-sm md:text-base font-semibold">User Info</h3>
            </div>
            <Separator className="my-1" />
            <div className="text-sm">
              <div>
                <strong>Name:</strong> {supportUser.full_name}
              </div>
              <div>
                <strong>Email:</strong> {supportUser.email}
              </div>
              <div className="mt-2 text-orange-600">
                This user is not authenticated in the main system. No additional data.
              </div>
            </div>
            {renderAuthInviteButton()}
          </Card>
          {renderTicketsSection(tickets)}
        </div>
      );
    }

    // Otherwise, we have an auth user => show everything
    return (
      <div className="p-3 md:p-4 space-y-4">
        {/* Basic user info */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2">
            <UserCircle className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">User Info</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            <div>
              <strong>Name: </strong>
              {authUser.full_name || supportUser.full_name}
            </div>
            <div>
              <strong>Email: </strong>
              {authUser.email || supportUser.email}
            </div>
            <div>
              <strong>Role: </strong>
              {authUser.role}
            </div>
            {authUser.last_sign_in_at && (
              <div>
                <strong>Last Sign In: </strong>
                {new Date(authUser.last_sign_in_at).toLocaleString()}
              </div>
            )}
          </div>
        </Card>

        {/* Tickets */}
        {renderTicketsSection(tickets)}

        {/* Courses / Summaries */}
        <Tabs value={activeCourse} onValueChange={setActiveCourse}>
          <TabsList className="w-full h-10">
            <TabsTrigger value="ALL">All Courses</TabsTrigger>
            {Object.values(COURSE_IDS).map((cid) => (
              <TabsTrigger key={cid} value={cid}>
                {courseNames[cid] || 'Loading...'}
              </TabsTrigger>
            ))}
          </TabsList>

          <TabsContent value="ALL" className="pt-3">
            {renderAllCoursesSummary(courses)}
          </TabsContent>
          {Object.values(COURSE_IDS).map((cid) => (
            <TabsContent key={cid} value={cid} className="pt-3">
              {renderCourseContent(cid, courses)}
            </TabsContent>
          ))}
        </Tabs>

        {/* Course Access & Payment Info */}
        <Card className="p-3 md:p-4">
          <div className="space-y-2 md:space-y-3">
            <div className="flex items-center gap-1.5">
              <BookOpen className="w-4 h-4 text-gray-500" />
              <span className="font-medium text-sm">Course Access</span>
            </div>
            {courseAccess.length === 0 ? (
              <div className="text-sm text-gray-500 pl-6">No active course access</div>
            ) : (
              <div className="space-y-2 pl-6">
                {courseAccess.map((access, i) => (
                  <div
                    key={`courseAccess-${i}`}
                    className="flex items-center justify-between gap-2 min-w-0"
                  >
                    <span className="text-sm font-medium truncate">
                      {products[access.productTitle] || access.productTitle}
                    </span>
                    <Badge
                      variant={access.tierId === TIER_IDS.FULL_ACCESS ? 'default' : 'secondary'}
                      className="text-xs whitespace-nowrap flex-shrink-0"
                    >
                      {access.accessType}
                    </Badge>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Card>

        {/* Course Access Controls */}
        <Card className="overflow-hidden">
          <div className="p-3 md:p-4">
            <CourseAccessControls
              userId={authUser.id}
              userEmail={authUser.email}
              currentAccess={userCourseAccess}
              defaultOpen={false}
              onAccessChanged={async () => {
                await loadAuthUserData(authUser.id);
                onRefreshData?.('Course access updated');
              }}
            />
          </div>
        </Card>

        {/* Payment Controls */}
        <Card className="overflow-hidden">
          <div className="p-3 md:p-4">
            <PaymentControls
              purchases={purchases}
              defaultOpen={false}
              onPurchaseUpdate={async () => {
                await loadAuthUserData(authUser.id);
                onRefreshData?.('Purchases updated');
              }}
            />
          </div>
        </Card>
      </div>
    );
  };

  /** Render each ticket in a small list. */
  const renderTicketsSection = (tickets: Ticket[]) => {
    if (!tickets.length) {
      return (
        <Card className="p-3 md:p-4">
          <div className="text-sm text-gray-500">No tickets found for this user.</div>
        </Card>
      );
    }
    return (
      <Card className="p-3 md:p-4 space-y-2">
        <div className="flex items-center gap-2">
          <Mail className="w-4 h-4 text-gray-500" />
          <h3 className="text-sm md:text-base font-semibold">Support Tickets</h3>
        </div>
        <Separator className="my-1" />
        <div className="max-h-64 overflow-y-auto text-sm space-y-2">
          {tickets.map((ticket) => (
            <div
              key={ticket.id}
              className="border-b last:border-none dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer transition-colors"
              onClick={() => {
                window.location.href = `/admin/support/ticket/${ticket.id}`;
              }}
            >
              <div className="flex items-center justify-between gap-3 py-2 px-2">
                <div className="flex-1 min-w-0">
                  <div className="font-medium line-clamp-1 text-gray-900 dark:text-gray-100">
                    {ticket.subject}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400 text-xs">
                    {ticket.status} - {ticket.priority}
                  </div>
                </div>
                <Button
                  variant="secondary"
                  size="sm"
                  className="h-7 px-3 text-xs flex items-center justify-center shrink-0 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/admin/support/ticket/${ticket.id}`, '_blank');
                  }}
                >
                  <span className="flex items-center">
                    #{ticket.short_id}
                    <ExternalLink className="w-3 h-3 ml-1.5" />
                  </span>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  };

  /** Renders a minimal summary for "ALL" courses. */
  const renderAllCoursesSummary = (courses: { [key: string]: ExtendedCourseProgress }) => {
    const courseIds = Object.keys(courses);
    if (!courseIds.length) {
      return (
        <div className="p-4 text-sm text-gray-500">
          No course data found.
        </div>
      );
    }
    const totalExamDates = courseIds.reduce((count, cid) => {
      if (courses[cid].examDate) return count + 1;
      return count;
    }, 0);
    return (
      <Card className="p-3 md:p-4">
        <div className="flex items-center gap-2 mb-2">
          <Calendar className="w-4 h-4 text-gray-500" />
          <h3 className="text-sm md:text-base font-semibold">Overview - All Courses</h3>
        </div>
        <Separator className="my-1" />
        <div className="text-sm text-gray-700">
          <div>Number of exam dates set: {totalExamDates}</div>
          <div>Courses with data: {courseIds.length}</div>
          <div className="text-xs text-gray-500 mt-1 italic">
            Select a course tab to see more details.
          </div>
        </div>
      </Card>
    );
  };

  /** Renders the progress details for one course. */
  const renderCourseContent = (
    courseId: string,
    courses: { [key: string]: ExtendedCourseProgress }
  ) => {
    const data = courses[courseId];
    if (!data) {
      return (
        <div className="p-4 text-sm text-gray-500">
          No data for this course.
        </div>
      );
    }
    return (
      <div className="space-y-3">
        {/* Course Mix */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileText className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Course Mix</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            {data.courseMix ? (
              <>
                <div>
                  <strong>Primary Course:</strong> {data.courseMix.primaryCourseName}
                </div>
                {data.courseMix.secondaryCourseName && (
                  <div>
                    <strong>Secondary Course:</strong> {data.courseMix.secondaryCourseName}
                  </div>
                )}
                {data.courseMix.alias && (
                  <div>
                    <strong>Alias:</strong> {data.courseMix.alias}
                  </div>
                )}
              </>
            ) : (
              <div className="text-gray-500 italic">No course mix set</div>
            )}
          </div>
        </Card>

        {/* Exam Date */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Exam Date</h3>
          </div>
          <Separator className="my-1" />
          {data.examDate ? (
            <div className="text-gray-800 text-sm">
              {new Date(data.examDate).toLocaleString()}
            </div>
          ) : (
            <div className="text-sm text-gray-500 italic">No exam date set</div>
          )}
        </Card>

        {/* Study Plan Items */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileText className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Study Plan Items</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm flex gap-2">
            <span>
              Total: <strong>{data.studyPlanItems.total}</strong>
            </span>
            <span>
              Completed: <strong>{data.studyPlanItems.completed}</strong>
            </span>
          </div>
        </Card>

        {/* Custom Flashcards */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <SmilePlus className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Custom Flashcards</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-2">
            <div>
              <strong>Total Created:</strong> {data.customFlashcardsCount}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 text-xs text-gray-600">
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Confident</Badge> {data.flashcardConfidence.confident}
              </div>
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Maybe</Badge> {data.flashcardConfidence.maybe}
              </div>
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Guessing</Badge> {data.flashcardConfidence.guessing}
              </div>
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Unanswered</Badge> {data.flashcardConfidence.unanswered}
              </div>
            </div>
          </div>
        </Card>

        {/* Custom Quizzes */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileQuestion className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Custom Quizzes</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm">
            <strong>Created:</strong> {data.quizCount}
          </div>
        </Card>

        {/* Lessons Progress */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <ListOrdered className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Lessons Progress</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm flex gap-3">
            <span>
              Total: <strong>{data.lessonProgress.total}</strong>
            </span>
            <span>
              Completed: <strong>{data.lessonProgress.completed}</strong>
            </span>
          </div>
        </Card>

        {/* Mentor Progress */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <UserCircle className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">Mentor Progress</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm flex gap-3">
            <span>
              Total Modules: <strong>{data.mentorProgress.total}</strong>
            </span>
            <span>
              Completed: <strong>{data.mentorProgress.completed}</strong>
            </span>
          </div>
        </Card>

        {/* MCQ Progress */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileText className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">MCQ Progress</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            <div>Total Attempted: {data.mcqProgress.total}</div>
            <div>Correct: {data.mcqProgress.correct}</div>
            <div>Incorrect: {data.mcqProgress.incorrect}</div>
            <div>Confident: {data.mcqProgress.confident}</div>
            <div>Maybe: {data.mcqProgress.maybe}</div>
            <div>Guessing: {data.mcqProgress.guessing}</div>
          </div>
        </Card>

        {/* TBS Progress */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <Edit className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">TBS Progress</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            <div>Total Attempts: {data.tbsProgress.total}</div>
            <div>Completed: {data.tbsProgress.completed}</div>
            <div>
              Average Score: {data.tbsProgress.averageScore.toFixed(2)}
            </div>
            <div>Confident: {data.tbsProgress.confident}</div>
            <div>Maybe: {data.tbsProgress.maybe}</div>
            <div>Guessing: {data.tbsProgress.guessing}</div>
          </div>
        </Card>
      </div>
    );
  };

  /** For an optional "Feedback Investigation" card. */
  const renderFeedbackInvestigation = () => {
    if (!feedbackStatus) return null; // or if you only show it under certain conditions
    if (!canInvestigateFeedback()) return null;

    return (
      <Card className="mb-4 border dark:border-gray-800">
        <div className="p-4">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <MessageSquare className="w-4 h-4 text-gray-500 dark:text-gray-400" />
              <span className="font-medium dark:text-gray-200">Feedback Investigation</span>
            </div>
            <Button
              size="sm"
              onClick={handleInvestigateFeedback}
              className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white"
            >
              <span>
                {feedbackStatus.resolved
                  ? 'Review Feedback'
                  : feedbackStatus.validity === 'pending'
                  ? 'Investigate'
                  : 'Review'}
              </span>
              <ExternalLink className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex flex-wrap gap-2 mt-3">
            {renderValidityBadge(feedbackStatus.validity)}
            {renderResolutionBadge(feedbackStatus.resolved)}
          </div>
          {feedbackStatus.reason && (
            <div className="mt-4">
              <div className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Feedback Review Notes:
              </div>
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded border dark:border-gray-700">
                <Accordion type="single" collapsible>
                  <AccordionItem value="notes" className="border-none">
                    {feedbackStatus.reason.length > 250 && (
                      <div className="flex justify-end px-2 pt-2">
                        <AccordionTrigger className="text-xs text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 py-0 w-auto hover:no-underline flex items-center gap-1">
                          Show full note
                          <svg
                            className="w-3 h-3 transform transition-transform duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </AccordionTrigger>
                      </div>
                    )}
                    <div className="p-2">
                      <div className="whitespace-pre-wrap break-words leading-relaxed">
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          {feedbackStatus.reason.length > 250
                            ? feedbackStatus.reason.substring(0, 250)
                            : feedbackStatus.reason}
                        </p>
                      </div>
                    </div>
                    {feedbackStatus.reason.length > 250 && (
                      <AccordionContent>
                        <div className="px-2 pb-2">
                          <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-wrap break-words leading-relaxed">
                            {feedbackStatus.reason}
                          </p>
                        </div>
                      </AccordionContent>
                    )}
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          )}
        </div>
      </Card>
    );
  };

  /** Final return: a Drawer that merges everything. */
  return (
    <Drawer open={isOpen} onOpenChange={onOpenChange} side="right" width="xl">
      <DrawerContent className="flex flex-col bg-white dark:bg-gray-900 z-50">
        <DrawerHeader className="border-b dark:border-gray-800 flex items-center justify-between px-4 py-3">
          <div className="flex flex-col space-y-1">
            <DrawerTitle className="text-lg font-semibold">
              {selectedUser
                ? `User Information – ${selectedUser.full_name || selectedUser.email}`
                : 'User Information'}
            </DrawerTitle>
            {/* Possibly show user status as a small badge */}
            {selectedUser && (
              <Badge variant={getUserStatus().variant as any} className="text-xs">
                {getUserStatus().label}
              </Badge>
            )}
          </div>

          <Button
            variant="ghost"
            size="sm"
            onClick={() => onOpenChange(false)}
            className="h-8 w-8 p-0 hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            <XCircle className="h-4 w-4" />
          </Button>
        </DrawerHeader>

        {/* Drawer body: scrollable content */}
        <div className="flex-1 overflow-y-auto bg-gray-50 dark:bg-gray-900/50">
          <div className="p-4 space-y-4">
            {renderFeedbackInvestigation()}
            {renderContentReference()}

            {/* The main "meat" of the user details */}
            {renderDrawerBody()}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default UserSearchDrawer;
