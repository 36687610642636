import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Home, Book, User } from 'lucide-react';
import { useSafeArea, useDeviceDetection } from '../../../hooks/useMobile';

interface BottomNavigationProps {
  currentPath: string;
  isInsideCourse: boolean;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({ currentPath, isInsideCourse }) => {
  const { courseId } = useParams<{ courseId: string }>();
  const location = useLocation();
  const safeArea = useSafeArea();
  const deviceInfo = useDeviceDetection();

  const navItems = [
    { path: '/', icon: Home, label: 'Home' },
    { path: '/products', icon: Book, label: 'Products' },
    { path: '/profile', icon: User, label: 'Profile' },
  ];

  // Hide navigation if inside a course or viewing tickets
  if (isInsideCourse || location.pathname.includes('/support')) {
    return null;
  }

  // Calculate bottom safe area padding
  const bottomSafeArea = Math.max(safeArea.bottom, deviceInfo.isIOS ? 20 : 0);
  
  return (
    <nav 
      className="md:hidden fixed bottom-0 left-0 right-0 bg-white/80 dark:bg-gray-800/80 
                 backdrop-blur-lg border-t border-gray-200 dark:border-gray-700 z-40
                 shadow-lg shadow-black/5"
      style={{
        paddingBottom: `max(${bottomSafeArea}px, env(safe-area-inset-bottom))`,
      }}
    >
      <ul className="flex justify-around">
        {navItems.map((item) => {
          const Icon = item.icon;
          const isActive = currentPath === item.path;
          
          return (
            <li key={item.path} className="flex-1">
              <Link
                to={item.path}
                className={`
                  flex flex-col items-center justify-center py-2
                  transition-colors duration-200 no-select
                  ${isActive 
                    ? 'text-primary-blue dark:text-primary-blue' 
                    : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200'
                  }
                `}
              >
                <Icon 
                  size={24} 
                  className={`transition-transform duration-200 ${isActive ? 'scale-105' : ''}`}
                />
                <span className={`
                  text-xs mt-1 font-medium
                  ${isActive ? 'opacity-100' : 'opacity-80'}
                `}>
                  {item.label}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>

      {/* Safe area spacer for older iOS devices */}
      {deviceInfo.isIOS && bottomSafeArea === 0 && (
        <div className="h-5 bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg" />
      )}
    </nav>
  );
};

export default BottomNavigation;