import React, { useCallback, useMemo } from 'react';
import { FilterCounts, QuizHistory, UserCourseAccess, TIER_IDS } from '../../services/api';

interface QuizSummaryFilterProps {
  filter: string;
  setFilter: (filter: string) => void;
  counts: FilterCounts;
  currentSummary: QuizHistory[];
  userCourseAccess?: UserCourseAccess | undefined;
}

const QuizSummaryFilter: React.FC<QuizSummaryFilterProps> = React.memo(({
  filter,
  setFilter,
  counts,
  currentSummary,
  userCourseAccess
}) => {
  const isLimitedTier = useMemo(() => {
    if (!userCourseAccess?.tier_id) return false;
    
    const limitedTiers = [TIER_IDS.FREE_TRIAL, TIER_IDS.MENTOR_ONLY] as const;
    return limitedTiers.includes(userCourseAccess.tier_id as typeof limitedTiers[number]);
  }, [userCourseAccess]);

  const filteredCounts = useMemo(() => {
    if (!isLimitedTier) return counts;

    // Calculate counts only for free trial content
    return currentSummary.reduce((acc, quiz) => {
      quiz.questions_data.forEach(question => {
        if (!question.free_trial_content) return;

        acc.all++;
        if (question.isCorrect) acc.correct++;
        else if (question.answered) acc.incorrect++;

        if (question.confidenceLevel) {
          const level = question.confidenceLevel.toLowerCase() as keyof FilterCounts;
          if (level in acc) {
            acc[level]++;
          }
        }
      });
      return acc;
    }, {
      all: 0,
      correct: 0,
      incorrect: 0,
      confident: 0,
      maybe: 0,
      guessing: 0
    } as FilterCounts);
  }, [counts, currentSummary, isLimitedTier]);

  const handleFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, [setFilter]);

  return (
    <div className="mb-4">
      <h3 className="font-bold mb-2 text-light-text dark:text-dark-text">
        Filter Results {isLimitedTier && '(Free Trial Content)'}
      </h3>
      <div className="flex flex-wrap space-x-2">
        {['All', 'Correct', 'Incorrect', 'Confident', 'Maybe', 'Guessing'].map((option) => {
          const countKey = option.toLowerCase() as keyof FilterCounts;
          const count = filteredCounts[countKey] || 0;
          const isDisabled = count === 0 && option !== 'All';
          
          return (
            <button
              key={option}
              onClick={() => !isDisabled && handleFilterChange(option.toLowerCase())}
              className={`px-3 py-1 rounded mb-2 ${
                filter === option.toLowerCase()
                  ? 'bg-blue-500 text-white'
                  : isDisabled
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
              }`}
              disabled={isDisabled}
            >
              {option} ({count})
            </button>
          );
        })}
      </div>
    </div>
  );
});

export default QuizSummaryFilter;