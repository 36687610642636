import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { queryClient, cacheManager } from '../../services/api';
import { User as UserIcon, Sun, Moon } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { Switch } from './../../components/ui/Switch';
import { useAuth } from '../../components/AuthProvider';
import { useUserCourseAccess } from '../../components/UserCourseAccessProvider';
import { TIER_IDS } from '../../services/api';

function ProfileDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { theme, toggleTheme } = useTheme();
  const { user, loading } = useAuth();
  const { userCourseAccesses } = useUserCourseAccess();
  const navigate = useNavigate();

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      
      // 1. Set logout message in sessionStorage first
      sessionStorage.setItem('authMessage', 'Successfully logged out');
      
      // 2. Clear React Query cache and remove all queries
      queryClient.clear();
      queryClient.removeQueries();
      
      // 3. Clear CacheManager caches
      cacheManager.clear();
      
      // 4. Clear specific user caches if we have a user
      if (user?.id) {
        await api.clearUserCache(user.id);
      }
      
      // 5. Clear all auth-related localStorage items
      Object.keys(localStorage).forEach(key => {
        if (
          key.includes('supabase') ||
          key.includes('sb-') ||
          key.includes('auth') ||
          key.includes('userData') ||
          key.includes('userAccess') ||
          key.includes('flashcard') ||
          key.includes('simulation') ||
          key.includes('customFlashcards') ||
          key.includes('products')
        ) {
          localStorage.removeItem(key);
        }
      });
      
      // 6. Clear auth-related sessionStorage
      Object.keys(sessionStorage).forEach(key => {
        if (key !== 'authMessage') { // Keep the logout message
          sessionStorage.removeItem(key);
        }
      });

      // 7. Perform Supabase logout
      await auth.logout();
      
      // 8. Close dropdown
      setIsOpen(false);
      
      // 9. Redirect to login page with full URL
      window.location.href = 'https://app.keslercpareview.com/login';
    } catch (error) {
      console.error('Error logging out:', error);
      
      // Even on error, ensure we clean up and redirect
      sessionStorage.setItem('authMessage', 'Logged out due to an error');
      localStorage.clear();
      sessionStorage.clear();
      queryClient.clear();
      cacheManager.clear();
      
      window.location.href = 'https://app.keslercpareview.com/login';
    } finally {
      setIsLoggingOut(false);
    }
  };

  const handleThemeToggle = () => {
    toggleTheme();
    setIsOpen(false);
  };

  const getUserAccessTag = () => {
    if (!userCourseAccesses?.length) return null;

    const hasPaidAccess = userCourseAccesses.some(
      access => access.tier_id === TIER_IDS.FULL_ACCESS || 
                access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
    );

    if (hasPaidAccess) {
      return {
        text: 'Paid Access',
        className: 'text-green-600 dark:text-green-400'
      };
    }

    const hasMentorAccess = userCourseAccesses.some(
      access => access.tier_id === TIER_IDS.MENTOR_ONLY
    );

    if (hasMentorAccess) {
      return {
        text: 'Mentee User',
        className: 'text-purple-600 dark:text-purple-400'
      };
    }

    const isFreeTrial = userCourseAccesses.every(
      access => access.tier_id === TIER_IDS.FREE_TRIAL
    );

    if (isFreeTrial) {
      return {
        text: 'Free Trial User',
        className: 'text-blue-600 dark:text-blue-400'
      };
    }

    return null;
  };

  const accessTag = getUserAccessTag();

  if (loading) {
    return (
      <div className="flex items-center justify-center w-10 h-10">
        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center w-10 h-10 text-sm font-medium text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-gray-300 dark:hover:text-gray-100"
      >
        <UserIcon className="w-6 h-6" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50 dark:bg-gray-800">
          {user ? (
            <>
              {/* User Info Section */}
              <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                <div className="text-sm font-medium text-gray-900 dark:text-white truncate">
                  {user.full_name || user.email}
                </div>
                {accessTag && (
                  <div className={`text-xs ${accessTag.className}`}>
                    {accessTag.text}
                  </div>
                )}
              </div>

              <Link 
                to="/profile" 
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                Profile
              </Link>
              
              <Link 
                to="/products" 
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                My Products
              </Link>

              {/* Support Links */}
              <div className="border-t border-gray-200 dark:border-gray-700">
                <Link 
                  to="/user/support/new" 
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                  onClick={() => setIsOpen(false)}
                >
                  Create Support Ticket
                </Link>
                <Link 
                  to="/user/support" 
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                  onClick={() => setIsOpen(false)}
                >
                  My Tickets
                </Link>
              </div>

              {/* Admin-only section */}
              {user.role === 'admin' && (
                <div className="border-t border-gray-200 dark:border-gray-700">
                  <Link 
                    to="/admin/support" 
                    className="flex items-center px-4 py-2 text-sm text-blue-600 hover:bg-gray-100 dark:text-blue-400 dark:hover:bg-gray-700" 
                    onClick={() => setIsOpen(false)}
                  >
                    Support Dashboard
                  </Link>
                  <Link 
                    to="/admin/feedback" 
                    className="flex items-center px-4 py-2 text-sm text-blue-600 hover:bg-gray-100 dark:text-blue-400 dark:hover:bg-gray-700" 
                    onClick={() => setIsOpen(false)}
                  >
                    Feedback Dashboard
                  </Link>
                </div>
              )}

              {/* Settings Section */}
              <div className="border-t border-gray-200 dark:border-gray-700">
                <div className="flex items-center justify-between px-4 py-2">
                  {theme === 'dark' ? (
                    <Moon className="h-4 w-4 text-gray-700 dark:text-gray-300" />
                  ) : (
                    <Sun className="h-4 w-4 text-gray-700 dark:text-gray-300" />
                  )}
                  <Switch
                    checked={theme === 'light'}
                    onChange={handleThemeToggle}
                  />
                </div>
                <button 
                  onClick={handleLogout}
                  disabled={isLoggingOut}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 disabled:opacity-50"
                >
                  {isLoggingOut ? 'Logging out...' : 'Logout'}
                </button>
              </div>
            </>
          ) : (
            <>
              <Link 
                to="/login" 
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                Login
              </Link>
              <Link 
                to="/register" 
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                Register
              </Link>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileDropdown;