import { useState, useEffect, useCallback, useRef } from 'react';
import ProductList from '../components/products/ProductList';
import { useProducts } from '../components/ProductProvider';
import { userDataService } from '../services/userDataService';
import { courseService } from '../services/courseService';
import { Product, UserAccessState, TIER_IDS } from '../services/api';
import { getUserProductAccess } from '../utils/accessUtils';
import UpgradeModal from '../components/course/common/UpgradeModal';
import UpgradeSMOnlyModal from '../components/course/common/switch-course/UpgradeSMOnlyModal';
import SwitchConfirmationModal from '../components/course/common/switch-course/SwitchConfirmationModal';
import { useAuth } from '../components/AuthProvider';
import { useUserCourseAccess } from '../components/UserCourseAccessProvider';
import { auth } from '../services/auth';
import { useNotification } from '../contexts/NotificationContext';
import LoadingScreen from '../components/course/common/LoadingScreen';
import { queryClient } from '../services/queryClient';
import { createLogger } from '../utils/Logger';

const logger = createLogger({ namespace: 'Products' });

function Products() {
  const [products, setProducts] = useState<Product[]>([]);
  const [accessStates, setAccessStates] = useState<Record<string, UserAccessState>>({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showSMOnlyModal, setShowSMOnlyModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{id: string, title: string} | null>(null);
  const [currentCourse, setCurrentCourse] = useState<{id: string, title: string} | null>(null);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);  // Single loading state

  const { user, loading: userLoading, error } = useAuth();
  const { getProduct, loading: productsLoading, error: productsError } = useProducts();
  const { refetch: refetchUserCourseAccess } = useUserCourseAccess();
  const { showNotification } = useNotification();
  const fetchInProgress = useRef(false);

  logger.debug('Hook states:', {
    userLoading,
    userError: error?.message,
    productsLoading,
    productsError: productsError,
    hasUser: !!user,
    userId: user?.id
  });

  const fetchUserData = useCallback(async () => {
    if (!user || fetchInProgress.current) {
      logger.debug('fetchUserData: Skipping fetch - no user or fetch in progress');
      return;
    }

    logger.debug('fetchUserData: Starting data fetch for user', user.id);
    fetchInProgress.current = true;

    try {
      const userData = await userDataService.getAllUserData(user.id);
      logger.debug('User data fetched:', {
        courseAccessesCount: userData.courseAccesses.length,
        coursePurchasesCount: userData.coursePurchases.length,
        hasMentorshipAccess: !!userData.mentorshipAccess
      });

      const courseDetails = await Promise.all(
        userData.courseAccesses.map(access => getProduct(access.course_id))
      );

      const validProducts = courseDetails.filter((product): product is Product => {
        const isValid = product !== null;
        if (!isValid) {
          console.warn('Found null product in courseDetails');
        }
        return isValid;
      });
      logger.debug('Valid products count:', validProducts.length);

      setProducts(validProducts);

      const states = validProducts.reduce((acc, product) => {
        acc[product.id] = getUserProductAccess(
          product,
          userData.courseAccesses,
          userData.coursePurchases,
          userData.mentorshipAccess || undefined
        );
        return acc;
      }, {} as Record<string, UserAccessState>);

      logger.debug('Access states calculated:', {
        productCount: Object.keys(states).length,
        statesSummary: Object.entries(states).map(([id, state]) => ({
          id,
          hasFullAccess: state.hasFullAccess,
          isActivatable: state.isActivatable,
          hasMentorAccess: state.hasMentorAccess
        }))
      });

      setAccessStates(states);

      // Find active course
      const activeCourse = validProducts.find(p => 
        states[p.id]?.hasFullAccess || states[p.id]?.currentTier === TIER_IDS.FULL_ACCESS || 
        states[p.id]?.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY
      );

      const nonMentorshipCourses = validProducts.filter(p => 
        p.type !== 'mentorship' && 
        p.id !== '7b008bd2-4b5d-4876-82d5-c9f1e05895e0'
      );

      const firstAvailableCourse = !activeCourse && user.switches_available > 0 && nonMentorshipCourses.length > 0
        ? nonMentorshipCourses[0]
        : null;

      const courseToUse = activeCourse || firstAvailableCourse;

      if (courseToUse) {
        logger.debug('Setting active/first available course:', {
          id: courseToUse.id,
          title: courseToUse.title,
          isActive: !!activeCourse
        });
        setCurrentCourse({
          id: courseToUse.id,
          title: courseToUse.title
        });
      } else {
        logger.debug('No active or available course found');
      }
    } catch (err) {
      console.error('Failed to fetch user data:', err);
    } finally {
      fetchInProgress.current = false;
      setIsInitialLoad(false);
    }
  }, [user, getProduct]);

  useEffect(() => {
    if (isInitialLoad && user && !userLoading) {
      fetchUserData();
    }
  }, [user, userLoading, fetchUserData, isInitialLoad]);

  const handleActivate = useCallback(async (productId: string) => {
    if (!user || currentCourse?.id === productId) {
      showNotification(
        'Cannot switch to the same course you are switching from. Please select a different course.',
        'error'
      );
      return;
    }

    const product = products.find(p => p.id === productId);
    if (product) {
      setSelectedProduct({
        id: product.id,
        title: product.title
      });
      setShowSwitchModal(true);
    }
  }, [user, currentCourse, products, showNotification]);

  const refreshAllData = useCallback(async (userId: string) => {
    logger.debug('Starting optimized data refresh');
    try {
      // Get fresh user data without clearing cache
      const freshUserData = await auth.getUserProfile(userId);
      
      // Update user data in cache
      queryClient.setQueryData(['user', userId], freshUserData);
      
      // Fetch new data
      const userData = await userDataService.refreshUserData(userId);
      
      // Update course details
      const courseDetails = await Promise.all(
        userData.courseAccesses.map(access => getProduct(access.course_id))
      );

      const validProducts = courseDetails.filter((product): product is Product => product !== null);

      // Calculate new access states with fresh data
      const newStates = validProducts.reduce((acc, product) => {
        acc[product.id] = getUserProductAccess(
          product,
          userData.courseAccesses,
          userData.coursePurchases,
          userData.mentorshipAccess || undefined
        );
        return acc;
      }, {} as Record<string, UserAccessState>);

      // Update state without triggering loading screens
      setProducts(validProducts);
      setAccessStates(newStates);

      // Update cache with new data
      queryClient.setQueryData(['userData', userId], userData);

      return freshUserData;
    } catch (error) {
      console.error('Error during data refresh:', error);
      throw error;
    }
  }, [getProduct]); 

  if (productsError) {
    return <div className="text-red-500">Failed to load courses</div>;
  }

  return (
    <div className="max-w-6xl mx-auto">
      {isSwitching && (
        <LoadingScreen 
          message="Switching Course Access"
          subMessage="Please wait while we update your course access..."
        />
      )}
      
      <h1 className="text-3xl font-bold mb-6 px-4 md:px-0">Available Courses</h1>
      <ProductList 
        products={products}
        accessStates={accessStates}
        switchesAvailable={user?.switches_available ?? 0}
        onUpgrade={() => {
          logger.debug('Upgrade modal triggered');
          setShowUpgradeModal(true);
        }}
        onUpgradeSMOnly={() => {
          logger.debug('Upgrade SM Only modal triggered');
          setShowSMOnlyModal(true);
        }}
        onActivate={handleActivate}
        onRequestSwitch={() => {
          logger.debug('Switch request triggered');
          showNotification('Please contact support to request additional course switches.', 'info');
        }}
      />
  
      <UpgradeModal 
        isOpen={showUpgradeModal} 
        onClose={() => setShowUpgradeModal(false)} 
      />
      
      <UpgradeSMOnlyModal 
        isOpen={showSMOnlyModal} 
        onClose={() => setShowSMOnlyModal(false)}
      />
      
      <SwitchConfirmationModal 
        isOpen={showSwitchModal}
        onClose={() => setShowSwitchModal(false)}
        onConfirm={async () => {
          if (!user || !selectedProduct || !currentCourse) {
            logger.debug('Switch confirmation cancelled - missing required data');
            return;
          }
  
          if (selectedProduct.id === currentCourse.id) {
            logger.debug('Prevented switch to same course');
            showNotification(
              'Cannot switch to the same course you are switching from. Please select a different course.',
              'error'
            );
            setShowSwitchModal(false);
            return;
          }
        
          try {
            setIsSwitching(true);
            setShowSwitchModal(false); // Close the confirmation modal first
            
            logger.debug('Attempting course switch');
  
            const result = await courseService.handleCourseSwitch(
              user.id,
              selectedProduct.id,
              currentCourse.id,
              true
            );
        
            if (result.success) {
              await new Promise(resolve => setTimeout(resolve, 100));
              const freshUserData = await refreshAllData(user.id);
              
              showNotification('Your course access has been updated successfully.', 'success');
  
              if (freshUserData.switches_available === 0) {
                // Keep the loading screen visible for a moment so user can see the notification
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              } else {
                setIsSwitching(false);
              }
            } else {
              console.error('Switch failed:', result.message);
              showNotification(
                result.message || 'Failed to switch courses. Please try again.',
                'error'
              );
              setIsSwitching(false);
            }
          } catch (error) {
            console.error('Error during course switch:', error);
            showNotification(
              'An error occurred while switching courses. Please try again.',
              'error'
            );
            setIsSwitching(false);
          }
        }}
        switchesAvailable={user?.switches_available ?? 0}
        fromCourse={currentCourse?.title ?? 'Current Course'}
        toCourse={selectedProduct?.title ?? 'Selected Course'}
      />
    </div>
  );
}

export default Products;