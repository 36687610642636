// File: src/components/admin/support/UserDetails.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '../../ui/Dialog';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../ui/Tabs';
import { Button } from '../../ui/Button';
import { Card } from '../../ui/Card';
import { AlertCircle, Calendar, Clock, Edit, FileQuestion, FileText, ListOrdered, Loader2, Mail, SmilePlus, UserCircle, XCircle, ExternalLink } from 'lucide-react';
import { Separator } from '../../ui/Separator';
import { Badge } from '../../ui/Badge';
import { Drawer, DrawerContent, DrawerHeader } from '../../ui/Drawer';
import { SupportUser, User, Ticket, ContentType, FeedbackValidity, COURSE_IDS } from '../../../services/api';
import { supabase } from '../../../services/supabase';

interface UserDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

interface UserDetailsData {
    supportUser: SupportUser | null;
    authUser: User | null;
    tickets: Ticket[];
    courses: {
      [courseId: string]: CourseProgress & {
        courseMix?: {
          primaryCourseName: string;
          secondaryCourseName?: string;
          alias?: string;
        };
      };
    };
  }

  interface CourseMixInfo {
    primaryCourseName: string;
    secondaryCourseName?: string;
    alias?: string;
  }
  
  // Extend CourseProgress interface
  interface ExtendedCourseProgress extends CourseProgress {
    courseMix?: CourseMixInfo;
  }

// Define the raw data structure from the database
interface RawTicketData {
    id: string;
    short_id: number;
    support_user_id: string;
    admin_id: string | null;
    status: 'new' | 'open' | 'pending' | 'closed';
    subject: string;
    body: string;
    priority: 'low' | 'medium' | 'high' | 'urgent';
    created_at: string;
    updated_at: string;
    last_activity_at: string;
    closed_at: string | null;
    tags: string[];
    content_type: ContentType | null;
    content_id: string | null;
    feedback_validity?: FeedbackValidity;
    feedback_reason?: string;
    feedback_resolved: boolean;
    has_unread?: boolean;
    attachments: Array<{
      name: string;
      url: string;
      type: string;
    }>;
    source: 'email' | 'web' | 'api';
    original_email_id: string | null;
    original_from_email: string | null;
    original_subject: string | null;
    email_thread_id: string | null;
    support_email_alias: string | null;
    is_spam: boolean;
    spam_score: number | null;
    thread_topic: string | null;
    list_id: string | null;
    support_user: SupportUser;
    messages_count: number;
  }

interface CourseProgress {
  examDate: string | null;
  customFlashcardsCount: number;
  flashcardConfidence: {
    guessing: number;
    maybe: number;
    confident: number;
    unanswered: number;
    total: number;
  };
  quizCount: number;
  studyPlanItems: {
    total: number;
    completed: number;
  };
  lessonProgress: {
    total: number;
    completed: number;
  };
  mentorProgress: {
    total: number;
    completed: number;
  };
  mcqProgress: {
    total: number;
    correct: number;
    incorrect: number;
    confident: number;
    maybe: number;
    guessing: number;
  };
  tbsProgress: {
    total: number;
    completed: number;
    averageScore: number;
    confident: number;
    maybe: number;
    guessing: number;
  };
}

const UserDetails: React.FC<UserDetailsProps> = ({ isOpen, onClose, userId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [details, setDetails] = useState<UserDetailsData | null>(null);
  const [activeCourse, setActiveCourse] = useState<string>('ALL');
  const [courseNames, setCourseNames] = useState<Record<string, string>>({});

  // Add a new useEffect to fetch course names
  useEffect(() => {
    const fetchCourseNames = async () => {
      try {
        const { data: products, error } = await supabase
          .from('products')
          .select('id, title')
          .eq('type', 'course');

        if (error) throw error;

        const nameMap = (products || []).reduce((acc, product) => ({
          ...acc,
          [product.id]: product.title
        }), {});

        setCourseNames({ ALL: 'All Courses', ...nameMap });
      } catch (err) {
        console.error('Error fetching course names:', err);
      }
    };

    fetchCourseNames();
  }, []);

  const fetchUserDetails = useCallback(async (supportUserId: string) => {
    setLoading(true);
    setError(null);
    setDetails(null);

    try {
      // 1) Get the support user record
      const { data: supportUserData, error: supportUserError } = await supabase
        .from('support_users')
        .select('*')
        .eq('id', supportUserId)
        .single();

      if (supportUserError) throw supportUserError;
      if (!supportUserData) {
        throw new Error('Support user not found.');
      }

      const supportUser: SupportUser = supportUserData;

      // 2) Fetch tickets first
      const { data: ticketsData, error: ticketsError } = await supabase
        .from('support_tickets')
        .select(`
          *,
          support_user:support_users (
            id,
            email,
            full_name,
            created_at,
            last_activity,
            auth_user_id,
            metadata,
            email_domain
          ),
          messages_count:support_messages (count)
        `)
        .eq('support_user_id', supportUserId)
        .order('created_at', { ascending: false });

      if (ticketsError) throw ticketsError;

      // 3) Get admin details for tickets where admin_id is not null
      const adminIds = (ticketsData || [])
        .map(ticket => ticket.admin_id)
        .filter((id): id is string => id !== null);

      const adminMap: Record<string, { email: string; full_name: string }> = {};
      
      if (adminIds.length > 0) {
        const { data: adminData, error: adminError } = await supabase
          .from('users')
          .select('id, email, full_name')
          .in('id', adminIds);

        if (adminError) {
          console.error('Error fetching admin data:', adminError);
        } else if (adminData) {
          adminData.forEach(admin => {
            adminMap[admin.id] = {
              email: admin.email,
              full_name: admin.full_name || 'Unknown Admin'
            };
          });
        }
      }

      // Transform the raw data to match the Ticket type
      const tickets: Ticket[] = (ticketsData as RawTicketData[]).map(rawTicket => ({
        id: rawTicket.id,
        short_id: rawTicket.short_id,
        support_user_id: rawTicket.support_user_id,
        admin_id: rawTicket.admin_id,
        status: rawTicket.status,
        subject: rawTicket.subject,
        body: rawTicket.body,
        priority: rawTicket.priority,
        created_at: rawTicket.created_at,
        updated_at: rawTicket.updated_at,
        last_activity_at: rawTicket.last_activity_at,
        closed_at: rawTicket.closed_at,
        tags: rawTicket.tags || [],
        content_type: rawTicket.content_type,
        content_id: rawTicket.content_id,
        feedback_validity: rawTicket.feedback_validity,
        feedback_reason: rawTicket.feedback_reason,
        feedback_resolved: rawTicket.feedback_resolved,
        has_unread: rawTicket.has_unread,
        attachments: rawTicket.attachments || [],
        source: rawTicket.source,
        original_email_id: rawTicket.original_email_id,
        original_from_email: rawTicket.original_from_email,
        original_subject: rawTicket.original_subject,
        email_thread_id: rawTicket.email_thread_id,
        support_email_alias: rawTicket.support_email_alias,
        is_spam: rawTicket.is_spam,
        spam_score: rawTicket.spam_score,
        thread_topic: rawTicket.thread_topic,
        list_id: rawTicket.list_id,
        user: rawTicket.support_user,
        admin: rawTicket.admin_id ? adminMap[rawTicket.admin_id] : undefined,
        _count: { messages: rawTicket.messages_count }
      }));

      // If no auth_user_id => user is not authenticated => just return partial
      if (!supportUser.auth_user_id) {
        setDetails({
          supportUser,
          authUser: null,
          tickets,
          courses: {}
        });
        setLoading(false);
        return;
      }

      const authUserId = supportUser.auth_user_id;

      // 4) Get the main user from users table
      const { data: authUserData, error: authUserError } = await supabase
        .from('users')
        .select('*')
        .eq('id', authUserId)
        .maybeSingle();

      if (authUserError) throw authUserError;
      const authUser: User | null = authUserData || null;

      // First fetch ALL mentor modules since they're course-independent
      const { data: allModulesData, error: modulesError } = await supabase
        .from('mentor_modules')
        .select('id');

      let mentorProgressData = {
        total: 0,
        completed: 0
      };

      if (modulesError) {
        console.error('Error fetching mentor_modules:', modulesError);
      } else if (allModulesData?.length) {
        // Set the total modules count
        mentorProgressData.total = allModulesData.length;

        // Get all completed modules for this user
        const moduleIds = allModulesData.map((m) => m.id);
        const { data: progressData, error: progressError } = await supabase
          .from('user_mentor_progress')
          .select('id, module_id, is_completed')
          .eq('user_id', authUserId)
          .in('module_id', moduleIds);

        if (progressError) {
          console.error('Error fetching user_mentor_progress:', progressError);
        } else if (progressData) {
          mentorProgressData.completed = progressData.filter((p) => p.is_completed).length;
        }
      }

      // Prepare placeholders for each course in COURSE_IDS
      // Filter out the MENTORSHIP course ID
      const courseIds = Object.values(COURSE_IDS).filter(
        id => id !== COURSE_IDS.MENTORSHIP
      );

      // 5) Build a parallel set of queries to gather data for each course
      const promises = courseIds.map(async (courseId) => {
        // Default structure
        const courseProgress: CourseProgress & {
          courseMix?: {
            primaryCourseName: string;
            secondaryCourseName?: string;
            alias?: string;
          };
        } = {
          examDate: null,
          customFlashcardsCount: 0,
          flashcardConfidence: {
            guessing: 0,
            maybe: 0,
            confident: 0,
            unanswered: 0,
            total: 0
          },
          quizCount: 0,
          studyPlanItems: {
            total: 0,
            completed: 0
          },
          lessonProgress: {
            total: 0,
            completed: 0
          },
          mentorProgress: mentorProgressData, // Use the same mentor progress data for all courses
          mcqProgress: {
            total: 0,
            correct: 0,
            incorrect: 0,
            confident: 0,
            maybe: 0,
            guessing: 0
          },
          tbsProgress: {
            total: 0,
            completed: 0,
            averageScore: 0,
            confident: 0,
            maybe: 0,
            guessing: 0
          }
        };

        // Fetch course mix data
        const { data: courseMixData } = await supabase
          .from('sp_course_mix')
          .select(`
            *,
            primary_course:cpa_courses!sp_course_mix_primary_cpa_course_id_fkey(name),
            secondary_course:cpa_courses!sp_course_mix_secondary_cpa_course_id_fkey(name)
          `)
          .eq('user_id', authUserId)
          .eq('course_id', courseId)
          .single();

        if (courseMixData) {
          courseProgress.courseMix = {
            primaryCourseName: courseMixData.primary_course?.name || 'Unknown',
            secondaryCourseName: courseMixData.secondary_course?.name,
            alias: courseMixData.alias
          };
        }

        // (A) sp_examdates -> get exam_date for this course
        const { data: examDateData } = await supabase
          .from('sp_examdates')
          .select('*')
          .eq('user_id', authUserId)
          .eq('course_id', courseId)
          .single();
        if (examDateData) {
          courseProgress.examDate = examDateData.exam_date || null;
        }

        // (B) custom_flashcards -> count how many for user + course
        // + also get confidence distribution
        {
          // Count all
          const { data: allCustom, error: customError } = await supabase
            .from('custom_flashcards')
            .select('confidence_level')
            .eq('user_id', authUserId)
            .eq('course_id', courseId);

          if (customError) {
            console.error('Error fetching custom flashcards:', customError);
          } else if (allCustom) {
            courseProgress.customFlashcardsCount = allCustom.length;
            courseProgress.flashcardConfidence.total = allCustom.length;

            let guess = 0;
            let mayb = 0;
            let conf = 0;
            let unans = 0;
            allCustom.forEach((c) => {
              if (c.confidence_level === 'guessing') guess++;
              else if (c.confidence_level === 'maybe') mayb++;
              else if (c.confidence_level === 'confident') conf++;
              else unans++;
            });
            courseProgress.flashcardConfidence.guessing = guess;
            courseProgress.flashcardConfidence.maybe = mayb;
            courseProgress.flashcardConfidence.confident = conf;
            courseProgress.flashcardConfidence.unanswered = unans;
          }
        }

        // (C) quiz_history -> how many "custom quizzes" (mock_exam_testlet_id is null and study_task_testlet_id is null)
        {
          const { data: quizHistoryData, error: quizError } = await supabase
            .from('quiz_history')
            .select('id, mock_exam_testlet_id, study_task_testlet_id')
            .eq('user_id', authUserId)
            .eq('course_id', courseId)
            .is('mock_exam_testlet_id', null)
            .is('study_task_testlet_id', null);

          if (quizError) {
            console.error('Error fetching quiz_history:', quizError);
          } else if (quizHistoryData) {
            courseProgress.quizCount = quizHistoryData.length;
          }
        }

        // (D) sp_studyplanitems -> how many tasks from the user's plan + completed
        {
          // first get plan
          const { data: planData } = await supabase
            .from('sp_userstudyplans')
            .select('plan_id')
            .eq('user_id', authUserId)
            .eq('course_id', courseId)
            .maybeSingle();

          if (planData) {
            const planId = planData.plan_id;

            const { data: itemsData, error: itemsError } = await supabase
              .from('sp_studyplanitems')
              .select('item_id, is_completed')
              .eq('plan_id', planId);

            if (itemsError) {
              console.error('Error fetching study plan items:', itemsError);
            } else if (itemsData) {
              courseProgress.studyPlanItems.total = itemsData.length;
              courseProgress.studyPlanItems.completed = itemsData.filter((i) => i.is_completed).length;
            }
          }
        }

        // (E) user_lesson_responses => total, completed for user+course
        {
          const { data: lessonData, error: lessonError } = await supabase
            .from('user_lesson_responses')
            .select('id, is_completed')
            .eq('user_id', authUserId)
            .eq('course_id', courseId);

          if (lessonError) {
            console.error('Error fetching user_lesson_responses:', lessonError);
          } else if (lessonData) {
            courseProgress.lessonProgress.total = lessonData.length;
            courseProgress.lessonProgress.completed = lessonData.filter((l) => l.is_completed).length;
          }
        }

        // (F) user_mentor_progress => total modules, completed modules for user+course
        {
          // We have a mentor_modules table referencing course_id, then user_mentor_progress referencing module_id
          // We can do a left join or two-step approach
          // 1) all modules for that course
          const { data: modulesData, error: modulesError } = await supabase
            .from('mentor_modules')
            .select('id')
            .eq('course_id', courseId);

          if (modulesError) {
            console.error('Error fetching mentor_modules:', modulesError);
          } else if (modulesData?.length) {
            courseProgress.mentorProgress.total = modulesData.length;

            // 2) user_mentor_progress -> completed
            const moduleIds = modulesData.map((m) => m.id);
            const { data: progressData, error: progressError } = await supabase
              .from('user_mentor_progress')
              .select('id, module_id, is_completed')
              .eq('user_id', authUserId)
              .in('module_id', moduleIds);

            if (progressError) {
              console.error('Error fetching user_mentor_progress:', progressError);
            } else if (progressData) {
              courseProgress.mentorProgress.completed = progressData.filter((p) => p.is_completed).length;
            }
          }
        }

        // (G) user_question_responses => for MCQs, gather total answered, correct, confidence distribution
        {
          const { data: qResp, error: qError } = await supabase
            .from('user_question_responses')
            .select('id, is_correct, confidence_level')
            .eq('user_id', authUserId)
            .eq('course_id', courseId);

          if (qError) {
            console.error('Error fetching user_question_responses:', qError);
          } else if (qResp) {
            courseProgress.mcqProgress.total = qResp.length;
            let correct = 0;
            let incorrect = 0;
            let conf = 0;
            let mayb = 0;
            let guess = 0;

            qResp.forEach((r) => {
              if (r.is_correct) correct++;
              else incorrect++;

              if (r.confidence_level === 'confident') conf++;
              else if (r.confidence_level === 'maybe') mayb++;
              else if (r.confidence_level === 'guessing') guess++;
            });

            courseProgress.mcqProgress.correct = correct;
            courseProgress.mcqProgress.incorrect = incorrect;
            courseProgress.mcqProgress.confident = conf;
            courseProgress.mcqProgress.maybe = mayb;
            courseProgress.mcqProgress.guessing = guess;
          }
        }

        // (H) user_tbs_responses => TBS attempts, how many completed, average score, confidence distribution
        {
          const { data: tbsData, error: tbsError } = await supabase
            .from('user_tbs_responses')
            .select('id, completed, score, confidence_level')
            .eq('user_id', authUserId)
            .eq('course_id', courseId);

          if (tbsError) {
            console.error('Error fetching user_tbs_responses:', tbsError);
          } else if (tbsData) {
            courseProgress.tbsProgress.total = tbsData.length;
            courseProgress.tbsProgress.completed = tbsData.filter((x) => !!x.completed).length;

            let totalScore = 0;
            let conf = 0;
            let mayb = 0;
            let guess = 0;
            tbsData.forEach((r) => {
              totalScore += r.score ?? 0;
              if (r.confidence_level === 'confident') conf++;
              else if (r.confidence_level === 'maybe') mayb++;
              else if (r.confidence_level === 'guessing') guess++;
            });

            if (tbsData.length > 0) {
              courseProgress.tbsProgress.averageScore = Math.round(
                (totalScore / tbsData.length) * 100
              ) / 100;
            }
            courseProgress.tbsProgress.confident = conf;
            courseProgress.tbsProgress.maybe = mayb;
            courseProgress.tbsProgress.guessing = guess;
          }
        }

        return { courseId, data: courseProgress };
      });

      const results = await Promise.all(promises);

      // build a map from courseId => data
      const coursesMap: { [key: string]: CourseProgress } = {};
      results.forEach((r) => {
        coursesMap[r.courseId] = r.data;
      });

      setDetails({
        supportUser,
        authUser,
        tickets,
        courses: coursesMap
      });
    } catch (err: any) {
      console.error('Error fetching user details:', err);
      setError(err.message || 'Failed to load user details');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen && userId) {
      fetchUserDetails(userId);
    } else if (!isOpen) {
      // Reset state so it doesn't show stale data next time
      setDetails(null);
      setError(null);
      setLoading(false);
    }
  }, [isOpen, userId, fetchUserDetails]);

  /**
   * Render a single course's data in a nice card or multiple small cards.
   */
  const renderCourseContent = (courseId: string) => {
    if (!details || !details.courses[courseId]) {
      return null;
    }
    const data = details.courses[courseId];

    return (
        <div className="space-y-3">
          {/* Add Course Mix Card at the top */}
          <Card className="p-3 md:p-4">
            <div className="flex items-center gap-2 mb-2">
              <FileText className="w-4 h-4 text-gray-500" />
              <h3 className="text-sm md:text-base font-semibold">
                Course Mix
              </h3>
            </div>
            <Separator className="my-1" />
            <div className="text-sm space-y-1">
              {data.courseMix ? (
                <>
                  <div>
                    <strong>Primary Course:</strong> {data.courseMix.primaryCourseName}
                  </div>
                  {data.courseMix.secondaryCourseName && (
                    <div>
                      <strong>Secondary Course:</strong> {data.courseMix.secondaryCourseName}
                    </div>
                  )}
                  {data.courseMix.alias && (
                    <div>
                      <strong>Alias:</strong> {data.courseMix.alias}
                    </div>
                  )}
                </>
              ) : (
                <div className="text-gray-500 italic">No course mix set</div>
              )}
            </div>
          </Card>
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              Exam Date
            </h3>
          </div>
          <Separator className="my-1" />
          {data.examDate ? (
            <div className="text-gray-800 dark:text-gray-200 text-sm">
              {new Date(data.examDate).toLocaleString()}
            </div>
          ) : (
            <div className="text-sm text-gray-500 dark:text-gray-400 italic">No exam date set</div>
          )}
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileText className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              Study Plan Items
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm flex gap-2">
            <span>
              Total: <strong>{data.studyPlanItems.total}</strong>
            </span>
            <span>
              Completed: <strong>{data.studyPlanItems.completed}</strong>
            </span>
          </div>
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <SmilePlus className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              Custom Flashcards
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-2">
            <div>
              <strong>Total Created:</strong> {data.customFlashcardsCount}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 text-xs text-gray-600 dark:text-gray-300">
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Confident</Badge> {data.flashcardConfidence.confident}
              </div>
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Maybe</Badge> {data.flashcardConfidence.maybe}
              </div>
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Guessing</Badge> {data.flashcardConfidence.guessing}
              </div>
              <div className="flex items-center gap-1">
                <Badge variant="secondary">Unanswered</Badge> {data.flashcardConfidence.unanswered}
              </div>
            </div>
          </div>
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileQuestion className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              Custom Quizzes
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm">
            <strong>Created:</strong> {data.quizCount}
          </div>
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <ListOrdered className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              Lessons Progress
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm flex gap-3">
            <span>
              Total: <strong>{data.lessonProgress.total}</strong>
            </span>
            <span>
              Completed: <strong>{data.lessonProgress.completed}</strong>
            </span>
          </div>
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <UserCircle className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              Mentor Progress
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm flex gap-3">
            <span>
              Total Modules: <strong>{data.mentorProgress.total}</strong>
            </span>
            <span>
              Completed: <strong>{data.mentorProgress.completed}</strong>
            </span>
          </div>
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <FileText className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              MCQ Progress
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            <div>Total Attempted: {data.mcqProgress.total}</div>
            <div>Correct: {data.mcqProgress.correct}</div>
            <div>Incorrect: {data.mcqProgress.incorrect}</div>
            <div>Confident: {data.mcqProgress.confident}</div>
            <div>Maybe: {data.mcqProgress.maybe}</div>
            <div>Guessing: {data.mcqProgress.guessing}</div>
          </div>
        </Card>

        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2 mb-2">
            <Edit className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">
              TBS Progress
            </h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            <div>Total Attempts: {data.tbsProgress.total}</div>
            <div>Completed: {data.tbsProgress.completed}</div>
            <div>Average Score: {data.tbsProgress.averageScore.toFixed(2)}</div>
            <div>Confident: {data.tbsProgress.confident}</div>
            <div>Maybe: {data.tbsProgress.maybe}</div>
            <div>Guessing: {data.tbsProgress.guessing}</div>
          </div>
        </Card>
      </div>
    );
  };

  /**
   * Render the global "All Courses" view if needed
   * (e.g., a quick summary across all courses).
   */
  const renderAllCoursesSummary = () => {
    if (!details || !details.courses) return null;

    const courseIds = Object.keys(details.courses);
    if (!courseIds.length) {
      return (
        <div className="p-4 text-sm text-gray-500">
          No course data found.
        </div>
      );
    }

    // Just a minimal summary: how many courses have exam dates, etc.
    const totalExamDates = courseIds.reduce((count, cid) => {
      if (details.courses[cid].examDate) return count + 1;
      return count;
    }, 0);

    return (
      <Card className="p-3 md:p-4">
        <div className="flex items-center gap-2 mb-2">
          <Calendar className="w-4 h-4 text-gray-500" />
          <h3 className="text-sm md:text-base font-semibold">
            Overview - All Courses
          </h3>
        </div>
        <Separator className="my-1" />
        <div className="text-sm text-gray-700 dark:text-gray-200">
          <div>Number of exam dates set: {totalExamDates}</div>
          <div>Courses with data: {courseIds.length}</div>
          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 italic">
            Select a course tab to see more details.
          </div>
        </div>
      </Card>
    );
  };

  const renderTicketsSection = () => {
    if (!details) return null;

    const { tickets } = details;
    if (!tickets.length) {
      return (
        <Card className="p-3 md:p-4">
          <div className="text-sm text-gray-500">No tickets found for this user.</div>
        </Card>
      );
    }

    return (
      <Card className="p-3 md:p-4 space-y-2">
        <div className="flex items-center gap-2">
          <Mail className="w-4 h-4 text-gray-500" />
          <h3 className="text-sm md:text-base font-semibold">Support Tickets</h3>
        </div>
        <Separator className="my-1" />
        <div className="max-h-64 overflow-y-auto text-sm space-y-2">
            {tickets.map((ticket) => (
                <div 
                    key={ticket.id} 
                    className="border-b last:border-none dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer transition-colors"
                    onClick={() => window.location.href = `/admin/support/ticket/${ticket.id}`}
                >
                    <div className="flex items-center justify-between gap-3 py-2 px-2">
                    <div className="flex-1 min-w-0">
                        <div className="font-medium line-clamp-1 text-gray-900 dark:text-gray-100">{ticket.subject}</div>
                        <div className="text-gray-500 dark:text-gray-400 text-xs">{ticket.status} - {ticket.priority}</div>
                    </div>
                    <Button
                        variant="secondary"
                        size="sm"
                        className="h-7 px-3 text-xs flex items-center justify-center shrink-0 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent row click when clicking button
                        window.open(`/admin/support/ticket/${ticket.id}`, '_blank');
                        }}
                    >
                        <span className="flex items-center">
                        #{ticket.short_id}
                        <ExternalLink className="w-3 h-3 ml-1.5" />
                        </span>
                    </Button>
                    </div>
                </div>
            ))}
        </div>
      </Card>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
          <p className="text-sm text-gray-500 mt-2">Loading user details...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-red-500">
          <AlertCircle className="w-6 h-6 mb-2" />
          <p>{error}</p>
        </div>
      );
    }

    if (!details || !details.supportUser) {
      return (
        <div className="p-4 text-sm text-gray-500">
          No user found.
        </div>
      );
    }

    // If user is not authenticated (no auth_user_id), show partial info
    if (!details.authUser) {
      return (
        <div className="p-4 space-y-4">
          <Card className="p-3 md:p-4">
            <div className="flex items-center gap-2">
              <UserCircle className="w-4 h-4 text-gray-500" />
              <h3 className="text-sm md:text-base font-semibold">User Info</h3>
            </div>
            <Separator className="my-1" />
            <div className="text-sm">
              <div>
                <strong>Name: </strong> {details.supportUser.full_name}
              </div>
              <div>
                <strong>Email: </strong> {details.supportUser.email}
              </div>
              <div className="mt-2 text-orange-600">
                This user is not authenticated in the main system. No additional data available.
              </div>
            </div>
          </Card>
          {renderTicketsSection()}
        </div>
      );
    }

    // Auth user is found. Render advanced info.
    const { authUser, supportUser } = details;
    const courseIds = Object.values(COURSE_IDS);

    return (
      <div className="h-full overflow-y-auto p-3 md:p-4 space-y-4">
        {/* Basic user info */}
        <Card className="p-3 md:p-4">
          <div className="flex items-center gap-2">
            <UserCircle className="w-4 h-4 text-gray-500" />
            <h3 className="text-sm md:text-base font-semibold">User Info</h3>
          </div>
          <Separator className="my-1" />
          <div className="text-sm space-y-1">
            <div>
              <strong>Name: </strong> {authUser.full_name || supportUser.full_name}
            </div>
            <div>
              <strong>Email: </strong> {authUser.email || supportUser.email}
            </div>
            <div>
              <strong>Role: </strong> {authUser.role}
            </div>
            {authUser.last_sign_in_at && (
              <div>
                <strong>Last Sign In: </strong>
                {new Date(authUser.last_sign_in_at).toLocaleString()}
              </div>
            )}
          </div>
        </Card>

        {/* Tickets */}
        {renderTicketsSection()}

        {/* Course Tabs */}
        <Tabs value={activeCourse} onValueChange={setActiveCourse}>
          <TabsList className="w-full h-10">
            <TabsTrigger value="ALL">All Courses</TabsTrigger>
            {courseIds.map((cid) => (
              <TabsTrigger key={cid} value={cid}>
                {courseNames[cid] || 'Loading...'}
              </TabsTrigger>
            ))}
          </TabsList>

          <TabsContent value="ALL" className="pt-3">
            {renderAllCoursesSummary()}
          </TabsContent>

          {courseIds.map((cid) => (
            <TabsContent key={cid} value={cid} className="pt-3">
              {renderCourseContent(cid)}
            </TabsContent>
          ))}
        </Tabs>
      </div>
    );
  };

  return (
    <Drawer open={isOpen} onOpenChange={onClose} width="md">
      <DrawerContent className="flex flex-col bg-white dark:bg-gray-900">
        <DrawerHeader className="border-b border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-semibold text-sm md:text-base text-gray-900 dark:text-gray-100">
              User Details
            </span>
          </div>
          <Button 
            variant="outline" 
            onClick={onClose} 
            className="h-8 w-8 p-0 border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            <XCircle className="h-4 w-4 text-gray-500 dark:text-gray-400" />
          </Button>
        </DrawerHeader>
        <div className="flex-1 relative overflow-hidden bg-white dark:bg-gray-900">
          {renderContent()}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default UserDetails;
