import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import DOMPurify from 'dompurify';
import { ChevronLeft, RefreshCcw, MessageCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './common/LoadingScreen';
import FeedbackPopup from './quiz-tools/FeedbackPopup';

interface KeslerCardsPreviewProps {
  flashcardId: string;
  courseId: string;
  userId: string;
  previewContent?: {
    side_1_content?: string;
    side_2_content?: string;
  };
}

interface Flashcard {
  id: string;
  side_1_content: string;
  side_2_content: string;
  flash_card_category_name: string;
  sub_flash_card_category_name: string;
  avg_confidence: number;
  guessing_count: number;
  maybe_count: number;
  confident_count: number;
}

interface UserFlashcardProgress {
  flashcard_id: string;
  confidence_level: 'guessing' | 'maybe' | 'confident' | null;
  last_reviewed: string;
}

const KeslerCardsPreview: React.FC<KeslerCardsPreviewProps> = ({ 
  flashcardId, 
  courseId, 
  userId,
  previewContent 
}) => {
  const navigate = useNavigate();
  const [flashcard, setFlashcard] = useState<Flashcard | null>(null);
  const [userProgress, setUserProgress] = useState<UserFlashcardProgress | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showingFront, setShowingFront] = useState(true);
  const [isFlipping, setIsFlipping] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  useEffect(() => {
    const fetchFlashcardData = async () => {
      try {
        // Get all flashcards for the course and filter to find the one we want
        const [flashcardsData, progressData] = await Promise.all([
          api.getFlashcards(courseId, userId),
          api.getUserFlashcardProgress(userId, courseId)
        ]);

        const currentFlashcard = flashcardsData.find(f => f.id === flashcardId);
        if (!currentFlashcard) {
          throw new Error('Flashcard not found');
        }

        // If preview content is provided, merge it with the current flashcard
        if (previewContent) {
          setFlashcard({
            ...currentFlashcard,
            side_1_content: previewContent.side_1_content || currentFlashcard.side_1_content,
            side_2_content: previewContent.side_2_content || currentFlashcard.side_2_content
          });
        } else {
          setFlashcard(currentFlashcard);
        }

        // Only set progress if not in preview mode
        if (!previewContent) {
          const currentProgress = progressData.find(p => p.flashcard_id === flashcardId) || null;
          setUserProgress(currentProgress);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error loading flashcard:', err);
        setError('Failed to load flashcard data');
        setLoading(false);
      }
    };

    fetchFlashcardData();
  }, [flashcardId, courseId, userId, previewContent]);

  const handleFlipCard = async () => {
    setIsFlipping(true);
    await new Promise(resolve => setTimeout(resolve, 150));
    setShowingFront(!showingFront);
    await new Promise(resolve => setTimeout(resolve, 150));
    setIsFlipping(false);
  };

  const handleBack = () => {
    navigate(`/course/${courseId}/kesler-cards`);
  };

  if (loading) return <LoadingScreen message="Loading flashcard..." />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!flashcard) return <div>Flashcard not found.</div>;

  const getConfidenceStats = () => {
    // Don't show confidence stats in preview mode
    if (previewContent) return null;

    const total = flashcard.guessing_count + flashcard.maybe_count + flashcard.confident_count;
    if (total === 0) return null;

    return (
      <div className="space-y-2 mt-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
        <h4 className="font-semibold text-sm">Confidence Distribution:</h4>
        <div className="flex justify-between text-xs">
          <span>Guessing: {((flashcard.guessing_count / total) * 100).toFixed(1)}%</span>
          <span>Maybe: {((flashcard.maybe_count / total) * 100).toFixed(1)}%</span>
          <span>Confident: {((flashcard.confident_count / total) * 100).toFixed(1)}%</span>
        </div>
        <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden flex">
          <div 
            className="h-full bg-red-500" 
            style={{ width: `${(flashcard.guessing_count / total) * 100}%` }} 
          />
          <div 
            className="h-full bg-yellow-500" 
            style={{ width: `${(flashcard.maybe_count / total) * 100}%` }} 
          />
          <div 
            className="h-full bg-green-500" 
            style={{ width: `${(flashcard.confident_count / total) * 100}%` }} 
          />
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {/* Navigation */}
      <div className="flex justify-between items-center">
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
        >
          <ChevronLeft size={20} />
          <span>Back to KeslerCards</span>
        </button>
      </div>

      {/* Card Header */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h1 className="text-xl font-bold text-center mb-2">
          {flashcard.flash_card_category_name}
        </h1>
        {flashcard.sub_flash_card_category_name && (
          <h2 className="text-sm text-gray-600 dark:text-gray-400 text-center">
            {flashcard.sub_flash_card_category_name}
          </h2>
        )}
        {!previewContent && userProgress && (
          <div className="mt-4 text-sm text-center">
            <span className="font-medium">Last reviewed:</span>{' '}
            {new Date(userProgress.last_reviewed).toLocaleDateString()}
            {userProgress.confidence_level && (
              <span className="ml-4">
                <span className="font-medium">Confidence:</span>{' '}
                <span className={`capitalize ${
                  userProgress.confidence_level === 'confident' ? 'text-green-600 dark:text-green-400' :
                  userProgress.confidence_level === 'maybe' ? 'text-yellow-600 dark:text-yellow-400' :
                  'text-red-600 dark:text-red-400'
                }`}>
                  {userProgress.confidence_level}
                </span>
              </span>
            )}
          </div>
        )}
      </div>

      {/* Flashcard */}
      <div className="w-full overflow-hidden">
        <div
          className={`flashcard-container w-full max-w-[95%] mx-auto h-[20rem] md:h-96 ${
            isFlipping ? 'flipping' : ''
          } relative`}
          onClick={handleFlipCard}
        >
          <div className={`flashcard ${showingFront ? 'front' : 'back'}`}>
            <div className="flashcard-side-indicator text-xs md:text-sm">
              {showingFront ? 'FRONT' : 'BACK'}
            </div>
            <div className="flashcard-content p-6 h-full text-gray-900 dark:text-gray-100 shadow-md bg-gray-50 dark:bg-gray-800 overflow-y-auto">
              <div className="flashcard-html-content pt-3 text-xs md:text-base">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      showingFront ? flashcard.side_1_content : flashcard.side_2_content
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Flip button */}
        <div className="flex justify-center mt-4">
          <button
            onClick={handleFlipCard}
            className="flex items-center gap-2 px-4 py-2 bg-primary-blue text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <RefreshCcw size={20} />
            <span>Flip Card</span>
          </button>
        </div>
      </div>

      {/* Confidence stats */}
      {getConfidenceStats()}

      {/* Feedback popup */}
      <FeedbackPopup
        isOpen={showFeedbackPopup}
        onClose={() => setShowFeedbackPopup(false)}
        contentId={flashcardId}
        contentType="flashcards"
        contentTitle={`${flashcard.flash_card_category_name} - ${flashcard.side_1_content.slice(0, 50)}`}
      />
    </div>
  );
};

export default KeslerCardsPreview;