import React, { useState } from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import { Textarea } from '../../../components/ui/Textarea';
import DOMPurify from 'dompurify';
import { useAuth } from '../../../components/AuthProvider';
import { useNotification } from '../../../contexts/NotificationContext';
import { api, ContentType } from '../../../services/api';
import { supabase } from '../../../services/supabase';
import { Alert, AlertDescription } from '../../../components/ui/Alert';
import { AlertCircle, Loader2 } from 'lucide-react';

interface FeedbackPopupProps {
  isOpen: boolean;
  onClose: () => void;
  contentId: string;
  contentType: ContentType;
  contentTitle?: string;
}

const MAX_FEEDBACK_LENGTH = 4000;

const convertToHtml = (text: string): string => {
  if (!text) return '';

  const sanitizedText = text
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const textWithLinks = sanitizedText.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600 underline">${url}</a>`;
  });

  return textWithLinks
    .split(/\n\n+/)
    .map(paragraph => 
      `<p>${
        paragraph
          .replace(/\n/g, '<br>')
          .replace(/\s{2,}/g, ' ')
      }</p>`
    )
    .join('');
};

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({ 
  isOpen, 
  onClose, 
  contentId,
  contentType,
  contentTitle
}) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getContentTypeLabel = () => {
    const labels = {
      questions: 'Question',
      lessons: 'Lesson',
      simulations: 'Simulation',
      flashcards: 'Flashcard'
    };
    return labels[contentType];
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id || !feedback.trim()) return;
  
    setLoading(true);
    setError(null);
  
    try {
      const htmlBody = convertToHtml(feedback);
      const contentLabel = getContentTypeLabel();
      const subject = `[${contentLabel}] Feedback: ${feedback.slice(0, 25)}${feedback.length > 25 ? '...' : ''}`;
  
      // Use saveFeedbackWithTicket to handle both ticket creation and feedback saving
      const result = await api.saveFeedbackWithTicket(
        contentType,
        contentId,
        htmlBody,
        user.id,
        subject,
        user.email
      );
  
      if (!result.ticketId) {
        throw new Error('Failed to create ticket and save feedback');
      }
  
      // Fetch the created ticket to get all necessary details
      const ticket = await api.getTicketById(result.ticketId);
      
      if (ticket) {
        // Send confirmation email
        await supabase.functions.invoke('sendgrid', {
          body: {
            to: user.email,
            subject: `[Ticket #${ticket.short_id}] ${subject}`,
            content: `Thank you for submitting your ${contentLabel.toLowerCase()} feedback. We have received your request and will respond shortly.
            
  Your message:
  ${htmlBody}`,
            ticketId: ticket.id,
            messageId: ticket.original_email_id?.replace(/[<>]/g, ''),
            fromName: 'Support Team',
            fromEmail: 'support@keslercpareview.com'
          }
        });
  
        showNotification?.('Feedback submitted successfully', 'success');
        setFeedback('');
        onClose();
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError('Failed to submit feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedContent = DOMPurify.sanitize(e.target.value);
    setFeedback(sanitizedContent.slice(0, MAX_FEEDBACK_LENGTH));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-4 sm:p-6 bg-white dark:bg-gray-800 max-w-[90vw] sm:max-w-lg w-full rounded-lg shadow-lg">
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white">
          {getContentTypeLabel()} Feedback
        </h2>

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
          Please describe any issues or questions you have about this {contentType.slice(0, -1).toLowerCase()}. 
          Don't worry about copying the content - our support team will have access to it.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <Textarea
            value={feedback}
            onChange={handleFeedbackChange}
            placeholder={`Describe your question or concern about this ${contentType.slice(0, -1).toLowerCase()}...`}
            className="w-full bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
            rows={4}
            aria-label="Feedback input"
            maxLength={MAX_FEEDBACK_LENGTH}
          />
          <p className="text-sm text-gray-500">
            {feedback.length}/{MAX_FEEDBACK_LENGTH} characters
          </p>
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
            <Button 
              type="button" 
              onClick={onClose} 
              variant="outline"
              disabled={loading}
              className="w-full sm:w-auto bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
            >
              Cancel
            </Button>
            <Button 
              type="submit"
              disabled={loading || !feedback.trim()}
              className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white flex items-center justify-center gap-2"
            >
              {loading ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span>Submitting...</span>
                </>
              ) : (
                <span>Submit Feedback</span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FeedbackPopup;