//// studyplanmanifesto.tsx

import React, { useState, createContext, useContext, useRef, useEffect } from 'react'
import { differenceInDays } from 'date-fns'
import { Modal } from './../../components/ui/Modal'
import { useNavigate } from 'react-router-dom';
import {
  api,
  User,
  SPStudyType,
  SPCourseMix,
  SPExamDate,
  SPStudyDays,
  SPCourse,
  SPUserStudyPlan,
  MentorModule,
  MentorModuleMetadata,
} from '../../services/api'
import {
  CheckCircle2,
  X,
  BookMarked,
  HandshakeIcon,
  GraduationCap,
  Brain,
  Clock4,
  Briefcase,
  Trophy,
  Users,
  Target,
  Award,
  Clock,
  AlertTriangle,
  Calendar,
  Coffee,
  Moon,
  Sun,
  Compass,
  RefreshCw,
  Building2,
  Zap,
  Shield,
  Mountain,
  HeartHandshake,
  Footprints,
  Scale,
  Flame,
  Sparkles,
  Medal,
  Heart,
  Radar,
  BadgeCheck,
  Map,
  Navigation,
  Rocket,
  BookOpen,
  Flag,
  Layers,
  Library,
  LucideIcon,
  MapPin,
  School,
} from 'lucide-react'

interface ModuleContent {
  description: string;
  blocks: any[];
  sales_pitch?: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////
// BEGIN: NEW CUSTOM ACCORDION COMPONENT FOR THE MANIFESTO
////////////////////////////////////////////////////////////////////////////////////////////////////

interface ManifestoAccordionContextType {
  value: string | null
  onChange: (val: string) => void
  type?: 'single' | 'multiple'
  collapsible?: boolean
}

const ManifestoAccordionContext = createContext<ManifestoAccordionContextType>({
  value: null,
  onChange: () => {},
  type: 'single',
  collapsible: true
})

interface ManifestoAccordionProps {
  value?: string | null
  onValueChange?: (val: string) => void
  type?: 'single' | 'multiple'
  collapsible?: boolean
  className?: string
  children: React.ReactNode
}

export const ManifestoAccordion: React.FC<ManifestoAccordionProps> = ({
  value: controlledValue,
  onValueChange,
  type = 'single',
  collapsible = true,
  className = '',
  children
}) => {
  const [internalValue, setInternalValue] = useState<string | null>(null)

  const currentValue = controlledValue ?? internalValue
  const onChange = onValueChange ?? setInternalValue

  return (
    <ManifestoAccordionContext.Provider value={{ value: currentValue, onChange, type, collapsible }}>
      <div className={`space-y-1 ${className}`}>{children}</div>
    </ManifestoAccordionContext.Provider>
  )
}

interface ManifestoAccordionItemContextType {
  value: string
  itemRef: React.RefObject<HTMLDivElement>
}

const ManifestoAccordionItemContext = createContext<ManifestoAccordionItemContextType>({
  value: '',
  itemRef: { current: null }
})

interface ManifestoAccordionItemProps {
  value: string
  className?: string
  children: React.ReactNode
}

export const ManifestoAccordionItem: React.FC<ManifestoAccordionItemProps> = ({
  value,
  className = '',
  children
}) => {
  const itemRef = useRef<HTMLDivElement>(null)

  return (
    <ManifestoAccordionItemContext.Provider value={{ value, itemRef }}>
      <div ref={itemRef} className={`border-b dark:border-gray-700 ${className}`}>
        {children}
      </div>
    </ManifestoAccordionItemContext.Provider>
  )
}

interface ManifestoAccordionTriggerProps {
  className?: string
  children: React.ReactNode
}

// Trigger that also scrolls into view when expanded
export const ManifestoAccordionTrigger: React.FC<ManifestoAccordionTriggerProps> = ({
  className = '',
  children
}) => {
  const { value, onChange, collapsible } = useContext(ManifestoAccordionContext)
  const { value: itemValue, itemRef } = useContext(ManifestoAccordionItemContext)
  const isExpanded = value === itemValue

  const handleClick = () => {
    // If it's already expanded and collapsible is true, collapse it
    if (collapsible && isExpanded) {
      onChange('')
    } else {
      onChange(itemValue)
    }
  }

  useEffect(() => {
    // Scroll into view if newly expanded
    if (isExpanded && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [isExpanded, itemRef])

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`
        flex w-full items-center justify-between py-4 text-sm font-medium text-left hover:underline
        ${className}
      `}
      aria-expanded={isExpanded}
    >
      {children}
      <svg
        className={`w-4 h-4 transform transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''}`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    </button>
  )
}

interface ManifestoAccordionContentProps {
  className?: string
  children: React.ReactNode
}

// Show/hide content based on expansion
export const ManifestoAccordionContent: React.FC<ManifestoAccordionContentProps> = ({
  className = '',
  children
}) => {
  const { value } = useContext(ManifestoAccordionContext)
  const { value: itemValue } = useContext(ManifestoAccordionItemContext)

  const isExpanded = value === itemValue
  if (!isExpanded) return null

  return (
    <div className={`overflow-hidden transition-all pb-4 text-sm ${className}`}>
      {children}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////////////////////////
// END: NEW CUSTOM ACCORDION COMPONENT FOR THE MANIFESTO
////////////////////////////////////////////////////////////////////////////////////////////////////


// Helper type for timeline calculations
interface StudyTimelineInfo {
  daysUntilStart: number
  hasStarted: boolean
  daysStudying: number
}

// Helper function to calculate study timeline information
const calculateStudyTimeline = (examDate: SPExamDate): StudyTimelineInfo => {
  const startDate = new Date(examDate.start_date)
  const createdDate = new Date(examDate.created_at)
  const today = new Date()

  const daysUntilStart = differenceInDays(startDate, createdDate)
  const hasStarted = startDate <= today
  const daysStudying = hasStarted ? differenceInDays(today, startDate) : 0

  return {
    daysUntilStart,
    hasStarted,
    daysStudying
  }
}

interface StudyPlanManifestoProps {
  isOpen: boolean;
  onClose: () => void;
  studyType: SPStudyType;
  courseMix: SPCourseMix;
  examDate: SPExamDate;
  studyDays: SPStudyDays;
  courses: SPCourse[];
  currentSection: string;
  user: User;
  userStudyPlan: SPUserStudyPlan;
  // Add courseId prop
  courseId: string;
}

// Reusable content block
interface ContentBlockProps {
  children: React.ReactNode
}

const ContentBlock: React.FC<ContentBlockProps> = ({ children }) => (
  <div className="mb-6 p-4 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
    {children}
  </div>
)

// Personalized shirt component
const PersonalizedCPAShirt: React.FC<{ user: User }> = ({ user }) => {
  const displayName = user.full_name || "Future CPA"
  return (
    <div className="relative w-full max-w-md mx-auto mb-6">
      <div className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
        <img 
          src="https://jvidoqtewkukebhpabuv.supabase.co/storage/v1/object/public/App-Images//manifesto.png"
          alt="CPA Exam Survivor Shirt" 
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 flex items-start justify-start">
          <p
            className="text-gray-700 text-lg font-light items-center justify-center text-center"
            style={{
              position: 'absolute',
              left: '60%',
              top: '33%',
              transform: 'rotate(6.19deg)',
              width: '35%',
              fontFamily: '"Architects Daughter", cursive',
              whiteSpace: 'wrap',
            }}
          >
            {displayName}
          </p>
        </div>
      </div>
    </div>
  )
}

// IntroBlock
interface IntroBlockProps {
  studyType: SPStudyType
  user: User
}

const IntroBlock: React.FC<IntroBlockProps> = ({ studyType, user }) => {
  const displayName = user.full_name || "Future CPA"
  const { study_type } = studyType

  const manifestoIntro = (
    <>
    <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
      <p className="mt-1 font-bold">
        From the desk of Bryan Kesler, CPA
        </p>
        <p className="mt-1 font-bold">
          Your Mentor, Cheerleader & Drinking Buddy on your journey to passing!
        </p></div>
      <p className="mt-3">
        Greetings {displayName}!
      </p>
      <p className="mt-3">
        After helping 3,000+ CPA Candidates pass the CPA Exam, I've been able to boil down the CPA exam passing process into a step-by-step system.
      </p>
      
      <p className="mt-3">
        I not only used these tactics to pass myself, but I've been able to help candidates from ages 15 to 60 create a personalized plan for success since 2015.
      </p>

      <p className="mt-3">
        What you're about to see is your personalized CPA Exam Manifesto - a complete roadmap designed specifically for where you are in your CPA journey.
      </p>

      <p className="mt-3">
        Before we dive into the technical details, let's talk about what being a CPA really means...
      </p>
    </>
  )

  const brandNewIntro = (
    <>
      <p className="text-lg font-medium mt-5">
        I've set aside your CPA survivor shirt!
      </p>

      <p className="mt-3">
        Please keep us posted when you have cleared all four sections so we can ship it your way!!**
      </p>      
      
      <div className="mt-6">
        <PersonalizedCPAShirt user={user} />
      </div>

      <p className="mt-3">
        Becoming a CPA exam survivor is only half the journey. Remember that earning your CPA license is 
        an amazing opportunity to change the world in ways you might not expect.
      </p>

      <p className="my-3">
        You won't know when, where, or how you'll have a massive impact. 
        But with your license, you'll be in the right place at the right time once you have these 
        three letters after your name.
      </p>

      <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
        <p>
          <em>Let me share a real example - my wife works for an organization that assists women in crisis 
          (trafficking, abuse, and unwanted pregnancies). While it started as a startup with limited resources,
          everything changed when they connected with a CPA who was CFO of a company with a vacant building.
          That CFO arranged a heavily discounted lease with four months rent-free, making it possible for
          the organization to help vulnerable women when they needed it most.</em>
        </p>

        <p className="mt-3">
          <em>Meaning... that you can do SO much good for the world outside of performing audits and filing taxes for clients once you obtain a CPA license.</em>
        </p>
      </div>
      <p className="my-3">
        That's what I want for you, {displayName}. To be a beacon of light in this world using the power 
        of your CPA license.
      </p>

      <p className="mt-3">
        Of course, becoming a CPA can also accelerate your career financially. Look at the founder of Nike
        - both a CPA and PwC alum! What's the fastest way to become a billionaire? Help a billion people!
      </p>

      <p className="mt-3">...and mastering the language of business is step #1 in that mission.</p>

      <p className="mt-3">
        Regardless of your personal motivation, let's dig into how we get you from Point A to CPA.
      </p>
    </>
  )

  const retakeIntro = (
    <>
      <p className="text-lg font-medium mt-5">
       I've set aside your CPA survivor shirt!
      </p>

      <p className="mt-3">
        Keep us posted when you have cleared your next section and we'll get it in the mail!!**
      </p>
      
      <div className="mt-6">
        <PersonalizedCPAShirt user={user} />
      </div>
        
      <p className="mt-3">
        You might already know this, but passing just one section proves you can do the same 
        for all four. You have what it takes—now it's about refining your approach and finishing the journey.
      </p>

      <p className="mt-3">
      Let me share a real example - my wife works for an organization that assists women in crisis 
        (trafficking, abuse, and unwanted pregnancies). While it started as a startup with limited resources,
        everything changed when they connected with a CPA who was CFO of a company with a vacant building.
        That CFO arranged a heavily discounted lease with four months rent-free, making it possible for
        the organization to help vulnerable women when they needed it most.
      </p>

      <p className="mt-3">
        Let's refocus, refine your plan, and make sure you're unstoppable on this next attempt.
      </p>
    </>
  )

  return (
    <ContentBlock>
      <div>
        {manifestoIntro}
        {study_type === 'brand_new' ? brandNewIntro : retakeIntro}

        <p className="mt-3 text-sm text-gray-600">
          ** NOTE: To receive a shirt you must be a paying member of Kesler CPA Review
        </p>
      </div>
    </ContentBlock>
  )
}

// CurrentSituationBlock
interface CurrentSituationBlockProps {
  courseMix: SPCourseMix;
  courses: SPCourse[];
  studyType: SPStudyType;
  user: User;
}

// Progress Step component
interface ProgressStepProps {
  number: number;
  title: string;
  description: string;
  icon: LucideIcon;
  isCompleted?: boolean;
}

const ProgressStep: React.FC<ProgressStepProps> = ({
  number,
  title,
  description,
  icon: Icon,
  isCompleted = false,
}) => (
  <div className="flex items-start space-x-4 p-4 bg-white dark:bg-gray-800 rounded-lg transition-all duration-200 hover:shadow-md">
    <div className={`flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center ${
      isCompleted ? 'bg-green-100 dark:bg-green-900/30' : 'bg-blue-100 dark:bg-blue-900/30'
    }`}>
      <Icon className={`w-5 h-5 ${
        isCompleted ? 'text-green-600 dark:text-green-400' : 'text-blue-600 dark:text-blue-400'
      }`} />
    </div>
    <div className="flex-1">
      <div className="flex items-center">
        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Step {number}</span>
        {isCompleted && (
          <CheckCircle2 className="w-4 h-4 ml-2 text-green-500 dark:text-green-400" />
        )}
      </div>
      <h4 className="text-lg font-semibold mt-1">{title}</h4>
      <p className="text-gray-600 dark:text-gray-300 mt-1">{description}</p>
    </div>
  </div>
);

// Course Card component
interface CourseCardProps {
  title: string;
  description: string;
  isPrimary?: boolean;
  icon: LucideIcon;
}

const CourseCard: React.FC<CourseCardProps> = ({
  title,
  description,
  isPrimary = false,
  icon: Icon,
}) => (
  <div className={`p-4 rounded-lg transition-all duration-200 hover:shadow-md ${
    isPrimary 
      ? 'bg-blue-50 dark:bg-blue-900/20 border-2 border-blue-200 dark:border-blue-800' 
      : 'bg-white dark:bg-gray-800'
  }`}>
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900/30 flex items-center justify-center">
        <Icon className="w-5 h-5 text-blue-600 dark:text-blue-400" />
      </div>
      <div className="flex-1">
        <div className="flex items-center">
          <h4 className="text-lg font-semibold">{title}</h4>
          {isPrimary && (
            <span className="ml-2 px-2 py-1 text-xs font-medium bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-full">
              Primary
            </span>
          )}
        </div>
        <p className="mt-2 text-gray-600 dark:text-gray-300">{description}</p>
      </div>
    </div>
  </div>
);

const CurrentSituationBlock: React.FC<CurrentSituationBlockProps> = ({
  courseMix,
  courses,
  studyType,
  user,
}) => {
  const { study_type } = studyType;

  const primaryCourse = courses.find(c => c.cpa_course_id === courseMix.primary_cpa_course_id);
  const secondaryCourse = courseMix.secondary_cpa_course_id
    ? courses.find(c => c.cpa_course_id === courseMix.secondary_cpa_course_id)
    : null;

  const isKeslerCourse = (courseName: string): boolean => {
    return courseName.toLowerCase().includes('kesler');
  };

  // Original intro texts
  const brandNewText = (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
      <p className="mb-4">
        You've taken the first crucial step by finalizing your study plan, and it sounds like you're in 
        the early stages of your CPA journey. This is fantastic because it means we can avoid 
        the rookie mistakes so many of us made when first starting out!
      </p>
      <p className="mt-4">
        If you still need a "big box" CPA course to go with Kesler (or to supplement Kesler's course if 
        Kesler is your primary), be sure to do your research. Don't just assume one brand is best—test 
        them out, see how they fit your learning style, and follow the strategies laid out in 
        Module 3 of Kesler Mentorship.
      </p>
      <p className="mt-4">
        At a high level, here's the process I want you to follow:
      </p>
    </div>
  );

  const retakeText = (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
      <p className="mt-4">
        It sounds like you've been down this road before, which shows serious commitment. 
        You've already learned a lot about how the CPA exam works, but we want to fine-tune your approach 
        so you can come back stronger than ever.
      </p>
      <p className="mt-4">
        If you're still using the same CPA review course you used previously, 
        don't worry—chances are it's still a good course. The key is learning the 
        right study methods (Study Loops, Study Funnels, and the other techniques 
        we teach) so that you don't repeat old mistakes.
      </p>      
      <p className="mt-4">
        At a high level, here's the process I want you to follow:
      </p>
    </div>
  );

  // Progress steps for brand new students
  const brandNewSteps = [
    {
      number: 1,
      title: 'Complete Mentorship Modules',
      description: 'Learn the proven methods for CPA exam success',
      icon: Brain,
      isCompleted: false,
    },
    {
      number: 2,
      title: 'Assess Your Situation',
      description: 'Evaluate your current work and study capabilities',
      icon: MapPin,
      isCompleted: false,
    },
    {
      number: 3,
      title: 'Mental Preparation',
      description: 'Get in the right mindset for success',
      icon: GraduationCap,
      isCompleted: false,
    },
    {
      number: 4,
      title: 'Strategic Planning',
      description: 'Plan your first section approach',
      icon: Flag,
      isCompleted: false,
    },
    {
      number: 5,
      title: 'Build Support System',
      description: 'Find accountability partners and join the community',
      icon: Users,
      isCompleted: false,
    },
  ];

  // Progress steps for retake students
  const retakeSteps = [
    {
      number: 1,
      title: 'Review Retake Strategies',
      description: 'Focus on Module 7a for specialized retake guidance',
      icon: Layers,
      isCompleted: false,
    },
    {
      number: 2,
      title: 'Strategic Resource Use',
      description: 'Learn to use your materials more effectively',
      icon: Library,
      isCompleted: false,
    },
    {
      number: 3,
      title: 'Schedule Assessment',
      description: 'Evaluate your work/study balance',
      icon: MapPin,
      isCompleted: false,
    },
    {
      number: 4,
      title: 'Community Integration',
      description: 'Connect with study group and get support',
      icon: Users,
      isCompleted: false,
    },
    {
      number: 5,
      title: 'Implementation',
      description: 'Apply proven methods from Modules 5 & 7',
      icon: BookOpen,
      isCompleted: false,
    },
  ];

  return (
    <ContentBlock>
      <div className="space-y-8">
        {/* Welcome Section */}
        <div className=" p-6 rounded-xl">
          <h3 className="text-xl font-bold mb-4">
            {study_type === 'brand_new' 
              ? 'Let\'s Hit The Ground Running!' 
              : 'Let\'s Boost Your Scores Together!'}
          </h3>
          <div className="space-y-4">
            <div className="prose dark:prose-invert max-w-none">
              {study_type === 'brand_new' ? brandNewText : retakeText}
            </div>
          </div>
        </div>

        {/* Progress Section */}
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold">Your Success Roadmap</h3>
          </div>
          <div className="grid gap-4">
            {(study_type === 'brand_new' ? brandNewSteps : retakeSteps).map((step) => (
              <ProgressStep key={step.number} {...step} />
            ))}
          </div>
        </div>

        {/* Course Selection Section */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold mb-4">Your Study Resources</h3>
          {primaryCourse && (
            <CourseCard
              title={`${primaryCourse.display_name}`}
              description={
                isKeslerCourse(primaryCourse.display_name)
                  ? "You'll be following our proven study outline with integrated study loops and funnels."
                  : `We'll help you integrate ${primaryCourse.display_name} with Kesler's study methods effectively.`
              }
              isPrimary
              icon={School}
            />
          )}
          {secondaryCourse && (
            <CourseCard
              title={courseMix.alias || secondaryCourse.display_name}
              description={
                isKeslerCourse(secondaryCourse.display_name)
                  ? "Great choice for supplemental resources! We'll show you how to enhance your primary materials."
                  : "Perfect for additional practice while following the Kesler study outline."
              }
              icon={Library}
            />
          )}
        </div>

        {/* Next Steps Card */}
        <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg">
          <h4 className="font-semibold mb-2">Ready to learn HOW to pass?</h4>
          <p className="text-gray-600 dark:text-gray-300">
            Use the button below to start with Module {study_type === 'brand_new' ? '1' : '7a'} of the Kesler CPA Mentorship program 
            to learn the specific strategies that will help you succeed.
          </p>
        </div>
      </div>
    </ContentBlock>
  );
};

interface StruggleBlockProps {
  studyType: SPStudyType;
}

const StruggleBlock: React.FC<StruggleBlockProps> = ({ studyType }) => {
  const { struggle } = studyType;
  
  // Struggle-specific advice
  let struggleAdvice: React.ReactNode = null;

  if (struggle === 'studying') {
    struggleAdvice = (
      <div className="mt-4 space-y-2">
        <h4 className="font-semibold">I noticed you're struggling with study techniques. You aren't alone, in fact this is one of the most common struggles CPA candidates have. Let's fix that!</h4>
        <p className="mb-2">
          First things first - forget everything you think you know about studying. Here's what you need to do:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Jump into Module 5A and learn about my Study Loops - they're game-changers</li>
          <li>Then hit Module 5B for Study Funnels - you need these if you want to target your weakest areas</li>
          <li>Stop watching videos passively - start DOING something with every concept</li>
          <li>Track everything - I want you measuring your progress daily</li>
        </ul>
      </div>
    );
  } else if (struggle === 'juggling') {
    struggleAdvice = (
      <div className="mt-4 space-y-2">
        <h4 className="font-semibold">I see you're trying to juggle multiple responsibilities with your CPA studies. Good news, I've helped hundreds of candidates in your exact situation. There's a way to make this work!</h4>
        <p className="mb-2">
          Here's your action plan to get everything under control:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>First stop - Module 4's time audit. This is non-negotiable!</li>
          <li>We need to find activities you can pause until after you pass</li>
          <li>Set firm boundaries around your study time - this is sacred</li>
          <li>Every minute counts - focus only on high-impact activities</li>
        </ul>
      </div>
    );
  } else if (struggle === 'motivation') {
    struggleAdvice = (
      <div className="mt-4 space-y-2">
        <h4 className="font-semibold">Sounds like you are dealing with some motivation issues? I get it - the CPA exam is a marathon disguised as a sprint, and keeping that fire burning is tough. But I've got you covered!</h4>
        <p className="mb-2">
          Here's how we're going to get your motivation back on track:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Drop everything and revisit your Big WHY in Module 1</li>
          <li>Get your accountability partner on speed dial - daily check-ins start now</li>
          <li>Set up rewards for hitting your milestones (make them good!)</li>
          <li>Start tracking every win, no matter how small - progress builds motivation</li>
        </ul>
      </div>
    );
  } else if (struggle === 'recovering from failure') {
    struggleAdvice = (
      <div className="mt-4 space-y-2">
        <h4 className="font-semibold">Let's talk about that failed attempt. I know it stings, but here's the truth - some of the best CPAs I know failed at least once. This is just part of your success story!</h4>
        <p className="mb-2">
          Here's your comeback plan:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Start with Module 7's retake strategies - they're specifically designed for this</li>
          <li>Look at your score report objectively - it's a roadmap, not a judgment</li>
          <li>Don't restart from scratch - focus on weak areas with laser precision</li>
          <li>Use what worked before, ditch what didn't - you're smarter this time</li>
        </ul>
      </div>
    );
  } else if (struggle === 'time') {
    struggleAdvice = (
      <div className="mt-4 space-y-2">
        <h4 className="font-semibold">Sounds like you share the same struggle as every CPA candidate, time management. Don't worry - this is actually easier to fix than most candidates think. I've got a system that works!</h4>
        <p className="mb-2">
          Here's your plan to master your study time:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Make Mentorship Modules 2 - 4 your best friends - this is where the magic happens</li>
          <li>Lock in that 8-12 week window per section - no exceptions!</li>
          <li>Commit to your weekly hours like they're set in stone</li>
          <li>Quality over quantity - focus on retention, not just hours logged</li>
        </ul>
      </div>
    );
  } else if (struggle === 'getting started') {
    struggleAdvice = (
      <div className="mt-4 space-y-2">
        <h4 className="font-semibold">Feeling stuck at the starting line? That's totally normal - the hardest part of any journey is taking that first step. But guess what? You've already taken it by being here!</h4>
        <p className="mb-2">
          Here's exactly what you need to do next:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Start with Module 1's Big WHY exercise - this is your foundation</li>
          <li>Move straight to Module 2-5's quick-start guide - no delays!</li>
          <li>Set tiny, achievable daily goals - we'll build from there</li>
          <li>Remember: progress beats perfection every time</li>
        </ul>
      </div>
    );
  }

  return (
    <ContentBlock>
      <div>
        {struggleAdvice}
        
        <div className="mt-6 bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <p className="font-medium mb-2">Remember:</p>
          <ul className="list-disc list-inside space-y-2">
            <li>Every CPA candidate faces struggles - you're not alone</li>
            <li>Use the specific modules mentioned above to overcome your challenge</li>
            <li>Track your progress and celebrate small wins</li>
            <li>Stay connected with our study group for extra support</li>
          </ul>
        </div>
      </div>
    </ContentBlock>
  );
};


interface RoadmapStepProps {
  number: string;
  title: string;
  icon: React.ElementType;
  items: string[];
}

const RoadmapStep: React.FC<RoadmapStepProps> = ({ number, title, icon: Icon, items }) => (
  <div className="transform transition-all duration-300 hover:scale-105">
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden border border-gray-100 dark:border-gray-700">
      <div className="p-4">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <div className="w-10 h-10 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center">
              <Icon className="w-5 h-5 text-blue-600 dark:text-blue-400" />
            </div>
          </div>
          <div>
            <span className="text-sm font-semibold text-blue-600 dark:text-blue-400">Step {number}</span>
            <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">{title}</h3>
          </div>
        </div>
        <div className="mt-4 space-y-2">
          {items.map((item, index) => (
            <div key={index} className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-blue-500 dark:bg-blue-400" />
              <p className="text-gray-600 dark:text-gray-300">{item}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

interface MentalMindsetBlockProps {
  studyType: SPStudyType;
}

const MentalMindsetBlock: React.FC<MentalMindsetBlockProps> = ({ studyType }) => {
  const { bn_mental_state, ip_mental_state, f_mental_state } = studyType;

  // Define a common set of Tailwind classes for bullet lists.
  const listClasses = "list-disc list-inside pl-5 text-base space-y-2";

  // Brand new states
  const brandNewPositive = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-emerald-500/10 to-blue-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Ready to Conquer the CPA Exam!
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You're in a great mental space right now and ready to conquer the world! This is fantastic. 
          However, it's tough to maintain this attitude for the entire journey. After helping thousands 
          of candidates pass, I can tell you that mental preparation is crucial. Plan for the worst, 
          hope for the best when sitting for the CPA exam.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Find Your Big WHY"
          icon={Compass}
          items={[
            "Without a WHY it's hard to stay focused",
            "It's important to understand what you can't accomplish without your CPA license",
            "The longer it takes to make the CPA a priority, the longer you're holding yourself back"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Embrace Failure"
          icon={Shield}
          items={[
            "Repeat after me: failing the CPA Exam is normal",
            "It's weird if you don't fail at least once statistically speaking",
            "AICPA wants to see how you react to failure"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Build Your Team"
          icon={Users}
          items={[
            "Find people to keep tabs on you",
            "You need some tough love in your life",
            "Build a support system for motivation boosts"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Reward Progress"
          icon={Medal}
          items={[
            "If studying doesn't feel fulfilling, you don't have the right motivation",
            "Tie getting studying done to something positive in your life",
            "Celebrate your wins, big and small"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Sprint to Victory"
          icon={Zap}
          items={[
            "It's four 100-meter sprints back to back",
            "Intensity is EVERYTHING - the harder you study, the sooner it's over",
            "Slow and steady isn't the way to victory - it's the path to burnout"
          ]}
        />
      </div>
  
      {/* Motivation Message */}
      <div className="bg-emerald-50 dark:bg-emerald-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-emerald-100 dark:bg-emerald-900/30 rounded-full flex items-center justify-center">
            <BadgeCheck className="w-5 h-5 text-emerald-600 dark:text-emerald-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            Your Reward Awaits
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          Keep this energy up! I'll be mailing you your CPA Survivor Shirt once you pass!
        </p>
      </div>
    </div>
  );
  
  const brandNewStruggling = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-amber-500/10 to-red-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Making the CPA Exam Your Priority
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You shared that you're struggling with making the CPA exam a priority right now. 
          This is NOT uncommon and something I can help you fix right now.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Discover Your WHY"
          icon={Radar}
          items={[
            "Without a WHY it's hard to stay focused",
            "It's important to understand what you can't accomplish without your CPA license",
            "The longer it takes to make the CPA a priority, the longer you're holding yourself back"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Face Your Fears"
          icon={Mountain}
          items={[
            "Failing the CPA Exam is normal - embrace it",
            "AICPA wants to see how you react to failure",
            "Persist and come back stronger - that's what they want to see"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Connect & Grow"
          icon={Heart}
          items={[
            "Find accountability partners who will check on you",
            "Join our study group for extra support",
            "Sometimes tough love is exactly what you need"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Build Rewards"
          icon={Trophy}
          items={[
            "Link study milestones to rewards you really want",
            "Make studying feel fulfilling by connecting it to positive outcomes",
            "Celebrate every milestone achieved"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Sprint Strategy"
          icon={Zap}
          items={[
            "Four intense sprints, not one long marathon",
            "Higher intensity = faster completion",
            "Avoid the slow burn that leads to burnout"
          ]}
        />
      </div>
  
      {/* Motivation Message */}
      <div className="bg-amber-50 dark:bg-amber-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-amber-100 dark:bg-amber-900/30 rounded-full flex items-center justify-center">
            <Award className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            Your Prize Awaits
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          Stay focused and committed - I'll be mailing you your CPA Survivor Shirt once you pass!
        </p>
      </div>
    </div>
  );
  
  const brandNewLost = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Finding Your Path Forward
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You shared that you feel lost with the CPA exam process. This is NOT uncommon 
          and something I can help you fix right now.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Start with WHY"
          icon={Map}
          items={[
            "This will be your compass when you feel lost",
            "Understanding your motivation is crucial",
            "Your WHY needs to be stronger than your excuses"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Accept the Journey"
          icon={Navigation}
          items={[
            "It's statistically normal to fail at least once",
            "Fear of failure shouldn't paralyze you",
            "Use failure as feedback, not a stop sign"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Build Support"
          icon={Users}
          items={[
            "Join our study group immediately",
            "Find accountability partners who understand your goals",
            "Don't try to figure everything out alone"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Create Joy"
          icon={Heart}
          items={[
            "Reward yourself for consistent study habits",
            "Celebrate small wins along the way",
            "Make studying something you look forward to"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Focus on Intensity"
          icon={Zap}
          items={[
            "Think of each section as a sprint",
            "High intensity study sessions are more effective",
            "Avoid the marathon approach that leads to burnout"
          ]}
        />
      </div>
  
      {/* Motivation Message */}
      <div className="bg-indigo-50 dark:bg-indigo-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center">
            <Award className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            Your Journey Begins
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          Take it one step at a time - I'll be mailing you your CPA Survivor Shirt once you pass!
        </p>
      </div>
    </div>
  );

  // Failed states with more empathy and personal experience
  const failedGood = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-emerald-500/10 to-blue-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          You've Got the Perfect Mindset!
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You mentioned you're feeling good despite having failed in the past. That's exactly 
          the mindset of a future CPA! I love that you're maintaining positivity - it's one of 
          the best predictors of success I've seen in my years of helping candidates pass.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Revisit Your WHY"
          icon={Compass}
          items={[
            "Your motivation might have evolved after experiencing the exam",
            "Use this experience to strengthen your resolve",
            "Remember what you're really fighting for"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Embrace Your Experience"
          icon={Mountain}
          items={[
            "You've already faced failure and you're still standing",
            "This resilience is exactly what makes a great CPA",
            "Use what you learned to come back stronger"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Lead By Example"
          icon={HeartHandshake}
          items={[
            "Share your comeback story with our study group",
            "Help others who might be struggling",
            "Let your positivity inspire others"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Maintain Momentum"
          icon={Flame}
          items={[
            "Set up a clear reward system for study milestones",
            "Maintain high-intensity study sessions",
            "Use your previous experience to study smarter"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Sprint to Success"
          icon={Zap}
          items={[
            "You know the drill - four sprints, not a marathon",
            "Keep that intensity high",
            "You're closer to passing than you think"
          ]}
        />
      </div>
  
      {/* Success Message */}
      <div className="bg-emerald-50 dark:bg-emerald-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-emerald-100 dark:bg-emerald-900/30 rounded-full flex items-center justify-center">
            <Award className="w-5 h-5 text-emerald-600 dark:text-emerald-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            You're So Close!
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          That CPA Survivor Shirt is waiting for you, and you're closer than ever to earning it!
        </p>
      </div>
    </div>
  );
  
  const failedFrustrated = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-orange-500/10 to-red-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          I've Been Where You Are
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          Let me tell you something - I've been exactly where you are. When I failed my first section, 
          it totally destroyed my confidence. I had never failed academically before, had a great GPA, 
          and my dad even passed on his first try. But you know what? That failure made me a better CPA 
          in the long run.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Reconnect with WHY"
          icon={Compass}
          items={[
            "This is more important than ever after a setback",
            "Your motivation needs to be stronger than your frustration",
            "Remember what you're really fighting for"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Reframe Failure"
          icon={Scale}
          items={[
            "Failing is so normal that it's almost weird not to fail",
            "AICPA wants to see how you handle setbacks",
            "This is your chance to prove you have what it takes"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Build Support"
          icon={Users}
          items={[
            "Don't isolate yourself - that's what I did and it was a huge mistake",
            "Join our study group where others understand your struggle",
            "Find accountability partners who will push you forward"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Reset Rewards"
          icon={Target}
          items={[
            "Set up immediate rewards for small study wins",
            "Celebrate progress, not just passing",
            "Make studying something you look forward to"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Attack with Intensity"
          icon={Flame}
          items={[
            "Remember: four sprints, not a marathon",
            "Channel your frustration into focused study sessions",
            "The harder you study, the sooner this is over"
          ]}
        />
      </div>
  
      {/* Motivation Message */}
      <div className="bg-orange-50 dark:bg-orange-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-orange-100 dark:bg-orange-900/30 rounded-full flex items-center justify-center">
            <Award className="w-5 h-5 text-orange-600 dark:text-orange-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            Your Comeback Story Starts Now
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          That CPA Survivor Shirt will feel even better when you earn it after overcoming this setback.
          - I know this from experience!
        </p>
      </div>
    </div>
  );
  
  const failedHopeless = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-purple-500/10 to-indigo-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Let's Find Your Way Back Together
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          I know exactly how it feels - it's like being trapped in your own head, 
          questioning everything. But here's the truth: feeling hopeless doesn't mean 
          you're hopeless. It means you're human, and we can fix this together.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Rediscover Your WHY"
          icon={Compass}
          items={[
            "This is your anchor when everything feels impossible",
            "What dreams are you putting on hold by giving up?",
            "The longer you wait, the longer you're holding yourself back"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="New Perspective"
          icon={Brain}
          items={[
            "Statistically, most CPAs fail at least once",
            "Each failure is feedback, not a final verdict",
            "The AICPA wants to see your resilience in action"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Break Isolation"
          icon={HeartHandshake}
          items={[
            "Join our study group immediately - you're not alone in this",
            "Connect with others who've bounced back from failure",
            "Let others help carry you until you find your strength again"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Small Steps Forward"
          icon={Footprints}
          items={[
            "Set tiny, achievable daily goals",
            "Reward yourself for showing up, even on hard days",
            "Build momentum one small victory at a time"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Rebuild Strong"
          icon={Sparkles}
          items={[
            "Remember: these are sprints, not a marathon",
            "High intensity study is your way out of this funk",
            "The sooner you dive back in, the sooner you'll feel in control"
          ]}
        />
      </div>
  
      {/* Personal Note */}
      <div className="bg-indigo-50 dark:bg-indigo-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center">
            <HeartHandshake className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            A Personal Note
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          I felt trapped in my own head after failing too. But you know what? That failure became 
          part of my success story. And soon, it will be part of yours. The CPA Survivor Shirt 
          I'm going to mail you once you pass will mean even more because of what you're 
          going through right now.
        </p>
      </div>
    </div>
  );

  const inProcessPositive = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-green-500/10 to-blue-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Keeping Your Momentum Strong
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You mentioned you're feeling pretty good about your CPA journey so far. That's fantastic! 
          I'm glad you're maintaining a positive attitude. Let's make sure you keep this momentum 
          going all the way to the finish line.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Stay Connected to Your WHY"
          icon={Compass}
          items={[
            "Your motivation is clearly working - keep it front and center",
            "Use it to push through the tough days ahead",
            "Let it fuel your study intensity"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Prepare for Challenges"
          icon={Shield}
          items={[
            "Even with great momentum, setbacks can happen",
            "Have a plan for handling tough days",
            "Keep your support system ready"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Leverage Your Momentum"
          icon={Rocket}
          items={[
            "Use your positive energy to tackle harder topics",
            "Help others in the study group - teaching reinforces learning",
            "Build on each small success"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Maintain Rewards"
          icon={Trophy}
          items={[
            "Keep celebrating those study milestones",
            "Stay consistent with your study schedule",
            "Let each success fuel the next one"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Keep Intensity High"
          icon={Zap}
          items={[
            "You're running sprints, not a marathon",
            "Use your positive energy for focused study sessions",
            "The harder you push now, the sooner you'll be done"
          ]}
        />
      </div>
  
      {/* Success Message */}
      <div className="bg-green-50 dark:bg-green-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center">
            <Award className="w-5 h-5 text-green-600 dark:text-green-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            You're On Track!
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          Keep this energy up, and that CPA Survivor Shirt will be yours before you know it!
        </p>
      </div>
    </div>
  );
  
  const inProcessStruggling = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-purple-500/10 to-blue-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Getting Back on Track Together
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You shared that you're struggling right now despite being in the middle of your CPA journey. 
          This is NOT uncommon—we all face dips in motivation. After helping thousands of candidates pass, 
          I can tell you that what matters most is how you handle these moments.
        </p>
      </div>
  
      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Revisit Your WHY"
          icon={Compass}
          items={[
            "Your original motivation might need refreshing",
            "Focus on what you can't accomplish without your CPA license",
            "The longer you stay stuck, the longer you're holding yourself back"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Embrace the Struggle"
          icon={Mountain}
          items={[
            "These moments separate future CPAs from quitters",
            "The AICPA wants to see how you handle adversity",
            "Use this as fuel to prove yourself"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Strengthen Support"
          icon={Users}
          items={[
            "If your accountability partner is MIA, find a new one immediately",
            "Get active in our study group - help others to help yourself",
            "You need some tough love right now to push through"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Reset Rewards"
          icon={Target}
          items={[
            "Create new, exciting rewards for hitting study targets",
            "Celebrate even smaller wins right now",
            "Make studying something you look forward to again"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Dial Up Intensity"
          icon={Zap}
          items={[
            "Remember: these are sprints, not a marathon",
            "The harder you study, the sooner this struggle ends",
            "Avoid the slow burn that leads to burnout"
          ]}
        />
      </div>
  
      {/* Encouragement Message */}
      <div className="bg-blue-50 dark:bg-blue-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center">
            <Award className="w-5 h-5 text-blue-600 dark:text-blue-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            Your Resilience Matters
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          The CPA exam is designed to test your resilience as much as your knowledge. 
          Show it what you're made of, and that CPA Survivor Shirt will be headed your way 
          before you know it!
        </p>
      </div>
    </div>
  );

  const inProcessLost = (
    <div className="space-y-8">
      {/* Hero Message */}
      <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl p-6">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Feeling Lost? Let's Get You Back on Track
        </h2>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          You mentioned feeling lost in the midst of your CPA exam studies. This happens more often 
          than you might think, especially when you're in the thick of it. The good news? We can 
          get you back on track right now.
        </p>
      </div>

      {/* Roadmap Steps */}
      <div className="grid gap-6 md:grid-cols-2">
        <RoadmapStep
          number="1"
          title="Return to Foundation"
          icon={Compass}
          items={[
            "Revisit Module 1's 'Big WHY' exercise - this is your compass",
            "Reconnect with what motivated you to start this journey",
            "Remember what's waiting for you on the other side"
          ]}
        />
        
        <RoadmapStep
          number="2"
          title="Reset Mindset"
          icon={RefreshCw}
          items={[
            "Feeling lost is temporary - your potential is permanent",
            "Focus on progress, not perfection",
            "Use this moment to prove your resilience"
          ]}
        />
        
        <RoadmapStep
          number="3"
          title="Rebuild Structure"
          icon={Building2}
          items={[
            "Get active in our study group immediately",
            "Find new accountability partners who will check on you daily",
            "Sometimes you need others to believe in you until you believe in yourself"
          ]}
        />
        
        <RoadmapStep
          number="4"
          title="Create Quick Wins"
          icon={Target}
          items={[
            "Set tiny, achievable daily goals",
            "Reward yourself for every small victory",
            "Build momentum one small step at a time"
          ]}
        />
        
        <RoadmapStep
          number="5"
          title="Return to Intensity"
          icon={Zap}
          items={[
            "These are sprints, not a marathon",
            "Intense focus beats long, unfocused hours",
            "The harder you push now, the sooner you'll find your way"
          ]}
        />
      </div>

      {/* Community Support Card */}
      <div className="bg-blue-50 dark:bg-blue-900/20 rounded-xl p-6 shadow-sm">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center">
            <Users className="w-5 h-5 text-blue-600 dark:text-blue-400" />
          </div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100">
            You're Not Alone
          </h3>
        </div>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
          Every CPA candidate feels lost at some point - it's part of the journey. But you've got
          me and our entire community behind you. Keep pushing, and that CPA Survivor Shirt will
          be yours to wear with pride!
        </p>
      </div>
    </div>
  );

  return (
    <ContentBlock>
      <div>
        {/* brand new mental state */}
        {bn_mental_state === 'Positive' && brandNewPositive}
        {bn_mental_state === 'Struggling' && brandNewStruggling}
        {bn_mental_state === 'Lost' && brandNewLost}

        {/* in process mental state */}
        {ip_mental_state === 'Positive' && inProcessPositive}
        {ip_mental_state === 'Struggling' && inProcessStruggling}
        {ip_mental_state === 'Lost' && inProcessLost}

        {/* failed mental state */}
        {f_mental_state === 'Good' && failedGood}
        {f_mental_state === 'Frustrated' && failedFrustrated}
        {f_mental_state === 'Hopeless' && failedHopeless}
      </div>
    </ContentBlock>
  );
};




interface StudyTimeBlockProps {
  studyDays: SPStudyDays
  examDate: SPExamDate
  studyType: SPStudyType
  userStudyPlan: SPUserStudyPlan
}

interface RoutineBlockProps {
  title: string;
  icon: React.ReactNode;
  routines: string[];
  tips?: string[];
  className?: string;
}

const RoutineBlock: React.FC<RoutineBlockProps> = ({ title, icon, routines, tips, className = '' }) => (
  <div className={`bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-200 dark:border-gray-700 ${className}`}>
    <div className="flex items-center mb-3">
      <div className="mr-3 text-blue-500 dark:text-blue-400">{icon}</div>
      <h4 className="text-lg font-semibold">{title}</h4>
    </div>
    <ul className="space-y-2">
      {routines.map((routine, index) => (
        <li key={index} className="flex items-start">
          <span className="mr-2 text-blue-500">•</span>
          <span>{routine}</span>
        </li>
      ))}
    </ul>
    {tips && tips.length > 0 && (
      <div className="mt-3 pt-3 border-t border-gray-200 dark:border-gray-700">
        <p className="text-sm font-semibold mb-2">Pro Tips:</p>
        <ul className="space-y-1">
          {tips.map((tip, index) => (
            <li key={index} className="text-sm text-gray-600 dark:text-gray-400 flex items-start">
              <span className="mr-2">💡</span>
              <span>{tip}</span>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

const StudyTimeBlock: React.FC<StudyTimeBlockProps> = ({ 
  studyDays, 
  examDate, 
  studyType,
  userStudyPlan 
}) => {
  const timelineInfo = calculateStudyTimeline(examDate)
  const { daysUntilStart, hasStarted, daysStudying } = timelineInfo
  const { recommended_weekly_hours } = userStudyPlan

  
  
  const getTimeContextMessage = () => {
    const startDate = new Date(examDate.start_date)
    const today = new Date()
    const isStartingToday = startDate.toDateString() === today.toDateString()

    const getTimelineText = () => {
      if (isStartingToday) {
        return "You're starting your CPA journey today, and"
      } else if (!hasStarted) {
        return `You're starting in ${daysUntilStart} days, and`
      } else if (daysStudying === 0) {
        return "You're in your first day of studying, and"
      } else {
        return `You've been studying for ${daysStudying} ${daysStudying === 1 ? 'day' : 'days'} now, and`
      }
    }

    const getHeaderText = () => {
      if (!hasStarted || isStartingToday) {
        return "Let's Talk About Your Study Schedule..."
      }
      return "Let's Optimize Your Study Schedule..."
    }

    const getIntroText = () => {
      if (!hasStarted || isStartingToday) {
        return "after helping thousands of candidates pass the CPA exam, I can tell you that time management is where most people get tripped up."
      }
      return "after helping thousands of candidates pass the CPA exam, I can tell you that the difference between passing and failing often comes down to how you structure your study time."
    }

    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-200 dark:border-gray-700 mb-8">
        <h3 className="font-semibold text-xl mb-4 text-blue-600 dark:text-blue-400">
          {getHeaderText()}
        </h3>
        <div className="space-y-4 text-gray-700 dark:text-gray-300">
          <p>
            {getTimelineText()} {getIntroText()}
          </p>
          <p>
            Here's the thing - when I was studying for the CPA exam I was working between 40-50+ hours a week. 
            I learned (the hard way) that it's not about finding more time, it's about 
            maximizing the time you have. The schedule I'm about to share with you is the exact framework 
            I used to pass all four sections while working full-time.
          </p>
          <p>
            I've customized this plan based on your {hasStarted ? 'current' : ''} situation - your work schedule, 
            your selected study days, and your target of {recommended_weekly_hours} hours per week. 
            Let's {hasStarted ? 'optimize' : 'break down'} your daily routine 
            {hasStarted ? ' to make every study hour count' : ' into a gameplan you can actually follow'}...
          </p>
        </div>
      </div>
    )
  }


  // Get selected study days
  const selectedDays = Object.entries(studyDays)
    .filter(([key, value]) => !['study_days_id', 'user_id', 'course_id'].includes(key) && value)
    .map(([day]) => day.charAt(0).toUpperCase() + day.slice(1));

  const weekdayMorningRoutines = [
    "Wake up 45 minutes earlier than usual",
    "Drink a full glass of water right after waking",
    "10-15 minutes of light exercise or meditation",
    "Quick shower to energize",
    "Focused study session before work/obligations"
  ];

  const weekdayLunchRoutines = [
    "Eat at desk while reviewing flashcards",
    "Complete 15-20 multiple choice questions",
    "Review challenging concepts from morning session",
    "Listen to CPA audio lectures during lunch walk",
    "Quick review of upcoming topics for evening study"
  ];

  const weekdayEveningRoutines = [
    "30-minute workout or physical activity",
    "Quick, healthy dinner preparation",
    "2-3 hour focused study session",
    "Review day's progress and plan tomorrow",
    "Relaxation period before bed (no screens)"
  ];

  const saturdayRoutines = [
    "Morning exercise to energize",
    "Handle essential errands/chores early",
    "Main study block (1 PM - 5:30 PM)",
    "Break for dinner/social time",
    "Evening review session (2 hours)"
  ];

  const sundayRoutines = [
    "Rest and recovery morning",
    "Light review or catch-up if needed",
    "God/family/personal time",
    "Evening prep for the week ahead",
    "2-hour light study session (if Sunday is a study day)"
  ];

  // We'll define a function to return hour-specific advice
  function getWeeklyHoursAdvice(hours: '15' | '20' | '25' | '30') {
    switch (hours) {
      case '15':
        return (
          <div className="space-y-2">
            <p>
              It looks like you plan to dedicate <strong>15 hours/week</strong> to studying.
              This is the <em>minimum recommended</em> and often necessary for those working
              <strong> 50+ hours</strong> a week. Just note that you may need more total weeks
              to cover all content. Use Module 4 to identify ways to optimize your study time.
            </p>
            <p>
              With only 15 hours, you <em>must</em> stay extremely efficient to avoid spreading
              out your study horizon too long. Otherwise, it's tough to retain everything.
              Sprints, not marathons!
            </p>
          </div>
        )
      case '20':
        return (
          <div className="space-y-2">
            <p>
              You plan to dedicate <strong>20 hours/week</strong> to studying. 
              This is our <em>recommended baseline</em>, especially if you work 
              around <strong>40-50 hours/week</strong>. 
              Try to consistently schedule these hours throughout your work week and weekends 
              to maintain momentum.
            </p>
            <p>
              With 20 hours/week, you should aim for around 8-12 weeks per section 
              (covered in Module 2) to keep your short-term memory fresh and avoid burnout.
            </p>
          </div>
        )
      case '25':
        return (
          <div className="space-y-2">
            <p>
              Your plan is <strong>25 hours/week</strong> of studying.
              This is an <em>accelerated pace</em> often used by part-time workers
              or folks who can manage a slightly lighter 40-hour job.
            </p>
            <p>
              This higher intensity means you can realistically complete each section 
              closer to 7-10 weeks if you maintain consistency. 
              But watch for signs of burnout—take breaks as needed, but still keep that 
              short-sprint approach top of mind.
            </p>
          </div>
        )
      case '30':
        return (
          <div className="space-y-2">
            <p>
              You've chosen <strong>30 hours/week</strong> of studying. 
              This is an <em>intensive load</em>, typically for those studying full-time 
              or who have minimal outside obligations. 
            </p>
            <p>
              At 30 hours/week, you can potentially tackle a section in as few as 6-8 weeks, 
              but you must monitor your energy levels diligently. 
              If you aren't careful, you can burn out quickly. 
              Still, done right, this approach can get you from zero to CPA in record time.
            </p>
          </div>
        )
      default:
        return null
    }
  }

  // Show user-specific text for the recommended weekly hours
  const hoursAdviceBlock = recommended_weekly_hours
    ? getWeeklyHoursAdvice(recommended_weekly_hours)
    : null

  // Timeline-specific advice
  const getTimelineAdvice = () => {
    const startDate = new Date(examDate.start_date)
    const today = new Date()
    const isStartingToday = startDate.toDateString() === today.toDateString()

    if (isStartingToday) {
      return (
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <p>
            Today is your first day! Make sure you've completed the Kesler CPA Mentorship modules 
            and are ready to jump in with <em>100% intensity</em>. Remember, 
            your study plan is built around maximizing these initial weeks of preparation.
          </p>
        </div>
      )
    }

    if (!hasStarted) {
      return (
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <p>
            You're set to start in <strong>{daysUntilStart}</strong> {daysUntilStart === 1 ? 'day' : 'days'}. 
            Use this prep time wisely to finalize your scheduling, gather your materials, 
            and complete the Kesler CPA Mentorship modules. When that start date comes, 
            you'll be ready to hit the ground running at <em>100% intensity</em>!
          </p>
        </div>
      )
    }

    if (daysStudying === 0) {
      return (
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <p>
            You're in your very first day of studying! Make sure you've reviewed Modules 2-5 
            to understand your blueprint coverage strategy and daily quiz approach. 
            Starting with the right habits today will set you up for success!
          </p>
        </div>
      )
    }

    return (
      <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
        <p>
          You've been studying for <strong>{daysStudying}</strong> {daysStudying === 1 ? 'day' : 'days'}. 
          If you're hitting your stride, keep that momentum going! If you feel like you need to 
          recalibrate, take a quick pass through Modules 2-5 to ensure your blueprint coverage 
          and daily quizzes are on track. Remember, small adjustments now can make a huge difference!
        </p>
      </div>
    )
  }

  const timelineAdvice = getTimelineAdvice()

  return (
    <ContentBlock>
      <div className="space-y-8">
        {/* Time Context Message */}
        {getTimeContextMessage()}
                
        {/* Selected Study Days Summary */}
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <h3 className="font-semibold text-lg mb-3 flex items-center">
            <Calendar className="w-5 h-5 mr-2" />
            Your Selected Study Days
          </h3>
          <p className="mb-2">You've chosen to study on:</p>
          <div className="flex flex-wrap gap-2">
            {selectedDays.map(day => (
              <span key={day} className="px-3 py-1 bg-blue-100 dark:bg-blue-800 rounded-full text-sm font-medium">
                {day}
              </span>
            ))}
          </div>
        </div>        

        {/* Timeline advice section */}
        {timelineAdvice}

        {/* Weekly hours advice section */}
        {hoursAdviceBlock && (
          <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
            {hoursAdviceBlock}
          </div>
        )}

        {/* Weekday Routines */}
        <div className="space-y-6">
          <h3 className="font-semibold text-xl mb-4">Weekday Study Structure</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <RoutineBlock
              title="Morning Power Start"
              icon={<Coffee className="w-5 h-5" />}
              routines={weekdayMorningRoutines}
              tips={["Place alarm across room", "Prep study materials night before"]}
            />
            <RoutineBlock
              title="Lunch Hour Optimization"
              icon={<Brain className="w-5 h-5" />}
              routines={weekdayLunchRoutines}
              tips={["Download mobile app questions", "Prep lunch the night before"]}
            />
            <RoutineBlock
              title="Evening Excellence"
              icon={<Moon className="w-5 h-5" />}
              routines={weekdayEveningRoutines}
              tips={["Set a firm cut-off time", "No screens 30 mins before bed"]}
            />
          </div>
        </div>

        {/* Weekend Routines */}
        <div className="space-y-6">
          <h3 className="font-semibold text-xl mb-4">Weekend Strategy</h3>
          <div className="grid md:grid-cols-2 gap-4">
            <RoutineBlock
              title="Saturday Power Session"
              icon={<Trophy className="w-5 h-5" />}
              routines={saturdayRoutines}
              tips={["Batch errands efficiently", "Set clear start/end times"]}
            />
            <RoutineBlock
              title="Sunday Balance"
              icon={<Sun className="w-5 h-5" />}
              routines={sundayRoutines}
              tips={["Prioritize rest in morning", "Light review only if needed"]}
            />
          </div>
        </div>
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <p className="mb-2">
            <strong>IMPORTANT:</strong> Implementing this strategy will NOT be easy. This is why it's crucial to setup a support/accountability system so that you can stay on track. 
          </p>
          <p className="mb-2">
            Dig into modules 2-5 to lock this down for your situation.
          </p>          
        </div>
      </div>
    </ContentBlock>
  );
};


// WorkBlock
interface WorkBlockProps {
  studyType: SPStudyType
}

const WorkBlock: React.FC<WorkBlockProps> = ({ studyType }) => {
  const { work_hours, kids } = studyType

  // "CHAPTER 4: The Work Conundrum" logic
  // We'll map each scenario:
  let workAdvice: React.ReactNode = null

  switch (work_hours) {
    case 'Unemployed':
      workAdvice = (
        <>
          <p className="mb-4">
            Being unemployed (or between jobs) can be stressful, but it can also be 
            a golden opportunity to dedicate extra time to studying. 
            </p>
            <p className="mb-4">
            This was exactly my situation during the Great Recession, and it let me 
            pass multiple sections quickly.
          </p>
          <p>
            Treat your day like you have two full-time jobs:
            <br />
            1) Searching for a new job.<br />
            2) Studying intensively for the CPA.<br />
            3) And if possible, add in some part-time exercise to keep your mind sharp!
          </p>
        </>
      )
      break

    case 'Student':
      workAdvice = (
        <>
          <p className="mb-4">
            Being a student is actually a great time to tackle the CPA exam if you’re eligible. 
            You likely have a more flexible schedule than someone working full time.
          </p>
          <p>
            Just remember not to get complacent. If you can sit for the exam, 
            do so as early as possible. Focus on Modules 2 & 3 to learn scheduling and 
            find the best resources to pair with Kesler. 
            Don’t let a busy semester or other commitments sabotage your progress!
          </p>
        </>
      )
      break

    case '40 Or Less Hours':
      workAdvice = (
        <>
          <p className="mb-4">
            Working part-time or up to 40 hours gives you a little more breathing room 
            to squeeze in daily study sessions. Use that extra flexibility to stay consistent.
          </p>
          <p>
            Consider turning lunch breaks into mini study sessions, 
            or end your shift each day with a quiz session. 
            The key is to treat the CPA exam as a top priority and not let 
            the available free time slip away.
          </p>
        </>
      )
      break

    case '40-50 Hours':
      workAdvice = (
        <>
          <p className="mb-4">
            You’re in a standard full-time role, which is very common among CPA candidates. 
            The big question here is how to balance overachieving at work vs. focusing on your exam.
          </p>
          <p>
            My recommendation: do your job well, but politely push back on extra responsibilities 
            that encroach on your study time. It’s okay to postpone the big promotions 
            if it means achieving your CPA license faster. That license can open far bigger doors down the road!
          </p>
        </>
      )
      break

    case 'More Than 50':
      workAdvice = (
        <>
          <p className="mb-4">
            Working more than 50 hours a week is challenging. 
            You have to be hyper-efficient and possibly advocate for fewer hours temporarily. 
            Communicate with your boss or HR if that’s an option.
          </p>
          <p>
            If it’s non-negotiable, you’ll need rock-solid time management. 
            Use every scrap of time—commutes, lunch, early mornings, late nights—and 
            keep a sharp boundary between work and study hours. 
            Remember: this intense sacrifice is temporary and ends once you pass.
          </p>
        </>
      )
      break

    default:
      workAdvice = (
        <p>
          We couldn’t detect your work schedule from your mentorship profile. 
          If you have a unique situation, please reach out to us in the study group 
          so we can provide more specific guidance.
        </p>
      )
  }

  // Additional snippet about kids if they have them
  const kidsAdvice = kids
    ? (
      <div className="mt-6 bg-blue-50 dark:bg-blue-900/20 p-3 rounded">
        <p className="mb-2 font-semibold">Kids + The CPA Exam</p>
        <p>
          Studying with kids is also challenging, so open communication is key. 
          Help them understand why you’re off studying so much. 
          Share your calendar with them and celebrate meeting study goals together. 
          When you are with your family, be fully present—no phone, no study materials. 
          This helps maintain a healthy balance and prevents burnout.
        </p>
      </div>
    )
    : null

  return (
    <ContentBlock>
      <div>
        {workAdvice}
        {kidsAdvice}
      </div>
    </ContentBlock>
  )
}

interface MilestonesBlockProps {
  studyType: SPStudyType
}

interface ScheduleRecommendationProps {
  title: string;
  weeks: string;
  hoursPerWeek: string;
  intensity: 'normal' | 'high' | 'very-high';
  notes?: string[];
}

const ScheduleRecommendation: React.FC<ScheduleRecommendationProps> = ({
  title,
  weeks,
  hoursPerWeek,
  intensity,
  notes = []
}) => {
  const intensityColors = {
    'normal': 'bg-blue-100 dark:bg-blue-900/20',
    'high': 'bg-yellow-100 dark:bg-yellow-900/20',
    'very-high': 'bg-red-100 dark:bg-red-900/20'
  };

  return (
    <div className={`p-4 rounded-lg mb-4 ${intensityColors[intensity]}`}>
      <h4 className="font-semibold text-lg mb-2">{title}</h4>
      <div className="space-y-2">
        <div className="flex items-center">
          <Calendar className="w-5 h-5 mr-2 text-gray-600 dark:text-gray-400" />
          <span>{weeks} weeks per section</span>
        </div>
        <div className="flex items-center">
          <Clock className="w-5 h-5 mr-2 text-gray-600 dark:text-gray-400" />
          <span>{hoursPerWeek} hours per week</span>
        </div>
        {notes.length > 0 && (
          <ul className="list-disc list-inside mt-2 space-y-1">
            {notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

interface MilestoneWarningProps {
  title: string;
  children: React.ReactNode;
}

const MilestoneWarning: React.FC<MilestoneWarningProps> = ({ title, children }) => (
  <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-4">
    <div className="flex items-center mb-2">
      <AlertTriangle className="w-5 h-5 mr-2 text-yellow-500" />
      <h4 className="font-semibold">{title}</h4>
    </div>
    <div className="ml-7">
      {children}
    </div>
  </div>
);

const MilestonesBlock: React.FC<MilestonesBlockProps> = ({ studyType }) => {
  const { struggle, work_hours } = studyType;

  // Intro message and core principles
  const introAndPrinciples = (
    <div className="space-y-4 mb-6">
      <p className="mb-4">
        Next up, let's talk about study scheduling and milestones. I've helped thousands of CPA candidates 
        pass this exam, and I can tell you right now - most people get this part wrong. They think more time 
        equals better results. But that's not how the CPA exam works.
      </p>
      
      <p className="mb-4">
        Based on your mentorship profile, I want to share some specific guidance about your study timeline. </p>
        <p className="mb-4">
        But first, here's what you absolutely need to understand about the CPA exam:
      </p>

      <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
        <p className="font-medium mb-2">
          The CPA exam is NOT a marathon - it's a series of 100-meter sprints! Here's why this matters:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Your short-term understanding is your secret weapon - use it!</li>
          <li>You need to study with HIGH INTENSITY for a shorter period</li>
          <li>12 weeks maximum per section - no exceptions!</li>
          <li>Save those last 10-14 days for your final review study funnels</li>
        </ul>
      </div>
    </div>
  );

  // Work-specific schedule recommendations
  let scheduleRec: React.ReactNode = null;

  if (work_hours === 'Unemployed') {
    scheduleRec = (
      <ScheduleRecommendation
        title="Let's Make the Most of This Time!"
        weeks="8-10"
        hoursPerWeek="20+ hours"
        intensity="high"
        notes={[
          "Being between jobs isn't fun, I've been there and done that too, but it's a golden opportunity for the CPA exam",
          "Treat this like your new full-time job (while job hunting)",
          "You've got the time - use it for intense, focused study sessions",
          "This is your chance to knock out sections quickly - don't waste it!"
        ]}
      />
    );
  } else if (work_hours === 'Student') {
    scheduleRec = (
      <ScheduleRecommendation
        title="Student Schedule"
        weeks="8-10"
        hoursPerWeek="20+ hours"
        intensity="high"
        notes={[
          "Treat CPA prep as a part-time job",
          "Leverage flexible student schedule",
          "Use breaks between classes for study sessions",
          "Plan around exam periods and assignments"
        ]}
      />
    );
  } else if (work_hours === '40 Or Less Hours') {
    scheduleRec = (
      <ScheduleRecommendation
        title="Part-Time Work Schedule"
        weeks="8-10"
        hoursPerWeek="20 hours"
        intensity="normal"
        notes={[
          "Use extra flexibility to maintain consistent study schedule",
          "Consider morning or evening study blocks",
          "Maximize days off for intense study sessions"
        ]}
      />
    );
  } else if (work_hours === '40-50 Hours') {
    scheduleRec = (
      <ScheduleRecommendation
        title="Full-Time Work Schedule"
        weeks="10-12"
        hoursPerWeek="20 hours"
        intensity="high"
        notes={[
          "Balance work responsibilities with study time",
          "Consider early morning or evening study sessions",
          "Use weekends for longer study blocks",
          "May need to temporarily limit extra work responsibilities"
        ]}
      />
    );
  } else if (work_hours === 'More Than 50') {
    scheduleRec = (
      <ScheduleRecommendation
        title="Heavy Workload Schedule"
        weeks="10-12"
        hoursPerWeek="15-20 hours"
        intensity="very-high"
        notes={[
          "Must be extremely efficient with limited study time",
          "Consider reducing work hours temporarily if possible",
          "Use every available moment (lunch, commute, etc.)",
          "Focus on high-yield study activities"
        ]}
      />
    );
  }

  return (
    <ContentBlock>
      <div>
        {introAndPrinciples}
        
        <MilestoneWarning title="ROOKIE MISTAKE ALERT">
          <p>
            I've seen this hundreds of times. Candidates think giving themselves MORE time 
            to study is the answer. But here's the truth - when you stretch beyond 12 weeks per section:
          </p>
          <ul className="list-disc list-inside mt-2">
            <li>Your short-term understanding advantage goes right out the window</li>
            <li>You'll burn out - trust me, I've seen it happen over and over</li>
            <li>You'll waste time re-learning stuff you've already covered</li>
            <li>Your study intensity drops, and that's a recipe for failure</li>
          </ul>
        </MilestoneWarning>

        {scheduleRec}

        <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
          <p>
            Hey, before we wrap this up - I want you to head over to Module 2 of your Kesler CPA Mentorship. 
            I've got a complete scheduling tool waiting for you there, plus way more detailed guidance 
            that's specifically built for your situation. Don't skip this - it's absolutely crucial for your success!
          </p>
        </div>
      </div>
    </ContentBlock>
  );
};

interface AccountabilityBlockProps {
  studyType: SPStudyType;
}

interface AccountabilityTipProps {
  title: string;
  children: React.ReactNode;
  icon: React.ReactNode;
}

const AccountabilityTip: React.FC<AccountabilityTipProps> = ({ title, children, icon }) => (
  <div className="mb-4 p-3 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
    <div className="flex items-center mb-2">
      <span className="mr-2 text-blue-500">{icon}</span>
      <h4 className="font-semibold text-gray-900 dark:text-gray-100">{title}</h4>
    </div>
    <div className="text-gray-700 dark:text-gray-300 ml-7">
      {children}
    </div>
  </div>
);

const AccountabilityBlock: React.FC<AccountabilityBlockProps> = ({ studyType }) => {
  const { accountability, kids } = studyType;

  // Common accountability tips for all partner types
  const commonTips = (
    <div className="mt-4 space-y-4">
      <AccountabilityTip title="Set Quantifiable Goals" icon={<Target className="w-5 h-5" />}>
        <ul className="list-disc list-inside space-y-2">
          <li>Avoid generic goals like "study more" - be specific</li>
          <li>Set targets for number of questions to complete</li>
          <li>Track specific lessons or modules to finish</li>
          <li>Define clear study hours per week</li>
        </ul>
      </AccountabilityTip>

      <AccountabilityTip title="Establish Rewards" icon={<Award className="w-5 h-5" />}>
        <ul className="list-disc list-inside space-y-2">
          <li>Create weekly rewards for hitting study targets</li>
          <li>Set bigger rewards for completing practice exams</li>
          <li>Plan a special celebration for passing each section</li>
        </ul>
      </AccountabilityTip>

      <AccountabilityTip title="Regular Check-ins" icon={<Calendar className="w-5 h-5" />}>
        <ul className="list-disc list-inside space-y-2">
          <li>Schedule weekly progress reviews</li>
          <li>Share your study calendar</li>
          <li>Update them on wins and challenges</li>
        </ul>
      </AccountabilityTip>
    </div>
  );

  // Partner-specific advice
  let accountabilityContent = null;
  
  if (accountability === 'Significant Other') {
    accountabilityContent = (
      <>
        <p className="mb-4">
          Your significant other is perfectly positioned to be your accountability champion. They see you daily 
          and can provide immediate support, but they need to understand exactly what you're going through:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Share your detailed study schedule and explain why certain times are non-negotiable</li>
          <li>Help them understand the mental and emotional challenges of CPA prep</li>
          <li>Plan specific quality time together as rewards for hitting study goals</li>
          <li>Keep them updated on your progress - celebrate wins together!</li>
        </ul>
      </>
    );
  } else if (accountability === 'Co-Worker') {
    accountabilityContent = (
      <>
        <p className="mb-4">
          Having a co-worker as your accountability partner offers unique advantages since they understand 
          the professional context of your CPA journey:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Use lunch breaks for quick progress check-ins</li>
          <li>Share your study schedule so they can help protect your study time</li>
          <li>Ask them to quiz you occasionally on relevant topics</li>
          <li>Let them know when you need extra support during crunch times</li>
        </ul>
      </>
    );
  } else if (accountability === 'Family Member') {
    accountabilityContent = (
      <>
        <p className="mb-4">
          Family members make great accountability partners because they're invested in your success, 
          but they need clear guidance on how to support you:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Explain the CPA exam process and timeline clearly</li>
          <li>Share your study calendar and weekly goals</li>
          <li>Plan family activities around your study schedule</li>
          <li>Ask them to help minimize distractions during study time</li>
        </ul>
      </>
    );
  } else if (accountability === 'Friend') {
    accountabilityContent = (
      <>
        <p className="mb-4">
          Friends can be excellent accountability partners, but you need to set clear boundaries 
          to ensure they help rather than distract:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Share your weekly study goals and ask for regular check-ins</li>
          <li>Plan social activities as rewards for hitting study targets</li>
          <li>Ask them to help you stay focused during study times</li>
          <li>Use their outside perspective to maintain balance</li>
        </ul>
      </>
    );
  } else if (accountability === 'I don\'t have anyone') {
    accountabilityContent = (
      <>
        <p className="mb-4">
          Don't let "I don't have anyone" stop you! Expanding your search will help you find the right accountability partner.
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Look for someone you interact with regularly</li>
          <li>Find a person who is reliable and supportive</li>
          <li>Choose someone who will consistently check in on your progress</li>
          <li>Pick someone who genuinely wants to see you succeed</li>
        </ul>
        <p className="mb-4">
          Use the mentorship button below to join our private study group to connect with other candidates and find an accountability partner. 
          Remember, they don't need to be in accounting - they just need to care about your success!
        </p>
      </>
    );
  }

  // Additional content for those with kids
  const kidsContent = kids && (
    <div className="mt-6 border-t pt-4">
      <AccountabilityTip title="Balancing Kids & Study" icon={<Brain className="w-5 h-5" />}>
        <ul className="list-disc list-inside space-y-2">
          <li>Share your study calendar with your children</li>
          <li>Be 100% present during family time - no phone or study materials</li>
          <li>Plan special activities as rewards for hitting study milestones</li>
          <li>Remember: family time can take priority - the CPA exam will still be there</li>
          <li>Connect with other parents in our study group for additional support</li>
        </ul>
      </AccountabilityTip>
    </div>
  );

  return (
    <ContentBlock>
      <div>
        {accountabilityContent}
        {commonTips}
        {kidsContent}
        
        <div className="mt-6 text-sm text-gray-600 dark:text-gray-400">
          <p>
            Ready to formalize your accountability system? Use the mentorship button below to head to Module 4 and join our CPA Exam Accountability Partner Program 
            to find the motivation to keep moving forward no matter what!
          </p>
        </div>
      </div>
    </ContentBlock>
  );
};

// NextStepsBlock
interface NextStepsBlockProps {
  studyType: SPStudyType
  courseMix: SPCourseMix
}

const NextStepsBlock: React.FC<NextStepsBlockProps> = ({ studyType, courseMix }) => {
  const { study_type } = studyType

  // "CHAPTER 7: Next Steps" 
  // We'll do brand_new vs retake logic
  const brandNewNextSteps = (
    <>
      <p className="mb-4">
        Now that we’ve covered your plan, the biggest key to success is implementing 
        the Kesler CPA Mentorship Method from day one. Remember, it’s not about 
        which review course is “the best,” but how you use it!
      </p>
      <p className="mb-4">
        Complete at least the first 5 Modules of Mentorship to fully grasp 
        Study Loops and Study Funnels, then check out any tutorial we have 
        on your specific CPA review course if you’re using a big box resource 
        to supplement or to pair with Kesler as the primary.
      </p>
      <p className="mb-4">
        That’s where you’ll learn how to integrate question banks, 
        videos, flashcards, or textbooks effectively without doubling your workload.
      </p>
    </>
  )

  const retakeNextSteps = (
    <>
      <p className="mb-4">
        If you’re retaking a section (or plan to retake soon), 
        don’t assume your existing course is the problem. 
        Often, it’s a matter of applying more effective study techniques.
      </p>
      <p className="mb-4">
        So, watch the Kesler Retake Strategies (Module 7a) to see how to rebuild 
        your final review period with study funnels, 
        plus how to approach old weak areas without redoing everything from scratch.
      </p>
      <p className="mb-4">
        Then, move onto the course-specific tutorials if you need a deeper dive 
        into any big box resource. The synergy between your “old” course 
        and the new Kesler approach can be the game-changer for your next attempt.
      </p>
    </>
  )

  return (
    <ContentBlock>
      <div>
        {study_type === 'brand_new' ? brandNewNextSteps : retakeNextSteps}
        <p>
          Above all, stay connected, keep your accountability partners engaged, 
          and never hesitate to reach out in our study group with questions. 
          We’re here to help you earn your Kesler Boost & pass!
        </p>
      </div>
    </ContentBlock>
  )
}

// Main Manifesto component
const StudyPlanManifesto: React.FC<StudyPlanManifestoProps> = ({
  isOpen,
  onClose,
  studyType,
  courseMix,
  examDate,
  studyDays,
  courses,
  currentSection,
  user,
  userStudyPlan,
  courseId // Add this
}) => {
  const navigate = useNavigate();
  const [moduleMetadata, setModuleMetadata] = useState<MentorModuleMetadata[]>([]);
  const [moduleContents, setModuleContents] = useState<Record<string, ModuleContent>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [expandedItem, setExpandedItem] = useState<string>("intro");

  // Fetch module metadata when component mounts
  useEffect(() => {
    const fetchModuleMetadata = async () => {
      try {
        const metadata = await api.getMentorModulesMetadata();
        setModuleMetadata(metadata);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching mentor module metadata:', err);
        setIsLoading(false);
      }
    };

    fetchModuleMetadata();
  }, []);

  // Navigation handler
  const handleNavigateToMentor = (moduleId?: string) => {
    onClose(); // Close the manifesto modal
    if (moduleId) {
      navigate(`/course/${courseId}/mentor/${moduleId}`);
    } else {
      navigate(`/course/${courseId}/mentor`);
    }
  };

  // Render next module recommendation
  const renderNextModuleRecommendation = () => {
    if (isLoading) {
      return (
        <div className="mt-8 bg-blue-50 dark:bg-gray-600 p-6 rounded-lg shadow-md">
          <p>Loading modules...</p>
        </div>
      );
    }

    const nextIncompleteModule = moduleMetadata.find(m => !m.is_completed);
    if (!nextIncompleteModule) {
      return (
        <div className="mt-8 bg-blue-50 dark:bg-gray-600 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
            Congratulations! You've completed all modules.
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Keep reviewing to reinforce your knowledge.
          </p>
          <button
            onClick={() => handleNavigateToMentor()}
            className="bg-primary-blue text-white px-4 py-2 rounded hover:bg-primary-blue-hover transition-colors duration-200"
          >
            Review All Modules
          </button>
        </div>
      );
    }

    // Construct full module for the recommendation
    const fullNextModule: MentorModule = {
      ...nextIncompleteModule,
      course_id: courseId,
      content: moduleContents[nextIncompleteModule.id] || { description: '', blocks: [] },
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };

    return (
      <div className="mt-8 bg-blue-50 dark:bg-gray-600 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
          Recommended Next Module
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          Continue your learning journey with the next uncompleted module:
        </p>
        <div className="flex flex-col space-y-4">
          <button
            onClick={() => handleNavigateToMentor(fullNextModule.id)}
            className="bg-primary-blue text-white px-4 py-2 rounded hover:bg-primary-blue-hover transition-colors duration-200"
          >
            Start {fullNextModule.title}
          </button>
          <button
            onClick={() => handleNavigateToMentor()}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors duration-200"
          >
            View All Modules
          </button>
        </div>
      </div>
    );
  };



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="w-full max-w-[800px] bg-gray-50 dark:bg-gray-900 rounded-xl shadow-xl flex flex-col max-h-[90vh]">

          {/* Header */}
          <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex-shrink-0">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                Your CPA Success Blueprint: {currentSection}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
              >
                <span className="sr-only">Close</span>
                <X className="h-6 w-6" />
              </button>
            </div>
          </div>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto px-6 py-4">

            {/* NEW ManifestoAccordion usage */}
            <ManifestoAccordion
              value={expandedItem}
              onValueChange={setExpandedItem}
              type="single"
              collapsible
              className="space-y-4"
            >
              {/* Intro Chapter */}
              <ManifestoAccordionItem value="intro">
                <ManifestoAccordionTrigger>
                  <div id="intro-trigger" className="flex items-center text-left">
                    <GraduationCap className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Getting Started: Your CPA Journey</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">What It REALLY Means To Be A CPA</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <IntroBlock studyType={studyType} user={user} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 1: Current Situation */}
              <ManifestoAccordionItem value="chapter-1">
                <ManifestoAccordionTrigger>
                  <div id="chapter-1-trigger" className="flex items-center text-left">
                    <BookMarked className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 1: Understanding Where You Are</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Your Current Situation & Kesler's Approach</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <CurrentSituationBlock 
                    studyType={studyType}
                    courseMix={courseMix}
                    courses={courses}
                    user={user}
                  />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 2: Biggest Struggle */}
              <ManifestoAccordionItem value="chapter-2">
                <ManifestoAccordionTrigger>
                  <div id="chapter-2-trigger" className="flex items-center text-left">
                    <Target className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 2: Overcoming Your Challenges</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Tackling Your Biggest CPA Exam Struggle</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <StruggleBlock studyType={studyType} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 3: Mental Mindset */}
              <ManifestoAccordionItem value="chapter-3">
                <ManifestoAccordionTrigger>
                  <div id="chapter-3-trigger" className="flex items-center text-left">
                    <Brain className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 3: The Mental Game</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Conquering The Silent Enemy... Mental Mindset</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <MentalMindsetBlock studyType={studyType} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 4: Study Time */}
              <ManifestoAccordionItem value="chapter-4">
                <ManifestoAccordionTrigger>
                  <div id="chapter-4-trigger" className="flex items-center text-left">
                    <Clock4 className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 4: Mastering Your Time</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Study Time Optimization Strategies</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <StudyTimeBlock
                    examDate={examDate}
                    studyDays={studyDays}
                    studyType={studyType}
                    userStudyPlan={userStudyPlan}
                  />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 5: Work Balance */}
              <ManifestoAccordionItem value="chapter-5">
                <ManifestoAccordionTrigger>
                  <div id="chapter-5-trigger" className="flex items-center text-left">
                    <Briefcase className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 5: Balancing Work & Study</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Solving The Work Conundrum</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <WorkBlock studyType={studyType} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 6: Milestones */}
              <ManifestoAccordionItem value="chapter-6">
                <ManifestoAccordionTrigger>
                  <div id="chapter-6-trigger" className="flex items-center text-left">
                    <Trophy className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 6: Planning Your Victory</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Study Milestones Mastery</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <MilestonesBlock studyType={studyType} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 7: Accountability */}
              <ManifestoAccordionItem value="chapter-7">
                <ManifestoAccordionTrigger>
                  <div id="chapter-7-trigger" className="flex items-center text-left">
                    <Users className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 7: Building Your Support System</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Accountability & Success Partners</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <AccountabilityBlock studyType={studyType} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>

              {/* Chapter 8: Next Steps */}
              <ManifestoAccordionItem value="chapter-8">
                <ManifestoAccordionTrigger>
                  <div id="chapter-8-trigger" className="flex items-center text-left">
                    <HandshakeIcon className="w-6 h-6 mr-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                    <div>
                      <span className="text-lg font-medium">Chapter 8: Your Path Forward</span>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Next Steps To Success</p>
                    </div>
                  </div>
                </ManifestoAccordionTrigger>
                <ManifestoAccordionContent>
                  <NextStepsBlock studyType={studyType} courseMix={courseMix} />
                </ManifestoAccordionContent>
              </ManifestoAccordionItem>
            </ManifestoAccordion>
          </div>

          {/* Footer */}
          <div className="px-6 py-4 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 rounded-b-xl flex-shrink-0">
            <div className="flex justify-between">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:text-gray-700 transition-colors duration-200"
              >
                Close
              </button>
              <button
                onClick={() => handleNavigateToMentor()}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <CheckCircle2 className="w-5 h-5" />
                <span>Go to Mentorship Modules</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StudyPlanManifesto;