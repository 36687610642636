import React, { useState, useEffect } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/Accordion';
import { Button } from '../ui/Button';
import { Switch } from '../ui/Switch';
import { Badge } from '../ui/Badge';
import { Input } from '../ui/Input';
import { Alert, AlertDescription } from '../ui/Alert';
import { 
  BookOpen, 
  Crown, 
  Loader2,
  AlertCircle,
  Lock
} from 'lucide-react';
import { useNotification } from '../../contexts/NotificationContext';
import { 
  TIER_IDS, 
  COURSE_IDS, 
  Product, 
  UserCourseAccess, 
  UserCoursePurchase,
  SubscriptionStatus 
} from '../../services/api';
import { courseService } from '../../services/courseService';
import { supabase, moderatorOperations, isStaffUser } from '../../services/supabase';
import { auth } from '../../services/auth';

interface CourseAccessControlsProps {
  userId: string;
  userEmail: string;
  currentAccess: UserCourseAccess[];
  onAccessChanged: () => void;
  defaultOpen?: boolean;
}

interface AccessMap {
  [courseId: string]: {
    tier_id: ValidTierIds;
    end_date: string | null;
    updated_at: string;
  }
}

type ValidTierIds = typeof TIER_IDS[keyof typeof TIER_IDS];

const ACTIVE_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = [
  'active',
  'active_partial_refund'
];

const CourseAccessControls: React.FC<CourseAccessControlsProps> = ({
  userId,
  userEmail,
  currentAccess,
  onAccessChanged,
  defaultOpen = false
}) => {
  const [accordionValue, setAccordionValue] = useState<string | undefined>(
    defaultOpen ? "course-access" : undefined
  );
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [switching, setSwitching] = useState(false);
  const [switchesAvailable, setSwitchesAvailable] = useState(0);
  const [addSwitchAmount, setAddSwitchAmount] = useState('1');
  const [processing, setProcessing] = useState(false);
  const [activePurchase, setActivePurchase] = useState<UserCoursePurchase | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [localAccess, setLocalAccess] = useState<UserCourseAccess[]>([]);
  const [accessMap, setAccessMap] = useState<AccessMap>({});
  const [mentorshipPurchases, setMentorshipPurchases] = useState<Record<string, UserCoursePurchase>>({});
  const [hasStaffAccess, setHasStaffAccess] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    checkStaffAccess();
  }, []);
  
  const checkStaffAccess = async () => {
    try {
      const isStaff = await isStaffUser();
      setHasStaffAccess(isStaff);
      if (!isStaff) {
        setError('Unauthorized: Only staff members can modify access');
      }
    } catch (err) {
      console.error('Error checking staff access:', err);
      setError('Error verifying staff access');
      setHasStaffAccess(false);
    }
  };
  
  useEffect(() => {
    if (hasStaffAccess) {
      loadUserSwitches();
    }
  }, [userId, hasStaffAccess]);

  const loadUserSwitches = async () => {
    try {
      if (!hasStaffAccess) return;

      // First ensure user exists in the users table
      const { data: existingUser, error: userError } = await supabase
        .from('users')
        .select('switches_available')
        .eq('id', userId)
        .maybeSingle();

      if (userError) {
        throw userError;
      }

      // If user doesn't exist, create them with default values
      if (!existingUser) {
        const { data: newUser, error: createError } = await supabase
          .from('users')
          .insert({
            id: userId,
            email: userEmail,
            switches_available: 0,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          })
          .select('switches_available')
          .single();

        if (createError) throw createError;
        setSwitchesAvailable(newUser?.switches_available || 0);
      } else {
        setSwitchesAvailable(existingUser.switches_available || 0);
      }
    } catch (error) {
      console.error('Error loading user switches:', error);
      // Don't show error to user, just set switches to 0
      setSwitchesAvailable(0);
    }
  };

  useEffect(() => {
    setLocalAccess(currentAccess || []);
  }, [currentAccess]);

  useEffect(() => {
    if (currentAccess?.length > 0) {
      setLocalAccess(currentAccess);
      
      // Update the access map
      const newAccessMap: AccessMap = {};
      currentAccess.forEach(access => {
        if (isValidTierId(access.tier_id)) {
          newAccessMap[access.course_id] = {
            tier_id: access.tier_id,
            end_date: access.end_date,
            updated_at: access.updated_at || new Date().toISOString()
          };
        }
      });
      
      setAccessMap(newAccessMap);
    }
  }, [currentAccess]);

  useEffect(() => {
    loadInitialData();
  }, [userId]); 

  const loadInitialData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Start with currentAccess prop if available
      if (currentAccess?.length > 0) {
        setLocalAccess(currentAccess);
      } else {
        // Load fresh access data if currentAccess is empty
        const { data: freshAccess, error: accessError } = await supabase
          .from('user_course_access')
          .select('*')
          .eq('user_id', userId);

        if (accessError) throw accessError;
        setLocalAccess(freshAccess || []);
      }

      await Promise.all([
        loadProducts(),
        loadActivePurchase()
      ]);
    } catch (error) {
      console.error('Error loading initial data:', error);
      
    } finally {
      setLoading(false);
    }
  };

  const loadProducts = async () => {
    try {
      const { data, error } = await supabase
        .from('products')
        .select('*')
        .in('type', ['course', 'mentorship'])
        .in('id', Object.values(COURSE_IDS));

      if (error) throw error;
      setProducts(data || []);
    } catch (error) {
      console.error('Error loading products:', error);
      throw error;
    }
  };

  const loadSwitchesAvailable = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('switches_available')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setSwitchesAvailable(data?.switches_available || 0);
    } catch (error) {
      console.error('Error loading switches:', error);
      throw error;
    }
  };

  const loadActivePurchase = async () => {
    try {
      
      // Query with tier filter but without maybeSingle()
      const { data, error } = await supabase
        .from('user_course_purchases')
        .select('*')
        .eq('user_id', userId)
        .eq('is_active', true)
        .in('subscription_status', ['active', 'active_partial_refund', 'trial'])
        .in('tier_id', [TIER_IDS.FULL_ACCESS, TIER_IDS.STUDY_MATERIALS_ONLY]);
  
      if (error) {
        console.error('Error in loadActivePurchase:', error);
        throw error;
      }
      
      // Set the first active purchase if any exist
      if (data && data.length > 0) {
        setActivePurchase(data[0]);
      } else {
        setActivePurchase(null);
      }
  
    } catch (error) {
      console.error('Error in loadActivePurchase:', error);
      setActivePurchase(null);
    }
  };

  const handleAddSwitchCredit = async () => {
    setProcessing(true);
    try {
      // Add staff check before proceeding
      const isStaff = await isStaffUser();
      if (!isStaff) {
        showNotification?.('Unauthorized: Only staff can modify switches', 'error');
        return;
      }

      // Check if user has any active purchases
      const { data: activePurchases } = await supabase
        .from('user_course_purchases')
        .select('*')
        .eq('user_id', userId)
        .eq('is_active', true)
        .in('subscription_status', ACTIVE_SUBSCRIPTION_STATUSES);

      if (!activePurchases || activePurchases.length === 0) {
        showNotification?.('Cannot add switches: No active purchases found', 'error');
        return;
      }

      const amount = parseInt(addSwitchAmount);
      const newSwitchCount = switchesAvailable + amount;

      await supabase.from('users').update({
        switches_available: newSwitchCount,
        last_switch_replenish_date: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }).eq('id', userId);

      setSwitchesAvailable(newSwitchCount);
      showNotification?.(`Added ${amount} switch credit(s)`, 'success');
      setAddSwitchAmount('1');
    } catch (error) {
      console.error('Error adding switch credits:', error);
      showNotification?.('Failed to add switch credits', 'error');
    } finally {
      setProcessing(false);
    }
  };

  const getCurrentActiveCourse = () => {
    // Find current active course (excluding non-course types)
    return Object.entries(accessMap).find(([productId, access]) => {
      const product = products.find(p => p.id === productId);
      return product?.type === 'course' && !access.end_date;
    });
  };

  // Add function to load mentorship purchases
  const loadMentorshipPurchases = async () => {
    try {
      const { data, error } = await supabase
        .from('user_course_purchases')
        .select('*')
        .eq('user_id', userId)
        .eq('tier_id', TIER_IDS.MENTOR_ONLY)
        .in('subscription_status', ACTIVE_SUBSCRIPTION_STATUSES)
        .eq('is_active', true);

      if (error) throw error;

      const purchaseMap = (data || []).reduce((acc, purchase: UserCoursePurchase) => {
        acc[purchase.thrivecart_product_id] = purchase;
        return acc;
      }, {} as Record<string, UserCoursePurchase>);

      setMentorshipPurchases(purchaseMap);
    } catch (error) {
      console.error('Error loading mentorship purchases:', error);
    }
  };

  // Add function to check if mentorship access is allowed
  const canAccessMentorship = (productId: string): boolean => {
    const purchase = mentorshipPurchases[productId];
    return (
      purchase?.is_active && 
      purchase?.subscription_status !== null &&
      ACTIVE_SUBSCRIPTION_STATUSES.includes(purchase.subscription_status)
    );
  };

  const toggleAccess = async (productId: string, enabled: boolean) => {
    const targetProduct = products.find(p => p.id === productId);
    if (!targetProduct) return;
  
    setLoading(true);
    try {
      const isStaff = await isStaffUser();
      if (!isStaff) {
        showNotification?.('Unauthorized: Only staff can modify access', 'error');
        return;
      }
  
      // Check for active purchases if trying to enable paid access
      if (enabled && targetProduct.type === 'course') {
        const { data: activePurchases } = await supabase
          .from('user_course_purchases')
          .select('*')
          .eq('user_id', userId)
          .eq('is_active', true)
          .in('subscription_status', ACTIVE_SUBSCRIPTION_STATUSES);

        if (!activePurchases || activePurchases.length === 0) {
          showNotification?.('Cannot enable paid access: No active purchases found', 'error');
          return;
        }
      }

      // Rest of your existing toggleAccess logic...
      if (targetProduct.type === 'mentorship') {
        if (enabled && !canAccessMentorship(productId)) {
          showNotification?.('No active mentorship subscription found', 'error');
          return;
        }
  
        const { data: existingAccess } = await supabase
          .from('user_course_access')
          .select('*')
          .eq('user_id', userId)
          .eq('course_id', productId)
          .single();
  
        if (enabled && !existingAccess) {
          const { data, error } = await supabase
            .from('user_course_access')
            .insert({
              user_id: userId,
              course_id: productId,
              tier_id: TIER_IDS.MENTOR_ONLY,
              start_date: new Date().toISOString(),
              end_date: null
            })
            .select()
            .single();
  
          if (error) {
            if (error.code === '23505') {
              showNotification?.('Access already exists', 'error');
            } else {
              throw error;
            }
            return;
          }
          
          setLocalAccess(prev => [...prev, data]);
          setAccessMap(prev => ({
            ...prev,
            [productId]: {
              tier_id: TIER_IDS.MENTOR_ONLY,
              end_date: null,
              updated_at: data.updated_at
            }
          }));
        } else if (!enabled && existingAccess) {
          const { error } = await supabase
            .from('user_course_access')
            .update({
              end_date: new Date().toISOString()
            })
            .eq('id', existingAccess.id);
  
          if (error) throw error;
  
          setLocalAccess(prev => prev.filter(access => access.id !== existingAccess.id));
          const { [productId]: removed, ...rest } = accessMap;
          setAccessMap(rest);
        }
  
        showNotification?.(
          enabled ? 'Mentorship access granted' : 'Mentorship access removed',
          'success'
        );
      } else {
        // Handle course type products with switching logic
        const currentActive = getCurrentActiveCourse();
        
        if (enabled) {
          if (currentActive && currentActive[0] !== productId) {
            if (switchesAvailable === 0) {
              showNotification?.('No switches available for this user.', 'error');
              return;
            }
  
            setSwitching(true);
            const result = await courseService.handleCourseSwitch(
              userId,
              productId,
              currentActive[0],
              true
            );
  
            if (!result.success) {
              showNotification?.(result.message || 'Failed to switch courses', 'error');
              return;
            }
  
            if (result.access) {
              setLocalAccess(result.access);
              setAccessMap(prev => {
                const newMap = { ...prev };
                delete newMap[currentActive[0]];
                newMap[productId] = {
                  tier_id: TIER_IDS.FULL_ACCESS,
                  end_date: null,
                  updated_at: new Date().toISOString()
                };
                return newMap;
              });
            }
  
            if (result.user) {
              setSwitchesAvailable(result.user.switches_available);
            }
  
            showNotification?.('Course access upgraded to paid successfully', 'success');
          } else {
            const { data, error: updateError } = await supabase
              .from('user_course_access')
              .update({
                tier_id: TIER_IDS.FULL_ACCESS,
                updated_at: new Date().toISOString()
              })
              .eq('user_id', userId)
              .eq('course_id', productId)
              .select()
              .single();
  
            if (updateError) throw updateError;
            
            setLocalAccess(prev => prev.map(access => 
              access.course_id === productId ? data : access
            ));
            setAccessMap(prev => ({
              ...prev,
              [productId]: {
                tier_id: TIER_IDS.FULL_ACCESS,
                end_date: null,
                updated_at: new Date().toISOString()
              }
            }));
  
            showNotification?.('Course upgraded to paid access', 'success');
          }
        } else {
          const { data, error: updateError } = await supabase
            .from('user_course_access')
            .update({
              tier_id: TIER_IDS.FREE_TRIAL,
              updated_at: new Date().toISOString()
            })
            .eq('user_id', userId)
            .eq('course_id', productId)
            .select()
            .single();
  
          if (updateError) throw updateError;
          
          setLocalAccess(prev => prev.map(access => 
            access.course_id === productId ? data : access
          ));
          setAccessMap(prev => ({
            ...prev,
            [productId]: {
              tier_id: TIER_IDS.FREE_TRIAL,
              end_date: null,
              updated_at: new Date().toISOString()
            }
          }));
  
          showNotification?.('Course set to free mode', 'success');
        }
      }
  
      onAccessChanged();
    } catch (error) {
      console.error('Error toggling access:', error);
      showNotification?.('Failed to update access', 'error');
    } finally {
      setLoading(false);
      setSwitching(false);
    }
  };

  const isValidTierId = (tierId: string): tierId is ValidTierIds => {
    return Object.values(TIER_IDS).includes(tierId as ValidTierIds);
  };

  const getCurrentAccess = (productId: string) => {
    return accessMap[productId];
  };

  const isAccessEnabled = (productId: string): boolean => {
    const access = accessMap[productId];
    // Return true only if access exists and it's NOT a free trial
    return !!access && access.tier_id !== TIER_IDS.FREE_TRIAL;
  };

  const getCourseStatus = (product: Product): string => {
    const access = getCurrentAccess(product.id);
    
    if (!access) return 'No Access';
    
    switch (access.tier_id) {
      case TIER_IDS.FULL_ACCESS:
        return 'Paid Access';
      case TIER_IDS.STUDY_MATERIALS_ONLY:
        return 'Study Materials';
      case TIER_IDS.MENTOR_ONLY:
        return product.type === 'mentorship' ? 'Mentor Access' : 'Mentor Only';
      case TIER_IDS.FREE_TRIAL:
        return 'Free Mode';
      default:
        return 'Unknown Access';
    }
  };

  const getStatusColor = (product: Product): string => {
    const access = getCurrentAccess(product.id);
    
    if (!access || access.tier_id === TIER_IDS.FREE_TRIAL) return 'text-gray-500';
    
    switch (access.tier_id) {
      case TIER_IDS.FULL_ACCESS:
        return 'text-green-600 dark:text-green-400';
      case TIER_IDS.STUDY_MATERIALS_ONLY:
        return 'text-blue-600 dark:text-blue-400';
      case TIER_IDS.MENTOR_ONLY:
        return 'text-purple-600 dark:text-purple-400';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <Accordion 
      type="single" 
      collapsible 
      className="w-full"
      value={accordionValue}
      onValueChange={setAccordionValue}
    >
      <AccordionItem value="course-access">
        <AccordionTrigger className="flex items-center hover:no-underline">
          <BookOpen className="w-4 h-4" />
          <span>Access Controls</span>
          {hasStaffAccess && switchesAvailable > 0 && (
            <Badge variant="secondary" className="ml-2">
              {switchesAvailable} switches
            </Badge>
          )}
        </AccordionTrigger>
        
        <AccordionContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {!hasStaffAccess ? (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Only administrators and moderators can modify access
              </AlertDescription>
            </Alert>
          ) : loading ? (
            <div className="flex justify-center p-4">
              <Loader2 className="h-6 w-6 animate-spin" />
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col sm:flex-row sm:items-center gap-4 p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                  <div className="flex items-center gap-3 min-w-fit">
                    <Badge 
                      variant="secondary" 
                      className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 px-3 py-1"
                    >
                      {switchesAvailable} available
                    </Badge>
                  </div>
  
                  <div className="flex items-center gap-3 w-full sm:w-auto sm:ml-auto">
                    <Input
                      type="number"
                      min="1"
                      max="2"
                      value={addSwitchAmount}
                      onChange={(e) => setAddSwitchAmount(e.target.value)}
                      className="w-12 h-9 bg-white dark:bg-gray-900"
                    />
                    <Button
                      variant="outline"
                      onClick={handleAddSwitchCredit}
                      disabled={processing}
                      className="h-9 px-4 flex items-center justify-center gap-2 bg-white dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 ml-auto sm:ml-0 text-gray-700 dark:text-gray-200"
                    >
                      {processing ? (
                        <Loader2 className="w-4 h-4 animate-spin" />
                      ) : (
                        <span>Add Credit</span>
                      )}
                    </Button>
                  </div>
                </div>
  
                {!activePurchase && (
                  <div className="flex items-center gap-2 p-4 rounded-lg bg-yellow-50 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-100 text-sm">
                    <AlertCircle className="w-4 h-4 flex-shrink-0" />
                    <span>No active purchase found</span>
                  </div>
                )}
              </div>
  
              <div className="space-y-3">
                {products.map((product) => (
                  <div 
                    key={product.id}
                    className="flex items-center justify-between p-3 border dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800"
                  >
                    <div className="flex items-center gap-3">
                      {product.type === 'course' ? (
                        <BookOpen className="w-4 h-4" />
                      ) : (
                        <Crown className="w-4 h-4" />
                      )}
                      <div>
                        <div className="font-medium dark:text-gray-100">
                          {product.title}
                          {product.type === 'mentorship' && !canAccessMentorship(product.id) && (
                            <span className="flex text-xs text-gray-500">
                              <Lock className="w-3 h-3 inline ml-1" />
                               Not Purchased
                            </span>
                          )}
                        </div>
                        <div className={`text-sm ${getStatusColor(product)}`}>
                          {getCourseStatus(product)}
                        </div>
                      </div>
                    </div>
                    
                    <Switch
                      checked={isAccessEnabled(product.id)}
                      onChange={(checked) => toggleAccess(product.id, checked)}
                      disabled={!hasStaffAccess || (product.type === 'mentorship' ? !canAccessMentorship(product.id) : loading)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default CourseAccessControls;