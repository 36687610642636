import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { getBrowserInstanceId } from '../../utils/browserInstance';
import { Button } from '../ui/Button';
import { Alert, AlertDescription } from '../ui/Alert';
import { ArrowLeft, Lock, AlertCircle, InfoIcon, CheckCircle, Loader } from 'lucide-react';
import { createLogger } from '../../utils/Logger';

const logger = createLogger({ namespace: 'ResetPassword.tsx' });

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasValidToken, setHasValidToken] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the fromTempPassword state
  const fromTempPassword = location.state?.fromTempPassword;

  const passwordRequirements = {
    minLength: 8,
    hasUppercase: /[A-Z]/,
    hasLowercase: /[a-z]/,
    hasNumber: /\d/,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/
  };

  // Check for valid access scenarios on mount
  useEffect(() => {
    logger.debug('Checking reset password access', { 
      fromTempPassword, 
      hasHash: !!location.hash 
    });

    // Parse token from URL hash if present
    const token = location.hash.split('&').find(param => 
      param.startsWith('#access_token='))?.split('=')[1];
    
    if (token) {
      logger.debug('Found reset token in URL');
      setHasValidToken(true);
    }

    // If not coming from temp password flow and no reset token, redirect to login
    if (!fromTempPassword && !token) {
      logger.debug('Invalid access attempt - redirecting to login');
      navigate('/login', { replace: true });
    }
  }, [fromTempPassword, location.hash, navigate]);

  const validatePassword = useCallback((password: string) => {
    const isValid = 
      password.length >= passwordRequirements.minLength &&
      passwordRequirements.hasUppercase.test(password) &&
      passwordRequirements.hasLowercase.test(password) &&
      passwordRequirements.hasNumber.test(password) &&
      passwordRequirements.hasSpecialChar.test(password);

    setIsPasswordValid(isValid);
  }, []);

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword, validatePassword]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setIsLoading(true);

    try {
      if (!isPasswordValid) {
        throw new Error("Password does not meet the requirements");
      }

      if (newPassword !== confirmPassword) {
        throw new Error("Passwords don't match");
      }

      setMessage('Updating password...');
      await auth.updatePassword(newPassword);
      
      setMessage('Updating user data...');
      api.clearAllCache();
      
      try {
        const browserInstanceId = await getBrowserInstanceId();
        await api.updateUserInstance(browserInstanceId);
      } catch (apiError) {
        logger.error('Error updating user instance:', apiError);
      }

      setMessage('Password updated successfully! Redirecting...');

      // Always redirect to products after setting new password
      setTimeout(() => navigate('/products', { replace: true }), 1500);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update password');
      setIsLoading(false);
    }
  };

  const getRequirementClassName = (ismet: boolean) => 
    `text-sm ${ismet ? 'text-green-600 dark:text-green-400' : 'text-gray-600 dark:text-gray-400'}`;

  // Prevent navigation while processing
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isLoading) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isLoading]);

  return (
    <div className="min-h-screen flex items-top justify-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        {/* Header Section */}
        <div className="text-center">
          <Link 
            to="/login"
            className="inline-flex items-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Link>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Reset Password
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Please enter your new password below
          </p>
        </div>

        {/* Show loading overlay when processing */}
        {isLoading && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl flex flex-col items-center space-y-4">
              <Loader className="w-8 h-8 animate-spin text-blue-500" />
              <p className="text-gray-900 dark:text-white">{message || 'Processing...'}</p>
            </div>
          </div>
        )}

        {/* Show special info message for temp password users */}
        {fromTempPassword && (
          <Alert className="bg-green-200 dark:bg-green-900/30 border-green-200 dark:border-green-800">
            <div className="flex items-center gap-3">
              <InfoIcon className="h-4 w-4 text-yellow-600 dark:text-yellow-400 flex-shrink-0" />
              <AlertDescription className="text-yellow-700 dark:text-yellow-300">
                For your security, please create your own password to continue. This password will be used for all future logins.
              </AlertDescription>
            </div>
          </Alert>
        )}

        {/* Alert Messages */}
        {(message || error) && (
          <Alert className={`${message ? 'bg-green-50 dark:bg-green-900/30 border-green-200 dark:border-green-800' : 
                                       'bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800'}`}>
            <div className="flex items-center gap-3">
              {message ? (
                <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-400 flex-shrink-0" />
              ) : (
                <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400 flex-shrink-0" />
              )}
              <AlertDescription className={`${message ? 'text-green-700 dark:text-green-300' : 
                                                       'text-red-700 dark:text-red-300'}`}>
                {message || error}
              </AlertDescription>
            </div>
          </Alert>
        )}

        {/* Form Section */}
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            {/* New Password Field */}
            <div className="space-y-2">
              <label 
                htmlFor="newPassword" 
                className="text-sm font-medium inset-y-0 left-0 flex text-gray-700 dark:text-gray-300"
              >
                <Lock className="h-5 w-5 pr-2 text-gray-400" /> New Password
              </label>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="block w-full pl-3 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Enter your new password"
              />

              {/* Password Requirements */}
              <ul className="mt-2 space-y-1">
                <li className={getRequirementClassName(newPassword.length >= passwordRequirements.minLength)}>
                  • At least {passwordRequirements.minLength} characters
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasUppercase.test(newPassword))}>
                  • At least one uppercase letter
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasLowercase.test(newPassword))}>
                  • At least one lowercase letter
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasNumber.test(newPassword))}>
                  • At least one number
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasSpecialChar.test(newPassword))}>
                  • At least one special character (!@#$%^&*(),.?":{}|&lt;&gt;)
                </li>
              </ul>
            </div>

            {/* Confirm Password Field */}
            <div className="space-y-2">
              <label 
                htmlFor="confirmPassword" 
                className="text-sm font-medium inset-y-0 left-0 flex text-gray-700 dark:text-gray-300"
              >
                <Lock className="h-5 w-5 pr-2 text-gray-400" /> Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="block w-full pl-3 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Confirm your new password"
              />
            </div>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            disabled={isLoading || !isPasswordValid || (!fromTempPassword && !hasValidToken)}
            className="w-full flex justify-center py-3 px-4 border border-transparent 
                     rounded-lg shadow-sm text-sm font-medium text-white
                     bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition duration-150 ease-in-out"
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                Updating Password...
              </div>
            ) : (
              'Reset Password'
            )}
          </Button>

          {/* Links Section */}
          {!fromTempPassword && (
            <div className="text-center">
              <Link 
                to="/login"
                className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
              >
                Remember your password? Sign in
              </Link>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;