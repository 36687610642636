import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import { parseQuestionContent } from '../../utils/questionUtils';
import DOMPurify from 'dompurify';

interface QuestionPreviewProps {
  questionId: string;
  courseId: string;
  userId: string;
  previewContent?: {
    question_content?: string;
    answer_content?: string;
    correct_answer?: string;
  };
}

interface Question {
  id: string;
  question_content: string;
  answer_content: string;
  correct_answer: string;
}

interface QuizHistory {
  question_data: {
    userAnswer: string;
    confidenceLevel: string;
    isCorrect: boolean;
  };
}

const QuestionPreview: React.FC<QuestionPreviewProps> = ({ 
  questionId, 
  courseId, 
  userId,
  previewContent 
}) => {
  const [question, setQuestion] = useState<Question | null>(null);
  const [quizHistory, setQuizHistory] = useState<QuizHistory | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        if (previewContent) {
          // If preview content is provided, use it instead of fetching
          setQuestion({
            id: questionId,
            question_content: previewContent.question_content || '',
            answer_content: previewContent.answer_content || '',
            correct_answer: previewContent.correct_answer || ''
          });
          setLoading(false);
          return;
        }

        const [questionData, historyData] = await Promise.all([
          api.getQuestionById(questionId),
          api.getQuizHistoryForQuestion(userId, courseId, questionId)
        ]);
        setQuestion(questionData);
        setQuizHistory(historyData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch question data');
        setLoading(false);
      }
    };

    fetchQuestionData();
  }, [questionId, courseId, userId, previewContent]);

  // Rest of the component implementation remains the same
  const renderHTML = (html: string) => ({ __html: DOMPurify.sanitize(html) });

  const getOptionClassName = (optionLetter: string) => {
    let className = "p-3 rounded-md border ";
    if (quizHistory && quizHistory.question_data.userAnswer === optionLetter) {
      className += "selected ";
    }
    if (optionLetter === question?.correct_answer) {
      className += "border-green-500 bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200 ";
    } else if (quizHistory && quizHistory.question_data.userAnswer === optionLetter) {
      className += "border-red-500 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 ";
    } else {
      className += "border-gray-300 dark:border-gray-600 ";
    }
    return className;
  };

  if (loading) return <div>Loading question...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!question) return <div>Question not found.</div>;

  const { questionText, tableHTML, options, romanList, isTableQuestion } = parseQuestionContent(question.question_content);

  // Extract only the first paragraph from questionText
  const parser = new DOMParser();
  const doc = parser.parseFromString(questionText, 'text/html');
  const firstParagraph = doc.querySelector('p');
  const cleanedQuestionText = firstParagraph ? firstParagraph.outerHTML : questionText;

  return (
    <div className="space-y-4">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <div 
          className="quiz-content mb-4 text-gray-800 dark:text-gray-200" 
          dangerouslySetInnerHTML={renderHTML(cleanedQuestionText)} 
        />
        
        {romanList && (
          <div 
            className="quiz-content mb-4 text-gray-800 dark:text-gray-200
                       [&_ol]:list-none [&_ol]:pl-0 [&_ol]:counter-reset-[roman]
                       [&_li]:relative [&_li]:pl-8 [&_li]:mb-2
                       [&_li]:before:absolute [&_li]:before:left-0 [&_li]:before:content-[counter(roman,upper-roman)]
                       [&_li]:before:font-bold [&_li]:before:text-gray-700 [&_li]:dark:before:text-gray-300
                       [&_li]:counter-increment-[roman]" 
            dangerouslySetInnerHTML={renderHTML(romanList)} 
          />
        )}

        {isTableQuestion ? (
          <div className="quiz-content overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border-b border-gray-200 dark:border-gray-700 shadow sm:rounded-lg">
                <div 
                  className="w-full table-auto
                            [&_table]:w-full [&_table]:border-collapse
                            [&_th]:border [&_th]:border-gray-200 [&_th]:dark:border-gray-700 [&_th]:p-3 [&_th]:text-center [&_th]:bg-gray-50 [&_th]:dark:bg-gray-700 [&_th]:font-semibold [&_th]:text-gray-800 [&_th]:dark:text-gray-200
                            [&_td]:border [&_td]:border-gray-200 [&_td]:dark:border-gray-700 [&_td]:p-3 [&_td]:text-center [&_td]:text-gray-800 [&_td]:dark:text-gray-200"
                  dangerouslySetInnerHTML={renderHTML(tableHTML)} 
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-2">
            {options.map((option: string, index: number) => {
              const optionLetter = String.fromCharCode(65 + index);
              return (
                <div
                  key={index}
                  className={getOptionClassName(optionLetter)}
                >
                  <span className="font-bold mr-2">{optionLetter}.</span>
                  <span dangerouslySetInnerHTML={renderHTML(option)} />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <h3 className="font-bold mb-2 text-gray-900 dark:text-gray-100">Explanation:</h3>
        <div 
          className="quiz-content text-gray-800 dark:text-gray-200" 
          dangerouslySetInnerHTML={renderHTML(question.answer_content)} 
        />
      </div>
    </div>
  );
};

export default QuestionPreview;