import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../services/auth';
import { api } from '../services/api';
import { User } from '../services/api';
import DOMPurify from 'dompurify';
import { useAuth } from '../components/AuthProvider';
import { Button } from '../components/ui/Button';
import { Alert, AlertDescription } from '../components/ui/Alert';
import { ArrowLeft, User as UserIcon, Mail, Lock, AlertCircle, CheckCircle, Loader } from 'lucide-react';

const Profile: React.FC = () => {
  const { user: authUser } = useAuth();
  const [user, setUser] = useState<User | null>(null);
  const [fullName, setFullName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const passwordRequirements = {
    minLength: 8,
    hasUppercase: /[A-Z]/,
    hasLowercase: /[a-z]/,
    hasNumber: /\d/,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/
  };

  const validatePassword = useCallback((password: string) => {
    const isValid = 
      password.length >= passwordRequirements.minLength &&
      passwordRequirements.hasUppercase.test(password) &&
      passwordRequirements.hasLowercase.test(password) &&
      passwordRequirements.hasNumber.test(password) &&
      passwordRequirements.hasSpecialChar.test(password);

    setIsPasswordValid(isValid);
  }, []);

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword, validatePassword]);

  useEffect(() => {
    if (authUser) {
      setUser(authUser);
      setFullName(authUser.full_name || '');
    }
  }, [authUser]);

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setIsUpdatingProfile(true);

    try {
      if (user) {
        const sanitizedFullName = DOMPurify.sanitize(fullName).trim();
        if (sanitizedFullName.length > 50) {
          setError('Full name must be 50 characters or less.');
          return;
        }
        await auth.updateProfile(user.id, { 
          full_name: sanitizedFullName,
          email: user.email,
          id: user.id
        });
        api.clearUserCache(user.id);
        setMessage('Profile updated successfully.');
      }
    } catch (err) {
      setError('Failed to update profile.');
    } finally {
      setIsUpdatingProfile(false);
    }
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setIsChangingPassword(true);

    if (!isPasswordValid) {
      setError("Password does not meet the requirements");
      setIsChangingPassword(false);
      return;
    }

    const sanitizedNewPassword = DOMPurify.sanitize(newPassword);
    const sanitizedConfirmPassword = DOMPurify.sanitize(confirmPassword);
    
    if (sanitizedNewPassword !== sanitizedConfirmPassword) {
      setError("Passwords don't match");
      setIsChangingPassword(false);
      return;
    }

    try {
      if (!user) {
        throw new Error('No user found');
      }
      await auth.updatePassword(sanitizedNewPassword);
      api.clearUserCache(user.id);
      setMessage('Password changed successfully.');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to change password.');
    } finally {
      setIsChangingPassword(false);
    }
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value);
    setter(sanitizedValue);
  };

  const getRequirementClassName = (ismet: boolean) => 
    `text-sm ${ismet ? 'text-green-600 dark:text-green-400' : 'text-gray-600 dark:text-gray-400'}`;

  const inputClassName = `
    block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 
    rounded-lg bg-white dark:bg-gray-800 
    text-gray-900 dark:text-white
    placeholder-gray-500 dark:placeholder-gray-400
    focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
    focus:border-transparent
    transition duration-150 ease-in-out
  `;

  const disabledInputClassName = `
    block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 
    rounded-lg bg-gray-100 dark:bg-gray-700
    text-gray-900 dark:text-white
    placeholder-gray-500 dark:placeholder-gray-400
    focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
    focus:border-transparent
    transition duration-150 ease-in-out
  `;

  const labelClassName = "flex items-center gap-2 text-sm font-medium text-gray-700 dark:text-gray-300";

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-top justify-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        {/* Header */}
        <div className="text-center">
          <Link 
            to="/products"
            className="inline-flex items-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Products
          </Link>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            User Profile
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Manage your account settings and change your password
          </p>
        </div>

        {/* Alert Messages */}
        {(message || error) && (
          <Alert className={`${message ? 'bg-green-50 dark:bg-green-900/30 border-green-200 dark:border-green-800' : 
                                       'bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800'}`}>
            <div className="flex items-center gap-3">
              {message ? (
                <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-400 flex-shrink-0" />
              ) : (
                <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400 flex-shrink-0" />
              )}
              <AlertDescription className={`${message ? 'text-green-700 dark:text-green-300' : 
                                                       'text-red-700 dark:text-red-300'}`}>
                {message || error}
              </AlertDescription>
            </div>
          </Alert>
        )}

        {/* Profile Form */}
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 space-y-6">
          <form onSubmit={handleUpdateProfile} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="email" className={labelClassName}>
                <Mail className="h-4 w-4 text-gray-500" />
                Email
              </label>
              <input
                type="email"
                id="email"
                value={user.email}
                disabled
                className={disabledInputClassName}
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="fullName" className={labelClassName}>
                <UserIcon className="h-4 w-4 text-gray-500" />
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                value={fullName}
                onChange={handleInputChange(setFullName)}
                maxLength={50}
                className={inputClassName}
                placeholder="Enter your full name"
              />
            </div>

            <Button
              type="submit"
              disabled={isUpdatingProfile}
              className="w-full flex justify-center"
            >
              {isUpdatingProfile ? (
                <div className="flex items-center">
                  <Loader className="w-5 h-5 mr-2 animate-spin" />
                  Updating...
                </div>
              ) : (
                'Update Profile'
              )}
            </Button>
          </form>
        </div>

        {/* Password Change Form */}
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 space-y-6">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Change Password
          </h3>
          <form onSubmit={handleChangePassword} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="newPassword" className={labelClassName}>
                <Lock className="h-4 w-4 text-gray-500" />
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={handleInputChange(setNewPassword)}
                required
                className={inputClassName}
                placeholder="Enter new password"
              />

              {/* Password Requirements */}
              <ul className="mt-2 space-y-1">
                <li className={getRequirementClassName(newPassword.length >= passwordRequirements.minLength)}>
                  • At least {passwordRequirements.minLength} characters
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasUppercase.test(newPassword))}>
                  • At least one uppercase letter
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasLowercase.test(newPassword))}>
                  • At least one lowercase letter
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasNumber.test(newPassword))}>
                  • At least one number
                </li>
                <li className={getRequirementClassName(passwordRequirements.hasSpecialChar.test(newPassword))}>
                  • At least one special character (!@#$%^&*(),.?":{}|&lt;&gt;)
                </li>
              </ul>
            </div>

            <div className="space-y-2">
              <label htmlFor="confirmPassword" className={labelClassName}>
                <Lock className="h-4 w-4 text-gray-500" />
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleInputChange(setConfirmPassword)}
                maxLength={50}
                required
                className={inputClassName}
                placeholder="Confirm new password"
              />
            </div>

            <Button
              type="submit"
              disabled={isChangingPassword}
              variant="secondary"
              className="w-full flex justify-center"
            >
              {isChangingPassword ? (
                <div className="flex items-center">
                  <Loader className="w-5 h-5 mr-2 animate-spin" />
                  Changing Password...
                </div>
              ) : (
                'Change Password'
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;