import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import { useUserCourseAccess } from '../../components/UserCourseAccessProvider';
import { Lock, Download, FileText, Headphones, ArrowLeft } from 'lucide-react';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';
import CopilotContent from './CopilotContent';

interface DigitalProductProps {
  productId: string;
  title: string;
  description: string;
  downloadUrl?: string;
  audioUrl?: string;
  hasAccess: boolean;
  type: 'download' | 'copilot' | 'study-tips';
  tierId?: string;
}

const DigitalProductView: React.FC<DigitalProductProps> = ({
  productId,
  title,
  description,
  downloadUrl,
  audioUrl,
  hasAccess,
  type,
  tierId
}) => {
  const { user } = useAuth();
  const { userCourseAccesses } = useUserCourseAccess();

  if (!user) return null;

  const renderContent = () => {
    
    if (!hasAccess) {
      return (
        <div className="text-center space-y-4">
          <div className="flex justify-center">
            <Lock className="w-12 h-12 text-gray-400 dark:text-gray-600" />
          </div>
          <p className="text-gray-600 dark:text-gray-400">
            This content requires additional access. Please upgrade your account to view this content.
          </p>
          <Button 
            onClick={() => window.open('https://sales.cpaexamguide.com/accounting-copilot/', '_blank', 'noopener,noreferrer')}
            variant="secondary"
            className="w-full"
          >
            Upgrade to Access
          </Button>
        </div>
      );
    }

    switch (type) {
      case 'copilot': {
        return <CopilotContent tierId={tierId} />;
      }

      case 'study-tips':
        return (
          <div className="space-y-4">
            {downloadUrl && (
              <Button 
                onClick={() => window.open(downloadUrl, '_blank')}
                className="w-full flex items-center justify-center gap-2"
              >
                <Download className="w-4 h-4" />
                Download Study Tips PDF
              </Button>
            )}
            {audioUrl && (
              <Button 
                onClick={() => window.open(audioUrl, '_blank')}
                variant="secondary"
                className="w-full flex items-center justify-center gap-2"
              >
                <Headphones className="w-4 h-4" />
                Listen to Audio Book
              </Button>
            )}
          </div>
        );

      default:
        return (
          <Button 
            onClick={() => downloadUrl && window.open(downloadUrl, '_blank')}
            className="w-full flex items-center justify-center gap-2"
          >
            <Download className="w-4 h-4" />
            Download Content
          </Button>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link 
          to="/products"
          className="inline-flex items-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Product Library
        </Link>

        <Card className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                {title}
              </h1>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                hasAccess 
                  ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400'
                  : 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400'
              }`}>
                {hasAccess ? 'Available' : 'Locked'}
              </span>
            </div>
            
            <p className="mt-4 text-gray-600 dark:text-gray-300">
              {description}
            </p>

            <div className="mt-8">
              {renderContent()}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DigitalProductView;