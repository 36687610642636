// src/services/queryClient.ts
import { 
  QueryClient, 
  QueryCache, 
  Query,
  QueryKey,
  DefaultOptions,
  QueryClientConfig
} from 'react-query';

// Define cache durations
const CACHE_DURATION = {
  USER_DATA: 2 * 60 * 1000, // 2 minutes
  PRODUCTS: 30 * 60 * 1000, // 30 minutes
  USER_ACCESS: 2 * 60 * 1000, // 2 minutes
  CONFIDENCE_LEVELS: 5 * 60 * 1000, // 5 minutes
  SIMULATION_DATA: 5 * 60 * 1000, // 5 minutes
  FLASHCARDS: 10 * 60 * 1000, // 10 minutes
  FEEDBACK_LIST: 5 * 60 * 1000, // 5 minutes
  FEEDBACK_ITEM: 5 * 60 * 1000, // 5 minutes
  DEFAULT_STALE_TIME: 60 * 60 * 1000, // 1 hour
  DEFAULT_CACHE_TIME: 24 * 60 * 60 * 1000 // 24 hours
} as const;

// Constants
const ACTIVE_SESSION_PATHS = [
  'quiz',
  'mock-exam',
  'simulations',
  'flashcards'
];

const UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

// Helper to check if we're in an active session
const isInActiveSession = (): boolean => {
  const currentPath = window.location.pathname;
  const pathMatch = /^\/course\/([^/]+)\/([^/]+)/.exec(currentPath);
  
  if (!pathMatch) return false;
  
  const [, courseId, sessionType] = pathMatch;
  return UUID_PATTERN.test(courseId) && ACTIVE_SESSION_PATHS.includes(sessionType);
};

// Query configurations
const BACKGROUND_CONFIG: DefaultOptions = {
  queries: {
    staleTime: CACHE_DURATION.DEFAULT_STALE_TIME,
    cacheTime: CACHE_DURATION.DEFAULT_CACHE_TIME,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1,
    refetchInterval: false,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error']
  },
  mutations: {
    retry: 1
  }
};

const SESSION_CONFIG: DefaultOptions = {
  queries: {
    ...BACKGROUND_CONFIG.queries,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchInterval: false
  },
  mutations: BACKGROUND_CONFIG.mutations
};

// Create the QueryClient instance
export const queryClient = new QueryClient({
  defaultOptions: isInActiveSession() ? SESSION_CONFIG : BACKGROUND_CONFIG,
  queryCache: new QueryCache({
    onError: (error: unknown, query: Query) => {
      console.error(`Query error for key ${JSON.stringify(query.queryKey)}:`, error);
    }
  })
});

// Route change handler
const handleRouteChange = () => {
  const inSession = isInActiveSession();
  queryClient.setDefaultOptions(inSession ? SESSION_CONFIG : BACKGROUND_CONFIG);

  if (inSession) {
    queryClient.getQueryCache().getAll().forEach(query => {
      queryClient.cancelQueries(query.queryKey);
    });
  }
};

window.addEventListener('popstate', handleRouteChange);

// Export helper methods
export const clearQueryData = (queryKey: QueryKey): void => {
  if (!isInActiveSession()) {
    queryClient.removeQueries(queryKey);
  }
};

export const resetCache = async (): Promise<void> => {
  if (!isInActiveSession()) {
    await queryClient.resetQueries(undefined, { 
      active: false 
    });
  }
};

export const invalidateQueries = async (queryKey: QueryKey): Promise<void> => {
  if (!isInActiveSession()) {
    await queryClient.invalidateQueries(queryKey);
  }
};

export const silentRefresh = async (queryKey: QueryKey): Promise<void> => {
  if (!isInActiveSession()) {
    await queryClient.prefetchQuery(queryKey, {
      staleTime: 0,
      cacheTime: BACKGROUND_CONFIG.queries?.cacheTime
    });
  }
};