import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { Ticket, ContentType } from '../../../services/api';
import { format } from 'date-fns';
import { 
  AlertCircle, 
  Clock, 
  Filter, 
  Inbox, 
  Loader2, 
  MessageCircle, 
  Search,
  Tag,
  UserCircle,
  RefreshCcw,
  Trash2,
  Mail,
  X,
  CheckSquare,
  Square,
  MessageSquare 
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { Card } from '../../ui/Card';
import { Checkbox } from '../../ui/Checkbox';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '../../ui/Select';
import { Tooltip } from '../../ui/Tooltip';
import Pagination from '../../course/common/Pagination';

// Constants
const ITEMS_PER_PAGE = 25;
const MAX_PREVIEW_LENGTH = 150;
const MAX_TOOLTIP_LENGTH = 500;

// Also add a type for status to make it more maintainable
type TicketStatus = 'new' | 'open' | 'pending' | 'closed';

interface AdminData {
  email: string;
  full_name: string;
}

interface TransformedTicket extends Omit<Ticket, '_count'> {
  _count: {
    messages: number;
  };
  admin?: AdminData;
}

interface TicketListProps {
  filter: 'all' | 'mine' | 'urgent';
  onTicketSelect: (ticket: TransformedTicket) => void;
  selectedTicketId?: string;
}

const TicketList: React.FC<TicketListProps> = ({ filter, onTicketSelect, selectedTicketId }) => {
  const { user } = useAuth();
  const [tickets, setTickets] = useState<TransformedTicket[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeStatuses, setActiveStatuses] = useState<Set<string>>(new Set(['new', 'open', 'pending']));
  const [priorityFilter, setPriorityFilter] = useState<string>('all');
  const [assignmentFilter, setAssignmentFilter] = useState<string>('all');
  const [selectedTickets, setSelectedTickets] = useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [editingStatus, setEditingStatus] = useState<string>('');
  const [showFeedbackOnly, setShowFeedbackOnly] = useState(false);

  // Add these new helper functions at the top
  const stripHtmlTags = (html: string): string => {
    try {
      // Create a DOMParser instance
      const parser = new DOMParser();
      // Parse the HTML string
      const doc = parser.parseFromString(html, 'text/html');
      // Get the text content and normalize whitespace
      return (doc.body.textContent || '')
        .replace(/\s+/g, ' ')
        .trim();
    } catch (error) {
      // Fallback to basic string replacement if DOMParser fails
      return html
        .replace(/<[^>]*>/g, '') // Remove HTML tags
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
        .replace(/\s+/g, ' ') // Normalize whitespace
        .trim();
    }
  };

  const truncateText = (text: string, maxLength: number): string => {
    if (!text) return '';
    const cleanText = stripHtmlTags(text);
    if (cleanText.length <= maxLength) return cleanText;
    return `${cleanText.substring(0, maxLength).trim()}...`;
  };

  useEffect(() => {
    loadTickets();
  }, [activeStatuses, priorityFilter, assignmentFilter, filter, currentPage, showFeedbackOnly]);

  // Updated loadTickets function with proper joins and logging
  const loadTickets = async () => {
    try {
      setLoading(true);
      
      let countQuery = supabase
        .from('support_tickets')
        .select('id', { count: 'exact' })
        .not('support_user_id', 'is', null);
  
      if (filter === 'urgent') {
        countQuery = countQuery.eq('priority', 'urgent');
      } else if (filter === 'mine') {
        countQuery = countQuery.eq('admin_id', user?.id);
      }
  
      if (activeStatuses.size > 0) {
        countQuery = countQuery.in('status', Array.from(activeStatuses));
      }

      // Add feedback filter to count query
      if (showFeedbackOnly) {
        countQuery = countQuery.not('content_type', 'is', null);
      }
  
      const { count, error: countError } = await countQuery;
      
      if (countError) throw countError;
  
      setTotalItems(count || 0);
      
      let query = supabase
        .from('support_tickets')
        .select(`
          *,
          user:support_users (
            id,
            email,
            full_name,
            auth_user_id,
            email_domain,
            metadata,
            created_at,
            last_activity
          ),
          support_messages (count)
        `)
        .not('support_user_id', 'is', null)
        .order('last_activity_at', { ascending: false })
        .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);
  
      if (filter === 'urgent') {
        query = query.eq('priority', 'urgent');
      } else if (filter === 'mine') {
        query = query.eq('admin_id', user?.id);
      }
  
      if (activeStatuses.size > 0) {
        query = query.in('status', Array.from(activeStatuses));
      }
  
      if (priorityFilter !== 'all') {
        query = query.eq('priority', priorityFilter);
      }
  
      switch (assignmentFilter) {
        case 'unassigned':
          query = query.is('admin_id', null);
          break;
        case 'assigned':
          query = query.not('admin_id', 'is', null);
          break;
        case 'mine':
          query = query.eq('admin_id', user?.id);
          break;
      }

      // Add feedback filter to main query
      if (showFeedbackOnly) {
        query = query.not('content_type', 'is', null);
      }
  
      const { data: ticketsData, error: ticketsError } = await query;
  
      if (ticketsError) throw ticketsError;
  
      if (!ticketsData) {
        setTickets([]);
        return;
      }
    
      const transformedTickets: TransformedTicket[] = ticketsData.map(ticket => ({
        ...ticket,
        _count: {
          messages: ticket.support_messages?.[0]?.count || 0
        },
        admin: ticket.admin ? {
          email: ticket.admin.email,
          full_name: ticket.admin.full_name
        } : undefined
      }));
  
      setTickets(transformedTickets);
  
    } catch (error) {
      console.error('Error in loadTickets:', error);
    } finally {
      setLoading(false);
    }
  };

  // Update the message count display in the JSX to handle undefined safely
  const renderMessageCount = (ticket: TransformedTicket) => {
    return (
      <div className="flex items-center">
        <MessageCircle className="w-3 h-3 mr-1" />
        {ticket._count.messages}
      </div>
    );
  };

  const toggleStatus = (status: TicketStatus) => {
    const newStatuses = new Set(activeStatuses);
    if (newStatuses.has(status)) {
      newStatuses.delete(status);
    } else {
      newStatuses.add(status);
    }
    setActiveStatuses(newStatuses);
  };

  // Helper function to get status button styles
  const getStatusButtonStyle = (status: string) => {
    const isActive = activeStatuses.has(status);
    return {
      base: `h-8 text-xs px-2.5 transition-colors flex items-center gap-1.5 border`,
      active: isActive ? 
        'bg-blue-100 text-blue-800 border-blue-200 hover:bg-blue-200 dark:bg-blue-900/30 dark:text-blue-300 dark:border-blue-800' :
        'bg-gray-50 text-gray-600 border-gray-200 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700'
    };
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'urgent': return 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300 border border-red-200 dark:border-red-800';
      case 'high': return 'bg-orange-100 text-orange-800 dark:bg-orange-900/20 dark:text-orange-300 border border-orange-200 dark:border-orange-800';
      case 'medium': return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-300 border border-yellow-200 dark:border-yellow-800';
      default: return 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-300 border border-gray-200 dark:border-gray-800';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'new': return 'bg-yellow-400 text-black border border-yellow-500 dark:border-yellow-600';
      case 'open': return 'bg-red-600 text-white border border-red-700 dark:border-red-800';
      case 'pending': return 'bg-blue-600 text-yellow-200 border border-blue-700 dark:border-blue-800';
      case 'closed': return 'bg-gray-600 text-white border border-gray-700 dark:border-gray-800';
      default: return 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-300 border border-gray-200 dark:border-gray-800';
    }
  };

  const getFeedbackTagStyle = () => {
    return 'bg-purple-100 text-purple-800 dark:bg-purple-900/20 dark:text-purple-300 border border-purple-200 dark:border-purple-800';
  };
  
  const getContentTypeTagStyle = (contentType: ContentType | null) => {
    if (!contentType) return '';
    
    switch (contentType) {
      case 'questions':
        return 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300 border border-green-200 dark:border-green-800';
      case 'lessons':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300 border border-blue-200 dark:border-blue-800';
      case 'simulations':
        return 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900/20 dark:text-indigo-300 border border-indigo-200 dark:border-indigo-800';
      case 'flashcards':
        return 'bg-pink-100 text-pink-800 dark:bg-pink-900/20 dark:text-pink-300 border border-pink-200 dark:border-pink-800';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-300 border border-gray-200 dark:border-gray-800';
    }
  };

  const getContentTypeDisplayText = (contentType: ContentType): string => {
    switch (contentType) {
      case 'questions':
        return 'MCQ';
      case 'lessons':
        return 'Lesson';
      case 'simulations':
        return 'TBS';
      case 'flashcards':
        return 'Flash';
      default:
        return contentType;
    }
  };

  const StatusChangeDropdown = () => (
    <div className="relative">
      <Select value={editingStatus} onValueChange={handleUpdateStatus}>
        <SelectTrigger className="h-8 text-sm min-w-[80px] bg-white dark:bg-gray-800">
          <SelectValue placeholder="Edit" />
        </SelectTrigger>
        <SelectContent className="w-auto min-w-[120px] absolute left-0 right-auto">
          <SelectItem 
            value="new" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            New
          </SelectItem>
          <SelectItem 
            value="open" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            Open
          </SelectItem>
          <SelectItem 
            value="pending" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            Pending
          </SelectItem>
          <SelectItem 
            value="closed" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            Closed
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );

  // Updated search filter to use support_user fields
const filteredTickets = tickets.filter(ticket => {
  if (!searchQuery) return true;
  const searchLower = searchQuery.toLowerCase();
  return (
    ticket.subject.toLowerCase().includes(searchLower) ||
    ticket.user.email.toLowerCase().includes(searchLower) ||
    (ticket.user.full_name || '').toLowerCase().includes(searchLower) ||
    ticket.tags?.some(tag => tag.toLowerCase().includes(searchLower)) ||
    (ticket.user.email_domain || '').toLowerCase().includes(searchLower)
  );
});

  const handleSelectAllButtonClick = () => {
    // Get all visible ticket IDs
    const visibleTicketIds = filteredTickets.map(ticket => ticket.id);
    
    // Check if all visible tickets are currently selected
    const allVisible = visibleTicketIds.every(id => selectedTickets.has(id));
    
    if (allVisible) {
      // If all visible tickets are selected, deselect only the visible ones
      const newSelected = new Set(selectedTickets);
      visibleTicketIds.forEach(id => newSelected.delete(id));
      setSelectedTickets(newSelected);
    } else {
      // If not all visible tickets are selected, select all visible ones
      const newSelected = new Set([...Array.from(selectedTickets), ...visibleTicketIds]);
      setSelectedTickets(newSelected);
    }
  };

  // Add helper to check if all visible tickets are selected
  const areAllVisibleSelected = () => {
    return filteredTickets.length > 0 && 
           filteredTickets.every(ticket => selectedTickets.has(ticket.id));
  };

  const handleSelectTicket = (ticketId: string) => {
    const newSelected = new Set(selectedTickets);
    if (newSelected.has(ticketId)) {
      newSelected.delete(ticketId);
    } else {
      newSelected.add(ticketId);
    }
    setSelectedTickets(newSelected);
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm('Are you sure you want to delete the selected tickets?')) return;

    try {
      const { error } = await supabase
        .from('support_tickets')
        .delete()
        .in('id', Array.from(selectedTickets));

      if (error) throw error;
      
      setSelectedTickets(new Set());
      loadTickets();
    } catch (error) {
      console.error('Error deleting tickets:', error);
    }
  };

  const handleUpdateStatus = async (newStatus: string) => {
    try {
      const ticketsToUpdate = Array.from(selectedTickets);
      
      const { error } = await supabase
        .from('support_tickets')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .in('id', ticketsToUpdate);

      if (error) throw error;
      
      setEditingStatus('');
      setSelectedTickets(new Set());
      loadTickets();
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Removed Card wrapper - unnecessary since we're using flex layout */}
      <div className="flex-1 flex flex-col min-h-0">
        {/* Header Section - Simplified structure */}
        <div className="bg-white dark:bg-gray-900 border-b dark:border-gray-800 sticky top-0 z-10">
          <div className="p-3 space-y-3">
            {/* Search - Removed unnecessary wrapper divs */}
            <div className="relative">
              <Input
                placeholder="Search tickets..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-8 h-9 bg-gray-50 dark:bg-gray-800 text-sm"
              />
              <Search className="w-4 h-4 text-gray-500 absolute left-2.5 top-2.5" />
            </div>

            {/* Status Filters - Simplified structure */}
            <div className="flex flex-wrap gap-1.5">
              {['new', 'open', 'pending', 'closed'].map(status => (
                <Button
                  key={status}
                  variant="ghost"
                  onClick={() => toggleStatus(status as TicketStatus)}
                  className={`${getStatusButtonStyle(status).base} ${getStatusButtonStyle(status).active} whitespace-nowrap`}
                >
                  {status}
                  {activeStatuses.has(status) && (
                    <X className="w-3 h-3" />
                  )}
                </Button>
              ))}
              
              <Button
                variant="ghost"
                onClick={() => setShowFeedbackOnly(!showFeedbackOnly)}
                className={`
                  h-8 text-xs px-2.5 transition-colors flex items-center gap-1.5 border
                  ${showFeedbackOnly 
                    ? 'bg-purple-100 text-purple-800 border-purple-200 hover:bg-purple-200 dark:bg-purple-900/30 dark:text-purple-300 dark:border-purple-800'
                    : 'bg-gray-50 text-gray-600 border-gray-200 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700'}
                `}
              >
                <MessageSquare className="w-3 h-3" />
                Feedback
                {showFeedbackOnly && (
                  <X className="w-3 h-3" />
                )}
              </Button>

              <div className="flex gap-2 ml-auto md:w-auto w-full">
                <div className="flex gap-2 md:w-auto w-full">
                  <Select value={priorityFilter} onValueChange={setPriorityFilter}>
                    <SelectTrigger className="h-8 text-xs md:text-sm md:w-[100px] w-full bg-gray-50 dark:bg-gray-800">
                      <AlertCircle className="w-3 h-3 mr-1" />
                      <SelectValue placeholder="Priority" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Priority</SelectItem>
                      <SelectItem value="urgent">Urgent</SelectItem>
                      <SelectItem value="high">High</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="low">Low</SelectItem>
                    </SelectContent>
                  </Select>

                  <Select value={assignmentFilter} onValueChange={setAssignmentFilter}>
                    <SelectTrigger className="h-8 text-xs md:text-sm md:w-[100px] w-full bg-gray-50 dark:bg-gray-800">
                      <UserCircle className="w-3 h-3 mr-1" />
                      <SelectValue placeholder="Assignment" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Tickets</SelectItem>
                      <SelectItem value="mine">My Tickets</SelectItem>
                      <SelectItem value="unassigned">Unassigned</SelectItem>
                      <SelectItem value="assigned">Assigned</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Selection Header - Simplified */}
        <div className="px-4 py-2 bg-white dark:bg-gray-900 border-b dark:border-gray-800 flex items-center gap-3">
          <Button 
            variant="ghost"
            onClick={handleSelectAllButtonClick}
            className="p-1"
          >
            {areAllVisibleSelected() ? (
              <CheckSquare className="w-5 h-5 text-blue-600" />
            ) : (
              <Square className="w-5 h-5 text-gray-400" />
            )}
          </Button>

          <span className="text-sm text-gray-600 dark:text-gray-400">
            {selectedTickets.size} selected
          </span>

          {selectedTickets.size > 0 && (
            <div className="flex items-center gap-2">
              <StatusChangeDropdown />
              <Button
                variant="destructive"
                onClick={handleDeleteSelected}
                className="h-8 w-8 p-0"
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
          )}

          <Button 
            variant="ghost"
            onClick={() => loadTickets()}
            className="ml-auto h-8 w-8 p-0"
          >
            <RefreshCcw className="w-4 h-4" />
          </Button>
        </div>

          {/* Tickets List - Scrollable */}
          <div className="flex-1 overflow-y-auto min-h-0">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
              </div>
            ) : filteredTickets.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-500 p-4">
                <Inbox className="w-12 h-12 mb-2" />
                <p>No tickets found</p>
              </div>
            ) : (
              <div className="divide-y divide-gray-100 dark:divide-gray-800">
                {filteredTickets.map((ticket) => (
                  <div
                    key={ticket.id}
                    className={`
                      group flex items-start p-2 cursor-pointer border-l-4 transition-all
                      hover:bg-gray-50 dark:hover:bg-gray-800/50 
                      ${selectedTicketId === ticket.id ? 
                        'bg-blue-50/80 dark:bg-blue-900/20 border-l-4 border-blue-500 font-bold' : 
                        'border-l-transparent bg-white dark:bg-gray-900'}
                      ${selectedTickets.has(ticket.id) ? 
                        'bg-gray-50/80 dark:bg-gray-800/50' : ''}
                    `}
                    onClick={() => onTicketSelect(ticket)}
                  >
                    <div 
                      className="mr-2 mt-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectTicket(ticket.id);
                      }}
                    >
                      <Checkbox
                        checked={selectedTickets.has(ticket.id)}
                        className="cursor-pointer"
                      />
                    </div>
  
                    <div className="flex-1 min-w-0">
                      {/* Ticket Header */}
                      <div className="flex items-start justify-between gap-2 mb-1">
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center gap-2">
                            <Mail className="w-4 h-4 text-gray-400 flex-shrink-0" />
                            <h3 className="font-medium text-sm text-gray-900 dark:text-gray-100 truncate">
                              {ticket.subject}
                            </h3>
                          </div>
                          
                          {/* Body preview with tooltip */}
                          {ticket.body && (
                            <Tooltip
                              content={
                                <div className="max-w-md">
                                  <p className="whitespace-pre-wrap text-xs">
                                    {truncateText(ticket.body, MAX_TOOLTIP_LENGTH)}
                                  </p>
                                </div>
                              }
                              side="right"
                              align="start"
                              delayMS={300}
                            >
                              <div className="text-xs text-gray-600 dark:text-gray-400 line-clamp-1">
                                {truncateText(ticket.body, MAX_PREVIEW_LENGTH)}
                              </div>
                            </Tooltip>
                          )}
                        </div>
  
                        {/* Status and Priority Badges */}
                        <div className="grid grid-cols-2 gap-1 justify-end flex-shrink-0 w-[150px]">
                          {/* Feedback and Content Type Badges */}
                          {ticket.content_type && (
                            <>
                              <span className={`text-xs px-1.5 py-0.5 rounded-full whitespace-nowrap inline-flex items-center justify-center ${getFeedbackTagStyle()}`}>
                                <AlertCircle className="w-3 h-3" />
                              </span>
                              <span className={`text-xs px-1.5 py-0.5 rounded-full whitespace-nowrap text-center ${getContentTypeTagStyle(ticket.content_type as ContentType)}`}>
                                {getContentTypeDisplayText(ticket.content_type as ContentType)}
                              </span>
                            </>
                          )}
                          {/* Priority Badge */}
                          <span className={`text-xs px-1.5 py-0.5 rounded-full whitespace-nowrap text-center ${getPriorityColor(ticket.priority)}`}>
                            {ticket.priority}
                          </span>
                          {/* Status Badge */}
                          <span className={`text-xs px-1.5 py-0.5 rounded-full whitespace-nowrap text-center ${getStatusColor(ticket.status)}`}>
                            {ticket.status}
                          </span>
                        </div>
                      </div>
  
                      {/* Ticket Metadata */}
                      <div className="flex flex-wrap items-center text-xs text-gray-500 gap-x-3 gap-y-1">
                        <div className="flex items-center">
                          <UserCircle className="w-3 h-3 mr-1" />
                          <span className="truncate max-w-[150px]">{ticket.user.full_name}</span>
                        </div>
                        <div className="flex items-center">
                          <Clock className="w-3 h-3 mr-1" />
                          {format(new Date(ticket.last_activity_at), 'MMM d, h:mm a')}
                        </div>
                        {renderMessageCount(ticket)}
                        {ticket.admin && (
                          <div className="flex items-center">
                            <UserCircle className="w-3 h-3 mr-1" />
                            <span className="truncate max-w-[150px]">{ticket.admin.full_name}</span>
                          </div>
                        )}
                        {ticket.tags && ticket.tags.length > 0 && (
                          <div className="flex items-center gap-1 flex-wrap">
                            <Tag className="w-3 h-3" />
                            {ticket.tags.map((tag: string, index: number) => (
                              <span
                                key={index}
                                className="text-xs px-1.5 py-0.5 rounded-full bg-gray-100 dark:bg-gray-800"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

        {/* Pagination */}
        <div className="sticky bottom-0 bg-white dark:bg-gray-900 border-t dark:border-gray-800">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketList;