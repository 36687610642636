export const ACTIVE_SESSION_ROUTES = [
    '/quiz',
    '/mock-exam', 
    '/simulations',
    '/course'
  ] as const;
  
  export const ACTIVE_SESSION_PATTERNS = [
    /\/simulations\/[\w-]+\/preview$/,      // Simulation previews
    /\/quiz\/[\w-]+$/,                      // Individual quizzes
    /\/mock-exam\/[\w-]+\/testlet/,         // Mock exam testlets
    /\/mock-exam\/[\w-]+\/review$/,         // Mock exam reviews
    /\/simulations\/[\w-]+\/review$/        // Simulation reviews
  ] as const;
  
  /**
   * Checks if the current route is an active session route
   * Active sessions include quizzes, mock exams, and simulations
   * where we want to prevent re-renders and maintain state
   */
  export const isActiveSessionRoute = (pathname: string = window.location.pathname): boolean => {
    // First check exact routes
    const isActiveRoute = ACTIVE_SESSION_ROUTES.some(route => 
      pathname.includes(route)
    );
  
    if (isActiveRoute) return true;
  
    // Then check regex patterns
    return ACTIVE_SESSION_PATTERNS.some(pattern => 
      pattern.test(pathname)
    );
  };
  
  /**
   * Checks if the current route is specifically a simulation route
   */
  export const isSimulationRoute = (pathname: string = window.location.pathname): boolean => {
    return pathname.includes('/simulations');
  };
  
  /**
   * Checks if the current route is specifically a mock exam route
   */
  export const isMockExamRoute = (pathname: string = window.location.pathname): boolean => {
    return pathname.includes('/mock-exam');
  };
  
  /**
   * Checks if the current route is specifically a quiz route
   */
  export const isQuizRoute = (pathname: string = window.location.pathname): boolean => {
    return pathname.includes('/quiz');
  };
  
  /**
   * Get the type of active session for the current route
   */
  export type ActiveSessionType = 'quiz' | 'mock-exam' | 'simulation' | 'none';
  
  export const getActiveSessionType = (pathname: string = window.location.pathname): ActiveSessionType => {
    if (isQuizRoute(pathname)) return 'quiz';
    if (isMockExamRoute(pathname)) return 'mock-exam';
    if (isSimulationRoute(pathname)) return 'simulation';
    return 'none';
  };