import React, { useState, useEffect } from 'react';
import { Alert } from '../ui/Alert';
import { Button } from '../ui/Button';
import { TIER_IDS } from '../../services/api';
import { 
  BookOpen, 
  Rocket, 
  BarChart2, 
  Mail, 
  FileText, 
  Table, 
  MessageSquare, 
  Users,
  Timer,
  Lock,
  Crown
} from 'lucide-react';
import { useAuth } from '../AuthProvider';

type TabValue = 
  | 'study-sidekick'
  | 'productivity-coach'
  | 'variance-analyzer'
  | 'email-copilot'
  | 'document-summarizer'
  | 'spreadsheet-tutor'
  | 'counterpoint-companion'
  | 'recruiting-copilot';

interface CopilotTab {
  id: TabValue;
  title: string;
  description: string;
  url: string;
  product: string;
  icon: React.ElementType;
  shortDesc: string;
}

interface TimerState {
  lastUsedDate: string;
  remainingTime: number;
}

interface CopilotContentProps {
  tierId?: string;
}

const FREE_TRIAL_DURATION = 180; // 3 minutes in seconds

const COPILOT_TABS: CopilotTab[] = [
  {
    id: 'study-sidekick',
    title: 'Study Sidekick',
    icon: BookOpen,
    shortDesc: 'Get help with CPA exam topics',
    description: 'Need to learn a new accounting topic? Ask Your KeslerEdge™ Study Sidekick for help!',
    url: 'https://keslerchatapp-private.vercel.app/',
    product: 'KeslerEdge™ CPA Exam Sidekick'
  },
  {
    id: 'productivity-coach',
    title: 'Productivity Coach',
    icon: Rocket,
    shortDesc: 'Optimize your study schedule',
    description: 'Need help finding time to study? Can\'t get any momentum going? Ask Your KeslerEdge™ Productivity Coach for help specific to your situation!',
    url: 'https://accounting-copilot-productivity-coach.vercel.app/',
    product: 'KeslerEdge™ CPA Exam Productivity Coach'
  },
  {
    id: 'variance-analyzer',
    title: 'Variance Analyzer',
    icon: BarChart2,
    shortDesc: 'Analyze financial fluctuations',
    description: 'See a weird variance? Observing a large fluctuation in the financial statements and not sure what to do next? Ask your Accounting Variance Analyzer for ideas!',
    url: 'https://accounting-copilot-statement-analysis.vercel.app/',
    product: 'Accounting Pilot\'s™ Accounting Variance Analyzer'
  },
  {
    id: 'email-copilot',
    title: 'Email Copilot',
    icon: Mail,
    shortDesc: 'Draft professional emails quickly',
    description: 'Don\'t let email ruin your productivity! Your Email Generator Copilot has you covered. Quickly craft the perfect email or draft response by sharing the details with your copilot below!',
    url: 'https://accounting-copilot-email-writer.vercel.app/',
    product: 'Accounting Pilot\'s™ Email Copilot'
  },
  {
    id: 'document-summarizer',
    title: 'Document Summarizer',
    icon: FileText,
    shortDesc: 'Get quick document summaries',
    description: 'Just need the cliff notes? Our document summarizer has you covered. Copy and paste ANY text (meeting notes, video transcripts, or emails) below, and our Summarizer Copilot will give you the bullet points and summarize the need-to-know information for you!',
    url: 'https://accounting-copilot-legal-doc-summarizer.vercel.app/',
    product: 'Accounting Pilot\'s™ Document Summarizer'
  },
  {
    id: 'spreadsheet-tutor',
    title: 'Spreadsheet Mastery',
    icon: Table,
    shortDesc: 'Excel & spreadsheet tutorials',
    description: 'Want to do more than just make your spreadsheets look pretty? Our Spreadsheet Mastery Tutor has you covered! Explain what you are trying to accomplish with your data and your Copilot will give you step-by-step instructions (and code) to follow!',
    url: 'https://accounting-copilot-excel-mastery.vercel.app/',
    product: 'Accounting Pilot\'s™ Spreadsheet Mastery Tutor'
  },
  {
    id: 'counterpoint-companion',
    title: 'Counterpoint Companion',
    icon: MessageSquare,
    shortDesc: 'Test and improve your arguments',
    description: 'Got a BIG idea, but not sure if it\'s any good? Need to convince your manager on your opinion? Passed the CPA exam and need to argue for a raise? Test your arguments against our Counterpoint Companion to sharpen your responses!',
    url: 'https://accounting-copilot-salary-booster.vercel.app/',
    product: 'Accounting Pilot\'s™ Counterpoint Companion'
  },
  {
    id: 'recruiting-copilot',
    title: 'Recruiting Copilot',
    icon: Users,
    shortDesc: 'Hire the right team members',
    description: 'Need to build a team? Need to replace an employee? Share your job position and responsibilities and our Recruiting Copilot will help you find your dream employee!',
    url: 'https://accounting-copilot-job-post-helper.vercel.app/',
    product: 'Accounting Pilot\'s™ Recruiting Copilot'
  }
];

const CopilotContent: React.FC<CopilotContentProps> = ({ tierId }) => {
  const [activeTab, setActiveTab] = useState<TabValue>('study-sidekick');
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const { user } = useAuth();

  const hasFullAccess = tierId === TIER_IDS.COPILOT;
  const isFreeTrial = tierId === TIER_IDS.FREE_TRIAL;
  const activeTabData = COPILOT_TABS.find(tab => tab.id === activeTab)!;

  const getUpgradeUrl = () => {
    const baseUrl = 'https://sales.cpaexamguide.com/accounting-copilot/';
    const params = new URLSearchParams();

    if (user) {
      const { email, full_name } = user;
      if (email) params.append('passthrough[customer_email]', email);
      if (full_name) params.append('passthrough[customer_name]', full_name);
    }

    return `${baseUrl}?${params.toString()}`;
  };

  const handleUpgradeClick = () => {
    const url = getUpgradeUrl();
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
    // Initialize timer state
    useEffect(() => {

      if (hasFullAccess) {
        setTimeRemaining(null);
        setIsTrialExpired(false);
        return;
      }

      if (!isFreeTrial) {
        setTimeRemaining(null);
        setIsTrialExpired(true);
        return;
      }
  
      const today = new Date().toISOString().split('T')[0];
      const savedTimer = localStorage.getItem('copilotTimer');
            
      let timerState: TimerState | null = null;
  
      try {
        timerState = savedTimer ? JSON.parse(savedTimer) : null;
      } catch (e) {
        console.error('Error parsing timer state:', e);
        timerState = null;
      }
  
      if (!timerState || timerState.lastUsedDate !== today) {
        const newTimerState = {
          lastUsedDate: today,
          remainingTime: FREE_TRIAL_DURATION
        };
        localStorage.setItem('copilotTimer', JSON.stringify(newTimerState));
        setTimeRemaining(FREE_TRIAL_DURATION);
        setIsTrialExpired(false);
      } else {
        setTimeRemaining(timerState.remainingTime);
        setIsTrialExpired(timerState.remainingTime <= 0);
      }
    }, [tierId, hasFullAccess, isFreeTrial]);
  
    // Handle timer countdown
    useEffect(() => {
      if (hasFullAccess || !isFreeTrial || timeRemaining === null) {
        return;
      }
      
      if (timeRemaining <= 0) {
        setIsTrialExpired(true);
        return;
      }
  
      const timer = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev === null) return 0;
          
          const newTime = Math.max(0, prev - 1);
          const today = new Date().toISOString().split('T')[0];
          
          localStorage.setItem('copilotTimer', JSON.stringify({
            lastUsedDate: today,
            remainingTime: newTime
          }));
  
          if (newTime <= 0) {
            setIsTrialExpired(true);
          }
  
          return newTime;
        });
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, [timeRemaining, hasFullAccess, isFreeTrial]);
  
    const formatTime = (seconds: number): string => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}:${secs.toString().padStart(2, '0')}`;
    };
  
    return (
      <div className="space-y-6">
        <h2 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-4">
          Select a tool to get started:
        </h2>

        {isFreeTrial && timeRemaining !== null && (
          <div className="w-full bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="flex items-center gap-2">
              <Timer className="w-5 h-5 text-gray-600 dark:text-gray-400" />
              <span className="font-medium text-gray-700 dark:text-gray-300">
                Free Trial Time Remaining: {formatTime(timeRemaining)}
              </span>
            </div>
            <Button 
              onClick={handleUpgradeClick}
              className="bg-primary-orange hover:bg-primary-orange-hover text-white px-4 py-2 rounded-lg font-semibold flex items-center gap-2"
            >
              <Crown size={16} />
              Unlock Unlimited Use
            </Button>
          </div>
        )}
        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          {COPILOT_TABS.map(tab => {
            const Icon = tab.icon;
            const isActive = activeTab === tab.id;
            
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  p-4 rounded-lg border transition-all duration-200
                  hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20
                  ${isActive ? 
                    'border-blue-500 bg-blue-50 dark:bg-blue-900/30 shadow-sm' : 
                    'border-gray-200 dark:border-gray-700'
                  }
                `}
              >
                <div className="flex flex-col items-center text-center space-y-2">
                  <Icon 
                    className={`w-6 h-6 ${
                      isActive ? 
                        'text-blue-500 dark:text-blue-400' : 
                        'text-gray-600 dark:text-gray-400'
                    }`} 
                  />
                  <div>
                    <div className={`font-medium ${
                      isActive ? 
                        'text-blue-700 dark:text-blue-300' : 
                        'text-gray-900 dark:text-gray-200'
                    }`}>
                      {tab.title}
                    </div>
                    <div className="text-xs text-gray-600 dark:text-gray-400 mt-1">
                      {tab.shortDesc}
                    </div>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
  
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-6 relative">
          {!hasFullAccess && isTrialExpired && (
            <div className="absolute inset-0 backdrop-blur-sm bg-gray-900/50 rounded-lg flex items-center justify-center z-10">
              <div className="text-center p-8 max-w-md">
                <Lock className="w-12 h-12 text-white mx-auto mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Free Trial Time Expired
                </h3>
                <p className="text-gray-200 mb-6">
                  Your free trial time for today has expired. Upgrade now to get unlimited access to all Accounting Copilot features!
                </p>
                <Button 
                  onClick={handleUpgradeClick}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white"
                >
                  Upgrade to Full Access
                </Button>
              </div>
            </div>
          )}
  
          <p className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-6">
            {activeTabData.description}
          </p>
          
          <div className="w-full h-[800px] bg-white dark:bg-gray-900 rounded-lg overflow-hidden shadow-sm">
            <iframe 
              src={activeTabData.url}
              className="w-full h-full border-0"
              title={activeTabData.title}
              sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
              loading="lazy"
            />
          </div>
  
          <Alert variant="warning" className="mt-6">
            <p className="text-xs text-center">
              <strong>DISCLAIMER:</strong> {activeTabData.product} is a BETA product that was created for 
              entertainment purposes only. Double-check all of the outputs and verify them against source material. 
              Do NOT upload copyrighted material into the chat. It can also be VERY 
              confidently wrong from time to time, please use professional skepticism and use this tool as a way 
              to verify your understanding of the material.
            </p>
          </Alert>
        </div>
      </div>
    );
};
  
export default CopilotContent;