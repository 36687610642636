import React from 'react';
import { Modal } from '../../ui/Modal';
import { ScrollArea } from '../../ui/ScrollArea';
import { Button } from '../../ui/Button';
import { Loader2, Save } from 'lucide-react';

interface DiffViewProps {
  field: string;
  oldValue: string;
  newValue: string;
}

// Helper function to find the differences between two strings
const findDifferences = (str1: string, str2: string) => {
    const maxLength = Math.max(str1.length, str2.length);
    const diffs = [];
    
    for (let i = 0; i < maxLength; i++) {
      if (str1[i] !== str2[i]) {
        let start = i;
        // Find the end of the difference
        while (i < maxLength && str1[i] !== str2[i]) {
          i++;
        }
        diffs.push({ start, end: i });
      }
    }
    return diffs;
  };
  
  const DiffLine: React.FC<{ 
    oldContent: string; 
    newContent: string;
    side: 'left' | 'right';
  }> = ({ oldContent, newContent, side }) => {
    const diffs = findDifferences(oldContent, newContent);
    const content = side === 'left' ? oldContent : newContent;
    const isChanged = oldContent !== newContent;
    
    const renderContent = () => {
      if (!diffs.length) return content;
      
      let lastIndex = 0;
      const parts = [];
      
      diffs.forEach(({ start, end }) => {
        // Add unchanged part
        if (start > lastIndex) {
          parts.push(
            <span key={`normal-${lastIndex}`} className="font-normal">
              {content.slice(lastIndex, start)}
            </span>
          );
        }
        
        // Add changed part
        parts.push(
          <span key={`changed-${start}`} className="font-bold">
            {content.slice(start, end)}
          </span>
        );
        
        lastIndex = end;
      });
      
      // Add remaining unchanged part
      if (lastIndex < content.length) {
        parts.push(
          <span key={`normal-${lastIndex}`} className="font-normal">
            {content.slice(lastIndex)}
          </span>
        );
      }
      
      return parts;
    };
  
    return (
      <div className={`py-1 px-4 font-mono text-sm ${isChanged ? 
        side === 'left' 
          ? 'bg-red-100/50 dark:bg-red-950/50 text-red-900 dark:text-red-100' 
          : 'bg-green-100/50 dark:bg-green-950/50 text-green-900 dark:text-green-100'
        : 'text-gray-700 dark:text-gray-300'}`}
      >
        <span className="inline-block w-4 text-gray-400">
          {isChanged ? (side === 'left' ? '-' : '+') : ' '}
        </span>
        {renderContent()}
      </div>
    );
  };
  
  const DiffView: React.FC<DiffViewProps> = ({ field, oldValue, newValue }) => {
    const oldLines = oldValue.split('\n');
    const newLines = newValue.split('\n');
    const maxLines = Math.max(oldLines.length, newLines.length);
  
    return (
      <div className="border dark:border-gray-700 rounded-lg overflow-hidden">
        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-2 border-b dark:border-gray-700">
          <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">
            {field}
          </h4>
        </div>
        <div className="grid grid-cols-2 divide-x dark:divide-gray-700">
          {/* Database Content */}
          <div>
            <div className="text-xs font-medium text-gray-500 dark:text-gray-400 p-2 border-b dark:border-gray-700">
              Database Content
            </div>
            <div>
              {Array.from({ length: maxLines }).map((_, i) => (
                <DiffLine
                  key={`old-${i}`}
                  oldContent={oldLines[i] || ''}
                  newContent={newLines[i] || ''}
                  side="left"
                />
              ))}
            </div>
          </div>
          
          {/* New Content */}
          <div>
            <div className="text-xs font-medium text-green-600 dark:text-green-400 p-2 border-b dark:border-gray-700">
              New Content
            </div>
            <div>
              {Array.from({ length: maxLines }).map((_, i) => (
                <DiffLine
                  key={`new-${i}`}
                  oldContent={oldLines[i] || ''}
                  newContent={newLines[i] || ''}
                  side="right"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
};

interface SaveChangesModalProps {
  isOpen: boolean;
  onClose: () => void;
  changes: Array<{
    field: string;
    oldValue: string;
    newValue: string;
  }>;
  onConfirm: () => Promise<void>;
  isSaving: boolean;
}

const SaveChangesModal: React.FC<SaveChangesModalProps> = ({
  isOpen,
  onClose,
  changes,
  onConfirm,
  isSaving
}) => {
  // Group changes by content type
  const questionContentChanges = changes.filter(c => 
    c.field === 'question_content' || 
    c.field === 'answer_content'
  );
  
  const metadataChanges = changes.filter(c => 
    !['question_content', 'answer_content'].includes(c.field)
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="container max-w-6xl mx-auto px-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden">
          <div className="px-6 py-4 border-b dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              Review Changes
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Compare the current database content with your new changes:
            </p>
          </div>

          <div className="px-6 py-4">
            <ScrollArea className="max-h-[60vh]">
              <div className="space-y-6">
                {questionContentChanges.length > 0 && (
                  <div className="space-y-4">
                    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Content Changes
                    </h4>
                    {questionContentChanges.map((change, index) => (
                      <DiffView
                        key={index}
                        field={change.field}
                        oldValue={change.oldValue}
                        newValue={change.newValue}
                      />
                    ))}
                  </div>
                )}

                {metadataChanges.length > 0 && (
                  <div className="space-y-4">
                    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Metadata Changes
                    </h4>
                    {metadataChanges.map((change, index) => (
                      <DiffView
                        key={index}
                        field={change.field}
                        oldValue={change.oldValue}
                        newValue={change.newValue}
                      />
                    ))}
                  </div>
                )}
              </div>
            </ScrollArea>
          </div>

          <div className="px-6 py-4 bg-gray-50 dark:bg-gray-900 flex justify-end gap-3">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={onConfirm}
              className="bg-green-600 hover:bg-green-700 text-white"
              disabled={isSaving}
            >
              {isSaving ? (
                <span className="flex items-center gap-1.5">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Saving Changes...
                </span>
              ) : (
                <span className="flex items-center gap-1.5">
                  <Save className="w-4 h-4" />
                  Save Changes
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SaveChangesModal;