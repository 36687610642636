import React, { createContext, useContext, useMemo, useCallback, useRef, useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useAuth } from './AuthProvider';
import { userDataService } from '../services/userDataService';
import { isActiveSessionRoute } from '../utils/activeSessionUtils';
import { UserCourseAccess, UserCoursePurchase, User, TIER_IDS } from '../services/api';

// Again, import the SINGLE shared queryClient:
import { queryClient } from '../services/queryClient';

interface UserCourseAccessContextType {
  userCourseAccesses: UserCourseAccess[];
  userCoursePurchases: UserCoursePurchase[];
  userData: User | null;
  hasActivePurchase: boolean;
  canSwitchToPaidCourse: boolean;
  isLoading: boolean;
  error: unknown;
  refetch: () => Promise<void>;
}

const UserCourseAccessContext = createContext<UserCourseAccessContextType | undefined>(undefined);

const STALE_TIME = 5 * 60 * 1000;     // 5 minutes
const CACHE_TIME = 10 * 60 * 1000;    // 10 minutes
const REFETCH_INTERVAL = 5 * 60 * 1000; // 5 minutes

interface UserCourseData {
  user: User | null;
  userCoursePurchases: UserCoursePurchase[];
  userCourseAccesses: UserCourseAccess[];
}

export const UserCourseAccessProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const lastFetchTimeRef = useRef<number>(0);
  const activeSessionRef = useRef(false);

  useEffect(() => {
    activeSessionRef.current = isActiveSessionRoute();
    const handleRouteChange = () => {
      activeSessionRef.current = isActiveSessionRoute();
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  // Fetch function
  const fetchUserCourseData = useCallback(async (): Promise<UserCourseData> => {
    if (!user) {
      throw new Error('No authenticated user');
    }
    if (activeSessionRef.current && Date.now() - lastFetchTimeRef.current < CACHE_TIME) {
      const cachedData = queryClient.getQueryData<UserCourseData>(['userCourseData', user.id]);
      if (cachedData) {
        return cachedData;
      }
    }
    const userData = await userDataService.getAllUserData(user.id);
    lastFetchTimeRef.current = Date.now();
    return {
      user: userData.user,
      userCoursePurchases: userData.coursePurchases,
      userCourseAccesses: userData.courseAccesses
    };
  }, [user]);

  const { data, isLoading, error, refetch }: UseQueryResult<UserCourseData, unknown> = useQuery(
    ['userCourseData', user?.id],
    fetchUserCourseData,
    {
      enabled: !!user,
      refetchInterval: activeSessionRef.current ? false : REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        console.error('Error fetching user course data:', error);
        if (activeSessionRef.current) {
          return queryClient.getQueryData(['userCourseData', user?.id]);
        }
      },
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME
    }
  );

  const safeRefetch = useCallback(async () => {
    if (user && !activeSessionRef.current) {
      try {
        await refetch();
        lastFetchTimeRef.current = Date.now();
      } catch (err) {
        console.error('Error refetching user course data:', err);
      }
    }
  }, [user, refetch]);

  const contextValue = useMemo(() => {
    const hasActivePurchase =
      data?.userCoursePurchases.some(
        (purchase) =>
          (purchase.tier_id === TIER_IDS.FULL_ACCESS ||
            purchase.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY) &&
          purchase.is_active &&
          (purchase.subscription_status === 'active' || purchase.subscription_status === null)
      ) ?? false;

    const canSwitchToPaidCourse =
      !!data?.user &&
      typeof data.user.switches_available === 'number' &&
      data.user.switches_available > 0;

    return {
      userCourseAccesses: data?.userCourseAccesses ?? [],
      userCoursePurchases: data?.userCoursePurchases ?? [],
      userData: data?.user ?? null,
      hasActivePurchase,
      canSwitchToPaidCourse,
      isLoading,
      error,
      refetch: safeRefetch
    };
  }, [data, isLoading, error, safeRefetch]);

  return (
    // Removed the nested QueryClientProvider
    <UserCourseAccessContext.Provider value={contextValue}>
      {children}
    </UserCourseAccessContext.Provider>
  );
};

export const useUserCourseAccess = (): UserCourseAccessContextType => {
  const context = useContext(UserCourseAccessContext);
  if (context === undefined) {
    throw new Error('useUserCourseAccess must be used within a UserCourseAccessProvider');
  }
  return context;
};
