// Types remain the same
interface ParsedQuestion {
  questionText: string;
  tableHTML: string;
  options: string[];
  romanList: string;
  isTableQuestion: boolean;
}

interface TableCell {
  letter: string | null;
  text: string;
}

// Updated cleanHtmlContent function to preserve formatting
const cleanHtmlContent = (html: string): string => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Function to clean text nodes while preserving structure
  const cleanNode = (node: Node): void => {
    if (node.nodeType === Node.TEXT_NODE) {
      // Clean only text nodes
      if (node.textContent) {
        node.textContent = node.textContent
          .replace(/\s+/g, ' ') // Collapse multiple spaces into one
          .trim();
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Preserve pre tags and their content
      if ((node as Element).tagName.toLowerCase() === 'pre') {
        return;
      }
      
      // Recursively clean child nodes
      Array.from(node.childNodes).forEach(cleanNode);
    }
  };

  // Clean the content
  cleanNode(tempDiv);
  
  return tempDiv.innerHTML;
};

// Other helper functions remain the same
const processTableCell = (cell: Element): TableCell => {
  const letter = cell.getAttribute('data-choice');
  const text = cell.textContent?.trim() || '';
  return { letter, text };
};

const formatMCQOption = (letter: string, text: string): string => {
  return `${letter}. ${text.trim()}`;
};

const getOptionsFromList = (ol: Element): string[] => {
  return Array.from(ol.querySelectorAll('li')).map((li, index) => {
    const letter = String.fromCharCode(65 + index);
    const text = li.textContent?.trim() || '';
    return formatMCQOption(letter, text);
  });
};

const applyTableStyles = (table: Element): void => {
  table.classList.add('w-full', 'border-collapse');
  
  const headerRow = table.querySelector('thead tr');
  if (headerRow) {
    const firstTh = headerRow.querySelector('th');
    if (firstTh) firstTh.textContent = 'Option';
    headerRow.querySelectorAll('th').forEach(th => {
      th.classList.add('border', 'border-light-text', 'dark:border-dark-text', 'p-2', 'text-center', 'font-bold');
    });
  }
};

const applyTableCellStyles = (td: Element): void => {
  td.classList.add('border', 'border-light-text', 'dark:border-dark-text', 'p-2', 'text-center', 'align-middle');
};

const processTableRow = (row: Element): { letter: string; optionText: string } | null => {
  const firstTd = row.querySelector('td');
  const cell = row.querySelector('.multi-choice[data-choice]');
  if (!cell || !firstTd) return null;

  const letter = cell.getAttribute('data-choice') || '';
  const optionText = Array.from(row.querySelectorAll('td'))
    .slice(1)
    .map(td => td.textContent?.trim())
    .filter(Boolean)
    .join(' | ');

  firstTd.innerHTML = `<div class="multi-choice" data-choice="${letter}">${letter}</div>`;
  firstTd.classList.add('cursor-pointer');
  applyTableCellStyles(firstTd);

  row.querySelectorAll('td').forEach(applyTableCellStyles);

  return { letter, optionText };
};

const processMCQTable = (table: Element): { options: string[]; tableHTML: string } => {
  applyTableStyles(table);
  
  const options: string[] = [];
  const rows = table.querySelectorAll('tr');
  
  rows.forEach(row => {
    const result = processTableRow(row);
    if (result) {
      options.push(formatMCQOption(result.letter, result.optionText));
    }
  });

  return {
    options,
    tableHTML: table.outerHTML
  };
};

const getAverageListItemLength = (ol: Element): number => {
  const items = ol.querySelectorAll('li');
  const totalLength = Array.from(items)
    .reduce((sum, item) => sum + (item.textContent?.length || 0), 0);
  return totalLength / items.length;
};

const findMultipleChoiceList = (element: Element): Element | null => {
  const allOrderedLists = Array.from(element.querySelectorAll('ol'));
  
  // If we have exactly two lists and the first one has a class
  if (allOrderedLists.length === 2 && 
      allOrderedLists[0].classList.length > 0 && 
      allOrderedLists[1].classList.length === 0) {
    // Return the second list as it's likely the multiple choice options
    return allOrderedLists[1];
  }
  
  // Filter out lists with classes
  const plainLists = allOrderedLists.filter(ol => ol.classList.length === 0);
  
  // If only one plain list, it must be the multiple choice options
  if (plainLists.length === 1) {
    return plainLists[0];
  }
  
  // If multiple plain lists, use the last one
  if (plainLists.length > 1) {
    return plainLists[plainLists.length - 1];
  }
  
  return null;
};

const preserveRomanList = (element: Element): string => {
  const romanList = element.querySelector('ol.upper-roman');
  if (!romanList) return '';
  
  const placeholder = `__ROMAN_LIST_${Date.now()}__`;
  romanList.replaceWith(placeholder);
  
  return romanList.outerHTML;
};

const restoreRomanList = (content: string, romanListHtml: string): string => {
  if (!romanListHtml) return content;
  return content.replace(/__ROMAN_LIST_\d+__/, romanListHtml);
};

export const parseQuestionContent = (content: string): ParsedQuestion => {
  const result: ParsedQuestion = {
    questionText: content,
    tableHTML: '',
    options: [],
    romanList: '',
    isTableQuestion: false
  };

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = cleanHtmlContent(content);
  
  // First, preserve the roman list if it exists
  const romanListHtml = preserveRomanList(tempDiv);
  if (romanListHtml) {
    result.romanList = romanListHtml;
  }

  // Get key elements
  const table = tempDiv.querySelector('table');
  const optionsList = findMultipleChoiceList(tempDiv);

  // Process table-based questions
  if (table) {
    const optionCells = table.querySelectorAll('.multi-choice[data-choice]');
    if (optionCells.length > 0) {
      result.isTableQuestion = true;
      table.remove();
      const tableResults = processMCQTable(table);
      result.options = tableResults.options;
      result.tableHTML = tableResults.tableHTML;
    } else if (optionsList) {
      result.options = getOptionsFromList(optionsList);
      optionsList.remove();
    }
  } else if (optionsList) {
    result.options = getOptionsFromList(optionsList);
    optionsList.remove();
  }

  // Get the cleaned question text and restore the roman list
  result.questionText = restoreRomanList(tempDiv.innerHTML, romanListHtml);

  return result;
};