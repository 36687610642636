// src/utils/logger.ts

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LoggerOptions {
  namespace?: string;
  level?: LogLevel;
}

class Logger {
  private namespace: string;
  private level: LogLevel;
  private isDevelopment: boolean;

  constructor(options: LoggerOptions = {}) {
    this.namespace = options.namespace || '';
    this.level = options.level || 'info';
    this.isDevelopment = this.checkIfDevelopment();
  }

  private checkIfDevelopment(): boolean {
    if (typeof window !== 'undefined') {
      const hostname = window.location.hostname;
      return hostname !== 'app.keslercpareview.com' && 
             hostname !== 'www.app.keslercpareview.com';
    }
    return false;
  }

  private formatMessage(message: string): string {
    return this.namespace ? `[${this.namespace}] ${message}` : message;
  }

  private shouldLog(level: LogLevel): boolean {
    const levels: Record<LogLevel, number> = {
      debug: 0,
      info: 1,
      warn: 2,
      error: 3
    };

    // Only show debug and info in development
    if (level === 'debug' || level === 'info') {
      return this.isDevelopment;
    }

    // Always show warnings and errors
    return true;
  }

  debug(message: string, ...args: any[]): void {
    if (this.shouldLog('debug')) {
      console.debug(this.formatMessage(message), ...args);
    }
  }

  info(message: string, ...args: any[]): void {
    if (this.shouldLog('info')) {
      console.info(this.formatMessage(message), ...args);
    }
  }

  warn(message: string, ...args: any[]): void {
    if (this.shouldLog('warn')) {
      console.warn(this.formatMessage(message), ...args);
    }
  }

  error(message: string, ...args: any[]): void {
    // Always log errors
    console.error(this.formatMessage(message), ...args);
  }
}

export const createLogger = (options: LoggerOptions = {}): Logger => {
  return new Logger(options);
};

// Create default logger instance
export const logger = createLogger();