import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, CreateStudyTypeRequest, BrandNewMentalState, InProcessMentalState, FailedMentalState, WorkHours, Accountability, Struggle, ShirtSize } from '../../services/api';
import DatePicker from 'react-datepicker';
import { format, addDays, subDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { 
  Award,
  Loader2, 
  ChevronDown,
  BookOpenCheck, 
  RotateCcw,
  ClipboardCheck,
  Smile, 
  Frown, 
  HelpCircle,
  ThumbsUp,
  AlertCircle, 
  BookPlus, 
  GraduationCap,
  School,
  Info,
  Calendar,
  Clock4,
  CheckCircle2,
  Briefcase,
  Users,
  Heart,
  HeartHandshake,
  User,
  Baby,
  BadgeCheck,
  BookOpen,
  Scale,
  Battery,
  Clock,
  Rocket,
  ArrowRight,
  ArrowLeft,
  LightbulbIcon,
  Trophy,
  Shirt,
  Lightbulb,
  ArrowDown
} from 'lucide-react';
import { createLogger } from '../../utils/Logger';

const logger = createLogger({ namespace: 'Study Plan Generator' });

interface StudyPlanGeneratorProps {
  userId: string;
  courseId: string;
  onComplete: () => void;
}

interface CourseOption {
  cpa_course_id: string;
  display_name: string;
  is_blueprint_based: boolean;
}

// Define the custom input props interface
interface CustomInputProps {
  value?: string;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function StudyPlanGenerator({ userId, courseId, onComplete }: StudyPlanGeneratorProps) {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [studyType, setStudyType] = useState<'brand_new' | 'retake'>('brand_new');
  const [sectionsPassed, setSectionsPassed] = useState<number>(0);
  const [studyTypeSelected, setStudyTypeSelected] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [studyDays, setStudyDays] = useState({
    monday: true, tuesday: true, wednesday: true, thursday: true,
    friday: true, saturday: true, sunday: true
  });
  const [studyDayWarning, setStudyDayWarning] = useState<string | null>(null);
  const [isKeslerPrimary, setIsKeslerPrimary] = useState<boolean | null>(null);
  const [primaryCourse, setPrimaryCourse] = useState<string>('');
  const [secondaryCourse, setSecondaryCourse] = useState<string>('');
  const [courses, setCourses] = useState<CourseOption[]>([]);
  const [keslerCourseId, setKeslerCourseId] = useState<string>('');
  const [recommendedWeeklyHours, setRecommendedWeeklyHours] = useState< '15' | '20' | '25' | '30'>('20');
  const [bnMentalState, setBnMentalState] = useState<BrandNewMentalState | undefined>();
  const [ipMentalState, setIpMentalState] = useState<InProcessMentalState | undefined>();
  const [fMentalState, setFMentalState] = useState<FailedMentalState | undefined>();
  const [workHours, setWorkHours] = useState<WorkHours | undefined>();
  const [accountability, setAccountability] = useState<Accountability | undefined>();
  const [kids, setKids] = useState<boolean | undefined>();
  const [struggle, setStruggle] = useState<Struggle | undefined>();
  const [survivorShirt, setSurvivorShirt] = useState<ShirtSize | undefined>();
  const [alias, setAlias] = useState<string | null>(null);
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const [blueprintCourseId, setBlueprintCourseId] = useState<string>('');
  const [calculatedExamDate, setCalculatedExamDate] = useState<Date | null>(null);
  const [totalWeeks, setTotalWeeks] = useState<number>(0);
  const [totalStudyHours, setTotalStudyHours] = useState<number>(0);
  const [isCalculating, setIsCalculating] = useState(false);
  const [courseTypeSelected, setCourseTypeSelected] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function fetchCourses() {
      try {
        const cpaCourses = await api.getCPACourses(courseId);
        const courseOptions: CourseOption[] = cpaCourses.map(course => ({
          cpa_course_id: course.cpa_course_id,
          display_name: course.display_name,
          is_blueprint_based: course.is_blueprint_based
        }));
        setCourses(courseOptions);

        const keslerCourse = courseOptions.find(course => course.display_name.toLowerCase().includes('kesler'));
        if (keslerCourse) {
          setKeslerCourseId(keslerCourse.cpa_course_id);
          setPrimaryCourse(keslerCourse.cpa_course_id);
        }

        // Find and set the Blueprint course ID
        const blueprintCourse = courseOptions.find(course => course.is_blueprint_based);
        if (blueprintCourse) {
          setBlueprintCourseId(blueprintCourse.cpa_course_id);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    }
    fetchCourses();
  }, [courseId]);

  // Add effect to handle scrolling when step changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [step]);

  const CalendarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
  );

  // Updated CustomInput component with proper typing and handling
  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick, onChange }, ref) => (
      <div className="relative">
        <input
          value={value}
          onChange={onChange}
          onClick={onClick}
          ref={ref}
          className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
          readOnly
        />
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <CalendarIcon />
        </div>
      </div>
    )
  );

// Update the handleCourseSelection function
const handleCourseSelection = (selectedValue: string) => {
  const [courseId, displayName] = selectedValue.split(',');
  const selectedCourse = courses.find(course => course.cpa_course_id === courseId);

  if (selectedValue === 'none') {
    setAlias(null);
    if (isKeslerPrimary) {
      setSecondaryCourse('');
    } else {
      setPrimaryCourse('');
    }
    setSelectedCourseId('none');
  } else if (selectedCourse) {
    if (selectedCourse.is_blueprint_based) {
      setAlias(displayName.toLowerCase());
    } else {
      setAlias(null);
    }
    
    if (isKeslerPrimary) {
      setSecondaryCourse(courseId);
    } else {
      setPrimaryCourse(courseId);
    }
    setSelectedCourseId(courseId);
  } else {
    setSelectedCourseId('');
    setAlias(null);
    if (isKeslerPrimary) {
      setSecondaryCourse('');
    } else {
      setPrimaryCourse('');
    }
  }

  logger.debug('Current selection:', {
    courseId,
    displayName,
    alias: selectedCourse?.is_blueprint_based ? displayName.toLowerCase() : null,
    isKeslerPrimary,
    primaryCourse: isKeslerPrimary ? primaryCourse : courseId,
    secondaryCourse: isKeslerPrimary ? courseId : secondaryCourse,
  });
};

 // Updated handleDateChange function
 const handleDateChange = (date: Date | null) => {
  if (date) {
    const normalizedDate = new Date(date.setHours(0, 0, 0, 0));
    logger.debug('Start date selected:', normalizedDate);
    setStartDate(normalizedDate);
  }
};

const formatDateWithDay = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { 
    weekday: 'long', 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit' 
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const [weekday, monthDayYear] = formattedDate.split(', ');
  return `${weekday} (${monthDayYear})`;
};

const getNextMonday = (date: Date): Date => {
  const result = new Date(date);
  const day = result.getDay();
  if (day === 0) { // Sunday
    result.setDate(result.getDate() + 1);
  } else if (day === 2) { // Tuesday
    result.setDate(result.getDate() - 1);
  } else if (day > 2) { // Wednesday to Saturday
    result.setDate(result.getDate() + (8 - day));
  }
  return result;
};

const handleWeeklyHoursChange = (hours: '15' | '20' | '25' | '30') => {
  logger.debug('Setting weekly hours to:', hours);
  setRecommendedWeeklyHours(hours);
};

const handleStudyDayChange = (day: string) => {
  setStudyDays(prev => {
    const newStudyDays = { ...prev, [day]: !prev[day as keyof typeof studyDays] };
    const selectedDaysCount = Object.values(newStudyDays).filter(Boolean).length;

    if (selectedDaysCount < 3) {
      setStudyDayWarning("You must select at least 3 study days.");
      return prev; // Don't allow fewer than 3 days
    } else if (selectedDaysCount === 3) {
      setStudyDayWarning("It's difficult to get into a rhythm if you don't study at least every other day.");
    } else {
      setStudyDayWarning(null);
    }

    return newStudyDays;
  });
};

const calculateStudyPlan = async () => {
  // Normalize the selected date to beginning of day (for consistency)
  const normalizedStartDate = new Date(startDate);
  normalizedStartDate.setHours(0, 0, 0, 0);

  logger.debug('Calculating study plan with:', { 
    startDate: normalizedStartDate, 
    courseId, 
    recommendedWeeklyHours,
    studyType,
    bnMentalState,
    ipMentalState,
    fMentalState,
    workHours,
    accountability,
    kids,
    struggle,
    survivorShirt 
  });

  if (!startDate || !courseId) {
    console.error('Start date or course selection is missing');
    return;
  }

  try {
    // Create course mix first
    logger.debug('Creating course mix...');
    const courseMixResult = await api.createCourseMix(
      userId, 
      courseId, 
      isKeslerPrimary ? keslerCourseId : primaryCourse,
      isKeslerPrimary ? (secondaryCourse || null) : keslerCourseId,
      alias
    );
    logger.debug('Course mix created:', courseMixResult);

    // Create study type with all fields
    logger.debug('Creating study type...');
    const studyTypeRequest: CreateStudyTypeRequest = {
      userId,
      courseId,
      studyType,
      bnMentalState: bnMentalState || null,
      ipMentalState: ipMentalState || null,
      fMentalState: fMentalState || null,
      workHours: workHours || null,
      accountability: accountability || null,
      kids: kids || null,
      struggle: struggle || null,
      survivorShirt: survivorShirt || null
    };
    const studyTypeResult = await api.createStudyType(studyTypeRequest);
    logger.debug('Study type created:', studyTypeResult);

    logger.debug('Calculating study plan with start date:', {
      recommendedWeeklyHours,
      startDate: normalizedStartDate.toISOString()
    });
    
    const result = await api.calculateStudyPlanAndExamDate(
      userId,
      courseId,
      normalizedStartDate.toISOString(),
      studyType,
      recommendedWeeklyHours,
      courseMixResult.mix_id,
      studyTypeResult.id
    );

    logger.debug('Study plan calculation result:', result);
    
    const examDate = new Date(result.calculatedExamDate);
    const adjustedExamDate = getNextMonday(examDate);
    setCalculatedExamDate(adjustedExamDate);
    setTotalStudyHours(Math.ceil(result.totalStudyHours));
    setTotalWeeks(result.totalWeeks);

    return result;
  } catch (error) {
    console.error('Error calculating study plan:', error);
    throw error;
  }
};

const isNextButtonDisabled = () => {
  switch (step) {
    case 1: // First time vs Retake
      return !studyTypeSelected;
    
    case 2: // NTS/exam application status
      return false; // Simple yes/no navigation, no validation needed
    
    case 3: // Brand New Mental State
      return bnMentalState === undefined;
    
    case 4: // In Process Mental State
      return ipMentalState === undefined;
    
    case 5: // Failed Mental State
      return fMentalState === undefined;
    
    case 6: // Kesler Primary vs Supplement
      return !courseTypeSelected;
    
    case 7: // Course Selection
      return isKeslerPrimary 
        ? (secondaryCourse === '' && selectedCourseId !== 'none') 
        : primaryCourse === '';
    
    case 8: // Start Date Selection
      return startDate === null;
    
    case 9: // Weekly Hours Selection
      return recommendedWeeklyHours === null;
    
    case 10: // Study Days Selection
      return Object.values(studyDays).filter(Boolean).length < 3;
    
    case 11: // Work Hours
      return workHours === undefined;
    
    case 12: // Accountability
      return accountability === undefined;
    
    case 13: // Kids
      return kids === undefined;
    
    case 14: // Struggle
      return struggle === undefined;
    
    case 15: // Kesler Pledge
      return false; // Simple agreement, no validation needed
    
    case 16: // Shirt Size
      return survivorShirt === undefined;
    
    case 17: // Final Confirmation
      return false; // All validation already done
    
    default:
      return false;
  }
};


const handleSubmit = async () => {
  if (isSubmitting) return;
  
  setIsSubmitting(true);
  logger.debug('Submitting study plan...');
  logger.debug('Current state before submission:', {
    studyType,
    startDate,
    calculatedExamDate,
    studyDays,
    primaryCourse,
    secondaryCourse,
    alias,
    recommendedWeeklyHours,
    bnMentalState,
    ipMentalState,
    fMentalState,
    workHours,
    accountability,
    kids,
    struggle,
    survivorShirt
  });

  if (!startDate || !calculatedExamDate || !primaryCourse) {
    console.error('Missing required fields');
    setIsSubmitting(false);
    return;
  }

  try {
    logger.debug('Creating study type...');
    const studyTypeRequest: CreateStudyTypeRequest = {
      userId,
      courseId,
      studyType,
      bnMentalState: bnMentalState || null,
      ipMentalState: ipMentalState || null,
      fMentalState: fMentalState || null,
      workHours: workHours || null,
      accountability: accountability || null,
      kids: kids || null,
      struggle: struggle || null,
      survivorShirt: survivorShirt || null
    };
    const studyTypeResult = await api.createStudyType(studyTypeRequest);
    logger.debug('Study type created:', studyTypeResult);

    logger.debug('Creating course mix...');
    const courseMixResult = await api.createCourseMix(
      userId, 
      courseId, 
      isKeslerPrimary ? keslerCourseId : primaryCourse,
      isKeslerPrimary ? (secondaryCourse || null) : keslerCourseId,
      alias
    );
    logger.debug('Course mix created:', courseMixResult);

    logger.debug('Setting study days...');
    await api.setStudyDays(userId, courseId, studyDays);
    logger.debug('Study days set');

    logger.debug('Creating user study plan...');
    const userStudyPlan = await api.createUserStudyPlan(
      userId, 
      courseId, 
      courseMixResult.mix_id, 
      studyTypeResult.id,
      recommendedWeeklyHours
    );
    logger.debug('User study plan created:', userStudyPlan);

    logger.debug('Creating study plan items...');
    await api.createStudyPlanItems(userStudyPlan.plan_id, courseId, userId);
    logger.debug('Study plan items created');

    logger.debug('Study plan creation completed successfully');
    
    // Replace onComplete() with navigation
    logger.debug('Navigating to study plan with manifesto flag...');
    navigate(`/course/${courseId}/study-plan`, {
      state: { 
        fromGenerator: true,
        refreshStudyPlan: true // Add this to ensure the study plan data is refreshed
      },
      replace: true
    });
  } catch (error) {
    console.error('Error creating study plan:', error);
    setIsSubmitting(false);
    
    // Add error handling for navigation failure
    if (error instanceof Error) {
      logger.error('Failed to create study plan:', error.message);
    } else {
      logger.error('Failed to create study plan:', error);
    }
  }
};

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-8">
            {/* Sections Passed Selection */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                How many sections have you passed?
              </h3>
              <div className="grid grid-cols-4 gap-4">
                {[0, 1, 2, 3].map((num) => (
                  <button
                    key={num}
                    onClick={() => {
                      setSectionsPassed(num);
                      api.updateSectionsPassed(userId, num).catch((error) => {
                        console.error('Error updating sections passed:', error);
                      });
                    }}
                    className={`
                      p-4 rounded-lg border transition-all duration-200
                      hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20
                      ${sectionsPassed === num
                        ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30 shadow-sm'
                        : 'border-gray-200 dark:border-gray-700'
                      }
                    `}
                  >
                    <div className="flex flex-col items-center text-center space-y-2">
                      <Award 
                        className={`w-6 h-6 ${
                          sectionsPassed === num
                            ? 'text-blue-500 dark:text-blue-400'
                            : 'text-gray-600 dark:text-gray-400'
                        }`}
                      />
                      <div className={`text-sm font-medium ${
                        sectionsPassed === num
                          ? 'text-blue-700 dark:text-blue-300'
                          : 'text-gray-900 dark:text-gray-200'
                      }`}>
                        {num === 0 ? 'None' : `${num} ${num === 1 ? 'Section' : 'Sections'}`}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            <div className="border-t border-gray-200 dark:border-gray-700 pt-8">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Is this your first time sitting for this section or is this a retake?
                </h3>
                <div className="space-y-3">
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    By <strong>first time</strong> we typically mean, you have never sat for this section OR it has been longer than 9-12 months since you last sat.
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    By <strong>retake</strong>, we mean, did you recently fail this section with a score between 50-74 within the past 6 months. If any lower than that, we recommend you select first time.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                <button
                  onClick={() => {
                    if (studyType === 'brand_new' && studyTypeSelected) {
                      setStudyTypeSelected(false);
                    } else {
                      setStudyType('brand_new');
                      setStudyTypeSelected(true);
                    }
                  }}
                  className={`
                    p-6 rounded-lg border transition-all duration-200
                    hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20
                    ${studyType === 'brand_new' && studyTypeSelected
                      ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30 shadow-sm' 
                      : 'border-gray-200 dark:border-gray-700'
                    }
                  `}
                >
                  <div className="flex flex-col items-center text-center space-y-3">
                    <BookOpenCheck 
                      className={`w-8 h-8 ${
                        studyType === 'brand_new' && studyTypeSelected
                          ? 'text-blue-500 dark:text-blue-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }`}
                    />
                    <div>
                      <div className={`text-lg font-medium ${
                        studyType === 'brand_new' && studyTypeSelected
                          ? 'text-blue-700 dark:text-blue-300'
                          : 'text-gray-900 dark:text-gray-200'
                      }`}>
                        First Time
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                        Start your CPA journey
                      </div>
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => {
                    if (studyType === 'retake' && studyTypeSelected) {
                      setStudyTypeSelected(false);
                    } else {
                      setStudyType('retake');
                      setStudyTypeSelected(true);
                    }
                  }}
                  className={`
                    p-6 rounded-lg border transition-all duration-200
                    hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20
                    ${studyType === 'retake' && studyTypeSelected
                      ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30 shadow-sm'
                      : 'border-gray-200 dark:border-gray-700'
                    }
                  `}
                >
                  <div className="flex flex-col items-center text-center space-y-3">
                    <RotateCcw
                      className={`w-8 h-8 ${
                        studyType === 'retake' && studyTypeSelected
                          ? 'text-blue-500 dark:text-blue-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }`}
                    />
                    <div>
                      <div className={`text-lg font-medium ${
                        studyType === 'retake' && studyTypeSelected
                          ? 'text-blue-700 dark:text-blue-300'
                          : 'text-gray-900 dark:text-gray-200'
                      }`}>
                        Retake
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                        Try again with confidence
                      </div>
                    </div>
                  </div>
                </button>
              </div>

              <button 
                className={`
                  w-full mt-4 px-6 py-3 rounded-lg transition-colors
                  flex items-center justify-center space-x-2
                  ${!studyTypeSelected
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'bg-orange-500 text-white hover:bg-orange-600'
                  }
                `}
                onClick={() => {
                  if (studyType === 'brand_new') {
                    setStep(2);
                  } else if (studyType === 'retake') {
                    setStep(5);
                  }
                }}
                disabled={!studyTypeSelected}
              >
                <span>Continue</span>
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        );
        // New case for NTS/exam application status
        case 2:
        return (
          <div className="space-y-8">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                Have You Been Approved To Sit For The Exam?
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Your approval status helps us determine the optimal study timeline and intensity level for your preparation.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <button
                onClick={() => setStep(3)}
                className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
              >
                <div className="flex flex-col items-center text-center space-y-3">
                  <ClipboardCheck className="w-8 h-8 text-green-500 dark:text-green-400" />
                  <div>
                    <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                      Yes, I'm Approved
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                      I have my NTS or application approved
                    </div>
                  </div>
                </div>
              </button>

              <button
                onClick={() => setStep(4)}
                className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
              >
                <div className="flex flex-col items-center text-center space-y-3">
                  <Clock className="w-8 h-8 text-orange-500 dark:text-orange-400" />
                  <div>
                    <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                      No, Still Waiting
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                      My application is in process
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-8">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                How Would You Describe Your Feelings With The CPA Exam?
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Understanding your current mindset helps us optimize your study approach and provide the right level of support and motivation.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {[
                {
                  state: 'Positive' as const,
                  icon: Smile,
                  description: 'Ready to tackle the challenge'
                },
                {
                  state: 'Struggling' as const,
                  icon: Frown,
                  description: 'Finding it tough but determined'
                },
                {
                  state: 'Lost' as const,
                  icon: HelpCircle,
                  description: 'Need clear direction and guidance'
                }
              ].map(({ state, icon: Icon, description }) => (
                <button
                  key={state}
                  onClick={() => {
                    setBnMentalState(state);
                    setStep(6);
                  }}
                  className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                >
                  <div className="flex flex-col items-center text-center space-y-3">
                    <Icon className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                    <div>
                      <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                        {state}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                        {description}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-8">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                How Are You Feeling About Your CPA Journey So Far?
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Your current state of mind helps us tailor the perfect study strategy and provide the right motivational support.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {[
                {
                  state: 'Positive' as const,
                  icon: ThumbsUp,
                  description: 'Making good progress'
                },
                {
                  state: 'Struggling' as const,
                  icon: AlertCircle,
                  description: 'Need additional support'
                },
                {
                  state: 'Lost' as const,
                  icon: HelpCircle,
                  description: 'Looking for better direction'
                }
              ].map(({ state, icon: Icon, description }) => (
                <button
                  key={state}
                  onClick={() => {
                    setIpMentalState(state);
                    setStep(6);
                  }}
                  className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                >
                  <div className="flex flex-col items-center text-center space-y-3">
                    <Icon className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                    <div>
                      <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                        {state}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                        {description}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        );

      case 5:
        return (
          <div className="space-y-8">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                How Are You Feeling After Your Previous Attempt?
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Understanding your current mindset helps us create a recovery strategy that builds confidence and ensures success on your next attempt.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {[
                {
                  state: 'Good' as const,
                  icon: Battery,
                  description: 'Ready to try again'
                },
                {
                  state: 'Frustrated' as const,
                  icon: AlertCircle,
                  description: 'Need a fresh approach'
                },
                {
                  state: 'Hopeless' as const,
                  icon: HelpCircle,
                  description: 'Looking for renewed motivation'
                }
              ].map(({ state, icon: Icon, description }) => (
                <button
                  key={state}
                  onClick={() => {
                    setFMentalState(state);
                    setStep(6);
                  }}
                  className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                >
                  <div className="flex flex-col items-center text-center space-y-3">
                    <Icon className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                    <div>
                      <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                        {state}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                        {description}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        );
        case 6:
          return (
            <div className="space-y-8">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  How Would You Like To Use Kesler CPA Review?
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Choose how you want to integrate Kesler with your CPA exam preparation. This helps us customize your learning experience.
                </p>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <button
                  onClick={() => {
                    setIsKeslerPrimary(true);
                    setPrimaryCourse(keslerCourseId);
                    setSecondaryCourse('');
                    setAlias(null);
                    setCourseTypeSelected(true);
                    setStep(7);
                  }}
                  className={`
                    p-6 rounded-lg border transition-all duration-200
                    hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20
                    border-gray-200 dark:border-gray-700
                  `}
                >
                  <div className="flex flex-col items-center text-center space-y-4">
                    <BookOpen className="w-10 h-10 text-gray-600 dark:text-gray-400" />
                    <div>
                      <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                        Primary Course
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                        Use Kesler's course outline and use your Big Box Course as a supplemental resource
                      </div>
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => {
                    setIsKeslerPrimary(false);
                    setPrimaryCourse('');
                    setSecondaryCourse(keslerCourseId);
                    setAlias(null);
                    setCourseTypeSelected(true);
                    setStep(7);
                  }}
                  className={`
                    p-6 rounded-lg border transition-all duration-200
                    hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20
                    border-gray-200 dark:border-gray-700
                  `}
                >
                  <div className="flex flex-col items-center text-center space-y-4">
                    <BookPlus className="w-10 h-10 text-gray-600 dark:text-gray-400" />
                    <div>
                      <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                        Supplemental Resource
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                        Enhance your existing CPA review course with Kesler's additional support and supplements
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          );

        case 7:
          const currentCourseId = isKeslerPrimary ? secondaryCourse : primaryCourse;
          const currentCourse = courses.find(course => course.cpa_course_id === currentCourseId);
          let selectValue = "";
          
          if (selectedCourseId === 'none') {
            selectValue = "none";
          } else if (currentCourse) {
            if (currentCourse.is_blueprint_based && alias) {
              if (alias.toLowerCase() === 'uworld') {
                selectValue = `${currentCourseId},UWorld`;
              } else {
                selectValue = `${currentCourseId},${alias.charAt(0).toUpperCase() + alias.slice(1)}`;
              }
            } else {
              selectValue = `${currentCourseId},${currentCourse.display_name}`;
            }
          }

          return (
            <div className="space-y-8">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {isKeslerPrimary
                    ? "Select Your Additional Study Materials (Optional)"
                    : "Select Your Primary CPA Review Course"
                }
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {isKeslerPrimary
                    ? "While Kesler provides comprehensive mentorship and study supplements, we highly recommend you have a textbook and videos lectures available to reference with the Kesler supplements."
                    : "Choose your main CPA review course that you'll be using alongside Kesler's supplemental materials."
                  }
                </p>
              </div>

              <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-4">
                <div className="flex items-start space-x-3">
                  <Info className="w-5 h-5 text-blue-500 dark:text-blue-400 mt-1 flex-shrink-0" />
                  <p className="text-sm text-blue-700 dark:text-blue-300">
                    {isKeslerPrimary
                      ? "PRO TIP: Consider pairing Kesler's comprehensive curriculum with a big box textbook for additional practice questions!"
                      : "PRO TIP: Watch our mentorship modules first to learn how to effectively integrate Kesler with your chosen course!"
                    }
                  </p>
                </div>
              </div>

              <div className="space-y-6">
                <div className="relative">
                  <School className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 dark:text-gray-500" />
                  <select
                    value={selectValue}
                    onChange={(e) => handleCourseSelection(e.target.value)}
                    className="w-full pl-10 pr-4 py-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 appearance-none cursor-pointer transition-colors hover:border-blue-500 dark:hover:border-blue-400"
                  >
                    <option value="">Select a course</option>
                    {isKeslerPrimary && <option value="none">None - Using Kesler Only</option>}
                    {courses
                      .filter(course => course.cpa_course_id !== keslerCourseId)
                      .map(course => {
                        if (course.is_blueprint_based) {
                          return [
                            <option key={`${course.cpa_course_id}_surgent`} value={`${course.cpa_course_id},Surgent`}>
                              Surgent CPA Review
                            </option>,
                            <option key={`${course.cpa_course_id}_yaeger`} value={`${course.cpa_course_id},Yaeger`}>
                              Yaeger CPA Review
                            </option>,
                            <option key={`${course.cpa_course_id}_uworld`} value={`${course.cpa_course_id},UWorld`}>
                              UWorld CPA Review
                            </option>,
                            <option key={`${course.cpa_course_id}_other`} value={`${course.cpa_course_id},Other`}>
                              Other Blueprint Based Course
                            </option>
                          ];
                        } else {
                          return (
                            <option key={course.cpa_course_id} value={`${course.cpa_course_id},${course.display_name}`}>
                              {course.display_name} CPA Review
                            </option>
                          );
                        }
                      })}
                  </select>
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="flex space-x-4">
                <button 
                  className="w-1/2 px-6 py-3 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center justify-center gap-2" 
                  onClick={() => setStep(6)}
                >
                  <ArrowLeft className="w-4 h-4" />
                  <span>Back</span>
                </button>
                <button 
                  className={`w-1/2 px-6 py-3 rounded-lg transition-colors flex items-center justify-center gap-2 ${
                    isNextButtonDisabled()
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-orange-500 text-white hover:bg-orange-600'
                  }`}
                  onClick={() => setStep(8)}
                  disabled={isNextButtonDisabled()}
                >
                  <span>Continue</span>
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>
            </div>
          );
          case 8:
            const today = new Date();
            const yesterday = subDays(today, 1);
            const minDate = subDays(today, 30);
            const maxDate = addDays(today, 30);
            
            return (
              <div className="space-y-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                    When Will You Begin Your CPA Journey?
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Choose your study start date so we can create a personalized schedule that aligns with your goals.
                  </p>
                </div>

                <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 p-6 space-y-6">
                  <div className="flex items-center space-x-3">
                    <Calendar className="w-5 h-5 text-blue-500 dark:text-blue-400" />
                    <span className="font-medium text-gray-700 dark:text-gray-300">Select Start Date</span>
                  </div>

                  <div className="relative">
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      customInput={
                        <button className="w-full flex items-center justify-between px-4 py-3 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg text-left text-gray-900 dark:text-gray-100 hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors">
                          <span className="block truncate">
                            {startDate ? format(startDate, 'MMMM d, yyyy') : 'Select a date'}
                          </span>
                          <ChevronDown className="w-5 h-5 text-gray-400" />
                        </button>
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormat="MMMM d, yyyy"
                      isClearable={false}
                      shouldCloseOnSelect={true}
                      minDate={minDate}
                      maxDate={maxDate}
                      className="react-datepicker-tailwind"
                      wrapperClassName="w-full"
                      popperClassName="datepicker-popper"
                      calendarClassName="border-0 !font-sans"
                    />
                  </div>

                  {startDate && startDate < yesterday && (
                    <div className="p-3 bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded-lg">
                      <div className="flex items-start space-x-3">
                        <AlertCircle className="w-5 h-5 text-yellow-500 dark:text-yellow-400 mt-0.5 flex-shrink-0" />
                        <p className="text-sm text-yellow-700 dark:text-yellow-300">
                          You're starting mid-study. Some tasks will be marked as late, but don't worry! We recommend taking a few study loop quizzes to catch up.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex space-x-4">
                  <button 
                    className="w-1/2 px-6 py-3 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center justify-center gap-2" 
                    onClick={() => setStep(7)}
                  >
                    <ArrowLeft className="w-4 h-4" />
                    <span>Back</span>
                  </button>
                  <button 
                    className="w-1/2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center gap-2" 
                    onClick={() => {
                      logger.debug('Moving to next step, current start date:', startDate);
                      setStep(9);
                    }}
                  >
                    <span>Continue</span>
                    <ArrowRight className="w-4 h-4" />
                  </button>
                </div>
              </div>
            );

            case 9:
              const hoursOptions = [
                { hours: '15', label: 'Minimum', description: 'Balanced pace' },
                { hours: '20', label: 'Recommended', description: 'Ideal for most' },
                { hours: '25', label: 'Accelerated', description: 'Faster progress' },
                { hours: '30', label: 'Intensive', description: 'Maximum effort' }
              ];
            
              return (
                <div className="space-y-8">
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                      How Much Time Can You Dedicate Weekly?
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Choose your weekly study commitment. We'll customize your plan based on your available time.
                    </p>
                  </div>
            
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {hoursOptions.map(({ hours, label, description }) => (
                      <button
                        key={hours}
                        onClick={() => {
                          setRecommendedWeeklyHours(hours as '15' | '20' | '25' | '30');
                          setStep(10);
                        }}
                        className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                      >
                        <div className="flex flex-col items-center text-center space-y-3">
                          <Clock4 className="w-8 h-8 text-gray-600 dark:text-gray-400" />
                          <div>
                            <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                              {hours} Hours ({label})
                            </div>
                            <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                              {description}
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              );

            case 10:
              return (
                <div className="space-y-8">
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                      Select Your Study Days
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Choose which days you'll dedicate to studying. A consistent schedule helps build lasting habits.
                    </p>
                  </div>

                  <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-4">
                    <div className="flex items-start space-x-3">
                      <Info className="w-5 h-5 text-blue-500 dark:text-blue-400 mt-1 flex-shrink-0" />
                      <p className="text-sm text-blue-700 dark:text-blue-300">
                        PRO TIP: We recommend scheduling regular breaks for family and social time, but maintaining a daily study routine helps build momentum!
                      </p>
                    </div>
                  </div>

                  {studyDayWarning && (
                    <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded-lg p-4">
                      <div className="flex items-start space-x-3">
                        <AlertCircle className="w-5 h-5 text-yellow-500 dark:text-yellow-400 mt-1 flex-shrink-0" />
                        <p className="text-sm text-yellow-700 dark:text-yellow-300">
                          {studyDayWarning}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                    {Object.entries(studyDays).map(([day, isSelected]) => (
                      <label
                        key={day}
                        className={`
                          p-3 rounded-lg border transition-all duration-200 cursor-pointer
                          ${isSelected
                            ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30'
                            : 'border-gray-200 dark:border-gray-700 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20'
                          }
                        `}
                      >
                        <div className="flex items-center justify-between space-x-2">
                          <span className={`text-sm font-medium truncate ${
                            isSelected
                              ? 'text-blue-700 dark:text-blue-300'
                              : 'text-gray-700 dark:text-gray-300'
                          }`}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </span>
                          <CheckCircle2 
                            className={`w-4 h-4 flex-shrink-0 ${
                              isSelected
                                ? 'text-blue-500 dark:text-blue-400'
                                : 'text-gray-300 dark:text-gray-600'
                            }`}
                          />
                        </div>
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => handleStudyDayChange(day)}
                          className="hidden"
                        />
                      </label>
                    ))}
                  </div>

                  <div className="flex space-x-4">
                    <button 
                      className="w-1/2 px-6 py-3 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center justify-center gap-2" 
                      onClick={() => setStep(9)}
                    >
                      <ArrowLeft className="w-4 h-4" />
                      <span>Back</span>
                    </button>
                    <button 
                      className="w-1/2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center gap-2" 
                      onClick={() => setStep(11)}
                    >
                      <span>Continue</span>
                      <ArrowRight className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              );
              // New additional cases:
              case 11:
                const workSchedules = [
                  { hours: '40 Or Less Hours' as const, icon: Clock, label: 'Standard Schedule', description: 'Traditional work week' },
                  { hours: '40-50 Hours' as const, icon: Briefcase, label: 'Extended Hours', description: 'Busy professional' },
                  { hours: 'More Than 50' as const, icon: Users, label: 'Heavy Workload', description: 'Demanding career' },
                  { hours: 'Unemployed' as const, icon: User, label: 'Full Focus', description: 'Dedicated to studying' },
                  { hours: 'Student' as const, icon: GraduationCap, label: 'Student Life', description: 'Balancing academics' }
                ];

                return (
                  <div className="space-y-8">
                    <div className="space-y-4">
                      <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        Let's Talk About Your Work Schedule
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Understanding your work commitments helps us create a realistic study plan that fits your life. We'll adjust the intensity and timing of your studies accordingly.
                      </p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {workSchedules.map(({ hours, icon: Icon, label, description }) => (
                        <button
                          key={hours}
                          onClick={() => {
                            setWorkHours(hours);
                            setStep(12);
                          }}
                          className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                        >
                          <div className="flex flex-col items-center text-center space-y-3">
                            <Icon className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                            <div>
                              <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                                {label}
                              </div>
                              <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                {description}
                              </div>
                              <div className="text-xs text-blue-600 dark:text-blue-400 mt-2">
                                {hours}
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                );

              case 12:
                const accountabilityOptions = [
                  { person: 'Significant Other' as const, icon: Heart, description: 'Partner support system' },
                  { person: 'Co-Worker' as const, icon: Briefcase, description: 'Professional ally' },
                  { person: 'Family Member' as const, icon: Users, description: 'Family support network' },
                  { person: 'Friend' as const, icon: HeartHandshake, description: 'Trusted companion' },
                  { person: 'I don\'t have anyone' as const, icon: BadgeCheck, description: 'Self-motivated' }
                ];

                return (
                  <div className="space-y-8">
                    <div className="space-y-4">
                      <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        Building Your Support System
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Having someone to keep you accountable can increase your chances of success by up to 95%. Let's identify your accountability partner for this journey.
                      </p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {accountabilityOptions.map(({ person, icon: Icon, description }) => (
                        <button
                          key={person}
                          onClick={() => {
                            setAccountability(person);
                            setStep(13);
                          }}
                          className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                        >
                          <div className="flex flex-col items-center text-center space-y-3">
                            <Icon className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                            <div>
                              <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                                {person}
                              </div>
                              <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                {description}
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                );

                case 13:
                  return (
                    <div className="space-y-8">
                      <div className="space-y-4">
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                          Understanding Your Family Commitments
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          Having children adds unique considerations to your study schedule. We'll help you balance family time with effective study sessions.
                        </p>
                      </div>
                
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <button
                          onClick={() => {
                            setKids(true);
                            setStep(14);
                          }}
                          className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                        >
                          <div className="flex flex-col items-center text-center space-y-3">
                            <Baby className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                            <div>
                              <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                                Yes, I Have Kids
                              </div>
                              <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                We'll help you balance family and studies
                              </div>
                            </div>
                          </div>
                        </button>
                
                        <button
                          onClick={() => {
                            setKids(false);
                            setStep(14);
                          }}
                          className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                        >
                          <div className="flex flex-col items-center text-center space-y-3">
                            <User className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                            <div>
                              <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                                No Kids
                              </div>
                              <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                More flexibility in scheduling
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  );

                case 14:
                  const struggles = [
                    { type: 'studying' as const, icon: BookOpen, label: 'Study Techniques', description: 'Need help with effective study methods' },
                    { type: 'juggling' as const, icon: Scale, label: 'Life Balance', description: 'Managing multiple responsibilities' },
                    { type: 'motivation' as const, icon: Battery, label: 'Staying Motivated', description: 'Maintaining momentum and drive' },
                    { type: 'recovering from failure' as const, icon: Rocket, label: 'Bouncing Back', description: 'Overcoming past CPA exam section failures' },
                    { type: 'time' as const, icon: Clock, label: 'Time Management', description: 'Finding time to study effectively' },
                    { type: 'getting started' as const, icon: Lightbulb, label: 'Taking First Steps', description: 'Struggling with signing up and starting the journey' }
                  ];
          
                  return (
                    <div className="space-y-8">
                      <div className="space-y-4">
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                          What's Your Biggest Challenge?
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          By understanding your main struggle, we can provide targeted strategies and support to help you overcome it. Everyone faces challenges - let's tackle yours together.
                        </p>
                      </div>
          
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {struggles.map(({ type, icon: Icon, label, description }) => (
                          <button
                            key={type}
                            onClick={() => {
                              setStruggle(type);
                              setStep(15);
                            }}
                            className="p-6 rounded-lg border transition-all duration-200 hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 border-gray-200 dark:border-gray-700"
                          >
                            <div className="flex flex-col items-center text-center space-y-3">
                              <Icon className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                              <div>
                                <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                                  {label}
                                </div>
                                <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                  {description}
                                </div>
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  );
          
                  case 15:
                    const pledgeItems = [
                      "I will start by learning HOW to pass the CPA exam through proven study methods and strategies.",
                      "I understand that success comes from both understanding the content and using effective study techniques.",
                      "I commit to finding an accountability partner or group to help keep me on track.",
                      "I will focus on mastering the AICPA Blueprints using structured study methods.",
                      "I will minimize distractions and seek support from a trusted CPA candidate community.",
                      "I will reach out for help when I'm stuck instead of struggling alone.",
                      "I commit to maintaining a positive mindset throughout my CPA journey!"
                    ];
                  
                    const shirtSizes = [
                      { size: 'small' as const, label: 'Small', fit: 'Fitted cut' },
                      { size: 'medium' as const, label: 'Medium', fit: 'Standard fit' },
                      { size: 'large' as const, label: 'Large', fit: 'Relaxed fit' },
                      { size: 'x-large' as const, label: 'X-Large', fit: 'Generous fit' },
                      { size: '2x-large' as const, label: '2X-Large', fit: 'Extra generous fit' }
                    ];
                  
                    const handleCalculateAndProceed = async (size: ShirtSize) => {
                      setSurvivorShirt(size);
                      setIsCalculating(true);
                      
                      try {
                        const result = await calculateStudyPlan();
                        if (result) {
                          setStep(16);
                        } else {
                          console.error('Failed to calculate study plan');
                        }
                      } catch (error) {
                        console.error('Error calculating study plan:', error);
                      } finally {
                        setIsCalculating(false);
                      }
                    };
                  
                    if (isCalculating) {
                      return (
                        <div className="flex flex-col items-center justify-center space-y-4 p-8">
                          <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                          <p className="text-lg text-gray-700 dark:text-gray-300">
                            Calculating your personalized study plan...
                          </p>
                        </div>
                      );
                    }
                  
                    return (
                      <div className="space-y-8">
                        <div className="space-y-4">
                          <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                            How Serious Are You About Passing the CPA Exam?
                          </h3>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            Take the pledge to join thousands of successful candidates who mastered the CPA exam using the Kesler Study Methods & Supplements.
                          </p>
                        </div>
                  
                        <div className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900/30 dark:to-indigo-900/30 rounded-lg overflow-hidden border border-blue-200 dark:border-blue-800">
                          <div className="w-full relative">
                            <img 
                              src="https://jvidoqtewkukebhpabuv.supabase.co/storage/v1/object/public/App-Images//CPA%20Surv%20Crew%20compressed.jpg"
                              alt="CPA Survivor Crew"
                              className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-blue-50/50 to-transparent dark:from-gray-900/50" />
                          </div>
                  
                          <div className="p-8">
                            <div className="flex flex-col items-center space-y-6">
                              <Trophy className="w-16 h-16 text-blue-500 dark:text-blue-400" />
                              <div className="space-y-6 w-full">
                                <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 text-center">
                                  The CPA Survivor's Pledge
                                </h4>
                                <div className="space-y-3">
                                  {pledgeItems.map((item, index) => (
                                    <div 
                                      key={index}
                                      className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                                    >
                                      <CheckCircle2 className="w-5 h-5 text-blue-500 dark:text-blue-400 mt-0.5 flex-shrink-0" />
                                      <p className="text-sm leading-relaxed">{item}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                  
                              <div className="w-full space-y-6">
                                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                                  <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">
                                    If You Agree... Accept the Pledge by Reserving Your CPA Survivor Shirt Below!*
                                  </h4>
                                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
                                    FUN FACT: Your CPA Survivor shirt isn't just clothing - it's a symbol of your commitment and a reminder of your goal. Select your size and <strong>when you pass using Kesler CPA Review</strong> it will all be yours.
                                  </p>
                  
                                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                                    {shirtSizes.map(({ size, label, fit }) => (
                                      <button
                                        key={size}
                                        onClick={() => handleCalculateAndProceed(size)}
                                        disabled={isCalculating}
                                        className={`
                                          p-6 rounded-lg border transition-all duration-200 
                                          ${isCalculating 
                                            ? 'opacity-50 cursor-not-allowed' 
                                            : 'hover:border-blue-500/50 hover:bg-blue-50/50 dark:hover:bg-blue-900/20'
                                          }
                                          border-gray-200 dark:border-gray-700
                                        `}
                                      >
                                        <div className="flex flex-col items-center text-center space-y-3">
                                          <Shirt className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                                          <div>
                                            <div className="text-lg font-medium text-gray-900 dark:text-gray-200">
                                              {label}
                                            </div>
                                            <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                              {fit}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    ))}
                                    
                                  </div>
                                  
                                  <p className="text-xs text-gray-500 dark:text-gray-400 italic my-4">
                                    *We will reserve your shirt even if you aren't a paid Kesler CPA Review member yet, but to qualify you must use the paid version of Kesler CPA Review to pass your final CPA 
                                    section to be eligible to claim your shirt.
                                  </p>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );

                    case 16:
                      return (
                        <div className="space-y-8">
                          <div className="space-y-4">
                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                              Your Personalized CPA Success Blueprint
                            </h3>
                            <p className="text-m text-gray-600 dark:text-gray-300">
                              We've crafted a customized study plan based on your unique situation. Review your details below to ensure everything looks perfect.
                            </p>
                          </div>
                    
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 border border-gray-200 dark:border-gray-700 shadow-sm">
                              <div className="flex items-center space-x-3 mb-4">
                                <Clock className="w-6 h-6 text-blue-500 dark:text-blue-400" />
                                <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100">Weekly Commitment</h4>
                              </div>
                              <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-400">Study Time:</span>
                                  <span className="font-semibold text-gray-900 dark:text-gray-100">{recommendedWeeklyHours} hrs/week</span>
                                </div>
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-400">Total Hours:</span>
                                  <span className="font-semibold text-gray-900 dark:text-gray-100">{totalStudyHours} hours</span>
                                </div>
                              </div>
                            </div>
                    
                            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 border border-gray-200 dark:border-gray-700 shadow-sm">
                              <div className="flex items-center space-x-3 mb-4">
                                <Calendar className="w-6 h-6 text-blue-500 dark:text-blue-400" />
                                <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100">Timeline</h4>
                              </div>
                              <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-400">Duration:</span>
                                  <span className="font-semibold text-gray-900 dark:text-gray-100">{totalWeeks}+ weeks</span>
                                </div>
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-400">Start Date:</span>
                                  <span className="font-semibold text-gray-900 dark:text-gray-100">{formatDateWithDay(startDate)}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-400">Target Exam:</span>
                                  <span className="font-semibold text-gray-900 dark:text-gray-100">
                                    {calculatedExamDate && formatDateWithDay(calculatedExamDate)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                          <div className="bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 rounded-lg p-6 border border-blue-200 dark:border-blue-800">
                            <div className="flex items-start space-x-4">
                              <div className="flex-shrink-0">
                                <LightbulbIcon className="w-6 h-6 text-blue-500 dark:text-blue-400" />
                              </div>
                              <div className="space-y-2">
                                <h4 className="font-semibold text-gray-900 dark:text-gray-100">Strategic Exam Tips</h4>
                                <p className="text-gray-700 dark:text-gray-300">
                                  Schedule your exam for a Monday to maximize your final preparation. This gives you a full weekend for focused review and ensures you're fresh for the exam.
                                </p>
                              </div>
                            </div>
                          </div>
                    
                          <div className="bg-green-50 dark:bg-green-900/20 rounded-lg p-6 border border-green-200 dark:border-green-800">
                            <div className="flex items-start space-x-4">
                              <div className="flex-shrink-0">
                                <Trophy className="w-6 h-6 text-green-500 dark:text-green-400" />
                              </div>
                              <div className="space-y-2">
                                <h4 className="font-semibold text-gray-900 dark:text-gray-100">Ready For Success</h4>
                                <p className="text-gray-700 dark:text-gray-300">
                                  Your personalized study plan is tailored to your schedule and learning style. Follow it consistently, and you'll be well-prepared for exam day.
                                </p>
                              </div>
                            </div>
                          </div>
                    
                          <div className="relative mt-12">
                            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                              <div className="animate-bounce">
                                <ArrowDown className="w-8 h-8 text-orange-500" />
                              </div>
                            </div>
                            
                            <div className="bg-orange-50 dark:bg-orange-900/20 rounded-lg p-6 border-2 border-orange-200 dark:border-orange-800">
                              <button 
                                className={`
                                  w-full px-8 py-6 bg-orange-500 text-white rounded-lg 
                                  transition-all duration-200 transform
                                  text-xl font-semibold
                                  shadow-lg hover:shadow-xl
                                  ${isSubmitting 
                                    ? 'opacity-75 cursor-not-allowed' 
                                    : 'hover:bg-orange-600 hover:-translate-y-0.5'
                                  }
                                `}
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <div className="flex items-center justify-center gap-3">
                                    <Loader2 className="w-7 h-7 animate-spin" />
                                    <span>Creating Your Personalized Study Plan...</span>
                                  </div>
                                ) : (
                                  <div className="flex items-center justify-center gap-3">
                                    <CheckCircle2 className="w-7 h-7" />
                                    <span>Click Here to Create My Study Plan!</span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    
                    default:
                      return null;
                }
              };

    return (
      <div 
        ref={containerRef}
        className="flex justify-center w-full p-4 pt-4"
      >
        <div className="study-plan-generator w-full max-w-[700px] bg-white dark:bg-gray-800 rounded-xl shadow-lg">
          <div className="p-8">
            <h2 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-gray-100">
              Create New Study Plan
            </h2>
            {renderStep()}
          </div>
        </div>
      </div>
    );
  }

export default StudyPlanGenerator;