import React, { useState, useEffect, useCallback } from 'react';
import { api, TIER_IDS, Lesson as APILesson } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ChevronLeft, MessageCircle, Crown } from 'lucide-react';
import LoadingScreen from './common/LoadingScreen';
import FeedbackPopup from './quiz-tools/FeedbackPopup';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { Button } from '../ui/Button';
import LessonNotes from './LessonNotes';

interface LessonPreviewProps {
  lessonId: string;
  courseId: string;
  userId: string;
  previewContent?: {
    content?: string;
  };
}

// Extend the API Lesson type with the additional fields from getLessons
interface LessonWithProgress extends APILesson {
  is_completed: boolean;
  confidence_level: string | null;
}

const LessonPreview: React.FC<LessonPreviewProps> = ({ 
  lessonId, 
  courseId, 
  userId,
  previewContent 
}) => {
  const navigate = useNavigate();
  const [lesson, setLesson] = useState<LessonWithProgress | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [unsavedNote, setUnsavedNote] = useState<string>('');
  const { userCourseAccesses, isLoading: accessLoading } = useUserCourseAccess();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  // Process URLs to include courseId
  const processUrls = useCallback((content: string) => {
    return content.replace(
      /https:\/\/app\.keslercpareview\.com\/course\/{{courseId}}/g, 
      `https://app.keslercpareview.com/course/${courseId}`
    );
  }, [courseId]);

  // Fetch lesson data
  useEffect(() => {
    const fetchLessonData = async () => {
      try {
        if (previewContent) {
          // If preview content is provided, merge it with existing lesson data
          const lessons = await api.getLessons(courseId, userId);
          const currentLesson = lessons.find(l => l.id === lessonId);
          
          if (!currentLesson) {
            throw new Error('Lesson not found');
          }

          setLesson({
            ...currentLesson,
            content: previewContent.content || currentLesson.content
          });
        } else {
          const lessons = await api.getLessons(courseId, userId);
          const currentLesson = lessons.find(l => l.id === lessonId);
          
          if (!currentLesson) {
            throw new Error('Lesson not found');
          }

          setLesson(currentLesson);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error loading lesson:', err);
        setError('Failed to load lesson data');
        setLoading(false);
      }
    };

    fetchLessonData();
  }, [lessonId, courseId, userId, previewContent]);

  // Check access restrictions
  const isContentLocked = useCallback(() => {
    // If we're in preview mode, content should never be locked
    if (previewContent) return false;

    if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return true;

    const hasFullAccess = courseAccesses.some(access => 
      access.tier_id === TIER_IDS.FULL_ACCESS || 
      access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
    );

    if (hasFullAccess) return false;

    const hasMentorAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.MENTOR_ONLY);
    const hasStudyMaterialsAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY);
    
    if (hasMentorAccess && hasStudyMaterialsAccess) return false;

    if (!lesson) return true;
    return !lesson.free_trial_content;
  }, [userCourseAccesses, courseId, lesson, previewContent]);

  const handleBack = () => {
    navigate(`/course/${courseId}/lessons`);
  };

  const handleNoteChange = (content: string) => {
    setUnsavedNote(content);
  };

  const handleNoteSave = async (content: string) => {
    if (!lesson) return;
    try {
      await api.updateNote(null, { content }, userId, courseId, null, lesson.id, null, null);
      setUnsavedNote('');
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };

  const handleUpgradeClick = () => {
    setIsUpgradeModalOpen(true);
  };

  if (loading || accessLoading) {
    return <LoadingScreen message="Loading lesson..." />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!lesson) {
    return <div>Lesson not found.</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {/* Navigation */}
      <div className="flex justify-between items-center">
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
        >
          <ChevronLeft size={20} />
          <span>Back to Lessons</span>
        </button>
      </div>

      {/* Lesson Header */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h1 className="text-xl font-bold text-center mb-2">
          {lesson.name}
        </h1>
        {lesson.lesson_category_name && (
          <h2 className="text-sm text-gray-600 dark:text-gray-400 text-center">
            {lesson.lesson_category_name}
          </h2>
        )}
        {!previewContent && lesson.is_completed && lesson.confidence_level && (
          <div className="mt-4 text-sm text-center">
            <span className={`capitalize ${
              lesson.confidence_level === 'confident' ? 'text-green-600 dark:text-green-400' :
              lesson.confidence_level === 'maybe' ? 'text-yellow-600 dark:text-yellow-400' :
              'text-red-600 dark:text-red-400'
            }`}>
              Confidence Level: {lesson.confidence_level}
            </span>
          </div>
        )}
      </div>

      {/* Lesson Content */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md relative">
        <div className={`prose dark:prose-invert max-w-none ${isContentLocked() ? 'blur-sm' : ''}`}>
          {isContentLocked() ? (
            <div className="text-center">
              <h3>This content is locked</h3>
              <p>Upgrade your account to access this lesson.</p>
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ 
              __html: DOMPurify.sanitize(processUrls(lesson.content || '')) 
            }} />
          )}
        </div>
        {isContentLocked() && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-lg">
            <div className="text-center text-white p-6">
              <h3 className="text-xl font-bold mb-4">Content is locked</h3>
              <p className="mb-6">Upgrade your account to access this lesson.</p>
              <Button
                onClick={handleUpgradeClick}
                className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center"
              >
                <Crown size={16} className="mr-2" />
                Upgrade Now
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Notes Section - Only show if not in preview mode */}
      {!previewContent && !isContentLocked() && lesson.lesson_category_id && (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <LessonNotes
            courseId={courseId}
            userId={userId}
            lessonId={lesson.id}
            lessonName={lesson.name}
            lessonCategory={{
              id: lesson.lesson_category_id,
              course_id: courseId,
              name: lesson.lesson_category_name || '',
              parent_lesson_category_id: null,
              category_number: -1
            }}
            unsavedNote={unsavedNote}
            onNoteChange={handleNoteChange}
            onNoteSave={handleNoteSave}
          />
        </div>
      )}

      {/* Feedback Button - Only show if not in preview mode */}
      {!previewContent && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => setShowFeedbackPopup(true)}
            className="flex items-center text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors"
          >
            <MessageCircle className="w-4 h-4 mr-1" />
            Report an Issue
          </button>
        </div>
      )}

      {/* Feedback Popup */}
      <FeedbackPopup
        isOpen={showFeedbackPopup}
        onClose={() => setShowFeedbackPopup(false)}
        contentId={lessonId}
        contentType="lessons"
        contentTitle={`${lesson.lesson_category_name || ''} - ${lesson.name}`}
      />
    </div>
  );
};

export default LessonPreview;