import React from 'react';
import { useSafeArea, useDeviceDetection } from '../../hooks/useMobile';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const safeArea = useSafeArea();
  const deviceInfo = useDeviceDetection();

  // Calculate footer padding, accounting for bottom navigation on mobile
  const bottomPadding = deviceInfo.isMobile ? Math.max(safeArea.bottom + 64, 80) : safeArea.bottom;
  const horizontalPadding = Math.max(safeArea.left, safeArea.right);

  return (
    <footer 
      className="bg-white dark:bg-gray-800 border-t border-gray-100 dark:border-gray-700 mt-10"
      style={{
        paddingBottom: `max(${bottomPadding}px, env(safe-area-inset-bottom))`,
        paddingLeft: `max(${horizontalPadding}px, env(safe-area-inset-left))`,
        paddingRight: `max(${horizontalPadding}px, env(safe-area-inset-right))`,
      }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <div className="flex flex-col sm:flex-row items-center gap-4">
            {/* Links Section */}
            <nav className="flex gap-4 text-sm text-gray-500 dark:text-gray-400">
              <a 
                href="https://www.keslercpa.com/privacy" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
              >
                Privacy Policy
              </a>
              <a 
                href="https://www.keslercpa.com/terms" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
              >
                Terms of Service
              </a>
            </nav>
          </div>

          {/* Copyright */}
          <div className="text-sm text-gray-500 dark:text-gray-400">
            <span className="whitespace-nowrap">
              Kesler CPA Review © {currentYear}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;