import React, { createContext, useContext, useCallback, useRef } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { api } from '../services/api';
import type { Product as ApiProduct } from '../services/api';
import { useAuth } from './AuthProvider';

// IMPORTANT: Use the SAME queryClient from services/queryClient
import { queryClient } from '../services/queryClient';

type Product = ApiProduct;

interface ProductContextType {
  products: { [id: string]: Product };
  loading: boolean;
  error: unknown;
  refreshProducts: () => void;
  getProduct: (id: string) => Promise<Product | null>;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export function ProductProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const courseCache = useRef<{ [id: string]: Product | null }>({});

  // Reusable fetch function
  const fetchProducts = useCallback(async () => {
    const products = await api.getProducts();
    products.forEach(product => {
      courseCache.current[product.id] = product;
    });
    return products.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {} as { [id: string]: Product });
  }, []);

  // Just one cache key. Include user id or "anonymous"
  const cacheKey = `products_${user?.id || 'anonymous'}`;

  // We use `useQuery` from the single, global QueryClient
  const {
    data: products = {},
    isLoading,
    error,
    refetch
  }: UseQueryResult<{ [id: string]: Product }, unknown> = useQuery(
    cacheKey,
    fetchProducts,
    {
      // You can still do an initialData from localStorage if you like
      initialData: () => {
        const cachedProducts = localStorage.getItem(cacheKey);
        return cachedProducts ? JSON.parse(cachedProducts) : {};
      },
      onSuccess: (data) => {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      }
    }
  );

  // Helper to get a single product
  const getProduct = useCallback(
    async (id: string): Promise<Product | null> => {
      if (courseCache.current[id]) {
        return courseCache.current[id];
      }
      try {
        const product = await queryClient.fetchQuery(
          ['course', id],
          () => api.getCourse(id),
          {
            staleTime: 60 * 60 * 1000 // 1 hour
          }
        );
        if (product) {
          courseCache.current[id] = product;
          // Also update "products" in the query cache
          queryClient.setQueryData<{ [id: string]: Product } | undefined>(
            cacheKey,
            (oldData) => {
              if (oldData) {
                return { ...oldData, [product.id]: product };
              }
              return { [product.id]: product };
            }
          );
        }
        return product;
      } catch (err) {
        console.error('Failed to fetch the product:', err);
        return null;
      }
    },
    [cacheKey]
  );

  const value: ProductContextType = {
    products,
    loading: isLoading,
    error,
    refreshProducts: refetch,
    getProduct
  };

  return (
    // We REMOVE any extra QueryClientProvider here. We just return the context.
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  );
}

export function useProducts(): ProductContextType {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductProvider');
  }
  return context;
}
