import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';

// IMPORTANT: Use the SINGLE shared queryClient from services/queryClient
import { queryClient } from './services/queryClient';

import { AuthProvider, useAuth } from './components/AuthProvider';
import { ProductProvider } from './components/ProductProvider';
import { UserCourseAccessProvider } from './components/UserCourseAccessProvider';
import { ThemeProvider } from './contexts/ThemeContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ThriveCartProvider } from './contexts/ThriveCartContext';
import { TextSelectionProvider } from './contexts/TextSelectionContext';
import {
  MockExamTimerProvider,
  SimulationTimerProvider,
  QuizTimerProvider
} from './contexts/TimerContext';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Products from './pages/Products';
import Course from './pages/Course';
import Profile from './pages/Profile';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Logout from './components/auth/Logout';
import ForgotPassword from './components/auth/ForgotPassword';
import VerifyEmail from './components/auth/VerifyEmail';
import ResetPassword from './components/auth/ResetPassword';
import DigitalProducts from './pages/DigitalProducts';
import SupportDashboard from './components/admin/support/SupportDashboard';
import TicketComposer from './components/admin/user/TicketComposer';
import UserTicketDashboard from './components/admin/user/UserTicketDashboard';
import FeedbackDashboard from './components/admin/feedback/FeedbackDashboard';
import FeedbackDetail from './components/admin/feedback/FeedbackDetail';
import TextSelectionPopup from './components/course/PiracyProtection/TextSelectionPopup';
import { createLogger } from './utils/Logger';

const logger = createLogger({ namespace: 'app.tsx' });

// Provide modal context for your app
export const ModalContext = React.createContext<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isModalOpen: false,
  setIsModalOpen: () => {},
});

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: 'admin' | 'moderator' | 'user';
}

// Reusable protected route logic
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();
  
  if (loading) return <div>Loading...</div>;
  
  // If no user, redirect to login quietly (no full reload)
  if (!user) return <Navigate to="/login" replace />;
  
  // If a role is required, check that the user’s role is allowed
  if (requiredRole) {
    if (requiredRole === 'moderator' && (user.role === 'admin' || user.role === 'moderator')) {
      return <>{children}</>;
    }
    if (user.role !== requiredRole) {
      return <Navigate to="/products" replace />;
    }
  }
  
  return <>{children}</>;
};

// Public routes for non-auth users
const PublicOnlyRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <div>Loading...</div>;
  if (user) return <Navigate to="/products" replace />;
  return <>{children}</>;
};

// Listens for user changes to reset query cache if user logs out
function AuthListener() {
  const { user } = useAuth();
  const prevUserRef = useRef(user);

  useEffect(() => {
    // Only clear queries on an actual logout (not initial page load)
    if (prevUserRef.current && !user) {
      // Clear all queries but do not forcibly refresh the page
      queryClient.resetQueries(undefined, {
        active: false
      });
      queryClient.clear();
    }
    prevUserRef.current = user;
  }, [user]);

  return null;
}

// Example sub-route for flashcards (just a placeholder)
function FlashcardRoutes() {
  return <Course />;
}

// Create a specialized route wrapper for ResetPassword
const ResetPasswordRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) return <div>Loading...</div>;

  // Allow access if:
  // 1. User is not authenticated (normal reset flow)
  // 2. User has temp password (from login flow)
  // 3. User came from the proper state (location.state.fromTempPassword)
  if (user && !location.state?.fromTempPassword) {
    return <Navigate to="/products" replace />;
  }

  return <>{children}</>;
};

function AppRoutes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    logger.debug('AppRoutes: Current path:', location.pathname);
  }, [location.pathname]);

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      <Layout>
        <AuthListener />
        <Routes>
          {/* Redirect root to products if logged in, otherwise to login */}
          <Route
            path="/"
            element={
              user ? <Navigate to="/products" replace /> : <Navigate to="/login" replace />
            }
          />

          {/* Product and User Support Routes */}
          <Route
            path="/products"
            element={
              <ProtectedRoute>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            path="/digital-products/:productId"
            element={
              <ProtectedRoute>
                <DigitalProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/support"
            element={
              <ProtectedRoute>
                <UserTicketDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/support/new"
            element={
              <ProtectedRoute>
                <TicketComposer onBack={() => window.history.back()} />
              </ProtectedRoute>
            }
          />
          
          {/* Admin / Moderator Routes */}
          <Route
            path="/admin/support"
            element={
              <ProtectedRoute requiredRole="admin">
                <SupportDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/support/ticket/:ticketId"
            element={
              <ProtectedRoute requiredRole="admin">
                <SupportDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/feedback"
            element={
              <ProtectedRoute requiredRole="moderator">
                <FeedbackDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/feedback/:ticketId"
            element={
              <ProtectedRoute requiredRole="moderator">
                <FeedbackDetail />
              </ProtectedRoute>
            }
          />

          {/* Course Routes */}
          <Route
            path="course/:courseId/*"
            element={
              <ProtectedRoute>
                <FlashcardRoutes />
              </ProtectedRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          {/* Auth Routes */}
          <Route
            path="/login"
            element={
              <PublicOnlyRoute>
                <Login />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicOnlyRoute>
                <Register />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicOnlyRoute>
                <ForgotPassword />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <ResetPasswordRoute>
                <ResetPassword />
              </ResetPasswordRoute>
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Layout>
    </ModalContext.Provider>
  );
}

// Now we only have ONE QueryClientProvider at this top-level
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <UserCourseAccessProvider>
            <ProductProvider>
              <ThemeProvider>
                <NotificationProvider>
                  <ThriveCartProvider>
                    <TextSelectionProvider>
                      <MockExamTimerProvider>
                        <SimulationTimerProvider>
                          <QuizTimerProvider>
                            <AppRoutes />
                          </QuizTimerProvider>
                        </SimulationTimerProvider>
                      </MockExamTimerProvider>
                    </TextSelectionProvider>
                  </ThriveCartProvider>
                </NotificationProvider>
              </ThemeProvider>
            </ProductProvider>
          </UserCourseAccessProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;