import { useQuery, UseQueryResult, QueryClient } from 'react-query';
import { 
  api,
  User, 
  SPUserStudyPlan, 
  SPStudyPlanItem, 
  SPCourse, 
  SPCourseMix, 
  SPStudySchedule,
  SPStudyType,
  SPStudyDays,
  SPExamDate
} from '../services/api';
import { createLogger } from '../utils/Logger';

const logger = createLogger({ namespace: 'usestudyplan hook' });

// Add interface for the exam date response
interface ExamDateResponse {
  exam_date_id: string;
  user_id: string;
  course_id: string;
  exam_date: string;
  start_date: string;
  created_at: string;
  updated_at: string;
}

export interface StudyPlanData {
  userStudyPlan: SPUserStudyPlan | null;
  studyPlanItems: SPStudyPlanItem[];
  examDate: SPExamDate | null;
  startDate: string | null;
  endDate: string | null;
  courseProviders: { [key: string]: string };
  primaryCourse: SPCourse | null;
  secondaryCourse: SPCourse | null;
  courseMix: SPCourseMix | null;
  studySchedule: SPStudySchedule | null;
  studyType: SPStudyType | null;
  studyDays: SPStudyDays | null;
  courses: SPCourse[];
  currentSection: string;
  user: User;  // Add this line
}

export interface StudyPlanError {
  message: string;
}

const fetchStudyPlanData = async (userId: string, courseId: string): Promise<StudyPlanData> => {
  try {
    // First get the user study plan and user data
    const [plan, user] = await Promise.all([
      api.getUserStudyPlan(userId, courseId),
      api.getUser(userId)
    ]);
    
    logger.debug('Fetched user study plan:', plan);
    
    if (!plan || !user) {
      return {
        userStudyPlan: null,
        studyPlanItems: [],
        examDate: null,
        startDate: null,
        endDate: null,
        courseProviders: {},
        primaryCourse: null,
        secondaryCourse: null,
        courseMix: null,
        studySchedule: null,
        studyType: null,
        studyDays: null,
        courses: [],
        currentSection: 'Study Plan',
        user: user || {  // Provide fallback user data
          id: userId,
          email: '',
          full_name: null,
          created_at: null,
          last_sign_in_at: null,
          role: 'user',
          payment_id: null,
          updated_at: null,
          switches_available: 0,
          last_switch_date: null,
          last_switch_replenish_date: null,
          sections_passed: 0  // Add the sections_passed property with default value
        }
      };
    }

    // Get course mix first since we need it for the items
    const courseMix = await api.getCourseMix(userId, courseId);
    logger.debug('Fetched course mix:', courseMix);

    // Then get the rest of the data in parallel
    const [
      items, 
      examDateResponse, 
      cpaCourses, 
      studySchedule,
      studyType,
      initialStudyDays
    ] = await Promise.all([
      api.getStudyPlanItems(plan.plan_id),
      api.getExamDate(userId, courseId) as Promise<ExamDateResponse>,
      api.getCPACourses(courseId),
      plan.current_schedule_id ? api.getStudySchedule(plan.plan_id) : null,
      api.getStudyType(userId, courseId),
      api.getUserStudyDays(userId, courseId)
    ]);

    // Handle study days
    let studyDays = initialStudyDays;
    if (!studyDays) {
      const defaultStudyDays = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false
      };
      
      try {
        studyDays = await api.setStudyDays(userId, courseId, defaultStudyDays);
        logger.debug('Created default study days:', studyDays);
      } catch (error) {
        console.error('Error creating default study days:', error);
      }
    }

    // Create the exam date object using the response
    let examDate: SPExamDate | null = null;
    if (examDateResponse) {
      examDate = {
        exam_date_id: examDateResponse.exam_date_id,
        user_id: examDateResponse.user_id,
        course_id: examDateResponse.course_id,
        start_date: examDateResponse.start_date,
        exam_date: examDateResponse.exam_date,
        created_at: examDateResponse.created_at,
        updated_at: examDateResponse.updated_at
      };
    }

    const courseProviders = cpaCourses.reduce((acc: { [key: string]: string }, course: SPCourse) => {
      acc[course.cpa_course_id] = course.name.toLowerCase().includes('kesler') ? 'kesler' :
                                  course.name.toLowerCase().includes('becker') ? 'becker' :
                                  course.name.toLowerCase().includes('gleim') ? 'gleim' : 'blueprint';
      return acc;
    }, {});

    let primaryCourse: SPCourse | null = null;
    let secondaryCourse: SPCourse | null = null;

    if (courseMix) {
      primaryCourse = cpaCourses.find((course: SPCourse) => course.cpa_course_id === courseMix.primary_cpa_course_id) || null;
      secondaryCourse = courseMix.secondary_cpa_course_id 
        ? cpaCourses.find((course: SPCourse) => course.cpa_course_id === courseMix.secondary_cpa_course_id) || null
        : null;
    }

    const result: StudyPlanData = {
      userStudyPlan: plan,
      studyPlanItems: items || [],
      examDate,
      startDate: examDateResponse?.start_date || null,
      endDate: studySchedule?.end_date || null,
      courseProviders,
      primaryCourse,
      secondaryCourse,
      courseMix,
      studySchedule,
      studyType,
      studyDays,
      courses: cpaCourses || [],
      currentSection: 'Study Plan',
      user: user  // Use the fetched user data
    };

    logger.debug('Returning study plan data:', result);
    return result;
  } catch (error) {
    console.error('Error in fetchStudyPlanData:', error);
    throw error;
  }
};

export const useStudyPlan = (
  userId: string,
  courseId: string,
  queryClient: QueryClient
): UseQueryResult<StudyPlanData, StudyPlanError> => {
  return useQuery<StudyPlanData, StudyPlanError>(
    ['studyPlan', userId, courseId],
    () => fetchStudyPlanData(userId, courseId),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        queryClient.setQueryData(['studyPlan', userId, courseId], data);
      },
    }
  );
};