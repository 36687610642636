import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, Eye } from 'lucide-react';
import { useQuery, useQueryClient } from 'react-query';
import { api, CACHE_KEYS, CACHE_DURATION, Ticket, ContentType } from '../../../services/api';
import { Card } from '../../ui/Card';
import { Button } from '../../ui/Button';
import { Badge } from '../../ui/Badge';
import { Input } from '../../ui/Input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/Select';
import Pagination from '../../course/common/Pagination';

interface FeedbackItem {
  ticket: Ticket;
  productTitle: string | null;
}

const ITEMS_PER_PAGE = 25;

const FeedbackList: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [validityFilter, setValidityFilter] = useState<'all' | 'pending' | 'yes' | 'no'>('all');
  const [resolvedFilter, setResolvedFilter] = useState<'all' | 'resolved' | 'unresolved'>('unresolved');
  const [currentPage, setCurrentPage] = useState(1);

  // Use React Query for data fetching
  const { data: feedbackItems = [], isLoading } = useQuery(
    CACHE_KEYS.FEEDBACK_LIST,
    api.getFeedbackTickets,
    {
      // Consider data fresh for 10 seconds
      staleTime: 10 * 1000,
      
      // Remove from cache after 1 minute of inactivity
      cacheTime: 60 * 1000,
      
      // Use React Query's built-in refetch on window focus
      refetchOnWindowFocus: true,
      
      // Always fetch fresh data when component mounts
      refetchOnMount: true
    }
  );

  // Memoize filtered items to prevent unnecessary recalculations
  const filteredItems = useMemo(() => {
    let filtered = [...feedbackItems];

    if (searchQuery.trim()) {
      const queryLower = searchQuery.trim().toLowerCase();
      filtered = filtered.filter((item) => {
        const inTicket =
          item.ticket.subject.toLowerCase().includes(queryLower) ||
          (item.ticket.body && item.ticket.body.toLowerCase().includes(queryLower)) ||
          (item.productTitle && item.productTitle.toLowerCase().includes(queryLower));
        return inTicket;
      });
    }

    if (validityFilter !== 'all') {
      filtered = filtered.filter(
        (item) => (item.ticket.feedback_validity || 'pending') === validityFilter
      );
    }

    if (resolvedFilter !== 'all') {
      filtered = filtered.filter((item) => {
        const isResolved = item.ticket.feedback_resolved || false;
        return resolvedFilter === 'resolved' ? isResolved : !isResolved;
      });
    }

    return filtered;
  }, [feedbackItems, searchQuery, validityFilter, resolvedFilter]);

  const handleRefresh = () => {
    queryClient.invalidateQueries(CACHE_KEYS.FEEDBACK_LIST);
  };

  const handleOpenDetail = (ticketId: string) => {
    navigate(`/admin/feedback/${ticketId}`);
  };

  const renderValidityBadge = (ticket: Ticket) => {
    const status = ticket.feedback_validity || 'pending';
    switch (status) {
      case 'yes':
        return (
          <Badge className="bg-emerald-500 text-white hover:bg-emerald-600">
            Valid
          </Badge>
        );
      case 'no':
        return (
          <Badge className="bg-rose-500 text-white hover:bg-rose-600">
            Invalid
          </Badge>
        );
      default:
        return (
          <Badge className="bg-amber-500 text-white hover:bg-amber-600">
            Pending
          </Badge>
        );
    }
  };

  const renderResolvedBadge = (ticket: Ticket) => {
    return ticket.feedback_resolved ? (
      <Badge className="bg-blue-500 text-white hover:bg-blue-600">
        Resolved
      </Badge>
    ) : (
      <Badge className="bg-gray-500 text-white hover:bg-gray-600">
        Unresolved
      </Badge>
    );
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);

  return (
    <Card className="flex flex-col h-full bg-white dark:bg-gray-900">
      <div className="px-3 py-2 border-b border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <Input
            placeholder="Search feedback..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="h-8 md:h-9 w-full md:max-w-xs bg-gray-50 dark:bg-gray-800"
          />

          <div className="ml-auto flex gap-2">
            <Select
              value={validityFilter}
              onValueChange={(val) => setValidityFilter(val as 'all' | 'pending' | 'yes' | 'no')}
            >
              <SelectTrigger className="h-8 md:h-9 text-sm bg-gray-50 dark:bg-gray-800 w-[120px]">
                <SelectValue placeholder="Filter Validity" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
                <SelectItem value="yes">Valid</SelectItem>
                <SelectItem value="no">Invalid</SelectItem>
              </SelectContent>
            </Select>

            <Select
              value={resolvedFilter}
              onValueChange={(val) => setResolvedFilter(val as 'all' | 'resolved' | 'unresolved')}
            >
              <SelectTrigger className="h-8 md:h-9 text-sm bg-gray-50 dark:bg-gray-800 w-[120px]">
                <SelectValue placeholder="Filter Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Status</SelectItem>
                <SelectItem value="resolved">Resolved</SelectItem>
                <SelectItem value="unresolved">Unresolved</SelectItem>
              </SelectContent>
            </Select>

            <Button
              onClick={handleRefresh}
              className="h-8 md:h-9 text-sm bg-orange-500 text-white hover:bg-orange-600"
            >
              Refresh
            </Button>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <Loader2 className="w-6 h-6 animate-spin text-orange-500" />
          </div>
        ) : filteredItems.length === 0 ? (
          <div className="p-4 text-gray-500 dark:text-gray-400 flex flex-col items-center gap-2">
            <Eye className="w-8 h-8" />
            No feedback found
          </div>
        ) : (
          <>
            <div className="divide-y divide-gray-200 dark:divide-gray-800">
              {paginatedItems.map(({ ticket, productTitle }) => (
                <div
                  key={ticket.id}
                  className="p-3 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors flex items-center gap-2"
                >
                  <div className="w-16 flex-none text-sm font-semibold text-gray-700 dark:text-gray-200">
                    #{ticket.short_id}
                  </div>

                  <div className="w-64 flex-none text-sm text-gray-700 dark:text-gray-200 truncate">
                    {ticket.subject}
                  </div>

                  <div className="flex-1 text-sm text-gray-700 dark:text-gray-200 truncate">
                    {productTitle ? productTitle : 'Unknown Product'}
                  </div>

                  <div className="w-28 flex-none text-xs md:text-sm text-gray-600 dark:text-gray-300 capitalize">
                    {ticket.content_type}
                  </div>

                  <div className="w-24 flex-none">{renderValidityBadge(ticket)}</div>
                  
                  <div className="w-24 flex-none">{renderResolvedBadge(ticket)}</div>

                  <div className="flex-none flex items-center gap-2">
                    <Button
                      className="h-8 text-xs px-2 bg-blue-500 text-white hover:bg-blue-600"
                      onClick={() => navigate(`/admin/support/ticket/${ticket.id}`)}
                    >
                      View Ticket
                    </Button>
                    <Button
                      className="h-8 text-xs px-2 bg-orange-500 text-white hover:bg-orange-600"
                      onClick={() => handleOpenDetail(ticket.id)}
                    >
                      Edit Feedback
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalItems={filteredItems.length}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </div>
    </Card>
  );
};

export default FeedbackList;