import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/api';
import { supabase } from '../../services/supabase';
import { queryClient } from '../../services/queryClient';
import { cacheManager } from '../../services/api';
import { useAuth } from '../../components/AuthProvider';

function Logout() {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // 1) Indicate the logout in sessionStorage (no forced reload).
        sessionStorage.setItem('authMessage', 'Successfully logged out');

        // 2) Clear any pending React Query fetches and caches.
        queryClient.clear();
        queryClient.removeQueries();

        // 3) Clear our custom caches.
        cacheManager.clear();
        await api.clearAllCache();

        // 4) If user is known, also clear their user-specific caches.
        if (user?.id) {
          await api.clearUserCache(user.id);
        }

        // 5) Clear localStorage/sessionStorage of all data. 
        //    (This is optional but normal for a full logout flow.)
        Object.keys(localStorage).forEach((key) => {
          localStorage.removeItem(key);
        });
        sessionStorage.removeItem('supabase.auth.token');

        // 6) Sign out with Supabase, but do NOT redirect the page; we’ll use `navigate()`.
        await supabase.auth.signOut();

        // 7) Finally, silently navigate to "/login" using React Router. 
        //    This avoids a full browser reload.
        navigate('/login', { replace: true });
      } catch (error) {
        console.error('Error during logout:', error);

        // 8) On error, still do cleanup but also just navigate without refreshing.
        sessionStorage.setItem('authMessage', 'Logged out due to an error');
        localStorage.clear();
        sessionStorage.clear();
        queryClient.clear();
        cacheManager.clear();
        navigate('/login', { replace: true });
      }
    };

    performLogout();
  }, [navigate, user]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white mx-auto mb-4"></div>
        <div className="text-gray-900 dark:text-white">Logging out...</div>
      </div>
    </div>
  );
}

export default Logout;
