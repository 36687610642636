// File: src/features/study-plan/components/LoopTask.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { api, StudyTaskDetails, TIER_IDS } from '../../../services/api';
import { Check, CheckCircle2, Trophy, Clock, ArrowRight } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../../components/ui/Alert';
import { useUserCourseAccess } from '../../UserCourseAccessProvider';
import { PremiumTaskWrapper } from './PremiumTaskWrapper';
import DOMPurify from 'dompurify';
import { useActiveQuiz, ActiveQuizState } from '../../../hooks/useActiveQuiz';
import PopupModal from '../common/PopupModal';
import Tooltip from '../../../components/ui/Tooltip';

interface LoopTaskProps {
  taskDetails: StudyTaskDetails;
  userId: string;
  courseId: string;
  itemId: string;
  onTaskComplete: () => void;
  nextTaskId: string | null;
  prevTaskId: string | null;
  onNavigateNext: () => void;
  onNavigatePrev: () => void;
}

interface KeslerTask {
  id: string;
  title: string;
  description: string;
  completed: boolean;
  actionType: 'kesler-cards' | 'quiz' | 'focus-notes';
  buttonText: string;
}

const LoopTask: React.FC<LoopTaskProps> = ({
  taskDetails,
  userId,
  courseId,
  itemId,
  onTaskComplete,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isMainTaskCompleted, setIsMainTaskCompleted] = useState<boolean>(false);
  const [keslerTasks, setKeslerTasks] = useState<KeslerTask[]>([]);
  const [isQuizLoading, setIsQuizLoading] = useState(false);
  const [quizError, setQuizError] = useState<string | null>(null);
  const [congratsMessage, setCongratsMessage] = useState<string | null>(null);

  // Tooltip + active task states
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  // Add active quiz state management
  const [activeQuizState, setActiveQuizState] = useState<ActiveQuizState>({
    activeQuiz: null,
    activeTestletType: null,
    showActiveQuizModal: false,
  });

  // Initialize useActiveQuiz hook
  const { data: activeQuiz } = useActiveQuiz(userId, courseId, {
    enabled: false,
    onSuccess: (quiz) => {
      if (quiz) {
        setActiveQuizState((prev) => ({
          ...prev,
          activeQuiz: quiz,
          showActiveQuizModal: true,
        }));
      }
    },
  });

  // Add premium access check
  const { userCourseAccesses } = useUserCourseAccess();
  const isRestrictedAccess = React.useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
    const courseAccesses = userCourseAccesses.filter(
      (access) => access.course_id === courseId
    );
    if (courseAccesses.length === 0) return true;
    const hasFullAccess = courseAccesses.some(
      (access) =>
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY ||
        access.tier_id === TIER_IDS.FULL_ACCESS
    );
    if (hasFullAccess) return false;
    const onlyHasRestrictedAccess = courseAccesses.every(
      (access) =>
        access.tier_id === TIER_IDS.FREE_TRIAL ||
        access.tier_id === TIER_IDS.MENTOR_ONLY
    );
    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  // Default button text logic
  const getDefaultButtonText = (
    actionType: KeslerTask['actionType'],
    isCompleted: boolean
  ): string => {
    if (actionType === 'quiz' && isCompleted) {
      return 'View Results';
    }
    return {
      'kesler-cards': 'Flashcards',
      'quiz': 'Quiz',
      'focus-notes': 'Focus Notes',
    }[actionType];
  };

  // Create tasks (updated to remove "Review Textbook/Lectures" and "Create KeslerCards", 
  // and revise "Review Flashcards" task text, plus updated "Add Focus Notes" description)
  const createTasks = useCallback(
    (isCompleted: boolean): KeslerTask[] => {
      return [
        {
          id: '1',
          title: 'Complete Looping Mode Card Deck',
          description:
            'Turn On Looping mode and review previously answered pre-made flashcards and review your Create Cards',
          completed: isCompleted,
          actionType: 'kesler-cards',
          buttonText: 'Flashcards',
        },
        {
          id: '2',
          title: 'Take Sample Quiz',
          description: `
            <p class="text-sm leading-relaxed text-gray-700 dark:text-gray-300 mb-4">
              Take a sample quiz over all the topics you have covered so far including unanswered and previously marked guessing or maybe confidence level questions.
            </p>
            <p class="text-sm leading-relaxed text-gray-700 dark:text-gray-300 font-medium">
              IMPORTANT: Start with multiple choice and once you feel confident in a section, start to introduce simulations into your study loops to start testing how deep your understanding of the material is.
            </p>
          `,
          completed: isCompleted,
          actionType: 'quiz',
          buttonText: 'Quiz',
        },
        {
          id: '3',
          title: 'Review Focus Notes & Source Materials',
          description:
            '<p>Dig into the topics that you need to research more with source material (your available textbooks and lectures) and clear as many Focus Notes as you can. Do not forget to create your own Flashcards as you have "ah-ha moments."</p>',
          completed: isCompleted,
          actionType: 'focus-notes',
          buttonText: 'Focus Notes',
        },
      ];
    },
    []
  );

  // On mount / when taskDetails changes, set tasks
  useEffect(() => {
    if (taskDetails) {
      setIsMainTaskCompleted(taskDetails.is_completed);

      const topicType = taskDetails.topic_type || 'loop';
      const storedTasks = localStorage.getItem(`keslerTasks_${itemId}_${topicType}`);
      if (storedTasks) {
        const parsedTasks: KeslerTask[] = JSON.parse(storedTasks);
        const updatedTasks = parsedTasks.map((task) => ({
          ...task,
          completed: taskDetails.is_completed ? true : task.completed,
        }));
        setKeslerTasks(updatedTasks);
      } else {
        const newTasks = createTasks(taskDetails.is_completed);
        setKeslerTasks(newTasks);
        localStorage.setItem(
          `keslerTasks_${itemId}_${topicType}`,
          JSON.stringify(newTasks)
        );
      }
    }
  }, [taskDetails, itemId, createTasks]);

  // Start quiz
  const handleQuiz = async () => {
    setQuizError(null);
    if (isQuizLoading) return;
    setIsQuizLoading(true);
    setCongratsMessage(null);

    try {
      // First check for active quiz
      const activeQuizResponse = await api.getActiveQuiz(userId, courseId);
      if (activeQuizResponse) {
        setActiveQuizState({
          activeQuiz: activeQuizResponse,
          activeTestletType: 'loop',
          showActiveQuizModal: true,
        });
        setIsQuizLoading(false);
        return;
      }

      // No active quiz, proceed with normal flow
      const quizResponse = await api.getStudyTaskLoopQuizQuestions(userId, courseId, itemId);
      if (!quizResponse.quizHistory || !quizResponse.testlet) {
        throw new Error('Failed to create quiz');
      }

      const searchParams = new URLSearchParams({
        study_task_testlet_id: quizResponse.testlet.id,
        quiz_history_id: quizResponse.quizHistory.id,
        study_task_id: itemId,
        type: 'loop',
      }).toString();

      navigate(`/course/${courseId}/quiz?${searchParams}`, {
        state: {
          resumeQuizId: quizResponse.quizHistory.id,
          quizData: quizResponse.quizHistory,
          studyTaskTestletId: quizResponse.testlet.id,
          studyTaskId: itemId,
          testletType: 'loop',
          from: 'study-task',
          showSummary: false,
          quizCompleted: false,
        },
      });
    } catch (error: any) {
      console.error('Error starting loop quiz:', error);
      if (error.message?.includes('Congratulations')) {
        setCongratsMessage(error.message);
      } else {
        setQuizError('Failed to start quiz. Please try again.');
      }
    } finally {
      setIsQuizLoading(false);
    }
  };

  // End quiz
  const handleEndQuiz = async () => {
    if (!activeQuizState.activeQuiz) return;
    try {
      const totalTime = activeQuizState.activeQuiz.questions_data.reduce(
        (sum, q) => sum + (q.timeSpent || 0),
        0
      );
      await api.endQuizSession(activeQuizState.activeQuiz.id, totalTime, true);
      setActiveQuizState({
        activeQuiz: null,
        activeTestletType: null,
        showActiveQuizModal: false,
      });
      await handleQuiz();
    } catch (error) {
      console.error('Error ending quiz:', error);
      setQuizError('Failed to end quiz session. Please try again.');
    }
  };

  // Update getTaskAction to handle focus-notes properly
  const getTaskAction = useCallback(
    (actionType: KeslerTask['actionType'], details: StudyTaskDetails, isCompleted: boolean) => {
      switch (actionType) {
        case 'quiz':
          return isCompleted
            ? () => navigate(`/course/${courseId}/analytics`)
            : handleQuiz;
            case 'kesler-cards':
              return !isCompleted
                ? () => {
                    if (details) {
                      // Set the flashcard mode settings in localStorage
                      localStorage.setItem('keslerCardsRandomMode', 'false');
                      localStorage.setItem('keslerCardsSpacedRepetitionMode', 'true');
                      localStorage.setItem('keslerCardsConfidenceFilter', 'All');
            
                      const encodedCategory = encodeURIComponent(
                        `${details.parent_flashcard_category_name ?? ''}${
                          details.sub_flashcard_category_name
                            ? ` - ${details.sub_flashcard_category_name}`
                            : ''
                        }`
                      );
                      const studyTaskReturnUrl = `/course/${courseId}/study-plan/${itemId}`;
                      navigate(`/course/${courseId}/kesler-cards`, {
                        state: {
                          category: encodedCategory,
                          returnUrl: studyTaskReturnUrl,
                          returnedFrom: 'kesler-cards',
                          item_id: itemId,
                          enableLoopingMode: true
                        },
                      });
                    }
                  }
                : () => {};
        case 'focus-notes':
          return !isCompleted
            ? () => {
                const studyTaskReturnUrl = `/course/${courseId}/study-plan/${itemId}`;
                navigate(`/course/${courseId}/focus-notes`, {
                  state: {
                    studyTaskId: itemId,
                    returnUrl: studyTaskReturnUrl,
                    returnedFrom: 'focus-notes',
                    item_id: itemId
                  },
                });
              }
            : () => {};
        default:
          return () => {};
      }
    },
    [courseId, itemId, navigate, handleQuiz]
  );

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const handleActionClick = (task: KeslerTask) => {
    scrollToTop();
    if (task.actionType === 'kesler-cards') {
      if (taskDetails) {
        const encodedCategory = encodeURIComponent(
          `${taskDetails.parent_flashcard_category_name ?? ''}${
            taskDetails.sub_flashcard_category_name
              ? ` - ${taskDetails.sub_flashcard_category_name}`
              : ''
          }`
        );
        const studyTaskReturnUrl = `/course/${courseId}/study-plan/${itemId}`;
        navigate(`/course/${courseId}/kesler-cards`, {
          state: {
            category: encodedCategory,
            returnUrl: studyTaskReturnUrl,
            returnedFrom: 'kesler-cards',
            item_id: itemId,
            enableLoopingMode: true // Add this flag
          },
        });
      }
    } else {
      getTaskAction(task.actionType, taskDetails, task.completed)();
    }
  };

  // Render the button
  const renderTaskButton = (task: KeslerTask, details: StudyTaskDetails) => {
    const buttonBaseClasses =
      'text-white py-2 px-4 rounded-md transition duration-300 text-sm inline-flex items-center justify-center space-x-1';
    let buttonClasses = buttonBaseClasses;

    if (task.completed && task.actionType === 'quiz') {
      buttonClasses += ' bg-primary-blue hover:bg-primary-blue-hover';
    } else if (task.completed) {
      buttonClasses += ' bg-gray-300 cursor-not-allowed';
    } else {
      buttonClasses += ' bg-primary-orange hover:bg-primary-orange-hover';
    }

    buttonClasses += ' w-full sm:w-auto';

    return (
      <button
        onClick={() => handleActionClick(task)}
        disabled={task.completed && task.actionType !== 'quiz'}
        className={buttonClasses}
      >
        {task.completed ? (
          task.actionType === 'quiz' ? (
            <span>{task.buttonText}</span>
          ) : (
            <>
              <CheckCircle2 className="w-4 h-4 mr-1" />
              <span>Completed</span>
            </>
          )
        ) : (
          task.buttonText
        )}
      </button>
    );
  };

  // Toggle completion
  const toggleTaskCompletion = async (taskId: string) => {
    // Clear tooltip if the task being completed is currently active
    if (activeTaskId === taskId) {
      setShowTooltip(false);
      setActiveTaskId(null);
    }

    const updatedTasks = keslerTasks.map((task) => {
      if (task.id === taskId) {
        const willBeCompleted = !task.completed;
        return {
          ...task,
          completed: willBeCompleted,
          buttonText:
            task.actionType === 'quiz'
              ? getDefaultButtonText('quiz', willBeCompleted)
              : task.buttonText,
        };
      }
      return task;
    });
    setKeslerTasks(updatedTasks);
    localStorage.setItem(
      `keslerTasks_${itemId}_${taskDetails.topic_type}`,
      JSON.stringify(updatedTasks)
    );

    const allCompleted = updatedTasks.every((task) => task.completed);
    if (allCompleted && !isMainTaskCompleted) {
      await handleMarkComplete();
    }
  };

  // Mark as complete
  const handleMarkComplete = async () => {
    try {
      await api.markStudyTaskComplete(itemId);
      setIsMainTaskCompleted(true);

      const updatedTasks = keslerTasks.map((task) => ({
        ...task,
        completed: true,
        buttonText:
          task.actionType === 'quiz'
            ? getDefaultButtonText('quiz', true)
            : task.buttonText,
      }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(
        `keslerTasks_${itemId}_${taskDetails.topic_type}`,
        JSON.stringify(updatedTasks)
      );

      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };

  // Mark as incomplete
  const handleUncompleteTask = async () => {
    try {
      await api.unmarkStudyTaskComplete(itemId);
      setIsMainTaskCompleted(false);

      const updatedTasks = keslerTasks.map((task) => ({
        ...task,
        completed: false,
        buttonText: getDefaultButtonText(task.actionType, false),
      }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(
        `keslerTasks_${itemId}_${taskDetails.topic_type}`,
        JSON.stringify(updatedTasks)
      );

      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error unmarking task as complete:', error);
    }
  };

  const formatContentWithLineBreaks = (content: string) => {
    return content.replace(
      /;(?=<\/span><span class="font-bold">)/g,
      ';</span><br/><span class="font-bold">'
    );
  };

  // Time estimate helper
  const formatTimeEstimate = (minutes: number | null): string => {
    if (!minutes) return 'Not available';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (hours === 1) {
      return remainingMinutes > 0
        ? `${hours} hour ${remainingMinutes} minutes`
        : `${hours} hour`;
    } else {
      return remainingMinutes > 0
        ? `${hours} hours ${remainingMinutes} minutes`
        : `${hours} hours`;
    }
  };

  const getEstimatedTime = (details: StudyTaskDetails): number | null => {
    return details.estimated_minutes;
  };

  const renderTimeEstimate = () => {
    const isRetake = taskDetails.topic_type?.includes('retake');
    const estimatedTime = getEstimatedTime(taskDetails);

    return (
      <div className="mb-6">
        <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 flex items-center">
            <Clock className="w-5 h-5 mr-2 text-primary-blue" />
            Estimated Time to Complete
          </h3>
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
            <div className="flex items-start">
              <ArrowRight className="w-5 h-5 text-primary-blue mt-0.5 mr-2 shrink-0" />
              <div>
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {isRetake ? 'Review Time' : 'Learning Time'}
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-gray-100 mt-1">
                  {formatTimeEstimate(estimatedTime)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

   // Update the handleReturnState useEffect to handle focus-notes returns
   useEffect(() => {
    if (!keslerTasks.length) return;

    if (location.state && location.state.returnedFrom) {
      const handleReturnState = async () => {

        const taskType = location.state.returnedFrom;
        
        if (taskType === 'quiz') {
          const quizTask = keslerTasks.find((t) => t.actionType === 'quiz');
          if (quizTask) {
            setActiveTaskId(quizTask.id);
            setShowTooltip(true);

            if (location.state.testletCompleted && !quizTask.completed) {
              await toggleTaskCompletion(quizTask.id);
            }
          }
        } else if (taskType === 'focus-notes') {
          const focusNotesTask = keslerTasks.find((t) => t.actionType === 'focus-notes');
          if (focusNotesTask && !focusNotesTask.completed) {
            setActiveTaskId(focusNotesTask.id);
            setShowTooltip(true);
          }
        } else {
          const matchingTask = keslerTasks.find(
            (t) => !t.completed && t.actionType === taskType
          );
          if (matchingTask) {
            setActiveTaskId(matchingTask.id);
            setShowTooltip(true);
          }
        }

        // Clear navigation state
        navigate(location.pathname, {
          replace: true,
          state: {},
        });
      };

      handleReturnState();
    }
  }, [location, keslerTasks, navigate, toggleTaskCompletion]);

  // Render completion circle with tooltip
  const renderCompletionCircle = (task: KeslerTask) => {
    const isActive = activeTaskId === task.id && showTooltip;

    const button = (
      <button
        onClick={() => toggleTaskCompletion(task.id)}
        className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 border-2 
          ${
            task.completed
              ? 'bg-gray-400 border-gray-400 cursor-not-allowed'
              : `bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-500 ${
                  isActive
                    ? 'animate-pulse border-primary-orange'
                    : 'hover:border-primary-blue dark:hover:border-primary-blue'
                }`
          }`}
        title={task.completed ? 'Task completed' : 'Mark as complete'}
      >
        {task.completed ? (
          <Check size={16} className="mx-auto text-white" />
        ) : (
          <div className="relative w-full h-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <Check
                size={14}
                className={`${isActive ? 'text-primary-orange' : 'text-gray-400'}`}
              />
            </div>
          </div>
        )}
      </button>
    );

    if (isActive) {
      const tooltipMessage =
        task.completed && task.actionType === 'quiz'
          ? 'Quiz completed! View results in Analytics'
          : 'Click to mark this task as complete!';
      return (
        <Tooltip
          content={
            <div className="flex items-center gap-2">
              <Check className="w-4 h-4" />
              <span>{tooltipMessage}</span>
            </div>
          }
          side="right"
          delayMS={0}
        >
          {button}
        </Tooltip>
      );
    }

    return button;
  };

  // Render main content
  const renderTaskContent = (details: StudyTaskDetails) => {
    const isKeslerPrimary = details.primary_course_name === 'Kesler CPA Review';
    const nonKeslerCourseName = isKeslerPrimary
      ? details.secondary_course_display_name
      : details.primary_course_display_name;
    const nonKeslerCourseAlias = isKeslerPrimary
      ? details.secondary_course_alias
      : details.primary_course_alias;
    const topicTitle = isKeslerPrimary
      ? details.secondary_topic_title || details.blueprint_topic_title
      : details.primary_topic_title;
    const keslerTopicTitle = isKeslerPrimary
      ? details.primary_topic_title
      : details.secondary_topic_title;

    const displayCourseName = (name: string | null, alias: string | null) => {
      if (name && alias) {
        return `${name} (${alias})`;
      }
      return name || 'N/A';
    };

    return (
      <>

        {nonKeslerCourseName && (
          <div className="mb-8 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">
              {displayCourseName(nonKeslerCourseName, nonKeslerCourseAlias)} Tasks
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Review a textbook and/or video lectures that cover this topic:
            </p>
            <div
              className="font-medium text-gray-800 dark:text-gray-200 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-blue"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  formatContentWithLineBreaks(topicTitle || 'N/A')
                ),
              }}
            />
          </div>
        )}

        <div className="mb-8">
          <div className="mb-6 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">
              Kesler Tasks
            </h3>
            <div
              className="text-gray-700 dark:text-gray-300 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-orange"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  formatContentWithLineBreaks(keslerTopicTitle || '')
                ),
              }}
            />
          </div>

          {/* Estimated Time Section */}
          {renderTimeEstimate()}

          <div className="space-y-4">
            {keslerTasks.map((task) => {
              const isActive = activeTaskId === task.id && showTooltip && !task.completed;
              return (
                <div
                  key={task.id}
                  className={`bg-white dark:bg-gray-800 overflow-hidden rounded-lg border-2 transition-all duration-300 ${
                    isActive
                      ? 'border-primary-orange shadow-lg shadow-orange-100 dark:shadow-orange-900/20'
                      : task.completed
                      ? 'border-gray-200 dark:border-gray-700'
                      : 'border-gray-200 dark:border-gray-700'
                  }`}
                >
                  {isActive && (
                    <div className="bg-orange-50 dark:bg-orange-900/10 px-4 py-2 flex items-center gap-2 border-b-2 border-primary-orange">
                      <Check className="w-4 h-4 text-primary-orange" />
                      <span className="text-sm text-orange-700 dark:text-orange-300">
                        Click the circle to mark this task as complete!
                      </span>
                    </div>
                  )}
                  <div
                    className={`p-4 sm:p-6 ${
                      isActive ? 'bg-orange-50/50 dark:bg-orange-900/5' : ''
                    }`}
                  >
                    {/* Desktop layout */}
                    <div className="hidden sm:grid grid-cols-[auto_1fr_auto] gap-6 items-center">
                      <div className="flex items-center justify-center">
                        {renderCompletionCircle(task)}
                      </div>
                      <div>
                        <h4
                          className={`text-lg font-medium ${
                            task.completed
                              ? 'text-gray-500 dark:text-gray-400 line-through'
                              : isActive
                              ? 'text-orange-900 dark:text-orange-100'
                              : 'text-gray-900 dark:text-gray-100'
                          }`}
                        >
                          {task.title}
                        </h4>
                        <p
                          className={`mt-1 text-sm ${
                            task.completed
                              ? 'text-gray-400 dark:text-gray-500 line-through'
                              : isActive
                              ? 'text-orange-700 dark:text-orange-300'
                              : 'text-gray-600 dark:text-gray-300'
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(task.description, {
                              ALLOWED_TAGS: ['p'],
                              ALLOWED_ATTR: ['class'],
                            }),
                          }}
                        />
                      </div>
                      <div className="flex items-center">
                        {renderTaskButton(task, details)}
                      </div>
                    </div>

                    {/* Mobile layout */}
                    <div className="sm:hidden flex flex-col space-y-4">
                      <div className="flex items-start justify-between">
                        <div className="flex-grow">
                          <h4
                            className={`text-lg font-medium ${
                              task.completed
                                ? 'text-gray-500 dark:text-gray-400 line-through'
                                : isActive
                                ? 'text-orange-900 dark:text-orange-100'
                                : 'text-gray-900 dark:text-gray-100'
                            }`}
                          >
                            {task.title}
                          </h4>
                          <p
                            className={`mt-1 text-sm ${
                              task.completed
                                ? 'text-gray-400 dark:text-gray-500 line-through'
                                : isActive
                                ? 'text-orange-700 dark:text-orange-300'
                                : 'text-gray-600 dark:text-gray-300'
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(task.description, {
                                ALLOWED_TAGS: ['p'],
                                ALLOWED_ATTR: ['class'],
                              }),
                            }}
                          />
                        </div>
                        <div className="ml-4">{renderCompletionCircle(task)}</div>
                      </div>
                      <div>{renderTaskButton(task, details)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => (
    <div className="bg-transparent">
      <div className="text-center px-6 pb-6 bg-transparent">
        <h2
          className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(taskDetails.task_name) }}
        />
      </div>

      {congratsMessage && (
        <Alert variant="success" className="mb-6">
          <div className="flex items-center gap-3">
            <Trophy className="h-5 w-5 text-green-600 dark:text-green-400" />
            <div>
              <AlertTitle>Achievement Unlocked!</AlertTitle>
              <AlertDescription>{congratsMessage}</AlertDescription>
            </div>
          </div>
        </Alert>
      )}

      {quizError && (
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>{quizError}</AlertDescription>
        </Alert>
      )}

      {/* Render card-based loop tasks */}
      {renderTaskContent(taskDetails)}

      <div className="mt-8">
        <button
          onClick={isMainTaskCompleted ? handleUncompleteTask : handleMarkComplete}
          className={`w-full ${
            isMainTaskCompleted
              ? 'bg-gray-400 hover:bg-gray-500'
              : 'bg-primary-blue hover:bg-primary-blue-hover'
          } text-white py-3 px-6 rounded-md transition duration-300`}
        >
          {isMainTaskCompleted ? 'Mark Task as Incomplete' : 'Mark Task as Complete'}
        </button>
      </div>
    </div>
  );

  return (
    <>
      <PremiumTaskWrapper courseId={courseId} taskType="loop" isPremium={isRestrictedAccess}>
        {renderContent()}
      </PremiumTaskWrapper>

      <PopupModal
        isOpen={activeQuizState.showActiveQuizModal}
        onClose={() =>
          setActiveQuizState((prev) => ({ ...prev, showActiveQuizModal: false }))
        }
        onResume={handleQuiz}
        onEnd={handleEndQuiz}
        activeQuiz={activeQuizState.activeQuiz}
        courseId={courseId}
      />
    </>
  );
};

export default LoopTask;