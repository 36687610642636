import React, { useState, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { getBrowserInstanceId } from '../../utils/browserInstance';
import { Button } from '../ui/Button';
import { Alert, AlertDescription } from '../ui/Alert';
import { Mail, Lock, User, AlertCircle, Loader } from 'lucide-react';

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [registrationStep, setRegistrationStep] = useState<'initial' | 'registering' | 'finalizing'>('initial');
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    setRegistrationStep('registering');

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setIsLoading(false);
      setRegistrationStep('initial');
      return;
    }

    try {
      const browserInstanceId = await getBrowserInstanceId();
      
      // Register and create user profile
      const { data, error: authError } = await auth.register(email, password, fullName);
      
      if (authError) throw authError;
      
      if (data.user) {
        setRegistrationStep('finalizing');
        
        // Perform login
        const { data: signInData, error: signInError } = await auth.login(email, password);
        
        if (signInError) throw signInError;
        
        if (signInData.user) {
          // Small delay to ensure all states are synchronized
          await new Promise(resolve => setTimeout(resolve, 1000));
          
          // Force a page reload before navigation
          window.location.href = '/products';
        } else {
          throw new Error('Login failed after registration');
        }
      } else {
        throw new Error('Registration failed: No user data returned');
      }
    } catch (err) {
      setError((err as Error).message || 'Failed to register. Please try again.');
      setRegistrationStep('initial');
    } finally {
      if (registrationStep !== 'finalizing') {
        setIsLoading(false);
      }
    }
  }, [email, password, confirmPassword, fullName, navigate, registrationStep]);

  const getButtonText = () => {
    switch (registrationStep) {
      case 'registering':
        return 'Creating your account...';
      case 'finalizing':
        return 'Finalizing setup...';
      default:
        return 'Create Account';
    }
  };

  return (
    <div className="min-h-screen flex items-top justify-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        {/* Header Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Create a Free Account
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Start your CPA exam preparation journey today with Kesler!
          </p>
        </div>

        {/* Error Alert */}
        {error && (
          <Alert className="bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800">
            <div className="flex items-center gap-3">
              <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400 flex-shrink-0" />
              <AlertDescription className="text-red-700 dark:text-red-300">
                {error}
              </AlertDescription>
            </div>
          </Alert>
        )}

        {/* Form Section */}
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            {/* Full Name Field */}
            <div className="space-y-2">
              <label 
                htmlFor="fullName" 
                className="text-sm font-medium inset-y-0 left-0 flex text-gray-700 dark:text-gray-300"
              >
                <User className="h-5 w-5 pr-2 text-gray-400" /> Full Name
              </label>
              <input
                id="fullName"
                name="fullName"
                type="text"
                autoComplete="name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="block w-full pl-3 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Enter your full name"
              />
            </div>

            {/* Email Field */}
            <div className="space-y-2">
              <label 
                htmlFor="email" 
                className="text-sm font-medium inset-y-0 left-0 flex text-gray-700 dark:text-gray-300"
              >
                <Mail className="h-5 w-5 pr-2 text-gray-400" /> Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full pl-3 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />
            </div>

            {/* Password Field */}
            <div className="space-y-2">
              <label 
                htmlFor="password" 
                className="text-sm font-medium inset-y-0 left-0 flex text-gray-700 dark:text-gray-300"
              >
                <Lock className="h-5 w-5 pr-2 text-gray-400" /> Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full pl-3 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Create a password"
              />
            </div>

            {/* Confirm Password Field */}
            <div className="space-y-2">
              <label 
                htmlFor="confirmPassword" 
                className="text-sm font-medium inset-y-0 left-0 flex text-gray-700 dark:text-gray-300"
              >
                <Lock className="h-5 w-5 pr-2 text-gray-400" /> Confirm Password
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="block w-full pl-3 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Confirm your password"
              />
            </div>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            disabled={isLoading}
            className="w-full flex justify-center py-3 px-4 border border-transparent 
                     rounded-lg shadow-sm text-sm font-medium text-white
                     bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition duration-150 ease-in-out"
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                {getButtonText()}
              </div>
            ) : (
              'Create Account'
            )}
          </Button>

          {/* Links Section */}
          <div className="text-center text-sm">
            <Link 
              to="/login"
              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
            >
              Already have an account? Sign in
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;