import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { Textarea } from '../../ui/Textarea';
import { api, ContentType } from '../../../services/api';

interface EditorField {
  key: string;
  label: string;
  content: string;
  type: 'html' | 'jsonb' | 'text';
}

export interface CodeEditorProps {
  contentType: ContentType;
  contentId: string;
  onChange?: (newContent: Record<string, any>) => void;
}

const CodeEditor: React.FC<CodeEditorProps> = ({ contentType, contentId, onChange }) => {
  const [fields, setFields] = useState<EditorField[]>([]);
  const [loading, setLoading] = useState(false);

  const getFieldsForContentType = (type: ContentType): Array<{ key: string; label: string; type: 'html' | 'jsonb' | 'text' }> => {
    switch (type) {
      case 'flashcards':
        return [
          { key: 'side_1_content', label: 'Front Side Content (Raw HTML)', type: 'html' },
          { key: 'side_2_content', label: 'Back Side Content (Raw HTML)', type: 'html' }
        ];
      case 'lessons':
        return [
          { key: 'content', label: 'Lesson Content (Raw HTML)', type: 'html' }
        ];
      case 'questions':
        return [
          { key: 'question_content', label: 'Question Content (Raw HTML)', type: 'html' },
          { key: 'answer_content', label: 'Answer Content (Raw HTML)', type: 'html' }
        ];
      case 'simulations':
        return [
          { key: 'exhibits', label: 'Exhibits (JSONB)', type: 'jsonb' },
          { key: 'html', label: 'Simulation Interface HTML', type: 'html' },
          { key: 'question_content', label: 'Question Content (JSONB)', type: 'jsonb' },
          { key: 'correct_answer', label: 'Correct Answer (JSONB)', type: 'jsonb' },
          { key: 'answer_content', label: 'Answer Content (Raw HTML)', type: 'html' },
          { key: 'external_id', label: 'External ID', type: 'text' }
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    fetchContent();
  }, [contentId, contentType]);

  async function fetchContent() {
    try {
      setLoading(true);
      const fieldConfigs = getFieldsForContentType(contentType);
      const { data } = await api.fetchContentRow(contentType, contentId);
      
      const newFields = fieldConfigs.map(config => {
        let content = data[config.key];
        if (config.type === 'jsonb' && content) {
          content = JSON.stringify(content, null, 2);
        }
        return {
          ...config,
          content: content || ''
        };
      });
      
      setFields(newFields);
      
      if (onChange) {
        const currentContent: Record<string, any> = {};
        for (const field of newFields) {
          currentContent[field.key] = field.content;
        }
        onChange(currentContent);
      }
    } catch (error) {
      console.error('Failed to load code editor content:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleContentChange = (index: number, newContent: string) => {
    const updatedFields = [...fields];
    updatedFields[index] = {
      ...updatedFields[index],
      content: newContent
    };
    setFields(updatedFields);

    if (onChange) {
      const updates: Record<string, any> = {};
      for (const field of updatedFields) {
        if (field.type === 'jsonb') {
          try {
            updates[field.key] = JSON.parse(field.content);
          } catch (error) {
            updates[field.key] = field.content;
          }
        } else {
          updates[field.key] = field.content;
        }
      }
      onChange(updates);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
        <Loader2 className="w-4 h-4 animate-spin" />
        Loading content...
      </div>
    );
  }

  return (
    <div className="border dark:border-gray-700 rounded p-3 bg-white dark:bg-gray-900">
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
          Edit Content
        </h3>
        {fields.map((field, index) => (
          <div key={field.key} className="space-y-2">
            <div className="text-sm font-semibold text-gray-900 dark:text-gray-100 border-b dark:border-gray-700 pb-1">
              {field.label}
            </div>
            <Textarea
              className="min-h-[200px] font-mono text-xs bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-700 focus:border-blue-500 dark:focus:border-blue-400 placeholder-gray-400 dark:placeholder-gray-500"
              value={field.content}
              onChange={(e) => handleContentChange(index, e.target.value)}
              placeholder={`Enter ${field.type === 'jsonb' ? 'JSON' : field.type === 'html' ? 'HTML' : 'text'} here...`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CodeEditor;