import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BookOpen, HelpCircle, Activity, Layers, Award, FileText, User, ChevronRight, ChevronLeft, BarChart, Calendar, Search as SearchIcon, X } from 'lucide-react';
import { useProducts } from '../components/ProductProvider';
import { useUserCourseAccess } from '../components/UserCourseAccessProvider';
import { Product, UserCourseAccess, COURSE_IDS } from '../services/api';
import { useSafeArea } from '../hooks/useMobile';

interface SidebarProps {
  courseId?: string;
  user: any | null;
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

interface MenuItem {
  path: string;
  icon: React.ElementType;
  label: string;
}

interface MenuSection {
  title?: string;
  items: MenuItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ courseId, user, isMenuOpen, setIsMenuOpen }) => {
  const location = useLocation();
  const safeArea = useSafeArea();
  const [isExpanded, setIsExpanded] = useState(() => {
    const saved = localStorage.getItem('sidebarExpanded');
    return saved !== null ? JSON.parse(saved) : true;
  });
  
  const [isMobile, setIsMobile] = useState(() => {
    return window.innerWidth < 1350 || window.innerHeight < 825;
  });
  
  const [products, setProducts] = useState<Product[]>([]);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const previousExpandedState = useRef<boolean>(isExpanded);
  const { getProduct } = useProducts();
  const { userCourseAccesses } = useUserCourseAccess();
  const isCoursePage = !!courseId;

  // Safe area styles
  const safeAreaStyle = {
    paddingTop: `max(${safeArea.top}px, env(safe-area-inset-top))`,
    paddingRight: `max(${safeArea.right}px, env(safe-area-inset-right))`,
    paddingBottom: `max(${safeArea.bottom}px, env(safe-area-inset-bottom))`,
    paddingLeft: `max(${safeArea.left}px, env(safe-area-inset-left))`
  };

  // Handle sidebar toggle
  const handleSidebarToggle = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    if (!isMobile) {
      previousExpandedState.current = newState;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const shouldBeMobile = window.innerWidth < 1350 || window.innerHeight < 825;
      const wasDesktop = !isMobile;
      
      setIsMobile(shouldBeMobile);
      
      if (shouldBeMobile && isExpanded) {
        previousExpandedState.current = isExpanded;
        setIsExpanded(false);
      } else if (!shouldBeMobile && wasDesktop === false) {
        setIsExpanded(previousExpandedState.current);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isExpanded, isMobile]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--sidebar-width',
      `${isExpanded ? '180px' : '80px'}`
    );
    localStorage.setItem('sidebarExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (user && userCourseAccesses.length > 0) {
        try {
          const courseDetails = await Promise.all(
            userCourseAccesses
              .filter(access => access.course_id !== COURSE_IDS.MENTORSHIP)
              .map((access: UserCourseAccess) => getProduct(access.course_id))
          );
          setProducts(courseDetails.filter((course): course is Product => course !== null));
        } catch (error) {
          console.error('Failed to fetch courses:', error);
        }
      }
    };

    fetchProducts();
  }, [user, userCourseAccesses, getProduct]);

  const menuSections: MenuSection[] = [
    {
      title: 'HOW To Pass',
      items: [
        { path: 'study-plan', icon: Calendar, label: 'Study Plan' },
        { path: 'mentor', icon: User, label: 'Mentor' },
      ],
    },
    {
      title: 'Learning Tools',
      items: [
        { path: 'lessons', icon: BookOpen, label: 'Lessons' },
        { path: 'quiz', icon: HelpCircle, label: 'Quiz' },
        { path: 'simulations', icon: Activity, label: 'Simulations' },
      ],
    },
    {
      title: 'Retention Tools',
      items: [
        { path: 'analytics', icon: BarChart, label: 'Analytics' },
        { path: 'kesler-cards', icon: Layers, label: 'KeslerCards' },
        { path: 'focus-notes', icon: FileText, label: 'Focus Notes' },
      ],
    },
    {
      title: 'Exam Day Tools',
      items: [
        { path: 'mock-exam', icon: Award, label: 'Mock Exam' },
        { path: 'search', icon: SearchIcon, label: 'Search' },
      ],
    },
  ];

  const renderMenu = () => (
    <div
      className={`fixed inset-0 z-50 transform ${
        isMenuOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out`}
      style={safeAreaStyle}
    >
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => setIsMenuOpen(false)}></div>
      <nav className="relative bg-white dark:bg-gray-800 w-64 h-full overflow-y-auto">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-gray-800 dark:text-white">Menu</h2>
            <button 
              onClick={() => setIsMenuOpen(false)}
              className="w-8 h-8 flex items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              aria-label="Close menu"
            >
              <X size={20} className="text-gray-600 dark:text-gray-400" />
            </button>
          </div>
          {courseId ? (
            menuSections.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
                  {section.title}
                </h3>
                <ul>
                  {section.items.map((item) => {
                    const Icon = item.icon;
                    const isActive = location.pathname.startsWith(`/course/${courseId}/${item.path}`);
                    return (
                      <li key={item.path}>
                        <Link
                          to={`/course/${courseId}/${item.path}`}
                          className={`flex items-center py-2 px-4 ${
                            isActive
                              ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200'
                              : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
                          }`}
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <Icon className="mr-3" size={18} />
                          {item.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))
          ) : (
            <div>
              <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
                My Courses
              </h3>
              <ul>
                {products
                  .filter(product => product.id !== COURSE_IDS.MENTORSHIP)
                  .map((product) => (
                  <li key={product.id}>
                    <Link
                      to={`/course/${product.id}/study-plan`}
                      className="flex items-center py-2 px-4 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <BookOpen className="mr-3" size={18} />
                      {product.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );

  const renderDesktopSidebar = () => (
    <nav 
      ref={sidebarRef}
      className="h-screen bg-white dark:bg-gray-800 border-r border-gray-100 dark:border-gray-700"
      style={{
        paddingTop: safeAreaStyle.paddingTop,
        paddingLeft: safeAreaStyle.paddingLeft
      }}
    >
      <div className="flex flex-col h-full">
        {/* Logo Section */}
        <div className="p-4 border-b border-gray-100 dark:border-gray-700">
          <Link to="/products" className="flex justify-center">
            <img
              src="/logo/mobile-app-png.png"
              alt="Kesler CPA Review Logo"
              className={`h-8 w-auto transition-all duration-300 ${isExpanded ? 'opacity-100' : 'opacity-70 hover:opacity-100'}`}
            />
          </Link>
        </div>
  
        {/* Navigation Section */}
        <div className="flex-1 py-4 overflow-y-auto">
          <ul className="space-y-6">
            {menuSections.map((section, index) => (
              <li key={index} className="px-3">
                {section.title && (
                  <div className="mb-2">
                    {isExpanded ? (
                      <h3 className="text-[11px] font-medium text-gray-400 dark:text-gray-300 tracking-wider uppercase">
                        {section.title}
                      </h3>
                    ) : (
                      <div className="h-px bg-gray-100 dark:bg-gray-600 my-2" />
                    )}
                  </div>
                )}
                <ul className="space-y-1">
                  {section.items.map((item) => {
                    const Icon = item.icon;
                    const isActive = location.pathname.startsWith(`/course/${courseId}/${item.path}`);
                    return (
                      <li key={item.path}>
                        <Link
                          to={`/course/${courseId}/${item.path}`}
                          className={`flex items-center ${
                            isExpanded ? 'px-3' : 'justify-center px-2'
                          } py-2 rounded-lg transition-all duration-200 group
                          ${
                            isActive
                              ? 'bg-gray-50 dark:bg-gray-700 text-primary-blue dark:text-blue-300'
                              : 'text-gray-600 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 hover:text-primary-blue dark:hover:text-blue-300'
                          }`}
                        >
                          <Icon 
                            className={`transition-all duration-200 ${isExpanded ? 'mr-3' : 'group-hover:scale-110'}`} 
                            size={18}
                          />
                          {isExpanded && (
                            <span className="text-sm font-medium whitespace-nowrap">
                              {item.label}
                            </span>
                          )}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        </div>
  
        {/* Toggle Button */}
        <div className="p-4 border-t border-gray-100 dark:border-gray-700" style={{ paddingBottom: safeAreaStyle.paddingBottom }}>
          <button
            onClick={handleSidebarToggle}
            className="w-full flex items-center justify-center p-2 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-white transition-colors"
            aria-label={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
          >
            {isExpanded ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
          </button>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      {isCoursePage ? (
        isMobile ? (
          isMenuOpen && renderMenu()
        ) : (
          renderDesktopSidebar()
        )
      ) : (
        isMenuOpen && renderMenu()
      )}
    </>
  );
};

export default Sidebar;