import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader2, ArrowLeft, Save, CheckCircle2, XCircle, AlertCircle, ExternalLink } from 'lucide-react';
import { api, CACHE_KEYS, Ticket, ContentFeedback } from '../../../services/api';
import { supabase } from '../../../services/supabase';
import { Card } from '../../ui/Card';
import { Button } from '../../ui/Button';
import { Textarea } from '../../ui/Textarea';
import CodeEditor from './CodeEditor';
import CategoryMapper from './CategoryMapper';
import SaveChangesModal from './SaveChangesModal';
import FeedbackPreview from './FeedbackPreview';
import { ScrollArea } from '../../ui/ScrollArea';
import { format } from 'date-fns';
import { Badge } from '../../ui/Badge';
import { createLogger } from '../../../utils/Logger';

const logger = createLogger({ namespace: 'feedbackdetail.tsx' });

type FeedbackValidity = 'pending' | 'yes' | 'no';

interface ContentChange {
  field: string;
  oldValue: string;
  newValue: string;
}

interface TicketStatus {
    validity: FeedbackValidity;
    isResolved: boolean;
  }

interface RawFeedbackData {
    userId: string;
    ticketId?: string;
    validity?: FeedbackValidity;
    isResolved?: boolean;
    feedbackText: string;
    email?: string;
    createdAt: string;
  }

  interface ExtendedContentFeedback extends RawFeedbackData {
    contentId: string;
  }

const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

interface ExtendedContentFeedback {
    feedbackText: string;
    email?: string;
    createdAt: string;
    ticketId?: string;
    isResolved?: boolean;
    validity?: 'pending' | 'yes' | 'no';
  }
  const FeedbackCard: React.FC<{ feedback: ExtendedContentFeedback }> = ({ feedback }) => {
    const previewText = stripHtml(feedback.feedbackText).slice(0, 30) + 
      (feedback.feedbackText.length > 30 ? '...' : '');
  
    const renderValidityBadge = () => {
      switch (feedback.validity) {
        case 'yes':
          return (
            <Badge variant="secondary" className="bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-200 flex items-center gap-1">
              <CheckCircle2 className="w-3 h-3" />
              Valid
            </Badge>
          );
        case 'no':
          return (
            <Badge variant="secondary" className="bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-200 flex items-center gap-1">
              <XCircle className="w-3 h-3" />
              Invalid
            </Badge>
          );
        default:
          return (
            <Badge variant="secondary" className="bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-200 flex items-center gap-1">
              <AlertCircle className="w-3 h-3" />
              Pending
            </Badge>
          );
      }
    };
  
    const renderResolutionBadge = () => {
      return feedback.isResolved ? (
        <Badge variant="secondary" className="bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-200">
          Resolved
        </Badge>
      ) : (
        <Badge variant="secondary" className="bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-200">
          Unresolved
        </Badge>
      );
    };
  
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg p-3 shadow-sm border border-gray-200 dark:border-gray-700">
        <div className="mb-2 text-sm text-gray-900 dark:text-gray-100">
          {previewText}
        </div>
        
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            {renderValidityBadge()}
            {renderResolutionBadge()}
          </div>
          
          <div className="flex items-center justify-between">
            <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
              {feedback.email}
            </span>
  
            <time 
              dateTime={feedback.createdAt}
              className="text-xs text-gray-500 dark:text-gray-400"
            >
              {format(new Date(feedback.createdAt), 'MMM d, yyyy')}
            </time>
  
            {feedback.ticketId && (
              <Button
                variant="primary"
                size="sm"
                className="h-7 text-xs flex items-center justify-center bg-blue-500 hover:bg-blue-600"
                onClick={() => window.open(`/admin/support/ticket/${feedback.ticketId}`, '_blank')}
              >
                <span className="flex items-center">
                  Ticket #{feedback.ticketId.slice(0, 8)}
                  <ExternalLink className="w-3 h-3 ml-1" />
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const FeedbackDetail: React.FC = () => {
    const { ticketId } = useParams<{ ticketId: string }>();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isResolved, setIsResolved] = useState(false);
    const [ticket, setTicket] = useState<Ticket | null>(null);
    const [loading, setLoading] = useState(true);
    const [contentFeedback, setContentFeedback] = useState<ExtendedContentFeedback[]>([]);
    const [originalContent, setOriginalContent] = useState<Record<string, any>>({});
    const [changes, setChanges] = useState<ContentChange[]>([]);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [courseId, setCourseId] = useState<string>('');
    const [feedbackValidity, setFeedbackValidity] = useState<FeedbackValidity>('pending');
    const [feedbackReason, setFeedbackReason] = useState('');
    const [savingValidity, setSavingValidity] = useState(false);
    const [validitySubmitted, setValiditySubmitted] = useState(false);
    const [isEditingReason, setIsEditingReason] = useState(false);
  
    // Query for ticket data
  const { data: ticketData, isLoading: isTicketLoading } = useQuery(
    [CACHE_KEYS.FEEDBACK_ITEM(ticketId!)],
    () => api.getTicketById(ticketId!),
    {
      enabled: !!ticketId,
      staleTime: 10 * 1000,
      cacheTime: 60 * 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  );

  // Update the content query with proper type annotations
const { data: contentData, isLoading: isContentLoading } = useQuery(
    [CACHE_KEYS.FEEDBACK_ITEM(ticketId!), 'content', ticketData?.content_type, ticketData?.content_id],
    async () => {
      if (!ticketData?.content_type || !ticketData?.content_id) return null;
      
      const { data: contentData, error: contentError } = await supabase
        .from(ticketData.content_type)
        .select('*, feedback')
        .eq('id', ticketData.content_id)
        .single();
  
      if (contentError) throw contentError;
  
      const feedbackArray = contentData.feedback || [];
      const ticketIds = feedbackArray
        .map((f: RawFeedbackData) => f.ticketId)
        .filter((id: string | undefined): id is string => !!id);
  
      if (!ticketIds.includes(ticketData.id)) {
        ticketIds.push(ticketData.id);
      }
  
      const { data: ticketsData, error: ticketsError } = await supabase
        .from('support_tickets')
        .select('id, feedback_validity, feedback_resolved')
        .in('id', ticketIds);
  
      if (ticketsError) throw ticketsError;
  
      const ticketStatusMap = new Map<string, TicketStatus>(
        ticketsData?.map(t => [
          t.id, 
          {
            validity: (t.feedback_validity || 'pending') as FeedbackValidity,
            isResolved: t.feedback_resolved || false
          }
        ])
      );
  
      return {
        ...contentData,
        ticketStatusMap
      };
    },
    {
      enabled: !!ticketData?.content_type && !!ticketData?.content_id,
      staleTime: 10 * 1000,
      cacheTime: 60 * 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  );

  // Manage loading state based on both queries
  useEffect(() => {
    if (!isTicketLoading && (!ticketData?.content_type || !isContentLoading)) {
      setLoading(false);
    }
  }, [isTicketLoading, isContentLoading, ticketData]);

  // Update ticket state when data changes
  useEffect(() => {
    if (ticketData) {
      setTicket(ticketData);
      setFeedbackValidity((ticketData.feedback_validity as FeedbackValidity) || 'pending');
      setFeedbackReason(ticketData.feedback_reason || '');
      setValiditySubmitted(!!ticketData.feedback_validity && !!ticketData.feedback_reason);
      setIsEditingReason(false);
      setIsResolved(!!ticketData.feedback_resolved);
    }
  }, [ticketData]);

  // Update the content state effect with proper typing
useEffect(() => {
    if (contentData && ticketData) {
      setOriginalContent(contentData);
      setCourseId(contentData.course_id);
      
      const feedbackArray = contentData.feedback || [];
      const mappedFeedback = feedbackArray.map((f: RawFeedbackData) => {
        const ticketStatus = f.ticketId ? contentData.ticketStatusMap?.get(f.ticketId) : null;
        const isCurrentTicket = f.ticketId === ticketData.id || 
          (f.userId === ticketData.support_user_id && 
           new Date(f.createdAt).getTime() === new Date(ticketData.created_at).getTime());
  
        if (isCurrentTicket) {
          return {
            ...f,
            contentId: ticketData.content_id,
            ticketId: ticketData.id,
            validity: ticketData.feedback_validity as FeedbackValidity || 'pending',
            isResolved: ticketData.feedback_resolved || false,
            feedbackText: ticketData.body,
            email: ticketData.user?.email
          } as ExtendedContentFeedback;
        }
  
        return {
          ...f,
          contentId: ticketData.content_id,
          ticketId: f.ticketId,
          validity: ticketStatus?.validity || 'pending',
          isResolved: ticketStatus?.isResolved || false
        } as ExtendedContentFeedback;
      });
  
      // Add current ticket if not in mapped feedback
      const currentTicketExists = mappedFeedback.some(
        (f: ExtendedContentFeedback) => f.ticketId === ticketData.id
      );
      
      if (!currentTicketExists) {
        mappedFeedback.push({
          contentId: ticketData.content_id,
          ticketId: ticketData.id,
          validity: ticketData.feedback_validity as FeedbackValidity || 'pending',
          isResolved: ticketData.feedback_resolved || false,
          feedbackText: ticketData.body,
          email: ticketData.user?.email,
          createdAt: ticketData.created_at,
          userId: ticketData.support_user_id
        } as ExtendedContentFeedback);
      }
  
      setContentFeedback(mappedFeedback);
    }
  }, [contentData, ticketData]);
  
  const handleContentChange = (newContent: Record<string, any>) => {
    const newChanges: ContentChange[] = [];
    
    Object.keys(newContent).forEach(key => {
      const oldValue = originalContent[key] || '';
      const newValue = newContent[key] || '';
      
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        newChanges.push({
          field: key,
          oldValue: oldValue,
          newValue: newValue
        });
      }
    });
    
    setChanges(newChanges);
  };

  const sortedFeedback = React.useMemo(() => {
    return [...contentFeedback].sort((a, b) => 
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [contentFeedback]);

  const handleSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleConfirmSave = async () => {
    if (!ticket?.content_type || !ticket?.content_id) return;
    
    try {
      setIsSaving(true);
      const updates = changes.reduce((acc, change) => {
        acc[change.field] = change.newValue;
        return acc;
      }, {} as Record<string, any>);

      await api.updateContentFields(ticket.content_type, ticket.content_id, updates);
      setShowSaveModal(false);
      setChanges([]);
      
      // Invalidate queries to refetch fresh data
      queryClient.invalidateQueries([CACHE_KEYS.FEEDBACK_ITEM(ticket.id)]);
      queryClient.invalidateQueries([CACHE_KEYS.FEEDBACK_ITEM(ticket.id), 'content']);
      queryClient.invalidateQueries(CACHE_KEYS.FEEDBACK_LIST);
      
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleValiditySelection = (validity: FeedbackValidity) => {
    setFeedbackValidity(validity);
    if (!feedbackReason.trim()) {
      // Reset reason when changing validity to force user to provide new reason
      setFeedbackReason('');
    }
    setValiditySubmitted(false); // Reset submitted state when changing validity
  };

  const isResolveEnabled = () => {
    // Only require validity submission, don't require content changes
    return validitySubmitted && (feedbackValidity === 'yes' || feedbackValidity === 'no');
  };

  const isSaveValidityDisabled = () => {
    // Only check for valid reason length and saving state when editing or not yet submitted
    return !feedbackReason.trim() || 
           feedbackReason.trim().length < 3 || 
           savingValidity;
  };

  const handleSaveFeedbackStatus = async () => {
    if (!ticket || isSaveValidityDisabled()) return;
  
    try {
      setSavingValidity(true);
      await api.updateTicketFeedbackValidity(
        ticket.id,
        feedbackValidity,
        feedbackReason.trim()
      );
      
      setValiditySubmitted(true);
      setIsEditingReason(false);
      
      // Invalidate queries to refetch fresh data
      queryClient.invalidateQueries([CACHE_KEYS.FEEDBACK_ITEM(ticket.id)]);
      queryClient.invalidateQueries(CACHE_KEYS.FEEDBACK_LIST);
      
    } catch (error) {
      console.error('Error updating feedback validity:', error);
    } finally {
      setSavingValidity(false);
    }
  };

  const handleResolveFeedback = async () => {
    logger.debug('Attempting to resolve feedback:', {
      ticket: !!ticket,
      isEnabled: isResolveEnabled(),
      validitySubmitted,
      feedbackValidity,
      changes: changes.length
    });

    if (!ticket || !isResolveEnabled()) {
      logger.debug('Resolve disabled - conditions not met');
      return;
    }
    
    try {
      logger.debug('Processing feedback resolution:', ticket.id);
      
      const { error } = await supabase
        .from('support_tickets')
        .update({
          feedback_resolved: true,
          status: 'closed',
          closed_at: new Date().toISOString()
        })
        .eq('id', ticket.id);

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }
      
      logger.debug('Successfully resolved feedback, redirecting to support ticket...');
      navigate(`/admin/support/ticket/${ticket.id}`);
      
    } catch (error) {
      console.error('Error resolving feedback:', error);
    }
  };

  const handleEditReason = () => {
    setIsEditingReason(true);
    setValiditySubmitted(false); // This unlocks all controls
  };

  // Update handleToggleResolved to also update feedback history
  const handleToggleResolved = async () => {
    if (!ticket) return;
    
    try {
      const newResolvedState = !isResolved;
      
      const { error } = await supabase
        .from('support_tickets')
        .update({
          feedback_resolved: newResolvedState,
          status: newResolvedState ? 'closed' : 'open',
          closed_at: newResolvedState ? new Date().toISOString() : null
        })
        .eq('id', ticket.id);
  
      if (error) throw error;
      
      setIsResolved(newResolvedState);
      
      // Update local ticket state
      setTicket(prev => prev ? {
        ...prev,
        feedback_resolved: newResolvedState,
        status: newResolvedState ? 'closed' : 'open',
        closed_at: newResolvedState ? new Date().toISOString() : null
      } : null);
      
      // Invalidate queries to refetch fresh data
      queryClient.invalidateQueries([CACHE_KEYS.FEEDBACK_ITEM(ticket.id)]);
      queryClient.invalidateQueries(CACHE_KEYS.FEEDBACK_LIST);
      
    } catch (error) {
      console.error('Error toggling resolved state:', error);
    }
  };

  const renderValidityControls = () => {
    return (
      <Card className="p-4 bg-gray-50 dark:bg-gray-900">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
          Feedback Validity
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-3">
            <Button
              variant={feedbackValidity === 'yes' ? "primary" : "outline"}
              onClick={() => handleValiditySelection('yes')}
              className={`h-20 flex flex-col items-center justify-center gap-2 ${
                feedbackValidity === 'yes' 
                  ? 'bg-green-500 hover:bg-green-600 focus:ring-green-500' 
                  : 'dark:border-gray-600 dark:text-gray-300'
              }`}
              disabled={validitySubmitted && !isEditingReason}
            >
              <CheckCircle2 className={`w-6 h-6 ${
                feedbackValidity === 'yes' ? 'text-white' : 'dark:text-gray-300'
              }`} />
              <span>Valid</span>
            </Button>
            <Button
              variant={feedbackValidity === 'no' ? "destructive" : "outline"}
              onClick={() => handleValiditySelection('no')}
              className={`h-20 flex flex-col items-center justify-center gap-2 ${
                feedbackValidity === 'no' 
                  ? '' 
                  : 'dark:border-gray-600 dark:text-gray-300'
              }`}
              disabled={validitySubmitted && !isEditingReason}
            >
              <XCircle className={`w-6 h-6 ${
                feedbackValidity === 'no' ? 'text-white' : 'dark:text-gray-300'
              }`} />
              <span>Invalid</span>
            </Button>
          </div>
          
          <Button
            variant={feedbackValidity === 'pending' ? "secondary" : "outline"}
            onClick={() => handleValiditySelection('pending')}
            className={`w-full h-12 flex items-center justify-center gap-2 ${
              feedbackValidity === 'pending' 
                ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-500 text-white'
                : 'dark:border-gray-600 dark:text-gray-300'
            }`}
            disabled={validitySubmitted && !isEditingReason}
          >
            <AlertCircle className={`w-5 h-5 ${
              feedbackValidity === 'pending' ? 'text-white' : 'dark:text-gray-300'
            }`} />
            <span>Pending Review</span>
          </Button>

          <Textarea
            value={feedbackReason}
            onChange={(e) => setFeedbackReason(e.target.value)}
            placeholder={feedbackValidity === 'pending' 
              ? "Select Valid or Invalid to provide reasoning..."
              : "Explain why this feedback is " + (feedbackValidity === 'yes' ? 'valid' : 'invalid') + "... (required)"
            }
            className="min-h-[100px] resize-none bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-400 placeholder-gray-400 dark:placeholder-gray-500"
            disabled={feedbackValidity === 'pending' || (validitySubmitted && !isEditingReason)}
          />

          <Button 
            onClick={validitySubmitted && !isEditingReason ? handleEditReason : handleSaveFeedbackStatus}
            variant="primary"
            className={`w-full h-10 ${
              validitySubmitted && !isEditingReason 
                ? 'bg-orange-500 hover:bg-orange-600 focus:ring-orange-500' 
                : ''
            }`}
            disabled={isSaveValidityDisabled()}
          >
            {savingValidity ? (
              <span className="flex items-center gap-2">
                <Loader2 className="w-4 h-4 animate-spin" />
                Saving...
              </span>
            ) : validitySubmitted && !isEditingReason ? (
              'Edit'
            ) : (
              'Save Validity Status'
            )}
          </Button>
        </div>
      </Card>
    );
  };

  const renderResolutionCard = () => {
    if (!validitySubmitted || feedbackValidity === 'pending') return null;

    return (
      <Card className={`p-4 mt-4 ${
        feedbackValidity === 'yes' 
          ? 'bg-green-50 dark:bg-green-900/20 border-green-200 dark:border-green-800'
          : 'bg-red-50 dark:bg-red-900/20 border-red-200 dark:border-red-800'
      }`}>
        <div className="flex items-center gap-3 mb-4">
          {feedbackValidity === 'yes' ? (
            <CheckCircle2 className="w-5 h-5 text-green-600" />
          ) : (
            <XCircle className="w-5 h-5 text-red-600" />
          )}
          <h3 className={`text-lg font-semibold ${
            feedbackValidity === 'yes'
              ? 'text-green-900 dark:text-green-100'
              : 'text-red-900 dark:text-red-100'
          }`}>
            {isResolved ? 'Feedback Resolved' : 'Ready to Resolve'}
          </h3>
        </div>
        
        {!isResolved && (
          <>
            <p className={`text-sm mb-4 ${
              feedbackValidity === 'yes'
                ? 'text-green-800 dark:text-green-200'
                : 'text-red-800 dark:text-red-200'
            }`}>
              {feedbackValidity === 'yes'
                ? changes.length > 0 
                  ? "You've made content changes. Ready to mark this feedback as resolved?"
                  : "Please make necessary content changes before resolving."
                : "You've marked this feedback as invalid. Ready to mark it as resolved?"}
            </p>
            <Button
              onClick={handleResolveFeedback}
              disabled={!isResolveEnabled()}
              className={`w-full ${
                feedbackValidity === 'yes'
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-red-600 hover:bg-red-700'
              } text-white`}
            >
              Mark Feedback as Resolved
            </Button>
          </>
        )}
        
        {isResolved && (
          <>
            <p className="text-sm mb-4 text-gray-600 dark:text-gray-300">
              This feedback has been marked as resolved. You can mark it as unresolved if needed.
            </p>
            <Button
              onClick={handleToggleResolved}
              className="w-full bg-gray-600 hover:bg-gray-700 text-white"
            >
              Mark as Unresolved
            </Button>
          </>
        )}
      </Card>
    );
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="h-full flex flex-col p-4">
        <Button variant="outline" onClick={() => navigate('/admin/feedback')}>
          <ArrowLeft className="w-4 h-4 mr-1" />
          Back to Dashboard
        </Button>
        <div className="text-center mt-4">Ticket not found.</div>
      </div>
    );
  }

   // In the header section, update the button variants:
   return (
    <div className="h-full flex flex-col">
      <div className="flex-none px-3 py-2 border-b dark:border-gray-700 bg-white dark:bg-gray-800 flex items-center gap-2">
        <Button 
          variant="ghost" 
          onClick={() => navigate('/admin/feedback')} 
          className="h-8 w-8 p-0"
        >
          <ArrowLeft className="w-4 h-4" />
        </Button>
        <h2 className="text-base font-semibold text-gray-900 dark:text-gray-100">
          Ticket #{ticket.short_id} - {ticket.subject}
        </h2>
        <div className="ml-auto flex items-center gap-2">
          {changes.length > 0 && (
            <Button 
              onClick={handleSaveClick}
              type="button"
              variant="primary"
              className="h-8 text-xs flex items-center justify-center gap-1.5"
            >
              <Save className="w-4 h-4" />
              Review Changes ({changes.length})
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => navigate(`/admin/support/ticket/${ticket.id}`)}
            className="h-8 text-xs"
          >
            View Support Ticket
          </Button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-hidden">
        <div className="h-full flex">
            
          {/* Left Side - Editor and Preview */}
          <div className="flex-1 overflow-y-auto p-4 mb-4">
            {renderValidityControls()}
            {renderResolutionCard()}

            <Card className="my-4">
              <CodeEditor
                contentType={ticket.content_type!}
                contentId={ticket.content_id!}
                onChange={handleContentChange}
              />
            </Card>

            <Card className="p-4 bg-gray-50 dark:bg-gray-900/50 mb-4">
              {ticket.content_type && ticket.content_id && courseId && (
                <CategoryMapper
                  contentType={ticket.content_type}
                  contentId={ticket.content_id}
                  courseId={courseId}
                />
              )}
            </Card>

            {/* Preview Section */}
            {ticket?.content_type && ticket?.content_id && courseId && (
              <div className="mt-4">
                <FeedbackPreview
                  contentType={ticket.content_type}
                  contentId={ticket.content_id}
                  userId={ticket.support_user_id}
                  courseId={courseId}
                  currentContent={changes.reduce((acc, change) => {
                    acc[change.field] = change.newValue;
                    return acc;
                  }, {...originalContent})}
                  originalContent={originalContent}
                />
              </div>
            )}
          </div>

          {/* Right Side - Feedback & Validity */}
          <div className="w-1/3 flex-none overflow-y-auto p-4">
          <div className="space-y-4">
            
            
              {/* Current Feedback Card */}
              {ticket && (
                <Card className="p-4 bg-gray-50 dark:bg-gray-900/50">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    Current Feedback
                  </h3>
                  {ticket.content_type && ticket.content_id && (
                    <div className="flex gap-2 text-xs text-gray-500 pb-4">
                        <span className="font-medium capitalize">
                        Type: {ticket.content_type}
                        </span>
                        <span className="font-medium">
                        ID: {ticket.content_id}
                        </span>
                    </div>
                    )}
                  <div className="bg-white dark:bg-gray-800 rounded-lg p-4 mb-4 shadow-sm border border-gray-200 dark:border-gray-700">
                    <p className="text-gray-900 dark:text-gray-100 text-sm">
                      {stripHtml(ticket.body)}
                    </p>
                    <div className="mt-2 flex flex-col gap-2">
                      <div className="flex justify-between items-center text-xs text-gray-500">
                        <span className="font-medium">{ticket.user?.email}</span>
                        <time dateTime={ticket.created_at}>
                          {format(new Date(ticket.created_at), 'MMM d, yyyy')}
                        </time>
                      </div>
                      
                    </div>
                  </div>
                </Card>
              )}

            
              {/* Feedback List */}
              <Card className="p-4 bg-gray-50 dark:bg-gray-900/50">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
                  Content Feedback History
                </h3>
                <ScrollArea className="h-[300px] pr-4">
                  <div className="space-y-3">
                    {sortedFeedback.length === 0 ? (
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        No feedback history available
                      </p>
                    ) : (
                      sortedFeedback.map((feedback, index) => (
                        <FeedbackCard key={index} feedback={feedback} />
                      ))
                    )}
                  </div>
                </ScrollArea>
              </Card>

              

            
            </div>
          </div>
        </div>
      </div>

     {/* Save Changes Modal */}
     <SaveChangesModal
      isOpen={showSaveModal}
      onClose={() => setShowSaveModal(false)}
      changes={changes}
      onConfirm={handleConfirmSave}
      isSaving={isSaving}
    />
  </div>
  );
};

export default FeedbackDetail;