import React from 'react';
import { useQueryClient } from 'react-query';
import FeedbackList from './FeedbackList';
import { CACHE_KEYS, api } from '../../../services/api';

/**
 * The main dashboard that aggregates and displays all feedback-related tickets.
 * It houses the FeedbackList, which shows each feedback ticket row.
 * Now includes data prefetching for better performance.
 */
const FeedbackDashboard: React.FC = () => {
  const queryClient = useQueryClient();

  // Prefetch feedback data when dashboard mounts
  React.useEffect(() => {
    queryClient.prefetchQuery(CACHE_KEYS.FEEDBACK_LIST, api.getFeedbackTickets);
  }, [queryClient]);

  return (
    <div className="h-full flex flex-col bg-gray-50 dark:bg-gray-900">
      <header className="flex-none bg-white dark:bg-gray-800 border-b dark:border-gray-700 px-4 py-3">
        <h1 className="text-lg font-bold text-gray-900 dark:text-gray-100">
          Feedback Dashboard
        </h1>
      </header>
      <main className="flex-1 overflow-y-auto p-4">
        <FeedbackList />
      </main>
    </div>
  );
};

export default FeedbackDashboard;