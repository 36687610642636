import { useState, useEffect, useCallback } from 'react';
import type { 
  DeviceInfo, 
  SwipeDirection, 
  TouchPosition,
  SwipeConfig,
  OrientationType,
  SafeAreaInsets 
} from '../services/api';

export const useDeviceDetection = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isIOS: false,
    isAndroid: false,
    isSafari: false,
    isChrome: false,
    isFirefox: false,
    orientation: 'portrait'
  });

  useEffect(() => {
    const updateDeviceInfo = () => {
      const ua = navigator.userAgent.toLowerCase();
      const width = window.innerWidth;
      
      setDeviceInfo({
        isMobile: width < 768 || /mobile|android|webos|iphone|ipod|blackberry|iemobile|opera mini/.test(ua),
        isTablet: width >= 768 && width < 1024 && (/ipad|android/.test(ua) || window.navigator.maxTouchPoints > 1),
        isDesktop: width >= 1024,
        isIOS: /iphone|ipad|ipod/.test(ua),
        isAndroid: /android/.test(ua),
        isSafari: /safari/.test(ua) && !/chrome/.test(ua),
        isChrome: /chrome/.test(ua),
        isFirefox: /firefox/.test(ua),
        orientation: window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
      });
    };

    updateDeviceInfo();
    window.addEventListener('resize', updateDeviceInfo);
    window.addEventListener('orientationchange', updateDeviceInfo);

    return () => {
      window.removeEventListener('resize', updateDeviceInfo);
      window.removeEventListener('orientationchange', updateDeviceInfo);
    };
  }, []);

  return deviceInfo;
};

export const useSwipe = (config: SwipeConfig) => {
  const [touchStart, setTouchStart] = useState<TouchPosition | null>(null);
  const [touchEnd, setTouchEnd] = useState<TouchPosition | null>(null);
  const [startTime, setStartTime] = useState<number>(0);

  const onTouchStart = useCallback((e: React.TouchEvent) => {
    setTouchEnd(null);
    setStartTime(Date.now());
    setTouchStart({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY
    });
  }, []);

  const onTouchMove = useCallback((e: React.TouchEvent) => {
    if (config.preventScroll) {
      e.preventDefault();
    }
    setTouchEnd({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY
    });
  }, [config.preventScroll]);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;

    const distX = touchEnd.x - touchStart.x;
    const distY = touchEnd.y - touchStart.y;
    const elapsedTime = Date.now() - startTime;

    if (elapsedTime <= config.maxTime) {
      const direction: SwipeDirection = {
        horizontal: Math.abs(distX) >= config.minDistance ? (distX > 0 ? 'right' : 'left') : null,
        vertical: Math.abs(distY) >= config.minDistance ? (distY > 0 ? 'down' : 'up') : null
      };

      return direction;
    }

    return null;
  }, [touchStart, touchEnd, startTime, config.maxTime, config.minDistance]);

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd
  };
};

export const useSafeArea = (): SafeAreaInsets => {
  const [safeArea, setSafeArea] = useState<SafeAreaInsets>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  });

  useEffect(() => {
    const updateSafeArea = () => {
      setSafeArea({
        top: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')) || 0,
        right: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sar')) || 0,
        bottom: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')) || 0,
        left: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal')) || 0
      });
    };

    updateSafeArea();
    window.addEventListener('resize', updateSafeArea);
    window.addEventListener('orientationchange', updateSafeArea);

    return () => {
      window.removeEventListener('resize', updateSafeArea);
      window.removeEventListener('orientationchange', updateSafeArea);
    };
  }, []);

  return safeArea;
};

export const useOrientation = (): OrientationType => {
  const [orientation, setOrientation] = useState<OrientationType>(
    window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    
    const updateOrientation = (e: MediaQueryListEvent | MediaQueryList) => {
      setOrientation(e.matches ? 'portrait' : 'landscape');
    };

    // Initial check
    updateOrientation(mediaQuery);

    // Add listener for orientation changes
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', updateOrientation);
    } else {
      // Fallback for older browsers
      mediaQuery.addListener(updateOrientation);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', updateOrientation);
      } else {
        // Fallback for older browsers
        mediaQuery.removeListener(updateOrientation);
      }
    };
  }, []);

  return orientation;
};

// Utility functions
export const isMobilePlatform = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isIOSPlatform = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const preventDoubleTapZoom = (e: TouchEvent) => {
  e.preventDefault();
  e.stopPropagation();
};