import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import {
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  RefreshCcw,
  Edit,
  Trash2,
  X,
  Lock,
  Crown,
  Sparkle,
  Scale,
  Shuffle,
  TargetIcon,
  MessageCircle
} from 'lucide-react';
import {
  api,
  Flashcard,
  FlashcardCategory,
  UserFlashcardProgress,
  CustomFlashcard,
  TIER_IDS,
  FilterType
} from '../../services/api';
import { auth } from '../../services/auth';
import {
  getCardsCompleted,
  updateCardsCompletedInStorage,
  getSpacedRepetitionUnlocked,
  setSpacedRepetitionUnlocked,
  setConfidenceLevelInStorage
} from '../../utils/FlashcardUtils';
import {
  getCardsForSpacedRepetition,
  updateCardReviewSchedule,
  DueCardInfo
} from '../../utils/spaced-repetition-utils';
import FeedbackPopup from './quiz-tools/FeedbackPopup';
import LoadingScreen from './common/LoadingScreen';
import useKeyPress from '../../hooks/useKeyPress';
import Pagination from './common/Pagination';
import { Switch } from '../ui/Switch';
import { getBrowserInstanceId } from '../../utils/browserInstance';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import UpgradeModal from './common/UpgradeModal';
import { useFlashcardCounts } from '../../hooks/useFlashcardCounts';

interface KeslerCardsProps {
  courseId: string;
  userId: string;
}

const FREE_CARD_LIMIT = 25;

export type ConfidenceLevel = 'guessing' | 'maybe' | 'confident' | null;

const AlertMessage: React.FC<{ message: string }> = ({ message }) => (
  <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded shadow-md z-50 animate-fade-in-out">
    <p>{message}</p>
  </div>
);

// Initialize random/spaced toggles from localStorage
const initializeToggleStates = () => {
  const savedRandomMode = localStorage.getItem('keslerCardsRandomMode');
  const savedSpacedRepetitionMode = localStorage.getItem('keslerCardsSpacedRepetitionMode');

  // If no saved state, default both to false (linear mode)
  if (!savedRandomMode && !savedSpacedRepetitionMode) {
    localStorage.setItem('keslerCardsRandomMode', 'false');
    localStorage.setItem('keslerCardsSpacedRepetitionMode', 'false');
    return { isRandomMode: false, isSpacedRepetitionMode: false };
  }

  const parsedRandomMode = savedRandomMode ? JSON.parse(savedRandomMode) : false;
  const parsedSpacedRepetitionMode = savedSpacedRepetitionMode ? JSON.parse(savedSpacedRepetitionMode) : false;

  // If somehow both got set to true, default to spaced repetition
  if (parsedRandomMode && parsedSpacedRepetitionMode) {
    localStorage.setItem('keslerCardsRandomMode', 'false');
    return { isRandomMode: false, isSpacedRepetitionMode: true };
  }

  return {
    isRandomMode: parsedRandomMode,
    isSpacedRepetitionMode: parsedSpacedRepetitionMode
  };
};

function KeslerCards({ courseId, userId }: KeslerCardsProps) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Possibly passed in as query or location.state
  const initialCategory = searchParams.get('category');
  const showCreateForm = searchParams.get('showCreateForm') === 'true';

  // State for “StudyTask” flows
  const [studyTaskId, setStudyTaskId] = useState<string | null>(null);
  const [returnUrl, setReturnUrl] = useState<string | null>(null);

  // Feedback Popup
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  // Page-level states
  const [hasInitialized, setHasInitialized] = useState(false);
  const [hasSeenBack, setHasSeenBack] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  // True only during initial data load
  const [isLoading, setLoading] = useState(true);

  // Filter states
  const [selectedCategory, setSelectedCategory] = useState<string | null>(initialCategory);
  const [confidenceFilter, setConfidenceFilter] = useState<FilterType>(() => {
    const storedFilter = localStorage.getItem('keslerCardsConfidenceFilter');
    return (storedFilter as FilterType) || 'All';
  });
  const [isCreateCardsActive, setIsCreateCardsActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showCreateCardForm, setShowCreateCardForm] = useState(showCreateForm);
  const [isFilterMinimized, setIsFilterMinimized] = useState(false);
  const [isCardMinimized, setIsCardMinimized] = useState(false);

  // Card display states
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showingFront, setShowingFront] = useState(true);
  const [isFlipping, setIsFlipping] = useState(false);
  const [isPreviouslyAnswered, setIsPreviouslyAnswered] = useState(false);
  const [confidenceLevel, setConfidenceLevel] = useState<ConfidenceLevel>(null);
  const [displayedConfidenceLevel, setDisplayedConfidenceLevel] = useState<ConfidenceLevel>(null);

  // Card editing states
  const [editingCard, setEditingCard] = useState<CustomFlashcard | null>(null);
  const [tagInput, setTagInput] = useState('');
  const editTagInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [filteredEditTags, setFilteredEditTags] = useState<string[]>([]);
  const [showEditTagDropdown, setShowEditTagDropdown] = useState(false);

  // For custom card creation
  const [newCardFront, setNewCardFront] = useState('');
  const [newCardBack, setNewCardBack] = useState('');
  const [newCardTags, setNewCardTags] = useState<string[]>([]);
  const [newCardTagInput, setNewCardTagInput] = useState('');
  const newCardTagInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [newCardColor, setNewCardColor] = useState<string>('#E2E8F0');
  const [isCreatingCard, setIsCreatingCard] = useState(false);
  const [expandedCards, setExpandedCards] = useState<{ [key: string]: boolean }>({});

  // Focus states for user input (to block hotkeys)
  const [isInputActive, setIsInputActive] = useState(false);
  const inputRefs = useRef<(HTMLTextAreaElement | HTMLInputElement | null)[]>([]);

  // Main data arrays
  const [cards, setCards] = useState<Flashcard[]>([]);
  const [customCards, setCustomCards] = useState<CustomFlashcard[]>([]);
  const [categories, setCategories] = useState<FlashcardCategory[]>([]);
  const [userProgress, setUserProgress] = useState<UserFlashcardProgress[]>([]);

  // Stats
  const [cardsCompleted, setCardsCompleted] = useState(() => 
    getCardsCompleted(userId, courseId)
  );
  const [isSpacedRepetitionUnlocked, setIsSpacedRepetitionUnlockedState] = useState(() => 
    getSpacedRepetitionUnlocked(userId, courseId)
  );
  const [dueCards, setDueCards] = useState<DueCardInfo[]>([]);
  const [spacedRepetitionCompleted, setSpacedRepetitionCompleted] = useState(false);

  // Random & spaced toggles
  const initialToggleStates = initializeToggleStates();
  const [isRandomMode, setIsRandomMode] = useState<boolean>(initialToggleStates.isRandomMode);
  const [isSpacedRepetitionMode, setIsSpacedRepetitionMode] = useState<boolean>(
    initialToggleStates.isSpacedRepetitionMode
  );

  // Access info
  const { userCourseAccesses, isLoading: accessLoading } = useUserCourseAccess();
  const [remainingFreeCards, setRemainingFreeCards] = useState<number>(FREE_CARD_LIMIT);

  // For upgrade modal
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  // Browser instance for tracking
  const [browserInstanceId, setBrowserInstanceId] = useState<string | null>(null);

  const showAlert = useCallback((message: string) => {
    setAlertMessage(message);
    setTimeout(() => setAlertMessage(null), 2500);
  }, []);

  const handleUpgradeClick = useCallback(() => {
    setIsUpgradeModalOpen(true);
  }, []);

  const handleCloseUpgradeModal = useCallback(() => {
    setIsUpgradeModalOpen(false);
  }, []);

  // Check if we should show an Upgrade button
  const showUpgradeButton = useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return false;
    const currentAccess = userCourseAccesses.find(access => access.course_id === courseId);
    if (!currentAccess) return false;
    return (
      currentAccess.tier_id === TIER_IDS.FREE_TRIAL ||
      currentAccess.tier_id === TIER_IDS.MENTOR_ONLY
    );
  }, [userCourseAccesses, courseId]);

  // Whether the user’s content is locked
  const isContentLocked = useCallback(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return true;

    const hasFullAccess = courseAccesses.some(
      access =>
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY ||
        access.tier_id === TIER_IDS.FULL_ACCESS
    );
    if (hasFullAccess) return false;

    // So any free or mentor plan => locked
    const onlyHasRestrictedAccess = courseAccesses.every(
      access =>
        access.tier_id === TIER_IDS.FREE_TRIAL ||
        access.tier_id === TIER_IDS.MENTOR_ONLY
    );
    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  // The main hook for filtering & counting
  const {
    counts,
    filteredCards,
    dueCards: hookDueCards,
    isCalculating,
    getFlashcardCount, // Add this back
    updateFlashcardCount // Add this back
  } = useFlashcardCounts({
    userId,
    courseId,
    regularCards: cards,
    customCards,
    userProgress,
    selectedCategory,
    confidenceFilter,
    isCreateMode: isCreateCardsActive,
    isRandomMode,
    isSpacedRepetitionMode,
    selectedTags
  });

  // Update useEffect to sync dueCards from hook
  useEffect(() => {
    setDueCards(hookDueCards);
  }, [hookDueCards]);

  // If spaced repetition is on, once the filtered deck hits 0, show “all done”
  useEffect(() => {
    if (isSpacedRepetitionMode && filteredCards.length === 0) {
      setSpacedRepetitionCompleted(true);
    } else {
      setSpacedRepetitionCompleted(false);
    }
  }, [isSpacedRepetitionMode, filteredCards.length]);

  // On mount, get the browser instance
  useEffect(() => {
    getBrowserInstanceId().then(id => setBrowserInstanceId(id));
  }, []);

  /**
   * The one-time data fetch on mount. We only set `isLoading` around
   * the initial load from the server, so it won’t flicker on every re-render.
   */
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');

      const [cats, flashcardsData, progressData, customData, stats] = await Promise.all([
        api.getFlashcardCategories(courseId),
        api.getFlashcards(courseId, user.id),
        api.getUserFlashcardProgress(user.id, courseId),
        api.getCustomFlashcards(user.id, courseId),
        api.getUserStudyStats(user.id, courseId)
      ]);

      setCategories(cats);
      setCards(flashcardsData);
      setUserProgress(progressData);
      setCustomCards(customData);

      const completedCount = stats.cardsCompleted;
      setCardsCompleted(completedCount);
      updateCardsCompletedInStorage(userId, courseId, completedCount);

      // Check if we just unlocked spaced repetition
      if (completedCount >= 10 && !isSpacedRepetitionUnlocked) {
        setIsSpacedRepetitionUnlockedState(true);
        setSpacedRepetitionUnlocked(userId, courseId, true);
        showAlert('Study Looping mode unlocked!');
      }
    } catch (err) {
      console.error(err);
      setError('Failed to load KeslerCards data');
    } finally {
      setLoading(false);
    }
  }, [
    courseId,
    userId,
    isSpacedRepetitionUnlocked,
    setIsSpacedRepetitionUnlockedState,
    showAlert
  ]);

  // Actually run the fetch on mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // If user is on a restricted plan, limit custom card creation
  useEffect(() => {
    if (isContentLocked()) {
      setRemainingFreeCards(Math.max(0, FREE_CARD_LIMIT - customCards.length));
    } else {
      setRemainingFreeCards(Infinity);
    }
  }, [customCards.length, isContentLocked]);

  /**
   * Possibly read location.state if we came from a “study task”.
   * This sets up category, random/spaced mode, etc. once, not on every render.
   */
  const handleStudyTaskInit = useCallback(() => {
    if (!hasInitialized && location.state) {
      const {
        flashcardCategoryId,
        returnUrl,
        item_id,
        enableLoopingMode
      } = location.state;

      if (returnUrl) setReturnUrl(returnUrl);
      if (item_id) setStudyTaskId(item_id);

      if (enableLoopingMode && isSpacedRepetitionUnlocked) {
        setIsSpacedRepetitionMode(true);
        setIsRandomMode(false);
        localStorage.setItem('keslerCardsSpacedRepetitionMode', 'true');
        localStorage.setItem('keslerCardsRandomMode', 'false');
      } else {
        setIsRandomMode(true);
        setIsSpacedRepetitionMode(false);
        localStorage.setItem('keslerCardsRandomMode', 'true');
        localStorage.setItem('keslerCardsSpacedRepetitionMode', 'false');
      }

      if (flashcardCategoryId) {
        setSelectedCategory(flashcardCategoryId);
        setConfidenceFilter('Unanswered');
        localStorage.setItem('keslerCardsConfidenceFilter', 'Unanswered');
      }

      setHasInitialized(true);
    }
  }, [location.state, hasInitialized, isSpacedRepetitionUnlocked]);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      // Clear filter on unmount unless we're in a study task
      if (!location.state?.flashcardCategoryId) {
        localStorage.removeItem('keslerCardsConfidenceFilter');
      }
    };
  }, [location.state]);

  // Run once after the component mounts
  useEffect(() => {
    handleStudyTaskInit();
  }, [handleStudyTaskInit]);

  // FLIP card
  const handleFlipCard = useCallback(async () => {
    setIsFlipping(true);
    const flippingToBack = showingFront;

    // Wait a bit for the flip start
    await new Promise(resolve => setTimeout(resolve, 150));
    setShowingFront(!showingFront);

    if (flippingToBack) {
      setHasSeenBack(true);
    }

    // Wait a bit more for flip animation to finish
    await new Promise(resolve => setTimeout(resolve, 150));
    setIsFlipping(false);
  }, [showingFront]);

  const canSelectConfidence = useMemo(() => {
    return !showingFront && !isFlipping;
  }, [showingFront, isFlipping]);

  const handleLockedConfidenceClick = () => {
    if (showingFront) {
      showAlert('Please flip the card to see the answer before rating your confidence.');
    }
  };

  // The "current" card we are showing
  const currentCard = useMemo(() => {
    if (filteredCards.length > 0 && currentCardIndex < filteredCards.length) {
      return filteredCards[currentCardIndex];
    }
    return null;
  }, [filteredCards, currentCardIndex]);

  // Update effect when current card changes
  useEffect(() => {
    if (!currentCard) return;

    if (isSpacedRepetitionMode) {
      // In spaced repetition mode, find the card in dueCards to get its confidence
      const dueCardInfo = dueCards.find(dc => dc.card.id === currentCard.id);
      if (dueCardInfo) {
        setConfidenceLevel(dueCardInfo.confidenceLevel);
        setDisplayedConfidenceLevel(dueCardInfo.confidenceLevel);
        setIsPreviouslyAnswered(true);
        setHasSeenBack(true);
      } else {
        setConfidenceLevel(null);
        setDisplayedConfidenceLevel(null);
        setIsPreviouslyAnswered(false);
        setHasSeenBack(false);
      }
    } else {
      // Regular mode - check userProgress or custom card confidence
      const storedConfidence = 'side_1_content' in currentCard
        ? userProgress.find(p => p.flashcard_id === currentCard.id)?.confidence_level ?? null
        : currentCard.confidence_level;

      if (storedConfidence) {
        setConfidenceLevel(storedConfidence);
        setDisplayedConfidenceLevel(storedConfidence);
        setIsPreviouslyAnswered(true);
        setHasSeenBack(true);
      } else {
        setConfidenceLevel(null);
        setDisplayedConfidenceLevel(null);
        setIsPreviouslyAnswered(false);
        setHasSeenBack(false);
      }
    }
    setShowingFront(true);
  }, [currentCard, userProgress, isSpacedRepetitionMode, dueCards]);

  const handleNextCard = useCallback(() => {
    if (!filteredCards.length) return;
  
    // In spaced repetition mode, clicking next after seeing the back means user confirms current confidence
    if (isSpacedRepetitionMode && hasSeenBack && currentCard && displayedConfidenceLevel) {
      const isCustomCard = !('side_1_content' in currentCard);
      const cardId = currentCard.id;
      
      // Update the review schedule using the current confidence level
      const now = new Date();
      updateCardReviewSchedule(
        userId,
        courseId,
        cardId,
        displayedConfidenceLevel, // Always use displayed level since next = confirm
        isCustomCard,
        now
      ).catch(error => {
        console.error('Failed to update review schedule:', error);
      });
  
      // This will remove the card and give us the next valid index
      const { currentIndex } = updateFlashcardCount(
        cardId, 
        displayedConfidenceLevel, 
        displayedConfidenceLevel, // Same level since we're confirming it
        isCustomCard
      );
  
      // Only mark as completed if this really was the last card
      if (filteredCards.length <= 1) {
        setSpacedRepetitionCompleted(true);
        return;
      }
  
      // Batch state updates for smoother transition
      setIsFlipping(true);
      setShowingFront(true);
      setHasSeenBack(false);
      
      // Keep the animation timeout but move the currentIndex update with other state changes
      if (currentIndex >= 0) {
        setCurrentCardIndex(currentIndex);
        setIsPreviouslyAnswered(false);
      }
      
      // Just reset the flip animation
      setTimeout(() => {
        setIsFlipping(false);
      }, 300);
      
      return;
    }
    
    // Regular mode - only update if confidence changed
    if (hasSeenBack && confidenceLevel !== null && currentCard) {
      const isCustomCard = !('side_1_content' in currentCard);
      const cardId = currentCard.id;
      const oldLevel = displayedConfidenceLevel;
  
      const { wasRemoved, currentIndex } = updateFlashcardCount(
        cardId, 
        oldLevel, 
        confidenceLevel, 
        isCustomCard
      );
  
      if (wasRemoved) {
        // Batch state updates for smoother transition
        setIsFlipping(true);
        setShowingFront(true);
        setHasSeenBack(false);
        
        if (currentIndex >= 0) {
          setCurrentCardIndex(currentIndex);
          setConfidenceLevel(null);
          setDisplayedConfidenceLevel(null);
          setIsPreviouslyAnswered(false);
        }
        
        // Just reset the flip animation
        setTimeout(() => {
          setIsFlipping(false);
        }, 300);
        
        return;
      }
    }
  
    // Default next card behavior - batch state updates
    setIsFlipping(true);
    setShowingFront(true);
    setHasSeenBack(false);

    // Calculate next index before the timeout
    const nextIdx = currentCardIndex + 1 >= filteredCards.length ? 0 : currentCardIndex + 1;
    setCurrentCardIndex(nextIdx);
    
    // Only reset confidence states in non-spaced repetition mode
    if (!isSpacedRepetitionMode) {
      setConfidenceLevel(null);
      setDisplayedConfidenceLevel(null);
      setIsPreviouslyAnswered(false);
    }
  
    // Just reset the flip animation
    setTimeout(() => {
      setIsFlipping(false);
    }, 300);
  }, [
    filteredCards,
    hasSeenBack,
    confidenceLevel,
    currentCard,
    displayedConfidenceLevel,
    isSpacedRepetitionMode,
    userId,
    courseId,
    updateFlashcardCount,
    currentCardIndex
  ]);

  const canMoveToNextCard = useMemo(() => {
    if (!currentCard) return false;
    
    // If previously answered, always allow moving
    if (isPreviouslyAnswered) return true;
    
    // Must see the back of the card first
    if (!hasSeenBack) return false;
    
    // In spaced repetition mode, if we've seen the back, we can move next
    // because there's always a confidence level (either current or new)
    if (isSpacedRepetitionMode && hasSeenBack && displayedConfidenceLevel) return true;
    
    // For regular mode, require a new confidence level selection
    return hasSeenBack && confidenceLevel !== null;
  }, [
    currentCard, 
    isPreviouslyAnswered, 
    hasSeenBack, 
    confidenceLevel, 
    isSpacedRepetitionMode,
    displayedConfidenceLevel
  ]);

  
  // Add this check for previous card availability
  const canMoveToPreviousCard = useCallback(() => {
    if (!filteredCards.length || currentCardIndex === 0) return false;
    
    // Check if previous card has a confidence level
    const prevCard = filteredCards[currentCardIndex - 1];
    const prevConfidenceLevel = 'side_1_content' in prevCard 
      ? userProgress.find(p => p.flashcard_id === prevCard.id)?.confidence_level
      : prevCard.confidence_level;
      
    return prevConfidenceLevel !== null;
  }, [filteredCards, currentCardIndex, userProgress]);

  const handlePreviousCard = useCallback(() => {
    if (!filteredCards.length || !canMoveToPreviousCard()) return;
  
    // Start animation
    setIsFlipping(true);
    
    // Calculate the new index
    const newIndex = currentCardIndex - 1;
    const prevCard = filteredCards[newIndex];
    
    // Get confidence info from the current filtered card
    const prevConfidenceLevel = 'side_1_content' in prevCard 
      ? userProgress.find(p => p.flashcard_id === prevCard.id)?.confidence_level ?? null
      : prevCard.confidence_level;
    
    // Update states
    setShowingFront(true);
    setConfidenceLevel(prevConfidenceLevel);
    setDisplayedConfidenceLevel(prevConfidenceLevel);
    setIsPreviouslyAnswered(prevConfidenceLevel !== null);
    setHasSeenBack(false);
    setCurrentCardIndex(newIndex);
  
    // Reset animation after delay
    setTimeout(() => {
      setIsFlipping(false);
    }, 300);
  }, [filteredCards, currentCardIndex, userProgress, canMoveToPreviousCard]);

  // Spaced repetition off
  const handleTurnOffSpacedRepetition = useCallback(() => {
    setIsSpacedRepetitionMode(false);
    setIsRandomMode(true);
    setConfidenceFilter('Unanswered');
    localStorage.setItem('keslerCardsSpacedRepetitionMode', 'false');
    localStorage.setItem('keslerCardsRandomMode', 'true');
    setSpacedRepetitionCompleted(false);
  }, []);

  // Setting confidence
  const handleConfidenceLevel = useCallback(
    async (level: ConfidenceLevel) => {
      if (!currentCard || level === null || !browserInstanceId) return;
      if (!hasSeenBack) {
        showAlert('Please flip the card to see the answer before rating your confidence.');
        return;
      }
  
      const cardId = currentCard.id;
      const isCustomCard = !('side_1_content' in currentCard);
      const oldLevel = displayedConfidenceLevel;
  
      try {
        const user = await auth.getCurrentUser();
        if (!user) throw new Error('User not authenticated');
  
        // Batch the initial state updates
        setConfidenceLevelInStorage(userId, courseId, cardId, level);
        setDisplayedConfidenceLevel(level);
        setConfidenceLevel(level);
        setIsPreviouslyAnswered(true);
  
        // Get removal info for navigation decisions
        const { wasRemoved, currentIndex } = updateFlashcardCount(
          cardId,
          oldLevel,
          level,
          isCustomCard
        );
  
        // Update review schedule
        const now = new Date();
        if (isSpacedRepetitionMode || oldLevel !== level) {
          await updateCardReviewSchedule(
            userId,
            courseId,
            cardId,
            level,
            isCustomCard,
            now,
            true
          );
        }
  
        // API updates (unchanged)
        const nowISO = now.toISOString();
        if (!isCustomCard) {
          const existingProgress = userProgress.find(p => p.flashcard_id === cardId);
          const progressData = {
            user_id: user.id,
            course_id: courseId,
            flashcard_id: cardId,
            confidence_level: level,
            last_reviewed: nowISO,
            browser_instance_id: browserInstanceId,
            version: existingProgress ? (existingProgress.version || 0) + 1 : 1,
            synced: 0
          };
          const updatedProgress = await api.updateUserFlashcardProgress(progressData);
  
          setUserProgress(prev => {
            if (existingProgress) {
              return prev.map(p => (p.flashcard_id === cardId ? updatedProgress : p));
            }
            return [...prev, updatedProgress];
          });
        } else {
          const updatedCard = {
            ...currentCard,
            confidence_level: level,
            last_reviewed: nowISO,
            version: (currentCard.version || 0) + 1
          };
          await api.updateCustomFlashcard(cardId, updatedCard);
  
          setCustomCards(prev =>
            prev.map(c => (c.id === cardId ? { ...c, ...updatedCard } : c))
          );
        }
  
        // Handle cardsCompleted stat
        const wasPrevNull = oldLevel === null;
        if (wasPrevNull) {
          const newCount = cardsCompleted + 1;
          setCardsCompleted(newCount);
          updateCardsCompletedInStorage(userId, courseId, newCount);
  
          if (newCount === 10 && !isSpacedRepetitionUnlocked) {
            setIsSpacedRepetitionUnlockedState(true);
            setSpacedRepetitionUnlocked(userId, courseId, true);
            showAlert('Study Looping mode unlocked!');
          }
        }
  
        // Simplified navigation logic
        if (isSpacedRepetitionMode && filteredCards.length <= 1) {
          setSpacedRepetitionCompleted(true);
        } else {
          // Batch state updates for transition
          setIsFlipping(true);
          setShowingFront(true);
          setHasSeenBack(false);
          
          if (wasRemoved) {
            // Card was removed - update to the new index
            setCurrentCardIndex(currentIndex);
            setConfidenceLevel(null);
            setDisplayedConfidenceLevel(null);
            setIsPreviouslyAnswered(false);
          } else if (confidenceFilter === 'All' || confidenceFilter === 'CreateCards') {
            // Advance to next card
            const nextIndex = (currentCardIndex + 1) % filteredCards.length;
            setCurrentCardIndex(nextIndex);
            setConfidenceLevel(null);
            setDisplayedConfidenceLevel(null);
            setIsPreviouslyAnswered(false);
          }
          
          // Reset flip animation
          setTimeout(() => {
            setIsFlipping(false);
          }, 300);
        }
  
      } catch (err) {
        console.error('Failed to update card progress:', err);
        showAlert('Failed to update card. Please try again.');
  
        // Roll back local changes
        setConfidenceLevelInStorage(userId, courseId, cardId, oldLevel || null);
        updateFlashcardCount(cardId, level, oldLevel, isCustomCard);
        setDisplayedConfidenceLevel(oldLevel);
        setConfidenceLevel(oldLevel);
      }
    },
    [
      currentCard,
      displayedConfidenceLevel,
      hasSeenBack,
      userId,
      courseId,
      userProgress,
      browserInstanceId,
      showAlert,
      updateFlashcardCount,
      confidenceFilter,
      cardsCompleted,
      isSpacedRepetitionUnlocked,
      isSpacedRepetitionMode,
      currentCardIndex,
      filteredCards.length
    ]
  );

  // Keyboard shortcuts
  const handleSpacebar = useCallback(() => {
    if (!isInputActive) {
      handleFlipCard();
    }
  }, [handleFlipCard, isInputActive]);

  const handleTabPress = useCallback(
    (event?: KeyboardEvent) => {
      if (!isInputActive) {
        if (event) {
          event.preventDefault();
        }
      }
    },
    [isInputActive]
  );

  const handleShiftPress = useCallback(() => {
    // No-op or we could confirm selected confidence
  }, []);

   // IMPORTANT: Disable hotkeys if the Feedback Popup is open
   const isKeyPressEnabled = useCallback(() => {
    return !showFeedbackPopup && !showCreateCardForm && !isInputActive;
  }, [showFeedbackPopup, showCreateCardForm, isInputActive]);

  useKeyPress(' ', handleSpacebar, isKeyPressEnabled);
  useKeyPress('Tab', handleTabPress, isKeyPressEnabled);
  useKeyPress('Shift', handleShiftPress, isKeyPressEnabled);

  // Input focus control
  const handleInputFocus = () => setIsInputActive(true);
  const handleInputBlur = () => setIsInputActive(false);
  const addInputRef = (el: HTMLTextAreaElement | HTMLInputElement | null) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  // Make sure we update the free-cards-remaining on length changes
  useEffect(() => {
    if (isContentLocked()) {
      setRemainingFreeCards(Math.max(0, FREE_CARD_LIMIT - customCards.length));
    } else {
      setRemainingFreeCards(Infinity);
    }
  }, [customCards.length, isContentLocked]);

  /**
   * Return to the parent study task or URL
   */
  const handleBackToStudyTask = useCallback(() => {
    if (studyTaskId) {
      navigate(`/course/${courseId}/study-plan/${studyTaskId}`, {
        state: { returnedFrom: 'kesler-cards' },
        replace: true
      });
    } else if (returnUrl) {
      navigate(returnUrl, {
        state: { returnedFrom: 'kesler-cards' },
        replace: true
      });
    }
  }, [studyTaskId, returnUrl, courseId, navigate]);

  const renderReturnButton = () => {
    if (studyTaskId || returnUrl) {
      return (
        <div className="flex justify-center mt-6">
          <button
            onClick={handleBackToStudyTask}
            className="px-6 py-3 bg-red-500 dark:bg-red-600 text-white dark:text-gray-100 rounded-lg shadow-md 
              hover:bg-red-600 dark:hover:bg-red-700 
              focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 focus:ring-opacity-75 
              transition-transform transform hover:scale-105 active:scale-95
              font-medium"
          >
            Back to Study Task
          </button>
        </div>
      );
    }
    return null;
  };

  // Filter button helpers
  const getFilterButtonText = (f: FilterType) => {
    return f === 'CreateCards' ? 'Create' : f;
  };

  const getFilterIcon = (f: FilterType) => {
    const iconSize = 'w-4 h-4';
    switch (f) {
      case 'Confident': return <Sparkle className={iconSize} />;
      case 'Maybe': return <Scale className={iconSize} />;
      case 'Guessing': return <Shuffle className={iconSize} />;
      case 'CreateCards': return <Edit className={iconSize} />;
      case 'Unanswered': return <TargetIcon className={iconSize} />;
      default: return null;
    }
  };

  const getFilterButtonStyle = (f: FilterType, active: boolean) => {
    const base = 'relative rounded text-xs md:text-sm flex items-center overflow-hidden transition-colors duration-200';
    if (active) {
      switch (f) {
        case 'Confident':
          return `${base} bg-green-500 text-white hover:bg-green-600`;
        case 'Maybe':
          return `${base} bg-yellow-500 text-white hover:bg-yellow-600`;
        case 'Guessing':
          return `${base} bg-red-500 text-white hover:bg-red-600`;
        default:
          // "All" or "CreateCards" etc.
          return `${base} bg-primary-blue text-white`;
      }
    } else {
      switch (f) {
        case 'Confident':
          return `${base} bg-green-100/70 text-green-700 hover:bg-green-200/70 dark:bg-green-800/50 dark:text-green-300 dark:hover:bg-green-700/50`;
        case 'Maybe':
          return `${base} bg-yellow-100/70 text-yellow-700 hover:bg-yellow-200/70 dark:bg-yellow-800/50 dark:text-yellow-300 dark:hover:bg-yellow-700/50`;
        case 'Guessing':
          return `${base} bg-red-100/70 text-red-700 hover:bg-red-200/70 dark:bg-red-800/50 dark:text-red-300 dark:hover:bg-red-700/50`;
        default:
          return `${base} bg-gray-300 text-gray-700 hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500`;
      }
    }
  };

  const handleFilterChange = useCallback(
    (filter: FilterType) => {
      if (filter === 'CreateCards') {
        setIsCreateCardsActive(prev => !prev);
        setShowCreateCardForm(prev => (prev ? false : prev));
        if (!isCreateCardsActive) {
          setConfidenceFilter('All');
        }
      } else if (filter === 'All') {
        setIsCreateCardsActive(false);
        setConfidenceFilter('All');
        setSelectedTags([]);
      } else {
        setConfidenceFilter(filter);
      }
      setCurrentCardIndex(0);

      // If we pick Unanswered, reset the card state
      if (filter === 'Unanswered') {
        setShowingFront(true);
        setHasSeenBack(false);
        setConfidenceLevel(null);
        setDisplayedConfidenceLevel(null);
        setIsPreviouslyAnswered(false);
      }

      // If we are leaving create mode, clear tags
      if (isCreateCardsActive && filter !== 'CreateCards') {
        setSelectedTags([]);
      }
    },
    [isCreateCardsActive]
  );

  const renderFilterButtons = () => {
    const filterList: FilterType[] = [
      'All',
      'CreateCards',
      'Unanswered',
      'Guessing',
      'Maybe',
      'Confident'
    ];

    const isActive = (f: FilterType) => {
      if (f === 'CreateCards') return isCreateCardsActive;
      if (f === 'All') return confidenceFilter === 'All' && !isCreateCardsActive;
      return confidenceFilter === f;
    };

    const handleXClick = (f: FilterType) => (e: React.MouseEvent) => {
      e.stopPropagation();
      if (f === 'CreateCards') {
        setIsCreateCardsActive(false);
      } else {
        setConfidenceFilter('All');
      }
    };

    return (
      <div className="flex flex-wrap gap-2">
        {filterList.map(f => {
          const active = isActive(f);
          const icon = getFilterIcon(f);
          const count = getFlashcardCount(f);

          return (
            <button
              key={f}
              onClick={() => handleFilterChange(f)}
              className={getFilterButtonStyle(f, active)}
            >
              <span className="px-2 py-1 flex items-center gap-2">
                {icon}
                <span>{getFilterButtonText(f)}</span>
                <span>({count})</span>
              </span>
              {active && f !== 'All' && (
                <span
                  className={`
                    flex items-center justify-center h-full px-2 ml-1 
                    ${
                      f === 'CreateCards'
                        ? 'bg-red-500'
                        : f === 'Confident'
                        ? 'bg-green-600'
                        : f === 'Maybe'
                        ? 'bg-yellow-600'
                        : f === 'Guessing'
                        ? 'bg-red-600'
                        : 'bg-red-400'
                    }
                  `}
                >
                  <X size={14} className="cursor-pointer text-white" onClick={handleXClick(f)} />
                </span>
              )}
            </button>
          );
        })}
      </div>
    );
  };

  // Sort categories for the category dropdown
  const sortCategories = (a: FlashcardCategory, b: FlashcardCategory) => {
    const categoryOrder = ['GEM', 'SIM', 'CALC'];
    const parseTitle = (title: string) => {
      const match = title.match(/\[(\w+)\]\s*(\d+)\.(\d+)/);
      if (match) {
        return {
          category: match[1],
          chapterNum: parseInt(match[2]),
          sectionNum: parseInt(match[3])
        };
      }
      return null;
    };

    const parseA = parseTitle(a.sub_flashcard_category_name);
    const parseB = parseTitle(b.sub_flashcard_category_name);
    if (!parseA || !parseB) return 0;
    if (parseA.chapterNum !== parseB.chapterNum) {
      return parseA.chapterNum - parseB.chapterNum;
    }
    if (parseA.sectionNum !== parseB.sectionNum) {
      return parseA.sectionNum - parseB.sectionNum;
    }
    return categoryOrder.indexOf(parseA.category) - categoryOrder.indexOf(parseB.category);
  };

  const sortedCategories = useMemo(() => {
    return [...categories].sort(sortCategories);
  }, [categories]);

  // Update the SpacedRepetitionCompletionScreen component to use due cards info
  const SpacedRepetitionCompletionScreen: React.FC<{
    onTurnOffSpacedRepetition: () => void;
    onCreateNewCard: () => void;
  }> = ({ onTurnOffSpacedRepetition, onCreateNewCard }) => {
    
    return (
      <div className="mt-4 md:mt-8 text-center bg-green-100 dark:bg-green-800 p-6 rounded-lg shadow-md">
        <h3 className="text-lg md:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
          All Looping Mode Cards Completed!
        </h3>
    
        <div className="mb-6 text-sm md:text-base text-gray-700 dark:text-gray-300">
  <strong>REMINDER:</strong>
  <div className="bg-gray-200 dark:bg-gray-700 p-4 rounded-lg mt-2 shadow-md">
    <p className="flex items-center justify-center gap-2">
      <Sparkle className="text-yellow-500" size={16} />
      <strong>CONFIDENT</strong> will cycle every <strong>7 days</strong>.
    </p>
    <p className="flex items-center justify-center gap-2">
      <Scale className="text-blue-500" size={16} />
      <strong>MAYBE</strong> will cycle every <strong>3 days</strong>.
    </p>
    <p className="flex items-center justify-center gap-2">
      <Shuffle className="text-red-500" size={16} />
      <strong>GUESSING</strong> will cycle <strong>every day</strong> until you've mastered them.
    </p>
  </div>
</div>

    
        <div className="flex flex-wrap justify-center gap-3">
          <button
            onClick={onTurnOffSpacedRepetition}
            className="bg-primary-blue text-white px-4 py-2 text-sm md:text-base rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
          >
            Review Unanswered Cards
          </button>
          <button
            onClick={onCreateNewCard}
            className="bg-orange-500 text-white px-4 py-2 text-sm md:text-base rounded-md hover:bg-orange-600 dark:bg-orange-700 dark:hover:bg-orange-800 transition duration-300"
          >
            Create New Card
          </button>
        </div>
      </div>
    );    
  };

  // Renders the main flashcard
  const renderCard = () => {
    if (!currentCard) {
      return <div>No cards available</div>;
    }

    // Distinguish custom vs. regular
    const isCustomCard = !('side_1_content' in currentCard);
    const frontContent = isCustomCard ? currentCard.front_content : currentCard.side_1_content;
    const backContent = isCustomCard ? currentCard.back_content : currentCard.side_2_content;

    // If custom card is locked beyond the free limit
    if (isCustomCard) {
      const idxInCustom = customCards.findIndex(c => c.id === currentCard.id);
      const isLocked = isContentLocked() && idxInCustom >= FREE_CARD_LIMIT;
      if (isLocked) {
        return (
          <div className="relative w-full max-w-sm md:max-w-3xl mx-auto">
            <div className="blur-sm pointer-events-none">
              <div className="flashcard-container w-full max-w-[95%] md:max-w-3xl mx-auto h-[20rem] md:h-96 relative">
                <div className={`flashcard ${showingFront ? 'front' : 'back'}`}>
                  <div className="flashcard-content p-6 md:p-6 h-full text-gray-900 dark:text-gray-100 shadow-md bg-gray-50 dark:bg-gray-800 overflow-y-auto">
                    <div className="flashcard-html-content pt-3 text-xs md:text-base">
                      <div className="quiz-content
                          prose prose-gray dark:prose-invert
                          [&>p]:mb-4 
                          [&>ul]:mb-4 
                          [&>ul]:!list-disc 
                          [&>ul]:!ml-4
                          [&>ul]:!pl-4
                          [&>ul]:!list-outside
                          [&>ul]:!list-style-type-disc
                          [&>ol]:!list-decimal 
                          [&>ol]:!ml-4
                          [&>ol]:!pl-4
                          [&>ol]:!list-outside
                          [&>ol]:!list-style-type-decimal
                          [&>ol>li]:mb-2
                          [&>ol+ul]:!ml-8
                          [&>ol+ul]:!list-disc
                          [&>ol+ul]:mt-2
                          [&>ul>li>ul]:!list-[circle]
                          [&>ul>li>ul]:!ml-4
                          [&>ul>li>ul]:mt-2
                          [&>ul>li]:mb-2
                          [&>ul>li>ul]:!list-outside
                          [&>ul>li]:!list-item
                          [&>ol>li]:!list-item
                          [&_p]:text-inherit
                          [&_li]:text-inherit
                          [&_ul]:!list-style-disc
                          [&_ol]:!list-style-decimal
                          [&_ul]:!m-0
                          [&_ol]:!m-0
                          [&_ul]:!pl-4
                          [&_ol]:!pl-4"
                          dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(showingFront ? frontContent : backContent, {
                                  ALLOWED_TAGS: ['p', 'br', 'strong', 'ul', 'ol', 'li', 'div'],
                                  ALLOWED_ATTR: ['style', 'class', 'type'],
                                  ADD_ATTR: ['type'],
                                  USE_PROFILES: {
                                      html: true,
                                      svg: false,
                                      svgFilters: false
                                  }
                              })
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-10 rounded-lg h-[30rem] md:h-96">
              <div className="text-center text-white px-6 py-4">
                <Lock size={36} className="mx-auto mb-3" />
                <p className="text-lg font-semibold mb-2">Premium Card Limit Reached</p>
                <p className="mb-4 text-sm">
                  Upgrade your account to access all your custom cards.
                </p>
                <button
                  onClick={handleUpgradeClick}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center mx-auto text-sm px-4 py-2 rounded"
                >
                  <Crown size={14} className="mr-2" />
                  Upgrade Now
                </button>
              </div>
            </div>
          </div>
        );
      }
    }

    // Normal card display
    return (
      <div className="w-full overflow-hidden">
        <div
          className={`flashcard-container w-full max-w-[95%] md:max-w-3xl mx-auto h-[20rem] md:h-96 ${
            isFlipping ? 'flipping' : ''
          } relative`}
          onClick={handleFlipCard}
        >
          <div className={`flashcard ${showingFront ? 'front' : 'back'}`}>
            <div className="flashcard-side-indicator text-xs md:text-sm">
              {showingFront ? 'FRONT' : 'BACK'}
            </div>
            {displayedConfidenceLevel && (
              <div
                className="absolute top-2 left-2 px-2 py-1 rounded-full text-xs font-medium"
                style={{
                  backgroundColor:
                    displayedConfidenceLevel === 'confident'
                      ? 'rgba(34, 197, 94, 0.2)'
                      : displayedConfidenceLevel === 'maybe'
                      ? 'rgba(234, 179, 8, 0.2)'
                      : 'rgba(239, 68, 68, 0.2)',
                  color:
                    displayedConfidenceLevel === 'confident'
                      ? 'rgb(21, 128, 61)'
                      : displayedConfidenceLevel === 'maybe'
                      ? 'rgb(161, 98, 7)'
                      : 'rgb(185, 28, 28)'
                }}
              >
                Previously marked:{' '}
                {displayedConfidenceLevel.charAt(0).toUpperCase() +
                  displayedConfidenceLevel.slice(1)}
              </div>
            )}
            <div className="flashcard-content p-6 md:p-6 h-full text-gray-900 dark:text-gray-100 shadow-md bg-gray-50 dark:bg-gray-800 overflow-y-auto">
              <div className="flashcard-html-content pt-3 text-xs md:text-base">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(showingFront ? frontContent : backContent)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Add feedback button below card */}
        {!isCustomCard && (
          <div className="flex justify-center mt-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowFeedbackPopup(true);
              }}
              className="flex items-center text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors"
            >
              <MessageCircle className="w-4 h-4 mr-1" />
              Report an Issue
            </button>
          </div>
        )}
      </div>
    );
  };

  // Confidence-level helper
  const confidenceLevels: ConfidenceLevel[] = ['guessing', 'maybe', 'confident'];

  const getConfidenceLevelText = (lvl: ConfidenceLevel) =>
    lvl ? lvl.charAt(0).toUpperCase() + lvl.slice(1) : 'Unknown';

  const getConfidenceLevelIcon = (lvl: ConfidenceLevel) => {
    const iconSize = 'w-4 h-4 md:w-5 md:h-5';
    switch (lvl) {
      case 'confident':
        return <Sparkle className={iconSize} />;
      case 'maybe':
        return <Scale className={iconSize} />;
      case 'guessing':
        return <Shuffle className={iconSize} />;
      default:
        return null;
    }
  };

  const getConfidenceLevelStyle = (lvl: ConfidenceLevel | null, selected: boolean) => {
    const base = 'transition-colors duration-200';
    const sel = selected ? 'font-bold' : '';
    switch (lvl) {
      case 'confident':
        return `${base} ${sel} ${
          selected
            ? 'bg-green-500 text-white hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700'
            : 'bg-green-100/70 text-green-700 hover:bg-green-200/70 dark:bg-green-800/50 dark:text-green-300 dark:hover:bg-green-700/50'
        }`;
      case 'maybe':
        return `${base} ${sel} ${
          selected
            ? 'bg-yellow-500 text-white hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700'
            : 'bg-yellow-100/70 text-yellow-700 hover:bg-yellow-200/70 dark:bg-yellow-800/50 dark:text-yellow-300 dark:hover:bg-yellow-700/50'
        }`;
      case 'guessing':
        return `${base} ${sel} ${
          selected
            ? 'bg-red-500 text-white hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700'
            : 'bg-red-100/70 text-red-700 hover:bg-red-200/70 dark:bg-red-800/50 dark:text-red-300 dark:hover:bg-red-700/50'
        }`;
      default:
        return `${base} bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600`;
    }
  };

  // For listing custom cards on “Create Cards” view
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 25;

  // Update the filtered cards calculation
  const filteredCustomCards = useMemo(() => {
    // Return empty array if we're not in create cards mode
    if (!showCreateCardForm && !isCreateCardsActive) {
      return [];
    }
    
    // If no tags selected, return all custom cards
    if (selectedTags.length === 0) {
      return customCards;
    }
    
    // Filter by selected tags
    return customCards.filter(card => 
      selectedTags.some(tag => card.tags.includes(tag))
    );
  }, [customCards, selectedTags, showCreateCardForm, isCreateCardsActive]);

  // Update pagination variables
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = useMemo(() => {
    return filteredCustomCards.slice(indexOfFirstCard, indexOfLastCard);
  }, [filteredCustomCards, indexOfFirstCard, indexOfLastCard]);

  const toggleTag = (tag: string) => {
    setSelectedTags(prev => {
      if (prev.includes(tag)) return prev.filter(t => t !== tag);
      return [...prev, tag];
    });
    setCurrentPage(1);
  };

  const toggleCardExpansion = (cardId: string) => {
    setExpandedCards(prev => ({ ...prev, [cardId]: !prev[cardId] }));
  };

  const handleTagFilter = useCallback(
    (tag: string) => {
      toggleTag(tag);
    },
    [toggleTag]
  );

  const getActiveTagsWithCounts = useCallback(() => {
    const counts: { [tag: string]: number } = {};
    customCards.forEach(card => {
      card.tags.forEach(tag => {
        counts[tag] = (counts[tag] || 0) + 1;
      });
    });
    return Object.entries(counts)
      .map(([tag, count]) => ({ tag, count }))
      .sort((a, b) => b.count - a.count);
  }, [customCards]);

  const [showAllTags, setShowAllTags] = useState(false);

  // Update the renderCustomCard function
  const renderCustomCard = (card: CustomFlashcard) => {
    const absoluteIndex = customCards.findIndex(c => c.id === card.id);
    const isLocked = isContentLocked() && absoluteIndex >= FREE_CARD_LIMIT;

    return (
      <div 
        key={card.id}
        className="rounded-lg shadow-md border border-gray-200 dark:border-gray-700 overflow-hidden flex flex-col relative mb-5"
        style={{ backgroundColor: card.color }}
      >
        <div className={`p-4 relative flex-grow ${isLocked ? 'blur-sm' : ''}`}>
          <div className="flex justify-between items-start mb-2">
            <div className="flex flex-wrap">
              <span className="mr-1 mb-1 pr-1.5 py-0.5 text-gray-800 text-xs rounded">
                Front
              </span>
              {card.tags.map((tag, tagIndex) => (
                <span
                  key={`${card.id}-tag-${tagIndex}-${tag}`}
                  className="mr-1 mb-1 px-1.5 py-0.5 bg-gray-50 dark:bg-gray-600 text-gray-800 dark:text-gray-200 text-xs rounded"
                >
                  {tag}
                </span>
              ))}
            </div>
            <button
              onClick={() => toggleCardExpansion(card.id)}
              className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 ml-2"
            >
              {expandedCards[card.id] ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </button>
          </div>
          <div className="font-bold text-sm md:text-base text-gray-800 dark:text-gray-700 mt-2 mb-10 overflow-hidden max-h-52">
            {expandedCards[card.id] ? card.front_content : truncateText(card.front_content, 500)}
          </div>
        </div>

        {expandedCards[card.id] && (
          <div className={`p-4 bg-white dark:bg-gray-700 relative rounded-lg mt-2 pb-10 ${isLocked ? 'blur-sm' : ''}`}>
            <div className="flex flex-wrap">
              <span className="mr-1 mb-1 pr-1.5 py-0.5 text-gray-800 dark:text-gray-200 text-xs rounded">Back</span>
            </div>
            <p className="text-sm md:text-base text-gray-700 dark:text-gray-300 mb-8 max-h-[400px] overflow-y-auto">
              {card.back_content}
            </p>
          </div>
        )}

        {isLocked && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-10 rounded-lg">
            <div className="text-center text-white px-6 py-4">
              <p className="text-lg font-semibold mb-2">Premium Card Limit Reached</p>
              <p className="mb-4 text-sm">
                Upgrade your account to access all your custom cards.
              </p>
              <button
                onClick={handleUpgradeClick}
                className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center mx-auto text-sm px-4 py-2 rounded"
              >
                <Crown size={14} className="mr-2" />
                Upgrade Now
              </button>
            </div>
          </div>
        )}

        <div className="absolute bottom-2 right-4 flex space-x-2 z-20">
          <button
            onClick={() => setEditingCard(card)}
            className="p-1 bg-gray-50 dark:bg-gray-600 text-yellow-500 hover:text-yellow-600 dark:text-yellow-400 dark:hover:text-yellow-300 transition duration-300 shadow-md rounded-full flex items-center justify-center w-8 h-8"
          >
            <Edit size={14} />
          </button>
          <button
            onClick={() => handleDeleteCard(card.id)}
            className="p-1 bg-gray-50 dark:bg-gray-600 text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300 transition duration-300 shadow-md rounded-full flex items-center justify-center w-8 h-8"
          >
            <Trash2 size={14} />
          </button>
        </div>
      </div>
    );
  };

  // Deleting a custom card
  const handleDeleteCard = useCallback(
    async (cardId: string) => {
      try {
        await api.deleteCustomFlashcard(cardId);
        showAlert('Card successfully deleted');
        setCustomCards(prev => prev.filter(c => c.id !== cardId));
      } catch (err) {
        console.error('Error deleting custom card:', err);
        showAlert('Failed to delete card. Please try again.');
      }
    },
    [showAlert]
  );

  // Handling new custom card creation
  const FRONT_MAX_LENGTH = 500;
  const BACK_MAX_LENGTH = 2000;
  const MAX_TAGS = 3;
  const MAX_TAG_LENGTH = 30;

  const sanitizeInput = (input: string) => DOMPurify.sanitize(input);

   // Add a function to truncate text
   const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleCardContentChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, maxLen: number) =>
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = sanitizeInput(e.target.value);
      setter(val.slice(0, maxLen));
    };

  const handleCreateCard = useCallback(async () => {
    if (isCreatingCard) return;
    if (isContentLocked() && customCards.length >= FREE_CARD_LIMIT) {
      showAlert(`You've reached the limit of ${FREE_CARD_LIMIT} free cards. Upgrade to create more!`);
      handleUpgradeClick();
      return;
    }
    setIsCreatingCard(true);
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');

      const tags = newCardTags.length ? newCardTags : ['general'];
      const now = new Date().toISOString();
      const newCard = await api.createCustomFlashcard({
        user_id: user.id,
        course_id: courseId,
        front_content: sanitizeInput(newCardFront),
        back_content: sanitizeInput(newCardBack),
        confidence_level: null,
        tags,
        color: newCardColor,
        browser_instance_id: browserInstanceId || ''
      });

      setCustomCards(prev => [...prev, newCard]);
      setNewCardFront('');
      setNewCardBack('');
      setNewCardTags([]);
      setNewCardTagInput('');
      setNewCardColor('#E2E8F0');
      showAlert('New card created successfully');
    } catch (err) {
      console.error('Error creating custom card:', err);
      setError('Failed to create custom card. Please try again.');
      showAlert('Failed to create new card. Please try again.');
    } finally {
      setIsCreatingCard(false);
    }
  }, [
    isCreatingCard,
    isContentLocked,
    customCards.length,
    FREE_CARD_LIMIT,
    handleUpgradeClick,
    newCardTags,
    courseId,
    userId,
    newCardFront,
    newCardBack,
    newCardColor,
    browserInstanceId,
    showAlert
  ]);

  // Editing an existing custom card
  const handleUpdateCard = useCallback(async () => {
    if (!editingCard) return;
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');

      const updated = await api.updateCustomFlashcard(editingCard.id, {
        front_content: sanitizeInput(editingCard.front_content),
        back_content: sanitizeInput(editingCard.back_content),
        tags: editingCard.tags,
        color: editingCard.color
      });
      setCustomCards(prev => prev.map(c => (c.id === updated.id ? updated : c)));
      setEditingCard(null);
    } catch (err) {
      console.error('Failed to update custom card:', err);
      showAlert('Failed to update the custom card. Please try again.');
    }
  }, [editingCard, showAlert]);

  // Tag input for new custom card
  const addNewCardTag = useCallback(
    (newTag: string) => {
      const sanitized = sanitizeInput(newTag);
      if (sanitized && newCardTags.length < MAX_TAGS && !newCardTags.includes(sanitized)) {
        setNewCardTags(prev => [...prev, sanitized.slice(0, MAX_TAG_LENGTH)]);
        setNewCardTagInput('');
      }
    },
    [newCardTags]
  );

  const removeNewCardTag = useCallback((tag: string) => {
    setNewCardTags(prev => prev.filter(t => t !== tag));
  }, []);

  const handleNewCardTagKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Backspace' && newCardTagInput === '' && newCardTags.length > 0) {
        e.preventDefault();
        const lastTag = newCardTags[newCardTags.length - 1];
        removeNewCardTag(lastTag);
        setNewCardTagInput(lastTag);
      } else if (e.key === ',' || e.key === ' ') {
        e.preventDefault();
        const tag = newCardTagInput.trim();
        addNewCardTag(tag);
      }
    },
    [newCardTagInput, newCardTags, addNewCardTag, removeNewCardTag]
  );

  // Tag input for editing an existing custom card
  const filterEditTags = useCallback(
    (input: string) => {
      if (!input) {
        setFilteredEditTags([]);
        setShowEditTagDropdown(false);
        return;
      }
      const allTags = Array.from(new Set(customCards.flatMap(card => card.tags)));
      const filtered = allTags
        .filter(tag => tag.toLowerCase().includes(input.toLowerCase()))
        .slice(0, 5);
      setFilteredEditTags(filtered);
      setShowEditTagDropdown(filtered.length > 0);
    },
    [customCards]
  );

  const handleEditTagInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = DOMPurify.sanitize(e.target.value.slice(0, 30));
      setTagInput(val);
      filterEditTags(val);
    },
    [filterEditTags]
  );

  const handleTagKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!editingCard) return;
      if (e.key === 'Backspace' && tagInput === '' && editingCard.tags.length > 0) {
        e.preventDefault();
        const lastTag = editingCard.tags[editingCard.tags.length - 1];
        removeTag(lastTag);
        setTagInput(lastTag);
      } else if (e.key === ',' || e.key === ' ') {
        e.preventDefault();
        const newTag = tagInput.trim();
        if (newTag) {
          addTag(newTag);
        }
      }
    },
    [editingCard, tagInput]
  );

  const addTag = useCallback(
    (newTag: string) => {
      if (!editingCard) return;
      const sanitized = DOMPurify.sanitize(newTag);
      if (
        sanitized &&
        editingCard.tags.length < MAX_TAGS &&
        !editingCard.tags.includes(sanitized)
      ) {
        setEditingCard({
          ...editingCard,
          tags: [...editingCard.tags, sanitized.slice(0, 30)]
        });
        setTagInput('');
      }
    },
    [editingCard]
  );

  const removeTag = useCallback(
    (tag: string) => {
      if (!editingCard) return;
      setEditingCard({
        ...editingCard,
        tags: editingCard.tags.filter(t => t !== tag)
      });
    },
    [editingCard]
  );

  const handleBackToStudy = useCallback(() => {
    setShowCreateCardForm(false);
    setConfidenceFilter('CreateCards');
  }, []);

  // If we’re still in the middle of the *initial fetch*, show loading
  if (isLoading) {
    return <LoadingScreen message="Loading your KeslerCards..." subMessage="This may take a few moments" />;
  }
  if (error) {
    return <div className="text-red-500 dark:text-red-400">{error}</div>;
  }

  // Main render
  return (
    <div className="space-y-4 relative md:p-6 max-w-[1000px] mx-auto bg-white dark:bg-gray-700 p-4 rounded-lg shadow-md transition-all duration-300 border border-gray-400 dark:border-gray-500">
      {alertMessage && <AlertMessage message={alertMessage} />}

      <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded shadow transition-all duration-300">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl md:text-2xl font-bold text-light-text dark:text-dark-text">
            KeslerCards
          </h2>
          <div className="flex items-center space-x-4 md:space-x-6">
            {showUpgradeButton && (
              <button
                onClick={handleUpgradeClick}
                className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center px-4 py-2 text-sm font-medium rounded"
              >
                <Crown size={16} className="mr-2" />
                Upgrade
              </button>
            )}
            <button
              onClick={() => setShowCreateCardForm(!showCreateCardForm)}
              className="bg-primary-blue text-white px-2 py-1 md:px-4 md:py-2 text-sm md:text-base rounded hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600"
            >
              {showCreateCardForm ? 'Back' : 'Create Cards'}
            </button>
            <button
              onClick={() => setIsFilterMinimized(!isFilterMinimized)}
              className="p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 flex items-center justify-center w-8 h-8 md:w-10 md:h-10"
            >
              {isFilterMinimized ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
            </button>
          </div>
        </div>

        {!showCreateCardForm && !isFilterMinimized && (
          <div className="flex flex-col space-y-4">
            <div>
              <label
                htmlFor="category-select"
                className="block text-sm font-medium text-light-text dark:text-dark-text mb-2"
              >
                Select Category
              </label>
              <select
                id="category-select"
                value={selectedCategory || ''}
                onChange={e => {
                  const val = e.target.value || null;
                  setSelectedCategory(val);
                  setCurrentCardIndex(0);
                }}
                className="w-full p-2 border rounded bg-light-background dark:bg-gray-700 text-light-text dark:text-dark-text"
              >
                <option value="">All Categories</option>
                {sortedCategories.map(cat => (
                  <option key={cat.id} value={cat.id}>
                    {cat.sub_flashcard_category_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <h3 className="text-sm font-medium text-light-text dark:text-dark-text mb-2">
                Filter KeslerCards
              </h3>
              {renderFilterButtons()}
            </div>

            <div className="flex">
              <div className="pl-2 flex items-center space-x-2">
                <span className="text-sm text-gray-600 dark:text-gray-300">Random</span>
                <Switch
                  checked={isRandomMode}
                  onChange={checked => {
                    setIsRandomMode(checked);
                    // If turning random on, ensure looping is off
                    if (checked) {
                      setIsSpacedRepetitionMode(false);
                      localStorage.setItem('keslerCardsSpacedRepetitionMode', 'false');
                    }
                    localStorage.setItem('keslerCardsRandomMode', JSON.stringify(checked));
                  }}
                />
              </div>
              <div className="pl-2 flex items-center space-x-2">
                <span className="text-sm text-gray-600 dark:text-gray-300">Looping Mode</span>
                <Switch
                  checked={isSpacedRepetitionMode}
                  onChange={checked => {
                    if (isSpacedRepetitionUnlocked) {
                      setIsSpacedRepetitionMode(checked);
                      // If turning looping on, ensure random is off
                      if (checked) {
                        setIsRandomMode(false);
                        localStorage.setItem('keslerCardsRandomMode', 'false');
                      }
                      localStorage.setItem('keslerCardsSpacedRepetitionMode', JSON.stringify(checked));
                    } else {
                      showAlert(
                        `Complete ${Math.max(10 - cardsCompleted, 0)} more cards to unlock Looping Mode`
                      );
                    }
                  }}
                  disabled={!isSpacedRepetitionUnlocked}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {!showCreateCardForm && (
        <>
          {spacedRepetitionCompleted ? (
            <SpacedRepetitionCompletionScreen
              onTurnOffSpacedRepetition={handleTurnOffSpacedRepetition}
              onCreateNewCard={() => setShowCreateCardForm(true)}
            />
          ) : filteredCards.length > 0 ? (
            <div
              className={`mt-4 md:mt-8 transition-all duration-300 ${
                isCardMinimized ? 'h-16 overflow-hidden' : ''
              }`}
            >
              <div className="flex flex-col items-center relative">
                {currentCard && (
                  <div className="bg-gray-50 dark:bg-gray-800 shadow-sm rounded-lg p-4 mb-6 max-w-3xl mx-auto w-full border border-white/20">
                    <div className="flex flex-col items-center space-y-3">
                      {/* Distinguish built-in vs. custom */}
                      {'side_1_content' in currentCard ? (
                        <>
                          <h1 className="text-sm md:text-md font-bold text-center">
                            {currentCard.flash_card_category_name}
                          </h1>
                          {currentCard.sub_flash_card_category_name && (
                            <h2 className="text-xs md:text-sm text-gray-700 dark:text-gray-300 font-medium text-center">
                              {currentCard.sub_flash_card_category_name}
                            </h2>
                          )}
                        </>
                      ) : (
                        <>
                          <h1 className="text-sm md:text-md font-bold text-center">Custom Card</h1>
                          {currentCard.tags && currentCard.tags.length > 0 && (
                            <div className="flex flex-wrap justify-center gap-2">
                              {currentCard.tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="px-2 py-1 text-xs rounded-full bg-opacity-80 text-gray-900 dark:text-gray-800 shadow-sm"
                                  style={{ backgroundColor: currentCard.color }}
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          )}
                        </>
                      )}

                      <div className="flex items-center space-x-2 text-xs md:text-sm text-gray-500 dark:text-gray-400">
                        <span>
                          Card {currentCardIndex + 1} / {filteredCards.length}
                        </span>
                        {isRandomMode && (
                          <span className="flex items-center text-blue-500 font-medium">
                            <RefreshCcw size={14} className="mr-1" />
                            Random Mode
                          </span>
                        )}
                        {isSpacedRepetitionMode && (
                          <span className="flex items-center text-purple-500 font-medium">
                            <Lock size={14} className="mr-1" />
                            Looping Mode
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {currentCard && renderCard()}

                <div className="mt-4 flex flex-wrap items-center justify-center gap-2 md:gap-6 px-1">
                 <button
                    onClick={handlePreviousCard}
                    className={`flex items-center justify-center p-1 md:p-2 rounded-full transition-colors duration-200
                      ${canMoveToPreviousCard()
                        ? 'bg-primary-blue text-white hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-600 dark:text-gray-400'
                      }`}
                    disabled={!canMoveToPreviousCard()}
                  >
                    <ChevronLeft size={20} />
                  </button>

                  {/* Confidence Buttons */}
                  {confidenceLevels.map((level, index) => (
                    <button
                      key={level || `unknown-${index}`}
                      onClick={
                        canSelectConfidence
                          ? () => handleConfidenceLevel(level)
                          : handleLockedConfidenceClick
                      }
                      className={`
                        flex items-center justify-center
                        min-w-[40px] md:min-w-[100px]
                        px-2 py-1 md:px-3 md:py-2 
                        rounded-full text-xs md:text-sm
                        ${!canSelectConfidence ? 'cursor-not-allowed opacity-50' : ''}
                        ${getConfidenceLevelStyle(level, confidenceLevel === level)}
                      `}
                      disabled={!canSelectConfidence}
                      title={
                        canSelectConfidence
                          ? getConfidenceLevelText(level)
                          : 'Flip card to see answer first'
                      }
                    >
                      <div className="flex items-center justify-center gap-2 w-full">
                        {getConfidenceLevelIcon(level)}
                        <span className="hidden md:inline">{getConfidenceLevelText(level)}</span>
                      </div>
                    </button>
                  ))}

                  <button
                    onClick={handleFlipCard}
                    className="flex items-center justify-center p-1 md:p-2 rounded-full bg-primary-blue text-white hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600 transition-colors duration-200"
                  >
                    <RefreshCcw size={20} />
                  </button>

                  <button
                    onClick={handleNextCard}
                    className={`flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full transition-colors duration-200 
                      ${
                        canMoveToNextCard
                          ? 'bg-primary-blue text-white hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600'
                          : 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-600 dark:text-gray-400'
                      }`}
                    disabled={!canMoveToNextCard}
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4 md:mt-8 text-center bg-yellow-100 dark:bg-yellow-800 p-4 rounded-lg shadow">
              <h3 className="text-lg md:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                No cards match the current filter
              </h3>
              <p className="mb-4 text-sm md:text-base text-gray-700 dark:text-gray-300">
                {confidenceFilter === 'CreateCards'
                  ? "You haven't created any custom cards yet."
                  : 'Try adjusting your filter settings or create new cards.'}
              </p>
              <div className="flex flex-wrap justify-center gap-2">
                <button
                  onClick={() => {
                    setSelectedCategory(null);
                    setConfidenceFilter('All');
                    setSelectedTags([]);
                  }}
                  className="bg-primary-blue text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
                >
                  Reset Filters
                </button>
                <button
                  onClick={() => setShowCreateCardForm(true)}
                  className="bg-green-500 text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 transition duration-300"
                >
                  Create New Card
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {showCreateCardForm && (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Create New KeslerCard</h3>
            {isContentLocked() && (
              <span className="text-sm text-gray-600 dark:text-gray-400">
                {remainingFreeCards} of {FREE_CARD_LIMIT} free cards remaining
              </span>
            )}
          </div>

          <div className="space-y-6">
            <div>
              <label
                htmlFor="front-content"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Front of Card
              </label>
              <textarea
                id="front-content"
                placeholder="Enter the question or prompt"
                value={newCardFront}
                onChange={handleCardContentChange(setNewCardFront, FRONT_MAX_LENGTH)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={addInputRef}
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                rows={3}
                maxLength={FRONT_MAX_LENGTH}
              />
              <p className="text-xs text-gray-500 mt-1 text-right">
                {newCardFront.length}/{FRONT_MAX_LENGTH}
              </p>
            </div>

            <div>
              <label
                htmlFor="back-content"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Back of Card
              </label>
              <textarea
                id="back-content"
                placeholder="Enter the answer or explanation"
                value={newCardBack}
                onChange={handleCardContentChange(setNewCardBack, BACK_MAX_LENGTH)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={addInputRef}
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                style={{ maxHeight: '400px' }}
                rows={3}
                maxLength={BACK_MAX_LENGTH}
              />
              <p className="text-xs text-gray-500 mt-1 text-right">
                {newCardBack.length}/{BACK_MAX_LENGTH}
              </p>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="flex-grow mb-4 md:mb-0">
                <label
                  htmlFor="newCardTags"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Optional: Add Tags (max 3)
                </label>
                <div className="relative">
                  <div className="flex flex-wrap items-center gap-2 p-1 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 min-h-[50px]">
                    {newCardTags.map((tag, index) => (
                      <span
                        key={index}
                        className="ml-1 inline-flex items-center bg-blue-100 dark:bg-primary-blue text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-sm max-h-[40px]"
                      >
                        {tag}
                        <button
                          onClick={() => removeNewCardTag(tag)}
                          className="ml-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex items-center justify-center"
                        >
                          <X size={18} />
                        </button>
                      </span>
                    ))}
                    <textarea
                      ref={newCardTagInputRef}
                      id="newCardTags"
                      value={newCardTagInput}
                      onChange={e => {
                        const val = sanitizeInput(e.target.value.slice(0, MAX_TAG_LENGTH));
                        setNewCardTagInput(val);
                      }}
                      onKeyDown={handleNewCardTagKeyDown}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      className="flex-grow p-0.5 bg-transparent border-none outline-none text-gray-900 dark:text-gray-100 text-sm md:text-base resize-none overflow-hidden"
                      placeholder={
                        newCardTags.length < MAX_TAGS ? 'Add comma or space to add tag' : ''
                      }
                      rows={1}
                      maxLength={MAX_TAG_LENGTH}
                      disabled={newCardTags.length >= MAX_TAGS}
                    />
                  </div>
                </div>
                <p className="text-xs text-gray-500 mt-1 text-right">
                  {newCardTagInput.length}/{MAX_TAG_LENGTH}
                </p>
              </div>

              <div className="flex-shrink-0 w-full md:w-auto">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Pick Color
                </label>
                <div className="flex space-x-2 flex-wrap">
                  {[
                    { name: 'Light Gray', value: '#E2E8F0' },
                    { name: 'Light Blue', value: '#BFDBFE' },
                    { name: 'Light Green', value: '#BBF7D0' },
                    { name: 'Light Yellow', value: '#FEF08A' },
                    { name: 'Light Pink', value: '#FBCFE8' },
                    { name: 'Light Purple', value: '#DDD6FE' }
                  ].map(color => (
                    <button
                      key={color.value}
                      onClick={() => setNewCardColor(color.value)}
                      className={`w-8 h-8 rounded-full border-2 transition-all duration-200 ${
                        newCardColor === color.value
                          ? 'border-blue-500 scale-110'
                          : 'border-gray-300 dark:border-gray-600 hover:scale-105'
                      }`}
                      style={{ backgroundColor: color.value }}
                      title={color.name}
                    />
                  ))}
                </div>
              </div>
            </div>

            <button
              onClick={handleCreateCard}
              disabled={!newCardFront.trim() || !newCardBack.trim() || isCreatingCard}
              className={`w-full px-6 py-3 rounded-md text-lg font-semibold transition duration-300 shadow-md hover:shadow-lg ${
                !newCardFront.trim() || !newCardBack.trim() || isCreatingCard
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-primary-blue text-white hover:bg-blue-600 dark:bg-orange-600 dark:hover:bg-orange-700'
              }`}
            >
              {isCreatingCard ? 'Creating...' : 'Create KeslerCard'}
            </button>
          </div>
        </div>
      )}

      {showCreateCardForm && (
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow relative mt-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">Your Custom Cards</h3>
            <button
              onClick={handleBackToStudy}
              className="bg-primary-blue text-white px-2 py-1 md:px-4 md:py-2 text-sm md:text-base rounded hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600"
            >
              Study
            </button>
          </div>

          <div className="mb-4 flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedTags([])}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedTags.length === 0
                  ? 'bg-primary-blue text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
              }`}
            >
              All
            </button>
            {getActiveTagsWithCounts()
              .slice(0, showAllTags ? undefined : 10)
              .map(({ tag, count }) => (
                <button
                  key={tag}
                  onClick={() => handleTagFilter(tag)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    selectedTags.includes(tag)
                      ? 'bg-primary-blue text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
                  }`}
                >
                  {tag} ({count})
                </button>
              ))}
            {getActiveTagsWithCounts().length > 10 && (
              <button
                onClick={() => setShowAllTags(!showAllTags)}
                className="px-3 py-1 rounded-full text-sm bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
              >
                {showAllTags ? 'Show Less' : `Show More (${getActiveTagsWithCounts().length - 10})`}
              </button>
            )}
            {selectedTags.length > 0 && (
              <button
                onClick={() => setSelectedTags([])}
                className="px-3 py-1 rounded-full text-sm bg-red-500 text-white hover:bg-red-600"
              >
                Clear Filters ({selectedTags.length})
              </button>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 auto-rows-auto">
            {filteredCustomCards
              .slice((currentPage - 1) * cardsPerPage, currentPage * cardsPerPage)
              .map(card => renderCustomCard(card))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalItems={filteredCustomCards.length}
            itemsPerPage={cardsPerPage}
            onPageChange={setCurrentPage}
          />
        </div>
      )}

      {editingCard && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Card</h3>
            <div className="space-y-4">
              <div>
                <textarea
                  value={editingCard.front_content}
                  onChange={e =>
                    setEditingCard({
                      ...editingCard,
                      front_content: sanitizeInput(e.target.value.slice(0, FRONT_MAX_LENGTH))
                    })
                  }
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  ref={addInputRef}
                  className="w-full p-2 border rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                  rows={3}
                  placeholder="Front of card"
                  maxLength={FRONT_MAX_LENGTH}
                />
                <p className="text-xs text-gray-500 mt-1 text-right">
                  {editingCard.front_content.length}/{FRONT_MAX_LENGTH}
                </p>
              </div>
              <div>
                <textarea
                  value={editingCard.back_content}
                  onChange={e =>
                    setEditingCard({
                      ...editingCard,
                      back_content: sanitizeInput(e.target.value.slice(0, BACK_MAX_LENGTH))
                    })
                  }
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  ref={addInputRef}
                  className="w-full p-2 border rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                  rows={3}
                  placeholder="Back of card"
                  maxLength={BACK_MAX_LENGTH}
                />
                <p className="text-xs text-gray-500 mt-1 text-right">
                  {editingCard.back_content.length}/{BACK_MAX_LENGTH}
                </p>
              </div>
              <div>
                <label
                  htmlFor="editTags"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                  Tags (max 3, press comma or space to add)
                </label>
                <div className="relative">
                  <div className="flex flex-wrap items-center gap-2 p-1 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 min-h-[50px]">
                    {editingCard.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="ml-1 inline-flex items-center bg-blue-100 dark:bg-primary-blue text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-sm max-h-[40px]"
                      >
                        {tag}
                        <button
                          onClick={() => removeTag(tag)}
                          className="ml-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex items-center justify-center"
                        >
                          <X size={18} />
                        </button>
                      </span>
                    ))}
                    <textarea
                      ref={editTagInputRef}
                      id="editTags"
                      value={tagInput}
                      onChange={handleEditTagInput}
                      onKeyDown={handleTagKeyDown}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      className="flex-grow p-0.5 bg-transparent border-none outline-none text-gray-900 dark:text-gray-100 text-sm md:text-base resize-none overflow-hidden"
                      placeholder={
                        editingCard?.tags.length < MAX_TAGS ? 'Add comma or space to add tag' : ''
                      }
                      rows={1}
                      style={{ minHeight: '24px', height: 'auto' }}
                      maxLength={MAX_TAG_LENGTH}
                      disabled={editingCard?.tags.length >= MAX_TAGS}
                    />
                  </div>
                  {showEditTagDropdown && (
                    <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                      {filteredEditTags.map((t, i) => (
                        <div
                          key={i}
                          className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                          onClick={() => {
                            addTag(t);
                            setShowEditTagDropdown(false);
                          }}
                        >
                          {t}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <p className="text-xs text-gray-500 mt-1 text-right">
                  {tagInput.length}/{MAX_TAG_LENGTH}
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Card Color
                </label>
                <div className="flex space-x-2">
                  {[
                    { name: 'Light Gray', value: '#E2E8F0' },
                    { name: 'Light Blue', value: '#BFDBFE' },
                    { name: 'Light Green', value: '#BBF7D0' },
                    { name: 'Light Yellow', value: '#FEF08A' },
                    { name: 'Light Pink', value: '#FBCFE8' },
                    { name: 'Light Purple', value: '#DDD6FE' }
                  ].map(color => (
                    <button
                      key={color.value}
                      onClick={() =>
                        setEditingCard({
                          ...editingCard,
                          color: color.value
                        })
                      }
                      className={`w-8 h-8 rounded-full border-2 ${
                        editingCard.color === color.value ? 'border-blue-500' : 'border-transparent'
                      }`}
                      style={{ backgroundColor: color.value }}
                      title={color.name}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setEditingCard(null)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500 transition duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateCard}
                  className="px-4 py-2 bg-primary-blue text-white rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add FeedbackPopup component */}
      {currentCard && 'side_1_content' in currentCard && (
        <FeedbackPopup
          isOpen={showFeedbackPopup}
          onClose={() => setShowFeedbackPopup(false)}
          contentId={currentCard.id}
          contentType="flashcards"
          contentTitle={`${currentCard.flash_card_category_name || ''} - ${currentCard.side_1_content.slice(0, 50)}`}
        />
      )}

      {/* Show small “character limit reached” if user typed too much */}
      {false && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-md shadow-lg">
          Character limit reached!
        </div>
      )}

      {renderReturnButton()}
      <UpgradeModal isOpen={isUpgradeModalOpen} onClose={handleCloseUpgradeModal} />
    </div>
  );
}

export default KeslerCards;
