import React from 'react';
import { Card } from '../../ui/Card';
import { ContentType } from '../../../services/api';
import QuestionPreview from '../../course/QuestionPreview';
import LessonPreview from '../../course/LessonPreview';
import KeslerCardsPreview from '../../course/KeslerCardsPreview';
import SimulationPreview from '../../course/SimulationPreview';

interface QuestionPreviewContent {
  question_content?: string;
  answer_content?: string;
  correct_answer?: string;
}

interface LessonPreviewContent {
  content?: string;
}

interface FlashcardPreviewContent {
  side_1_content?: string;
  side_2_content?: string;
}

interface SimulationPreviewContent {
  html?: string;
  question_content?: string;
  correct_answer?: string;
  answer_content?: string;
  exhibits?: any;
}

// Update the props interfaces for each preview component
declare module '../../course/QuestionPreview' {
  interface QuestionPreviewProps {
    previewContent?: QuestionPreviewContent;
  }
}

declare module '../../course/LessonPreview' {
  interface LessonPreviewProps {
    previewContent?: LessonPreviewContent;
  }
}

declare module '../../course/KeslerCardsPreview' {
  interface KeslerCardsPreviewProps {
    previewContent?: FlashcardPreviewContent;
  }
}

declare module '../../course/SimulationPreview' {
  interface SimulationPreviewProps {
    previewContent?: SimulationPreviewContent;
  }
}

interface FeedbackPreviewProps {
  contentType: ContentType;
  contentId: string;
  userId: string;
  courseId: string;
  currentContent: Record<string, any>;
  originalContent: Record<string, any>;
}

const FeedbackPreview: React.FC<FeedbackPreviewProps> = ({
  contentType,
  contentId,
  userId,
  courseId,
  currentContent,
  originalContent
}) => {
  // Determine if there are any changes to show
  const hasChanges = Object.keys(currentContent).some(key => 
    JSON.stringify(currentContent[key]) !== JSON.stringify(originalContent[key])
  );

  const renderPreview = () => {
    switch (contentType) {
      case 'questions':
        return (
          <div className="space-y-4">
            {hasChanges && (
              <div>
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Preview with Changes
                </h4>
                <QuestionPreview 
                  questionId={contentId} 
                  courseId={courseId} 
                  userId={userId}
                  previewContent={{
                    question_content: currentContent.question_content,
                    answer_content: currentContent.answer_content,
                    correct_answer: currentContent.correct_answer
                  }}
                />
              </div>
            )}
            <div>
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                Current Version
              </h4>
              <QuestionPreview 
                questionId={contentId} 
                courseId={courseId} 
                userId={userId} 
              />
            </div>
          </div>
        );
      case 'lessons':
        return (
          <div className="space-y-4">
            {hasChanges && (
              <div>
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Preview with Changes
                </h4>
                <LessonPreview 
                  lessonId={contentId} 
                  courseId={courseId} 
                  userId={userId}
                  previewContent={{
                    content: currentContent.content
                  }}
                />
              </div>
            )}
            <div>
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                Current Version
              </h4>
              <LessonPreview 
                lessonId={contentId} 
                courseId={courseId} 
                userId={userId}
              />
            </div>
          </div>
        );
      case 'flashcards':
        return (
          <div className="space-y-4">
            {hasChanges && (
              <div>
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Preview with Changes
                </h4>
                <KeslerCardsPreview 
                  flashcardId={contentId} 
                  courseId={courseId} 
                  userId={userId}
                  previewContent={{
                    side_1_content: currentContent.side_1_content,
                    side_2_content: currentContent.side_2_content
                  }}
                />
              </div>
            )}
            <div>
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                Current Version
              </h4>
              <KeslerCardsPreview 
                flashcardId={contentId} 
                courseId={courseId} 
                userId={userId}
              />
            </div>
          </div>
        );
      case 'simulations':
        return (
          <div className="space-y-4">
            {hasChanges && (
              <div>
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Preview with Changes
                </h4>
                <SimulationPreview 
                  simulationId={contentId} 
                  courseId={courseId} 
                  userId={userId}
                  previewContent={{
                    html: currentContent.html,
                    question_content: currentContent.question_content,
                    correct_answer: currentContent.correct_answer,
                    answer_content: currentContent.answer_content,
                    exhibits: currentContent.exhibits
                  }}
                />
              </div>
            )}
            <div>
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                Current Version
              </h4>
              <SimulationPreview 
                simulationId={contentId} 
                courseId={courseId} 
                userId={userId}
              />
            </div>
          </div>
        );
      default:
        return (
          <div className="p-4 text-gray-500 dark:text-gray-400">
            Preview not available for this content type
          </div>
        );
    }
  };

  return (
    <Card className="mt-4">
      <div className="p-4 border-b dark:border-gray-700">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
          Content Preview
        </h3>
      </div>
      <div className="p-4">
        {renderPreview()}
      </div>
    </Card>
  );
};

export default FeedbackPreview;