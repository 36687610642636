import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { api, StudyTaskDetails } from '../../../services/api';
import { Check, CheckCircle2, Clock, ArrowRight, RotateCw } from 'lucide-react';
import DOMPurify from 'dompurify';
import { useActiveQuiz, ActiveQuizState } from '../../../hooks/useActiveQuiz';
import PopupModal from '../common/PopupModal';
import Tooltip from '../../../components/ui/Tooltip';

interface LearnTaskProps {
  taskDetails: StudyTaskDetails;
  userId: string;
  courseId: string;
  itemId: string;
  onTaskComplete: () => void;
}

interface KeslerTask {
  id: string;
  title: string;
  description: string;
  completed: boolean;
  actionType: 'lesson' | 'kesler-cards' | 'quiz';
  buttonText: string;
}

const LearnTask: React.FC<LearnTaskProps> = ({ taskDetails, userId, courseId, itemId, onTaskComplete }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isMainTaskCompleted, setIsMainTaskCompleted] = useState<boolean>(false);
  const [keslerTasks, setKeslerTasks] = useState<KeslerTask[]>([]);
  const [isQuizLoading, setIsQuizLoading] = useState(false);
  const location = useLocation();
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  // NEW: State to handle no available questions alert
  const [noAvailableQuestions, setNoAvailableQuestions] = useState<boolean>(false);
  const [activeQuizState, setActiveQuizState] = useState<ActiveQuizState>({
    activeQuiz: null,
    activeTestletType: null,
    showActiveQuizModal: false
  });

  const { data: activeQuiz } = useActiveQuiz(userId, courseId, {
    enabled: false,
    onSuccess: (quiz) => {
      if (quiz) {
        setActiveQuizState(prev => ({
          ...prev,
          activeQuiz: quiz,
          showActiveQuizModal: true
        }));
      }
    }
  });

  const getDefaultButtonText = (actionType: KeslerTask['actionType'], isCompleted: boolean): string => {
    if (actionType === 'quiz' && isCompleted) {
      return 'View Results';
    }
    return {
      'lesson': 'Lesson',
      'kesler-cards': 'Flashcards',
      'quiz': 'Quiz'
    }[actionType] || '';
  };

  const createTasks = useCallback((isCompleted: boolean): KeslerTask[] => {
    return [
      { 
        id: '1', 
        title: 'Review AICPA Blueprint Source Material', 
        description: 'Review a textbook/lecture covering the topic above, and leave notes in the corresponding Kesler Lesson recording all of your questions you want to research later',
        completed: isCompleted, 
        actionType: 'lesson',
        buttonText: 'Lesson'
      },
      { 
        id: '2', 
        title: 'Practice KeslerCards', 
        description: 'Take some time to review the flashcards for this topic before answering practice questions',
        completed: isCompleted,
        actionType: 'kesler-cards',
        buttonText: 'Flashcards'
      },
      { 
        id: '3', 
        title: 'Complete KeslerQs', 
        description: "Complete a sample of KeslerQs on this topic and leave notes on questions you don't understand for later review and KeslerCards to capture your Ah-ha! moments.",
        completed: isCompleted,
        actionType: 'quiz',
        buttonText: 'Quiz'
      },
    ];
  }, []);

  // UPDATED: This function now checks if there are no quiz questions available
  const handleQuiz = async () => {
    if (!taskDetails?.question_category_id) {
      console.error('No question category ID available');
      return;
    }
  
    if (isQuizLoading) return;
    setIsQuizLoading(true);
  
    try {
      // First check for active quiz
      const activeQuizResponse = await api.getActiveQuiz(userId, courseId);
      
      if (activeQuizResponse) {
        setActiveQuizState({
          activeQuiz: activeQuizResponse,
          activeTestletType: 'learn',
          showActiveQuizModal: true
        });
        setIsQuizLoading(false);
        return;
      }

      // No active quiz, create new one
      const quizResponse = await api.getStudyTaskQuizQuestions(
        userId,
        courseId,
        itemId,
        taskDetails.question_category_id,
        'learn',
        taskDetails.task_name
      );

      if (!quizResponse.quizHistory || !quizResponse.testlet) {
        throw new Error('Failed to create quiz');
      }

      // NEW: Check if the testlet has zero questions
      if (
        !quizResponse.testlet.questions_data ||
        quizResponse.testlet.questions_data.length === 0
      ) {
        // Show alert for no questions
        setNoAvailableQuestions(true);
        setIsQuizLoading(false);
        return;
      }
  
      const searchParams = new URLSearchParams({
        study_task_testlet_id: quizResponse.testlet.id,
        quiz_history_id: quizResponse.quizHistory.id,
        study_task_id: itemId,
        type: 'learn'
      }).toString();
  
      navigate(`/course/${courseId}/quiz?${searchParams}`, {
        state: {
          resumeQuizId: quizResponse.quizHistory.id,
          quizData: quizResponse.quizHistory,
          studyTaskTestletId: quizResponse.testlet.id,
          studyTaskId: itemId,
          testletType: 'learn',
          from: 'study-task',
          showSummary: false,
          quizCompleted: false
        }
      });
    } catch (error) {
      console.error('Error starting learn quiz:', error);
    } finally {
      setIsQuizLoading(false);
    }
  };

  const handleEndQuiz = async () => {
    if (!activeQuizState.activeQuiz) return;
    
    try {
      // End the active quiz session
      const totalTime = activeQuizState.activeQuiz.questions_data.reduce(
        (sum, q) => sum + (q.timeSpent || 0), 
        0
      );
      
      await api.endQuizSession(
        activeQuizState.activeQuiz.id, 
        totalTime,
        true
      );
      
      // Reset active quiz state
      setActiveQuizState({
        activeQuiz: null,
        activeTestletType: null,
        showActiveQuizModal: false
      });
      
      // Start a new quiz
      await handleQuiz();
    } catch (error) {
      console.error('Error ending quiz:', error);
      // You might want to show an error message to the user here
    }
  };
  
  const getTaskAction = useCallback((actionType: KeslerTask['actionType'], taskDetails: StudyTaskDetails, isCompleted: boolean) => {
    switch (actionType) {
      case 'quiz':
        return isCompleted 
          ? () => navigate(`/course/${courseId}/analytics`)
          : handleQuiz;
      case 'lesson':
        return !isCompleted 
          ? () => {
              const studyTaskReturnUrl = `/course/${courseId}/study-plan/${itemId}`;
              navigate(
                `/course/${courseId}/lessons/${taskDetails?.lesson_id}`, 
                { 
                  state: { 
                    item_id: itemId,
                    returnUrl: studyTaskReturnUrl,
                    returnedFrom: 'lesson' 
                  } 
                }
              );
            }
          : () => {};
          case 'kesler-cards':
            return !isCompleted ? () => {
              if (taskDetails) {
                // Set the flashcard mode settings in localStorage
                localStorage.setItem('keslerCardsRandomMode', 'true');
                localStorage.setItem('keslerCardsSpacedRepetitionMode', 'false');
                localStorage.setItem('keslerCardsConfidenceFilter', 'Unanswered');
                
                const studyTaskReturnUrl = `/course/${courseId}/study-plan/${itemId}`;
                navigate(`/course/${courseId}/kesler-cards`, { 
                  state: { 
                    flashcardCategoryId: taskDetails.flashcard_category_id,
                    category: `${taskDetails.parent_flashcard_category_name} - ${taskDetails.sub_flashcard_category_name}`,
                    returnUrl: studyTaskReturnUrl,
                    returnedFrom: 'kesler-cards',
                    item_id: itemId
                  }
                });
              }
            } : () => {};
          default:
            return () => {};
        }
      }, [courseId, itemId, navigate, handleQuiz]);

  useEffect(() => {
    if (taskDetails) {
      setIsMainTaskCompleted(taskDetails.is_completed);

      const storedTasks = localStorage.getItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`);
      if (storedTasks) {
        const parsedTasks: KeslerTask[] = JSON.parse(storedTasks);
        const updatedTasks = parsedTasks.map(task => ({
          ...task,
          completed: taskDetails.is_completed ? true : task.completed,
        }));
        setKeslerTasks(updatedTasks);
        localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      } else {
        const newTasks = createTasks(taskDetails.is_completed);
        setKeslerTasks(newTasks);
        localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(newTasks));
      }
    }
  }, [taskDetails, itemId, createTasks]);

  const renderTaskButton = (task: KeslerTask, taskDetails: StudyTaskDetails) => {
    const buttonBaseClasses = "text-white py-2 px-4 rounded-md transition duration-300 text-sm inline-flex items-center justify-center space-x-1";
    
    let buttonClasses = buttonBaseClasses;
    if (task.completed && task.actionType === 'quiz') {
      buttonClasses += " bg-primary-blue hover:bg-primary-blue-hover";
    } else if (task.completed) {
      buttonClasses += " bg-gray-300 cursor-not-allowed";
    } else {
      buttonClasses += " bg-primary-orange hover:bg-primary-orange-hover";
    }

    buttonClasses += " w-full sm:w-auto";
  
    return (
      <button
        onClick={() => handleActionClick(task)}  // Updated to use handleActionClick
        disabled={task.completed && task.actionType !== 'quiz'}
        className={buttonClasses}
      >
        {task.completed ? (
          task.actionType === 'quiz' ? (
            <span>{task.buttonText}</span>
          ) : (
            <>
              <CheckCircle2 className="w-4 h-4 mr-1" />
              <span>Completed</span>
            </>
          )
        ) : (
          task.buttonText
        )}
      </button>
    );
  };

  // Update toggleTaskCompletion to clear the tooltip state
  const toggleTaskCompletion = async (taskId: string) => {
    // Clear tooltip when completing the active task
    if (activeTaskId === taskId) {
      setShowTooltip(false);
      setActiveTaskId(null);
    }

    const updatedTasks = keslerTasks.map(task => {
      if (task.id === taskId) {
        const willBeCompleted = !task.completed;
        return { 
          ...task, 
          completed: willBeCompleted,
          buttonText: task.actionType === 'quiz' 
            ? getDefaultButtonText('quiz', willBeCompleted)
            : task.buttonText
        };
      }
      return task;
    });
    setKeslerTasks(updatedTasks);
    localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));

    const allCompleted = updatedTasks.every(task => task.completed);
    if (allCompleted && !isMainTaskCompleted) {
      await handleMarkComplete();
    }
  };
  
  // Update handleMarkComplete function
  const handleMarkComplete = async () => {
    try {
      await api.markStudyTaskComplete(itemId);
      setIsMainTaskCompleted(true);
      const updatedTasks = keslerTasks.map(task => ({ 
        ...task, 
        completed: true,
        buttonText: task.actionType === 'quiz' 
          ? getDefaultButtonText('quiz', true)
          : task.buttonText
      }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      
      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };
  
  // Update handleUncompleteTask function
  const handleUncompleteTask = async () => {
    try {
      await api.unmarkStudyTaskComplete(itemId);
      setIsMainTaskCompleted(false);
      
      // Reset task button texts when unmarking complete
      const updatedTasks = keslerTasks.map(task => ({
        ...task,
        completed: false,
        buttonText: getDefaultButtonText(task.actionType, false)
      }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      
      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error unmarking task as complete:', error);
    }
  };

  const formatContentWithLineBreaks = (content: string) => {
    return content.replace(/;(?=<\/span><span class="font-bold">)/g, ';</span><br/><span class="font-bold">');
  };

  const formatTimeEstimate = (minutes: number | null): string => {
    if (!minutes) return 'Not available';
    
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (hours === 1) {
      return remainingMinutes > 0 
        ? `${hours} hour ${remainingMinutes} minutes`
        : `${hours} hour`;
    } else {
      return remainingMinutes > 0 
        ? `${hours} hours ${remainingMinutes} minutes`
        : `${hours} hours`;
    }
  };

  const getEstimatedTime = (taskDetails: StudyTaskDetails): number | null => {
    return taskDetails.estimated_minutes;
  };
  
  const renderTimeEstimate = () => {
  
    const isRetake = taskDetails.topic_type?.includes('retake');
    const estimatedTime = getEstimatedTime(taskDetails);
  
    return (
      <div className="mb-6">
        <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 flex items-center">
            <Clock className="w-5 h-5 mr-2 text-primary-blue" />
            Estimated Time to Complete
          </h3>
          
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
            <div className="flex items-start">
              <ArrowRight className="w-5 h-5 text-primary-blue mt-0.5 mr-2 shrink-0" />
              <div>
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {isRetake ? 'Review Time' : 'Learning Time'}
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-gray-100 mt-1">
                  {formatTimeEstimate(estimatedTime)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!keslerTasks.length) return;
  
    if (location.state && location.state.returnedFrom) {
      // Wrap it in an async function
      const handleReturnState = async () => {
  
        const taskType = location.state.returnedFrom;
        const quizTask = keslerTasks.find((t) => t.actionType === "quiz");
  
        if (taskType === "quiz" && quizTask) {
  
          setActiveTaskId(quizTask.id);
          setShowTooltip(true);
  
          if (location.state.testletCompleted && !quizTask.completed) {
            await toggleTaskCompletion(quizTask.id);
          }
        } else {
          const matchingTask = keslerTasks.find(
            (t) => !t.completed && t.actionType === taskType
          );
          if (matchingTask) {
            setActiveTaskId(matchingTask.id);
            setShowTooltip(true);
          }
        }
  
        // Clear navigation state
        navigate(location.pathname, {
          replace: true,
          state: {},
        });
      };
  
      // then just call it
      handleReturnState();
    }
  }, [location, keslerTasks, navigate, toggleTaskCompletion]);
  

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const handleActionClick = (task: KeslerTask) => {
    if (task.actionType) {
      scrollToTop();
      getTaskAction(task.actionType, taskDetails, task.completed)();
    }
  };

  // Update renderCompletionCircle to handle completed quiz returns
  const renderCompletionCircle = (task: KeslerTask) => {
    const isActive = activeTaskId === task.id && showTooltip;
    
    const button = (
      <button
        onClick={() => toggleTaskCompletion(task.id)}
        className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 border-2 
          ${task.completed
            ? 'bg-green-500 border-green-500 hover:bg-green-600 hover:border-green-600'
            : `bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-500 
              ${isActive ? 'animate-pulse border-primary-orange' : 'hover:border-primary-blue dark:hover:border-primary-blue'}`
          }`}
        title={task.completed ? "Task completed" : "Mark as complete"}
      >
        {task.completed ? (
          <Check size={16} className="mx-auto text-white" />
        ) : (
          <div className="relative w-full h-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <Check 
                size={14} 
                className={`${isActive ? 'text-primary-orange' : 'text-gray-400'}`}
              />
            </div>
          </div>
        )}
      </button>
    );

    if (isActive) {
      const tooltipMessage = task.completed && task.actionType === 'quiz' 
        ? "Quiz completed! View results in Analytics"
        : "Click to mark this task as complete!";

      return (
        <Tooltip 
          content={
            <div className="flex items-center gap-2">
              <Check className="w-4 h-4" />
              <span>{tooltipMessage}</span>
            </div>
          }
          side="right"
          delayMS={0}
        >
          {button}
        </Tooltip>
      );
    }

    return button;
  };
  
  const renderTaskContent = (taskDetails: StudyTaskDetails) => {
    const isKeslerPrimary = taskDetails.primary_course_name === 'Kesler CPA Review';
    
    const nonKeslerCourseName = isKeslerPrimary ? taskDetails.secondary_course_display_name : taskDetails.primary_course_display_name;
    const nonKeslerCourseAlias = isKeslerPrimary ? taskDetails.secondary_course_alias : taskDetails.primary_course_alias;
    
    const topicTitle = isKeslerPrimary 
      ? (taskDetails.secondary_topic_title || taskDetails.blueprint_topic_title)
      : taskDetails.primary_topic_title;
    
    const keslerTopicTitle = isKeslerPrimary ? taskDetails.primary_topic_title : taskDetails.secondary_topic_title;
  
    const displayCourseName = (name: string | null, alias: string | null) => {
      if (name && alias) {
        return `${name} (${alias})`;
      }
      return name || 'N/A';
    };
  
    return (
      <>
        <div className="text-center px-6 pb-6 bg-transparent">
          <h2 
            className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(taskDetails.task_name) }}
          />
        </div>

        {nonKeslerCourseName && (
          <div className="mb-8 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">
              {displayCourseName(nonKeslerCourseName, nonKeslerCourseAlias)} Tasks
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Review a textbook and/or video lectures that cover this topic:
            </p>
            <div 
              className="font-medium text-gray-800 dark:text-gray-200 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-blue"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContentWithLineBreaks(topicTitle || 'N/A')) }}
            />
          </div>
        )}

        <div className="mb-8">
          <div className="mb-6 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">Kesler Tasks</h3>
            <div 
              className="text-gray-700 dark:text-gray-300 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-orange"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContentWithLineBreaks(keslerTopicTitle || '')) }}
            />
          </div>

          {/* Add Time Estimate Section */}
          {renderTimeEstimate()}

          <div className="space-y-4">
            {keslerTasks.map((task) => {
              const isActive = activeTaskId === task.id && showTooltip && !task.completed;
              return (
                <div 
                  key={task.id}
                  className={`bg-white dark:bg-gray-800 overflow-hidden rounded-lg border-2 transition-all duration-300 ${
                    isActive
                      ? 'border-primary-orange shadow-lg shadow-orange-100 dark:shadow-orange-900/20'
                      : task.completed
                      ? 'border-gray-200 dark:border-gray-700'
                      : 'border-gray-200 dark:border-gray-700'
                  }`}
                >
                  {isActive && (
                    <div className="bg-orange-50 dark:bg-orange-900/10 px-4 py-2 flex items-center gap-2 border-b-2 border-primary-orange">
                      <Check className="w-4 h-4 text-primary-orange" />
                      <span className="text-sm text-orange-700 dark:text-orange-300">
                        Click the circle to mark this task as complete!
                      </span>
                    </div>
                  )}
                  <div className={`p-4 sm:p-6 ${isActive ? 'bg-orange-50/50 dark:bg-orange-900/5' : ''}`}>
                    {/* Desktop Layout */}
                    <div className="hidden sm:grid grid-cols-[1fr_auto_auto] gap-6 items-center">
                      <div>
                        <h4 className={`text-lg font-medium ${
                          task.completed 
                            ? 'text-gray-500 dark:text-gray-400 line-through' 
                            : isActive
                              ? 'text-orange-900 dark:text-orange-100'
                              : 'text-gray-900 dark:text-gray-100'
                        }`}>
                          {task.title}
                        </h4>
                        <p className={`mt-1 text-sm ${
                          task.completed 
                            ? 'text-gray-400 dark:text-gray-500 line-through' 
                            : isActive
                              ? 'text-orange-700 dark:text-orange-300'
                              : 'text-gray-600 dark:text-gray-300'
                        }`}>
                          {task.description}
                        </p>
                      </div>

                      <div className="flex items-center">
                        {renderTaskButton(task, taskDetails)}
                      </div>

                      <div className="flex items-center justify-center pl-2">
                        {renderCompletionCircle(task)}
                      </div>
                    </div>

                    {/* Mobile Layout */}
                    <div className="sm:hidden flex flex-col space-y-4">
                      <div className="flex items-start justify-between">
                        <div className="flex-grow">
                          <h4 className={`text-lg font-medium ${
                            task.completed 
                              ? 'text-gray-500 dark:text-gray-400 line-through' 
                              : isActive
                                ? 'text-orange-900 dark:text-orange-100'
                                : 'text-gray-900 dark:text-gray-100'
                          }`}>
                            {task.title}
                          </h4>
                          <p className={`mt-1 text-sm ${
                            task.completed 
                              ? 'text-gray-400 dark:text-gray-500 line-through' 
                              : isActive
                                ? 'text-orange-700 dark:text-orange-300'
                                : 'text-gray-600 dark:text-gray-300'
                          }`}>
                            {task.description}
                          </p>
                        </div>
                        <div className="ml-4">
                          {renderCompletionCircle(task)}
                        </div>
                      </div>
                      <div>
                        {renderTaskButton(task, taskDetails)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="bg-transparent">
        {/* NEW: Alert if no quiz questions available */}
        {noAvailableQuestions && (
          <div className="my-6 p-4 rounded-md bg-red-50 border border-red-300 text-red-800">
            <p className="font-semibold">
              There are no quiz questions available for this filter. You can mark the quiz task complete.
            </p>
            <button
              onClick={handleMarkComplete}
              className="mt-3 bg-primary-orange hover:bg-primary-orange-hover text-white py-2 px-4 rounded-md"
            >
              Mark Task as Complete
            </button>
          </div>
        )}

        {renderTaskContent(taskDetails)}
        <div className="mt-8">
          <button
            onClick={isMainTaskCompleted ? handleUncompleteTask : handleMarkComplete}
            className={`w-full ${
              isMainTaskCompleted
                ? 'bg-gray-400 hover:bg-gray-500'
                : 'bg-primary-blue hover:bg-primary-blue-hover'
            } text-white py-3 px-6 rounded-md transition duration-300`}
          >
            {isMainTaskCompleted ? 'Mark Task as Incomplete' : 'Mark Task as Complete'}
          </button>
        </div>
      </div>

      <PopupModal
        isOpen={activeQuizState.showActiveQuizModal}
        onClose={() => setActiveQuizState(prev => ({ ...prev, showActiveQuizModal: false }))}
        onResume={handleQuiz}
        onEnd={handleEndQuiz}
        activeQuiz={activeQuizState.activeQuiz}
        courseId={courseId}
      />
    </>
  );
};

export default LearnTask;