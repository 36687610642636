import React, { useState, useEffect } from 'react';
import { api, Simulation, UserTBSResponse, SimulationQuestionContent } from '../../services/api';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './common/LoadingScreen';
import SimulationsRender from './SimulationsRender';
import SimulationNotes from './SimulationNotes';
import { ChevronDown, ChevronUp, ArrowLeft, BarChart2, Layout } from 'lucide-react';

interface SimulationCorrectAnswer {
  [questionId: string]: {
    [cellRef: string]: string;
  };
}

interface SimulationPreviewProps {
  simulationId: string;
  courseId: string;
  userId: string;
  previewContent?: {
    html?: string;
    question_content?: SimulationQuestionContent;
    correct_answer?: SimulationCorrectAnswer;
    answer_content?: string;
    exhibits?: any;
  };
}

const SimulationPreview: React.FC<SimulationPreviewProps> = ({
  simulationId,
  courseId,
  userId,
  previewContent
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [userResponse, setUserResponse] = useState<UserTBSResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showResponseComparison, setShowResponseComparison] = useState(false);

  useEffect(() => {
    const loadSimulation = async () => {
      if (!simulationId || !courseId || !userId) return;

      try {
        setIsLoading(true);

        if (previewContent) {
          // If preview content is provided, fetch basic simulation data and merge with preview
          const simulationData = await api.getSimulation(simulationId);
          
          // Ensure preview content has all required fields for question_content
          const mergedQuestionContent = previewContent.question_content
            ? {
                ...simulationData.question_content,
                ...previewContent.question_content,
                questions: previewContent.question_content.questions || simulationData.question_content.questions,
                tableHeaders: previewContent.question_content.tableHeaders || simulationData.question_content.tableHeaders,
                introduction: previewContent.question_content.introduction || simulationData.question_content.introduction,
                type: previewContent.question_content.type || simulationData.question_content.type
              }
            : simulationData.question_content;
          
          const mergedSimulation: Simulation = {
            ...simulationData,
            html: previewContent.html ?? simulationData.html,
            question_content: mergedQuestionContent,
            correct_answer: previewContent.correct_answer ?? simulationData.correct_answer,
            answer_content: previewContent.answer_content ?? simulationData.answer_content,
            exhibits: previewContent.exhibits ?? simulationData.exhibits
          };
          
          setSimulation(mergedSimulation);
        } else {
          // Fetch simulation and user response in parallel for normal mode
          const [simulationData, userResponseData] = await Promise.all([
            api.getSimulation(simulationId),
            api.getUserTBSResponse(userId, simulationId, courseId)
          ]);

          setSimulation(simulationData);
          setUserResponse(userResponseData);
        }
      } catch (err) {
        console.error('Error loading simulation:', err);
        setError('Failed to load simulation. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    loadSimulation();
  }, [simulationId, courseId, userId, previewContent]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const navigationButtons = [
    {
      label: "Back to Notes",
      icon: <ArrowLeft size={20} />,
      onClick: () => navigate(`/course/${courseId}/focus-notes`),
      className: "bg-gray-500 hover:bg-gray-600"
    },
    {
      label: "Simulations",
      icon: <Layout size={20} />,
      onClick: () => navigate(`/course/${courseId}/simulations`),
      className: "bg-blue-500 hover:bg-blue-600"
    },
    {
      label: "Analytics",
      icon: <BarChart2 size={20} />,
      onClick: () => navigate(`/course/${courseId}/analytics`),
      className: "bg-green-500 hover:bg-green-600"
    }
  ];

  const renderSolution = (content: string) => {
    return (
      <div 
        dangerouslySetInnerHTML={{ __html: content }}
        className={`
          [&>div]:space-y-6 
          [&>div>div]:bg-gray-800/5 
          dark:[&>div>div]:bg-gray-100/5 
          [&>div>div]:p-4 
          [&>div>div]:rounded-lg 
          [&_p]:mb-4 
          last:[&_p]:mb-0 
          [&_h5]:font-bold 
          [&_h5]:text-lg 
          [&_h5]:mb-2
          
          /* List Specific Styles */
          [&_ol]:list-decimal 
          [&_ol]:pl-8
          [&_ol]:my-6
          [&_ol>li]:mb-4
          [&_ol>li]:pl-4
          [&_ol>li]:list-item
          [&_ol>li]:relative
          [&_ol>li]:leading-relaxed
          
          /* Nested List Spacing */
          [&_ol>li>ol]:mt-4
          [&_ol>li>ol]:mb-0
          
          /* Strong Text */
          [&_strong]:font-semibold
          [&_strong]:text-gray-900
          dark:[&_strong]:text-gray-100
          
          /* List Counter Specifics */
          [&_ol]:counter-reset-[item]
          [&_ol>li]:before:content-[counter(item)]
          [&_ol>li]:before:counter-increment-[item]
          [&_ol>li]:before:absolute
          [&_ol>li]:before:left-[-1.5em]
          [&_ol>li]:before:text-gray-600
          dark:[&_ol>li]:before:text-gray-400
          
        `}
      />
    );
  };

  if (isLoading) return <LoadingScreen message="Loading Simulation..." />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!simulation) return <div>Simulation not found.</div>;

  return (
    <div className="p-4 sm:p-6 md:p-8 bg-white dark:bg-gray-800 rounded-lg shadow">
      <div className="max-w-4xl mx-auto">
        {/* Navigation Buttons - Only show in regular mode */}
        {!previewContent && (
          <div className="flex flex-wrap gap-3 mb-6">
            {navigationButtons.map((button, index) => (
              <button
                key={index}
                onClick={button.onClick}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg text-white transition-colors duration-200 ${button.className}`}
              >
                {button.icon}
                <span className="hidden sm:inline">{button.label}</span>
              </button>
            ))}
          </div>
        )}

        {/* SimulationsRender */}
        <SimulationsRender
          simulation={simulation}
          onAnswerChange={() => {}}
          userAnswers={userResponse?.answers || {}}
          isSubmitted={!previewContent}
          gradingResults={userResponse ? {
            score: userResponse.score || 0,
            feedback: userResponse.feedback || []
          } : null}
          currentSimulationIndex={0}
          totalSimulations={1}
          userId={userId}
          courseId={courseId}
          isReviewing={!previewContent}
        />

        {/* User Response Section - Only show in regular mode */}
        {!previewContent && userResponse && (
          <div className="mt-6">
            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
              <div className="text-center text-xl font-bold mb-4">
                Score: {userResponse.score}%
              </div>
              {userResponse.time_spent && (
                <div className="text-center mb-4">
                  Time Spent: {formatTime(userResponse.time_spent)}
                </div>
              )}
              {userResponse.confidence_level && (
                <div className="text-center mb-4">
                  Confidence Level: <span className="capitalize">{userResponse.confidence_level}</span>
                </div>
              )}
            </div>

            <div className="mt-4">
              <button
                onClick={() => setShowResponseComparison(!showResponseComparison)}
                className="flex items-center justify-between w-full p-4 bg-gray-100 dark:bg-gray-700 rounded-lg"
              >
                <span className="text-lg font-semibold">Response Comparison</span>
                {showResponseComparison ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>

              {showResponseComparison && userResponse.feedback && (
                <div className="mt-4 space-y-4">
                  {userResponse.feedback
                    .filter(f => f.questionId !== 'totals' && f.questionId !== 'header')
                    .map((item) => {
                      const questionNum = item.questionId.replace(/[^0-9]/g, '');
                      return (
                        <div key={item.questionId} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                          <h4 className="text-lg font-semibold mb-2">Question {questionNum}</h4>
                          {Object.entries(item.answerFeedback).map(([key, val]) => (
                            <div 
                              key={key} 
                              className={`p-2 rounded mb-2 ${
                                val.isCorrect 
                                  ? 'bg-green-100 dark:bg-green-800' 
                                  : 'bg-red-100 dark:bg-red-800'
                              }`}
                            >
                              <p>Your answer: {typeof val.userAnswer === 'object' ? val.userAnswer.value : val.userAnswer}</p>
                              <p>Correct answer: {typeof val.correctAnswer === 'object' ? val.correctAnswer.value : val.correctAnswer}</p>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Solution Section */}
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Solution:</h3>
          {renderSolution(simulation.answer_content)}
        </div>

        {/* Notes Section - Only show in regular mode */}
        {!previewContent && (
          <div className="mt-6">
            <SimulationNotes
              simulationId={simulationId}
              courseId={courseId}
              userId={userId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SimulationPreview;